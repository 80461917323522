import { useRef, useState, useEffect } from 'react';

/**
 * Return the results of `getBoundingClientRect` and listen to resize events.
 *
 * Note: this is not useful for positional tracking, only element size changes
 */
// https://github.com/streamich/react-use/blob/master/src/useMeasureDirty.ts
export const useMeasure = (ref) => {
  const frame = useRef(0);
  const [rect, set] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // eslint-disable-next-line id-length
    x: 0,
    // eslint-disable-next-line id-length
    y: 0,
  });

  const [observer] = useState(
    () => new ResizeObserver((entries) => {
      const entry = entries[0];

      if (entry) {
        cancelAnimationFrame(frame.current);

        frame.current = requestAnimationFrame(() => {
          if (ref.current) {
            set(entry.contentRect);
          }
        });
      }
    }),
  );

  useEffect(() => {
    observer.disconnect();

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [observer, ref]);

  return rect;
};

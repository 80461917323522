import classnames from 'classnames';
import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import syncTokens from '@amzn/meridian-tokens/base/icon/sync';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';
import AdLeftSlotWarning from './AdLeftSlotWarning';
import CheckoutSOVWarning from './CheckoutSOVWarning';
import VSSMSlotWarning from './VSSMSlotWarning';
import FullSlotWarning from './FullSlotWarning';

const Placement = (props) => {
  const {
    campaignColumns,
    currentTab,
    shouldShowSyncUpCampaignsBlock,
    shouldShowMarketingManagerNotes,
    shouldShowMerchant,
    shouldShowChannel,
    syncUpCampaignsLabel,
    isVSSM,
    onSyncCampaign,
  } = props;

  const campaignField = createCampaignFieldForIndex(currentTab);

  const onSyncCurrentCampaign = () => onSyncCampaign(currentTab);

  const getSyncUpCampaignsBlock = () => {
    if (!shouldShowSyncUpCampaignsBlock) {
      return null;
    }

    return (
        <div>
          <Button
              onClick={onSyncCurrentCampaign}
              size="small"
          >
            <Icon tokens={syncTokens}/> Sync up
          </Button>
          <p>{syncUpCampaignsLabel}</p>
        </div>
    );
  };

  const getMarketingManagerNotes = () => {
    if (!shouldShowMarketingManagerNotes) {
      return null;
    }
    return (
      <div className={styles.formGroupSection}>
        <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
          <FormField {...campaignField(campaignColumns.MARKETING_MANAGER_NOTES)} />
        </div>
      </div>
    )
  }

  const getMerchant = () => {
    if (!shouldShowMerchant) {
      return null;
    }

    return (
      <div className={styles.formControl}>
        <FormField {...campaignField(campaignColumns.MERCHANT)} />
      </div>
    );
  };

  const getChannel = () => {
    if (!shouldShowChannel) {
      return null;
    }

    return (
      <div className={styles.formControl}>
        <FormField {...campaignField(campaignColumns.CHANNEL)} />
      </div>
    );
  };

  const marketingManagerNotes = getMarketingManagerNotes();
  const merchant = getMerchant();
  const channel = getChannel();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Placement</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.STATUS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PLACEMENT_IN_BUNDLE)} />
            </div>
            {channel}
          </div>
          {merchant}
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.VSSM_ID)} isDisabled={!isVSSM} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.SOV_TYPE)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.SOV)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PAGETYPE)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PAGE_NAME)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PAGE_ID)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PLACEMENT)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.DESKTOP_SLOT)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.MOBILE_SLOT)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.WIDGET)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.IS_ANIMATED_CONTENT)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PAGE_THEME)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.OTHER_THEME)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.TARGETING)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.TARGETING_EXCLUDE)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.WEBLAB)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.WEBLAB_TREATMENT)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.CONTRA_COGS} />
            </div>
          </div>
          {marketingManagerNotes}
          <AdLeftSlotWarning />
          <CheckoutSOVWarning />
          <VSSMSlotWarning />
          <FullSlotWarning />
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              {getSyncUpCampaignsBlock()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
Placement.propTypes = formCampaignColumns;

export default Placement;

import { CampaignColumns } from '../../constants';
import { UtilizationAlmOnlyPages } from '../../constants/utilization';
import { onUtilizationShowFmcSlots } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { UtilizationPage: { selectedFilters: { pageType } } } = getState();
  const { data: { filterOptionKey, selectedFilter } } = action;

  if (
    filterOptionKey !== CampaignColumns.PAGE_NAME.name
    || !selectedFilter
    || !UtilizationAlmOnlyPages[pageType]
  ) {
    return next(action);
  }

  const isAlmSlotsOnlyPage = UtilizationAlmOnlyPages[pageType].includes(selectedFilter);

  dispatch(onUtilizationShowFmcSlots(!isAlmSlotsOnlyPage));

  return next(action);
};

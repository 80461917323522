// TODO: Unify these controls with the ones in helpers/formRenders.js
import DateTime from './DateTime';
import Input from './Input';
import TextAreaInput from './TextAreaInput';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import TimeInput from './TimeInput';
import Uneditable from './Uneditable';
import Checkbox from './Checkbox';
import JsonInput from './JsonInput';

export default {
  DateTime,
  Input,
  TextAreaInput,
  SingleSelect,
  MultiSelect,
  JsonInput,
  TimeInput,
  Uneditable,
  Checkbox,
};

import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const ContentAsins = (props) => {
  const {
    campaignColumns,
    currentTab,
    shouldShowSpanishCopy,
    shouldShowTranslationStatus,
  } = props;
  const campaignField = createCampaignFieldForIndex(currentTab);

  const getSpanishCopy = () => {
    if (!shouldShowSpanishCopy) {
      return null;
    }

    return (
      <div className={styles.formGroupSection}>
        <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
          <FormField {...campaignField(campaignColumns.SPANISH_COPY)} />
        </div>
      </div>
    );
  };

  const getTranslationStatus = () => {
    if (!shouldShowTranslationStatus) {
      return null;
    }

    return (
      <div className={styles.formControl}>
        <FormField {...campaignField(campaignColumns.TRANSLATION_STATUS)} />
      </div>
    );
  };

  const spanishCopy = getSpanishCopy();
  const translationStatus = getTranslationStatus();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Content & ASINs</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.LANGUAGE)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.LABEL)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.COPY)} />
            </div>
          </div>
          {spanishCopy}
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.SUB_HEADLINE)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.TERMS_AND_CONDITIONS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlCheckbox)}>
              <FormField {...campaignField(campaignColumns.USE_NEW_TEMPLATE)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.HERO_ASINS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.ASINS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.BROWSE_NODE_IDS)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.NEED_INVENTORY_AWARENESS)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.INVENTORY_AWARENESS)} />
            </div>
            {translationStatus}
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.SHOVELER_CONTENT)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.DEDUPE_RULES)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignField(campaignColumns.CUSTOM_SEE_MORE_URL)} />
            </div>
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ContentAsins.propTypes = {
  ...formCampaignColumns,
};

export default ContentAsins;

import { getDefaultPollingStartDateTime } from '../../helpers/customerNotifications';

export default (dispatch, getState, action, next) => {
  const { Notifications: { list } } = getState();
  const { type } = action;

  const lastNotificationReceivedTime = (list && list.length)
    ? list[0].createdAt
    : getDefaultPollingStartDateTime();

  return next({
    type,
    payload: {
      lastNotificationReceivedTime,
    },
  });
};

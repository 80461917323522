import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Link from '@amzn/meridian/link';
import Icon from '@amzn/meridian/icon';
import exportSmallTokens from '@amzn/meridian-tokens/base/icon/export-small';
import PopOver from '../PopOver';
import { GridViewDisplayModes, Language, NON_BREAKING_SPACE } from '../../constants';
import { campaignInputProps } from '../../proptypes';
import styles from './GridViewCell.module.scss';

const isPopOverWindow = (element) => {
  let result = false;
  Array.from(element.classList).forEach((className) => {
    if (className.indexOf('popOver') > -1) {
      result = true;
    }
  });

  return result;
};
// TODO: Disabled cells should be grayed out. Need to review this change with Anna first.
const GridViewCell = ({
  children,
  isActive,
  isDisabled,
  link,
  onSelectCell,
  onFlashFillCells,
  onFlashFillPackageCells,
  inputComponent,
  textAlign,
  errorText,
  isSticky,
  comparedItem,
  showComparison,
  isPopOverEnabled,
  displayMode,
}) => {
  const cellRef = useRef();
  const isPreview = displayMode === GridViewDisplayModes.PREVIEW_MODE;
  const [open, setOpen] = useState(false);
  const onContextMenuInvoke = useCallback(() => setOpen(!open), [open]);

  let derivedChildren = children;
  if (Array.isArray(children)) {
    derivedChildren = children.join(', ');
  }
  if (typeof children === 'boolean') {
    derivedChildren = children ? 'True' : 'False';
  }

  const [isHovered, setIsHovered] = useState(false);

  let contents;

  if (isActive && !isDisabled) {
    const ActiveElement = inputComponent;
    contents = <ActiveElement />;
  } else {
    contents = derivedChildren || NON_BREAKING_SPACE;
  }

  const isEmpty = (value) => {
    return typeof value === 'undefined' || value === NON_BREAKING_SPACE || value === '' || value === null;
  };

  const classes = classNames({
    [styles.cell]: true,
    [styles.active]: isActive,
    [styles.inactive]: !isActive,
    [styles.clickable]: !isActive,
    [styles.clickableHovered]: isHovered && !isActive,
    [styles.preview]: isPreview,
    [styles.disabled]: isDisabled,
    [styles.left]: textAlign === 'left',
    [styles.right]: textAlign === 'right',
    [styles.center]: textAlign === 'center',
    [styles.add]: showComparison && isEmpty(comparedItem) && !isEmpty(children),
    [styles.remove]: showComparison && !isEmpty(comparedItem) && isEmpty(children),
    [styles.update]: showComparison
      && !isEmpty(comparedItem)
      && !isEmpty(children)
      && comparedItem !== children,
  });

  const errorPopup = errorText ? (
    <div className={styles.errorText}>
      {errorText}
    </div>
  ) : null;

  const errorIcon = errorText ? (
    <div className={styles.errorIcon} />
  ) : null;

  const onClick = (e) => {
    if (!e || isPopOverWindow(e.target)) {
      return;
    }

    if (!isActive && !isDisabled && !isPreview) {
      onSelectCell();
    }
  };

  const onContextMenuClick = (e) => {
    e.preventDefault();
    onContextMenuInvoke();
  };

  const onFlashFillMenuItemClick = () => {
    if (window.confirm(Language.FLASH_FILL_PAGE_CONFIRMATION_MSG)) {
      onFlashFillCells();
    }
  };
  const onFlashFillPackageMenuItemClick = () => {
    if (window.confirm(Language.FLASH_FILL_PACKAGE_CONFIRMATION_MSG)) {
      onFlashFillPackageCells();
    }
  };

  const containerClasses = classNames({
    [styles.cellContainer]: true,
    [styles.sticky]: isSticky,
    ...classes,
  });

  const getLink = () => {
    if (!link) { return null; }
    return (
      <div className={styles.floatingLink}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon tokens={exportSmallTokens} />
        </Link>
      </div>
    );
  };

  const getContextMenu = () => {
    return (
      <Menu
        anchorNode={cellRef.current}
        id="cell-context-menu"
        open={open}
        position="bottom"
        onClose={onContextMenuInvoke}
      >
        <MenuItem onClick={onFlashFillMenuItemClick}>
          {Language.FLASH_FILL_CONTEXT_PAGE_MENU_ITEM}
        </MenuItem>
        <MenuItem onClick={onFlashFillPackageMenuItemClick}>
          {Language.FLASH_FILL_CONTEXT_PACKAGE_MENU_ITEM}
        </MenuItem>
      </Menu>
    );
  };

  const popOverContent = isPopOverEnabled && !isActive && derivedChildren
    ? <PopOver isOpen={isHovered}>{derivedChildren}</PopOver>
    : null;

  const contextMenu = getContextMenu();

  return (
    <>
      <td
        ref={cellRef}
        onClick={onClick}
        onContextMenu={onContextMenuClick}
        role="gridcell"
        className={containerClasses}
        onMouseEnter={() => { setIsHovered(true); }}
        onMouseLeave={() => { setIsHovered(false); }}
      >
        {popOverContent}
        <div className={classes}>
          {contents}
          {errorIcon}
        </div>
        {isHovered && errorPopup}
        {isHovered && !isActive && getLink()}
      </td>
      {contextMenu}
    </>
  );
};

GridViewCell.propTypes = {
  onSelectCell: PropTypes.func.isRequired,
  onFlashFillCells: PropTypes.func.isRequired,
  inputComponent: PropTypes.elementType.isRequired,
  isPopOverEnabled: PropTypes.bool.isRequired,
  children: campaignInputProps,
  comparedItem: campaignInputProps,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'right', 'center']),
  errorText: PropTypes.string,
  isSticky: PropTypes.bool,
  link: PropTypes.string,
  showComparison: PropTypes.bool,
};

GridViewCell.defaultProps = {
  errorText: '',
  isActive: false,
  isDisabled: false,
  textAlign: 'left',
  children: NON_BREAKING_SPACE,
  isSticky: false,
  link: null,
  comparedItem: null,
  showComparison: false,
};

export default GridViewCell;

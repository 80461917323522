import { connect } from 'react-redux';
import { onSetPaginationPageAction } from '../../actionCreators';
import { getFilteredPackages } from '../../selectors/campaignFiltersSelectors';
import GridViewPaginator from '../Paginator/Paginator';
const mapStateToProps = (state) => {
  const { GridViewPage: { paginationPage, paginationItemsPerPage } } = state;
  return {
    paginationPage,
    pageCount: Math.ceil(Object.keys(getFilteredPackages(state)).length / paginationItemsPerPage),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetPaginationPage(page) { dispatch(onSetPaginationPageAction(page)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewPaginator);

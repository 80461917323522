import { connect } from 'react-redux';
import CampaignStats from '../CampaignStats';
import { getCustomViewUrl, getStatsByGroups } from '../../../helpers/dashboard';
import { isFreshBusiness } from '../../../helpers/common';
import { CampaignColumns } from '../../../constants';

export const mapStateToProps = (state) => {
  const {
    Sitewide: {
      selectedBusiness,
    },
    Dashboard: {
      isOverlapMode,
      myStats: {
        totalMarketingManager,
        totalScheduler,
        totalCampaigns,
        statsBy,
      },
      dateRange,
    },
    User: {
      currentUser: {
        username,
      },
    },
  } = state;

  const [startDate, endDate] = dateRange;

  const totalCampaignsUrl = getCustomViewUrl(
    {
      startDate,
      endDate,
      username,
    },
    isOverlapMode,
  );

  const totalMarketingManagerUrl = getCustomViewUrl(
    {
      startDate,
      endDate,
      username,
    },
    isOverlapMode,
    CampaignColumns.MARKETING_MANAGER.name,
  );

  const totalSchedulerUrl = getCustomViewUrl(
    {
      startDate,
      endDate,
      username,
    },
    isOverlapMode,
    CampaignColumns.SCHEDULER.name,
  );

  const statsByGroups = getStatsByGroups(statsBy, dateRange, {
    username,
  },
  isOverlapMode);

  return {
    isFreshBusiness: isFreshBusiness(selectedBusiness),
    totalCampaigns,
    totalCampaignsUrl,
    totalMarketingManager,
    totalMarketingManagerUrl,
    totalScheduler,
    totalSchedulerUrl,
    statsByGroups,
  };
};

export default connect(mapStateToProps)(CampaignStats);

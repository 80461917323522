import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import PackageForm from './PackageForm';
import {
  CampaignColumns,
  Form,
  FormValidationTypes,
  SovTypes,
} from '../../constants';
import {
  getString,
  isFreshBusiness,
  isWFMBusiness,
} from '../../helpers/common';

import { validate, warn } from '../../helpers/formValidators';
import {
  onAddPackage,
  onEditPackage,
  onRateCardAssetUpdate,
} from '../../actionCreators';
import { getCampaignFieldsByBusiness } from '../../helpers/form';
import {
  getDefaultTimezoneFromState,
} from '../../helpers/dateTime';
import {
  getOmnichannelPackageTab,
} from '../../selectors/packageTabs';
import { createPackageShell } from '../../helpers/package';
import {
  getCurrentBusinessStartYear,
  isFreshOnsiteTrafficBusiness,
  isReadOnlyMode,
  isBusinessFreshTraffic,
  isWebsiteOmnichannelViewMode,
} from '../../selectors/sitewide';
import { areCampaignsLoaded } from '../../selectors/campaign';
import { arePackagesLoaded } from '../../selectors/package';
import { createOnlineCampaignShell } from '../../helpers/campaign';
import { TimeValues } from 'src/constants/dateTime';

const selector = formValueSelector(Form.NAME);

export const getActiveCampaign = (campaigns, activeTab) => {
  if (!campaigns) {
    return null;
  }

  return campaigns.find((_, index) => index === activeTab);
}

export const mapStateToProps = (state, ownProps) => {
  const {
    isNew,
    isClone,
    loadedPackage,
    isAssetEditMode,
  } = ownProps;
  const {
    Meta: {
      initialMetadataLoaded,
    },
    Sitewide: {
      selectedBusiness,
    },
    User: { currentUser: { username } },
    FormPage: {
      validation: {
        [FormValidationTypes.UTILIZATION]: {
          isValidDesktopSlot,
          isValidMobileSlot,
        },
      },
    },
  } = state;

  const campaignColumnsSet = getCampaignFieldsByBusiness(selectedBusiness);
  let currentPackage = {};

  if (loadedPackage && (isClone || !isNew)) {
    currentPackage = _.cloneDeep(loadedPackage);

    if (isClone) {
      currentPackage[CampaignColumns.REF_MARKER.name] = '';
      if (!_.isEmpty(currentPackage.campaigns)) {
        currentPackage.campaigns = currentPackage.campaigns.map(campaign => {
          return {
            ...campaign,
            [CampaignColumns.REF_MARKER.name]: '',
          };
        });
      }
    }
  } else {
    // TODO: move sov to middleware
    // New
    const currentSelectedBusiness = getString(selector(state, campaignColumnsSet.BUSINESS.name));

    // Set default SOV
    let assignedSovType = SovTypes.SOV;
    if (isWFMBusiness(currentSelectedBusiness)) {
      assignedSovType = SovTypes.RANDOM;
    }
    if (isFreshBusiness(currentSelectedBusiness)) {
      assignedSovType = SovTypes.SOV;
    }

    const campaigns = isWebsiteOmnichannelViewMode(state)
      ? []
      : [
        createOnlineCampaignShell({
          [CampaignColumns.BUSINESS_START_YEAR.name]: getCurrentBusinessStartYear(state),
          [CampaignColumns.TIMEZONE_ID.name]: getDefaultTimezoneFromState(state),
          [CampaignColumns.START_TIME.name]: TimeValues.DEFAULT.value,
          [CampaignColumns.END_TIME.name]: TimeValues.DEFAULT.value,
        }),
      ];
    // Set defaults
    currentPackage = createPackageShell({
        [CampaignColumns.BUSINESS_START_YEAR.name]: getCurrentBusinessStartYear(state),
        [CampaignColumns.TIMEZONE_ID.name]: getDefaultTimezoneFromState(state),
        [CampaignColumns.MARKETING_MANAGER.name]: username || '',
        [CampaignColumns.START_TIME.name]: isBusinessFreshTraffic(state) ? TimeValues.TRAFFIC_START.value : TimeValues.DEFAULT.value,
        [CampaignColumns.END_TIME.name]: isBusinessFreshTraffic(state) ? TimeValues.MIDNIGHT.value : TimeValues.DEFAULT.value,
      },
      campaigns,
    );
  }

  const id = !isClone && loadedPackage ? loadedPackage.id : null;
  const isLoading = !arePackagesLoaded(state) && !initialMetadataLoaded && !isAssetEditMode;
  const isNotFound = !isNew && arePackagesLoaded(state) && !loadedPackage;
  const showConfirmation = isNew && (
    (isValidDesktopSlot !== undefined && !isValidDesktopSlot)
    || (isValidMobileSlot !== undefined && !isValidMobileSlot)
  );

  return {
    id,
    isNew,
    isClone,
    isLoading,
    isNotFound,
    isTrafficMode: isBusinessFreshTraffic(state),
    isLoadingCampaigns: !areCampaignsLoaded(state),
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    isRefMarkerEditable: !isNew && !isClone && !isNotFound,
    isReadOnlyMode: isReadOnlyMode(state),
    initialValues: currentPackage,
    campaignColumns: campaignColumnsSet,
    showConfirmation,
    assetEditCompatibleMode: isAssetEditMode,
    campaign: getActiveCampaign(
      selector(state, 'campaigns'),
      getOmnichannelPackageTab(state)
    ),
  };
};

export const mapDispatchToProps = (dispatch, { isAssetEditMode }) => ({
  onCreate: (data, { hasFailedSlotsValidation = false } = {}) => {
    dispatch(onAddPackage(data, { hasFailedSlotsValidation }));
  },
  onUpdate: (data) => {
    if (isAssetEditMode) {
      const updatedAsset = {
        campaign: data,
        rateCardId: data.rateCardId,
        id: data.id,
        version: data.version,
      };
      delete updatedAsset.campaign.id;
      delete updatedAsset.campaign.version;

      dispatch(onRateCardAssetUpdate(updatedAsset));
    } else {
      dispatch(onEditPackage(data));
    }
  },
  onClone: (data) => {
    dispatch(onAddPackage(data, { isClone: true }));
  },
});

const reduxFormConfig = {
  form: Form.NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true, // https://github.com/redux-form/redux-form/issues/2971
  validate,
  warn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(reduxFormConfig)(PackageForm));

import { connect } from 'react-redux';

import {
  onSetActiveFilterView,
  onOpenFilterViewModal,
  onDeleteFilterView,
  onCustomViewSetModalVisible,
} from '../../../actionCreators';

import GridViewFilterControlMenu from './GridViewFilterControlMenu';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      filterViews,
      activeFilterView,
      filters,
      sorts,
    },
  } = state;

  const activeViewData = { filters, sorts };

  return {
    filterViews,
    activeFilterView,
    activeViewData,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setActiveFilterView(name) { dispatch(onSetActiveFilterView(name)); },
    openViewModal(view) { dispatch(onOpenFilterViewModal(view)); },
    deleteViewAction(name) { dispatch(onDeleteFilterView(name)); },
    openCustomViewModal() { dispatch(onCustomViewSetModalVisible(true)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewFilterControlMenu);

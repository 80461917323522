import { createSelector } from 'reselect';
import { getMelodyTestingAdminList } from 'src/helpers/accessControlLists';

export const getUser = (state) => state.User;

export const getCurrentUser = createSelector(
  [getUser],
  ({ currentUser }) => currentUser,
);
export const getUsername = createSelector(
  [getCurrentUser],
  ({ username }) => username,
);

export const isCurrentUserAdmin = (state) => getCurrentUser(state).isAdmin;
export const isCurrentUserMetadataAdmin = (state) => getCurrentUser(state).isMetadataAdmin;
export const isCurrentUserMelodyAdmin = (state) => {
  const username = getUsername(state);
  const melodyAdminList = getMelodyTestingAdminList();
  return melodyAdminList.includes(username);
};

import { connect } from 'react-redux';
import RateCardAssetsMenu from './RateCardAssetsMenu';
import { getPublishedCampaignListUrl } from '../../helpers/rateCard';
import {
  hasAssets,
  hasPublishedAssets,
  isCurrentConfigTheLatest,
} from '../../selectors/rateCardSelectors';
import { onRateCardBatchDeleteAssets } from '../../actionCreators';

const mapStateToProps = (state, { rateCardId }) => {
  const hasAssetsBeenPublished = hasPublishedAssets(state, rateCardId);
  const isDeleteAllButtonAvailable = isCurrentConfigTheLatest(state)
    && hasAssets(state) && !hasAssetsBeenPublished;

  return {
    publishedCampaignListUrl: getPublishedCampaignListUrl(rateCardId),
    isShowCampaignButtonAvailable: hasAssetsBeenPublished,
    isDeleteAllButtonAvailable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAllAssets() {
      dispatch(onRateCardBatchDeleteAssets());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardAssetsMenu);

import {
  onRateCardAssetDelete,
} from '../../actionCreators';
import { getRateCardAssetsList } from '../../selectors/rateCardSelectors';

export default (dispatch, getState, action, next) => {
  getRateCardAssetsList(getState()).forEach((asset) => dispatch(onRateCardAssetDelete(asset)));

  return next(action);
};

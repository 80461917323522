import React from 'react';
import { useHistory } from 'react-router-dom';
import Heading from '@amzn/meridian/heading';
import Button from '@amzn/meridian/button';
import Breadcrumb, { BreadcrumbGroup } from '@amzn/meridian/breadcrumb';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import { AddIcon, EditIcon, DeleteIcon } from '../Icons';
import { Urls, Language } from '../../constants';
import styles from './BulletinBoard.module.scss';
var BulletinBoard = function (props) {
    var history = useHistory();
    var announcements = props.announcements, onDelete = props.onDelete, isLoading = props.isLoading, isAdmin = props.isAdmin;
    var editAnnouncement = function (id) { return function () {
        // TODO: metrics
        history.push("".concat(Urls.EDIT_BOARD_ANNOUNCEMENT, "/").concat(id));
    }; };
    var createAnnouncement = function () {
        // TODO: metrics
        history.push("".concat(Urls.ADD_BOARD_ANNOUNCEMENT));
    };
    var loadingMessage = (React.createElement(TableRow, null,
        React.createElement(TableCell, { columnSpan: 5 }, "Please wait, loading...")));
    var formattedList = announcements.map(function (announcement) {
        var id = announcement.id, isStickyText = announcement.isStickyText, isGlobalText = announcement.isGlobalText, message = announcement.message, startDateTime = announcement.startDateTime, endDateTime = announcement.endDateTime, isSticky = announcement.isSticky;
        var controlsBlock = isAdmin
            ? (React.createElement(TableCell, { alignmentVertical: "top" },
                React.createElement("div", { className: styles.controls },
                    React.createElement(Button, { size: "small", type: "link", onClick: editAnnouncement(id) },
                        React.createElement(EditIcon, null)),
                    React.createElement(Button, { size: "small", type: "link", onClick: onDelete(id) },
                        React.createElement(DeleteIcon, null)))))
            : null;
        var dateRange = "".concat(startDateTime, " ").concat(endDateTime);
        return (React.createElement(TableRow, { key: "".concat(id, "/").concat(startDateTime, "/").concat(endDateTime), highlightOnHover: true, highlight: isSticky },
            controlsBlock,
            React.createElement(TableCell, { alignmentVertical: "top" },
                React.createElement("p", { className: styles.userInputArea, dangerouslySetInnerHTML: { __html: message } })),
            React.createElement(TableCell, { alignmentVertical: "top" },
                React.createElement("span", { className: styles.option }, isStickyText)),
            React.createElement(TableCell, { alignmentVertical: "top" },
                React.createElement("span", { className: styles.option }, isGlobalText)),
            React.createElement(TableCell, { alignmentVertical: "top" },
                React.createElement("span", { className: styles.option }, dateRange))));
    });
    var contentBlock = isLoading
        ? loadingMessage
        : formattedList;
    var addNewAnnouncementBlock = null;
    var actionsHeader = null;
    if (isAdmin) {
        addNewAnnouncementBlock = (React.createElement("div", { className: styles.addNewAnnouncementButton },
            React.createElement(Button, { size: "small", type: "link", onClick: createAnnouncement },
                React.createElement(AddIcon, null),
                ' ',
                Language.BULLETIN_BOARD_ADD_NEW_ANNOUNCEMENT_BUTTON)));
        actionsHeader = React.createElement(TableCell, null, "Actions");
    }
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className: styles.breadcrumbs },
                React.createElement(BreadcrumbGroup, null,
                    React.createElement(Breadcrumb, { href: "#dashboard" }, "Dashboard"),
                    React.createElement(Breadcrumb, { href: "#board" }, "Bulletin Board"))),
            React.createElement("div", { className: styles.heading },
                React.createElement(Heading, { level: 1 }, "Bulletin Board")),
            addNewAnnouncementBlock,
            React.createElement(Table, { headerRows: 1, showStripes: false, showDividers: true },
                React.createElement(TableRow, null,
                    actionsHeader,
                    React.createElement(TableCell, null, "Announcement"),
                    React.createElement(TableCell, null, "Pin"),
                    React.createElement(TableCell, null, "Pin (global)"),
                    React.createElement(TableCell, null, "Active for date range")),
                contentBlock))));
};
export default BulletinBoard;

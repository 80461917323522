import { connect } from 'react-redux';
import AppSyncCheckConnection from './AppSyncCheckConnection';
import { getUsername } from '../../selectors/user';
import { isAppSyncConnectionAlive } from '../../selectors/sitewide';
import {
  setAppSyncConnectionStatus
} from '../../actionCreators/sitewide';
import { AppSyncConnectionType } from '../../constants/appSync';

export const mapStateToProps = (state) => {
  return {
    isAppSyncConnectionAlive: isAppSyncConnectionAlive(state),
    username: getUsername(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetAppSyncConnectionStatus(isAlive, error) {
      dispatch(setAppSyncConnectionStatus(
        isAlive,
        AppSyncConnectionType.CONNECTION,
        error
      ));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppSyncCheckConnection);
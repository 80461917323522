import { RateCardMerchTypes } from '../../../../constants/rateCard';

const Consideration = {
  'Storefront Tile (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Hero Tile',
    ],
  },
  'Storefront Shoveler - Standard (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler Basic Feature',
    ],
  },
  'Storefront Shoveler - Feature (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler Feature Plus',
    ],
  },
  'Storefront Enhanced Shoveler (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Enhanced Storefront Shoveler Feature',
    ],
  },
  'Category Hero (1-quarter)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Page Hero',
    ],
  },
  'Fresh Event Shoveler': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'SNAP EBT Page Shoveler (1-quarter)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'SNAP EBT Page Shoveler (1-quarter)',
    ],
  },
  'Category Shoveler (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Shoveler (2-weeks)',
    ],
  },
  'Category Shoveler (4-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Shoveler (4-weeks)',
    ],
  },
  'Category Enhanced Shoveler (2-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Enhanced Shoveler (2-weeks)',
    ],
  },
  'Category Enhanced Shoveler (4-weeks)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Enhanced Shoveler (4-weeks)',
    ],
  },
  'Fresh Event Inclusion': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Fresh Event Inclusion',
    ],
  },
  'Specialty Page Inclusion': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Specialty Page Inclusion',
    ],
  },
  'Storefront Shoveler - Standard (1-week)': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler - Standard (1-week)',
    ],
  },
  '[Standard] Seasonal Event Bundle': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Fresh Event Shoveler',
      'Category Shoveler (4-weeks)',
      'Deal Shoveler (2-weeks)',
    ],
  },
  '[Premium] Seasonal Event Bundle': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Fresh Event Shoveler',
      'Category Enhanced Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
      'Shared Endcap',
    ],
  },
};

export default Consideration;
import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const PromotionalInfo = ({ campaignColumns, currentTab }) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Promotional Info</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlCheckbox)}>
              <FormField {...campaignField(campaignColumns.IS_DEAL)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PROMO_TYPE)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.PROMO_ID)} />
            </div>
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

PromotionalInfo.propTypes = {
  ...formCampaignColumns,
};

export default PromotionalInfo;

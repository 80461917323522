import { connect } from 'react-redux';
import moment from 'moment';
import BulletinBoardDashboardView from './BulletinBoardDashboardView';
import { BulletinBoard } from '../../../constants';
import { getSanitizedString } from '../../../helpers/bulletinBoard';
import {
  getFilteredActiveAnnouncements
} from '../../../selectors/bulletinBoard';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      campaignsLoading,
    },
    BulletinBoard: {
      announcements,
    },
  } = state;

  let announcementsList = [];
  if (announcements.length) {
    const filteredAnnouncements = getFilteredActiveAnnouncements(state);

    const maxLimit = filteredAnnouncements.length < BulletinBoard.LIMIT_MAX_ANNOUNCEMENTS
      ? filteredAnnouncements.length
      : BulletinBoard.LIMIT_MAX_ANNOUNCEMENTS;

    announcementsList = filteredAnnouncements
      .slice(0, maxLimit)
      .map((announcement) => {
        const {
          startDateTime,
          endDateTime,
          message,
        } = announcement;

        return {
          ...announcement,
          message: getSanitizedString(message),
          startDateTime: moment(startDateTime).format('DD/MM/YYYY'),
          endDateTime: moment(endDateTime).format('DD/MM/YYYY'),
        };
      });
  }

  return {
    announcements: announcementsList,
    isLoading: campaignsLoading,
    isAdmin: true,
  };
};

export default connect(mapStateToProps)(BulletinBoardDashboardView);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import Modal from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import UtilizationSlotsPanel from '../UtilizationSlotsPanel';

import { CampaignColumns } from '../../../constants';
import { utilizationOrderedSlotProp } from '../../../proptypes';

import styles from './UtilizationSlotsSettingsModal.module.scss';

const UtilizationSlotsSettingsModal = (props) => {
  const {
    orderedDesktopSlots,
    orderedMobileSlots,
    showModal,
    onCloseModal,
  } = props;
  const [currentTab, setCurrentTab] = useState(CampaignColumns.DESKTOP_SLOT.name);

  const getSlotsPanel = () => {
    if (currentTab === CampaignColumns.DESKTOP_SLOT.name) {
      return (
        <UtilizationSlotsPanel
          slots={orderedDesktopSlots}
          slotType={CampaignColumns.DESKTOP_SLOT.name}
        />
      );
    }

    return (
      <UtilizationSlotsPanel
        slots={orderedMobileSlots}
        slotType={CampaignColumns.MOBILE_SLOT.name}
      />
    );
  };

  const getContent = () => {
    const slotsPanel = getSlotsPanel();

    return (
      <>
        <TabGroup value={currentTab} onChange={setCurrentTab}>
          <Tab value={CampaignColumns.DESKTOP_SLOT.name}>
            {CampaignColumns.DESKTOP_SLOT.display}
          </Tab>
          <Tab value={CampaignColumns.MOBILE_SLOT.name}>
            {CampaignColumns.MOBILE_SLOT.display}
          </Tab>
        </TabGroup>
        <div>
          {slotsPanel}
        </div>
      </>
    );
  };

  const content = getContent();

  return (
    <>
      <Modal open={showModal}>
        <div className={styles.closeButtonContainer}>
          <Button
            onClick={onCloseModal}
            type="tertiary"
          >
            Close
          </Button>
        </div>
        {content}
      </Modal>
    </>
  );
};

UtilizationSlotsSettingsModal.propTypes = {
  orderedDesktopSlots: PropTypes.arrayOf(utilizationOrderedSlotProp).isRequired,
  orderedMobileSlots: PropTypes.arrayOf(utilizationOrderedSlotProp).isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default UtilizationSlotsSettingsModal;

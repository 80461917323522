import React from 'react';
import PropTypes from 'prop-types';
import SingleSelect from '../../StyledInputs/SingleSelect';

const BusinessGroup = ({ businessGroup, businessGroups, onChange }) => {
  return (
    <SingleSelect
      inputValue={businessGroup}
      options={businessGroups}
      onInputChange={onChange}
      size="small"
    />
  );
};

BusinessGroup.propTypes = {
  businessGroup: PropTypes.string.isRequired,
  businessGroups: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BusinessGroup;

import classnames from 'classnames';
import React from 'react';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import syncTokens from '@amzn/meridian-tokens/base/icon/sync';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';


const Placement = (props) => {
  const {
    campaignColumns,
    currentTab
  } = props;

  const campaignField = createCampaignFieldForIndex(currentTab);

  const getTrafficPlacement = () => {
    return (
      <div className={styles.formGroupContent}>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.PROGRAM)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.INITIATIVE)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.TARGET_BUSINESS)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.SEASONAL_EVENT)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.HVA)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.SOV)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.DEVICE_TYPE)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.PAGETYPE)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.PAGE_ID)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.PLACEMENT)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.DESKTOP_SLOT)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.MOBILE_SLOT)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.TARGETING_TYPE)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.TARGETING)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.TARGETING_EXCLUDE)} />
        </div>
      </div>
      <div className={styles.formGroupSection}>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.WEBLAB)} />
        </div>
        <div className={styles.formControl}>
          <FormField {...campaignField(campaignColumns.WEBLAB_TREATMENT)} />
        </div>
      </div>
    </div>
    )
  }

  const trafficPlacement = getTrafficPlacement();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Traffic Placement</div>
      <div className={styles.formGroupWrapper}>
          {trafficPlacement}
      </div>
    </div>
  );
};

export default Placement;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import styles from '../BulletinBoardForm.module.scss';

const SubmitButtons = ({ submitting }) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupLabel}>
          <span className={styles.formGroupTitle} />
          <p className={styles.formGroupDescription} />
        </div>
        <div className={styles.formGroupContent}>
          <div className={styles.buttonContainer}>
            <div className={styles.persistentButtonContainer} />
            <div className={styles.submitButtonContainer}>
              <div className={classnames(styles.wrapperInner, styles.flexRight)}>
                <Button
                  submit
                  type="primary"
                  disabled={submitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SubmitButtons.propTypes = {
  submitting: PropTypes.bool,
};

SubmitButtons.defaultProps = {
  submitting: false,
};

export default SubmitButtons;

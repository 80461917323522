import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import BasicInfo from './BasicInfo';
import {
  CampaignColumns,
  Form,
} from '../../../../constants';
import {
  isWebsiteOmnichannelViewMode,
} from '../../../../selectors/sitewide';
import {
  getInStoreCampaigns,
  isExclusiveInStorePackage,
} from '../../../../helpers/package';

const selector = formValueSelector(Form.NAME);

export const mapStateToProps = (state) => {
  const packageName = selector(state, CampaignColumns.PACKAGE_NAME.name)
  return {
    shouldShowDateTimePicker: isWebsiteOmnichannelViewMode(state),
    shouldShowStatus: isWebsiteOmnichannelViewMode(state),
    isExclusiveInStorePackage: isExclusiveInStorePackage(packageName),
    hasInStoreCampaigns: getInStoreCampaigns(selector(state, 'campaigns')).length,
  };
};

export default connect(mapStateToProps)(BasicInfo);

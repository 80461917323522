import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import PropTypes from 'prop-types';
import TimeInput from '../../StyledInputs/TimeInput';
import CheckboxGroup from '../../CheckboxGroup';
import {
  Language,
} from '../../../constants';
import styles from './InStoreUtilizationForm.module.scss';
import {
  InStoreUtilizationFilterGroupNames,
} from '../../../constants/inStoreUtilization';

const InStoreUtilizationForm = (props) => {
  const {
    filterOptions,
    selectedFilters,
    selectedDateRange,
    startTime,
    endTime,
    onSetFilter,
    onSelectDateRange,
    onStartTimeSet,
    onEndTimeSet,
    onCalculateResults,
    isDateDisabled,
    isButtonDisabled,
  } = props;

  const disabledDates = useCallback(
    isDateDisabled(selectedDateRange),
    [selectedDateRange],
  );

  const updateStartTime = (value) => onStartTimeSet(value);
  const updateEndTime = (value) => onEndTimeSet(value);

  const controls = useMemo(() => {
    const {
      PLACEMENT_TYPES, VERTICAL, PLACEMENT, STATUS,
    } = InStoreUtilizationFilterGroupNames;

    return [
      {
        name: PLACEMENT_TYPES.name,
        label: PLACEMENT_TYPES.label,
        options: filterOptions[PLACEMENT_TYPES.name],
        selectedOptions: selectedFilters[PLACEMENT_TYPES.name],
      },
      {
        name: VERTICAL.name,
        label: VERTICAL.label,
        options: filterOptions[VERTICAL.name],
        selectedOptions: selectedFilters[VERTICAL.name],
      },
      {
        name: PLACEMENT.name,
        label: PLACEMENT.label,
        options: filterOptions[PLACEMENT.name],
        selectedOptions: selectedFilters[PLACEMENT.name],
      },
      {
        name: STATUS.name,
        label: STATUS.label,
        options: filterOptions[STATUS.name],
        selectedOptions: selectedFilters[STATUS.name],
      },
    ].map((controlProps) => (
      <CheckboxGroup
        key={controlProps.name}
        onCheck={onSetFilter}
        {...controlProps}
      />
    ));
  }, [filterOptions, selectedFilters, onSetFilter]);

  const getFiltersPanel = () => (
    <div className={styles.filtersContainer}>
      {controls}
    </div>
  );

  const getDateRangePicker = () => {
    return (
      <div className={classnames(styles.control, styles.controlDateTimePicker)}>
        <div className={styles.groupContainer}>
          <div className={styles.datePicker}>
            <DateRangePicker
              value={selectedDateRange}
              onChange={onSelectDateRange}
              startLabel="Start date"
              endLabel="End date"
              monthsInView={2}
              disabledDates={disabledDates}
              clearButton
            />
          </div>
          <div className={styles.timePicker}>
            <div className={styles.startDate}>
              <TimeInput
                inputValue={startTime}
                onInputChange={updateStartTime}
              />
            </div>
            <div className={styles.endDate}>
              <TimeInput
                inputValue={endTime}
                onInputChange={updateEndTime}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getControlButtons = () => {
    return (
      <div className={classnames(styles.control, styles.controlButtons)}>
        <Button
          className={styles.showButton}
          onClick={onCalculateResults}
          disabled={isButtonDisabled}
          size="large"
        >
          {Language.UTILIZATION_SHOW_RESULTS}
        </Button>
      </div>
    );
  };

  const filtersPanel = getFiltersPanel();
  const dateRangePicker = getDateRangePicker();
  const controlButton = getControlButtons();

  return (
    <div className={styles.container}>
      {filtersPanel}
      {dateRangePicker}
      <section className={styles.controlButtonsContainer}>
        {controlButton}
      </section>
    </div>
  );
};

InStoreUtilizationForm.propTypes = {
  filterOptions: PropTypes.objectOf(PropTypes.array),
  selectedFilters: PropTypes.objectOf(PropTypes.object),
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  selectedDateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  onStartTimeSet: PropTypes.func.isRequired,
  onEndTimeSet: PropTypes.func.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  isDateDisabled: PropTypes.func.isRequired,
  onSetFilter: PropTypes.func.isRequired,
  onCalculateResults: PropTypes.func.isRequired,
};
InStoreUtilizationForm.defaultProps = {
  filterOptions: {},
  selectedFilters: {},
};

export default InStoreUtilizationForm;

import React from 'react';
import BulletinBoardForm from '../BulletinBoard/BulletinBoardForm';
import { announcementFormProps } from '../../proptypes';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const BulletinBoardFormPage = (props) => {
  const { match: { params: { id } } } = props;

  return <BulletinBoardForm isNew={false} id={id} />;
};

BulletinBoardFormPage.propTypes = announcementFormProps;

export default WithHistoryTiming(BulletinBoardFormPage, { pageName: PageNames.EDIT_ANNOUNCEMENT });

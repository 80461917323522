import { onCommentsSet } from '../../actionCreators';

export default (dispatch, getState) => {
  const { Comments: { list } } = getState();

  const sortedList = [...list].sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });

  dispatch(onCommentsSet({
    comments: sortedList,
    shouldOverride: true,
  }));
};

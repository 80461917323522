import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import cogTokens from '@amzn/meridian-tokens/base/icon/cog';
import filterTokens from '@amzn/meridian-tokens/base/icon/filter';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import PropTypes from 'prop-types';
import TimeInput from 'src/components/StyledInputs/TimeInput';
import UtilizationSlotsSettingsModal from '../UtilizationSlotsSettingsModal';
import UtilizationFilters from '../UtilizationFiltersPanel';
import UtilizationFilterTagList from '../UtilizationFilterTagList';
import {
  Language,
} from '../../../constants';
import styles from './UtilizationForm.module.scss';

const UtilizationForm = (props) => {
  const {
    selectedDateRange,
    startTime,
    startYears,
    endTime,
    isButtonDisabled,
    isAggregateByWeeksMode,
    isSettingsButtonAvailable,
    isDateDisabled,
    isCalendarViewMode,
    shouldShowViewModeSwitch,
    onSelectDateRange,
    onStartTimeSet,
    onEndTimeSet,
    onShowResults,
    onSettingsClick,
  } = props;

  const filterPanelButtonRef = useRef();
  const [isFiltersPanelOpen, setFiltersPanelOpen] = useState(false);

  const showFiltersPanel = (isOpen) => () => setFiltersPanelOpen(isOpen);

  const disabledDates = useCallback(
    isDateDisabled(
      isAggregateByWeeksMode,
      selectedDateRange,
      startYears,
    ),
    [isAggregateByWeeksMode, selectedDateRange, startYears],
  );

  const updateStartTime = (value) => {
    onStartTimeSet(value);
  };
  const updateEndTime = (value) => onEndTimeSet(value);

  const getDateRangePickerControl = () => {
    return (
      <DateRangePicker
        value={selectedDateRange}
        onChange={onSelectDateRange}
        startLabel="Start date"
        endLabel="End date"
        monthsInView={2}
        disabledDates={disabledDates}
        clearButton
      />
    );
  };

  const getTimePickerControl = () => {
    return (
      <div className={styles.timePicker}>
        <div className={styles.startDate}>
          <TimeInput
            inputValue={startTime}
            onInputChange={updateStartTime}
          />
        </div>
        <div className={styles.endDate}>
          <TimeInput
            inputValue={endTime}
            onInputChange={updateEndTime}
          />
        </div>
      </div>
    );
  };

  const getDateTimeFilters = () => {
    const dateRangePickerControl = getDateRangePickerControl();
    const timePickerControl = getTimePickerControl();

    return (
      <div className={styles.dateTimeControls}>
        {dateRangePickerControl}
        {timePickerControl}
      </div>
    );
  };

  const getShowResultsButton = () => (
    <Button
      onClick={onShowResults}
      disabled={isButtonDisabled}
      size="large"
    >
      {Language.UTILIZATION_SHOW_RESULTS}
    </Button>
  );

  const getShowFiltersButton = () => {
    return (
      <Button
        ref={filterPanelButtonRef}
        onClick={showFiltersPanel(true)}
        size="large"
        type="secondary"
      >
        <Icon tokens={filterTokens}>
          {Language.SHOW_FILTERS_BUTTON}
        </Icon>
      </Button>
    );
  };

  const getActionButtons = () => {
    const showFiltersButton = getShowFiltersButton();
    const showResultsButton = getShowResultsButton();

    return (
      <div className={classnames(styles.control, styles.actionButtons)}>
        {showFiltersButton}
        {showResultsButton}
      </div>
    );
  };

  const getSettingsButton = () => {
    const isDisabled = !isSettingsButtonAvailable
      || (shouldShowViewModeSwitch && isCalendarViewMode);

    return (
      <div className={styles.slotsSettingsButtonContainer}>
        <Tooltip position="bottom" title={Language.UTILIZATION_SLOTS_SETTINGS_BUTTON_TOOLTIP}>
          <Button
            onClick={onSettingsClick}
            type="tertiary"
            disabled={isDisabled}
          >
            <Icon tokens={cogTokens}>{Language.UTILIZATION_SLOTS_SETTINGS_BUTTON_LABEL}</Icon>
          </Button>
        </Tooltip>
      </div>
    );
  };

  const getAllFiltersPanel = () => (
    <UtilizationFilters
      isOpen={isFiltersPanelOpen}
      onClose={showFiltersPanel(false)}
      buttonRef={filterPanelButtonRef}
    />
  );

  const allFiltersPanel = getAllFiltersPanel();
  const dateTimeFilters = getDateTimeFilters();
  const settingsButton = getSettingsButton();
  const actionButtons = getActionButtons();

  return (
    <div className={styles.container}>
      {allFiltersPanel}
      {settingsButton}
      <UtilizationSlotsSettingsModal />
      <section className={styles.content}>
        {dateTimeFilters}
        {actionButtons}
      </section>
      <section className={styles.content}>
        <UtilizationFilterTagList />
      </section>
    </div>
  );
};

UtilizationForm.propTypes = {
  startTime: PropTypes.string.isRequired,
  startYear: (PropTypes.number | PropTypes.string).isRequired,
  endTime: PropTypes.string.isRequired,
  selectedDateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  onStartTimeSet: PropTypes.func.isRequired,
  onEndTimeSet: PropTypes.func.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  isAggregateByWeeksMode: PropTypes.bool.isRequired,
  isSettingsButtonAvailable: PropTypes.bool.isRequired,
  isDateDisabled: PropTypes.func.isRequired,
  isCalendarViewMode: PropTypes.bool,
  shouldShowViewModeSwitch: PropTypes.bool,
  onShowResults: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
};

UtilizationForm.defaultProps = {
  isCalendarViewMode: false,
  shouldShowViewModeSwitch: false,
};

export default UtilizationForm;

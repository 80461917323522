import { connect } from 'react-redux';

import {
  onMetadataEditorSetQuickCreateModalVisibility,
  onMetadataEditoQuickCreateModalSave,
} from '../../../actionCreators';

import QuickCreateModal from './QuickCreateModal';

export const mapStateToProps = (state) => {
  const {
    MetadataEditor: { quickCreateModalVisible },
    Meta: { metadata: { fields } },
  } = state;
  return {
    isOpen: quickCreateModalVisible,
    fields,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onModalClose() { dispatch(onMetadataEditorSetQuickCreateModalVisibility(false)); },
    onSave(data) {
      dispatch(onMetadataEditoQuickCreateModalSave(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickCreateModal);

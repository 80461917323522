import { TimerActions } from '../../constants';
import window from '../../helpers/window';

export const initialState = {
  timer: null,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case TimerActions.START_TIMER: {
      return {
        ...state,
        timer: window.performance.now(),
      };
    }
    case TimerActions.COMPLETE_TIMER: {
      return {
        ...state,
        timer: null,
      };
    }
    case TimerActions.CANCEL_TIMER: {
      return {
        ...state,
        timer: null,
      };
    }
    default: {
      return state;
    }
  }
};

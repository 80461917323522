import {
  localToAppsync,
  getExcludedFields,
  formatPackageForMutationInput,
} from '../../helpers/translateCampaigns';
import {
  Language,
  MetricLocations,
  MetricNames,
  MetricTypes
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import {
  onDeleteCampaign,
  onOpenToast
} from '../../actionCreators';
import { getUsername } from '../../selectors/user';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutatations from '../../graphql/mutations';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const username = getUsername(state);

  const {
    payload: {
      currentPackage,
      options,
    }
  } = action;

  const { campaigns } = currentPackage;
  const { isBulkAction } = options;

  const packageToSend = localToAppsync(currentPackage);

  getExcludedFields(['campaigns'])
    .forEach((excludedField) => {
      delete packageToSend[excludedField];
    });

  const dataToSend = {
    input: {
      package: formatPackageForMutationInput(packageToSend),
      username,
    },
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: dataToSend,
    action: MetricNames.DELETE_PACKAGE,
    username,
  };

  submitStringMetric(MetricNames.DELETE_PACKAGE, metricContext);

  const deletePackage = (params) => {
    return API.graphql(graphqlOperation(mutatations.deletePackage, params));
  }

  const deleteCampaigns = (campaigns) => {
    if (!campaigns || !campaigns.length) {
      return Promise.resolve();
    }

    campaigns.forEach(campaign => {
      dispatch(onDeleteCampaign(campaign, { isBulkAction: true }));
    });

    return Promise.resolve();
  };

  const onError = (error) => {
    submitAppSyncError(error, metricContext);

    if (!isBulkAction) {
      dispatch(onOpenToast(Language.GENERIC_ERROR));
    }
  }

  deleteCampaigns(campaigns)
    .then(() => deletePackage(dataToSend))
    .catch(onError);

  return next(action);
};

import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import {
  getStartDatePickerPresets,
  getTrafficEndDatePickerPresets,
} from '../../../../../helpers/dateTime';
import { createCampaignFieldForIndex } from 'src/helpers/form';


const BasicInfo = ({
  currentTab,
  campaignColumns,
  startDate,
  isFreshIntTraffic,
}) => {

  const campaignField = createCampaignFieldForIndex(currentTab);

  const getTrafficBasicInfo = () => {
    return (
      <div className={styles.formGroupContent}>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.STATUS} />
          </div>          
          <div className={styles.formControl}>
            <FormField {...campaignColumns.MARKETING_MANAGER} />
          </div>
        </div>
        <>
          {isFreshIntTraffic && (
            <div className={styles.formGroupSection}>
              <div className={styles.formControl}>
                <FormField {...campaignColumns.BRAND} />
              </div>
              <div className={styles.formControl}>
                <FormField {...campaignField(campaignColumns.COUNTRY)} />
              </div>
            </div>
          )}
        </>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...campaignColumns.START_DATE}
              presets={getStartDatePickerPresets()}
            />
          </div>
          <div className={styles.formControl}>
            <FormField
              {...campaignColumns.END_DATE}
              presets={getTrafficEndDatePickerPresets(startDate)}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.START_TIME} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.END_TIME} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.TIMEZONE_ID} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignColumns.MARKETING_MANAGER_NOTES} />
          </div>
        </div>
      </div>
    )
  }

  const trafficBasicInfo = getTrafficBasicInfo();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Basic info</div>
      <div className={styles.formGroupWrapper}>
        {trafficBasicInfo}
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Button from '@amzn/meridian/button';
import Heading from '@amzn/meridian/heading';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import plusCircleTokens from '@amzn/meridian-tokens/base/icon/plus-circle';
import RateCardListButtons from '../RateCardListButtons';
import { rateCardProps } from '../../proptypes';
import styles from './RateCardList.module.scss';
import RateCardListPaginator from '../RateCardListPaginator';
import RateCardSearch from '../RateCardSearch';
import { Language, Urls } from '../../constants';
import { RateCardHeadersNames } from '../../constants/rateCard';
import RateCardConfigSelector from '../RateCardConfigSelector';

const RateCardList = (props) => {
  const {
    cards,
    onLoad,
    showPagination,
    showNewRateCardButton,
  } = props;

  const history = useHistory();

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const onAddNewRateCard = () => {
    history.push(Urls.RATE_CARD_NEW);
  };

  const getHeaderRow = () => {
    const headerCells = RateCardHeadersNames.map((headerRow) => {
      return <TableCell key={headerRow}>{headerRow}</TableCell>;
    });
    return (
      <TableRow
        highlightOnHover
      >
        {headerCells}
      </TableRow>
    );
  };

  const getRows = () => {
    if (!cards.length) {
      const columnSpan = RateCardHeadersNames.length;

      return (
        <TableRow
          highlightOnHover
        >
          <TableCell columnSpan={columnSpan}>No cards to show. yet.</TableCell>
        </TableRow>
      );
    }

    return cards.map((card) => {
      const {
        vendor,
        marketingManager,
        note,
        isAssetPublished,
        id,
      } = card;

      const isPublished = isAssetPublished ? 'Yes' : 'No';

      return (
        <TableRow
          key={id}
          highlightOnHover
        >
          <TableCell>
            <RateCardListButtons rateCard={card} />
          </TableCell>
          <TableCell>{vendor}</TableCell>
          <TableCell>{marketingManager}</TableCell>
          <TableCell>{note}</TableCell>
          <TableCell>{isPublished}</TableCell>
        </TableRow>
      );
    });
  };

  const getNewRateCardButton = () => {
    if (!showNewRateCardButton) {
      return null;
    }

    return (
      <Button
        type="icon"
        onClick={onAddNewRateCard}
        size="small"
        className={styles.buttonAddNew}
      >
        <Icon tokens={plusCircleTokens}>New Rate Card</Icon>
      </Button>
    );
  };

  const getHeader = () => {
    const newRateCardButton = getNewRateCardButton();

    return (
      <div className={styles.headerContainer}>
        <div className={styles.headerMain}>
          <Heading level={2} className={styles.title}>Rate Card List</Heading>
          {newRateCardButton}
        </div>
        <div className={styles.configSelector}>
          <Tooltip
            position="bottom"
            title={Language.RATE_CARD_CONFIG_SELECTOR_MESSAGE}
          >
            <span className={styles.configSelectorTitle}>
              {Language.RATE_CARD_CONFIG_SELECTOR_NAME}
              :
            </span>
          </Tooltip>
          <RateCardConfigSelector />
        </div>
      </div>
    );
  };

  const getPagination = () => {
    if (!showPagination) {
      return null;
    }

    return (
      <div className={styles.pagination}>
        <RateCardListPaginator />
      </div>
    );
  };

  const getCardsList = () => {
    const headerRow = getHeaderRow();
    const rows = getRows();

    return (
      <div className={styles.cardListContainer}>
        <Table
          headerRows={1}
          showDividers
          showStripes
        >
          {headerRow}
          {rows}
        </Table>
      </div>
    );
  };

  const header = getHeader();
  const cardsList = getCardsList();
  const pagination = getPagination();

  return (
    <div className={styles.container}>
      {header}
      <RateCardSearch />
      {cardsList}
      {pagination}
    </div>
  );
};

RateCardList.propTypes = {
  cards: PropTypes.arrayOf(rateCardProps).isRequired,
  onLoad: PropTypes.func.isRequired,
  showPagination: PropTypes.bool.isRequired,
  showNewRateCardButton: PropTypes.bool.isRequired,
};

RateCardList.defaultTypes = {
  showPagination: false,
  showNewRateCardButton: true,
};

export default RateCardList;

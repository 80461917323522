import { connect } from 'react-redux';
import {
  onCreateCsCampaignSetCampaignData,
} from '../../../actionCreators';

import MarketingType from './MarketingType';

export const mapStateToProps = (state) => {
  const { CreateSymphonyCsCampaign: { creativeData: { intent = '' }, intents } } = state;
  return {
    intent,
    intents,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onChange(intent) {
      dispatch(onCreateCsCampaignSetCampaignData({ creativeData: { intent } }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingType);

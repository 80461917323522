import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import App from './App';
import CognitoAuth from './CognitoAuth';
import { MetricNames } from '../constants';
import store from '../store';
import { submitTimerMetric } from '../metrics';
import config from '../config/cognito';
import { getDeployedLocation, isProduction } from '../helpers/env';
import window from '../helpers/window';
import { updatePageTitle } from '../helpers/common';

Amplify.configure(config);

const AppWrapper = () => {
  useEffect(() => {
    const timeToLoadPage = new Date() - window.f3.t;
    const timer = window.performance.timing;
    submitTimerMetric(MetricNames.PAGELOAD, timeToLoadPage);
    setTimeout(() => {
      submitTimerMetric(
        MetricNames.CLICK_TO_CONTENT_INTERACTIVE,
        timer.domInteractive - timer.navigationStart,
      );
    }, 10);

    if (!isProduction()) {
      updatePageTitle(`${document.title} (${getDeployedLocation()})`);
    }
  }, []);

  return (
    <Provider store={store}>
      <CognitoAuth>
        <App />
      </CognitoAuth>
    </Provider>
  );
};

export default AppWrapper;

import _ from 'lodash';

import {
  KEYPRESS,
  MovementDirections,
  KeyboardKeys,
} from '../../constants';

import {
  onResetActiveCell,
  onMoveActiveCell,
} from '../../actionCreators';

export default (store) => (next) => (action) => {
  const { dispatch } = store;
  const relevantActions = [
    KEYPRESS,
  ];

  if (!_.includes(relevantActions, action.type)) {
    return next(action);
  }
  // handle keyboard presses here
  const { data: { key, altKey, shiftKey } } = action;

  if (altKey) { return null; }

  switch (key) {
    case KeyboardKeys.ESCAPE: {
      return dispatch(onResetActiveCell());
    }
    case KeyboardKeys.TAB: {
      return shiftKey
        ? dispatch(onMoveActiveCell(MovementDirections.LEFT))
        : dispatch(onMoveActiveCell(MovementDirections.RIGHT));
    }
    default: {
      return next(action);
    }
  }
};

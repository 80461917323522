import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';

import Column from '../column';

import SingleSelect from '../../StyledInputs/SingleSelect';

import styles from '../MetadataEditor.module.scss';

const MetadataEditor = ({
  fields,
  selectedField,
  dependencies,
  onSelectField,
  onOpenQuickCreateModal,
}) => {
  const fieldNames = Object.keys(fields);

  const columns = dependencies.map((dependency, i) => {
    return (
      <Column
        key={dependency.name}
        dependency={dependency}
        dependencies={dependencies.slice(0, i)}
      />
    );
  });

  return (
    <>
      <div className={styles.fieldSelector}>
        <SingleSelect
          options={fieldNames}
          onInputChange={onSelectField}
          inputValue={selectedField}
          size="small"
        />
      </div>
      <div className={styles.fieldSelector}>
        <Button
          onClick={onOpenQuickCreateModal}
          size="small"
        >
          Quick Create New Page
        </Button>
      </div>
      <div className={styles.columnWrapper}>
        {columns}
      </div>
    </>
  );
};

MetadataEditor.propTypes = {
  fields: PropTypes.objectOf(PropTypes.object).isRequired,
  selectedField: PropTypes.string,
  dependencies: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    selection: PropTypes.string,
  })).isRequired,
  onSelectField: PropTypes.func.isRequired,
  onOpenQuickCreateModal: PropTypes.func.isRequired,
};

MetadataEditor.defaultProps = {
  selectedField: '',
};

export default MetadataEditor;

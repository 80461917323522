import { connect } from 'react-redux';
import UtilizationSlotsSettingsModal from './UtilizationSlotsSettingsModal';
import { CampaignColumns } from '../../../constants';
import { getSlotsOrder } from '../../../helpers/utilization';
import { onUtilizationToggleSlotsSettings } from '../../../actionCreators';

export const mapStateToProps = (state) => {
  const {
    Sitewide: { selectedBusiness },
    UtilizationPage: {
      slotsOrder,
      selectedFilters: {
        pageType,
      },
      isSlotsSettingsOpen,
    },
  } = state;

  const orderedDesktopSlots = getSlotsOrder(
    slotsOrder,
    selectedBusiness,
    pageType,
    CampaignColumns.DESKTOP_SLOT.name,
  );
  const orderedMobileSlots = getSlotsOrder(
    slotsOrder,
    selectedBusiness,
    pageType,
    CampaignColumns.MOBILE_SLOT.name,
  );

  return {
    orderedDesktopSlots,
    orderedMobileSlots,
    showModal: isSlotsSettingsOpen,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal() {
      dispatch(onUtilizationToggleSlotsSettings());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationSlotsSettingsModal);

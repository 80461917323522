import React from 'react';
import Icon from '@amzn/meridian/icon';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import deleteTokens from '@amzn/meridian-tokens/base/icon/close-circle';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { filterViewProps } from '../../../proptypes';
import { Language } from '../../../constants';

import GridViewFilterControlMenuItem from '../GridViewFilterControlMenuItem';

import styles from './GridViewFilterControlMenu.module.scss';
import sitewideStyles from '../../../styles/sitewide.module.scss';

const GridViewFilterControlMenu = ({
  filterViews,
  activeFilterView,
  setActiveFilterView,
  setIsOpen,
  openViewModal,
  deleteViewAction,
  activeViewData,
  openCustomViewModal,
}) => {
  const onClick = (value) => {
    setIsOpen(false);
    setActiveFilterView(value);
  };

  const openEditModal = (evt, view) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsOpen(false);
    openViewModal(view);
  };

  const deleteView = (evt, view) => {
    evt.preventDefault();
    evt.stopPropagation();
    /* eslint-disable-next-line no-alert */
    if (window.confirm('Are you sure you want to delete this view? This action cannot be undone.')) {
      deleteViewAction(view.name);
    }
  };

  const icons = (view) => {
    return (
      <div className={styles.iconTray}>
        <button
          onClick={(evt) => { openEditModal(evt, view); }}
          className={classnames(sitewideStyles.buttonAsLink, styles.iconButton)}
          type="button"
          data-testid="open"
        >
          <Icon tokens={editTokens} className={sitewideStyles.svgIcon} />
        </button>
        <button
          onClick={(evt) => { deleteView(evt, view); }}
          className={classnames(sitewideStyles.buttonAsLink, styles.iconButton)}
          type="button"
          data-testid="delete"
        >
          <Icon tokens={deleteTokens} className={sitewideStyles.svgIcon} />
        </button>
      </div>
    );
  };

  const clearFilterView = (
    <GridViewFilterControlMenuItem
      onClick={() => { onClick(null); }}
      data-testid="clear"
    >
      Show all
    </GridViewFilterControlMenuItem>
  );

  const viewMenuItems = filterViews.map((view, index) => {
    return (
      <GridViewFilterControlMenuItem
        onClick={() => {
          onClick(filterViews[index].name);
        }}
        key={view.name || 'default'}
        isActive={view.name === activeFilterView}
      >
        <div className={styles.itemName}>{view.name}</div>
        {icons(view)}
      </GridViewFilterControlMenuItem>
    );
  });

  const openModalMenuItem = (
    <GridViewFilterControlMenuItem
      onClick={() => {
        setIsOpen(false);
        openViewModal(activeViewData);
      }}
    >
      Save/Edit current view
    </GridViewFilterControlMenuItem>
  );

  const customViewToggle = (
    <GridViewFilterControlMenuItem
      onClick={() => {
        setIsOpen(false);
        openCustomViewModal();
      }}
    >
      {Language.EDIT_VISIBLE_COLUMNS}
    </GridViewFilterControlMenuItem>
  );

  return (
    <ul className={styles.menu}>
      {viewMenuItems}
      {openModalMenuItem}
      {clearFilterView}
      {customViewToggle}
    </ul>
  );
};

GridViewFilterControlMenu.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  filterViews: PropTypes.arrayOf(filterViewProps).isRequired,
  setActiveFilterView: PropTypes.func.isRequired,
  activeFilterView: PropTypes.string,
  openViewModal: PropTypes.func.isRequired,
  deleteViewAction: PropTypes.func.isRequired,
  activeViewData: PropTypes.shape({
    filters: PropTypes.array,
    sorts: PropTypes.array,
  }).isRequired,
  openCustomViewModal: PropTypes.func.isRequired,
};

GridViewFilterControlMenu.defaultProps = {
  activeFilterView: '',
};

export default GridViewFilterControlMenu;

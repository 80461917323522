import { connect } from 'react-redux';
import RateCardAssetsList from './RateCardAssetsList';
import { getPaginatedAssetsList, hasPublishedAssets } from '../../selectors/rateCardSelectors';

export const mapStateToProps = (state, { rateCardId }) => {
  const { BulkImport: { isProcessed } } = state;

  return {
    assets: getPaginatedAssetsList(state),
    shouldShowNewAssetsList: isProcessed,
    isReadOnlyMode: hasPublishedAssets(state, rateCardId),
  };
};

export default connect(mapStateToProps)(RateCardAssetsList);

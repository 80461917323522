import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import CalendarRecord from './CalendarRecord';
import { CalendarRecordConfig } from '../../constants/calendar';
import { CalendarPlacementProps } from '../../proptypes';

const CalendarRowRecords = (props) => {
  const {
    calendarStartDate,
    calendarEndDate,
    timezone,
    cellWidth,
    placements,
    date,
    visible,
    onClick,
  } = props;

  const getPlacementStartDate = useCallback((placementStartTime) => {
    const startOfSearchDay = calendarStartDate.startOf('day');

    if (!placementStartTime) {
      return startOfSearchDay;
    }

    const placementStartDateTime = DateTime.fromISO(placementStartTime).setZone(
      timezone,
    );

    return startOfSearchDay >= placementStartDateTime
      ? startOfSearchDay
      : placementStartDateTime;
  });

  const getStartPosition = (givenDate) => {
    const startOfDay = calendarStartDate.startOf('day');

    if (startOfDay >= givenDate) {
      return 0;
    }

    const startHour = (givenDate.hour * cellWidth) / 24;
    const startMinute = (givenDate.minute * cellWidth) / (24 * 60);

    return startHour + startMinute;
  };

  const getWidth = (placement) => {
    const eachDayWidth = cellWidth;

    const startDate = getPlacementStartDate(placement.startTime?.[0]);

    const calendarEndDateTime = calendarEndDate;

    const placementEndDateTime = DateTime.fromISO(
      placement.endTime?.[0]
        ?? calendarEndDateTime.endOf('day').setZone(timezone).toISO(),
    ).setZone(timezone);

    const endDate = calendarEndDateTime.valueOf() < placementEndDateTime.valueOf()
      ? calendarEndDateTime.endOf('day') // Round until end of calendar day
      : placementEndDateTime;

    const days = parseFloat(
      (endDate.diff(startDate, 'minutes').minutes / (24 * 60)).toFixed(2),
    );

    return eachDayWidth * days;
  };

  const recordRows = useMemo(() => {
    return placements.map((placement, index) => (
      <React.Fragment key={placement.id}>
        {date.startOf('day').valueOf()
          === getPlacementStartDate(placement?.startTime?.[0])
            .startOf('day')
            .valueOf()
          && cellWidth !== 0 && (
            <CalendarRecord
              key={placement.id}
              placement={placement}
              index={index}
              recordWidth={getWidth(placement)}
              startPosition={getStartPosition(
                getPlacementStartDate(placement?.startTime?.[0]),
              )}
              onClick={onClick}
            />
        )}
      </React.Fragment>
    ));
  }, [placements, date, getPlacementStartDate, cellWidth, getWidth, getStartPosition, onClick]);

  const rowHeight = !visible
    ? 0
    : (
      placements.length
      * CalendarRecordConfig.CALENDAR_RECORD_HEIGHT + CalendarRecordConfig.CALENDAR_RECORD_MARGIN
    );

  return (
    <div
      style={{
        height: rowHeight,
      }}
    >
      {visible && recordRows}
    </div>
  );
};

CalendarRowRecords.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarStartDate: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  calendarEndDate: PropTypes.object.isRequired,
  timezone: PropTypes.string.isRequired,
  cellWidth: PropTypes.number.isRequired,
  placements: PropTypes.arrayOf(CalendarPlacementProps).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  date: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CalendarRowRecords;

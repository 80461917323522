import VSSMBundles from './VSSM';
import Awareness from './Awareness';
import Conversion from './Conversion';
import Consideration from './Consideration';

const Bundles = {
  ...Awareness,
  ...Conversion,
  ...Consideration,
  ...VSSMBundles,
};

export default Bundles;

/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@amzn/meridian/checkbox';
import ControlGroup from '@amzn/meridian/control-group';

const CheckboxGroup = (props) => {
  const {
    name,
    label,
    options,
    selectedOptions,
    onCheck,
    showCheckAll,
  } = props;

  const onClickCheckbox = (groupName, optionName) => (isChecked) => {
    onCheck(groupName, optionName, isChecked);
  };

  const onCheckAll = (groupName, isChecked) => () => {
    options.forEach(({ value }) => {
      onClickCheckbox(groupName, value)(!isChecked);
    });
  };

  const getCheckboxControl = (name, label, isChecked, onChange) => (
    <Checkbox
      key={name}
      checked={isChecked}
      name={name}
      onChange={onChange}
    >
      {label}
    </Checkbox>
  );

  if (!options || !options.length) {
    return null;
  }

  const getOptionList = () => {
    const optionList = options.map(({ value, label }) => {
      const isChecked = selectedOptions ? selectedOptions[value] : false;

      return getCheckboxControl(
        value,
        label,
        isChecked,
        onClickCheckbox(name, value),
      );
    });

    if (showCheckAll) {
      const isAllChecked = options.every(({ value }) => {
        return !selectedOptions ? false : selectedOptions[value];
      });

      optionList.unshift(getCheckboxControl(
        'checkAll',
        'All',
        isAllChecked,
        onCheckAll(name, isAllChecked),
      ));
    }

    return optionList;
  };

  const optionList = getOptionList();

  return (
    <ControlGroup
      label={label}
      name={`${name}[]`}
    >
      {optionList}
    </ControlGroup>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onCheck: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })),
  selectedOptions: PropTypes.objectOf(PropTypes.bool),
  showCheckAll: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
  options: [],
  selectedOptions: {},
  showCheckAll: true,
};

export default CheckboxGroup;

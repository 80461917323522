import { connect } from 'react-redux';
import UtilizationOnlineCalendar from './UtilizationOnlineCalendar';
import { onUtilizationSetSlotsViewMode } from '../../../actionCreators';
import {
  UtilizationSlotsOrderByPageType,
  UtilizationSlotsViewMode,
} from '../../../constants/utilization';
import { sortBySlotPositionAndNumber } from '../../../helpers/utilization';

const getSortedGroupedPlacements = (groupedPlacements, pageType) => {
  if (!pageType || !groupedPlacements || !Object.keys(groupedPlacements).length) {
    return [];
  }

  const convertedPlacements = Object.entries(groupedPlacements);
  const customSlotOrder = UtilizationSlotsOrderByPageType[pageType];
  if (!customSlotOrder) {
    return convertedPlacements;
  }

  return convertedPlacements.sort(sortBySlotPositionAndNumber(customSlotOrder));
};

export const mapStateToProps = (state) => {
  const {
    Sitewide: {
      showLoader,
    },
    UtilizationPage: {
      slotUtilization: {
        groupedDesktopPlacements,
        groupedMobilePlacements,
      },
      selectedDateRange,
      selectedFilters: {
        pageType,
      },
      slotsViewMode,
    },
  } = state;

  const [startTime, endTime] = selectedDateRange;

  const sortedGroupedPlacements = slotsViewMode === UtilizationSlotsViewMode.DESKTOP.name
    ? getSortedGroupedPlacements(groupedDesktopPlacements, pageType)
    : getSortedGroupedPlacements(groupedMobilePlacements, pageType);

  return {
    slotsViewMode,
    groupedPlacements: sortedGroupedPlacements,
    showLoader,
    startTime,
    endTime,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSwitchSlotsViewMode: (slotsViewMode) => {
      dispatch(onUtilizationSetSlotsViewMode(slotsViewMode));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationOnlineCalendar);

import _ from 'lodash';
import { Form } from '../../constants';
import {
  arrayPush,
  arrayRemoveAll,
  formValueSelector,
} from 'redux-form';
import {
  getSecondaryLanguageUniqueColumns,
  getFirstOnlineCampaign,
  getCampaignSyncupExcludedColumns,
} from '../../helpers/campaign';

const getMergedCampaign = (targetCampaign, currentCampaign) => {
  const newCampaign = _.cloneDeep(targetCampaign);

  [
    ...getSecondaryLanguageUniqueColumns(),
    ...getCampaignSyncupExcludedColumns(),
  ].forEach(secondaryLanguageUniqueColumn => {
    if (newCampaign.hasOwnProperty(secondaryLanguageUniqueColumn)) {
      newCampaign[secondaryLanguageUniqueColumn] = currentCampaign[secondaryLanguageUniqueColumn];
    }
    if (newCampaign.hasOwnProperty(secondaryLanguageUniqueColumn)) {
      newCampaign[secondaryLanguageUniqueColumn] = currentCampaign[secondaryLanguageUniqueColumn];
    }
  });

  return newCampaign;
};

const selector = formValueSelector(Form.NAME);
export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { tabIndex } } = action;

  const campaigns = selector(state, 'campaigns') || [];
  if (!campaigns || !campaigns.length) {
    return next(action);
  }

  const firstOnlineCampaign = getFirstOnlineCampaign(campaigns);
  if (!firstOnlineCampaign) {
    return next(action);
  }

  const updatedCampaigns = campaigns.map((campaign, index) => {
    if (index !== tabIndex) {
      return campaign;
    }

    return getMergedCampaign(
      firstOnlineCampaign,
      campaigns[tabIndex]
    );
  });

  dispatch(arrayRemoveAll(Form.NAME, 'campaigns'));
  updatedCampaigns.forEach(updatedCampaign => {
    dispatch(arrayPush(Form.NAME, 'campaigns', updatedCampaign));
  });

  return next(action);
}
import { UtilizationActions } from '../../constants';
import { UtilizationSlotsViewMode, UtilizationViewMode } from '../../constants/utilization';

export const initialState = {
  filterOptions: {},
  selectedFilters: {},
  selectedDateRange: [],
  startTime: '00:00',
  endTime: '00:00',
  selectedTimezone: '',
  slotUtilization: {},
  daysInDateRange: [],
  isFiltersModalOpen: false,
  isAggregateByWeeksMode: true,
  isAbsStatsValuesOnly: true,
  slotsOrder: {},
  isSlotsSettingsOpen: false,
  showFmcSlots: false,
  viewMode: UtilizationViewMode.CALENDAR.name,
  slotsViewMode: UtilizationSlotsViewMode.DESKTOP.name,
};

/* eslint-disable no-param-reassign */
const clearChildrenFilters = (filter, selectedFilters) => {
  if (filter && filter.children && filter.children.length > 0) {
    filter.children.forEach((child) => {
      selectedFilters[child] = '';
    });
  }
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  const { type, data } = action;

  switch (type) {
    case UtilizationActions.FILTER_OPTIONS_CREATED: {
      return {
        ...state,
        filterOptions: data,
      };
    }
    case UtilizationActions.BUSINESS_CHANGED: {
      return {
        ...state,
        selectedFilters: {},
      };
    }
    case UtilizationActions.FILTER_CHANGE: {
      const { selectedFilters } = state;
      clearChildrenFilters(data.filterOptionValue, selectedFilters);
      selectedFilters[data.filterOptionKey] = data.selectedFilter;
      return {
        ...state,
        selectedFilters,
      };
    }
    case UtilizationActions.SELECT_DATE_RANGE: {
      return {
        ...state,
        selectedDateRange: data,
      };
    }
    case UtilizationActions.SELECT_START_TIME: {
      const { startTime } = data;
      return {
        ...state,
        startTime,
      };
    }
    case UtilizationActions.SELECT_END_TIME: {
      const { endTime } = data;
      return {
        ...state,
        endTime,
      };
    }
    case UtilizationActions.TOGGLE_STATS_MODE: {
      return {
        ...state,
        isAggregateByWeeksMode: !state.isAggregateByWeeksMode,
      };
    }
    case UtilizationActions.TOGGLE_ABS_SOV_MODE: {
      return {
        ...state,
        isAbsStatsValuesOnly: !state.isAbsStatsValuesOnly,
      };
    }
    case UtilizationActions.SELECT_TIMEZONE: {
      return {
        ...state,
        selectedTimezone: data,
      };
    }
    case UtilizationActions.SET_RESULTS: {
      const { slotUtilization, daysInDateRange } = data;

      return {
        ...state,
        slotUtilization,
        daysInDateRange,
      };
    }
    case UtilizationActions.OPEN_FILTERS_MODAL: {
      return {
        ...state,
        isFiltersModalOpen: true,
      };
    }
    case UtilizationActions.CLOSE_FILTERS_MODAL: {
      return {
        ...state,
        isFiltersModalOpen: false,
      };
    }
    case UtilizationActions.TOGGLE_SLOTS_ORDER_SETTINGS: {
      return {
        ...state,
        isSlotsSettingsOpen: !state.isSlotsSettingsOpen,
      };
    }
    case UtilizationActions.SET_SLOTS_ORDER: {
      const {
        business,
        pageType,
        slots,
        slotType,
      } = data;

      const newValue = state.slotsOrder[business] && state.slotsOrder[business][pageType]
        ? {
          ...state.slotsOrder[business][pageType],
          [slotType]: slots,
        }
        : {
          [slotType]: slots,
        };

      return {
        ...state,
        slotsOrder: {
          ...state.slotsOrder,
          [business]: {
            ...state.slotsOrder[business],
            [pageType]: newValue,
          },
        },
      };
    }
    case UtilizationActions.SWITCH_VIEW_MODE: {
      const { payload: { viewMode } } = action;

      return {
        ...state,
        viewMode,
      };
    }
    case UtilizationActions.SHOW_FMC_SLOTS: {
      const { payload: { flag } } = action;

      return {
        ...state,
        showFmcSlots: flag,
      };
    }
    case UtilizationActions.SET_SLOTS_VIEW_MODE: {
      const { payload: { slotsViewMode } } = action;

      return {
        ...state,
        slotsViewMode,
      };
    }
    default: {
      return state;
    }
  }
};

import {
  CampaignColumns,
  Delimiters,
  InputTypes,
} from '../../constants';
import { getRateCardAssetsList } from '../../selectors/rateCardSelectors';
import { getRateCardExportColumns } from '../../helpers/rateCard';
import createColumn from '../../helpers/createColumn';

const formatString = (string) => {
  // In the CSV spec, a double quote character is escaped by a second double quote character
  const stringToParse = `${string}` || '';
  return stringToParse.replace(/"/g, '""');
};

const extractCampaignString = (campaigns, columnOrder, delimeter, allowedColumns = {}) => {
  const columnString = columnOrder
    .filter((column) => {
      return allowedColumns[column.name] && column.name !== CampaignColumns.REF_MARKER.name;
    })
    .map((column) => {
      return `"${formatString(column.display)}"`;
    }).join(delimeter);

  const campaignString = campaigns.map((campaign) => {
    return columnOrder
      .filter((column) => {
        return allowedColumns[column.name] && column.name !== CampaignColumns.REF_MARKER.name;
      })
      .map((column) => {
        let value = campaign[column.name];
        if (Array.isArray(value)) {
          value = value.join(', ');
        }
        if (value === undefined) {
          value = '';
        }
        return `"${formatString(value)}"`;
      }).join(delimeter);
  }).join(Delimiters.NEWLINE);

  return `${columnString}${Delimiters.NEWLINE}${campaignString}`;
};

const sendCsv = (data) => {
  const link = document.createElement('a');
  // Prepending a byte order mask sequence at the beginning of the content,
  // so that browsers detect it as UTF-8 (Safari, Chrome).
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  // Setting content type to UTF-8 explicitly (Opera, FF)
  const blob = new Blob([bom, data], { encoding: 'UTF-8', type: 'text/csv;charset=UTF-8' });
  const url = window.URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'exported_assets.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default (dispatch, getState, action, next) => {
  const { payload: { reportType } } = action;
  const { Meta: { metadata: { columnOrder } } } = getState();

  const allowedColumnsList = getRateCardExportColumns(reportType);

  const ASSET_ID_COLUMN = createColumn('assetId', 'Asset ID', InputTypes.INPUT);
  const RATE_CARD_ID_COLUMN = createColumn('rateCardId', 'Rate Card ID', InputTypes.INPUT);

  const assetsList = getRateCardAssetsList(getState()).map((asset) => {
    return {
      ...asset.campaign,
      [ASSET_ID_COLUMN.name]: asset.id,
      [RATE_CARD_ID_COLUMN.name]: asset.rateCardId,
    };
  });

  const newColumnOrder = [...columnOrder];

  // Add RateCard/Asset ID to the exported columns
  allowedColumnsList[ASSET_ID_COLUMN.name] = true;
  allowedColumnsList[RATE_CARD_ID_COLUMN.name] = true;

  newColumnOrder.push(ASSET_ID_COLUMN);
  newColumnOrder.push(RATE_CARD_ID_COLUMN);

  const data = extractCampaignString(
    assetsList,
    newColumnOrder,
    Delimiters.COMMA,
    allowedColumnsList,
  );

  sendCsv(data);

  return next(action);
};

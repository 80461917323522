import { getUsername } from '../../selectors/user';
import {
  BulkImportActions,
  GraphQLLabels,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import {
  onOpenToast,
  onUpdatedPackageReceived,
  onBulkImportActionCompleted,
} from '../../actionCreators';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import { updatePackage } from '../../graphql/mutations';
import {
  appsyncToLocal,
  localToAppsync,
  formatPackageForMutationInput,
} from '../../helpers/translateCampaigns';
import { getPackage } from '../../selectors/package';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const username = getUsername(state);

  const { payload: { id, showToast, options } } = action;
  const { isBulkAction } = options;
  const {
    Meta: { metadata },
  } = getState();

  const editedRow = getPackage(state, id);
  const packageToSend = localToAppsync(editedRow);

  const input = {
    package: formatPackageForMutationInput(packageToSend),
    username,
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: input,
    action: MetricNames.EDIT_PACKAGE,
    username,
  };

  submitStringMetric(MetricNames.EDIT_PACKAGE, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: updatePackage,
    data: { input },
    label: GraphQLLabels.UPDATE_PACKAGE,
    onSuccessFunction(updatedPackage) {
      const translatedPackage = appsyncToLocal(
        updatedPackage.data.updatePackage, metadata,
      );
      dispatch(onUpdatedPackageReceived(translatedPackage));
      if (isBulkAction) {
        dispatch(onBulkImportActionCompleted(
          translatedPackage.refMarker,
          BulkImportActions.SUCCESS,
        ));
      } 
      if (showToast) {
        dispatch(onOpenToast('Package has been successfully updated'));
      }
    },
    onFailureFunction(error) {
      dispatch(onOpenToast('Error. Package update failed!'));
      if (isBulkAction) {
        dispatch(onBulkImportActionCompleted(
          packageToSend.refMarker,
          BulkImportActions.ERROR,
        ));
      }
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};

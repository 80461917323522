import _ from 'lodash';
import { CampaignColumns, CampaignTypes } from '../constants';
import { createItemShell } from './common';

const getCampaignColumns = () => {
  return [
    CampaignColumns.ALT_TEXT,
    CampaignColumns.ASINS,
    CampaignColumns.BODY_COPY,
    CampaignColumns.BRAND,
    CampaignColumns.BUSINESS_START_YEAR,
    CampaignColumns.CAMPAIGN_NAME,
    CampaignColumns.CAMPAIGN_TYPE,
    CampaignColumns.CATEGORY,
    CampaignColumns.CBR_SIM,
    CampaignColumns.CHANNEL,
    CampaignColumns.COPY,
    CampaignColumns.COUNTRY,
    CampaignColumns.CREATIVE_STATUS,
    CampaignColumns.CTA,
    CampaignColumns.CUSTOMER_CTA_URL,
    CampaignColumns.DESIGN_SIM,
    CampaignColumns.DESKTOP_IMAGE_1x,
    CampaignColumns.DESKTOP_IMAGE_2x,
    CampaignColumns.DEVICE_TYPE,
    CampaignColumns.END_DATE,
    CampaignColumns.START_DATE,
    CampaignColumns.END_TIME,
    CampaignColumns.START_TIME,
    CampaignColumns.FREQUENCY,
    CampaignColumns.HEADLINE,
    CampaignColumns.HVA,
    CampaignColumns.INITIATIVE,
    CampaignColumns.IS_DEAL,
    CampaignColumns.IS_LOCKED,
    CampaignColumns.LANGUAGE,
    CampaignColumns.LOCK_MESSAGE,
    CampaignColumns.LOCK_USER,
    CampaignColumns.MARKETING_MANAGER_NOTES,
    CampaignColumns.MOBILE_IMAGE_1x,
    CampaignColumns.MOBILE_IMAGE_2x,
    CampaignColumns.OTHER_THEME,
    CampaignColumns.PACKAGE_ID,
    CampaignColumns.PLACEMENT_SOURCE,
    CampaignColumns.PLACEMENT_IN_BUNDLE,
    CampaignColumns.PROMO_ID,
    CampaignColumns.PROMO_TYPE,
    CampaignColumns.RATE_CARD_ID,
    CampaignColumns.REF_MARKER,
    CampaignColumns.SCHEDULER,
    CampaignColumns.SCHEDULING_INSTRUCTIONS,
    CampaignColumns.SPANISH_COPY,
    CampaignColumns.STATUS,
    CampaignColumns.TIMEZONE_ID,
    CampaignColumns.TRANSLATION_STATUS,
    CampaignColumns.ALM_STORES,
    CampaignColumns.BROWSE_NODE_IDS,
    CampaignColumns.CBR_COMMENTS,
    CampaignColumns.CONTRA_COGS,
    CampaignColumns.COMPOSER_COMPONENT_JSON,
    CampaignColumns.CS_CAMPAIGN_IDS,
    CampaignColumns.CUSTOM_SEE_MORE_URL,
    CampaignColumns.DEDUPE_RULES,
    CampaignColumns.DESKTOP_SLOT,
    CampaignColumns.ENCODED_KEPLER_WIDGET_PARAM,
    CampaignColumns.FUNCTIONAL_QA_NOTES,
    CampaignColumns.HERO_ASINS,
    CampaignColumns.IMAGE_PATHS,
    CampaignColumns.IMAGE_TYPE,
    CampaignColumns.INVENTORY,
    CampaignColumns.INVENTORY_AWARENESS,
    CampaignColumns.IS_ANIMATED_CONTENT,
    CampaignColumns.LABEL,
    CampaignColumns.MOBILE_SLOT,
    CampaignColumns.MERCHANT,
    CampaignColumns.NEED_INVENTORY_AWARENESS,
    CampaignColumns.PAGE_ID,
    CampaignColumns.PAGE_NAME,
    CampaignColumns.PAGE_THEME,
    CampaignColumns.PAGETYPE,
    CampaignColumns.PERCOLATE_PARAMETERS,
    CampaignColumns.PLACEMENT,
    CampaignColumns.SEASONAL_EVENT,
    CampaignColumns.SHOVELER_CONTENT,
    CampaignColumns.SLOTS_OVERBOOKED_VALIDATION_BYPASSED,
    CampaignColumns.STOREFRONT_REVIEW_NOTES,
    CampaignColumns.SOV,
    CampaignColumns.SOV_TYPE,
    CampaignColumns.SUB_HEADLINE,
    CampaignColumns.SYMPHONY_ID,
    CampaignColumns.SYMPHONY_URL,
    CampaignColumns.TABLET_IMAGE_1x,
    CampaignColumns.TABLET_IMAGE_2x,
    CampaignColumns.TARGET_BUSINESS,
    CampaignColumns.TARGETING,
    CampaignColumns.TARGETING_EXCLUDE,
    CampaignColumns.TARGETING_TYPE,
    CampaignColumns.TERMS_AND_CONDITIONS,
    CampaignColumns.USE_NEW_TEMPLATE,
    CampaignColumns.VSSM_ID,
    CampaignColumns.WEBLAB,
    CampaignColumns.WEBLAB_TREATMENT,
    CampaignColumns.WIDGET,
  ]
};

export const getCampaignColumnNames = () => getCampaignColumns().map(({ name }) => name);

export const isOnlineCampaign = ({ campaignType }) => campaignType === CampaignTypes.ONLINE;
export const isInStoreCampaign = ({ campaignType }) => campaignType === CampaignTypes.IN_STORE;
export const createCampaignShell = (campaignOverrides = {}) => {
  return createItemShell(getCampaignColumns(), campaignOverrides);
};

export const createInStoreCampaignShell = (campaignOverrides = {}) => {
  return createCampaignShell({
    [CampaignColumns.CAMPAIGN_TYPE.name]: CampaignTypes.IN_STORE,
    ...campaignOverrides,
  });
};

export const createOnlineCampaignShell = (campaignOverrides = {}) => {
  return createCampaignShell({
    [CampaignColumns.CAMPAIGN_TYPE.name]: CampaignTypes.ONLINE,
    ...campaignOverrides,
  });
};

export const getSecondaryLanguageUniqueColumns = (returnLabels = false) => {
  const columns = [
    CampaignColumns.LANGUAGE,
    CampaignColumns.LABEL,
    CampaignColumns.COPY,
    CampaignColumns.SUB_HEADLINE,
    CampaignColumns.TERMS_AND_CONDITIONS,
  ];

  return columns.map(column => returnLabels ? column.display : column.name);
};

export const getFirstOnlineCampaignIndex = (campaigns) => {
  const onlineCampaignIndexes = [];

  campaigns.forEach((campaign, index) => {
    if (!isOnlineCampaign(campaign)) {
      return;
    }

    onlineCampaignIndexes.push(index);
  });

  return onlineCampaignIndexes.length ? onlineCampaignIndexes[0] : null;
}

export const getFirstOnlineCampaign = (campaigns) => {
  if (!campaigns) {
    return null;
  }
  return campaigns[getFirstOnlineCampaignIndex(campaigns)];
};

export const getAllOnlineCampaigns = (campaigns) => {
  if (!campaigns) {
    return [];
  }

  return campaigns.filter(campaign => isOnlineCampaign(campaign));
}

export const getSortedCampaigns = (campaigns) => {
  return _.orderBy(
    campaigns,
    [CampaignColumns.CAMPAIGN_TYPE.name, CampaignColumns.LANGUAGE.name],
    ['desc', 'asc'],
  );
};

export const getCampaignWatchableFieldsForCampaignNameChange = () => {
  return [
    CampaignColumns.PAGE_NAME.name,
    CampaignColumns.WIDGET.name,
    CampaignColumns.PLACEMENT.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.DEVICE_TYPE.name,
    CampaignColumns.SEASONAL_EVENT.name,
  ];
};

export const getCampaignSyncupExcludedColumns = () => {
  return [
    'id',
    'version',
    'createdAt',
    'updatedAt',
    CampaignColumns.SYMPHONY_URL.name,
    CampaignColumns.SYMPHONY_ID.name,
    CampaignColumns.CS_CAMPAIGN_IDS.name,
    CampaignColumns.ENCODED_KEPLER_WIDGET_PARAM.name,
    CampaignColumns.RATE_CARD_ID.name,
    CampaignColumns.REF_MARKER.name,
    CampaignColumns.CAMPAIGN_NAME.name,
  ];
};

export const isNew = (campaign) => !campaign.id;
import { createSelector } from 'reselect';
import moment from 'moment';
import { getFilteredInStoreCampaigns } from '../helpers/inStoreUtilizationFilters';
import { getCampaignsForCurrentBusinessStartYears } from './campaign';
import {
  getPackagesForCurrentBusinessStartYears,
} from './package';

export const getUtilization = (state) => {
  return state.InStoreUtilizationPage.utilization;
};
export const getFilterOptions = (state) => {
  return state.InStoreUtilizationPage.filterOptions;
};
export const getSelectedFilters = (state) => {
  return state.InStoreUtilizationPage.selectedFilters;
};
export const getSelectedStartTime = (state) => {
  return state.InStoreUtilizationPage.startTime;
};
export const getSelectedEndTime = (state) => {
  return state.InStoreUtilizationPage.endTime;
};
export const getSelectedDateRange = (state) => {
  return state.InStoreUtilizationPage.selectedDateRange;
};
export const getInStoreUtilization = createSelector(
  [getUtilization],
  (utilization) => utilization,
);
export const calculateInStoreUtilization = createSelector(
  [
    getPackagesForCurrentBusinessStartYears,
    getCampaignsForCurrentBusinessStartYears,
    getFilterOptions,
    getSelectedFilters,
    getSelectedDateRange,
    getSelectedStartTime,
    getSelectedEndTime,
  ],
  (
    packages,
    campaigns,
    filterOptions,
    selectedFilters,
    selectedDateRange,
    startTime,
    endTime,
  ) => {
    const [dateRangeStart, dateRangeEnd] = selectedDateRange;

    const startDateTime = moment(`${dateRangeStart} ${startTime}`);
    const endDateTime = moment(`${dateRangeEnd} ${endTime}`);

    return getFilteredInStoreCampaigns(
      packages,
      campaigns,
      startDateTime,
      endDateTime,
      filterOptions,
      selectedFilters,
    );
  },
);

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import bellIconTokens from '@amzn/meridian-tokens/base/icon/bell';
import Badge from '@amzn/meridian/badge';
import Icon from '@amzn/meridian/icon';

const NotificationBell = ({ notificationsCount, onOpenNotificationsWidget }) => {
  return (
    <Button
      type="link"
      onClick={onOpenNotificationsWidget}
      data-testid="open-notification-widget"
    >
      <Icon tokens={bellIconTokens}>
        Open notifications panel
        <Badge type="theme" value={notificationsCount}>
          Notifications count
        </Badge>
      </Icon>
    </Button>
  );
};

NotificationBell.propTypes = {
  notificationsCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]).isRequired,
  onOpenNotificationsWidget: PropTypes.func.isRequired,
};

export default NotificationBell;

import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@amzn/meridian/alert';
import WithHistoryTiming from '../../WithHistoryTiming';
import { PageNames } from '../../../constants';
import Package from '../../Package';

export const RateCardAssets = ({ shouldRender, rateCardAssetId }) => {
  if (!shouldRender) {
    return <Alert type="error">No Asset found! Please re-open the card using the list view.</Alert>;
  }

  return (
    <Package
      isNew={false}
      id={rateCardAssetId}
      isAssetEditMode
    />
  );
};

RateCardAssets.propTypes = {
  shouldRender: PropTypes.bool.isRequired,
  rateCardAssetId: PropTypes.string.isRequired,
};

export default WithHistoryTiming(RateCardAssets, { pageName: PageNames.RATE_CARD_ASSETS });

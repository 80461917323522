import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import Checkbox from '@amzn/meridian/checkbox';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import withDragAndDrop from '../../../decorators/withDragAndDrop';

import styles from './UtilizationSlotsPanel.module.scss';
import { utilizationOrderedSlotProp } from '../../../proptypes';

const UtilizationSlotsPanel = (props) => {
  const {
    onSave,
    itemsList,
    setItemsList,
    onDragStart,
    onDragOver,
    onDrop,
    onDragEnter,
    onDragEnd,
    hoveredItem,
    draggedItem,
    droppedItem,
    dragStyles,
  } = props;

  const onCheckItems = (isVisible) => {
    const newItemsList = itemsList.map((item) => {
      return {
        ...item,
        isVisible,
      };
    });

    setItemsList(newItemsList);
  };
  const onCheckAll = () => onCheckItems(true);
  const onUnCheckAll = () => onCheckItems(false);

  const onToggleItem = (index) => {
    const newItemsList = itemsList.map((item, i) => {
      if (i !== index) { return item; }
      return {
        ...item,
        isVisible: !item.isVisible,
      };
    });

    setItemsList(newItemsList);
  };

  const getSlotsList = () => {
    const list = itemsList.map(({ slotName, isVisible }, index) => {
      const classes = classnames({
        [dragStyles.itemRow]: true,
        [dragStyles.hoverAbove]: hoveredItem === index && draggedItem > index,
        [dragStyles.hoverBelow]: hoveredItem === index && draggedItem < index,
        [dragStyles.itemDropped]: droppedItem === index,
      });

      return (
        <li
          className={classes}
          key={slotName}
          draggable="true"
          onDragStart={(evt) => { onDragStart(evt, index); }}
          onDragOver={(evt) => { onDragOver(evt); }}
          onDrop={(evt) => { onDrop(evt, index); }}
          onDragEnter={() => { onDragEnter(index); }}
          onDragEnd={() => { onDragEnd(); }}
          data-testid="checkbox"
        >
          <div className={dragStyles.itemIcon}>
            <DragHandleIcon style={{ color: 'black' }} />
          </div>
          <div className={dragStyles.itemCheck}>
            <Checkbox
              checked={isVisible}
              onChange={() => { onToggleItem(index); }}
            >
              {slotName}
            </Checkbox>
          </div>
        </li>
      );
    });

    return (
      <ul className={styles.list}>
        {list}
      </ul>
    );
  };

  const getControls = () => {
    return (
      <div className={styles.buttonContainer}>
        <Button
          onClick={onCheckAll}
          data-testid="check-all"
        >
          Check All
        </Button>
        <Button
          onClick={onUnCheckAll}
          data-testid="uncheck-all"
        >
          Un-check All
        </Button>
        <Button
          onClick={() => onSave(itemsList)}
          data-testid="save-btn"
        >
          Save
        </Button>
      </div>
    );
  };

  const getContent = () => {
    if (!itemsList.length) {
      return <p>No data to display</p>;
    }

    const slotsList = getSlotsList();
    const controls = getControls();

    return (
      <>
        {slotsList}
        {controls}
      </>
    );
  };

  return getContent();
};

UtilizationSlotsPanel.propTypes = {
  onSave: PropTypes.func.isRequired,
  itemsList: PropTypes.arrayOf(utilizationOrderedSlotProp).isRequired,
  setItemsList: PropTypes.func.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  /* eslint-disable react/forbid-prop-types */
  dragStyles: PropTypes.object.isRequired,
  hoveredItem: PropTypes.number,
  draggedItem: PropTypes.number,
  droppedItem: PropTypes.number,
};
UtilizationSlotsPanel.defaultProps = {
  hoveredItem: null,
  draggedItem: null,
  droppedItem: null,
};

export default withDragAndDrop(UtilizationSlotsPanel);

import classnames from 'classnames';
import React from 'react';
import sitewideStyles from '../../../styles/sitewide.module.scss';

export const getMessages = (touched, error, warning) => {
  return (touched
    && ((error && (
    <p className={
          classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)
        }
    >
      {error}
    </p>
    ))
      || (warning
        && (
          <p className={
            classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)
          }
          >
            {warning}
          </p>
        ))));
};

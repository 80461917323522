import { CampaignColumns, Language } from '../../../constants';
import {
  getBusinessEvergreen,
  getBusinessStartYears,
} from '../../../selectors/sitewide';

export const validateStartYear = (state, pkg) => {
  const isValid = getBusinessStartYears(state)
    .includes(pkg[CampaignColumns.BUSINESS_START_YEAR.name]);
  const error = pkg[CampaignColumns.BUSINESS_START_YEAR.name] ===
  getBusinessEvergreen(state)
    ? { [CampaignColumns.END_DATE.name]: { text: Language.END_DATE_INCONSISTENT } }
    : { [CampaignColumns.START_DATE.name]: { text: Language.START_DATE_INCONSISTENT } };
  if (!isValid) {
    pkg.errors = {
      ...pkg.errors,
      ...error,
    };
  }
  return !isValid;
};
import {
  CreateCsCampaignActions,
  PAGE_INIT,
} from '../../constants';
import {
  onCreateCsCampaignClearData,
  onCreateCsCampaignOpenDiffModal,
} from '../../actionCreators';

import performCampaignCreation from './performCampaignCreation';
import processSymphonyMetadata from './processSymphonyMetadata';
import setDefaultCampaignData from './setDefaultCampaignData';
import calcCampaignDiff from './calcCampaignDiff';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  if (action.type === CreateCsCampaignActions.OPEN_MODAL) {
    dispatch(onCreateCsCampaignClearData());
  }
  if (action.type === CreateCsCampaignActions.SET_CAMPAIGN) {
    setDefaultCampaignData(getState(), dispatch, action);
  }
  if (action.type === CreateCsCampaignActions.SEND_CAMPAIGN) {
    performCampaignCreation(getState(), dispatch);
  }
  if (action.type === CreateCsCampaignActions.CALC_CAMPAIGN_DIFF) {
    calcCampaignDiff(store, next, action);
  }
  if (action.type === CreateCsCampaignActions.SET_CAMPAIGN_DIFF) {
    dispatch(onCreateCsCampaignOpenDiffModal());
  }
  if (action.type === PAGE_INIT) {
    processSymphonyMetadata(dispatch);
  }

  return next(action);
};

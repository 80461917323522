import React from 'react';
import Alert from '@amzn/meridian/alert';
import PropTypes from 'prop-types';
import { Language } from '../../../../../../constants';

const CheckoutSOVWarning = ({ showWarning }) => {
  return showWarning ? (
    <Alert type="informational">
      {Language.CHECKOUT_SOV_WARNING}
    </Alert>
  ) : null;
};
CheckoutSOVWarning.propTypes = {
  showWarning: PropTypes.bool.isRequired,
};
export default CheckoutSOVWarning;

import { getUsername } from '../../selectors/user';
import {
  BulkImportActions,
  GraphQLLabels,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import {
  onBulkImportActionCompleted,
  onOpenToast,
} from '../../actionCreators';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import { updateCampaign } from '../../graphql/mutations';
import {
  formatCampaignForMutationInput,
  localToAppsync
} from '../../helpers/translateCampaigns';
import { getCampaign } from '../../selectors/campaign';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const username = getUsername(state);

  const { payload: { id, packageId, showToast, isBulkAction } } = action;

  const editedRow = getCampaign(state, id, packageId);
  const campaignToSend = localToAppsync(editedRow);

  const input = {
    campaign: formatCampaignForMutationInput(campaignToSend),
    username,
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: input,
    action: MetricNames.EDIT_CAMPAIGN,
    username,
  };

  submitStringMetric(MetricNames.EDIT_CAMPAIGN, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: updateCampaign,
    data: { input },
    label: GraphQLLabels.UPDATE_CAMPAIGN,
    onSuccessFunction() {
      if (showToast) {
        dispatch(onOpenToast('Campaign has been successfully updated'));
      }
      if (isBulkAction) {
        dispatch(onBulkImportActionCompleted(
          campaignToSend.refMarker,
          BulkImportActions.SUCCESS,
        ));
      }
    },
    onFailureFunction(error) {
      dispatch(onOpenToast('Error. Campaign update failed!'));
      submitAppSyncError(error, metricContext);

      if (isBulkAction) {
        dispatch(onBulkImportActionCompleted(
          campaignToSend.refMarker,
          BulkImportActions.ERROR,
        ));
      }
    },
  }));

  return next(action);
};

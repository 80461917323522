import {
  RateCardMerchTypes,
  RateCardPlacementTypes,
} from '../../../../constants/rateCard';

const Awareness = [
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Hero (4-weeks)',
    dollars: 15000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Hero (2-weeks)',
    dollars: 7500,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Storefront Shoveler - Standard (1-week)',
    dollars: 50000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Storefront Shoveler - Standard (4-weeks)',
    dollars: 200000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Storefront Enhanced Shoveler (1-week)',
    dollars: 57500,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Storefront Enhanced Shoveler (4-weeks)',
    dollars: 230000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Storefront Enhanced Shoveler (2-weeks)',
    dollars: 115000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Storefront Shoveler - Standard (2-weeks)',
    dollars: 100000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 26,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Hero (1-quarter)',
    dollars: 30000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: '[Standard] Product Launch Bundle',
    dollars: 110000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: '[Premium] Product Launch Bundle',
    dollars: 185000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 1,
    numberPlacements: 1,
    description: 'Available to Gold+ LoS only',
  },
];

export default Awareness;
import {
  PackageTabActions,
  PACKAGE_TAB_INDEX,
  PackageTabTypes,
  nonOmnichannelPackageTabList,
} from '../../constants';

export const initialState = {
  [PackageTabTypes.OMNICHANNEL]: PACKAGE_TAB_INDEX,
  [PackageTabTypes.NON_OMNICHANNEL]: nonOmnichannelPackageTabList.FORM.name
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case PackageTabActions.SET_TAB: {
      const { tabName, tabType } = payload;

      return {
        ...state,
        [tabType]: tabName,
      };
    }
    case PackageTabActions.RESET_TAB: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import history from '../history';
import GridViewPage from './gridViewPage';
import UtilizationPage from './utilizationPage';
import InStoreUtilizationPage from './InStoreUtilizationPage';
import FormPage from './formPage';
import Sitewide from './sitewide';
import User from './user';
import CreateSymphonyCsCampaign from './CreateSymphonyCsCampaign';
import Dashboard from './dashboard';
import MetadataEditor from './MetadataEditor';
import BulkImport from './bulkImport';
import BulletinBoard from './BulletinBoard';
import AuditLog from './auditLog';
import CustomView from './customView';
import ThirdPartyIFrame from './ThirdPartyIFrame';
import Notifications from './CustomerNotifications';
import Comments from './comments';
import PackageTabs from './PackageTabs';
import RateCard from './RateCard';
import Timer from './timer';
import PerformanceTimer from './performanceTimer';
import MetadataAuditLog from './MetadataAuditLog';
import Meta from './Metadata';
import UserSettings from './UserSettings';

export default combineReducers({
  router: connectRouter(history),
  form: reduxFormReducer,
  GridViewPage,
  UtilizationPage,
  InStoreUtilizationPage,
  FormPage,
  Sitewide,
  User,
  CreateSymphonyCsCampaign,
  Dashboard,
  MetadataEditor,
  BulkImport,
  BulletinBoard,
  AuditLog,
  CustomView,
  UserSettings,
  ThirdPartyIFrame,
  Notifications,
  Comments,
  PackageTabs,
  RateCard,
  Timer,
  PerformanceTimer,
  MetadataAuditLog,
  Meta,
});

import React from 'react';
import Alert from '@amzn/meridian/alert';
import { HashLink as Link } from 'react-router-hash-link';
import moment from 'moment-timezone';
import { getFiltersUrl } from '../../../../../../helpers/utilization';
import { CampaignColumns, UTILIZATION_DATE_FORMAT } from '../../../../../../constants';
import { campaignFormUtilizationProps } from '../../../../../../proptypes';

import siteWideStyle from '../../../../../../styles/sitewide.module.scss';
import styles from './FullSlotWarning.module.scss';

const FullSlotWarning = (props) => {
  const {
    utilizationData: {
      startDate,
      endDate,
      timezoneId,
      desktopUtilizationData,
      mobileUtilizationData,
    },
    showErrors,
  } = props;

  const getLink = (slotType, slotName, numberOfCampaigns) => {
    const url = getFiltersUrl(
      slotType,
      slotName,
      startDate,
      endDate,
    );
    return (
      <Link
        smooth
        to={url}
        target="_blank"
        rel="noopener noreferrer"
        className={siteWideStyle.link}
      >
        {numberOfCampaigns}
      </Link>
    );
  };

  const getMessage = (
    slotType,
    slotName,
    numberOfCampaigns,
    totalSOV,
  ) => {
    const link = getLink(
      slotType,
      slotName,
      numberOfCampaigns,
    );

    const formattedStartDate = moment.tz(startDate, timezoneId).format(UTILIZATION_DATE_FORMAT);
    const formattedEndDate = moment.tz(endDate, timezoneId).format(UTILIZATION_DATE_FORMAT);

    return (
      <>
        <h3 className={styles.messageTitle}>Slot Overbooked</h3>
        <div>
          <p className={styles.messageText}>
            There are
            {' '}
            {link}
            {' '}
            campaigns currently scheduled in slot
            {' '}
            <strong>{slotName}</strong>
            {' '}
            between
            {' '}
            <strong>
              {formattedStartDate}
              {' '}
              and
              {' '}
              {formattedEndDate}
            </strong>
            .
          </p>
          <p className={styles.messageText}>
            The total scheduled SOV during this selected time range is already
            {' '}
            <strong>{totalSOV}</strong>
            %.
          </p>
          <p className={styles.messageText}>
            Please select a different slot.
          </p>
        </div>
      </>
    );
  };

  const getErrorsList = () => {
    if (!showErrors) {
      return null;
    }

    const list = [];
    if (desktopUtilizationData) {
      list.push({
        id: desktopUtilizationData.slotName,
        message: getMessage(
          CampaignColumns.DESKTOP_SLOT.name,
          desktopUtilizationData.slotName,
          desktopUtilizationData.numberOfCampaigns,
          desktopUtilizationData.totalSOV,
        ),
      });
    }
    if (mobileUtilizationData) {
      list.push({
        id: mobileUtilizationData.slotName,
        message: getMessage(
          CampaignColumns.MOBILE_SLOT.name,
          mobileUtilizationData.slotName,
          mobileUtilizationData.numberOfCampaigns,
          mobileUtilizationData.totalSOV,
        ),
      });
    }

    if (!list.length) {
      return null;
    }

    const itemsList = list.map(({ id, message }) => (
      <li key={id} className={styles.item}>
        <Alert
          type="warning"
        >
          {message}
        </Alert>
      </li>
    ));

    return (
      <ul className={styles.list}>
        {itemsList}
      </ul>
    );
  };

  return getErrorsList();
};
FullSlotWarning.propTypes = {
  utilizationData: campaignFormUtilizationProps.isRequired,
};

export default FullSlotWarning;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import Row from '@amzn/meridian/row';
import classnames from 'classnames';

import {
  Comparators,
  FilterGroupTypes,
} from '../../../constants';

import GridViewFilterRow from '../GridViewFilterRow';

import styles from '../GridViewFilterModal/GridViewFilterModal.module.scss';

const defaultFilter = { column: '', comparator: Comparators.EQUALS, value: '' };

const GridViewFilterGroup = ({
  filters,
  onUpdateFilters,
  isTopLevelGroup,
  groupType,
  depth,
  onToggleGroupType,
}) => {
  const addFilter = () => {
    onUpdateFilters([...filters, { ...defaultFilter }]);
  };
  const addGroup = () => {
    onUpdateFilters([
      ...filters,
      { type: FilterGroupTypes.OR, rules: [{ ...defaultFilter }] },
    ]);
  };
  const deleteFilter = (index) => {
    onUpdateFilters(filters.filter((filter, i) => i !== index));
  };
  const updateFilter = (index, updatedFilter) => {
    const newFilters = filters.map((filter, i) => {
      if (i !== index) {
        return filter;
      }
      return updatedFilter;
    });

    onUpdateFilters(newFilters);
  };
  const toggleGroupType = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    onToggleGroupType();
  };

  const getFilterRow = (filter, index) => {
    // index should be ok here, as the filters will not be reordered at any time
    /* eslint-disable-next-line react/no-array-index-key */
    const row = (
      <GridViewFilterRow
        filter={filter}
        index={index}
        updateFilter={updateFilter}
        deleteFilter={deleteFilter}
        key={index}
        depth={depth + 1}
      />
    );
    if (index < filters.length - 1) {
      return (
        <React.Fragment key={index}>
          {row}
          <div
            className={styles.groupType}
            onClick={toggleGroupType}
            role="button"
            tabIndex={0}
            onKeyPress={toggleGroupType}
          >
            {groupType}
          </div>
        </React.Fragment>
      );
    }
    return row;
  };

  const filterList = filters.map(getFilterRow);
  const actionRow = (
    <Row className={styles.actionRow} width="100%" widths="mixed" height="38px">
      <div className={styles.actionRowItem}>
        <Button onClick={addFilter} size="small" className={styles.actionButton}>Add Filter</Button>
      </div>
      <div className={styles.actionRowItem}>
        <Button onClick={addGroup} size="small" className={styles.actionButton}>Add Group</Button>
      </div>
    </Row>
  );

  return (
    <div
      className={classnames(styles.row, {
        [styles.childRow]: !isTopLevelGroup,
        [styles.evenGroup]: depth % 2 === 0 && !isTopLevelGroup,
        [styles.oddGroup]: depth % 1 === 0 && !isTopLevelGroup,
      })}
    >
      {filterList}
      {actionRow}
    </div>
  );
};

GridViewFilterGroup.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    column: PropTypes.string,
    comparator: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    rules: PropTypes.array,
  })).isRequired,
  onUpdateFilters: PropTypes.func.isRequired,
  groupType: PropTypes.string.isRequired,
  depth: PropTypes.number.isRequired,
  onToggleGroupType: PropTypes.func,
  isTopLevelGroup: PropTypes.bool,
};

GridViewFilterGroup.defaultProps = {
  isTopLevelGroup: false,
  onToggleGroupType: () => {},
};

export default GridViewFilterGroup;

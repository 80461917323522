import React from 'react';
import PropTypes from 'prop-types';
import Card, { CardHeader } from '@amzn/meridian/card';
import Heading from '@amzn/meridian/heading';
import { Language } from '../../constants';
import styles from './AccessControl.module.scss';

const AccessControl = ({ children, hasAccess }) => {
  const getMessage = () => {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Card>
            <CardHeader>
              <Heading level={2} type="h500">
                {Language.ACCESS_CONTROL_TITLE}
              </Heading>
            </CardHeader>
            <div className={styles.contentContainer}>
              {Language.ACCESS_CONTROL_MESSAGE}
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return hasAccess ? children : getMessage();
};

AccessControl.propTypes = {
  children: PropTypes.element.isRequired,
  hasAccess: PropTypes.bool.isRequired,
};

export default AccessControl;

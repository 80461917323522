import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import SubmitButtons from './SubmitButtons';
import {
  getTotalInvestment,
  getTotalMoneyAvailable,
  hasPublishedAssets,
} from '../../../../selectors/rateCardSelectors';
import {
  onRateCardSetTab,
  onRateCardCreateAssets,
} from '../../../../actionCreators';
import { Form } from '../../../../constants';
import { RateCardColumns } from '../../../../constants/rateCard';
import { getMoneyFormatter } from '../../../../helpers/money';

const selector = formValueSelector(Form.RATE_CARD_NAME);
const moneyFormatter = getMoneyFormatter();

export const mapStateToProps = (state, { isNew, isClone }) => {
  const { RateCard: { assets } } = state;

  const rateCardId = selector(state, RateCardColumns.ID.name);
  const totalInvestment = getTotalInvestment(state);
  const totalMoneyAvailable = getTotalMoneyAvailable(state);
  const showSaveButton = !hasPublishedAssets(state, rateCardId) || isClone;

  return {
    rateCardId,
    totalInvestment: moneyFormatter.format(totalInvestment),
    totalMoneyAvailable: moneyFormatter.format(totalMoneyAvailable),
    showAuditLogButton: !isNew,
    showAssetsButton: !isNew,
    showSaveButton,
    hasAssets: !!(assets.length),
    isMoneyLimitExceeded: totalMoneyAvailable < 0,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSwitchTab: (tabName) => {
      dispatch(onRateCardSetTab({ tabName }));
    },
    onCreateAssets: (rateCardId) => {
      dispatch(onRateCardCreateAssets(rateCardId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButtons);

import { connect } from 'react-redux';

import {
  onRateCardAssetsPublish,
  onRateCardAssetsExport,
  onBulkImportSetValidationMode,
  onBulkImportPerformAssetsImport,
  onBulkImportProcessFile,
  onBulkImportClearPendingPackages,
} from '../../actionCreators';
import RateCardAssetsManager from './RateCardAssetsManager';
import { hasPublishedAssets, isCurrentConfigTheLatest } from '../../selectors/rateCardSelectors';
import { BulkImportWorkMode, Language } from '../../constants';

export const mapStateToProps = (state, { rateCardId }) => {
  const {
    RateCard: { assets },
    BulkImport: {
      isProcessed,
      isFileParsing,
      accepted: {
        create,
        update,
      },
    },
  } = state;

  const hasAssetsToProcess = !!(isProcessed && (create.length || update.length));
  const shouldShowImportWarning = !!(isProcessed && (!create.length && !update.length));
  const isPublishButtonAvailable = !hasPublishedAssets(state, rateCardId)
    && isCurrentConfigTheLatest(state);

  return {
    hasAssets: assets.length > 0,
    showCancelButton: isProcessed,
    shouldImportButtonBeAvailable: hasAssetsToProcess,
    isPublishButtonAvailable,
    shouldShowLoader: isFileParsing,
    shouldShowImportWarning,
  };
};

export const mapDispatchToProps = (dispatch, { rateCardId }) => {
  return {
    onAssetsExport: (reportType) => {
      dispatch(onRateCardAssetsExport(reportType));
    },
    onAssetsImport() {
      dispatch(onBulkImportPerformAssetsImport(rateCardId));
    },
    onAssetsPublish() {
      if (window.confirm(Language.RATE_CARD_ASSETS_COLLECTION_PUBLISH)) {
        dispatch(onRateCardAssetsPublish());
      }
    },
    onProcessFile(data, fileType) {
      dispatch(onBulkImportProcessFile({
        data,
        fileType,
        workMode: BulkImportWorkMode.RATE_CARD,
      }));
    },
    onClear() {
      dispatch(onBulkImportClearPendingPackages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardAssetsManager);

import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import Button from '@amzn/meridian/button';
import styles from './ActiveUtilityFilters.module.scss';
import siteWideStyle from '../../../../styles/sitewide.module.scss';
import { Urls } from '../../../../constants';

const ActiveUtilityFilters = (
  {
    selectedFilters,
    selectedTimezone,
    filterOptions,
    onOpenFilters,
  },
) => {
  const getSelectedFilterList = () => {
    if (!selectedFilters || !filterOptions) {
      return null;
    }

    return Object.keys(selectedFilters).map((filterName) => {
      const filterValue = selectedFilters[filterName];

      const currentFilter = filterOptions.get(filterName);
      if (!currentFilter) {
        return null;
      }

      return (
        <li key={`${currentFilter.display}/${filterValue}`}>
          {currentFilter.display}
          :
          <wbr/>
          {filterValue}
        </li>
      );
    });
  }

  const selectedFilterList = getSelectedFilterList();

  return (
    <article className={styles.filtersContainer}>
      <div>
        <Button
          onClick={onOpenFilters}
          className={styles.filtersButton}
          size="small"
        >
          Change filters
        </Button>
        <Link
          smooth
          to={Urls.UTILIZATION}
          className={siteWideStyle.link}
        >
          Open in Utilization view
        </Link>
      </div>
      <section className={styles.filtersDescription}>
        <h4 className={styles.activeFiltersTitle}>Enabled filters:</h4>
        <ul className={styles.activeFiltersList}>
          {selectedFilterList}
          <li key="Timezone">
            Timezone:
            {' '}
            <wbr />
            {selectedTimezone}
          </li>
        </ul>
      </section>
    </article>
  );
};

ActiveUtilityFilters.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedTimezone: PropTypes.string.isRequired,
  filterOptions: PropTypes.instanceOf(Map).isRequired,
  onOpenFilters: PropTypes.func.isRequired,
};
export default ActiveUtilityFilters;

import { Language } from '../../../constants';
import { getRequiredFields } from '../../../helpers/bulletinBoard';

export const isEmpty = (value) => !(value || typeof value === 'number');

export const validate = (values) => {
  const errors = {};

  getRequiredFields().forEach((fieldName) => {
    if (isEmpty(values[fieldName])) {
      errors[fieldName] = Language.VALIDATION_REQUIRED;
    }
  });

  return errors;
};

export const warn = () => {
  return {};
};

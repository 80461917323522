import { RateCardColumns } from '../constants/rateCard';

// All but these props will be packed under userInput field when send to AppSync
const getRateCardRealPropsList = () => {
  return {
    [RateCardColumns.BUSINESS.name]: true,
    [RateCardColumns.VENDOR.name]: true,
    [RateCardColumns.MARKETING_MANAGER.name]: true,
    [RateCardColumns.CONFIGURATION_VERSION.name]: true,
    [RateCardColumns.NOTE.name]: true,
  };
};
const getRateCardIgnorePropsList = () => {
  return {
    [RateCardColumns.ID.name]: true,
    version: true,
    userInput: true,
  };
};

const isRealRateCardProp = (columnName) => getRateCardRealPropsList()[columnName];
const isIgnoredRateCardProp = (columnName) => getRateCardIgnorePropsList()[columnName];

export const localToAppsync = (rateCard) => {
  const userInput = {};
  /* eslint-disable-next-line no-restricted-syntax */
  for (const [key, value] of Object.entries(rateCard)) {
    if (!isRealRateCardProp(key) && !isIgnoredRateCardProp(key)) {
      userInput[key] = value;

      /* eslint-disable-next-line no-param-reassign */
      delete rateCard[key];
    }
  }

  return {
    ...rateCard,
    userInput: JSON.stringify(userInput),
  };
};

export const appsyncToLocal = (rateCard) => {
  const userInput = JSON.parse(rateCard.userInput) || {};

  /* eslint-disable-next-line no-param-reassign */
  delete rateCard.userInput;

  return {
    ...rateCard,
    ...userInput,
  };
};

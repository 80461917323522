import { connect } from 'react-redux';
import { onCompleteTimer } from '../../../actionCreators';
import HistoryTimingWrapper from './HistoryTimingWrapper';
import { PageNames } from '../../../constants';

export const mapDispatchToProps = (dispatch, trackingProps) => {
  const { pageName = PageNames.UNKNOWN_PAGE } = trackingProps;

  return {
    pageName,
    onComponentLoaded(name) { dispatch(onCompleteTimer({ pageName: name })); },
  };
};

export default connect(null, mapDispatchToProps)(HistoryTimingWrapper);

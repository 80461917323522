import React from 'react';
import classnames from 'classnames';
import Link from '@amzn/meridian/link';
import { accordion } from '../../../decorators/accordion';
import { dashboardProps } from '../../../proptypes';

import styles from './CampaignStats.module.scss';

const CampaignStats = (props) => {
  const {
    isFreshBusiness,
    totalCampaigns,
    totalCampaignsUrl,
    totalMarketingManager,
    totalMarketingManagerUrl,
    totalScheduler,
    totalSchedulerUrl,
    statsByGroups,
    openItemsIds,
    toggleOpenItem,
  } = props;

  if (!totalCampaigns) {
    return (
      <div className={styles.groupContainer}>
        No data available for the selected date range
      </div>
    );
  }

  const groupsBlock = statsByGroups.map((group) => {
    const { groupName, value: groupOptions } = group;

    const options = groupOptions.map((value) => {
      const { columnValue, count, url } = value;

      const columnName = columnValue || '[empty]';
      const countItems = <Link href={url}>{count}</Link>;

      return (
        <li
          key={`${columnValue}/${count}`}
        >
          {columnName}
          :
          {' '}
          {countItems}
        </li>
      );
    });

    return (
      <div className={styles.groupContainer} key={`${groupName}/${groupOptions.length}`}>
        <button
          type="button"
          onClick={() => {
            toggleOpenItem(groupName);
          }}
          className={styles.groupTitle}
          tabIndex="0"
        >
          {groupName}
        </button>
        <ul
          className={classnames({
            [styles.list]: true,
            [styles.closed]: !openItemsIds[groupName],
          })}
        >
          {options}
        </ul>
      </div>
    );
  });

  let marketingManagerTotalBlock = '';
  let schedulerTotalBlock = '';
  if (isFreshBusiness) {
    marketingManagerTotalBlock = (
      <div className={styles.subTotalBlock}>
        Total as Marketing Manager:
        {' '}
        <Link href={totalMarketingManagerUrl}>{totalMarketingManager}</Link>
      </div>
    );
    schedulerTotalBlock = (
      <div className={styles.subTotalBlock}>
        Total as Scheduler:
        {' '}
        <Link href={totalSchedulerUrl}>{totalScheduler}</Link>
      </div>
    );
  }

  const totalsBlock = (
    <div className={styles.groupContainer}>
      <b>Active campaigns:</b>
      {' '}
      <Link href={totalCampaignsUrl}>{totalCampaigns}</Link>
      {marketingManagerTotalBlock}
      {schedulerTotalBlock}
    </div>
  );

  return (
    <div>
      <div>
        {totalsBlock}
      </div>
      {groupsBlock}
    </div>
  );
};

CampaignStats.propTypes = dashboardProps;
CampaignStats.defaultProps = {
  totalCampaigns: 0,
  totalCampaignsUrl: '',
  isFreshBusiness: false,
};

export default accordion(CampaignStats);

/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import infoTokens from '@amzn/meridian-tokens/base/icon/info';
import { inputControl as Input } from './formRenders';
import { getMoneyFormatter } from './money';
import { capitalize } from './common';

class RenderFields {
  static _getTextDescription = (description) => {
    if (!description) {
      return null;
    }

    return (
      <Tooltip position="top" title={description}>
        <Icon tokens={infoTokens}>Description</Icon>
      </Tooltip>
    );
  };

  static textField = (params) => {
    const {
      input, label, description, meta: { touched, error },
    } = params;

    const textDescription = this._getTextDescription(description);

    return (
      <div>
        <label>
          {label}
        </label>
        <div>
          <span>{input.value}</span>
          <span style={{ marginLeft: '5px' }}>{textDescription}</span>
          {touched && error && <span>{error}</span>}
        </div>
      </div>
    );
  };

  static textInputField = (params) => {
    const {
      input, label, type, meta,
    } = params;

    return (
      <div>
        <label>
          {label}
        </label>
        <div>
          <Input
            input={input}
            type={type}
            placeholder={label}
            meta={meta}
          />
        </div>
      </div>
    );
  };

  static capitalizedTextInputField = (data) => {
    return this.textField({
      ...data,
      input: {
        ...data.input,
        value: capitalize(data.input.value),
      },
    });
  };

  static numberInputField = (params) => {
    return this.textInputField({
      ...params,
      type: 'number',
    });
  };

  static dollarsInputField = (data) => {
    return this.textField({
      ...data,
      input: {
        ...data.input,
        value: getMoneyFormatter().format(data.input.value),
      },
    });
  };
}

export default RenderFields;

import { Auth } from 'aws-amplify';
import {
  getAppAdminList,
  getMetadataAdminList,
  getLDAPAdminAccessGroups,
  getLDAPMetadataAdminAccessGroups,
  getLDAPReadOnlyAccessGroups,
} from './accessControlLists';

export const getUserName = (user) => {
  if (!user || !user.username || !user.username.length) {
    return '';
  }
  /* eslint-disable no-unused-vars */
  const [_, alias] = user.username.split('_');

  return alias;
};

export const getUserRealName = (user) => {
  let realName = '';
  try {
    if (!Object.keys(user).length || !user.signInUserSession) {
      return '';
    }
    const {
      signInUserSession: {
        idToken: {
          payload: {
            /* eslint-disable camelcase */
            given_name,
          },
        },
      },
    } = user;

    realName = given_name;
  } catch (e) {
    // TODO: track error
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return realName;
};

const getUserGroups = (user) => {
  if (!user || !user.attributes || !user.attributes['custom:ldap_groups']) {
    return [];
  }

  try {
    return JSON.parse(user.attributes['custom:ldap_groups']);
  } catch (error) {
    return [];
  }
};

const isUserWithinLDAPGroups = (user, LDAPGroups) => {
  if (!user) {
    return false;
  }

  const userGroups = getUserGroups(user);
  if (!userGroups || !userGroups.length) {
    return false;
  }

  for (const userGroup of userGroups) {
    if (LDAPGroups.includes(userGroup)) {
      return true;
    }
  }

  return false;
};

export const isReadOnly = (user) => {
  if (!user) {
    return false;
  }

  return isUserWithinLDAPGroups(user, getLDAPReadOnlyAccessGroups());
};

export const isAdmin = (user) => {
  if (!user) {
    return false;
  }

  if (getAppAdminList().find((admin) => admin === getUserName(user))) {
    return true;
  }

  return isUserWithinLDAPGroups(user, getLDAPAdminAccessGroups());
};

export const isMetadataAdmin = (user) => {
  if (!user) {
    return false;
  }

  if (getMetadataAdminList().find(
    (metadataAdmin) => metadataAdmin === getUserName(user))) {
    return true;
  }

  return isUserWithinLDAPGroups(user, getLDAPMetadataAdminAccessGroups());
};

export const getUserData = (user) => {
  const username = getUserName(user) || 'guest';

  return {
    username,
    realName: getUserRealName(user) || 'guest',
    isAdmin: isAdmin(user),
    isMetadataAdmin: isMetadataAdmin(user) || isAdmin(user),
    isReadOnly: isReadOnly(user),
  };
};

export const signOut = () => {
  Auth.signOut();
};

export const redirectToLoginPage = () => {
  Auth.federatedSignIn();
};

export async function getCurrentUserInfo() {
  return Auth.currentAuthenticatedUser();
}

export async function hasAccessTokens() {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (e) {
    // TODO: add error logging
    return false;
  }

  return true;
}

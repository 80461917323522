import { CampaignColumns } from './index';

export const InStoreUtilizationActions = Object.freeze({
  SET_FILTER_OPTIONS: 'InStoreUtilizationActions/SET_FILTER_OPTIONS',
  SET_FILTERS: 'InStoreUtilizationActions/SET_FILTERS',
  SET_FILTER: 'InStoreUtilizationActions/SET_FILTER',
  TOGGLE_ALL_FILTERS: 'InStoreUtilizationActions/TOGGLE_ALL_FILTERS',
  SELECT_DATE_RANGE: 'InStoreUtilizationActions/SELECT_DATE_RANGE',
  SELECT_TIMEZONE: 'InStoreUtilizationActions/SELECT_TIMEZONE',
  SELECT_START_TIME: 'InStoreUtilizationActions/SELECT_START_TIME',
  SELECT_END_TIME: 'InStoreUtilizationActions/SELECT_END_TIME',
  LOAD_PRESETS: 'InStoreUtilizationActions/LOAD_PRESETS',
  CALCULATE_RESULTS: 'InStoreUtilizationActions/CALCULATE_RESULTS',
  SET_RESULTS: 'InStoreUtilizationActions/SET_RESULTS',
  SELECT_TIME_FRAME: 'InStoreUtilizationActions/SELECT_TIME_FRAME',
});
export const InStoreUtilizationPlacementTypes = Object.freeze({
  VFCC: {
    name: 'VFCC',
    label: 'VFCC',
  },
  HOUSE: {
    name: 'House',
    label: 'House',
  },
});
export const InStoreUtilizationFilterGroupNames = Object.freeze({
  STATUS: {
    name: CampaignColumns.STATUS.name,
    label: CampaignColumns.STATUS.display,
  },
  VERTICAL: {
    name: CampaignColumns.VERTICAL.name,
    label: CampaignColumns.VERTICAL.display,
  },
  PLACEMENT: {
    name: CampaignColumns.PLACEMENT.name,
    label: CampaignColumns.PLACEMENT.display,
  },
  PLACEMENT_TYPES: {
    name: 'placementTypes',
    label: 'Placement Types',
  },
});
export const InStoreUtilizationGroupByCategories = Object.freeze({
  ENDCAP: {
    name: 'ENDCAP',
    label: 'Endcap',
  },
  ENDCAP_INCLUSION: {
    name: 'ENDCAP_INCLUSION',
    label: 'Endcap Inclusion',
  },
});
export const InStoreUtilizationConfig = Object.freeze({
  CALCULATE_UTILIZATION_DEFER_TIMEOUT: 200,
});

import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const AutomatedFields = ({
  campaignColumns,
  currentTab,
}) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  const getTrafficAutomatedFields = () => {
    return (
      <div className={styles.formGroupContent}>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField
              {...campaignField(campaignColumns.CAMPAIGN_NAME)}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...campaignField(campaignColumns.REF_MARKER)}
            />
          </div>
        </div>
      </div>
    )
  }

  const trafficAutomatedFields = getTrafficAutomatedFields();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Automated Traffic fields</div>
      <div className={styles.formGroupWrapper}>
        {trafficAutomatedFields}
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              Automated fields
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomatedFields;

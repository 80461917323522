import { RateCardMerchTypes } from '../../constants/rateCard';

const BundlesConfig = {
  'Premium Prime Day Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day Hero ASIN Shoveler Inclusion',
      'Prime Day Dedicated Shoveler',
      'Themed Endcap Inclusion',
    ],
  },
  'Prime Day Exclusive Shoveler': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day Dedicated Shoveler',
    ],
  },
  'Prime Day Inclusion': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day Blended Shoveler Inclusion',
    ],
  },
  'Omnichannel Deal Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Basic Deal Shoveler',
      'Themed Endcap Inclusion',
    ],
  },
  'Omnichannel Event Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Themed Endcap Inclusion',
    ],
  },
  'Omnichannel Event': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Themed Endcap Inclusion',
      'Dedicated Event Shoveler',
    ],
  },
  'Omnichannel Event/Deal Feature Plus': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Themed Endcap Inclusion',
      'Dedicated Event Enhanced Shoveler',
      'Circular Feature',
    ],
  },
  'Omnichannel Launch Package': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Storefront Side Tile Hero',
      'Circular Feature',
    ],
  },
  'Online Deal Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Basic Deal Shoveler',
    ],
  },
  'Online Deal Page Hero Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deal Hero Feature',
    ],
  },
  'Online Fresh Find': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Fresh Find Hero',
    ],
  },
  'Online Animated Storefront Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Animated Storefront Hero Tile',
    ],
  },
  'Online Storefront Tile Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Hero Tile',
    ],
  },
  'Online Storefront Shoveler Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler Basic Feature',
    ],
  },
  'VSSM Storefront Shoveler': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'VSSM Storefront Shoveler',
    ],
  },
  'Online Storefront Shoveler Feature Plus': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler Feature Plus',
    ],
  },
  'Online Enhanced Storefront Shoveler Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Enhanced Storefront Shoveler Feature',
    ],
  },
  'Online Category Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Page Hero',
    ],
  },
  'Online Category Shoveler Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Page Shoveler',
    ],
  },
  'VSSM Category Shoveler': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'VSSM Category Shoveler',
    ],
  },
  'Online Enhanced Category Shoveler Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Enhanced Category Page Shoveler',
    ],
  },
  'Online Checkout Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Checkout Page Shoveler',
    ],
  },
  'Online Tier A Event Dedicated Inclusion': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'Online Tier B Event Dedicated Inclusion': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'Online Tier C Event Dedicated Inclusion': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'In-Store Endcap - Premium (Ambient)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'In-Store Endcap - Premium (Ambient)',
    ],
  },
  'In-Store Endcap - High Visibility (Ambient)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'In-Store Endcap - High Visibility (Ambient)',
    ],
  },
  'In-Store Endcap - Limited (Ambient)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'In-Store Endcap - Limited (Ambient)',
    ],
  },
  'In-Store Endcap - Premium (Chilled)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'In-Store Endcap - Premium (Chilled)',
    ],
  },
  'In-Store Endcap - Limited (Chilled)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'In-Store Endcap - Limited (Chilled)',
    ],
  },
  'In-Store Endcap - Premium (Frozen)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'In-Store Endcap - Premium (Frozen)',
    ],
  },
  'Specialty Page Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Dedicated Specialty Page Feature',
    ],
  },
  'Targeted Storefront Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Dedicated Specialty Page Feature',
    ],
  },
  'Targeted Deals Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Targeted Deals Feature',
    ],
  },
  'SNAP Page Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'SNAP Page Feature',
    ],
  },
};

export default BundlesConfig;

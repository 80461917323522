import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Autolinker from 'autolinker';
import styles from './PopOver.module.scss';

const PopOver = (props) => {
  const {
    children,
    isOpen,
  } = props;

  return (
    <div
      className={classnames({
        [styles.popOver]: true,
        [styles.open]: isOpen,
      })}
      dangerouslySetInnerHTML={{ __html: Autolinker.link(children) }}
    />
  );
};

PopOver.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element.isRequired]).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PopOver;

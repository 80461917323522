import { connect } from 'react-redux';
import PackageHeader from './PackageHeader';
import {
  isWebsiteOmnichannelViewMode,
} from '../../../selectors/sitewide';
import { safeDateFromNow } from '../../../helpers/dateTime';
import { isLoadingComplete } from '../../../helpers/common';
import { arePackagesLoaded } from '../../../selectors/package';
import { areCampaignsLoaded } from '../../../selectors/campaign';

export const mapStateToProps = (state) => {

  return {
    isLoading: !arePackagesLoaded(state) || !areCampaignsLoaded(state),
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    safeDateFromNow,
  };
};

export default connect(mapStateToProps)(PackageHeader);

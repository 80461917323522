import { onCommentsLoad } from '../../actionCreators';
import { CommentsConfig } from '../../constants';

export default (dispatch, getState) => {
  const doPolling = () => {
    const { Comments: { isPollingAllowed, activeCampaignId } } = getState();

    if (!isPollingAllowed) {
      return;
    }

    dispatch(onCommentsLoad({
      campaignId: activeCampaignId,
    }));

    setTimeout(() => {
      doPolling();
    }, CommentsConfig.POLLING_INTERVAL);
  };

  const checkIfCanStartPolling = () => {
    const { Comments: { isPollingAllowed, activeCampaignId } } = getState();

    if (!isPollingAllowed || !activeCampaignId) {
      setTimeout(checkIfCanStartPolling, CommentsConfig.CHECK_POLLING_INTERVAL);
      return;
    }

    doPolling();
  };

  checkIfCanStartPolling();

  return null;
};

import { RateCardMerchTypes, RateCardPlacementTypes } from '../../constants/rateCard';

const LimitedPlacements = [
  // Awareness
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Online Storefront Shoveler Feature Plus',
    dollars: 125000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 13,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Online Storefront Tile Feature',
    dollars: 75000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 20,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  // Conversion
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '2023 Premium Prime Day Feature',
    dollars: 225000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 3,
    uffPlacement: 2,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '2023 Prime Day Exclusive Shoveler',
    dollars: 100000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 8,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'In-Store Endcap',
    dollars: 100000,
    isUff: 0,
    isAfs: 1,
    vendorMax: 14,
    numberPlacements: 1,
    uffPlacement: 0,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Omnichannel Deal Feature',
    dollars: 130000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Omnichannel Event Feature',
    dollars: 130000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Omnichannel Deal Spotlight',
    dollars: 190000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 6,
    numberPlacements: 3,
    uffPlacement: 1,
    afsPlacement: 2,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Omnichannel Fresh Find',
    dollars: 170000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 1,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Online Fresh Find',
    dollars: 95000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 6,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Premium Endcap - F3G only',
    dollars: 120000,
    isUff: 0,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    uffPlacement: 0,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Online Deal Page Hero Feature',
    dollars: 70000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 3,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  // Consideration
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Omnichannel Event Plus',
    dollars: 210000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 3,
    uffPlacement: 1,
    afsPlacement: 2,
  },
];

export default LimitedPlacements;

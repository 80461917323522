import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import { styledInputProps } from '../../proptypes';
import styles from './StyledInputs.module.scss';

const getLabel = (selectedOptions) => {
  return selectedOptions.map((option) => option.label).join(', ');
};

const onChange = (selectedOptions, onInputChange, options) => {
  // onChange by default sends the selectedOptions in the order they were selected,
  // not the order they are listed in the list, this function rearranges them in list order

  const selectedOptionsValues = {};
  selectedOptions.forEach((option) => { selectedOptionsValues[option] = true; });

  const valuesToSend = options.filter((option) => {
    return selectedOptionsValues[option];
  });

  onInputChange(valuesToSend);
};

const MultiSelect = ({
  inputValue,
  onInputChange,
  options,
  onBlur,
  label,
}) => {
  const [searchTerm, updateSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(inputValue || []);
  const searchRegExp = useMemo(
    () => new RegExp(_.escapeRegExp(searchTerm), 'i'),
    [searchTerm],
  );

  const selections = options.filter((option) => {
    return searchRegExp.test(option);
  }).map((option) => (
    <SelectOption value={option} label={option} key={option} />
  ));

  const emptyResults = (
    <Column alignment="center stretch" spacing="small" spacingInset="small">
      <Text alignment="center">No results</Text>
    </Column>
  );

  const onCommitChanges = () => {
    onChange(selectedOptions, onInputChange, options);
    onBlur();
  };

  return (
    <div className={styles.multiSelectContainer}>
      <Select
        onBlur={onCommitChanges}
        value={selectedOptions}
        onChange={setSelectedOptions}
        onSearch={updateSearchTerm}
        searchQuery={searchTerm}
        valueLabel={getLabel}
        placeholder={label}
        size="small"
        width="100%"
        autoFocus
      >
        {selections}
        {!selections.length && emptyResults}
      </Select>
    </div>
  );
};

MultiSelect.propTypes = {
  ...styledInputProps,
  options: PropTypes.arrayOf(PropTypes.string),
};

MultiSelect.defaultProps = {
  options: [],
};

export default MultiSelect;

import { ThirdPartyIFrameActions } from '../../constants';

export const initialState = {
  id: null,
  packageId: null,
  src: null,
  composerEnabled: true,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case ThirdPartyIFrameActions.SET_DESTINATION: {
      return {
        ...state,
        src: action.payload,
      };
    }
    case ThirdPartyIFrameActions.SET_ID: {
      const { payload: {  id, packageId } } = action;
      return {
        ...state,
        id,
        packageId,
      };
    }
    case ThirdPartyIFrameActions.CLOSE: {
      return {
        ...state,
        src: null,
        id: null,
        packageId: null,
      };
    }
    case ThirdPartyIFrameActions.ENABLE_EXPERIENCE: {
      return {
        ...state,
        composerEnabled: true,
      };
    }
    default: {
      return state;
    }
  }
};

import { connect } from 'react-redux';
import FeatureNotification from './FeatureNotification';
import { onCloseAllNotifications } from '../../actionCreators';

const mapStateToProps = ((state) => {
  const { Sitewide: { notifications } } = state;

  return {
    notifications,
  };
});

const mapDispatchToProps = (dispatch) => {
  return {
    onClose: (version) => () => {
      dispatch(onCloseAllNotifications(version));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureNotification);

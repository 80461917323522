import React from 'react';
import Alert from '@amzn/meridian/alert';
import WithHistoryTiming from '../../WithHistoryTiming';
import { PageNames } from '../../../constants';
import RateCardContainer from '../../RateCardContainer';
import { campaignFormProps } from '../../../proptypes';

function EditRateCardPage({ rateCardId, shouldRender }) {
  if (!shouldRender) {
    return <Alert type="error">No Rate Card found! Please re-open the card using the list view.</Alert>;
  }

  return (
    <RateCardContainer isNew={false} id={rateCardId} />
  );
}

EditRateCardPage.propTypes = campaignFormProps;

export default WithHistoryTiming(EditRateCardPage, { pageName: PageNames.RATE_CARD_EDIT });

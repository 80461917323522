import { connect } from 'react-redux';
import NotificationsList from './NotificationsList';
import {
  onNotificationsLoad,
} from '../../actionCreators';
import { getDefaultInitialLoadStartDateTime } from '../../helpers/customerNotifications';
import {
  getNotificationsUnreadCount,
  getPaginatedNotifications,
} from '../../selectors/notificationsSelectors';
import { isLoadingComplete } from '../../helpers/common';
import { arePackagesLoaded } from '../../selectors/package';
import { areCampaignsLoaded } from '../../selectors/campaign';

export const mapStateToProps = (state) => {
  const {
    Notifications: { isLoading, isUpdating },
  } = state;

  return {
    notificationsList: getPaginatedNotifications(state),
    notificationsCount: getNotificationsUnreadCount(state),
    isLoading: !arePackagesLoaded(state) || !areCampaignsLoaded(state) || isLoading,
    isUpdating,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onNotificationsLoad: () => {
      dispatch(onNotificationsLoad({
        startDateTime: getDefaultInitialLoadStartDateTime(),
      }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);

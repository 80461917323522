import React from 'react';
import BulletinBoard from '../BulletinBoard';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const BulletinBoardPage = () => {
  return <BulletinBoard />;
};

export default WithHistoryTiming(BulletinBoardPage, { pageName: PageNames.BULLETIN_BOARD });

import { SiteWideActions } from '../constants/sitewide';

export const onSwitchWebsiteViewMode = (viewMode) => ({
  type: SiteWideActions.SWITCH_WEBSITE_VIEW_MODE,
  payload: {
    viewMode,
  },
});

export const setAppSyncConnectionStatus = (
  isAlive,
  connectionType,
  error,
) => ({
  type: SiteWideActions.SET_APP_SYNC_CONNECTION_STATUS,
  payload: {
    isAlive,
    connectionType,
    error,
  },
});

export const setWebsiteReadOnlyMode = (isReadOnlyMode) => ({
  type: SiteWideActions.SET_WEBSITE_READ_ONLY_MODE,
  payload: {
    isReadOnlyMode,
  },
});
import { connect } from 'react-redux';
import Scheduling from './Scheduling';
import { getCurrentPackageActiveTab } from '../../../../../helpers/form';

export const mapStateToProps = (state) => {
  return {
    currentTab: getCurrentPackageActiveTab(state),
  };
};

export default connect(mapStateToProps)(Scheduling);
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';

import styles from './ThirdPartyIFrame.module.scss';
// const debug = 'http://localhost:3000/#/redirectLanding?uploadedFiles=https%3A%2F%2Fm.media-amazon.com%2Fimages%2FG%2F01%2Fcomposer%2Fuploads%2FMulti-Tile-Old%2Ffilename_h1a33tk.jpg';

const ThirdPartyIFrame = ({
  src,
  onThirdPartyIFrameClose,
}) => {
  if (!src) {
    return null;
  }
  return (
    <div className={styles.iframeWrapper}>
      <div className={styles.iframeCloseButton}>
        <Button onClick={onThirdPartyIFrameClose} data-testid="close">Close</Button>
      </div>
      <div className={styles.iframeContainer}>
        <iframe
          src={src}
          title="Composer"
          className={styles.iframe}
        />
      </div>
    </div>
  );
};

ThirdPartyIFrame.propTypes = {
  src: PropTypes.string,
  onThirdPartyIFrameClose: PropTypes.func.isRequired,
};

ThirdPartyIFrame.defaultProps = {
  src: null,
};

export default ThirdPartyIFrame;

import { Form } from '../../constants';
import { arrayRemove, formValueSelector } from 'redux-form';

const selector = formValueSelector(Form.NAME);
export default (dispatch, getState, action, next) => {
  const state = getState();
  const { payload: { tabIndex } } = action;

  const campaigns = selector(state, 'campaigns') || [];
  if (!campaigns || !campaigns.length || !campaigns[tabIndex]) {
    return next(action);
  }

  dispatch(arrayRemove(Form.NAME, 'campaigns', tabIndex));

  return next(action);
}
import { connect } from 'react-redux';
import {
  onCreateCsCampaignSetCampaignData,
} from '../../../actionCreators';

import BusinessGroup from './BusinessGroup';

export const mapStateToProps = (state) => {
  const {
    CreateSymphonyCsCampaign: { creativeData: { businessGroup = '' }, businessGroups },
    Sitewide: { selectedBusiness },
  } = state;

  const businessGroupOptions = businessGroups[selectedBusiness] || [];
  return {
    businessGroup,
    businessGroups: businessGroupOptions,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onChange(businessGroup) {
      dispatch(onCreateCsCampaignSetCampaignData({ creativeData: { businessGroup } }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessGroup);

import React from 'react';
import PropTypes from 'prop-types';
import Input from '@amzn/meridian/input';

const Description = ({ description, onChange }) => {
  return (
    <Input
      value={description}
      size="small"
      onChange={onChange}
    />
  );
};

Description.propTypes = {
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Description;

import { createSelector } from 'reselect';
import { getActiveCell } from 'src/selectors/gridView';
import { isPackage } from '../helpers/package';
import {
  getDependentOptionsForFieldOnItem
} from '../helpers/getMetadataOptions';
import { getPackage } from './package';
import { CampaignColumns, CampaignTypes } from '../constants';
import { filterInStoreStatusOptions } from '../helpers/filterInStoreStatuses';
import { isInStoreCampaign } from '../helpers/campaign';

export const getMetadataFields = (state) => state.Meta.metadata.fields;

export const getColumnMetadata = createSelector(
  [
    getMetadataFields,
    (state, columnName) => columnName,
  ],
  (metadataFields, columnName) => {
    return metadataFields[columnName] || [];
  },
);

export const getColumnOptions = createSelector(
  [
    (state, columnName) => getColumnMetadata(state, columnName),
    (state, columnName, item) => item,
  ],
  (columnMetadata, item) => {
    if (!columnMetadata) {
      return [];
    }
    const { options, dependsOn } = columnMetadata;

    return getDependentOptionsForFieldOnItem(
      options,
      item,
      dependsOn,
    );
  },
);

export const getMetadataOptionsForInput = createSelector(
  [
    (state) => state,
    getMetadataFields,
    getActiveCell,
  ],
  (state, metadataFields, activeCell) => {
    const { item, columnName } = activeCell;

    const columnMetadata = metadataFields[columnName];
    if (!columnMetadata) {
      return [];
    }

    const { options, dependsOn } = columnMetadata;

    if (options && columnName === CampaignColumns.STATUS.name
      && item[CampaignColumns.CAMPAIGN_TYPE.name]
      && isInStoreCampaign(item)) {
        return filterInStoreStatusOptions(options);
    }
    if (options && Array.isArray(options)) {
      return options;
    }

    if (!dependsOn) {
      return [];
    }

    if (isPackage(item)) {
      return getDependentOptionsForFieldOnItem(
        options,
        item,
        dependsOn,
      );
    }

    const campaignOptionList = getDependentOptionsForFieldOnItem(
      options,
      item,
      dependsOn,
    );

    if (campaignOptionList && campaignOptionList.length) {
      return campaignOptionList;
    }

    const parentPackage = getPackage(state, item.packageId);

    return getDependentOptionsForFieldOnItem(
      options,
      parentPackage,
      dependsOn,
    );
  },
);

import { connect } from 'react-redux';
import {
  onCommentsLoad,
} from '../../actionCreators';
import { getCampaign } from '../../selectors/campaign';
import { getCommentsSelector } from '../../selectors/commentsSelector';
import CommentsList from './CommentsList';

export const getCampaignName = (state, campaignId, packageId) => {
  const campaign = getCampaign(state, campaignId, packageId);
  if (campaign && campaign.campaignName) {
    return campaign.campaignName;
  }
  return '';
};

export const mapStateToProps = (state, ownProps) => {
  const { campaignId, packageId, isModalView } = ownProps;

  const {
    Comments: { isPosting, isAutoScrollingEnabled },
  } = state;

  const campaignName = isModalView
    ? getCampaignName(state, campaignId, packageId)
    : null;

  return {
    commentsList: getCommentsSelector(state),
    isPosting,
    isAutoScrollingEnabled,
    campaignName,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onLoadComments: (campaignId) => {
      dispatch(onCommentsLoad({
        campaignId,
      }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsList);

import React from 'react';
import Alert from '@amzn/meridian/alert';
import Link from '@amzn/meridian/link';

const AdLeftSlotWarning = ({ showWarning }) => {
  return showWarning ? (
    <Alert type="warning">
      {'Click '}
      <Link
        href="https://w.amazon.com/bin/view/F3/RetailMarketing/Storefront/HouseContent/#HStorefrontContentGuardrails"
        // to open link in new tab
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </Link>
      {' to review guardrails for content submissions on '}
      <b>ad-left</b>
      {' slots'}
    </Alert>
  ) : null;
};

export default AdLeftSlotWarning;

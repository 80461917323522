export const getPaginationBounds = (items, pageNumber, itemsPerPage) => {
  const minPage = 0;
  const maxPage = items.length <= itemsPerPage
    ? minPage
    : Math.ceil(items.length / itemsPerPage);

  let currentPage = pageNumber - 1;
  if (currentPage < minPage) {
    currentPage = minPage;
  }
  if (currentPage > maxPage) {
    currentPage = maxPage;
  }

  return [itemsPerPage * currentPage, itemsPerPage * (currentPage + 1)];
};

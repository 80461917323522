import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@amzn/meridian/tooltip';
import Icon from '@amzn/meridian/icon';
import Badge from '@amzn/meridian/badge';
import viewTokens from '@amzn/meridian-tokens/base/icon/view';
import { Language } from '../../../constants';
import styles from './GridViewFilterControl.module.scss';
import GridViewFilterControlMenu from '../GridViewFilterControlMenu';

import { filterProps, sortProps } from '../../../proptypes';

const GridViewFilterControl = ({ filters, sorts }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    setIsOpen(true);
  };

  const backdropClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setIsOpen(false);
  };

  const filterLength = filters.length + sorts.length;

  const button = (
    <Tooltip position="left" title={Language.FILTER_CONTROL_BUTTON}>
      <div
        className={styles.outer}
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyPress={onClick}
        data-testid="button"
      >
        <div className={styles.gridViewFilterControl}>
          <div className={styles.iconWrapper}>
            <Icon tokens={viewTokens} />
          </div>
          <div className={styles.badgeWrapper}>
            <Badge value={filterLength} />
          </div>
        </div>
      </div>
    </Tooltip>
  );

  const backdrop = (
    <div
      className={styles.backdrop}
      onClick={backdropClick}
      role="button"
      tabIndex={0}
      onKeyPress={onClick}
      data-testid="backdrop"
    />
  );

  return (
    <>
      {isOpen && <GridViewFilterControlMenu setIsOpen={setIsOpen} />}
      {isOpen && backdrop}
      {button}
    </>
  );
};

GridViewFilterControl.propTypes = {
  filters: PropTypes.arrayOf(filterProps).isRequired,
  sorts: PropTypes.arrayOf(sortProps).isRequired,
};

export default GridViewFilterControl;

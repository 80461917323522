import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import RadioButton from '@amzn/meridian/radio-button';

import { ExportOptions, ExportOptionsTypes } from '../../constants';

import styles from './ExportModal.module.scss';

const { PAGE_SELECTION, EXPORT_TYPE, EXPORT_FILTERS } = ExportOptionsTypes;
const { [PAGE_SELECTION]: pageSelectionOptions, [EXPORT_TYPE]: exportTypeOptions, [EXPORT_FILTERS]: exportFilterOptions } = ExportOptions;
const ExportModal = ({
  isOpen,
  isOmnichannelMode,
  onExport,
  onCloseModal,
  onSelect,
  pageSelection,
  exportType,
  exportFilters,
}) => {
  const onClickExport = () => {
    onExport({ pageSelection, exportType, exportFilters });
    onCloseModal();
  };
  const setSelection = (option) => (value) => {
    if (option === PAGE_SELECTION) {
      onSelect({ pageSelection: value });
    } else if (option === EXPORT_TYPE) {
      onSelect({ exportType: value });
    }
    else if (option === EXPORT_FILTERS) {
      onSelect({ exportFilters: value });
    }
  };
  const pageSelections = pageSelectionOptions.map((option) => {
    return (
      <div key={option.value}>
        <RadioButton
          checked={pageSelection === option.value}
          onChange={setSelection(PAGE_SELECTION)}
          name={PAGE_SELECTION}
          value={option.value}
        >
          {option.display}
        </RadioButton>
      </div>
    );
  });

  const exportTypes = exportTypeOptions.map((option) => {
    return (
      <div key={option.value}>
        <RadioButton
          checked={exportType === option.value}
          onChange={setSelection(EXPORT_TYPE)}
          name={EXPORT_TYPE}
          value={option.value}
        >
          {option.display}
        </RadioButton>
      </div>
    );
  });

  const exportFiltersView = isOmnichannelMode 
    ? (
      exportFilterOptions.map((option) => {
        return (
          <div key={option.value}>
            <RadioButton
              checked={exportFilters === option.value}
              onChange={setSelection(EXPORT_FILTERS)}
              name={EXPORT_FILTERS}
              value={option.value}
            >
              {option.display}
            </RadioButton>
          </div>
        )
      })
    ) : null;

  return (
    <Modal
      open={isOpen}
    >
      <div className={styles.header}>Page Selection</div>
      {pageSelections}
      <div className={styles.header}>Export Type</div>
      {exportTypes}
      {isOmnichannelMode ? <div className={styles.header}>Export Mode</div> : null}
      {exportFiltersView}

      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button type="secondary" size="small" onClick={onCloseModal}>Cancel</Button>
          <Button
            type="secondary"
            size="small"
            onClick={onClickExport}
          >
            Export
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

ExportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onExport: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  pageSelection: PropTypes.string.isRequired,
  exportType: PropTypes.string.isRequired,
  exportFilters: PropTypes.string.isRequired,
};

export default ExportModal;

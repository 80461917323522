import { connect } from 'react-redux';
import { CampaignColumns } from '../../constants';
import { getCampaignColumnNames } from '../../helpers/campaign';
import { getPackageColumnNames } from '../../helpers/package';
import GridViewRow from './GridViewRow';
import {
  onSetExpandedRowsOverride,
} from '../../actionCreators';
import {
  isWebsiteOmnichannelViewMode,
} from '../../selectors/sitewide';
import { isActiveRow, isRowExpanded } from '../../selectors/gridView';

const isCellDisabled = (item, columnName, supportedCells) => {
  const isUnsupportedCell = (
    supportedCells
    && supportedCells.length
    && !supportedCells.includes(columnName)
  );

  return !item.hasOwnProperty(columnName) || isUnsupportedCell;
};

const getValueFromCampaigns = (item = {}, columnName) => {
  const { campaigns } = item;
  if (!campaigns || campaigns.length === 0) {
    return;
  }
  const firstCampaignCellValue = getCellValue(campaigns[0], columnName);
  if (campaigns.length === 1 || campaigns.every((campaign) => getCellValue(campaign, columnName) === firstCampaignCellValue)) {
    return firstCampaignCellValue;
  }
}

const getCellValue = (item, columnName, isPackage = false, parentPackage) => {
  const isDisabledPackageCell = isPackage && !getPackageColumnNames().includes(columnName);
  const isDisabledCampaignCell = !isPackage && !getCampaignColumnNames().includes(columnName);
  if (isDisabledPackageCell) {
    return getValueFromCampaigns(item, columnName);
  }
  if (isDisabledCampaignCell && parentPackage) {
    return parentPackage[columnName];
  }

  return item[columnName];
};

const getComparedItem = (comparedRow, column) => comparedRow[column.name];

const getErrorText = (item, column) => {
  const columnName = column.name;
  if (item.errors && item.errors[columnName] && item.errors[columnName].text) {
    return item.errors[columnName].text;
  }

  return null;
};

export const mapStateToProps = (state, { item }) => {
  const { id, campaigns } = item;
  return {
    isActive: isActiveRow(state, id),
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    businessStartYear: item[CampaignColumns.BUSINESS_START_YEAR.name],
    campaignsCount: campaigns ? campaigns.length : 0,
    isRowExpanded: isRowExpanded(state, id),
    getCellValue,
    getComparedItem,
    getErrorText,
    isCellDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetExpandedRowsOverride: (packageId, isOpen) => () => {
      dispatch(onSetExpandedRowsOverride(packageId, isOpen));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewRow);

import { connect } from 'react-redux';
import MetadataPage from './metadata';
import { isUSBusiness } from '../../../helpers/common';
import { getSelectedBusiness } from '../../../selectors/sitewide';
import {
  isCurrentUserAdmin,
  isCurrentUserMetadataAdmin,
} from '../../../selectors/user';

export const mapStateToProps = (state) => {
  return {
    hasAccess: (
      isUSBusiness(getSelectedBusiness(state))
        ? isCurrentUserAdmin(state) || isCurrentUserMetadataAdmin(state)
        : true
    )
  };
};

export default connect(mapStateToProps)(MetadataPage);

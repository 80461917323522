import { connect } from 'react-redux';
import UtilizationTable from './UtilizationTable';
import { isGenericFreshBusiness } from '../../../helpers/common';

export const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      selectedFilters,
      daysInDateRange,
      selectedDateRange,
      isAbsStatsValuesOnly,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  return {
    selectedFilters,
    daysInDateRange,
    selectedDateRange,
    isAbsStatsValuesOnly,
    isFreshBusiness: isGenericFreshBusiness(selectedBusiness),
  };
};

export default connect(mapStateToProps)(UtilizationTable);

import React from 'react';
import NotificationsList from '../NotificationsList';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const NotificationsPage = () => {
  return <NotificationsList />;
};

export default WithHistoryTiming(NotificationsPage, { pageName: PageNames.NOTIFICATIONS });

import { CampaignColumns, CampaignTypes } from '../constants/index';

const IN_STORE_ONLY_PACKAGES = {
  'In-Store Endcap - Premium (Ambient)': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Premium In-Store Endcap - Ambient',
      }
    ],
  },
  'In-Store Endcap - High Visibility (Ambient)': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'High-Visibility Endcap',
      }
    ],
  },
  'In-Store Endcap - Limited (Ambient)': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Limited In-Store Endcap - Ambient',
      }
    ],
  },
  'In-Store Endcap - Premium (Chilled)': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Premium In-Store Endcap - Chilled',
      }
    ],
  },
  'In-Store Endcap - Limited (Chilled)': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Limited In-Store Endcap - Chilled',
      }
    ],
  },
  'In-Store Endcap - Premium (Frozen)': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Premium In-Store Endcap - Frozen',
      }
    ],
  },
  'House - In-Store Only': {
    [CampaignTypes.ONLINE]: [],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: '',
      }
    ],
  },
};

export default IN_STORE_ONLY_PACKAGES;
import { connect } from 'react-redux';

import Notifications from './Notifications';

export const mapStateToProps = (state) => {
  const { Notifications: { isWidgetOpen } } = state;
  return {
    isWidgetOpen,
  };
};

export default connect(mapStateToProps)(Notifications);

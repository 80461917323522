import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CalendarWeek from '../CalendarWeek';
import styles from '../Calendar.module.scss';

const commonRender = (events) => events.map((event) => {
  const { start, end, name } = event;

  const startDate = moment(start).format('MMM Do');
  const endDate = moment(end).format('MMM Do');

  return {
    start,
    end,
    render: (props) => {
      return (
        <CalendarWeek
          {...props}
        >
          <h3 className={styles.weekTitle}>{name}</h3>
          <p className={styles.weekContent}>
            Running on
            {' '}
            {startDate}
            {' '}
            -
            {' '}
            {endDate}
          </p>
        </CalendarWeek>
      );
    },
  };
});

commonRender.propTypes = {
  events: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    name: PropTypes.string,
  }),
};
commonRender.defaultProps = {
  events: [],
};

export default commonRender;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '@amzn/meridian/text';
import styles from '../../PackageForm.module.scss';
import { formCampaignColumns } from '../../../../proptypes';

const AutomatedFieldsSummary = (props) => {
  const {
    campaigns,
  } = props;

  const getAutoFieldsList = () => {
    if (!campaigns || !campaigns.length) {
      return null;
    }

    const autoFieldsList = campaigns.map(({ refMarker, campaignName }, index) => {
      const key = refMarker || index;

      return (
        <li key={key} className={styles.refMarkerItem}>
          <Text>Ref Marker: {refMarker} | Campaign name: {campaignName}</Text>
        </li>
      );
    });

    return (
      <div className={styles.formGroupSection}>
        <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
          <ul className={styles.refMarkerList}>
            {autoFieldsList}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Automated fields</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          {getAutoFieldsList()}
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
AutomatedFieldsSummary.propTypes = {
  ...formCampaignColumns,
  isNew: PropTypes.bool.isRequired,
  id: PropTypes.string,
  assetEditCompatibleMode: PropTypes.bool.isRequired,
};

AutomatedFieldsSummary.defaultProps = {
  id: null,
};

export default AutomatedFieldsSummary;

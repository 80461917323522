import moment from 'moment-timezone';
import { SET_USER, LOG_OUT_USER, DEFAULT_TIMEZONE_ID } from '../../constants';
import { signOut } from '../../helpers/cognito';
import { onSetUser, onSetUserTimezone } from '../../actionCreators';
import { setWebsiteReadOnlyMode } from '../../actionCreators/sitewide';

export default ({ dispatch }) => (next) => (action) => {
  if (action.type === SET_USER) {
    const { payload: { isReadOnly } } = action;
    if (isReadOnly) {
      dispatch(setWebsiteReadOnlyMode(true));
    }

    dispatch(onSetUserTimezone((moment.tz.guess() || DEFAULT_TIMEZONE_ID)));

    return next(action);
  }
  if (action.type === LOG_OUT_USER) {
    dispatch(onSetUser(null));
    signOut();
    return next(action);
  }

  return next(action);
};

import { onAddNotifications } from '../../actionCreators';
import {
  FeatureNotificationActions,
  FEATURE_NOTIFICATION_STORAGE_KEY,
} from '../../constants';
import versionHistory from '../../version';
import { isInteger } from '../../helpers/common';

const getClientScriptVersion = () => {
  const value = parseInt(window.localStorage.getItem(FEATURE_NOTIFICATION_STORAGE_KEY), 10);
  return isInteger(value) ? value : -1;
};

const setClientScriptVersion = (version) => {
  try {
    window.localStorage.setItem(FEATURE_NOTIFICATION_STORAGE_KEY, parseInt(version, 10));
  } catch (e) {
    // TODO: error reporting
    /* eslint-disable no-console */
    console.error(e);
  }
};

const validOnCloseActions = [
  FeatureNotificationActions.CLOSE,
  FeatureNotificationActions.CLOSE_BULK,
];

export default ({ getState, dispatch }) => (next) => (action) => {
  const { type, data } = action;
  if (validOnCloseActions.includes(type)) {
    const { version } = data;
    const { Sitewide: { notifications } } = getState();

    if (!notifications.find((notification) => notification.version === version)) {
      // TODO: error reporting
      return null;
    }

    setClientScriptVersion(version);

    return next(action);
  }

  if (type === FeatureNotificationActions.LOAD) {
    const serverScriptVersionHistoryList = versionHistory();
    let clientVersion = getClientScriptVersion();

    if (clientVersion === -1) {
      const startFromVersion = serverScriptVersionHistoryList.length - 1;
      clientVersion = startFromVersion;

      setClientScriptVersion(startFromVersion);
    }

    const notifications = [];
    for (let serverVersion = 0;
      serverVersion < serverScriptVersionHistoryList.length;
      serverVersion++) {
      if (serverVersion > clientVersion) {
        notifications.push({
          ...serverScriptVersionHistoryList[serverVersion],
          version: serverVersion,
        });
      }
    }

    if (notifications.length > 0) {
      return dispatch(onAddNotifications(notifications));
    }
  }

  return next(action);
};

import graphqlAction from './graphqlAction';
import { getMetadata, getMetadataAuditLogsByBusiness } from '../graphql/queries';
import { updateMetadata } from '../graphql/mutations';
import {
  KEYPRESS,
  PackageActions,
  CampaignActions,
  GridViewActions,
  FeatureNotificationActions,
  DashboardActions,
  LOAD_PACKAGES,
  SET_PACKAGES,
  SET_CAMPAIGNS,
  LOAD_CAMPAIGNS,
  ON_METADATA_RECEIVED,
  ON_ADD_METADATA_ENTRY,
  ON_REMOVE_METADATA_ENTRY,
  ON_CLEAR_METADATA,
  PAGE_INIT,
  UtilizationActions,
  SELECT_BUSINESS,
  SELECT_YEARS,
  OPEN_TOAST,
  CLOSE_TOAST,
  TOGGLE_SIDE_MENU,
  ON_UPDATE_METADATA,
  ADD_ERROR_TO_CAMPAIGN,
  NOOP,
  UPLOAD_LOCAL_METADATA,
  SHOW_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  SET_USER,
  SET_USER_UNABLE_TO_SIGN_IN,
  SET_USER_TIMEZONE,
  LOG_OUT_USER,
  CreateCsCampaignActions,
  MetadataEditorActions,
  BulkImportActions,
  BulletinBoardActions,
  AuditLogActions,
  SET_NEW_USER_EXPERIENCE_SHOWN,
  CustomViewActions,
  ThirdPartyIFrameActions,
  NotificationsActions,
  CommentActions,
  PackageTabActions,
  TimerActions,
  MetadataAuditLogActions,
  FormActions,
  PerformanceTimerActions,
  FlashFillActions,
  RateCardActions,
  AuditLogTypes,
  BulkImportWorkMode,
  ON_RECEIVED_LAST_CAMPAIGN_IDS,
  ON_BUSINESS_RECEIVED,
  ON_MIGRATE_LOCAL_TO_CLOUD,
  ON_YEARS_RECEIVED,
  SubscriptionActions,
  ASSIGN_CAMPAIGNS_TO_PACKAGES,
  SET_PACKAGES_WITH_CAMPAIGNS,
  LoadingStatusActions,
} from '../constants';
import wfmMetadata from '../fixtures/wfmMetadata';
import freshMetadata from '../fixtures/metadata';
import { MediaServiceActions } from '../constants/digitalCirculatorFileUploader';

export const localMetadatas = {
  Fresh: freshMetadata,
  WFM: wfmMetadata,
};

export const onKeyboardPressAction = (event) => ({ type: KEYPRESS, data: event });
export const onSelectCell = (data) => ({
  type: GridViewActions.SELECT_CELL,
  payload: data,
});
export const onActiveCellInputUpdate = (value) => ({
  type: GridViewActions.ACTIVE_CELL_INPUT_UPDATE,
  payload: {
    value,
  },
});
export const onResetActiveCell = () => ({ type: GridViewActions.RESET_ACTIVE_CELL });
export const onActiveCellInputSubmit = () => ({ type: GridViewActions.ACTIVE_CELL_INPUT_SUBMIT });
export const onAddPackage = (newPackage, options = {}) => {
  const {
    isClone = false,
    isBulkAction = false,
    hasFailedSlotsValidation = false,
  } = options;

  return {
    type: PackageActions.ADD_PACKAGE,
    payload: {
      newPackage,
      options: {
        isClone,
        isBulkAction,
        hasFailedSlotsValidation
      },
    },
  }
};
export const onEditPackage = (updatedPackage, options = {}) => {
  const {
    isBulkAction = false,
    skipCampaignsUpdate = false,
  } = options;

  return {
    type: PackageActions.EDIT_PACKAGE,
    payload: {
      updatedPackage,
      options: {
        isBulkAction,
        skipCampaignsUpdate,
      }
    },
  }
};
export const onDeletePackage = (currentPackage, options = {}) => {
  const { isBulkAction = false } = options;

  return {
    type: PackageActions.DELETE_PACKAGE,
    payload: {
      currentPackage,
      options: {
        isBulkAction,
      }
    },
  };
};
export const loadCampaigns = (
  business,
  startYear,
  nextToken = null,
) => ({
  type: LOAD_CAMPAIGNS,
  payload: {
    business,
    startYear,
    nextToken,
  },
});
export const loadPackages = (
  business,
  startYear,
  nextToken = null,
) => ({
  type: LOAD_PACKAGES,
  payload: {
    business,
    startYear,
    nextToken,
  },
});
export const setPackagesWithCampaigns = (businessStartYear, combinedPackagesForBusinessStartYear) => ({
  type: SET_PACKAGES_WITH_CAMPAIGNS,
  payload: {
    businessStartYear,
    combinedPackagesForBusinessStartYear,
  },
});
export const setPackages = (businessStartYear, packages) => ({
  type: SET_PACKAGES,
  payload: {
    businessStartYear,
    packages,
  },
});
export const setCampaigns = (businessStartYear, campaigns) => ({
  type: SET_CAMPAIGNS,
  payload: {
    businessStartYear,
    campaigns,
  },
});
export const setCampaignsLoadingStarted = (businessStartYear) => ({
  type: LoadingStatusActions.SET_CAMPAIGNS_LOADING_STATUS,
  payload: { businessStartYear, isLoading: true },
});
export const setCampaignsLoadingFinished = (businessStartYear) => ({
  type: LoadingStatusActions.SET_CAMPAIGNS_LOADING_STATUS,
  payload: { businessStartYear, isLoading: false },
});
export const setPackagesLoadingStarted = (businessStartYear) => ({
  type: LoadingStatusActions.SET_PACKAGES_LOADING_STATUS,
  payload: { businessStartYear, isLoading: true },
});
export const setPackagesLoadingFinished = (businessStartYear) => ({
  type: LoadingStatusActions.SET_PACKAGES_LOADING_STATUS,
  payload: { businessStartYear, isLoading: false },
});
export const assignCampaignsToPackages = (businessStartYear) => ({
  type: ASSIGN_CAMPAIGNS_TO_PACKAGES,
  payload: { businessStartYear },
});
export const onGetLastUsedCampaignIds = (ids) => ({
  type: ON_RECEIVED_LAST_CAMPAIGN_IDS,
  data: ids,
});
export const onMoveActiveCell = (direction) => ({
  type: GridViewActions.MOVE_ACTIVE_CELL,
  payload: { direction },
});
export const onAddCampaign = (packageId, business_startYear, campaign, options = {}) => {
  const {
    isClone = false,
    isBulkAction = false,
    hasFailedSlotsValidation = false,
  } = options;

  return {
    type: CampaignActions.ADD_CAMPAIGN,
    payload: {
      packageId,
      business_startYear,
      campaign,
      options: {
        isClone,
        isBulkAction,
        hasFailedSlotsValidation,
      },
    },
  };
};
export const onEditCampaign = (campaign) => ({
  type: CampaignActions.EDIT_CAMPAIGN,
  payload: {
    campaign,
  },
});
export const onMetadataReceived = (data) => ({
  type: ON_METADATA_RECEIVED,
  data,
});
export const onClearMetadata = () => ({
  type: ON_CLEAR_METADATA,
});
export const onPerformMetadataUpdate = () => ({
  type: ON_UPDATE_METADATA,
});
export const onFetchMetadataAction = (business, useLocalMetadata = false) => {
  if (!useLocalMetadata) {
    return graphqlAction({
      graphqlAction: getMetadata,
      data: { business },
      onSuccessAction: onMetadataReceived,
      label: 'fetchMetadata',
    });
  }
  try {
    const localMetadata = { data: { getMetadata: { metadata: JSON.stringify(localMetadatas[business]) || '', version: 1 } } };
    return onMetadataReceived(localMetadata);
  } catch {
    return { type: NOOP };
  }
};
export const onUpdateMetadataAction = (
  username,
  business,
  metadata,
  version,
  onFailureFunction = () => {},
  onSuccessFunction = () => {},
) => graphqlAction({
  graphqlAction: updateMetadata,
  data: {
    input: {
      metadata: {
        business,
        metadata,
        version,
      },
      username,
    },
  },
  label: 'updateMetadata',
  onSuccessFunction,
  onFailureFunction,
});
export const onDeleteCampaign = (campaign, options = {}) => {
  const { isBulkAction = false } = options;

  return {
    type: CampaignActions.DELETE_CAMPAIGN,
    payload: {
      campaign,
      options: {
        isBulkAction,
      }
    },
  };
};
export const onSetFilters = (data) => ({
  type: GridViewActions.SET_FILTERS,
  data,
});
export const onAddFilter = (data) => ({
  type: GridViewActions.ADD_FILTER,
  data,
});
export const onSetSorts = (data) => ({
  type: GridViewActions.SET_SORTS,
  data,
});
export const onSetActiveFilterView = (data) => ({
  type: GridViewActions.SET_ACTIVE_FILTER_VIEW,
  data,
});
export const onOpenFilterViewModal = (data) => ({
  type: GridViewActions.OPEN_FILTER_VIEW_MODAL,
  data,
});
export const onCloseFilterViewModal = () => ({
  type: GridViewActions.CLOSE_FILTER_VIEW_MODAL,
});
export const onSaveFilterView = (data) => ({
  type: GridViewActions.SAVE_FILTER_VIEW,
  data,
});
export const onDeleteFilterView = (data) => ({
  type: GridViewActions.DELETE_FILTER_VIEW,
  data,
});
export const onPageInit = () => ({
  type: PAGE_INIT,
});
export const onSetPaginationPageAction = (data) => ({
  type: GridViewActions.SET_PAGE,
  data,
});
export const toggleActionBar = () => ({
  type: GridViewActions.TOGGLE_ACTION_BAR,
});
export const onUtilizationFilterOptionsCreated = (data) => ({
  type: UtilizationActions.FILTER_OPTIONS_CREATED,
  data,
});
export const onUtilizationFilterChange = (filterOptionKey, filterOptionValue, selectedFilter) => ({
  type: UtilizationActions.FILTER_CHANGE,
  data: {
    filterOptionKey,
    filterOptionValue,
    selectedFilter,
  },
});
export const onUtilizationBusinessChanged = (data) => ({
  type: UtilizationActions.BUSINESS_CHANGED,
  data,
});
export const onUtilizationSelectDateRange = (data) => ({
  type: UtilizationActions.SELECT_DATE_RANGE,
  data,
});
export const onUtilizationSelectTimezone = (data) => ({
  type: UtilizationActions.SELECT_TIMEZONE,
  data,
});
export const onUtilizationShowResults = () => ({
  type: UtilizationActions.SHOW_RESULTS,
});
export const onUtilizationSetResults = (data) => ({
  type: UtilizationActions.SET_RESULTS,
  data,
});
export const onUtilizationOpenFiltersModal = (data) => ({
  type: UtilizationActions.OPEN_FILTERS_MODAL,
  data,
});
export const onUtilizationCloseFiltersModal = (data) => ({
  type: UtilizationActions.CLOSE_FILTERS_MODAL,
  data,
});
export const onUtilizationStartTimeSet = ({ startTime }) => ({
  type: UtilizationActions.SELECT_START_TIME,
  data: {
    startTime,
  },
});
export const onUtilizationStopTimeSet = ({ endTime }) => ({
  type: UtilizationActions.SELECT_END_TIME,
  data: {
    endTime,
  },
});
export const onUtilizationToggleStatsMode = () => ({
  type: UtilizationActions.TOGGLE_STATS_MODE,
});
export const onUtilizationToggleAbsSovMode = () => ({
  type: UtilizationActions.TOGGLE_ABS_SOV_MODE,
});
export const onUtilizationSetSlotsOrder = (data) => {
  const {
    business,
    pageType,
    slots,
    slotType,
  } = data;

  return {
    type: UtilizationActions.SET_SLOTS_ORDER,
    data: {
      business,
      pageType,
      slots,
      slotType,
    },
  };
};
export const onUtilizationToggleSlotsSettings = () => ({
  type: UtilizationActions.TOGGLE_SLOTS_ORDER_SETTINGS,
});
export const onUtilizationToggleSlotsVisibility = () => ({
  type: UtilizationActions.TOGGLE_SLOTS_VISIBILITY,
});
export const onUtilizationSwitchViewMode = (viewMode) => ({
  type: UtilizationActions.SWITCH_VIEW_MODE,
  payload: {
    viewMode,
  },
});
export const onUtilizationShowFmcSlots = (flag) => ({
  type: UtilizationActions.SHOW_FMC_SLOTS,
  payload: {
    flag,
  },
});
export const onUtilizationSetSlotsViewMode = (slotsViewMode) => ({
  type: UtilizationActions.SET_SLOTS_VIEW_MODE,
  payload: {
    slotsViewMode,
  },
});
export const onAddMetadataEntry = (data) => ({
  type: ON_ADD_METADATA_ENTRY,
  data,
});
export const onRemoveMetadataEntry = (data) => ({
  type: ON_REMOVE_METADATA_ENTRY,
  data,
});
export const onBusinessSelect = ({ business, forceReload }) => ({
  type: SELECT_BUSINESS,
  data: {
    business,
    forceReload,
  },
});
export const onBusinessReceived = (data) => ({
  type: ON_BUSINESS_RECEIVED,
  data,
});
export const onStartYearsSelect = (startYears) => ({
  type: SELECT_YEARS,
  payload: {
    startYears,
  },
});
export const onStartYearsReceived = (startYears) => ({
  type: ON_YEARS_RECEIVED,
  payload: { startYears },
});
export const onMigrateLocalToCloud = (type, location, setting) => ({
  type: ON_MIGRATE_LOCAL_TO_CLOUD,
  data: {
    type,
    location,
    setting,
  },
});
export const onCreatedPackageReceived = (createdPackage) => ({
  type: GridViewActions.CREATED_PACKAGE_RECEIVED,
  payload: {
    createdPackage,
  },
});
export const onUpdatedPackageReceived = (updatedPackage) => ({
  type: GridViewActions.UPDATED_PACKAGE_RECEIVED,
  payload: {
    updatedPackage,
  },
});
export const onCreatedCampaignReceived = (campaign) => ({
  type: GridViewActions.CREATED_CAMPAIGN_RECEIVED,
  payload: { campaign },
});
export const onUpdatedCampaignReceived = (campaign) => ({
  type: GridViewActions.UPDATED_CAMPAIGN_RECEIVED,
  payload: {
    campaign,
  },
});
export const onUpdatedCampaignSubscriptionReceived = (campaignData) => ({
  type: SubscriptionActions.UPDATED_CAMPAIGN_RECEIVED,
  payload: { campaignData },
});
export const onUpdateCommentsSubscriptionRecieved = (commentData) => ({
  type: SubscriptionActions.UPDATED_COMMENTS_RECIEVED,
  payload: { commentData },
})
export const onSendPackageEdit = (id, options = {}) => {
  const {
    isBulkAction = false,
  } = options;
  return {
    type: GridViewActions.SEND_PACKAGE_EDIT,
    payload: {
      id,
      options: {
        isBulkAction,
      }
    },
  }
};
export const onSendCampaignEdit = (id, packageId) => ({
  type: GridViewActions.SEND_CAMPAIGN_EDIT,
  payload: {
    id,
    packageId,
  },
});

export const onLoadFilterViewsFromStorage = () => ({
  type: GridViewActions.LOAD_FILTER_VIEWS_FROM_STORAGE,
});
export const onSetFilterViews = (data) => ({
  type: GridViewActions.SET_FILTER_VIEWS,
  data,
});
export const onOpenToast = (message, timeout) => ({
  type: OPEN_TOAST,
  data: {
    message,
    timeout,
  },
});
export const onCloseToast = (data) => ({
  type: CLOSE_TOAST,
  data,
});
export const onExportCurrentView = (payload) => ({
  type: GridViewActions.EXPORT_CURRENT_VIEW,
  payload,
});
export const onOpenExportModal = () => ({
  type: GridViewActions.OPEN_EXPORT_MODAL,
});
export const onCloseExportModal = () => ({
  type: GridViewActions.CLOSE_EXPORT_MODAL,
});
export const onSetExportSettings = ({ pageSelection, exportType, exportFilters }) => ({
  type: GridViewActions.SET_EXPORT_SETTINGS,
  payload: { pageSelection, exportType, exportFilters },
});
export const onGetExportSettings = () => ({
  type: GridViewActions.GET_EXPORT_SETTINGS,
});
export const onUploadExportSettings = ({ pageSelection, exportType, exportFilters }) => ({
  type: GridViewActions.UPLOAD_EXPORT_SETTINGS,
  payload: { pageSelection, exportType, exportFilters },
});
export const toggleSideMenu = () => ({
  type: TOGGLE_SIDE_MENU,
});
export const onUpdateCampaignsSov = () => ({
  type: GridViewActions.UPDATE_CAMPAIGNS_SOV,
});
export const onAddErrorToCampaign = (campaign, column, message) => ({
  type: ADD_ERROR_TO_CAMPAIGN,
  data: { campaign, column, message },
});
export const onUploadLocalMetadata = (business, metadata) => {
  let metadataToUpload = metadata;
  if (!metadata) {
    if (!localMetadatas[business]) { return { type: NOOP }; }
    metadataToUpload = localMetadatas[business];
  }
  return {
    type: UPLOAD_LOCAL_METADATA,
    data: {
      business,
      metadata: metadataToUpload,
    },
  };
};
export const onShowLoadingSpinner = () => ({
  type: SHOW_LOADING_SPINNER,
});
export const onHideLoadingSpinner = () => ({
  type: HIDE_LOADING_SPINNER,
});
export const onLoadNotifications = () => ({
  type: FeatureNotificationActions.LOAD,
});
export const onAddNotifications = (notifications) => ({
  type: FeatureNotificationActions.ADD_BULK,
  data: notifications,
});
export const onCloseNotification = (version) => ({
  type: FeatureNotificationActions.CLOSE,
  data: { version },
});
export const onCloseAllNotifications = (version) => ({
  type: FeatureNotificationActions.CLOSE_BULK,
  data: { version },
});
export const onUpdateCampaignColumn = (
  item,
  columnName,
  newValue
) => {
  return {
    type: GridViewActions.UPDATE_CAMPAIGN_COLUMN,
    payload: {
      item,
      columnName,
      newValue,
    }
  };
};
export const onSetUser = (payload) => ({
  type: SET_USER,
  payload,
});
export const onSetUserTimezone = (timeZone) => ({
  type: SET_USER_TIMEZONE,
  payload: { timeZone },
});
export const onSetUserUnableToSignIn = () => ({
  type: SET_USER_UNABLE_TO_SIGN_IN,
});
export const onSignOut = () => ({
  type: LOG_OUT_USER,
});
export const onParseCampaignASINs = (startYear, packageId, id) => ({
  type: GridViewActions.PARSE_CAMPAIGN_ASINS,
  payload: {
    startYear,
    packageId,
    id,
  },
});
export const onCreateCsCampaignClearData = () => ({
  type: CreateCsCampaignActions.CLEAR_DATA,
});
export const onCreateCsCampaignCloseModal = () => ({
  type: CreateCsCampaignActions.CLOSE_MODAL,
});
export const onCreateCsCampaignOpenDiffModal = () => ({
  type: CreateCsCampaignActions.OPEN_DIFF_MODAL,
});
export const onCreateCsCampaignCloseDiffModal = () => ({
  type: CreateCsCampaignActions.CLOSE_DIFF_MODAL,
});
export const onCreateCsCampaignClearValidationErrors = () => ({
  type: CreateCsCampaignActions.CLEAR_VALIDATION_ERRORS,
});
export const onCreateCsCampaignSendCampaign = (data) => ({
  type: CreateCsCampaignActions.SEND_CAMPAIGN,
  data,
});
export const onCreateCsCampaignSetCampaignData = (data) => ({
  type: CreateCsCampaignActions.SET_CAMPAIGN_DATA,
  data,
});
export const onCreateCsCampaignSetCampaign = (campaign) => ({
  type: CreateCsCampaignActions.SET_CAMPAIGN,
  payload: {
    campaign,
  }
});
export const onCreateCsCampaignSetMetadata = (data) => ({
  type: CreateCsCampaignActions.SET_METADATA,
  data,
});
export const onCreateCsCampaignOpenModal = () => ({
  type: CreateCsCampaignActions.OPEN_MODAL,
});
export const onCreateCsCampaignSetRunningStatus = (data) => ({
  type: CreateCsCampaignActions.SET_RUNNING_STATE,
  data,
});
export const onCreateCsCampaignSetValidationErrors = (data) => ({
  type: CreateCsCampaignActions.SET_VALIDATION_ERRORS,
  data,
});
export const onCreateCsCampaignCalcDiff = ({ existingCampaign, updatedCampaign }) => {
  return {
    type: CreateCsCampaignActions.CALC_CAMPAIGN_DIFF,
    data: {
      existingCampaign,
      updatedCampaign,
    },
  };
};
export const onCreateCsCampaignChangeDiffViewMode = (viewMode) => ({
  type: CreateCsCampaignActions.ON_CHANGE_DIFF_VIEW_MODE,
  data: viewMode,
});
export const onCreateCsCampaignSetDiff = (diff) => ({
  type: CreateCsCampaignActions.SET_CAMPAIGN_DIFF,
  data: diff,
});
export const onDashboardInit = () => ({
  type: DashboardActions.INIT,
});
export const onDashboardDateRangeSet = (dateRange) => ({
  type: DashboardActions.SET_DATE_RANGE,
  data: {
    dateRange,
  },
});
export const onDashboardRunCalc = () => ({
  type: DashboardActions.RUN_CALC,
});
export const onDashboardStatsSet = (stats) => ({
  type: DashboardActions.SET_DATA,
  data: {
    stats,
  },
});
export const onDashboardModeToggle = () => ({
  type: DashboardActions.TOGGLE_MODE,
});
export const onDashboardStartTimeSet = ({ startTime }) => ({
  type: DashboardActions.SET_START_TIME,
  data: {
    startTime,
  },
});
export const onDashboardEndTimeSet = ({ endTime }) => ({
  type: DashboardActions.SET_END_TIME,
  data: {
    endTime,
  },
});
export const onMetadataEditorSelectField = (field) => ({
  type: MetadataEditorActions.SELECT_FIELD,
  data: field,
});
export const onMetadataEditorSetDependencies = (dependencies) => ({
  type: MetadataEditorActions.SET_DEPENDENCIES,
  data: dependencies,
});
export const onMetadataEditorSetDependentOption = ({ name, selection }) => ({
  type: MetadataEditorActions.SET_DEPENDENT_OPTION,
  data: { name, selection },
});
export const onMetadataEditorAddOption = (option) => ({
  type: MetadataEditorActions.ADD_OPTION,
  data: option,
});
export const onMetadataEditorDeleteOption = (option) => ({
  type: MetadataEditorActions.DELETE_OPTION,
  data: option,
});
export const onMetadataEditorAddOptionForField = (data) => ({
  type: MetadataEditorActions.ADD_OPTION_FOR_FIELD,
  data,
});
export const onMetadataEditorDeleteOptionForField = (data) => ({
  type: MetadataEditorActions.DELETE_OPTION_FOR_FIELD,
  data,
});
export const onMetadataEditorSetQuickCreateModalVisibility = (isVisible) => ({
  type: MetadataEditorActions.SET_QUICK_CREATE_MODAL_VISIBILITY,
  data: isVisible,
});
export const onMetadataEditoQuickCreateModalSave = (data) => ({
  type: MetadataEditorActions.QUICK_CREATE_MODAL_SAVE,
  data,
});
export const onBulkImportProcessFile = (params) => {
  const {
    data,
    fileType,
    workMode = BulkImportWorkMode.STANDARD,
  } = params;

  return {
    type: BulkImportActions.PROCESS_FILE,
    payload: { data, fileType, workMode },
  };
};
export const onBulkImportSetPendingPackages = (processedCampaigns) => ({
  type: BulkImportActions.SET_PENDING_PACKAGES,
  payload: {
    processedCampaigns,
  }
});
export const onBulkImportSetValidationMode = (validationMode) => ({
  type: BulkImportActions.SET_VALIDATION_MODE,
  payload: {
    validationMode,
  },
});
export const onBulkImportClearPendingPackages = () => ({
  type: BulkImportActions.CLEAR_PACKAGES,
});
export const onBulkImportPerformImport = () => ({
  type: BulkImportActions.PERFORM_IMPORT,
});
export const onBulkImportActionCompleted = (refMarker, responseType) => ({
  type: BulkImportActions.ACTION_COMPLETED,
  payload: { 
    refMarker, 
    responseType 
  },
});
export const onBulkImportSetForImport = (expectedPackageCount) => ({
  type: BulkImportActions.SET_FOR_IMPORT,
  payload: {
    expectedPackageCount,
  },
});
export const onBulkImportPackageSucceeded = (refMarker) => ({
  type: BulkImportActions.PACKAGE_SUCCEEDED,
  payload: {
    refMarker,
  },
});
export const onBulkImportPackageFailed = (refMarker) => ({
  type: BulkImportActions.PACKAGE_FAILED,
  payload: {
    refMarker,
  },
});
export const onBulkImportStartImporting = () => ({
  type: BulkImportActions.START_IMPORTING,
});
export const onBulkImportCompleteImporting = () => ({
  type: BulkImportActions.COMPLETE_IMPORTING,
});
export const onBulkImportPerformAssetsImport = (rateCardId) => ({
  type: BulkImportActions.PERFORM_ASSETS_IMPORT,
  payload: {
    rateCardId,
  },
});
export const onBulkImportStartFileParsing = () => ({
  type: BulkImportActions.START_FILE_PARSING,
});
export const onBulkImportCompleteFileParsing = () => ({
  type: BulkImportActions.COMPLETE_FILE_PARSING,
});
export const onBulletinBoardLoad = () => ({
  type: BulletinBoardActions.LOAD,
});
export const onBulletinBoardSet = ({ items }) => ({
  type: BulletinBoardActions.SET,
  data: {
    items,
  },
});
export const onBulletinBoardDelete = (id) => ({
  type: BulletinBoardActions.DELETE,
  data: {
    id,
  },
});
export const onBulletinBoardAdd = (data) => ({
  type: BulletinBoardActions.ADD,
  data,
});
export const onBulletinBoardEdit = (data) => ({
  type: BulletinBoardActions.EDIT,
  data,
});
export const onAuditLogSetItem = (id, auditLogType = AuditLogTypes.CAMPAIGN) => ({
  type: AuditLogActions.SET_ITEM,
  payload: {
    id,
    auditLogType,
  },
});
export const onAuditLogFetch = (id, auditLogType) => ({
  type: AuditLogActions.FETCH,
  payload: {
    id,
    auditLogType,
  },
});
export const onAuditLogClear = () => ({
  type: AuditLogActions.CLEAR,
});
export const onAuditLogSetLoading = (isLoading) => ({
  type: AuditLogActions.SET_LOADING,
  payload: isLoading,
});
export const onAuditLogSetLoaded = (isLoaded) => ({
  type: AuditLogActions.SET_LOADED,
  payload: isLoaded,
});
export const onAuditLogSetLogs = (data) => ({
  type: AuditLogActions.SET_LOGS,
  payload: { data },
});
export const onAuditLogSetUntranslatedLogs = (data) => ({
  type: AuditLogActions.SET_UNTRANSLATED_LOGS,
  payload: { data },
});
export const onAuditLogClearUntranslatedLogs = () => ({
  type: AuditLogActions.CLEAR_UNTRANSLATED_LOGS,
});
export const onSetNewUserExperienceShown = (isShown) => ({
  type: SET_NEW_USER_EXPERIENCE_SHOWN,
  payload: isShown,
});
export const onCustomViewSetUseView = (shouldShow) => ({
  type: CustomViewActions.SET_USE_VIEW,
  payload: shouldShow,
});
export const onCustomViewSetColumnOrder = (columnOrder) => ({
  type: CustomViewActions.SET_COLUMN_ORDER,
  payload: columnOrder,
});
export const onCustomViewSetModalVisible = (showModal) => ({
  type: CustomViewActions.SET_MODAL_VISIBLE,
  payload: showModal,
});
export const onThirdPartyIFrameSetDestination = (destination) => ({
  type: ThirdPartyIFrameActions.SET_DESTINATION,
  payload: destination,
});
export const onThirdPartyIFrameClose = () => ({
  type: ThirdPartyIFrameActions.CLOSE,
});
export const onThirdPartyIFrameEnableExperience = () => ({
  type: ThirdPartyIFrameActions.ENABLE_EXPERIENCE,
});
export const onThirdPartyIFrameOpenComposerForCampaign = (id, packageId) => ({
  type: ThirdPartyIFrameActions.OPEN_COMPOSER,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameOpenMelodyForCampaign = (id, packageId) => ({
  type: ThirdPartyIFrameActions.OPEN_MELODY,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameOpenJSONBourneForCampaign = (id, packageId) => ({
  type: ThirdPartyIFrameActions.OPEN_JSON_BOURNE,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameSetId = (id, packageId) => ({
  type: ThirdPartyIFrameActions.SET_ID,
  payload: {
    id,
    packageId
  },
});
export const onThirdPartyIFrameEvent = (event) => ({
  type: ThirdPartyIFrameActions.COMPOSER_EVENT,
  payload: event,
});
export const onThirdPartyIFrameJSONBourneEvent = (event) => ({
  type: ThirdPartyIFrameActions.JSON_BOURNE_EVENT,
  payload: event,
});
export const onNotificationsInit = () => ({
  type: NotificationsActions.INIT,
});
export const onNotificationsLoad = (options = {}) => {
  const { nextToken, startDateTime } = options;

  return {
    type: NotificationsActions.LOAD,
    payload: {
      nextToken,
      startDateTime,
    },
  };
};
export const onCreateNotificationSubscriptionReceived = (notification) => ({
  type: NotificationsActions.CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED,
  payload: {
    notification,
  }
})
export const onNotificationsSet = ({ notifications, shouldOverride }) => ({
  type: NotificationsActions.SET,
  payload: {
    notifications,
    shouldOverride,
  },
});
export const onNotificationsSort = () => ({
  type: NotificationsActions.SORT,
});
export const onNotificationsSetLoading = (isLoading) => ({
  type: NotificationsActions.SET_LOADING,
  payload: {
    isLoading,
  },
});
export const onNotificationsSetLoaded = () => ({
  type: NotificationsActions.SET_NOTIFICATIONS_LOADED,
});
export const onNotificationsUpdateNewCount = () => ({
  type: NotificationsActions.UPDATE_NEW_NOTIFICATIONS_COUNT,
});
export const onNotificationsSetNewCount = (count) => ({
  type: NotificationsActions.SET_NEW_NOTIFICATIONS_COUNT,
  payload: {
    count,
  },
});
export const onNotificationsOpenWidget = () => ({
  type: NotificationsActions.OPEN_WIDGET,
});
export const onNotificationsCloseWidget = () => ({
  type: NotificationsActions.CLOSE_WIDGET,
});
export const onNotificationsInitLastSeen = () => ({
  type: NotificationsActions.INIT_LAST_SEEN,
});
export const onNotificationsCreateLastSeenAt = () => ({
  type: NotificationsActions.CREATE_LAST_SEEN_AT,
});
export const onSetNotificationsPagination = (pageNumber) => ({
  type: NotificationsActions.SET_PAGE_NUMBER,
  payload: {
    pageNumber,
  },
});
export const onNotificationsUpdateLastReceivedTime = () => ({
  type: NotificationsActions.UPDATE_LAST_NOTIFICATION_RECEIVED_TIME,
});
export const onNotificationsEnableExperience = () => ({
  type: NotificationsActions.ENABLE_EXPERIENCE,
});
export const onNotificationsMarkAs = (notifications, valueToMark) => ({
  type: NotificationsActions.MARK_AS,
  payload: {
    notifications,
    valueToMark,
  },
});
export const onNotificationsSetUpdating = (isUpdating) => ({
  type: NotificationsActions.SET_UPDATING,
  payload: {
    isUpdating,
  },
});
export const onNotificationOpen = (id) => ({
  type: NotificationsActions.OPEN,
  payload: {
    id,
  },
});
export const onNotificationMark = (notifications, overrideValue) => ({
  type: NotificationsActions.MARK,
  payload: {
    notifications,
    overrideValue,
  },
});
export const onNotificationsCommitMarked = (valueToMark) => ({
  type: NotificationsActions.COMMIT_MARKED,
  payload: {
    valueToMark,
  },
});
export const onNotificationsResetMarked = () => ({
  type: NotificationsActions.RESET_MARKED,
});
export const onNotificationsResetLoaded = () => ({
  type: NotificationsActions.RESET_LOADED,
});
export const onPackageFormSetTab = (tabName, tabType) => ({
  type: PackageTabActions.SET_TAB,
  payload: {
    tabName,
    tabType,
  },
});
export const onPackageFormResetTab = () => ({
  type: PackageTabActions.RESET_TAB,
});
export const onCampaignFormSetUtilizationData = (data) => ({
  type: FormActions.SET_UTILIZATION,
  payload: data,
});
export const onCampaignFormSetValidation = ({ validationType, data }) => ({
  type: FormActions.SET_VALIDATION,
  payload: {
    validationType,
    data,
  },
});
export const onFormAddTab = (campaignType) => ({
  type: FormActions.ADD_TAB,
  payload: {
    campaignType
  },
});

export const onFormRemoveTab = (tabIndex) => ({
  type: FormActions.REMOVE_TAB,
  payload: {
    tabIndex,
  },
});
export const onFormSyncTabs = (tabIndex) => ({
  type: FormActions.SYNC_TABS,
  payload: {
    tabIndex,
  },
});
export const onCommentsLoad = ({ campaignId, nextToken = null }) => ({
  type: CommentActions.LOAD,
  payload: {
    campaignId,
    nextToken,
  },
});
export const onCommentsSet = ({ comments, shouldOverride = false }) => ({
  type: CommentActions.SET,
  payload: {
    comments,
    shouldOverride,
  },
});
export const onCommentsSort = () => ({
  type: CommentActions.SORT,
});
export const onCommentsAdd = ({ campaignId, message }) => ({
  type: CommentActions.ADD,
  payload: {
    campaignId,
    message,
  },
});
export const onCommentsSetPosting = (flag) => ({
  type: CommentActions.SET_POSTING,
  payload: {
    flag,
  },
});
export const onCommentsDelete = ({ campaignId, createdAt }) => ({
  type: CommentActions.DELETE,
  payload: {
    campaignId,
    createdAt,
  },
});
export const onCommentsToggleAutoScrolling = () => ({
  type: CommentActions.TOGGLE_AUTO_SCROLLING,
});
export const onCommentsReset = () => ({
  type: CommentActions.RESET,
});
export const onCommentsStartPolling = () => ({
  type: CommentActions.START_POLLING,
});
export const onCommentsStopPolling = () => ({
  type: CommentActions.STOP_POLLING,
});
export const onCommentsSetActiveCampaign = ({ id, packageId }) => ({
  type: CommentActions.SET_ACTIVE_CAMPAIGN,
  payload: {
    campaignId: id,
    packageId,
  },
});
export const onCommentsModalOpen = () => ({
  type: CommentActions.OPEN_MODAL,
});
export const onCommentsModalClose = () => ({
  type: CommentActions.CLOSE_MODAL,
});
export const onStartTimer = () => ({
  type: TimerActions.START_TIMER,
});
export const onCompleteTimer = ({ pageName }) => ({
  type: TimerActions.COMPLETE_TIMER,
  payload: {
    pageName,
  },
});
export const onCancelTimer = () => ({
  type: TimerActions.CANCEL_TIMER,
});
export const onMetadataAuditLogsReceived = (payload) => ({
  type: MetadataAuditLogActions.METADATA_AUDIT_LOGS_RECEIVED,
  payload,
});
export const onMetadataAuditLogsAddLog = (payload) => ({
  type: MetadataAuditLogActions.ADD_LOG,
  payload,
});
export const onMetadataAuditLogsClearData = () => ({
  type: MetadataAuditLogActions.CLEAR_DATA,
});
export const onMetadataAuditLogSetFilterString = (filterString) => ({
  type: MetadataAuditLogActions.SET_FILTER_STRING,
  payload: filterString,
});
export const onGetMetadataAuditLogsByBusiness = (business, nextToken = null) => {
  return graphqlAction({
    graphqlAction: getMetadataAuditLogsByBusiness,
    data: { business, nextToken },
    onSuccessAction: onMetadataAuditLogsReceived,
    label: 'fetchMetadataAuditLogs',
  });
};
export const onPerformanceTimerStart = (timerName) => ({
  type: PerformanceTimerActions.TIMER_START,
  payload: {
    timerName,
  },
});
export const onPerformanceTimerStop = (timerName) => ({
  type: PerformanceTimerActions.TIMER_STOP,
  payload: {
    timerName,
  },
});
export const onFlashFillPage = (id, packageId, cellValue, columnName) => ({
  type: FlashFillActions.FILL_PAGE,
  payload: {
    id,
    packageId,
    cellValue,
    columnName,
  },
});
export const onFlashFillPackage = (id, packageId, cellValue, columnName) => ({
  type: FlashFillActions.FILL_PACKAGE,
  payload: {
    id,
    packageId,
    cellValue,
    columnName,
  },
});
export const onRateCardLoadList = (nextToken = null) => ({
  type: RateCardActions.LOAD_LIST,
  payload: {
    nextToken,
  },
});
export const onRateCardSetList = (cards) => ({
  type: RateCardActions.SET_LIST,
  payload: {
    cards,
  },
});
export const onRateCardSetListLoaded = () => ({
  type: RateCardActions.SET_LIST_LOADED,
});
export const onRateCardSetPagination = (pageNumber) => ({
  type: RateCardActions.SET_PAGE_NUMBER,
  payload: {
    pageNumber,
  },
});
export const onRateCardSetAssetsPagination = (pageNumberAssets) => ({
  type: RateCardActions.SET_PAGE_NUMBER_ASSETS,
  payload: {
    pageNumberAssets,
  },
});
export const onRateCardSetAssetsList = (assets) => ({
  type: RateCardActions.RATE_CARD_ASSET_SET_LIST,
  payload: {
    assets,
  },
});
export const onRateCardSetAssetsListLoaded = () => ({
  type: RateCardActions.RATE_CARD_ASSET_SET_LIST_LOADED,
});
export const onRateCardBatchDeleteAssets = () => ({
  type: RateCardActions.RATE_CARD_ASSETS_DELETE_BATCH,
});
export const onRateCardResetAssets = () => ({
  type: RateCardActions.RATE_CARD_RESET_ASSETS,
});
export const onRateCardAdd = (rateCard) => ({
  type: RateCardActions.RATE_CARD_ADD,
  payload: {
    rateCard,
  },
});
export const onRateCardAddReceived = (rateCard) => ({
  type: RateCardActions.RATE_CARD_ADD_RECEIVED,
  payload: {
    rateCard,
  },
});
export const onRateCardDelete = (rateCard) => ({
  type: RateCardActions.RATE_CARD_DELETE,
  payload: {
    rateCard,
  },
});
export const onRateCardClone = (rateCard) => ({
  type: RateCardActions.RATE_CARD_CLONE,
  payload: {
    rateCard,
  },
});
export const onRateCardUpdate = (rateCard) => ({
  type: RateCardActions.RATE_CARD_UPDATE,
  payload: {
    rateCard,
  },
});
export const onRateCardUpdatedReceived = (rateCard) => ({
  type: RateCardActions.RATE_CARD_UPDATED_RECEIVED,
  payload: {
    rateCard,
  },
});
export const onRateCardSetTab = ({ tabName }) => ({
  type: RateCardActions.SET_TAB,
  payload: {
    tabName,
  },
});
export const onRateCardResetTab = () => ({
  type: RateCardActions.RESET_TAB,
});
export const onRateCardCreateAssets = (rateCardId) => ({
  type: RateCardActions.RATE_CARD_CREATE_ASSETS,
  payload: {
    rateCardId,
  },
});

export const onRateCardAssetAdd = ({ rateCardId, campaign }, isFromBulkImport = false) => ({
  type: RateCardActions.RATE_CARD_ASSET_ADD,
  payload: {
    asset: {
      rateCardId,
      campaign,
    },
    isFromBulkImport,
  },
});
export const onRateCardAssetUpdate = (
  {
    rateCardId,
    campaign,
    id,
    version,
  },
  isFromBulkImport = false,
) => ({
  type: RateCardActions.RATE_CARD_ASSET_UPDATE,
  payload: {
    asset: {
      rateCardId,
      id,
      campaign,
      version,
    },
    isFromBulkImport,
  },
});
export const onRateCardAssetUpdateReceived = ({
  rateCardId, campaign, id, version,
}) => ({
  type: RateCardActions.RATE_CARD_ASSET_UPDATE_RECEIVED,
  payload: {
    asset: {
      rateCardId,
      id,
      campaign,
      version,
    },
  },
});
export const onRateCardAssetDelete = (asset) => ({
  type: RateCardActions.RATE_CARD_ASSET_DELETE,
  payload: {
    asset,
  },
});
export const onRateCardAssetsLoadList = (
  { rateCardId, nextToken = null },
  forceReload = false,
) => ({
  type: RateCardActions.RATE_CARD_ASSET_LOAD_LIST,
  payload: {
    rateCardId,
    nextToken,
    forceReload,
  },
});
export const onRateCardAssetsExport = (reportType) => ({
  type: RateCardActions.RATE_CARD_ASSET_EXPORT,
  payload: {
    reportType,
  },
});
export const onRateCardAssetsPublish = () => ({
  type: RateCardActions.RATE_CARD_ASSETS_PUBLISH,
});
export const onRateCardMarkAsPublished = (rateCardId) => ({
  type: RateCardActions.RATE_CARD_MARK_AS_PUBLISHED,
  payload: {
    rateCardId,
  },
});
export const onRateCardOpenReportModal = (reportType) => ({
  type: RateCardActions.OPEN_REPORT_MODAL,
  payload: {
    reportType,
  },
});
export const onRateCardCloseReportModal = () => ({
  type: RateCardActions.CLOSE_REPORT_MODAL,
});
export const onRateCardSetSearchQuery = (searchQuery) => ({
  type: RateCardActions.SET_SEARCH_QUERY,
  payload: {
    searchQuery,
  },
});
export const onRateCardSetConfigName = (configName) => ({
  type: RateCardActions.SET_CONFIG_NAME,
  payload: {
    configName,
  },
});
export const onRateCardRefreshPlacements = (placementType) => ({
  type: RateCardActions.RATE_CARD_REFRESH_PLACEMENTS,
  payload: {
    placementType,
  },
});
export const onMediaServiceUploadFiles = (files) => ({
  type: MediaServiceActions.UPLOAD_FILES,
  payload: {
    files,
  },
});
export const onMediaServiceReset = () => ({
  type: MediaServiceActions.RESET,
});

export const onToggleRowsExpanded = () => ({
  type: GridViewActions.TOGGLE_ROWS_EXPANDED,
});

export const onSetExpandedRowsOverride = (rowId, isOpen) => ({
  type: GridViewActions.SET_EXPANDED_ROWS_OVERRIDE,
  payload: {
    rowId,
    isOpen,
  }
});
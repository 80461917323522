/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import arrowRightSmallTokens from '@amzn/meridian-tokens/base/icon/arrow-right-small';
import arrowDownSmallTokens from '@amzn/meridian-tokens/base/icon/arrow-down-small';
import Icon from '@amzn/meridian/icon';
import Text from '@amzn/meridian/text';
import CalendarRowRecords from './CalendarRowRecords';
import { CalendarPlacementProps } from '../../proptypes';
import styles from './Calendar.module.scss';

const CalendarRow = (props) => {
  const tableCellRefs = useRef([]);
  const [slotExpanded, setSlotExpanded] = useState(true);
  const [cellWidth, setCellWidth] = useState(0);

  const {
    selectedDates, placements, slotName, timezone, rowWidth, onClick,
  } = props;
  const calendarStartDate = selectedDates[0];
  const calendarEndDate = selectedDates[selectedDates.length - 1];

  useLayoutEffect(() => {
    setCellWidth(tableCellRefs.current[0]?.getBoundingClientRect().width ?? 0);
  }, [rowWidth]);

  const selectedDatesList = selectedDates.map((date, index) => (
    <td
      key={date.toISO()}
      className={styles.calendarTableCell}
      ref={(node) => {
        tableCellRefs.current[index] = node;
      }}
    >
      <CalendarRowRecords
        visible={slotExpanded}
        placements={placements}
        calendarEndDate={calendarEndDate}
        calendarStartDate={calendarStartDate}
        cellWidth={cellWidth}
        date={date}
        timezone={timezone}
        onClick={onClick}
      />
    </td>
  ));

  const placementsCountSpell = placements.length > 1 ? 's' : '';
  const slotDescription = `(${placements.length} placement${placementsCountSpell})`;
  const iconStyle = slotExpanded ? arrowDownSmallTokens : arrowRightSmallTokens;

  return (
    <tr>
      <td
        className={classNames(
          styles.slotNameColumn,
          slotExpanded ? styles.slotNameColumnExpand : styles.slotNameColumnCollapse,
        )}
      >
        <div
          onClick={() => setSlotExpanded(!slotExpanded)}
          className={styles.slotNameColumnSection}
        >
          <Text>
            <Icon tokens={iconStyle} />
          </Text>
          <div className={styles.slotDescriptionContainer}>
            {slotName}
            <br />
            <span className={styles.slotPlacementCount}>{slotDescription}</span>
          </div>
        </div>
      </td>
      {selectedDatesList}
    </tr>
  );
};

CalendarRow.propTypes = {
  selectedDates: PropTypes.arrayOf(PropTypes.object).isRequired,
  placements: PropTypes.arrayOf(CalendarPlacementProps).isRequired,
  slotName: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  rowWidth: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const MemoizedCalendarRow = React.memo(CalendarRow, (prevProps, nextProps) => {
  return (
    prevProps.rowWidth === nextProps.rowWidth
    && prevProps.slotName === nextProps.slotName
    && prevProps.placements === nextProps.placements
    && prevProps.selectedDates === nextProps.selectedDates
  );
});

export default MemoizedCalendarRow;

import { CampaignColumns } from '../constants';

import { createRequiredFields } from '../helpers/createRequiredFields';

const PACKAGE_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.BRAND.name,
  ],
  STATUS_APPROVED: [],
};

const CAMPAIGN_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,  
    CampaignColumns.COUNTRY.name,
    CampaignColumns.BRAND.name,
  ],
  STATUS_APPROVED: [
    CampaignColumns.PAGE_NAME.name,
    CampaignColumns.PAGE_ID.name,
    CampaignColumns.DESKTOP_SLOT.name,
    CampaignColumns.MOBILE_SLOT.name,
    CampaignColumns.CAMPAIGN_NAME.name,
  ],
};

const FRESH_INT_TRAFFIC_REQUIRED_FIELDS = createRequiredFields(
  PACKAGE_FIELDS,
  CAMPAIGN_FIELDS
);

export default FRESH_INT_TRAFFIC_REQUIRED_FIELDS;
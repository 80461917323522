import { getActiveCell } from 'src/selectors/gridView';
import { MovementDirections } from '../../constants';
import { onSelectCell } from '../../actionCreators';

const moveActiveCell = (dispatch, getState, action, next) => {
  const { payload: { direction } } = action;
  const state = getState();
  
  const activeCell = getActiveCell(state);
  if (!direction || !activeCell) {
    return;
  }
  const { Meta: { metadata: { columnOrder } } } = state;
  const { item, columnName } = activeCell;

  const currentColumnIndex = columnOrder.findIndex((column) => {
    return column.name === columnName;
  });

  switch (direction) {
    case MovementDirections.LEFT: {
      if (currentColumnIndex <= 0) {
        return;
      }
      dispatch(onSelectCell({
        item,
        columnName: columnOrder[currentColumnIndex - 1].name,
      }));
      return;
    }
    case MovementDirections.RIGHT: {
      if (currentColumnIndex >= columnOrder.length - 1) {
        return;
      }
      dispatch(onSelectCell({
        item,
        columnName: columnOrder[currentColumnIndex + 1].name,
      }));
      return;
    }
    default: {
      break;
    }
  }

  return next(action);
};

export default moveActiveCell;

import addPackage from './addPackage';
import assignCampaignsToPackages from './assignCampaignsToPackages';
import editPackage from './editPackage';
import deletePackage from './deletePackage';
import loadPackages from './loadPackages';
import sendPackageEdit from './sendPackageEdit';
import loadCampaigns from './loadCampaigns';
import addCampaign from './addCampaign';
import editCampaign from './editCampaign';
import sendCampaignEdit from './sendCampaignEdit';
import deleteCampaign from './deleteCampaign';
import updatedCampaignSubscriptionReceived from './updatedCampaignSubscriptionReceived';
import moveActiveCell from './moveActiveCell';
import updateActiveCell from './updateActiveCell';
import activeCellInputSubmit from './activeCellInputSubmit';
import parseCampaignASINs from './parseCampaignASINs';
import updateCampaignsSov from './updateCampaignsSov';

import {
  LOAD_PACKAGES,
  LOAD_CAMPAIGNS,
  GridViewActions,
  PackageActions,
  CampaignActions,
  SubscriptionActions,
  ASSIGN_CAMPAIGNS_TO_PACKAGES,
} from '../../constants';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;

  if (action.type === ASSIGN_CAMPAIGNS_TO_PACKAGES) {
    return assignCampaignsToPackages(dispatch, getState, action, next);
  }

  if (action.type === LOAD_CAMPAIGNS) {
    return loadCampaigns(dispatch, getState, action, next);
  }

  if (action.type === LOAD_PACKAGES) {
    return loadPackages(dispatch, getState, action, next);
  }

  if (action.type === GridViewActions.SEND_PACKAGE_EDIT) {
    return sendPackageEdit(dispatch, getState, action, next);
  }

  if (action.type === PackageActions.ADD_PACKAGE) {
    return addPackage(dispatch, getState, action, next);
  }

  if (action.type === PackageActions.EDIT_PACKAGE) {
    return editPackage(dispatch, getState, action, next);
  }

  if (action.type === PackageActions.DELETE_PACKAGE) {
    return deletePackage(dispatch, getState, action, next);
  }

  if (action.type === CampaignActions.ADD_CAMPAIGN) {
    return addCampaign(dispatch, getState, action, next);
  }

  if (action.type === CampaignActions.EDIT_CAMPAIGN) {
    return editCampaign(dispatch, getState, action, next);
  }

  if (action.type === GridViewActions.SEND_CAMPAIGN_EDIT) {
    return sendCampaignEdit(dispatch, getState, action, next);
  }

  if (action.type === CampaignActions.DELETE_CAMPAIGN) {
    return deleteCampaign(dispatch, getState, action, next);
  }

  // TODO: add subscriptions for create/delete campaign
  if (action.type === SubscriptionActions.UPDATED_CAMPAIGN_RECEIVED) {
    return updatedCampaignSubscriptionReceived(dispatch, getState, action, next);
  }

  if (action.type === GridViewActions.MOVE_ACTIVE_CELL) {
    return moveActiveCell(dispatch, getState, action, next);
  }

  if ([
    GridViewActions.MOVE_ACTIVE_CELL,
    GridViewActions.SELECT_CELL,
  ].indexOf(action.type) !== -1) {
    return updateActiveCell(dispatch, getState, action, next);
  }

  if (action.type === GridViewActions.ACTIVE_CELL_INPUT_SUBMIT) {
    return activeCellInputSubmit(dispatch, getState, action, next);
  }

  if (action.type === GridViewActions.UPDATE_CAMPAIGNS_SOV) {
    return updateCampaignsSov(dispatch, getState, action, next);
  }

  if (action.type === GridViewActions.PARSE_CAMPAIGN_ASINS) {
    return parseCampaignASINs(dispatch, getState, action, next);
  }

  return next(action);
};

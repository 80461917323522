import { BusinessYear } from '../constants';
import { EVERGREEN_YEAR } from '../constants/dateTime';
import { joinBusinessStartYear } from './common';
import { parseYearFromDate } from './dateTime';

export const getBusinessStartYearFromItem = ({ startDateTime, endDateTime }, business) => {
  if (endDateTime && parseYearFromDate(endDateTime) === EVERGREEN_YEAR) {
    return joinBusinessStartYear(business, BusinessYear.EVERGREEN);
  }
  return joinBusinessStartYear(business, parseYearFromDate(startDateTime))
}
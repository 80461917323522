/* eslint-disable consistent-return */
import _ from 'lodash';
import { getLastUsedPackageIds } from 'src/selectors/gridView';
import {
  onSetFilters,
  onSetSorts,
  onSetFilterViews,
  onMigrateLocalToCloud,
} from '../../actionCreators';
import { createFilterString } from '../../helpers/filters';
import history from '../../history';
import {
  Urls,
  GridViewActions,
  CloudSettingsDataType,
  MetricLocations,
  MetricNames,
  MetricTypes,
  FILTER_VIEW_LOCAL_STORAGE_KEY,
} from '../../constants';
import getUserSettings from '../UserSettingsMiddleware/getUserSettings';
import updateUserSettings from '../UserSettingsMiddleware/updateUserSettings';
import { submitAppSyncError } from '../../metrics';
import {
  checkLocalStorage,
  updateLocalStorage,
  getDefaultFiltersAndSorts,
} from '../migrateSettingsMiddleware';

const pushFiltersToHistory = (store) => {
  const { filters, sorts } = store.getState().GridViewPage;
  const filterAndSortString = createFilterString({ filters, sorts });
  history.push(`${Urls.SHOW_ALL_CAMPAIGNS}?${filterAndSortString}`);
};

export default (store) => (next) => (action) => {
  next(action);
  const { getState, dispatch } = store;
  const { filterViews, activeFilterView } = getState().GridViewPage;

  const filterRelevantActions = [
    GridViewActions.ADD_FILTER,
    GridViewActions.REMOVE_FILTER,
    GridViewActions.ADD_SORT,
    GridViewActions.REMOVE_SORT,
  ];

  const viewRelevantActions = [
    GridViewActions.SET_ACTIVE_FILTER_VIEW,
    GridViewActions.SAVE_FILTER_VIEW,
  ];

  const actionsToUpdateStorage = [
    GridViewActions.SAVE_FILTER_VIEW,
    GridViewActions.DELETE_FILTER_VIEW,
  ];

  if (_.includes(filterRelevantActions, action.type)) {
    pushFiltersToHistory(store);
  }

  if (_.includes(viewRelevantActions, action.type)) {
    const filterView = filterViews.find((view) => { return view.name === activeFilterView; });

    store.dispatch(onSetFilters(filterView ? filterView.filters : []));
    store.dispatch(onSetSorts(filterView ? filterView.sorts : []));
    pushFiltersToHistory(store);

    if (action.type === GridViewActions.SET_ACTIVE_FILTER_VIEW) {
      const filtersPayload = {
        metricLocation: MetricLocations.GRID_VIEW_FILTER_MIDDLEWARE,
        dataType: CloudSettingsDataType.LAST_USED_FILTER_SORT,
        data: JSON.stringify(filterView),
      };

      updateUserSettings(dispatch, getState, action, next, filtersPayload);

      const packageIds = getLastUsedPackageIds(getState);

      if (packageIds.length) {
        const campaignsPayload = {
          metricLocation: MetricLocations.GRID_VIEW_FILTER_MIDDLEWARE,
          dataType: CloudSettingsDataType.LAST_USED_CAMPAIGN_IDS,
          data: packageIds,
        };
        updateUserSettings(dispatch, getState, action, next, campaignsPayload);
      }
    }
  }

  if (_.includes(actionsToUpdateStorage, action.type)) {
    try {
      const data = JSON.stringify(filterViews);
      const payload = {
        metricLocation: MetricLocations.GRID_VIEW_FILTER_MIDDLEWARE,
        dataType: CloudSettingsDataType.FILTERS_AND_SORTS,
        data,
      };
      updateLocalStorage(FILTER_VIEW_LOCAL_STORAGE_KEY, data);
      return updateUserSettings(dispatch, getState, action, next, payload);
    } catch (error) {
      const metricContext = {
        location: MetricLocations.GRID_VIEW_FILTER_MIDDLEWARE,
        type: MetricTypes.APPSYNC,
        action: MetricNames.UPDATE_USER_SETTING,
      };
      submitAppSyncError(error, metricContext);
    }
  }

  if (action.type === GridViewActions.LOAD_FILTER_VIEWS_FROM_STORAGE) {
    const location = MetricLocations.GRID_VIEW_FILTER_MIDDLEWARE;
    const dataType = CloudSettingsDataType.FILTERS_AND_SORTS;
    const payload = {
      metricLocation: location,
      dataType,
      successFunction: (response) => {
        try {
          if (!response) { // if we don't have a cloud setting
            const localFilterViews = JSON.parse(
              checkLocalStorage(FILTER_VIEW_LOCAL_STORAGE_KEY, location),
            ) || getDefaultFiltersAndSorts();
            // Migrate existing filters/sorts to the cloud for an old customer
            // Set up default filters/sorts in the cloud for a new customer
            dispatch(onMigrateLocalToCloud(dataType, location, JSON.stringify(localFilterViews)));
            dispatch(onSetFilterViews(localFilterViews));
          } else {
            const jsonResponse = JSON.parse(response.settings);
            const cloudFilterViews = Array.isArray(jsonResponse)
              ? jsonResponse
              : getDefaultFiltersAndSorts();
            dispatch(onSetFilterViews(cloudFilterViews));
          }
        } catch (error) {
          const metricContext = {
            location: MetricLocations.GRID_VIEW_FILTER_MIDDLEWARE,
            type: MetricTypes.APPSYNC,
            action: MetricNames.GET_USER_SETTING,
          };
          submitAppSyncError(error, metricContext);
        }
      },
    };
    return getUserSettings(dispatch, getState, action, next, payload);
  }
};

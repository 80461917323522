import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment-timezone/index';
import { updateMetadata } from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import {
  PAGE_INIT,
  ON_BUSINESS_RECEIVED,
  UPLOAD_LOCAL_METADATA,
  PerformanceTimerNames,
  ON_YEARS_RECEIVED,
} from '../../constants';
import {
  onBulletinBoardLoad,
  onBusinessSelect,
  onLoadFilterViewsFromStorage,
  onLoadNotifications,
  onSetNewUserExperienceShown,
  onThirdPartyIFrameEnableExperience,
  onNotificationsEnableExperience,
  onPerformanceTimerStart,
  onStartYearsSelect,
} from '../../actionCreators';
import { getSelectedBusiness } from '../../helpers/businessSelector';
import { getSelectedYear } from '../../helpers/startYearsSelector';

export default ({ getState, dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === PAGE_INIT) {
    const { router: { location: { query } } } = getState();
    if (query.enableComposer) {
      dispatch(onThirdPartyIFrameEnableExperience());
    }
    dispatch(onNotificationsEnableExperience());

    getSelectedYear(dispatch, getState, action, next);
    getSelectedBusiness(dispatch, getState, action, next);

    dispatch(onLoadFilterViewsFromStorage());
    dispatch(onLoadNotifications());
    dispatch(onBulletinBoardLoad());
    dispatch(onPerformanceTimerStart(PerformanceTimerNames.APP_INIT));
  }
  if (action.type === ON_BUSINESS_RECEIVED) {
    if (action.data) {
      dispatch(onBusinessSelect({
        business: action.data,
        forceReload: false,
      }));
    } else {
      dispatch(onSetNewUserExperienceShown(true));
    }
  }

  if (action.type === ON_YEARS_RECEIVED) {
    if (action.payload) {
      const { payload: { startYears } } = action;
      try {
        const parsedYears = JSON.parse(startYears);
        dispatch(onStartYearsSelect(parsedYears));
      } catch (e) {
        dispatch(onStartYearsSelect([ moment().year().toString()]));
      }
    } 
  }

  if (action.type === UPLOAD_LOCAL_METADATA) {
    const { business, metadata } = action.data;
    if (!business || !metadata) { return null; }
    return API.graphql(graphqlOperation(queries.getMetadata, { business }))
      .then((result) => {
        const { data: { getMetadata: { version } } } = result;
        return API.graphql(graphqlOperation(
          updateMetadata,
          { input: { business, metadata: JSON.stringify(metadata), expectedVersion: version } },
        ));
      });
  }

  return null;
};

import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import BulletinBoardForm from './BulletinBoardForm';
import { Form } from '../../../constants';
import { validate, warn } from './validators';
import {
  onBulletinBoardAdd,
  onBulletinBoardEdit,
} from '../../../actionCreators';
import { getAnnouncementById } from '../../../helpers/bulletinBoard';
import { isBulletinBoardLoading } from '../../../selectors/bulletinBoard';

const mapStateToProps = (state, ownProps) => {
  const {
    isNew,
    id,
  } = ownProps;
  const {
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  const isLoading = isBulletinBoardLoading(state);

  const announcement = !isNew && !isLoading
    ? getAnnouncementById(state, id)
    : {};

  const isNotFound = !isLoading && !announcement;

  return {
    isLoading,
    isNotFound,
    isNew,
    business: selectedBusiness,
    initialValues: announcement,
  };
};
const mapDispatchProps = (dispatch) => ({
  onCreate: (data) => {
    dispatch(onBulletinBoardAdd(data));
  },
  onUpdate: (data) => {
    dispatch(onBulletinBoardEdit(data));
  },
});

const reduxFormConfig = {
  form: Form.BULLETIN_BOARD_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true, // https://github.com/redux-form/redux-form/issues/2971
  validate,
  warn,
};

export default connect(
  mapStateToProps,
  mapDispatchProps,
)(reduxForm(reduxFormConfig)(BulletinBoardForm));

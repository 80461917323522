/* eslint-disable no-param-reassign */
import {
  CampaignColumns,
  DEFAULT_TIMEZONE_ID,
  Language,
} from '../../../constants';
import { parseDateAndTimeToISOString } from '../../../helpers/dateTime';

const invalidDate = Language.INVALID_DATE;

const validateDateTime = (pkg, dateTimeValue, columnName, shouldValidate) => {
  if (!shouldValidate) {
    return false;
  }

  if (dateTimeValue === invalidDate || !dateTimeValue) {
    if (!pkg.errors) {
      pkg.errors = {};
    }
    if (!pkg.errors[columnName]) {
      pkg.errors[columnName] = {};
    }
    pkg.errors[columnName].text = Language.INVALID_DATE_SUPPLIED;

    return true;
  }

  return false;
};

const validateDateTimes = (item, partialFieldsValidationList = {}) => {
  const {
    timezoneId,
    startDate,
    startTime,
    endDate,
    endTime,
  } = item;
  const isPartialFieldsValidation = Object.keys(partialFieldsValidationList).length;
  const shouldValidateStartDate = !isPartialFieldsValidation
    || (isPartialFieldsValidation && partialFieldsValidationList[CampaignColumns.START_DATE.name]);
  const shouldValidateEndDate = !isPartialFieldsValidation
    || (isPartialFieldsValidation && partialFieldsValidationList[CampaignColumns.END_DATE.name]);

  const currentTimezoneId = timezoneId || DEFAULT_TIMEZONE_ID;

  const startDateTime = parseDateAndTimeToISOString(startDate, startTime, currentTimezoneId);
  const endDateTime = parseDateAndTimeToISOString(endDate, endTime, currentTimezoneId);
  const startDateHasErrors = validateDateTime(item, startDateTime, CampaignColumns.START_DATE.name, shouldValidateStartDate);
  const endDateHasErrors = validateDateTime(item, endDateTime, CampaignColumns.END_DATE.name, shouldValidateEndDate);

  return startDateHasErrors || endDateHasErrors;
};

export default validateDateTimes;

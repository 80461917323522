import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
  onSelectCell,
} from '../../actionCreators';

export const defaultDispatchToProps = (dispatch) => {
  return {
    onInputChange(evt) {
      dispatch(onActiveCellInputUpdate(evt.target.value));
    },
    onSubmit(evt) {
      evt.preventDefault();
      dispatch(onActiveCellInputSubmit());
    },
    onBlur() {
      dispatch(onSelectCell(null));
    },
  };
};

export const defaultStateToProps = (state) => {
  const { GridViewPage: { activeCell, activeCellValue } } = state;

  const getInputValue = () => {
    if (!activeCell || !activeCellValue) {
      return null;
    }

    return activeCellValue;
  };

  return {
    inputValue: getInputValue(),
  };
};

import {
  GraphQLLabels, Language, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import { onOpenToast } from '../../actionCreators';
import { updateAnnouncement } from '../../graphql/mutations';
import { getTranslatedAnnouncement, getAnnouncementById } from '../../helpers/bulletinBoard';

const editRequest = ({
  state, dispatch, action, next,
}) => {
  const {
    User: {
      currentUser: {
        username,
      },
    },
  } = state;

  const {
    data: {
      id,
    },
  } = action;

  const targetAnnouncement = getAnnouncementById(state, id);
  if (!targetAnnouncement) {
    // TODO: error reporting
    // announcement doesn't exist
    return next(action);
  }

  const announcementToEdit = getTranslatedAnnouncement(action.data, state);

  const metricContext = {
    location: MetricLocations.BULLETIN_BOARD_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: announcementToEdit,
    action: MetricNames.EDIT_ANNOUNCEMENT,
    username,
  };

  const input = {
    announcement: announcementToEdit,
    username,
  };

  submitStringMetric(MetricNames.EDIT_ANNOUNCEMENT, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: updateAnnouncement,
    data: { input },
    label: GraphQLLabels.EDIT_ANNOUNCEMENT,
    onSuccessFunction() {
      dispatch(onOpenToast(Language.BULLETIN_BOARD_SUCCESS_EDIT_ANNOUNCEMENT));
    },
    onFailureFunction(error) {
      dispatch(onOpenToast(Language.BULLETIN_BOARD_ERROR_EDIT_ANNOUNCEMENT));
      submitAppSyncError(error, metricContext);
    },
  }));

  const newAction = {
    ...action,
    data: announcementToEdit,
  };
  return next(newAction);
};

export default editRequest;

import { connect } from 'react-redux';
import UtilizationFilterTagList from './UtilizationFilterTagList';
import { onUtilizationFilterChange } from '../../../actionCreators';

export const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      selectedFilters,
    },
  } = state;

  return {
    filters: Object.entries(selectedFilters),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveFilter(filterName) {
      dispatch(onUtilizationFilterChange(filterName, {}, ''));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationFilterTagList);

import BundlesConfig from './bundlesConfig';
import PlacementsConfig from './placementsConfig';
import MultipliersConfig from './multipliersConfig';
import LimitedPlacements from './limitedPlacements';
import MainPlacements from './mainPlacements';
import BonusMainPlacements from './bonusMainPlacements';

const VSSMPlacements = [];

export {
  BundlesConfig,
  PlacementsConfig,
  MultipliersConfig,
  LimitedPlacements,
  VSSMPlacements,
  MainPlacements,
  BonusMainPlacements,
};

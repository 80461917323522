import _ from 'lodash';
import { CampaignColumns } from '../../constants';

const sortField = (options) => {
  if (Array.isArray(options)) {
    return options.sort();
  }
  const sorted = {};
  Object.keys(options).forEach((key) => {
    sorted[key] = sortField(options[key]);
  });
  return sorted;
};

const shouldSkipColumnSort = (columnName) => [
  CampaignColumns.TIMEZONE_ID.name,
].includes(columnName);

const sortMetadata = (metadata) => {
  const sortedFields = {};

  Object.keys(metadata.fields).forEach((key) => {
    sortedFields[key] = {
      ...metadata.fields[key],
      options: shouldSkipColumnSort(key)
        ? metadata.fields[key].options
        : sortField(metadata.fields[key].options),
    };
  });

  const sortedMetadata = {
    ...metadata,
    fields: sortedFields,
  };
  return sortedMetadata;
};

export const processMetadata = (state, data) => {
  return {
    ...state,
    initialMetadataLoaded: true,
    metadata: {
      ...sortMetadata(data),
    },
  };
};

export const addMetadataEntry = (state, data) => {
  const { value, field, parents } = data;
  const { metadata: { fields } } = state;

  if (!field || !value || !parents) { return state; }

  const thisColumn = _.cloneDeep(fields[field]);
  if (!thisColumn) { return state; }

  const dependencies = thisColumn.dependsOn;
  let { options } = thisColumn;
  let hasAllParents = true;
  dependencies.forEach((dependency, i) => {
    const parent = parents.find((thisParent) => {
      return thisParent.field === dependency;
    });
    if (!parent || !parent.value) { hasAllParents = false; }
    if (!hasAllParents) { return; }
    const parentValue = parent.value;
    if (!options[parentValue]) {
      if ((i + 1) < dependencies.length) {
        options[parentValue] = {};
      } else {
        options[parentValue] = [];
      }
    }
    options = options[parentValue];
  });
  if (!hasAllParents) { return state; }
  if (options.includes(value)) { return state; }
  options.push(value);
  return {
    ...state,
    metadata: {
      ...state.metadata,
      fields: {
        ...state.metadata.fields,
        [field]: thisColumn,
      },
    },
  };
};

const updateColumn = (options, dependencies, optionsWithDroppedValue, parents) => {
  if (dependencies.length === 0) { return optionsWithDroppedValue; }
  const dependency = dependencies.shift();
  const { value: parent } = parents.find((thisParent) => thisParent.field === dependency);
  return {
    ...options,
    [parent]: updateColumn(options[parent], dependencies, optionsWithDroppedValue, parents),
  };
};

export const removeMetadataEntry = (state, data) => {
  const { value, field, parents } = data;
  const { metadata: { fields } } = state;
  if (!field || !value || !parents) { return state; }
  const thisColumn = _.cloneDeep(fields[field]);
  if (!thisColumn) { return state; }
  const dependencies = thisColumn.dependsOn;
  let { options } = thisColumn;
  let hasAllParents = true;
  dependencies.forEach((dependency) => {
    const parent = parents.find((thisParent) => {
      return thisParent.field === dependency;
    });
    if (!parent || !parent.value) { hasAllParents = false; }
    if (!hasAllParents) { return; }
    const parentValue = parent.value;
    options = options[parentValue];
  });
  if (!hasAllParents) { return state; }
  const optionsWithDroppedValue = options.filter((option) => option !== value);
  const updatedColumn = updateColumn(
    thisColumn.options,
    thisColumn.dependsOn,
    optionsWithDroppedValue,
    parents,
  );
  return {
    ...state,
    metadata: {
      ...state.metadata,
      fields: {
        ...state.metadata.fields,
        [field]: {
          dependsOn: state.metadata.fields[field].dependsOn,
          options: updatedColumn,
        },
      },
    },
  };
};

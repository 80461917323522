import {
  onMetadataAuditLogsAddLog,
  onMetadataAuditLogsClearData,
  onGetMetadataAuditLogsByBusiness,
} from '../../actionCreators';
import {
  SELECT_BUSINESS,
  MetadataAuditLogActions,
} from '../../constants';

export const parseMetadataAuditLogs = ({ items, parsedLogs, dispatch }) => {
  items
    .map((item) => {
      if (!item.version || !item.metadataDiff) {
        // some logs are coming in empty due to errors on the backend
        // returning an empty object here prevents it from being added to
        // the list
        return {};
      }
      return {
        ...item,
        metadataDiff: JSON.parse(item.metadataDiff),
      };
    })
    .forEach((item) => {
      if (!item.version || parsedLogs[item.version]) { return; }
      dispatch(onMetadataAuditLogsAddLog(item));
    });
};

export default ({ getState, dispatch }) => (next) => (action) => {
  if (action.type === MetadataAuditLogActions.METADATA_AUDIT_LOGS_RECEIVED) {
    const { payload: { data: { getMetadataAuditLogsByBusiness: { items, nextToken } } } } = action;
    const {
      MetadataAuditLog: { parsedLogs },
      Sitewide: { selectedBusiness },
    } = getState();
    if (nextToken) {
      dispatch(onGetMetadataAuditLogsByBusiness(selectedBusiness, nextToken));
    }
    try {
      parseMetadataAuditLogs({ items, parsedLogs, dispatch });
    } catch {
      // Nothing to do here, leaving to keep linter happy
    }
  }
  if (action.type === SELECT_BUSINESS) {
    dispatch(onMetadataAuditLogsClearData());
  }
  return next(action);
};

import { connect } from 'react-redux';
import NotificationsListPanel from './NotificationsListPanel';
import {
  onNotificationMark,
  onNotificationsCommitMarked,
} from '../../../actionCreators';
import { NotificationsPanelList } from '../../../constants';
import { getMarkedNotificationsCount } from '../../../selectors/notificationsSelectors';

export const mapStateToProps = (state) => {
  const {
    Notifications: { paginationPage, isUpdating },
  } = state;

  return {
    markedNotificationsCount: getMarkedNotificationsCount(state),
    paginationPage,
    isUpdating,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onMarkAll: (notificationsList, overrideValue) => {
      dispatch(onNotificationMark(notificationsList, overrideValue));
    },
    onCommitChanges: (value) => {
      const isMarkedAsRead = NotificationsPanelList.MARK_AS_READ.label === value;
      dispatch(onNotificationsCommitMarked(isMarkedAsRead));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsListPanel);

import moment from 'moment-timezone/index';
import { connect } from 'react-redux';
import _ from 'lodash';
import UtilizationForm from './UtilizationForm';
import {
  onUtilizationStartTimeSet,
  onUtilizationStopTimeSet,
  onUtilizationSelectDateRange,
  onUtilizationShowResults,
  onUtilizationToggleSlotsSettings,
  onPerformanceTimerStart,
} from '../../../actionCreators';
import {
  isWFMBusiness,
  isGeneric3PBusiness,
  isFreshJPBusiness,
  isGenericFreshBusiness,
} from '../../../helpers/common';
import { PerformanceTimerNames } from '../../../constants';
import { UtilizationViewMode } from '../../../constants/utilization';

const getProcessedDateRange = (dates) => {
  const defaultRange = ['', ''];

  if (!dates) {
    return defaultRange;
  }
  const [startDate] = dates;

  // Unset both start and end dates if a customer unsets start date
  return !startDate ? defaultRange : dates;
};

export const isDateDisabled = (isAggregateByWeeksMode, selectedDateRange, startYears) => (date) => {
  if (!isAggregateByWeeksMode) {
    return false;
  }
  const [currentStartDate] = selectedDateRange;
  if (!currentStartDate) {
    return false;
  }
  const dateInStartYears = startYears.find((startYear) => _.isEqual(moment(date).year(), parseInt(startYear)));

  return moment(currentStartDate).day() !== moment(date).day() || !dateInStartYears;
};

export const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      selectedFilters,
      selectedDateRange,
      startTime,
      endTime,
      isAggregateByWeeksMode,
      viewMode,
    },
    Sitewide: {
      selectedBusiness,
      startYears,
      showLoader,
    },
  } = state;

  const shouldDisableButton = () => {
    const isDateSelected = selectedDateRange.length > 1;

    if (!selectedFilters) {
      return true;
    }
    if (
      isWFMBusiness(selectedBusiness)
      || isGeneric3PBusiness(selectedBusiness)
      || isFreshJPBusiness(selectedBusiness)
    ) {
      return !(isDateSelected && selectedFilters.pageType && selectedFilters.channel);
    }
    return !(isDateSelected && selectedFilters.pageName);
  };

  return {
    selectedDateRange,
    startYears,
    startTime,
    endTime,
    isButtonDisabled: shouldDisableButton(),
    isAggregateByWeeksMode,
    isSettingsButtonAvailable: !showLoader,
    isCalendarViewMode: UtilizationViewMode.CALENDAR.name === viewMode,
    shouldShowViewModeSwitch: isGenericFreshBusiness(selectedBusiness),
    isDateDisabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSelectDateRange(data) {
      dispatch(onUtilizationSelectDateRange(getProcessedDateRange(data)));
    },
    onStartTimeSet(value) {
      dispatch(onUtilizationStartTimeSet({ startTime: value }));
    },
    onEndTimeSet(value) {
      dispatch(onUtilizationStopTimeSet({ endTime: value }));
    },
    onShowResults() {
      // Move this action dispatcher to the Utilization's middleware
      // once Utilization page's report generation is removed on app init
      dispatch(onPerformanceTimerStart(PerformanceTimerNames.UTILIZATION_PAGE));
      dispatch(onUtilizationShowResults());
    },
    onSettingsClick() {
      dispatch(onUtilizationToggleSlotsSettings());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationForm);

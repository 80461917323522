import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian//modal';
import Button from '@amzn/meridian/button';
import CommentsList from '../CommentsList';

import styles from './CommentsModal.module.scss';

const CommentsModal = (props) => {
  const {
    isOpen,
    campaignId,
    packageId,
    onModalClose,
  } = props;

  useEffect(() => {
    return () => {
      onModalClose();
    };
  }, [
    onModalClose,
  ]);

  const comments = campaignId ? <CommentsList campaignId={campaignId} packageId={packageId} isModalView /> : null;

  return (
    <Modal open={isOpen}>
      <div className={styles.buttonContainer}>
        <Button
          data-testid="btn-comments-modal-close"
          onClick={onModalClose}
        >
          Close
        </Button>
      </div>
      {comments}
    </Modal>
  );
};

CommentsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  campaignId: PropTypes.string,
};
CommentsModal.defaultProps = {
  campaignId: null,
};

export default CommentsModal;

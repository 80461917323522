import moment from 'moment-timezone/index';
import { BulletinBoardColumns } from '../constants/index';
import { nl2br, sanitize } from './common';

export const getRequiredFields = () => {
  return [
    BulletinBoardColumns.BUSINESS.name,
    BulletinBoardColumns.MESSAGE.name,
    BulletinBoardColumns.START_DATE_TIME.name,
    BulletinBoardColumns.END_DATE_TIME.name,
  ];
};

export const getConvertedDateTimeToUTC = (dateTime) => moment(dateTime).utc().format();

export const getTranslatedAnnouncement = (announcement, state) => {
  const { startDateTime, endDateTime } = announcement;
  const { Sitewide: { selectedBusiness } } = state;

  return {
    ...announcement,
    startDateTime: getConvertedDateTimeToUTC(startDateTime),
    endDateTime: getConvertedDateTimeToUTC(endDateTime),
    business: selectedBusiness,
  };
};

export const getSortedResults = (items) => {
  return items
    .sort((a, b) => {
      if (!a.isSticky && b.isSticky) {
        return 1;
      }
      if (a.isSticky && !b.isSticky) {
        return -1;
      }

      return moment(b.endDateTime).diff(a.endDateTime);
    });
};

export const getAnnouncementById = (state, id) => {
  const {
    BulletinBoard: {
      announcements,
    },
  } = state;

  return announcements.find((announcement) => announcement.id === +id);
};

export const createBBColumn = (name, display, inputType) => ({ name, display, inputType });
export const getSanitizedString = (str) => sanitize(nl2br(str));

import { connect } from 'react-redux';

import {
  onUtilizationShowResults,
  onUtilizationCloseFiltersModal,
} from '../../../../actionCreators';

import UpdateUtilityFiltersModal from './UpdateUtilityFiltersModal';

const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      isFiltersModalOpen,
    },
  } = state;

  return {
    isOpen: isFiltersModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseFilters() {
      dispatch(onUtilizationCloseFiltersModal());
    },
    onShowResults() {
      dispatch(onUtilizationShowResults());
      dispatch(onUtilizationCloseFiltersModal());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUtilityFiltersModal);

import { connect } from 'react-redux';
import Reports from './Reports';
import { onRateCardOpenReportModal } from '../../../../actionCreators';

export const mapStateToProps = () => {
  return {};
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onShowReport: (reportType) => () => {
      dispatch(onRateCardOpenReportModal(reportType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

import { API, graphqlOperation } from 'aws-amplify';
import {
  MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import { getRateCardsForBusiness } from '../../graphql/queries';
import {
  onRateCardLoadList,
  onRateCardSetList,
  onRateCardSetListLoaded,
} from '../../actionCreators';
import { appsyncToLocal } from '../../helpers/translateRateCard';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const { RateCard: { isListLoaded } } = getState();

  const getRateCards = (token) => {
    const {
      User: { currentUser: { username } },
      Sitewide: { selectedBusiness },
    } = getState();

    const params = {
      nextToken: token,
      business: selectedBusiness,
    };

    const metricContext = {
      location: MetricLocations.RATE_CARD_LIST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: params,
      action: MetricNames.LOAD_RATE_CARDS,
      username,
    };

    submitStringMetric(MetricNames.LOAD_RATE_CARDS, metricContext);

    return API.graphql(graphqlOperation(getRateCardsForBusiness, params))
      .then(({ data }) => {
        if (!data.getRateCardsForBusiness) {
          throw new Error('Response doesn\'t contain \'getRateCardsForBusiness\' param!');
        }
        const { getRateCardsForBusiness: { items, nextToken } } = data;

        const translatedCards = items.map((card) => appsyncToLocal(card));

        dispatch(onRateCardSetList(translatedCards));
        if (nextToken) {
          dispatch(onRateCardLoadList(nextToken));
        } else {
          dispatch(onRateCardSetListLoaded());
        }
      }).catch((error) => {
        submitAppSyncError(error, metricContext);
      });
  };

  if (isListLoaded) {
    return next(action);
  }

  const { payload: { nextToken } } = action;

  getRateCards(nextToken);

  return next(action);
};

import * as KatalMetrics from '@amzn/katal-metrics';
import KatalLogger from '@amzn/katal-logger';
import KatalMetricsDriverSushi from '@amzn/katal-metrics-driver-sushi';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import { Auth } from 'aws-amplify';

import { DeployLocations, PROGRAM_NAME } from '../constants';
import { getDeployedLocation } from '../helpers/env';

// note: for a list of all metrics being logged in PMET, see here:
// https://monitorportal.amazon.com/igraph?search=katal.f3*

const createBaseKatalLogger = async () => {
  switch (getDeployedLocation()) {
    case DeployLocations.DEV:
      return new KatalLogger({
        logToConsole: true,
        decodeStackTrace: true,
      });
    default:
      const user = await Auth.currentAuthenticatedUser();
      const token = user.getSignInUserSession().getIdToken().getJwtToken();
      return new KatalLogger({
        url: "https://8x50prj4s9.execute-api.us-west-2.amazonaws.com/prod/v1/log",
        decodeStackTrace: true,
        headers: {
          Authorization: token
        },
      });
  }
};

let logger = null;

const initLogger = async () => {
  const newLogger = await createBaseKatalLogger();
  newLogger.addErrorListener();

  return newLogger;
};

initLogger()
  .then((initializedLogger) => logger = initializedLogger)
  .catch((error) => console.error(error));

const makePublisher = () => {
  /* eslint-disable-next-line no-console */
  const metricsErrorHandler = (err) => { console.error(err); };
  let metricsDriver;

  if (process.env.NODE_ENV !== 'production') {
    metricsDriver = new KatalMetricsDriverConsoleLogJson();
  } else {
    metricsDriver = new KatalMetricsDriverSushi.Builder()
      .withDomainRealm('prod', 'USAmazon')
      .withErrorHandler(metricsErrorHandler)
      .build();
  }
  const initialMetricsContext = new KatalMetrics.Context.Builder()
    .withSite('F3MarketingCalendar')
    .withServiceName('F3MarketingCalendar')
    .build();

  return new KatalMetrics.Publisher(metricsDriver, metricsErrorHandler, initialMetricsContext);
};

const initialMetricsPublisher = makePublisher();

export const submitCounterMetric = (name, count = 1) => {
  const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(name);

  actionMetricsPublisher.publishCounterMonitor(name, count);
};

export const submitTimerMetric = (name, timer) => {
  const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod(name);

  actionMetricsPublisher.publishTimerMonitor(name, timer);
};

export const submitStringMetric = (metric, context) => {
  if (logger === null) {
    return;
  }
  try {
    const parsedName = metric.split(' ').join('_');
    submitCounterMetric(parsedName);
  } catch (e) {
    submitCounterMetric('unparsedName');
  }
  /* eslint-disable-next-line no-param-reassign */
  context.program = PROGRAM_NAME;
  /* eslint-disable-next-line no-param-reassign */
  context.stage = getDeployedLocation();
  logger.info(metric, context);
};

export const submitError = (metric, context) => {
  if (logger === null) {
    return;
  }
  /* eslint-disable-next-line no-param-reassign */
  context.program = PROGRAM_NAME;
  /* eslint-disable-next-line no-param-reassign */
  context.stage = getDeployedLocation();
  logger.error(metric, context);
};

export const submitAppSyncError = (appsyncError, context) => {
  const errorMessage = appsyncError && appsyncError.errors && appsyncError.errors.length
    ? appsyncError.errors[0].message
    : appsyncError;

  submitError(errorMessage, context);
};

import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import RateCardForm from './RateCardForm';
import { Form } from '../../constants';
import { validate, warn } from '../../helpers/rateCardFormValidators';
import {
  onRateCardAdd,
  onRateCardClone,
  onRateCardUpdate,
} from '../../actionCreators';
import {
  getRateCardCampaignFieldsByBusiness,
} from '../../helpers/form';
import { RateCardColumns, RateCardMerchTypes } from '../../constants/rateCard';
import getConfig, { getLatestConfigName } from '../../helpers/getRateCardConfig';
import { isRateCardListLoaded } from '../../selectors/rateCardSelectors';

const selector = formValueSelector(Form.RATE_CARD_NAME);

export const mapStateToProps = (state, ownProps) => {
  const {
    isNew,
    isClone,
    loadedRateCard,
  } = ownProps;
  const {
    Sitewide: {
      selectedBusiness,
    },
    User: { currentUser: { username } },
  } = state;

  const isVSSM = selector(state, RateCardColumns.IS_VSSM.name);

  const campaignColumnsSet = getRateCardCampaignFieldsByBusiness(selectedBusiness);
  let campaign = {};

  if (isClone && loadedRateCard) {
    // Clone campaign
    campaign = { ...loadedRateCard };
  } else if (!isNew && loadedRateCard) {
    // Edit
    campaign = { ...loadedRateCard };
  } else {
    // New

    // Set defaults
    campaign = {
      [RateCardColumns.CONFIGURATION_VERSION.name]: getLatestConfigName(),
      [RateCardColumns.MARKETING_MANAGER.name]: username || '',
      [RateCardColumns.BUSINESS.name]: selectedBusiness,
      [RateCardColumns.MERCHANDISING_INTENT.name]: [
        {
          className: RateCardMerchTypes.AWARENESS.name,
          spread: RateCardMerchTypes.AWARENESS.defaultSpread,
        },
        {
          className: RateCardMerchTypes.CONSIDERATION.name,
          spread: RateCardMerchTypes.CONSIDERATION.defaultSpread,
        },
        {
          className: RateCardMerchTypes.CONVERSION.name,
          spread: RateCardMerchTypes.CONVERSION.defaultSpread,
        },
      ],
    };
  }
  const id = !isClone && loadedRateCard ? loadedRateCard.id : null;
  const isLoading = !isRateCardListLoaded(state);
  const isNotFound = !isNew && !isLoading && !loadedRateCard;
  const config = getConfig();
  const bundlesConfig = config.getBundlesConfig();
  const placementsConfig = config.getPlacementsConfig();

  return {
    id,
    isNew,
    isClone,
    isLoading,
    isNotFound,
    isVSSM,
    initialValues: campaign,
    campaignColumns: campaignColumnsSet,
    bundlesConfig,
    placementsConfig,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onCreate: (data) => {
    dispatch(onRateCardAdd(data));
  },
  onUpdate: (data) => {
    dispatch(onRateCardUpdate(data));
  },
  onClone: (data) => {
    dispatch(onRateCardClone(data));
  },
});

const reduxFormConfig = {
  form: Form.RATE_CARD_NAME,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true, // https://github.com/redux-form/redux-form/issues/2971
  validate,
  warn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm(reduxFormConfig)(RateCardForm));

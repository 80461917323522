export default (state, data) => {
  return {
    ...state,
    campaigns: state.campaigns.map((campaign) => {
      if (campaign.id !== data.campaign.id) { return campaign; }
      return {
        ...campaign,
        errors: {
          ...campaign.errors,
          [data.column]: { text: data.message },
        },
      };
    }),
  };
};

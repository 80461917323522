import { connect } from 'react-redux';

import { onThirdPartyIFrameClose } from '../../actionCreators';

import ThirdPartyIFrame from './ThirdPartyIFrame';

export const mapStateToProps = (state) => {
  const { ThirdPartyIFrame: { src } } = state;
  return {
    src,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onThirdPartyIFrameClose() { dispatch(onThirdPartyIFrameClose()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyIFrame);

import { connect } from 'react-redux';

import { onSetNotificationsPagination } from '../../actionCreators';
import { getNotificationsList } from '../../selectors/notificationsSelectors';

import NotificationsPaginator from '../Paginator/Paginator';

export const mapStateToProps = (state) => {
  const { Notifications: { paginationPage, paginationItemsPerPage } } = state;
  return {
    paginationPage,
    pages: Math.ceil(getNotificationsList(state).length / paginationItemsPerPage),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetPaginationPage(pageNumber) {
      dispatch(onSetNotificationsPagination(pageNumber));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPaginator);

import { SELECT_YEARS } from '../../constants';
import { setSelectedYearsUserSettings } from '../../helpers/startYearsSelector';
import {
  loadCampaigns,
  loadPackages,
  onNotificationsInit,
  onNotificationsResetLoaded,
  onNotificationsResetMarked,
  setCampaignsLoadingStarted,
  setPackagesLoadingStarted,
} from '../../actionCreators';
import { joinBusinessStartYear } from '../../helpers/common';
import { getPackageLoadingStatus } from '../../selectors/package';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;
  const {
    Meta: { initialMetadataLoaded },
    Sitewide: { selectedBusiness },
  } = getState();

  if (type === SELECT_YEARS) {
    const { payload: { startYears } } = action;
    setSelectedYearsUserSettings(dispatch, getState, action, next, JSON.stringify(startYears));
    dispatch(onNotificationsResetLoaded());
    dispatch(onNotificationsResetMarked());
    const packageLoadingStatus = getPackageLoadingStatus(getState());
    startYears.forEach((year) => {
      const businessStartYear = joinBusinessStartYear(selectedBusiness, year);
      const packageLoadedForBusinessStartYear = packageLoadingStatus.hasOwnProperty(businessStartYear) && !packageLoadingStatus[businessStartYear];
      if (year && !packageLoadedForBusinessStartYear && initialMetadataLoaded) {
        dispatch(setPackagesLoadingStarted(businessStartYear));
        dispatch(loadPackages(selectedBusiness, year));
        dispatch(setCampaignsLoadingStarted(businessStartYear));
        dispatch(loadCampaigns(selectedBusiness, year));
      }
    });
    dispatch(onNotificationsInit());
  }

  return next(action);
};
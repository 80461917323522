import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../CommentsList.module.scss';
import Comment from '../../Comment';
import { commentProps } from '../../../proptypes';

const CommentsScreen = (props) => {
  const [chatScreenRef, setChatScreenRef] = useState('');

  const {
    commentsList,
    isAutoScrollingEnabled,
  } = props;

  useEffect(() => {
    if (isAutoScrollingEnabled && chatScreenRef) {
      chatScreenRef.scrollIntoView();
    }
  }, [chatScreenRef, commentsList, isAutoScrollingEnabled]);

  let content = '';
  if (!commentsList || !commentsList.length) {
    content = (
      <div className={styles.noCommentsContainer}>
        <p className={styles.noComments}>No Comments... yet.</p>
      </div>
    );
  } else {
    const comments = commentsList.map((comment) => {
      const {
        id,
        createdAt,
      } = comment;

      return (
        <li
          className={styles.listItem}
          key={`${id}/${createdAt}`}
        >
          <Comment {...comment} />
        </li>
      );
    });

    content = (
      <ul className={styles.list}>
        {comments}
      </ul>
    );
  }

  return (
    <div className={styles.chatScreen}>
      {content}
      <div ref={(ref) => {
        setChatScreenRef(ref);
      }}
      />
    </div>
  );
};

CommentsScreen.propTypes = {
  commentsList: PropTypes.arrayOf(PropTypes.shape(commentProps)),
  isAutoScrollingEnabled: PropTypes.bool.isRequired,
};
CommentsScreen.defaultProps = {
  commentsList: [],
};

export default CommentsScreen;

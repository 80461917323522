import React from 'react';
import classnames from 'classnames';
import styles from '../../PackageForm.module.scss';
import FormField from '../../FormField';
import { createCampaignFieldForIndex } from '../../../../helpers/form';

const AutomatedFields = ({
  campaignColumns,
  currentTab,
  isRefMarkerEditable,
}) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Automated fields</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField
                {...campaignField(campaignColumns.CAMPAIGN_NAME)}
              />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField
                {...campaignField(campaignColumns.REF_MARKER)}
                isDisabled={!isRefMarkerEditable}
              />
            </div>
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              Automated fields
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomatedFields;

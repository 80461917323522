import { connect } from 'react-redux';
import CampaignStats from '../CampaignStats';
import { getCustomViewUrl, getStatsByGroups } from '../../../helpers/dashboard';

export const mapStateToProps = (state) => {
  const {
    Dashboard: {
      isOverlapMode,
      totalStats: {
        totalCampaigns,
        statsBy,
      },
      dateRange,
    },
  } = state;

  const [startDate, endDate] = dateRange;

  const totalCampaignsUrl = getCustomViewUrl(
    {
      startDate,
      endDate,
    },
    isOverlapMode,
  );

  const statsByGroups = getStatsByGroups(statsBy, dateRange, {}, isOverlapMode);

  return {
    totalCampaigns,
    totalCampaignsUrl,
    statsByGroups,
  };
};

export default connect(mapStateToProps)(CampaignStats);

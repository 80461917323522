import React from 'react';
import PropTypes from 'prop-types';
import InStoreUtilizationRender from '../../Calendar/CalendarEventRender/inStoreUtilizationRender';
import Calendar from '../../Calendar';
import { campaignProps } from '../../../proptypes';
import styles from './InStoreUtilizationCalendar.module.scss';

const InStoreUtilizationCalendar = (props) => {
  const {
    timelineEventsData,
    timeFrame,
    onTimeFrameChange,
    headerText,
  } = props;

  return (
    <>
      <h3
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: headerText }}
      />
      <Calendar
        onTimeFrameChange={onTimeFrameChange}
        timeFrame={timeFrame}
        events={timelineEventsData}
        eventsRender={InStoreUtilizationRender}
      />
    </>
  );
};

InStoreUtilizationCalendar.propTypes = {
  timelineEventsData: PropTypes.arrayOf(PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    campaign: campaignProps,
  })),
  onTimeFrameChange: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  timeFrame: PropTypes.string,
};

InStoreUtilizationCalendar.defaultProps = {
  timelineEventsData: [],
  timeFrame: '',
};

export default InStoreUtilizationCalendar;

import { connect } from 'react-redux';

import { onCloseFilterViewModal, onSaveFilterView } from '../../../actionCreators';
import { getCampaignFieldsByBusiness } from '../../../helpers/form';
import getAllMetadataOptionsForColumn from '../../../helpers/getAllMetadataOptionsForColumn';

import GridViewFilterModal from './GridViewFilterModal';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      filterViewModalOpen: isOpen,
      filterViewBeingEdited,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;
  return {
    campaignColumns: getCampaignFieldsByBusiness(selectedBusiness),
    isOpen,
    filterViewBeingEdited,
    getAllMetadataOptionsForColumn: (column) => getAllMetadataOptionsForColumn(state, column),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    closeModal() { dispatch(onCloseFilterViewModal()); },
    saveView(view) { dispatch(onSaveFilterView(view)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewFilterModal);

import _ from 'lodash';
import {
  BusinessTypes,
  CampaignColumns,
  CampaignStatuses,
  CampaignTypes,
  NeedsInventoryAwarenessFilterFields,
  NeedsInventoryAwarenessHeroFields,
  VendorTypes,
  WidgetTypes,
} from '../constants';
import {
  getItemBusiness,
  isFinalizedAdLeftCampaign,
  isFreshBusiness,
  isWFMBusiness,
  shouldDisableSovForSovType,
} from './common';
import { isPackage } from './package';
import REQUIRED_FIELDS_BY_BUSINESS from '../validationConfiguration';
import { RequiredFieldTypes } from '../constants/requiredFields';
import { isOnlineCampaign } from './campaign';

const getNeedsInventoryAwarenessRequiredFields = (needsInventoryAwareness, widget, fieldsSet) => {
  const newFieldSet = [...fieldsSet];
  // Additional required fields for needsInventoryAwareness when widgetType is HERO
  if (needsInventoryAwareness
          && (widget === WidgetTypes.HERO || widget === WidgetTypes.HERO_MAIN)) {
    NeedsInventoryAwarenessHeroFields.forEach((field) => {
      if (!newFieldSet.includes(field)) {
        newFieldSet.push(field);
      }
    });
  }
  // Removes ASIN related fields when does not need Inventory Awareness
  if (!needsInventoryAwareness) {
    return newFieldSet.filter((field) => {
      return !NeedsInventoryAwarenessFilterFields.includes(field);
    });
  }

  return newFieldSet;
};

const getRequiredFieldsByBusiness = (item, requiredFieldsType) => {
  if (_.isEmpty(item) || !requiredFieldsType) {
    return {};
  }

  const business = getItemBusiness(item);
  if (
    !business
    || _.isEmpty(REQUIRED_FIELDS_BY_BUSINESS[business])
    || _.isEmpty(REQUIRED_FIELDS_BY_BUSINESS[business][requiredFieldsType])
  ) {
    return {};
  }

  return REQUIRED_FIELDS_BY_BUSINESS[business][requiredFieldsType];
};

const getDefaultRequiredFields = (item, requiredFieldsType) => {
  if (_.isEmpty(item)) {
    return [];
  }
  const business = getItemBusiness(item);
  if (!business) {
    return [];
  }

  const requiredFieldsByBusiness = getRequiredFieldsByBusiness(
    item,
    requiredFieldsType
  );

  const { status } = item;
  const { campaignType } = item; 
  const {
    BASE = [],
    STATUS_APPROVED = [],
    APPROVED_IN_STORE = [],
    INPUTS_COMPLETE_BASE = [],
    INPUTS_COMPLETE_ADDITIONAL = [],
    INPUTS_COMPLETE_IN_STORE = [],
  } = requiredFieldsByBusiness;

  switch (status) {
    case CampaignStatuses.APPROVED:
      if (campaignType && !isOnlineCampaign(item)) {
        return APPROVED_IN_STORE;
      }
      return [
        ...BASE,
        ...STATUS_APPROVED,
      ];
    case CampaignStatuses.INPUTS_COMPLETE:
      if (business !== BusinessTypes.FRESH.name) {
        return BASE;
      }
      if (campaignType && !isOnlineCampaign(item)) {
        return INPUTS_COMPLETE_IN_STORE;
      }
      const { onlineField = {} } = item;
      const { placement } = onlineField;

      const shouldHaveAdditionalFields = (
        placement && (placement === 'HERO' || placement.indexOf('Half') !== -1)
      );

      return [
        ...BASE,
        ...INPUTS_COMPLETE_BASE,
        ...(shouldHaveAdditionalFields ? INPUTS_COMPLETE_ADDITIONAL : [])
      ];
    default:
      return BASE;
  }
};

export const getPackageRequiredFields = (pkg) => {
  return getDefaultRequiredFields(
    pkg,
    RequiredFieldTypes.PACKAGE
  );
};

export const getCampaignRequiredFields = (campaign, pkg = {}) => {
  let defaultRequiredFields = getDefaultRequiredFields(
    campaign,
    RequiredFieldTypes.CAMPAIGN,
  );

  const business = getItemBusiness(campaign);
  const {
    status,
    sovType,
    desktopSlot,
    needsInventoryAwareness,
    widget,
  } = campaign;

  if (!_.isEmpty(pkg)) {
    const { vendor } = pkg;

    if (isFreshBusiness(business)
      && vendor !== VendorTypes.HOUSE
      && [
        CampaignStatuses.INPUTS_COMPLETE,
        CampaignStatuses.APPROVED,].includes(status)
    ) {
      [
        CampaignColumns.PACKAGE_NAME.name,
        CampaignColumns.PLACEMENT_IN_BUNDLE.name,
      ].forEach(column => {
        defaultRequiredFields.push(column);
      })
    }
  }

  if (isFinalizedAdLeftCampaign(desktopSlot, status)) {
    defaultRequiredFields.push(CampaignColumns.INVENTORY_AWARENESS.name);
  }

  defaultRequiredFields = getNeedsInventoryAwarenessRequiredFields(
    needsInventoryAwareness,
    widget,
    defaultRequiredFields
  );

  if ((isFreshBusiness(business) || isWFMBusiness(business))
    && shouldDisableSovForSovType(sovType)) {
    return defaultRequiredFields.filter((fieldName) => {
      return fieldName !== CampaignColumns.SOV.name;
    });
  }

  return defaultRequiredFields;
};

export const getItemRequiredFields = (item) => {
  if (_.isEmpty(item)) {
    return [];
  }
  const business = getItemBusiness(item);
  if (!business) {
    return [];
  }

  const requiredFields = isPackage(item)
    ? getPackageRequiredFields(item)
    : getCampaignRequiredFields(item)

  if (_.isEmpty(requiredFields)) {
    return [];
  }

  return requiredFields;
};

export const getMissingRequiredFields = (item, overrideStatus) => {
  const requiredFields = getItemRequiredFields({
    ...item,
    status: overrideStatus || item.status,
  });

  return requiredFields.filter((field) => {
    return !item[field];
  }).map((field) => {
    let display = '';
    Object.keys(CampaignColumns).forEach((column) => {
      if (CampaignColumns[column].name === field) {
        display = CampaignColumns[column].display;
      }
    });
    return display;
  });
};


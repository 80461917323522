import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SideMenu, { SideMenuLink } from '@amzn/meridian/side-menu';
import Row from '@amzn/meridian/row';

import { useHistory } from 'react-router-dom';
import { submitStringMetric } from '../../metrics';
import { MetricTypes, MetricLocations } from '../../constants';

const Menu = ({
  isActionBarExpanded,
  isSideMenuOpen,
  onToggleActionBar,
  currentRoute,
  linkList,
  intakeLinksList,
  openLinkInNewTab,
  onUpdateSOVFlagsAction,
  onOpenExportModal,
  onToggleSideMenu,
}) => {
  const history = useHistory();

  const onClickLink = useCallback((route, isExternal = false) => () => {
    submitStringMetric(route,
      {
        location: MetricLocations.SIDE_MENU,
        type: MetricTypes.CLICK,
      });
    if (isExternal) {
      openLinkInNewTab(route);

      return;
    }
    history.push(route);
  }, [history, openLinkInNewTab]);

  const sideMenuAction = (funcToCall) => {
    return () => {
      onToggleSideMenu();
      if (typeof funcToCall === 'function') {
        funcToCall();
      }
    };
  };

  const links = linkList
    .filter(({ shouldShow }) => shouldShow)
    .map((link) => {
      const {
        name, route, iconComponent, isExternal
      } = link;

      return (
        <SideMenuLink
          key={route}
          href={route}
          selected={currentRoute === route}
          onClick={sideMenuAction(onClickLink(route, isExternal))}
          collapsedIcon={iconComponent}
        >
          <Row>{name}</Row>
        </SideMenuLink>
      );
    });

  const intakeLinks = intakeLinksList.map((link) => {
    const {
      name, route, isExternal, icon,
    } = link;

    return (
      <SideMenuLink
        key={route}
        href={route}
        selected={currentRoute === route}
        onClick={sideMenuAction(onClickLink(route, isExternal))}
        icon={icon}
      >
        <Row>{name}</Row>
      </SideMenuLink>
    );
  });

  return (
    <Row alignmentVertical="stretch" spacing="none">
      <SideMenu
        open={isSideMenuOpen}
        minWidth={200}
      >
        <SideMenuLink
          href="#"
          onClick={sideMenuAction(onToggleActionBar)}
        >
          <Row>
            {isActionBarExpanded ? 'Hide' : 'Show'}
            {' '}
            action bar
          </Row>
        </SideMenuLink>
        <SideMenuLink
          href="#"
          onClick={sideMenuAction(onUpdateSOVFlagsAction)}
        >
          <Row>
            Update SOV for page
          </Row>
        </SideMenuLink>
        <SideMenuLink
          href="#"
          onClick={sideMenuAction(onOpenExportModal)}
        >
          <Row>
            Export View
          </Row>
        </SideMenuLink>
        {links}
        {intakeLinks}
      </SideMenu>
    </Row>
  );
};

Menu.propTypes = {
  openLinkInNewTab: PropTypes.func.isRequired,
  isSideMenuOpen: PropTypes.bool.isRequired,
  isActionBarExpanded: PropTypes.bool.isRequired,
  onToggleActionBar: PropTypes.func.isRequired,
  currentRoute: PropTypes.string.isRequired,
  onUpdateSOVFlagsAction: PropTypes.func.isRequired,
  onOpenExportModal: PropTypes.func.isRequired,
  linkList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
  intakeLinksList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
  onToggleSideMenu: PropTypes.func.isRequired,
};

export default Menu;

import { connect } from 'react-redux';
import AdLeftSlotWarning from './AdLeftSlotWarning';
import { isFinalizedAdLeftCampaign } from '../../../../../../helpers/common';
import { getFormActiveCampaign } from '../../../../../../selectors/form';

export const mapStateToProps = (state) => {
  const {
    status,
    desktopSlot,
  } = getFormActiveCampaign(state);

  return {
    showWarning: isFinalizedAdLeftCampaign(desktopSlot, status),
  };
};

export default connect(mapStateToProps)(AdLeftSlotWarning);

import { connect } from 'react-redux';
import RateCardAssets from './rate-card-assets';
import { getRateCardAssetById } from '../../../helpers/rateCard';

export const mapStateToProps = (state, props) => {
  const {
    RateCard: { assets },
  } = state;
  const { match: { params: { id } } } = props;

  const isValidAsset = getRateCardAssetById(assets, id);

  return {
    shouldRender: !!(assets.length > 0 && isValidAsset),
    rateCardAssetId: id,
  };
};

export default connect(mapStateToProps)(RateCardAssets);

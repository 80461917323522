import { Comparators } from '../../constants';

export const setFilters = (state, filters) => {
  // TODO: sanitize filters
  return {
    ...state,
    filters,
  };
};

export const setSorts = (state, sorts) => {
  return {
    ...state,
    sorts,
  };
};

export const addFilter = (state, filter) => {
  return {
    ...state,
    filters: [...state.filters, filter],
  };
};

export const addSort = (state, sort) => {
  return {
    ...state,
    sorts: [...state.sorts, sort],
  };
};

export const removeFilter = (state, index) => {
  return {
    ...state,
    filters: state.filters.filter((_, i) => { return i !== index; }),
  };
};

export const removeSort = (state, index) => {
  return {
    ...state,
    sorts: state.sorts.filter((_, i) => { return i !== index; }),
  };
};

export const setActiveFilterView = (state, name) => {
  return {
    ...state,
    activeFilterView: name,
    paginationPage: 0,
  };
};

export const parseFilters = (filters) => {
  return filters.map((filter) => {
    if (filter.type && filter.rules) {
      return {
        ...filter,
        rules: parseFilters(filter.rules),
      };
    }
    return filter;
  }).filter((filter) => {
    if (filter.type && filter.rules) {
      return filter.rules.length > 0;
    }
    return (filter.column !== '' && filter.value !== '')
      || (filter.comparator === Comparators.NOT_EMPTY)
      || (filter.comparator === Comparators.EMPTY);
  });
};

export const parseSorts = (sorts) => {
  return sorts.filter((sort) => {
    return sort.column !== '';
  });
};

const parseView = (view) => {
  return {
    ...view,
    filters: parseFilters(view.filters),
    sorts: parseSorts(view.sorts),
  };
};

export const saveFilterView = (state, data) => {
  let hasBeenUpdated = false;
  const parsedView = parseView(data);
  const filterViews = state.filterViews.map((filter) => {
    if (filter.name === parsedView.name) {
      hasBeenUpdated = true;
      return parsedView;
    }
    return filter;
  });
  if (!hasBeenUpdated) { filterViews.push(parsedView); }
  return {
    ...state,
    filterViews,
    activeFilterView: parsedView.name,
  };
};

export const deleteFilterView = (state, data) => {
  return {
    ...state,
    filterViews: state.filterViews.filter((filter) => { return filter.name !== data; }),
  };
};

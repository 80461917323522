import {
  onNotificationsResetMarked,
  onNotificationsMarkAs,
} from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { Notifications: { markedList } } = getState();
  const { payload: { valueToMark } } = action;

  const notificationsToMark = [];
  for (let i = 0, keys = Object.keys(markedList); i < keys.length; i++) {
    const notification = markedList[keys[i]];
    if (notification.isMarked) {
      delete notification.isMarked;
      notificationsToMark.push(notification);
    }
  }
  dispatch(onNotificationsMarkAs(notificationsToMark, valueToMark));
  dispatch(onNotificationsResetMarked());

  return next(action);
};

import {
  SET_USER,
  SET_USER_TIMEZONE,
  SET_USER_UNABLE_TO_SIGN_IN,
} from '../../constants';

export const initialState = {
  currentUser: null,
  cannotAuthenticate: false,
  timeZone: null,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case SET_USER_TIMEZONE: {
      const { payload: { timeZone } } = action;

      return {
        ...state,
        timeZone,
      };
    }
    case SET_USER_UNABLE_TO_SIGN_IN: {
      return {
        ...state,
        cannotAuthenticate: true,
      };
    }
    default: {
      return state;
    }
  }
};

import { connect } from 'react-redux';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import helpKnockoutTokens from '@amzn/meridian-tokens/base/icon/help-knockout';
import SideMenu from './SideMenu';
import {
  onOpenExportModal,
  onUpdateCampaignsSov,
  toggleActionBar,
  toggleSideMenu,
} from '../../actionCreators';
import {
  ExternalUrls,
  MetricTypes,
  MetricLocations,
  MetricNames,
  Urls,
  LinkTypes,
} from '../../constants';
import { openLinkInNewTab } from '../../helpers/common';
import { submitStringMetric } from '../../metrics';
import getBusinessSpecificLink from '../../helpers/getBusinessSpecificLink';
import { isBusinessFreshTraffic, isReadOnlyMode } from '../../selectors/sitewide';

export const getLinkList = (isReadOnlyMode, isTrafficMode) => [
  {
    name: 'Metadata tool',
    route: Urls.METADATA_EDITOR,
    shouldShow: !isReadOnlyMode,
  },
  {
    name: 'ASIN Parser',
    route: ExternalUrls.ASIN_PARSER,
    isExternal: true,
    shouldShow: true,
  },
  {
    name: 'Browsers extensions',
    route: ExternalUrls.BROWSER_EXTENSIONS,
    isExternal: true,
    shouldShow: true,
  },
  {
    name: 'Bulk upload',
    route: Urls.BULK_IMPORT,
    shouldShow: !isReadOnlyMode,
  },
  {
    name: 'Dashboard',
    route: Urls.DASHBOARD,
    shouldShow: true,
  },
  {
    name: 'Rate Card',
    route: Urls.RATE_CARD_LIST,
    shouldShow: !isReadOnlyMode && !isTrafficMode,
  },
];

export const getIntakeLinks = (business) => [
  {
    name: 'Request a Feature',
    route: getBusinessSpecificLink(LinkTypes.REQUEST_FEATURE, business),
    isExternal: true,
    icon: helpKnockoutTokens,
    accessGroup: [],
    locations: [],
  },
  {
    name: 'Report an Issue',
    route: getBusinessSpecificLink(LinkTypes.REPORT_ISSUE, business),
    isExternal: true,
    icon: alertKnockoutTokens,
    accessGroup: [],
    locations: [],
  },
];

export const mapStateToProps = (state) => {
  const {
    router: {
      location: {
        pathname: currentRoute,
      },
    },
    Sitewide: {
      isActionBarExpanded,
      isSideMenuOpen,
      selectedBusiness,
    },
    User: {
      currentUser,
    },
  } = state;

  return {
    isActionBarExpanded,
    isSideMenuOpen,
    currentRoute,
    linkList: getLinkList(isReadOnlyMode(state), isBusinessFreshTraffic(state)),
    intakeLinksList: getIntakeLinks(selectedBusiness),
    openLinkInNewTab,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onToggleActionBar() {
      submitStringMetric(MetricNames.TOGGLE_ACTION_BAR,
        {
          location: MetricLocations.SIDE_MENU,
          type: MetricTypes.CLICK,
        });
      dispatch(toggleActionBar());
    },
    onUpdateSOVFlagsAction() {
      submitStringMetric(MetricNames.UPDATE_SOV_FLAGS,
        {
          location: MetricLocations.SIDE_MENU,
          type: MetricTypes.CLICK,
        });
      dispatch(onUpdateCampaignsSov());
    },
    onOpenExportModal() {
      submitStringMetric(MetricNames.OPEN_EXPORT_MODAL,
        {
          location: MetricLocations.SIDE_MENU,
          type: MetricTypes.CLICK,
        });
      dispatch(onOpenExportModal());
    },
    onToggleSideMenu() {
      dispatch(toggleSideMenu());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);

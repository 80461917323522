import React from 'react';
import PropTypes from 'prop-types';

import NotificationBell from '../NotificationBell';
import NotificationsListModal from '../NotificationsListModal';
import styles from './Notifications.module.scss';

const Notifications = ({ isWidgetOpen }) => {
  return (
    <>
      <NotificationBell />
      {isWidgetOpen && (
      <div className={styles.notificationsContainer}>
        <NotificationsListModal />
      </div>
      )}
    </>
  );
};

Notifications.propTypes = {
  isWidgetOpen: PropTypes.bool.isRequired,
};

export default Notifications;

import {
  RateCardMerchTypes,
  RateCardPlacementTypes,
} from '../../../../constants/rateCard';

const Conversion = [
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Deals Stripe (1-week)',
    dollars: 35000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Deals Stripe (4-week)',
    dollars: 140000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Prime Day (Lead-In) Landing Page  - Shoveler Inclusion',
    dollars: 5000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Prime Day (Lead-In) Landing Page - Exclusive Shoveler',
    dollars: 30000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Prime Day (Event) Landing Page - Exclusive Shoveler',
    dollars: 70000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 8,
    numberPlacements: 2,
    description: 'Please note that this placement will not be available for incremental pitching',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Deals Stripe (2-weeks)',
    dollars: 70000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 3,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] F3G Lobby Pallet Bundle',
    dollars: 200000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'VMI only',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] F3G Perimeter Pallet Bundle',
    dollars: 150000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'Available to Gold+ LoS only',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] NF Perimeter Pallet Bundle',
    dollars: 150000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'Available to Gold+ LoS only',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: 'Non Food Perimeter Pallet Bundle',
    dollars: 150000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Premium] Endcap Bundle - Ambient Exclusive',
    dollars: 175000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 1,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] Endcap Bundle - Ambient Exclusive',
    dollars: 120000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] Endcap Bundle - Ambient Shared',
    dollars: 75000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] Endcap Bundle - Chilled Exclusive',
    dollars: 120000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] Endcap Bundle - Chilled Shared',
    dollars: 75000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] Endcap Bundle - Frozen Exclusive',
    dollars: 120000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Basic] Endcap Bundle - Frozen Shared',
    dollars: 75000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Standard] Prime Day Bundle',
    dollars: 90000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Premium] Prime Day Bundle',
    dollars: 220000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Standard] 2-week Spend & Save Bundle',
    dollars: 145000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'This Bundle includes a Custom Landing Page',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Premium] 2-week Spend & Save Bundle',
    dollars: 180000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'This Bundle includes a Custom Landing Page',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Standard] 4-week Spend & Save Bundle',
    dollars: 245000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'This Bundle includes a Custom Landing Page',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.LIMITED,
    placementName: '[Premium] 4-week Spend & Save Bundle',
    dollars: 300000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'This Bundle includes a Custom Landing Page',
  },
];

export default Conversion;
import { createSelector } from 'reselect';
import { Form } from '../constants';
import {
  calcTotalInvestment,
  getMoneyUsedPerMerchantClass,
  getTotalMoneyUsedAcrossMerchantClasses,
} from '../helpers/rateCard';
import { getPaginationBounds as getPaginationRange } from '../helpers/pagination';
import {
  RateCardColumns,
  RateCardConfig,
  RateCardSearchFiltersList,
} from '../constants/rateCard';
import { getLatestConfigName } from '../helpers/getRateCardConfig';

export const getRateCardList = (state) => {
  return state.RateCard.cards;
};

export const getRateCardAssetsList = (state) => {
  return state.RateCard.assets;
};

export const isRateCardListLoaded = (state) => {
  return state.RateCard.isListLoaded;
};

export const getSelectedBusiness = (state) => {
  return state.Sitewide.selectedBusiness;
};

export const getSearchQuery = (state) => {
  return state.RateCard.searchQuery;
};

export const getCurrentConfigName = (state) => state.RateCard.configName;

export const isCurrentConfigTheLatest = createSelector(
  [getCurrentConfigName, getLatestConfigName],
  (currentConfigName, latestConfigName) => {
    return currentConfigName === latestConfigName;
  },
);

export const getFilteredRateCardList = createSelector(
  [getRateCardList, getCurrentConfigName],
  (rateCardList, currentConfigName) => {
    return rateCardList.filter((rateCard) => {
      return rateCard[RateCardColumns.CONFIGURATION_VERSION.name] === currentConfigName;
    });
  },
);

export const getIsEarlyBirdDiscount = (state) => {
  if (state.form[Form.RATE_CARD_NAME]) {
    return state.form[Form.RATE_CARD_NAME].values[RateCardColumns.IS_EARLY_BIRD.name];
  }

  return null;
};

export const getMerchInvestment = (state) => {
  if (state.form[Form.RATE_CARD_NAME]) {
    return state.form[Form.RATE_CARD_NAME].values[RateCardColumns.MERCHANT_INVESTMENT.name];
  }

  return null;
};

export const getMerchIntent = (state) => {
  if (state.form[Form.RATE_CARD_NAME]) {
    return state.form[Form.RATE_CARD_NAME].values[RateCardColumns.MERCHANDISING_INTENT.name];
  }

  return [];
};

export const getLimitedPlacements = (state) => {
  if (state.form[Form.RATE_CARD_NAME]) {
    return state.form[Form.RATE_CARD_NAME].values[RateCardColumns.LIMITED_PLACEMENTS.name];
  }

  return [];
};

export const getMainPlacements = (state) => {
  if (state.form[Form.RATE_CARD_NAME]) {
    return state.form[Form.RATE_CARD_NAME].values[RateCardColumns.MAIN_PLACEMENTS.name];
  }

  return [];
};

export const getPaginationBounds = (state) => {
  const { RateCard: { cards, pageNumber, cardsPerPage } } = state;

  return getPaginationRange(cards, pageNumber, cardsPerPage);
};

export const getPaginationAssetsBounds = (state) => {
  const { RateCard: { assets, pageNumberAssets, assetsPerPage } } = state;

  return getPaginationRange(assets, pageNumberAssets, assetsPerPage);
};

export const getPaginatedCardList = createSelector(
  [getFilteredRateCardList, getPaginationBounds],
  (paginatedList, paginationBounds) => {
    return paginatedList.slice(paginationBounds[0], paginationBounds[1]);
  },
);

export const getSearchFilteredCardList = createSelector(
  [getFilteredRateCardList, getPaginatedCardList, getSearchQuery],
  (cardsList, paginatedList, searchQuery) => {
    if (!searchQuery) {
      return paginatedList;
    }

    return cardsList.filter((card) => {
      let matchesCount = 0;
      RateCardSearchFiltersList.forEach((filterColumnName) => {
        if (!card[filterColumnName]) {
          return;
        }

        if (card[filterColumnName].toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) {
          matchesCount++;
        }
      });

      return !!matchesCount;
    });
  },
);

export const getPaginatedAssetsList = createSelector(
  [getRateCardAssetsList, getPaginationAssetsBounds],
  (paginatedList, paginationBounds) => {
    return paginatedList.slice(paginationBounds[0], paginationBounds[1]);
  },
);

export const shouldShowPagination = createSelector(
  [getSearchQuery, getFilteredRateCardList],
  (searchQuery, cards) => {
    return !!(!searchQuery && cards.length) && cards.length > RateCardConfig.LIMIT_PER_PAGE;
  },
);

export const getTotalMerchantClassSpreadPercentage = createSelector(
  [getMerchIntent],
  (merchIntent) => {
    if (!merchIntent || !merchIntent.length) {
      return 0;
    }

    return merchIntent.reduce((totalSpreadPercentage, { spread }) => {
      return (totalSpreadPercentage + parseInt(spread, 10));
    }, 0);
  },
);

export const getLimitedPlacementsMoneyUsedPerClass = createSelector(
  [getLimitedPlacements],
  (limitedPlacements) => getMoneyUsedPerMerchantClass(limitedPlacements),
);

export const getMainPlacementsMoneyUsedPerClass = createSelector(
  [getMainPlacements],
  (mainPlacements) => getMoneyUsedPerMerchantClass(mainPlacements),
);

export const getTotalLimitedPlacementsMoneyUsed = createSelector(
  [getLimitedPlacementsMoneyUsedPerClass],
  (limitedPlacementsMoneyUsedPerClass) => (
    getTotalMoneyUsedAcrossMerchantClasses(limitedPlacementsMoneyUsedPerClass)
  ),
);

export const getTotalMainPlacementsMoneyUsed = createSelector(
  [getMainPlacementsMoneyUsedPerClass],
  (mainPlacementsMoneyUsedPerClass) => (
    getTotalMoneyUsedAcrossMerchantClasses(mainPlacementsMoneyUsedPerClass)
  ),
);

export const getTotalInvestment = createSelector(
  [getMerchInvestment, getIsEarlyBirdDiscount, getCurrentConfigName],
  (merchInvestment, hasEarlyBirdDiscount, configName) => (
    calcTotalInvestment(merchInvestment, {
      hasEarlyBirdDiscount,
      configName,
    })
  ),
);

export const getTotalMoneyUsed = createSelector(
  [getTotalLimitedPlacementsMoneyUsed, getTotalMainPlacementsMoneyUsed],
  (totalLimitedPlacementsMoneyUsed, totalMainPlacementsMoneyUsed) => (
    totalLimitedPlacementsMoneyUsed + totalMainPlacementsMoneyUsed
  ),
);

export const getTotalMoneyAvailable = createSelector(
  [getTotalInvestment, getTotalMoneyUsed],
  (totalInvestment, totalMoneyUsed) => (totalInvestment - totalMoneyUsed),
);

export const getTotalMainPlacementMoneyAvailable = createSelector(
  [getTotalInvestment, getTotalLimitedPlacementsMoneyUsed],
  (totalInvestment, totalLimitedPlacementsMoneyUsed) => (
    totalInvestment - totalLimitedPlacementsMoneyUsed
  ),
);

export const hasPublishedAssets = createSelector(
  [
    (state) => state.RateCard.cards,
    (state, rateCardId) => rateCardId,
  ],
  (cards, rateCardId) => {
    return !!cards.find((card) => card.id === rateCardId && card.isAssetPublished);
  },
);

export const getRateCardAssetById = createSelector(
  [
    getRateCardAssetsList,
    (state, assetId) => assetId,
    (state, assetId, returnInFormEditReadyFormat) => returnInFormEditReadyFormat,
  ],
  (assetList, assetId, returnInFormEditReadyFormat) => {
    return assetList.find((asset) => asset.id === assetId);
  },
);

export const hasAssets = createSelector(
  [getRateCardAssetsList],
  (assetList) => assetList.length,
);

import _ from 'lodash';
import PACKAGE_MAPPING from '../packageMappingConfiguration';
import { CampaignTypes } from '../constants';
import {
  getCampaignColumnNames,
} from './campaign';

export const getPackageMapping = (packageName) => {
  const packageMapping = PACKAGE_MAPPING[packageName];
  if (_.isEmpty(packageMapping)) {
    return {};
  }

  return {
    getOnlineCampaignPresets: () => {
      const onlineCampaignPresets = packageMapping[CampaignTypes.ONLINE];
      if (!onlineCampaignPresets) {
        return [];
      }

      return onlineCampaignPresets.map(onlineCampaignPreset => {
        return _.pick(onlineCampaignPreset, getCampaignColumnNames());
      });
    },
    getInStoreCampaignPresets: () => {
      return packageMapping[CampaignTypes.IN_STORE] || [];
    },
  };
};

import { connect } from 'react-redux';
import CommentsPanel from './CommentsPanel';
import {
  onCommentsAdd,
  onCommentsToggleAutoScrolling,
} from '../../../actionCreators';

export const mapStateToProps = (state) => {
  const { Comments: { isPosting, isAutoScrollingEnabled } } = state;

  return {
    isPosting,
    isAutoScrollingEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onToggleAutoScrolling: () => {
      dispatch(onCommentsToggleAutoScrolling());
    },
    onAddComment: ({ campaignId, message }) => {
      dispatch(onCommentsAdd({ campaignId, message }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentsPanel);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Announcement.module.scss';
import { sanitize } from '../../helpers/common';

const Announcement = ({ announcements }) => {
  const announcementsList = announcements.map((announcement) => {
    const { id, message } = announcement;

    return (
      <li
        className={styles.announcementContainer}
        key={id}
      >
        <div className={styles.messageContainer}>
          {/* eslint-disable react/no-danger */}
          <p
            className={styles.announcement}
            dangerouslySetInnerHTML={{ __html: sanitize(message) }}
          />
        </div>
      </li>
    );
  });

  return announcements.length > 0
    ? (
      <div className={styles.container}>
        <ul className={styles.announcements}>
          {announcementsList}
        </ul>
      </div>
    )
    : null;
};

Announcement.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
  })).isRequired,
};

export default Announcement;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Button from '@amzn/meridian/button';
import { useHistory } from 'react-router-dom';
import { Language } from '../../constants';
import styles from './RateCardAssetsMenu.module.scss';
import { RateCardAssetsMenuLinksName } from '../../constants/rateCard';

const RateCardAssetsMenu = (props) => {
  const {
    publishedCampaignListUrl,
    isShowCampaignButtonAvailable,
    isDeleteAllButtonAvailable,
    onDeleteAllAssets,
  } = props;

  const history = useHistory();
  const buttonRef = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const onOpenMenu = () => setMenuOpen(true);
  const onCloseMenu = () => setMenuOpen(false);
  const onShowPublishedCampaigns = () => history.push(publishedCampaignListUrl);

  const onDeleteAllExistingAssets = () => {
    if (window.confirm(Language.RATE_CARD_CONFIRM_DELETE_ALL_ASSETS)) {
      onDeleteAllAssets();
    }
  };

  const getAssetsMenuButton = () => {
    return (
      <Button
        ref={buttonRef}
        onClick={onOpenMenu}
        aria-controls="my-menu"
        aria-haspopup="menu"
        aria-expanded="false"
      >
        Assets Actions
      </Button>
    );
  };

  const getMenu = () => {
    const menuItemList = [
      {
        onClick: onShowPublishedCampaigns,
        isDisabled: !isShowCampaignButtonAvailable,
        display: RateCardAssetsMenuLinksName.SHOW_PUBLISHED,
      },
      {
        onClick: onDeleteAllExistingAssets,
        isDisabled: !isDeleteAllButtonAvailable,
        display: RateCardAssetsMenuLinksName.DELETE_ALL,
      },
    ].map(({ onClick, isDisabled, display }) => (
      <MenuItem
        key={display}
        onClick={onClick}
        disabled={isDisabled}
      >
        {display}
      </MenuItem>
    ));

    return (
      <Menu
        anchorNode={buttonRef.current}
        id="asset-actions-menu"
        open={isMenuOpen}
        position="bottom"
        onClose={onCloseMenu}
        alignment="end"
      >
        {menuItemList}
      </Menu>
    );
  };

  const assetsMenuButton = getAssetsMenuButton();
  const menu = getMenu();

  return (
    <div className={styles.menuContainer}>
      {assetsMenuButton}
      {menu}
    </div>
  );
};

RateCardAssetsMenu.propTypes = {
  publishedCampaignListUrl: PropTypes.string,
  isShowCampaignButtonAvailable: PropTypes.bool,
  isDeleteAllButtonAvailable: PropTypes.bool,
  onDeleteAllAssets: PropTypes.func.isRequired,
};

RateCardAssetsMenu.defaultProps = {
  publishedCampaignListUrl: '',
  isShowCampaignButtonAvailable: false,
  isDeleteAllButtonAvailable: false,
};

export default RateCardAssetsMenu;

// TODO: Unify these controls and the ones in ActiveCellInputs component
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Input from '@amzn/meridian/input';
import Checkbox from '@amzn/meridian/checkbox';
import DatePicker from '@amzn/meridian/date-picker';
import Textarea from '@amzn/meridian/textarea';
import TimeSelect, { timeRange } from '@amzn/meridian/time-select';
import Hint from '../components/PackageForm/Hint';
import HintLink from '../components/PackageForm/HintLink';
import { formControlProps } from '../proptypes';
import { shouldShowHintLink } from './form';
import { Language } from 'src/constants';
import { getTimeOptions, isValidHalfHourTime, padTimeWithSeconds } from './dateTime';
import styles from '../components/PackageForm/PackageForm.module.scss';
import sitewideStyles from '../styles/sitewide.module.scss';

// TODO: Extract controls to PackageForm/Controls
export const genericControl = (props, type = 'text') => {
  const {
    input,
    label,
    placeholder,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
    isDisabled,
  } = props;

  const requiredFieldMark = isRequired
    ? <span className={styles.controlRequiredMark}>*</span>
    : null;

  const hintLink = shouldShowHintLink(input.name)
    ? (
      <HintLink
        urlLink={input.value}
      />
    )
    : null;

  const hint = hintLink
    ? (
      <Hint>
        {hintLink}
      </Hint>
    )
    : null;

  /* eslint-disable react/jsx-fragments */
  return (
    <div className={styles.controlBox}>
      {requiredFieldMark}
      <Input
        {...input}
        onChange={input.onChange}
        label={label}
        placeholder={placeholder}
        error={touched && !!error}
        type={type}
        size="small"
        disabled={isDisabled}
      />
      {hint}
      {touched && ((error
        && (
          <p className={
            classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)
          }
          >
            {error}
          </p>
        ))
        || (warning
          && (
            <p className={
              classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)
            }
            >
              {warning}
            </p>
          )))}
    </div>
  );
};

genericControl.propTypes = formControlProps;

genericControl.defaultProps = {
  label: '',
  placeholder: '',
};

export const inputControl = (props) => {
  return genericControl(props, 'text');
};

export const timeControl = (props) => {
  return genericControl(props, 'time');
};

export const numberControl = (props) => {
  return genericControl(props, 'number');
};

export const datePickerControl = (props) => {
  const {
    input,
    startLabel,
    endLabel,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
    isDisabled,
    presets,
    isDisabledDate,
    customOnBlur,
  } = props;

  const { name, value } = input;

  /* eslint-disable react/jsx-fragments */
  return (
    <div className={styles.controlBox}>
      {isRequired ? <span className={styles.controlRequiredMark}>*</span> : null}
      <DatePicker
        {...input}
        onChange={input.onChange}
        onBlur={() => {
          customOnBlur(name, value);
        }}
        startLabel={startLabel}
        endLabel={endLabel}
        monthsInView={1}
        error={touched && !!error}
        disabled={isDisabled}
        disabledDates={isDisabledDate}
        presets={presets}
      />
      {touched && ((error
        && (
          <p className={
            classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)
          }
          >
            {error}
          </p>
        ))
        || (warning
          && (
            <p className={
              classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)
            }
            >
              {warning}
            </p>
          )))}
    </div>
  );
};

datePickerControl.propTypes = {
  isDisabled: PropTypes.bool,
  isDisabledDate: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  input: PropTypes.object.isRequired,
  customOnBlur: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  presets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

datePickerControl.defaultProps = {
  startLabel: '',
  endLabel: '',
  isDisabled: false,
  presets: [],
};


const renderWarningMessage = (warning) => {
    return (
      <p className={classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)}>
        {warning}
      </p>
    );
}


const renderErrorMessage = (error) => {
  return (
    <p className={classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)}>
      {error}
    </p>
  );
};


export const timePickerControl = (props) => {
  const {
    input,
    label,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
  } = props;
  
  const { name, value } = input;  
  const isInvalid = touched && error;
  const formattedValue = padTimeWithSeconds(value);
  const timeOptions = getTimeOptions(formattedValue);

  /* eslint-disable react/jsx-fragments */
  return (
    <div className={styles.controlBox}>
      {isRequired ? <span className={styles.controlRequiredMark}>*</span> : null}
      <TimeSelect
        {...input}
        onChange={input.onChange}
        value={formattedValue || ''}
        error={isInvalid}
        errorMessage={!isValidHalfHourTime(formattedValue) ? Language.INVALID_TIME_FORMAT : null}
        options={timeOptions}
        disabledOptions={!isValidHalfHourTime}
      />
      {isInvalid && renderErrorMessage(error)}
      {!isInvalid && renderWarningMessage(warning)}
    </div>
  );
};

timePickerControl.propTypes = {
  isRequired: PropTypes.bool.isRequired,
  /* eslint-disable react/forbid-prop-types */
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
  presets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

timePickerControl.defaultProps = {
  isDisabled: false,
};



export const textAreaControl = (props) => {
  const {
    input,
    label,
    placeholder,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
    isDisabled,
    rows = 2,
  } = props;

  /* eslint-disable react/jsx-fragments */
  return (
    <div className={styles.controlBox}>
      {isRequired ? <span className={styles.controlRequiredMark}>*</span> : null}
      <Textarea
        {...input}
        onChange={input.onChange}
        label={label}
        placeholder={placeholder}
        error={touched && !!error}
        size="small"
        disabled={isDisabled}
        rows={rows}
      />
      {touched && ((error
        && (
          <p className={
            classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)
          }
          >
            {error}
          </p>
        ))
        || (warning
          && (
            <p className={
              classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)
            }
            >
              {warning}
            </p>
          )))}
    </div>
  );
};

textAreaControl.propTypes = formControlProps;
textAreaControl.defaultProps = {
  label: '',
  placeholder: '',
  isDisabled: false,
};

export const checkboxControl = (props, type = 'text') => {
  const {
    input,
    label,
    placeholder,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
    isDisabled,
  } = props;

  return (
    <div className={styles.controlBox}>
      {isRequired ? <span className={styles.controlRequiredMark}>*</span> : null}
      <Checkbox
        {...input}
        checked={input.value || false}
        onChange={(data) => {
          input.onChange(data);
        }}
        error={touched && !!error}
        type={type}
        size="medium"
        disabled={isDisabled}
      >
        <span className={sitewideStyles.checkboxLabel}>
          {placeholder || label}
        </span>
      </Checkbox>
      {touched && ((error
        && (
          <p className={
            classnames(sitewideStyles.validationMessage, sitewideStyles.validationError)
          }
          >
            {error}
          </p>
        ))
        || (warning
          && (
            <p className={
              classnames(sitewideStyles.validationMessage, sitewideStyles.validationWarning)
            }
            >
              {warning}
            </p>
          )))}
    </div>
  );
};

checkboxControl.propTypes = formControlProps;

checkboxControl.defaultProps = {
  label: '',
  placeholder: '',
};

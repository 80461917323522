import { connect } from 'react-redux';
import RateCardContainerTabs from './RateCardContainerTabs';
import {
  onRateCardSetTab,
} from '../../../actionCreators';

export const mapStateToProps = (state) => {
  const {
    RateCard: {
      currentTab,
    },
  } = state;

  return {
    currentTab,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSwitchTab(tabName) {
      dispatch(onRateCardSetTab({ tabName }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardContainerTabs);

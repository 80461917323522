import React from 'react';
import Alert from '@amzn/meridian/alert';
import PropTypes from 'prop-types';
import { Language } from '../../../../../../constants';

const VSSMSlotWarning = ({ showWarning }) => {
  return showWarning ? (
    <Alert type="warning">
      {Language.VSSM_EXCLUSIVE_SLOTS_WARNING}
    </Alert>
  ) : null;
};
VSSMSlotWarning.propTypes = {
  showWarning: PropTypes.bool.isRequired,
};
export default VSSMSlotWarning;

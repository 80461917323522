import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import {
  onCommentsSet,
  onCommentsSort,
  onCommentsSetPosting,
} from '../../actionCreators';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import { createComment } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

export default (dispatch, getState, action) => {
  const { User: { currentUser: { username } } } = getState();
  const { payload: { campaignId, message } } = action;

  const metricContext = {
    location: MetricLocations.COMMENTS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.CREATE_COMMENT,
    username,
  };

  const input = {
    comment: {
      campaignId,
      message,
      createdBy: username,
    }
  };

  dispatch(onCommentsSetPosting(true));
  dispatch(onGraphQLAction({
    graphqlAction: createComment,
    data: {
      input,
    },
    label: GraphQLLabels.CREATE_COMMENT,
    onSuccessFunction({ data: { createComment: newComment } }) {
      if (!newComment) {
        submitError('createComment returned an empty result', metricContext);
        dispatch(onCommentsSetPosting(false));
        return;
      }
      dispatch(onCommentsSet({
        comments: [
          newComment,
        ],
      }));
      dispatch(onCommentsSort());
      dispatch(onCommentsSetPosting(false));
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
      dispatch(onCommentsSetPosting(false));
    },
  }));
};

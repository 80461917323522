import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HashLink as Link } from 'react-router-hash-link';
import UtilityChart from './UtilityChart';
import ActiveUtilityFilters from './ActiveUtilityFilters';
import UpdateUtilityFiltersModal from './UpdateUtilityFiltersModal';
import { slotUtilizationProps } from '../../../proptypes';

import styles from './Utilization.module.scss';
import siteWideStyle from '../../../styles/sitewide.module.scss';
import { getFiltersUrl } from '../../../helpers/utilization';
import { CampaignColumns } from '../../../constants';

const Utilization = (props) => {
  const {
    selectedFilters,
    desktopGroups,
    mobileGroups,
    getGroupName,
    getGroupDescription,
    desktopChartItems,
    mobileChartItems,
    selectedDateRange,
  } = props;

  const [, endDate] = selectedDateRange;

  const processDateList = (date, utilization, slotName, slotType) => {
    if (!utilization[date]) {
      return null;
    }

    const link = getFiltersUrl(slotType, slotName, date, endDate, selectedFilters);

    const { totalSOV, numberOfCampaigns } = utilization[date];
    const content = `${date}: ${totalSOV}% (${numberOfCampaigns})`;

    return (
      <li
        className={styles.groupScheduleItem}
        key={`${date}`}
      >
        <Link
          smooth
          to={link}
          className={siteWideStyle.link}
        >
          {content}
        </Link>
      </li>
    );
  };

  const processSlots = (slotName, group, slotType) => {
    if (!group[slotName]) {
      return null;
    }
    const { placement, utilization } = group[slotName];

    let schedule = null;
    if (utilization) {
      const datesList = Object.keys(utilization);
      schedule = datesList.map((date) => processDateList(date, utilization, slotName, slotType));
    }

    return (
      <li
        className={styles.groupItem}
        key={`${slotName}/${placement}`}
      >
        <p className={styles.slotName}>{slotName}</p>
        <div className={styles.slotBodyContainer}>
          <p className={styles.placement}>
            Placement:
            {' '}
            {placement}
          </p>
          <div className={styles.scheduleSection}>
            <p className={styles.scheduleTitle}>
              Schedule:
            </p>
            <ul className={styles.groupScheduleList}>
              {schedule}
            </ul>
          </div>
        </div>
      </li>
    );
  };

  const processGroups = (groupName, groups, slotType) => {
    if (!groups[groupName]) {
      return null;
    }
    const group = groups[groupName];
    const list = Object.keys(group).map((slotName) => processSlots(slotName, group, slotType));

    const groupDescriptionData = getGroupDescription(groupName);

    let groupDescription = null;
    if (groupDescriptionData) {
      const [start, end] = groupDescriptionData;
      groupDescription = `(${start}% - ${end}%)`;
    }

    return (
      <div
        className={styles.slotsContainer}
        key={groupName}
      >
        <p className={classnames({
          [styles.groupTitle]: true,
          [styles.closed]: true,
        })}
        >
          {getGroupName(groupName)}
          {' '}
          {groupDescription}
        </p>
        <ul className={styles.groupContainer}>
          {list}
        </ul>
      </div>
    );
  };

  const getItems = (groups, slotType) => Object.keys(groups).map((group) => {
    return processGroups(group, groups, slotType);
  });

  let desktopSlotsUtilizationBlock = <p>No data available</p>;
  if (Object.keys(desktopGroups).length) {
    desktopSlotsUtilizationBlock = (
      <div className={styles.groupBlock}>
        <p className={styles.groupBlockTitle}>Desktop slots</p>
        <UtilityChart chartItems={desktopChartItems} />
        {getItems(desktopGroups, CampaignColumns.DESKTOP_SLOT.name)}
      </div>
    );
  }
  let mobileSlotsUtilizationBlock = <p>No data available</p>;
  if (Object.keys(mobileGroups).length) {
    mobileSlotsUtilizationBlock = (
      <div className={styles.groupBlock}>
        <p className={styles.groupBlockTitle}>Mobile slots</p>
        <UtilityChart chartItems={mobileChartItems} />
        {getItems(mobileGroups, CampaignColumns.MOBILE_SLOT.name)}
      </div>
    );
  }

  return (
    <div>
      <ActiveUtilityFilters />
      <UpdateUtilityFiltersModal />
      {desktopSlotsUtilizationBlock}
      {mobileSlotsUtilizationBlock}
    </div>
  );
};

Utilization.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string).isRequired,
  desktopGroups: PropTypes.objectOf(
    PropTypes.shape({
      placement: PropTypes.string,
      utilization: PropTypes.objectOf(
        slotUtilizationProps,
      ),
    }),
  ),
  mobileGroups: PropTypes.objectOf(
    PropTypes.shape({
      placement: PropTypes.string,
      utilization: PropTypes.objectOf(
        slotUtilizationProps,
      ),
    }),
  ),
  getGroupName: PropTypes.func.isRequired,
  getGroupDescription: PropTypes.func.isRequired,
  desktopChartItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  ).isRequired,
  mobileChartItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  ).isRequired,
  selectedDateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Utilization.defaultProps = {
  desktopGroups: {},
  mobileGroups: {},
};

export default Utilization;

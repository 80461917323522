import { RateCardMerchTypes, RateCardPlacementTypes } from '../../../../constants/rateCard';

const VSSMPlacements = [
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'VSSM Storefront Shoveler - Standard (1-week)',
    dollars: 50000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'VSSM Storefront Shoveler - Standard (2-weeks)',
    dollars: 100000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 26,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'VSSM Storefront Shoveler - Standard (4-weeks)',
    dollars: 200000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'VSSM Category Shoveler (1-quarter)',
    dollars: 25000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'VSSM Category Shoveler (2-weeks)',
    dollars: 8000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'VSSM Category Shoveler (4-weeks)',
    dollars: 9000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
];

export default VSSMPlacements;

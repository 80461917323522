/* eslint-disable radix */
/* eslint-disable consistent-return */
import _ from 'lodash';
import { arrayPush, arrayRemoveAll } from 'redux-form';
import formValueSelector from 'redux-form/lib/formValueSelector';
import getConfig from '../../helpers/getRateCardConfig';
import {
  RateCardColumns,
  RateCardPlacementTypes,
} from '../../constants/rateCard';
import {
  getMediaPackageByValue,
  getMerchantClassesList,
  hasBusinessTypeMatch,
} from '../../helpers/rateCard';
import {
  getCurrentConfigName,
  getTotalMainPlacementMoneyAvailable,
} from '../../selectors/rateCardSelectors';
import { Form } from '../../constants';
import { trimAndLowerCase } from '../../helpers/common';

const getMainBonusPlacementsWithAllocatedQuantities = (bonusMainPlacementList, mediaPackage) => {
  if (!bonusMainPlacementList || !mediaPackage) {
    return [];
  }

  return bonusMainPlacementList
    .filter((bonusPlacement) => (
      hasBusinessTypeMatch(bonusPlacement) && mediaPackage === bonusPlacement.mediaPackage))
    .map(({ numberPlacements, ...rest }) => ({
      ...rest,
      plannedPlacements: numberPlacements,
    }));
};

const allocatePlacementsQuantities = (
  placements,
  merchantClass,
  merchantClassGroupBudget = 0,
  lowestPlacementCost = 0,
) => {
  if (merchantClassGroupBudget <= 0
    || (lowestPlacementCost && merchantClassGroupBudget < lowestPlacementCost)) {
    return;
  }

  let currentGroupBudget = merchantClassGroupBudget;
  let currentLowestPlacementCost = lowestPlacementCost;

  placements.forEach((placement) => {
    const { className, dollars } = placement;

    if (!placement.plannedPlacements) {
      /* eslint-disable-next-line no-param-reassign */
      placement.plannedPlacements = 0;
    }

    if (
      !hasBusinessTypeMatch(placement)
      || trimAndLowerCase(merchantClass) !== trimAndLowerCase(className)
      || dollars > currentGroupBudget
    ) {
      return;
    }

    /* eslint-disable-next-line no-param-reassign */
    placement.plannedPlacements++;
    currentGroupBudget -= dollars;

    if (dollars < currentLowestPlacementCost || currentLowestPlacementCost === 0) {
      currentLowestPlacementCost = dollars;
    }
  });

  allocatePlacementsQuantities(
    placements,
    merchantClass,
    currentGroupBudget,
    currentLowestPlacementCost,
  );
};

const getPlacementsWithDefaultAllocatedQuantities = (placements) => {
  if (!placements || !placements.length) {
    return [];
  }

  return placements
    .filter((placement) => hasBusinessTypeMatch(placement))
    .map((placement) => ({
      ...placement,
      plannedPlacements: 0,
    }));
};

const getMainPlacementsWithAllocatedQuantities = (placements, placementSpreadLimit) => {
  if (!placements || !placements.length || !Object.keys(placementSpreadLimit).length) {
    return [];
  }

  const placementsWithAllocatedQuantities = _.cloneDeep(placements);

  getMerchantClassesList().forEach((merchantClass) => {
    const classGroupSpread = placementSpreadLimit[merchantClass];
    if (!classGroupSpread || !classGroupSpread.dollars) {
      return;
    }
    allocatePlacementsQuantities(
      placementsWithAllocatedQuantities,
      merchantClass,
      classGroupSpread.dollars,
    );
  });

  return placementsWithAllocatedQuantities;
};

const getPlacements = (
  mediaPackage,
  placementSpreadLimit,
  rateCardConfigName,
  isLimitedPlacement,
  isVSSMPlacement,
) => {
  const config = getConfig(rateCardConfigName);

  if (isLimitedPlacement) {
    return getPlacementsWithDefaultAllocatedQuantities(config.getLimitedPlacements());
  }

  if (isVSSMPlacement) {
    return getPlacementsWithDefaultAllocatedQuantities(config.getVSSMPlacements());
  }

  const mainPlacements = getMainPlacementsWithAllocatedQuantities(
    getPlacementsWithDefaultAllocatedQuantities(config.getMainPlacements()),
    placementSpreadLimit,
  );

  if (!mainPlacements || !mainPlacements.length) {
    return [];
  }

  const bonusPlacements = getMainBonusPlacementsWithAllocatedQuantities(
    config.getBonusMainPlacements(),
    mediaPackage,
  );

  if (!bonusPlacements || !bonusPlacements.length) {
    return mainPlacements;
  }

  return mainPlacements.concat(bonusPlacements);
};

const getMerchantClassGroupSpreadLimits = (merchIntent, totalInvestment) => (
  Object.values(merchIntent).reduce((merchantClassGroupSpreadLimits, { className, spread }) => ({
    ...merchantClassGroupSpreadLimits,
    [className]: {
      spread,
      dollars: Math.ceil(totalInvestment * (spread / 100)),
    },
  }), {})
);

const getPlacementFieldName = (placementType) => ({
  [RateCardPlacementTypes.LIMITED]: RateCardColumns.LIMITED_PLACEMENTS.name,
  [RateCardPlacementTypes.MAIN]: RateCardColumns.MAIN_PLACEMENTS.name,
  [RateCardPlacementTypes.MAIN_VSSM]: RateCardColumns.MAIN_PLACEMENTS.name,
}[placementType] || '');

export default (dispatch, getState, action, next) => {
  const { payload: { placementType } } = action;

  const placementFieldName = getPlacementFieldName(placementType);
  if (!placementFieldName) {
    return next(action);
  }

  const state = getState();
  const selector = formValueSelector(Form.RATE_CARD_NAME);
  const rateCardConfigName = getCurrentConfigName(state);

  const {
    mediaPackage,
    merchIntent,
    isVSSM,
  } = selector(state, ...[
    RateCardColumns.MEDIA_PACKAGE.name,
    RateCardColumns.MERCHANDISING_INTENT.name,
    RateCardColumns.IS_VSSM.name,
  ]);

  const mediaPackageName = getMediaPackageByValue(mediaPackage);
  const merchantClassGroupSpreadLimits = getMerchantClassGroupSpreadLimits(
    merchIntent,
    getTotalMainPlacementMoneyAvailable(state),
  );

  dispatch(arrayRemoveAll(Form.RATE_CARD_NAME, placementFieldName));
  if (isVSSM) {
    dispatch(arrayRemoveAll(Form.RATE_CARD_NAME, RateCardColumns.LIMITED_PLACEMENTS.name));
  }

  const placements = getPlacements(
    mediaPackageName,
    merchantClassGroupSpreadLimits,
    rateCardConfigName,
    placementType === RateCardPlacementTypes.LIMITED,
    isVSSM,
  );
  if (!placements || !placements.length) {
    return next(action);
  }

  const sortedPlacements = _.orderBy(placements, ['className', 'type', 'dollars', 'placementName']);
  sortedPlacements.forEach((placement) => {
    dispatch(arrayPush(Form.RATE_CARD_NAME, placementFieldName, placement));
  });

  return next(action);
};

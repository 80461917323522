/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CalendarRecordConfig } from '../../constants/calendar';
import { CalendarPlacementProps } from '../../proptypes';
import { getUtilizationCellStyles } from '../../styles/utilization';
import styles from './Calendar.module.scss';
import { DateFormats } from '../../constants';

const formatDate = (date) => moment(date).format(DateFormats.MM_DD_YYYY);

const CalendarRecord = (props) => {
  const {
    index,
    placement,
    recordWidth,
    startPosition,
    onClick,
  } = props;
  // Stack records on top of each other, each new record should be underneath the previous one
  const topPosition = index
    * CalendarRecordConfig.CALENDAR_RECORD_HEIGHT + CalendarRecordConfig.CALENDAR_RECORD_MARGIN;

  const {
    guaranteePercentage, status, displayName, startTime, endTime,
  } = placement;

  const percentageDisplay = guaranteePercentage ? `${guaranteePercentage}%` : 'Default';
  const percentage = recordWidth > CalendarRecordConfig.MIN_RECORD_WIDTH && (
    <div className={styles.sov}>
      {percentageDisplay}
    </div>
  );

  const dateRange = `${formatDate(startTime[0])} - ${formatDate(endTime[0])}`;

  const onRecordClick = (entity) => () => onClick(entity);
  return (
    <div
      className={classNames({
          [styles.record]: true,
          ...getUtilizationCellStyles(status)
        })}
      style={{
        top: topPosition,
        width: recordWidth - CalendarRecordConfig.RECORD_PADDING - 5,
        left: startPosition + CalendarRecordConfig.RECORD_PADDING,
      }}
      onClick={onRecordClick(placement)}
    >
      {percentage}
      <p>
        <b>
          {displayName}
        </b>
        {' '}
        (
        <span>{status}</span>
        {' '}
        |
        {dateRange}
        )
      </p>
    </div>
  );
};

CalendarRecord.propTypes = {
  index: PropTypes.number.isRequired,
  placement: CalendarPlacementProps.isRequired,
  recordWidth: PropTypes.number.isRequired,
  startPosition: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CalendarRecord;

import { getInitialSlotsList } from '../../selectors/utilizationDataSelectors';
import { onUtilizationSetSlotsOrder } from '../../actionCreators';
import { CampaignColumns } from '../../constants';

export default (dispatch, getState, action, next) => {
  next(action);
  const state = getState();

  const {
    UtilizationPage: {
      slotsOrder,
      selectedFilters: {
        pageType,
      },
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  if (
    !Object.keys(slotsOrder).length
    || !slotsOrder[selectedBusiness]
    || !slotsOrder[selectedBusiness][pageType]
  ) {
    const { desktopSlots, mobileSlots } = getInitialSlotsList(state);

    if (desktopSlots.length) {
      dispatch(onUtilizationSetSlotsOrder({
        slots: desktopSlots,
        slotType: CampaignColumns.DESKTOP_SLOT.name,
      }));
    }
    if (mobileSlots.length) {
      dispatch(onUtilizationSetSlotsOrder({
        slots: mobileSlots,
        slotType: CampaignColumns.MOBILE_SLOT.name,
      }));
    }
  }
};

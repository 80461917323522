import { API, graphqlOperation } from 'aws-amplify';
import {
  CommentsConfig, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { getCommentsForCampaignId } from '../../graphql/queries';
import {
  onCommentsLoad,
  onCommentsSet,
  onCommentsSort,
} from '../../actionCreators';
import { submitAppSyncError } from '../../metrics';

export default (store) => () => (action) => {
  const { dispatch, getState } = store;

  const getComments = (campaignId, token) => {
    const { User: { currentUser: { username } } } = getState();

    const params = {
      campaignId,
      nextToken: token,
      limit: CommentsConfig.LOAD_LIMIT,
    };

    const metricContext = {
      location: MetricLocations.COMMENTS_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: params,
      action: MetricNames.LOAD_COMMENTS,
      username,
    };

    return API.graphql(graphqlOperation(getCommentsForCampaignId, params))
      .then(({ data: { getCommentsForCampaignId: { items, nextToken } } }) => {
        dispatch(onCommentsSet({ comments: items, shouldOverride: true }));
        if (nextToken) {
          dispatch(onCommentsLoad({ campaignId, nextToken }));
        } else {
          dispatch(onCommentsSort());
        }
      }).catch((error) => {
        submitAppSyncError(error, metricContext);
      });
  };

  const { campaignId, nextToken } = action.payload;

  return getComments(campaignId, nextToken);
};

import {
  onBulkImportSetForImport,
  onBulkImportStartImporting,
  onRateCardAssetAdd,
  onOpenToast,
  onBulkImportCompleteImporting,
  onBulkImportClearPendingPackages,
  onBulkImportActionCompleted,
  onRateCardAssetUpdate,
} from '../../actionCreators';
import { BulkImportActions, Language } from '../../constants';
import { getRateCardAssetById } from '../../helpers/rateCard';

export default (dispatch, state, action) => {
  const {
    BulkImport: { accepted: { create, update } },
    RateCard: { assets },
  } = state;
  const { payload: { rateCardId } } = action;
  const expectedCampaignCount = create.length + update.length;
  dispatch(onBulkImportSetForImport({ expectedCampaignCount }));
  dispatch(onBulkImportStartImporting());
  // dispatch(onRateCardBatchDeleteAssets());
  create.forEach((campaignToCreate) => {
    const assetToCreate = {
      rateCardId,
      campaign: campaignToCreate,
    };
    /* eslint-disable-next-line no-param-reassign */
    delete campaignToCreate.rateCardId;
    dispatch(onRateCardAssetAdd(assetToCreate, true));
  });
  update.forEach((campaignToUpdate) => {
    const existingAsset = getRateCardAssetById(assets, campaignToUpdate.assetId);
    // Campaign not loaded in memory, possibly from page still loading
    if (!existingAsset) {
      dispatch(onOpenToast(`Update failed: Asset with ID "${campaignToUpdate.assetId}" not found in the Rate Card.`, 0));
      dispatch(onBulkImportActionCompleted(
        campaignToUpdate.campaignName,
        BulkImportActions.ERROR,
      ));
      return;
    }

    const updatedCampaign = {
      ...existingAsset.campaign,
      ...campaignToUpdate,
    };

    /* eslint-disable-next-line no-param-reassign */
    delete updatedCampaign.rateCardId;
    /* eslint-disable-next-line no-param-reassign */
    delete updatedCampaign.assetId;

    const assetToSend = {
      rateCardId: campaignToUpdate.rateCardId,
      id: campaignToUpdate.assetId,
      campaign: updatedCampaign,
      version: existingAsset.version,
    };

    dispatch(onRateCardAssetUpdate(assetToSend, true));
  });

  dispatch(onOpenToast(Language.RATE_CARD_ASSETS_CHANGES_APPLIED));
  dispatch(onBulkImportCompleteImporting());
  dispatch(onBulkImportClearPendingPackages());
};

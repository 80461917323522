export const CalendarRecordConfig = Object.freeze({
  CALENDAR_RECORD_HEIGHT: 40,
  CALENDAR_RECORD_MARGIN: 8,
  MIN_RECORD_WIDTH: 144,
  RECORD_PADDING: 4,
});

export const CalendarDateFormatOptions = Object.freeze({
  weekday: 'short',
  month: 'short',
  day: 'numeric',
});

import React from 'react';
import PropTypes from 'prop-types';
import Tile from '@amzn/meridian/tile';

const CalendarWeek = (props) => {
  const {
    children,
    highlightingClass,
  } = props;

  return (
    <Tile accentPosition="start">
      <div className={highlightingClass}>
        {children}
      </div>
    </Tile>
  );
};

CalendarWeek.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  highlightingClass: PropTypes.string,
};
CalendarWeek.defaultProps = {
  highlightingClass: '',
};

export default React.memo(CalendarWeek);

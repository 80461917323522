import _ from 'lodash';
import {
  onRateCardMarkAsPublished,
  onOpenToast,
  onBulkImportSetForImport,
} from '../../actionCreators';
import { Language } from '../../constants';
import {
  createAddPackagesPromiseChain
} from '../GridViewRequestMiddleware/addPackage';

const isOldVersionRateCard = (assets) => {
  for (let i = 0; i < assets.length; i++) {
    const asset = assets[i].campaign;
    if (!asset.campaigns || _.isEmpty(asset.campaigns)) {
      return true;
    }
  }

  return false;
};

export default (dispatch, getState, action, next) => {
  const { RateCard: { assets } } = getState();

  if (!assets || !assets.length) {
    return next(action);
  }

  if (isOldVersionRateCard(assets)) {
    dispatch(onOpenToast(Language.RATE_CARD_CANNOT_PUBLISH_ASSETS));

    return next(action);
  }
  const packages = assets.map((asset) => asset.campaign);
  dispatch(onBulkImportSetForImport(packages.length));
  createAddPackagesPromiseChain(packages, dispatch, getState, { isBulkAction: true })

  dispatch(onRateCardMarkAsPublished(assets[0].rateCardId));

  return next(action);
};

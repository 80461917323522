import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const FormField = (props) => {
  const {
    name,
    type,
    label,
    options,
    placeholder,
    component,
    isRequired,
    customOnBlur,
    allowOverride,
    showAutoSelect,
    isDisabled,
    isDisabledDate,
    presets,
  } = props;

  return (
    <Field
      key={name}
      name={name}
      type={type}
      label={label}
      options={options}
      placeholder={placeholder}
      component={component}
      isRequired={isRequired}
      customOnBlur={customOnBlur}
      allowOverride={allowOverride}
      showAutoSelect={showAutoSelect}
      isDisabled={isDisabled}
      isDisabledDate={isDisabledDate}
      presets={presets}
    />
  );
};

FormField.propTypes = {
  isDisabled: PropTypes.bool,
  isDisabledDate: PropTypes.func,
  allowOverride: PropTypes.bool,
  showAutoSelect: PropTypes.bool,
  customOnBlur: PropTypes.func,
  isRequired: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
  presets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

FormField.defaultProps = {
  isDisabled: false,
  isDisabledDate: () => false,
  allowOverride: false,
  showAutoSelect: false,
  customOnBlur: _.noop,
  type: 'text',
  label: '',
  options: [],
  placeholder: '',
  presets: [],
};

export default FormField;

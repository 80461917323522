import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RadioButton from '@amzn/meridian/radio-button';
import Checkbox from '@amzn/meridian/checkbox';
import Button from '@amzn/meridian/button';
import styles from '../../RateCardForm.module.scss';
import { RateCardReportType } from '../../../../constants/rateCard';

const Reports = ({ onShowReport }) => {
  const [reportType, setReportType] = useState(RateCardReportType.EXTERNAL.name);
  const [showPointsInTheReport, setShowPointsInTheReport] = useState(true);

  const pointsCheckboxBlock = (
    <div className={styles.reportCheckbox}>
      <Checkbox
        checked={showPointsInTheReport}
        onChange={setShowPointsInTheReport}
      >
        Show placement points in the report
      </Checkbox>
    </div>
  );

  const reportTypes = Object.entries(RateCardReportType).map(([key, data]) => {
    const { name, display } = data;

    const pointsCheckbox = name === RateCardReportType.EXTERNAL.name
      ? pointsCheckboxBlock
      : null;

    return (
      <li className={styles.reportItem} key={key}>
        <RadioButton
          checked={reportType === name}
          onChange={setReportType}
          name="reportType"
          value={name}
        >
          {`${display} use`}
        </RadioButton>
        {pointsCheckbox}
      </li>
    );
  });

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Reports</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={classnames(styles.formGroupSection, styles.reportsSection)}>
            Report type:

            <ul className={styles.reportList}>
              {reportTypes}
            </ul>
            <Button onClick={onShowReport(reportType)}>
              Generate
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Reports.propTypes = {
  onShowReport: PropTypes.func.isRequired,
};

export default Reports;

import { deleteAnnouncement } from '../../graphql/mutations';
import {
  GraphQLLabels, Language, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import { onOpenToast } from '../../actionCreators';
import { submitAppSyncError, submitStringMetric } from '../../metrics';

const deleteRequest = ({
  state, dispatch, action, next,
}) => {
  const { data: { id } } = action;
  const {
    BulletinBoard: {
      announcements,
    },
    User: {
      currentUser: {
        username,
      },
    },
  } = state;

  const announcementToDelete = announcements.find((announcement) => announcement.id === id);
  if (!Object.keys(announcementToDelete).length) {
    dispatch(onOpenToast('Error while deleting announcement!'));
    return null;
  }

  const input = {
    announcement: announcementToDelete,
    username,
  };

  const metricContext = {
    location: MetricLocations.BULLETIN_BOARD_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: JSON.stringify(announcementToDelete),
    action: MetricNames.DELETE_ANNOUNCEMENT,
    username,
  };

  submitStringMetric(MetricNames.DELETE_ANNOUNCEMENT, metricContext);

  dispatch(onGraphQLAction({
    graphqlAction: deleteAnnouncement,
    data: { input },
    label: GraphQLLabels.DELETE_ANNOUNCEMENT,
    onSuccessFunction() {
      dispatch(onOpenToast(Language.BULLETIN_BOARD_ANNOUNCEMENT_DELETED));
    },
    onFailureFunction(error) {
      dispatch(onOpenToast(Language.BULLETIN_BOARD_ERROR_DELETE_ANNOUNCEMENT));
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};

export default deleteRequest;

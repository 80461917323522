import moment from 'moment';
import {
  CampaignStatuses,
  MomentJsDayNames,
  PlacementType,
} from '../constants';
import { InStoreUtilizationGroupByCategories } from '../constants/inStoreUtilization';
import {
  getQuarterEndDate,
  getQuarterStartDate,
  getClosestTargetDayWithinRange,
} from './dateTime';

export const getOptionsByName = (name, options) => {
  for (let i = 0; i < options.length; i++) {
    const [optionName, container] = options[i];
    if (optionName === name) {
      return container.options;
    }
  }

  return [];
};

export const getSingleOptionByKey = (options, name, caseInsensitive = false) => {
  let optionsList = [];

  Object.keys(options).forEach((optionKey) => {
    if (!caseInsensitive) {
      if (name.trim() === optionKey.trim()) {
        optionsList = options[optionKey];
        return;
      }
    }
    if (name.trim().toLowerCase() === optionKey.trim().toLowerCase()) {
      optionsList = options[optionKey];
    }
  });

  return optionsList;
};

export const getCaseInsensitiveSingleOptionByKey = (options, name) => {
  return getSingleOptionByKey(options, name, true);
};

export const getInStoreStatuses = (statuses) => {
  if (!statuses || !statuses.length) {
    return [];
  }

  const baseAllowedStatuses = [
    CampaignStatuses.CANCELED,
    CampaignStatuses.HOLD,
    CampaignStatuses.MISSING_INPUTS,
  ];

  return statuses.filter((status) => {
    const formattedStatus = status.trim().toLowerCase();
    if (formattedStatus.includes(PlacementType.IN_STORE.toLowerCase())) {
      return true;
    }

    return baseAllowedStatuses.includes(status);
  });
};

export const getGroupByCategories = () => Object
  .keys(InStoreUtilizationGroupByCategories)
  .map((key) => InStoreUtilizationGroupByCategories[key].label);

export const createDateRange = (currentQuarter = moment().quarter(), currentYear = moment().year()) => {
  const firstDayInRange = getQuarterStartDate(currentQuarter, currentYear);
  const lastDayInRange = getQuarterEndDate(currentQuarter, currentYear);

  const firstWednesdayInRange = getClosestTargetDayWithinRange(
    MomentJsDayNames.WEDNESDAY,
    firstDayInRange,
    lastDayInRange,
  );
  const lastTuesdayInRange = getClosestTargetDayWithinRange(
    MomentJsDayNames.TUESDAY,
    firstDayInRange,
    lastDayInRange,
    true,
  );

  return [
    firstWednesdayInRange,
    lastTuesdayInRange,
  ];
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './GridViewFilterControlMenuItem.module.scss';

const GridViewFilterControlMenuItem = ({ onClick, children, isActive }) => {
  const classes = classNames({
    [styles.menuItem]: true,
    [styles.active]: isActive,
  });

  return (
    <li
      className={classes}
    >
      <div
        className={styles.menuItemContent}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    </li>
  );
};

GridViewFilterControlMenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  isActive: PropTypes.bool,
};

GridViewFilterControlMenuItem.defaultProps = {
  onClick: () => {},
  children: '',
  isActive: false,
};

export default GridViewFilterControlMenuItem;

import moment from 'moment';
import {
  OPEN_TOAST,
  CLOSE_TOAST,
  TOGGLE_SIDE_MENU,
  SHOW_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  SET_NEW_USER_EXPERIENCE_SHOWN,
  FeatureNotificationActions,
  BusinessTypes,
  GridViewActions,
  SELECT_BUSINESS,
  SELECT_YEARS,
} from '../../constants';
import { SiteWideActions, WebsiteViewMode } from '../../constants/sitewide';

const defaultTimeout = 3000;

export const initialState = {
  startYears: [ moment().year().toString() ],
  toastId: 0,
  toasts: [],
  notifications: [],
  isSideMenuOpen: false,
  viewMode: WebsiteViewMode.NON_OMNICHANNEL.name,
  showLoader: false,
  showNewUserExperience: false,
  staticData: {
    businessTypes: Object.values(BusinessTypes),
  },
  isActionBarExpanded: false,
  isAppSyncConnectionAlive: true,
  isReadOnlyMode: false,
  selectedBusiness: BusinessTypes.FRESH.name,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_TOAST: {
      const timeout = typeof action.data.timeout === 'undefined' ? defaultTimeout : action.data.timeout;
      const toastId = state.toastId + 1;
      const newToast = {
        id: `${toastId}`, // required to be a string
        message: action.data.message,
        timeout,
      };
      return {
        ...state,
        toastId,
        toasts: [...state.toasts, newToast],
      };
    }
    case SELECT_BUSINESS: {
      return {
        ...state,
        selectedBusiness: action.data.business,
      };
    }
    case SELECT_YEARS: {
      const { payload: { startYears } } = action;
      return {
        ...state,
        startYears,
      }
    }
    case CLOSE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.data),
      };
    }
    case GridViewActions.TOGGLE_ACTION_BAR: {
      return {
        ...state,
        isActionBarExpanded: !state.isActionBarExpanded,
      };
    }
    case TOGGLE_SIDE_MENU: {
      return {
        ...state,
        isSideMenuOpen: !state.isSideMenuOpen,
      };
    }
    case SHOW_LOADING_SPINNER: {
      return {
        ...state,
        showLoader: true,
      };
    }
    case HIDE_LOADING_SPINNER: {
      return {
        ...state,
        showLoader: false,
      };
    }
    case FeatureNotificationActions.ADD_BULK: {
      return {
        ...state,
        notifications: action.data,
      };
    }
    case FeatureNotificationActions.CLOSE: {
      return {
        ...state,
        notifications: state.notifications.filter((notification) => {
          return notification.version !== action.data.version;
        }),
      };
    }
    case FeatureNotificationActions.CLOSE_BULK: {
      return {
        ...state,
        notifications: [],
      };
    }
    case SET_NEW_USER_EXPERIENCE_SHOWN: {
      return {
        ...state,
        showNewUserExperience: action.payload,
      };
    }
    case SiteWideActions.SWITCH_WEBSITE_VIEW_MODE: {
      const { payload: { viewMode } } = action;

      return {
        ...state,
        viewMode,
      };
    }
    case SiteWideActions.SET_APP_SYNC_CONNECTION_STATUS: {
      const { payload: { isAlive } } = action;

      return {
        ...state,
        isAppSyncConnectionAlive: isAlive,
      };
    }
    case SiteWideActions.SET_WEBSITE_READ_ONLY_MODE: {
      const { payload: { isReadOnlyMode } } = action;

      return {
        ...state,
        isReadOnlyMode,
      };
    }
    default: {
      return state;
    }
  }
};

// TODO: This whole component is temporary here under namespace "UtilizationCalendar".
// It will be moved under components/Calendar soon, once existing Calendar there
// is deprecated (@see more info on this in components/Calendar).
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import styles from './Calendar.module.scss';
import CalendarRow from './CalendarRow';
import { useColumnResizer } from './hooks/useColumnResizer';
import { Urls, DEFAULT_TIMEZONE_ID, UrlSearchQuery } from '../../constants';
import { CalendarDateFormatOptions } from '../../constants/calendar';
import { openCampaignModal } from '../../helpers/campaignModal';
import { groupedPlacementProps } from '../../proptypes';

const CalendarTable = (props) => {
  const history = useHistory();
  const {
    startTime, endTime, timezone, groupedPlacements,
  } = props;

  const tableRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const slotNameHeaderRef = useRef(null);
  const tableHeaderCellRefs = useRef([]);

  const datesList = useMemo(() => {
    const dates = [];
    let startDate = DateTime.fromISO(startTime).setZone(timezone);
    const stopDate = DateTime.fromISO(endTime).setZone(timezone);
    while (startDate <= stopDate) {
      dates.push(startDate);

      startDate = startDate.plus({ days: 1 });
    }

    return dates;
  }, [startTime, endTime, timezone]);

  const {
    tableWidth, slotColumnWidth, tableHeight, rowWidth,
  } = useColumnResizer({
    minCellWidth: 70,
    tableWrapperRef,
    tableRef,
    resizerRef,
    slotNameHeaderRef,
    tableHeaderCellRef: { current: tableHeaderCellRefs.current?.[0] },
  });

  const onOpenPackage = (campaign) => openCampaignModal(
    history,
    `${Urls.EDIT_PACKAGE}/${campaign.packageId}`,
    Urls.UTILIZATION,
    `${UrlSearchQuery.CAMPAIGN_ID}=${campaign.id}`
  );

  const getTableHeaders = () => {
    const daysList = datesList.map((value, index) => (
      <th
        key={value.toISO()}
        className={styles.calendarTableHeaderCell}
        ref={(node) => {
          tableHeaderCellRefs.current[index] = node;
        }}
      >
        <div>{value.toLocaleString(CalendarDateFormatOptions)}</div>
      </th>
    ));

    return (
      <thead>
        <tr>
          <th
            ref={slotNameHeaderRef}
            className={styles.slotNameHeader}
            style={{ width: slotColumnWidth }}
          >
            Slot name
            <div
              ref={resizerRef}
              style={{ height: tableHeight }}
              className={styles.resizer}
            />
          </th>
          {daysList}
        </tr>
      </thead>
    );
  };

  const getTableBody = () => {
    return groupedPlacements.map(
      ([slotName, placements]) => (
        <CalendarRow
          key={slotName}
          rowWidth={rowWidth}
          selectedDates={datesList}
          slotName={slotName}
          placements={placements}
          timezone={timezone}
          onClick={onOpenPackage}
        />
      ),
    );
  };

  const tableHeaders = getTableHeaders();
  const tableBody = getTableBody();

  return (
    <div className={styles.calendarTableWrapper}>
      <div id="table-wrapper" ref={tableWrapperRef}>
        <table
          ref={tableRef}
          className={styles.calendarTable}
          style={{ width: tableWidth }}
        >
          {tableHeaders}
          <tbody>
            {tableBody}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CalendarTable.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  timezone: PropTypes.string,
  groupedPlacements: groupedPlacementProps,
};
CalendarTable.defaultProps = {
  startTime: '00:00',
  endTime: '00:00',
  groupedPlacements: [],
  timezone: DEFAULT_TIMEZONE_ID,
};

export default CalendarTable;

import React from 'react';
import PropTypes from 'prop-types';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import { UtilizationRouteToType } from '../../../constants/utilization';

const UtilizationMenu = (props) => {
  const {
    buttonRef, isOpen, onClose, onClick,
  } = props;
  const linksList = Object.keys(UtilizationRouteToType).map((utilizationRoute) => {
    const utilizationType = UtilizationRouteToType[utilizationRoute];
    if (!utilizationType) {
      return null;
    }
    const { label } = utilizationType;

    return (
      <MenuItem
        key={utilizationRoute}
        onClick={onClick(utilizationRoute)}
      >
        {label}
      </MenuItem>
    );
  });

  return (
    <Menu
      anchorNode={buttonRef.current}
      id="utilization-page-menu"
      open={isOpen}
      position="bottom"
      onClose={onClose}
    >
      {linksList}
    </Menu>
  );
};

UtilizationMenu.propTypes = {
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UtilizationMenu;

import React from 'react';
import classnames from 'classnames';
import { styledInputProps } from '../../../proptypes';

import styles from '../../GridViewCell/GridViewCell.module.scss';

const Uneditable = ({ inputValue }) => {
  return (
    <div className={classnames(styles.cell, styles.disabledInput)}>
      {inputValue || '\u00a0'}
    </div>
  );
};

Uneditable.propTypes = styledInputProps;

export default Uneditable;

import { connect } from 'react-redux';
import WebsiteViewSwitcher from './WebsiteViewSwitcher';
import { onSwitchWebsiteViewMode } from '../../actionCreators/sitewide';
import { isCurrentUserAdmin } from '../../selectors/user';
import { getWebsiteViewMode } from '../../selectors/sitewide';

const mapStateToProps = (state) => {
  return {
    viewMode: getWebsiteViewMode(state),
    isAdmin: isCurrentUserAdmin(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onViewModeSwitch(viewMode) {
    dispatch(onSwitchWebsiteViewMode(viewMode));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteViewSwitcher);

import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import VSSMSlotWarning from './VSSMSlotWarning';
import { isVSSMExclusiveSlot } from '../../../../../../helpers/form';
import { Form } from '../../../../../../constants';
import { getFormActiveCampaign } from '../../../../../../selectors/form';

export const mapStateToProps = (state) => {
  const { vertical } = getFormValues(Form.NAME)(state);
  const {
    pageType,
    desktopSlot,
    mobileSlot,
  } = getFormActiveCampaign(state);

  return {
    showWarning: isVSSMExclusiveSlot(vertical, pageType, desktopSlot, mobileSlot),
  };
};

export default connect(mapStateToProps)(VSSMSlotWarning);

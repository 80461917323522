import validateRequiredFields from './validateRequiredFields';
import checkMetadata from './checkMetadata';
import validateDateTimes from './validateDateTimes';
import { validateStartYear } from './validateStartYear';

export const validateBulkImportPackage = (state, pkg, partialFieldsValidationList = {}) => {
  const requiredFieldsValidations = validateRequiredFields(pkg, partialFieldsValidationList);
  const metadataValidations = checkMetadata(state, pkg, partialFieldsValidationList);
  const dateTimeValidations = validateDateTimes(pkg, partialFieldsValidationList);
  const startYearValidations = validateStartYear(state, pkg);
  return requiredFieldsValidations || metadataValidations || dateTimeValidations || startYearValidations;
};


import {
  GraphQLLabels,
  MetricLocations,
  MetricNames,
  MetricTypes,
} from '../../constants';
import {
  onRateCardSetAssetsList,
} from '../../actionCreators';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import { createAsset } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

import {
  translateAssetFromAppsyncToLocal,
  translateAssetFromLocalToAppsync,
} from '../../helpers/translateRateCardAssets';

export default (dispatch, getState, action, next) => {
  const {
    User: { currentUser: { username } },
    Meta: { metadata },
  } = getState();
  const {
    payload: {
      asset: {
        rateCardId,
        campaign,
      },
      isFromBulkImport,
    },
  } = action;

  const metricContext = {
    location: MetricLocations.RATE_CARD_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.ADD_RATE_CARD_ASSET,
    username,
  };

  const assetToSend = isFromBulkImport
    ? translateAssetFromLocalToAppsync(campaign)
    : campaign;

  const input = {
    asset: {
      rateCardId,
      campaign: JSON.stringify(assetToSend),
      version: 1,
    },
    username,
  };

  dispatch(onGraphQLAction({
    graphqlAction: createAsset,
    data: {
      input,
    },
    label: GraphQLLabels.CREATE_RATE_CARD_ASSET,
    onSuccessFunction({ data: { createAsset: response } }) {
      if (!response) {
        submitError('createAsset returned an empty result', metricContext);
        return;
      }

      const newAsset = {
        ...response,
        campaign: translateAssetFromAppsyncToLocal(JSON.parse(response.campaign), metadata),
      };
      dispatch(onRateCardSetAssetsList([newAsset]));
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};

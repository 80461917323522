import React from 'react';
import { campaignFormProps } from '../../proptypes';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';
import RateCardContainer from '../RateCardContainer';

const CloneRateCardPage = (props) => {
  const { match: { params: { id } } } = props;

  return <RateCardContainer isClone id={id} />;
};

CloneRateCardPage.propTypes = campaignFormProps;

export default WithHistoryTiming(CloneRateCardPage, {
  pageName: PageNames.CLONE_RATE_CARD,
});

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import CircleChart from '../../../CircleChart';

import styles from './UtilityChart.module.scss';

const UtilityChart = (props) => {
  const { chartItems } = props;

  const chartList = chartItems.map(({ label, percent, color }) => {
    return (
      <div
        className={styles.chartItem}
        key={`${label}/${percent}`}
      >
        <CircleChart
          percent={percent}
          color={color}
        />
        <span
          className={classnames({
            [styles.label]: true,
            [styles[color]]: true,
          })}
        >
          {label}
        </span>
      </div>
    );
  });

  return (
    <div className={styles.chartContainer}>
      {chartList}
    </div>
  );
};

UtilityChart.propTypes = {
  chartItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      percent: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number]).isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default UtilityChart;

import React from 'react';
import classnames from 'classnames';
import Heading from '@amzn/meridian/heading';
import PropTypes from 'prop-types';
import styles from './DashboardCard.module.scss';

const DashboardCard = (props) => {
  const {
    children,
    heading,
    isLoading,
    isDisabled,
  } = props;

  const headingSection = heading
    ? (
      <Heading
        level={3}
        className={styles.heading}
      >
        {heading}
      </Heading>
    )
    : null;

  const classes = classnames({
    [styles.card]: true,
    [styles.disabled]: isDisabled,
  });

  return (
    <div className={classes}>
      {headingSection}
      {isLoading ? 'Please wait, loading...' : <section>{children}</section>}
    </div>
  );
};

DashboardCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element.isRequired]),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  heading: PropTypes.string,
};

DashboardCard.defaultProps = {
  heading: '',
  isDisabled: false,
  isLoading: false,
  children: null,
};

export default DashboardCard;

import Awareness from './Awareness';
import Consideration from './Consideration';
import Conversion from './Conversion';

const MainPlacements = [
  ...Awareness,
  ...Consideration,
  ...Conversion,
];

export default MainPlacements;

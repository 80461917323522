import momentTimezone from 'moment-timezone';
import formValueSelector from 'redux-form/lib/formValueSelector';
import moment from 'moment';
import {
  CampaignColumns,
  CampaignStatuses,
  Comparators,
  DateIntervals,
  FilterGroupTypes,
  Form,
  MomentJsDayNames,
  SovTypes,
  StatusesToExcludeFromUtilization,
  Urls,
  UTC_TIMEZONE_ID,
  UTILIZATION_DATE_FORMAT,
  DEFAULT_TIMEZONE_ID,
  Delimiters,
} from '../constants';
import { createFilter, createFilterString, createGroupFilter } from './filters';
import { parseDateAndTimeToISOString } from './dateTime';
import { isEvergreen } from './common';
import { getCampaignsForCurrentBusinessStartYears } from 'src/selectors/campaign';

export const shouldIncludeCampaignInUtilization = (
  campaign,
  pageID,
  slot,
  campaignSlotField,
  startDateTimeRange,
  endDateTimeRange,
) => {
  const dateTimeCheck = campaign.startDateTime <= endDateTimeRange
    && campaign.endDateTime >= startDateTimeRange;
  return (
    campaign.pageId === pageID
    && !StatusesToExcludeFromUtilization.includes(campaign.status)
    && campaign[campaignSlotField] === slot
    && (dateTimeCheck || isEvergreen(campaign))
  );
};

export const getUtilizationForSlot = (
  campaigns,
  pageID,
  slot,
  startDateTime,
  endDateTime,
  campaignSlotField,
  timezoneId = UTC_TIMEZONE_ID,
) => {
  const startDateTimeUTC = momentTimezone.tz(startDateTime, timezoneId).utc().format();
  const endDateTimeUTC = momentTimezone.tz(endDateTime, timezoneId).utc().format();

  return campaigns.reduce((utilizationDataAccumulator, campaign) => {
    let updatedTotalSOV = utilizationDataAccumulator.totalSOV;
    let updatedTargetedSOV = utilizationDataAccumulator.targetedSOV;
    let updatedNumberOfCampaigns = utilizationDataAccumulator.numberOfCampaigns;

    const shouldInclude = shouldIncludeCampaignInUtilization(
      campaign,
      pageID,
      slot,
      campaignSlotField,
      startDateTimeUTC,
      endDateTimeUTC,
    );
    const { sov, sovType } = campaign;
    if (shouldInclude) {
      const campaignSov = sov || 0;
      if (sovType === SovTypes.TARGETED_SOV) {
        updatedTargetedSOV += campaignSov;
      } else {
        updatedTotalSOV += campaignSov;
      }
      updatedNumberOfCampaigns++;
    }

    return {
      targetedSOV: updatedTargetedSOV,
      totalSOV: updatedTotalSOV,
      numberOfCampaigns: updatedNumberOfCampaigns,
    };
  }, {
    targetedSOV: 0,
    totalSOV: 0,
    numberOfCampaigns: 0,
  });
};

export const generateSlotUtilizationDataFresh = (
  slotOptions,
  slotField,
  daysInDateRange,
  campaigns,
  pageID,
  placements,
  daysStep,
  timezoneId,
) => {
  const slotUtilization = {};

  placements.forEach((placement) => {
    if (slotOptions && slotOptions[placement]) {
      const slots = slotOptions[placement];
      const slotToUtilizationMap = slots.reduce((slotToUtilizationAccumulator, slot) => {
        const dateToUtilizationMap = daysInDateRange.reduce(
          (dateToUtilizationAccumulator, date) => {
            const nextDayInterval = date.clone().add(daysStep, 'days');
            const slotUtilizationDetails = getUtilizationForSlot(
              campaigns,
              pageID,
              slot,
              date,
              nextDayInterval,
              slotField,
              timezoneId,
            );

            return {
              ...dateToUtilizationAccumulator,
              [date.format(UTILIZATION_DATE_FORMAT)]: slotUtilizationDetails,
            };
          }, {},
        );

        return {
          ...slotToUtilizationAccumulator,
          [slot]: {
            placement,
            utilization: dateToUtilizationMap,
          },
        };
      }, {});

      Object.entries(slotToUtilizationMap).forEach(([key, value]) => {
        slotUtilization[key] = value;
      });
    }
  });

  return slotUtilization;
};

export const generateSlotPlacementDataFresh = (
  slotOptions,
  slotField,
  campaigns,
  placements,
  selectedDateRange,
) => {
  const [rangeStartDateTime, rangeEndDateTime] = selectedDateRange;
  const slotUtilization = {};
  const timezoneId = DEFAULT_TIMEZONE_ID;

  placements.forEach((placement) => {
    if (!slotOptions || !slotOptions[placement]) {
      return;
    }

    const slotToUtilizationData = slotOptions[placement].reduce((totalSlots, slot) => {
      const filteredCampaigns = campaigns.filter((campaign) => {
        const {
          startDateTime,
          endDateTime,
        } = campaign;

        const campaignStartDateTimeUTC = momentTimezone.tz(
          startDateTime,
          timezoneId,
        ).utc().format();
        const campaignEndDateTimeUTC = momentTimezone.tz(
          endDateTime,
          timezoneId,
        ).utc().format();
        const rangeStartDateTimeUTC = momentTimezone.tz(
          rangeStartDateTime,
          timezoneId,
        ).utc().format();
        const rangeEndDateTimeUTC = momentTimezone.tz(
          rangeEndDateTime,
          timezoneId,
        ).utc().format();
        const dateTimeCheck = campaignStartDateTimeUTC <= rangeEndDateTimeUTC
          && campaignEndDateTimeUTC >= rangeStartDateTimeUTC;
        return (
          campaign[slotField] === slot
          && (dateTimeCheck || isEvergreen(campaign))
        );
      });

      const translatedCampaigns = filteredCampaigns.map((campaign) => {
        const {
          id,
          packageId,
          startDateTime,
          endDateTime,
          status,
          campaignName,
          sov,
        } = campaign;

        return {
          id,
          packageId,
          startTime: [startDateTime],
          endTime: [endDateTime],
          status,
          displayName: campaignName,
          guaranteePercentage: sov,
        };
      });

      return {
        ...totalSlots,
        [slot]: translatedCampaigns,
      };
    }, {});

    Object.entries(slotToUtilizationData).forEach(([key, value]) => {
      slotUtilization[key] = value;
    });
  });

  return slotUtilization;
};

// TODO - Implement changes to generate the additional data WFM wants (e.g. guaranteed SOV)
export const generateSlotUtilizationDataWFM = (
  slotOptions,
  slotField,
  daysInDateRange,
  campaigns,
  pageID,
) => {
  const slotKeys = Object.keys(slotOptions);
  if (!slotKeys || !slotKeys.length) {
    return {};
  }
  const [pageType] = slotKeys;
  if (!pageType) {
    return {};
  }
  const slots = slotOptions[pageType];

  return slots.reduce((slotToUtilizationAccumulator, slot) => {
    const dateToUtilizationMap = daysInDateRange.reduce(
      (dateToUtilizationAccumulator, date) => {
        const nextMonday = date.clone().add(7, 'days').utc().format();
        const slotUtilizationDetails = getUtilizationForSlot(
          campaigns,
          pageID,
          slot,
          date.utc().format(),
          nextMonday,
          slotField,
        );

        return {
          ...dateToUtilizationAccumulator,
          [date.format(UTILIZATION_DATE_FORMAT)]: slotUtilizationDetails,
        };
      }, {},
    );

    return {
      ...slotToUtilizationAccumulator,
      [slot]: {
        placement: '',
        utilization: dateToUtilizationMap,
      },
    };
  }, {});
};

export const getFiltersList = (slotType, slotName, startDate, endDate, options) => {
  if (!slotType
    || ![CampaignColumns.DESKTOP_SLOT.name, CampaignColumns.MOBILE_SLOT.name].includes(slotType)) {
    return [];
  }

  const filters = [
    createFilter(
      slotType,
      Comparators.EQUALS,
      encodeURIComponent(slotName),
    ),
    createFilter(
      CampaignColumns.STATUS.name,
      Comparators.DOES_NOT_EQUAL,
      CampaignStatuses.HOLD,
    ),
    createFilter(
      CampaignColumns.STATUS.name,
      Comparators.DOES_NOT_EQUAL,
      CampaignStatuses.CANCELED,
    ),
    createGroupFilter(FilterGroupTypes.OR, [
      createFilter(
        CampaignColumns.START_DATE.name,
        Comparators.LESS_THAN,
        endDate,
      ),
      createFilter(
        CampaignColumns.START_DATE.name,
        Comparators.EQUALS,
        endDate,
      ),
    ]),
    createGroupFilter(FilterGroupTypes.OR, [
      createFilter(
        CampaignColumns.END_DATE.name,
        Comparators.GREATER_THAN,
        startDate,
      ),
      createFilter(
        CampaignColumns.END_DATE.name,
        Comparators.EQUALS,
        startDate,
      ),
    ]),
  ];

  if (options.pageName) {
    filters.push(createFilter(
      CampaignColumns.PAGE_NAME.name,
      Comparators.EQUALS,
      options.pageName,
    ));
  }
  if (options.pageType) {
    filters.push(createFilter(
      CampaignColumns.PAGETYPE.name,
      Comparators.EQUALS,
      options.pageType,
    ));
  }
  if (options.pageID) {
    filters.push(createFilter(
      CampaignColumns.PAGE_ID.name,
      Comparators.EQUALS,
      options.pageID,
    ));
  }
  if (options.channel) {
    filters.push(createFilter(
      CampaignColumns.CHANNEL.name,
      Comparators.EQUALS,
      options.channel,
    ));
  }
  if (options.status) {
    filters.push(createFilter(
      CampaignColumns.STATUS.name,
      Comparators.EQUALS,
      options.status,
    ));
  }
  if (options.category) {
    filters.push(createFilter(
      CampaignColumns.CATEGORY.name,
      Comparators.EQUALS,
      options.category,
    ));
  }
  if (options.marketingManager) {
    filters.push(createFilter(
      CampaignColumns.MARKETING_MANAGER.name,
      Comparators.EQUALS,
      options.marketingManager,
    ));
  }

  return filters;
};

export const getFiltersUrl = (slotType, slotName, startDate, endDate, options = {}) => {
  try {
    const encodedOptions = {};
    const customOptions = Object.entries(options);

    if (customOptions.length) {
      customOptions.forEach(([key, value]) => {
        encodedOptions[key] = encodeURIComponent(value);
      });
    }

    const filters = getFiltersList(
      slotType,
      slotName,
      startDate,
      endDate,
      encodedOptions,
    );
    return `${Urls.SHOW_ALL_CAMPAIGNS}?${createFilterString({ filters })}`;
  } catch (e) {
    /* eslint-disable no-console */
    console.error(e);
    return null;
  }
};

export const getSlotStructure = ({ slotName, isVisible = true }) => ({
  slotName,
  isVisible,
});

/**
 * Converts an array of slots to an internal data structure.
 *
 * @param {Array} slots
 * @returns {Array}
 */
export const getTranslatedSlots = (slots) => {
  if (!slots || !slots.length) {
    return [];
  }

  return slots.map((slotName) => getSlotStructure({ slotName }));
};

export const getSlotsOrder = (slotsOrder, business, pageType, slotType) => {
  return slotsOrder[business]
  && slotsOrder[business][pageType]
  && slotsOrder[business][pageType][slotType]
    ? slotsOrder[business][pageType][slotType]
    : [];
};

export const getReorderedSlots = (slots, slotsOrder) => {
  if (!slots || !slotsOrder || !Object.keys(slotsOrder).length) {
    return [];
  }

  const orderedSlots = [];
  slotsOrder.forEach(({ slotName, isVisible }) => {
    if (!slots[slotName]) {
      return;
    }

    orderedSlots.push({
      ...slots[slotName],
      slotName,
      isVisible,
    });
  });

  return orderedSlots;
};

export const mergeSlots = (defaultSlotsList, savedSlotsList) => {
  const mergedSlotsList = [...savedSlotsList];

  defaultSlotsList.forEach((defaultSlot) => {
    if (!savedSlotsList.find((s) => s.slotName === defaultSlot.slotName)) {
      mergedSlotsList.push(defaultSlot);
    }
  });

  return mergedSlotsList;
};

export const getUtilizationDataForCampaignForm = (state, activeTabValues) => {
  const {
    Meta: { metadata },
  } = state;

  const campaigns = getCampaignsForCurrentBusinessStartYears(state);
  const { pageId, startDate, endDate, timezoneId, desktopSlot, mobileSlot, startTime, endTime} = activeTabValues
  
  if (pageId && startDate && endDate && (desktopSlot || mobileSlot)) {
    let timezone = UTC_TIMEZONE_ID;
    if (timezoneId) {
      timezone = metadata.fields.timezoneId.options[timezoneId];
    }

    const startDateISO = parseDateAndTimeToISOString(startDate,
      startTime,
      timezone);
    const endDateISO = parseDateAndTimeToISOString(endDate,
      endTime,
      timezone);

    let desktopUtilizationData = null;
    if (desktopSlot) {
      desktopUtilizationData = getUtilizationForSlot(campaigns,
        pageId,
        desktopSlot,
        startDateISO,
        endDateISO,
        CampaignColumns.DESKTOP_SLOT.name);

      desktopUtilizationData.slotName = desktopSlot;
    }

    let mobileUtilizationData = null;
    if (mobileSlot) {
      mobileUtilizationData = getUtilizationForSlot(campaigns,
        pageId,
        mobileSlot,
        startDateISO,
        endDateISO,
        CampaignColumns.MOBILE_SLOT.name);

      mobileUtilizationData.slotName = mobileSlot;
    }

    return {
      startDate: startDateISO,
      endDate: endDateISO,
      timezone,
      desktopUtilizationData,
      mobileUtilizationData,
    };
  }

  return {};
};

export const translateInStoreCampaignDataToTimeline = (inStoreCampaigns) => {
  return inStoreCampaigns.map((campaign) => {
    const { startDateTime, endDateTime } = campaign;
    const start = moment(startDateTime).format('YYYY-MM-DD 00:00');
    const end = moment(endDateTime).format('YYYY-MM-DD 00:00');

    return {
      start,
      end,
      campaign,
    };
  });
};
export const createDateRange = (options = {}) => {
  const {
    startFromDate = moment(),
    isNA = true,
    amountOfDays = DateIntervals.ONE_WEEK * 2,
  } = options;

  const startFromDay = isNA ? MomentJsDayNames.WEDNESDAY : MomentJsDayNames.MONDAY;
  const firstDay = moment(startFromDate).isoWeekday(startFromDay);
  const startDate = firstDay.format('Y-MM-DD');
  const endDate = firstDay.clone().add(amountOfDays, 'days').format('Y-MM-DD');

  return [startDate, endDate];
};

const getSlotOrderIndex = (slotLocation, slotNumber, slotsOrder) => {
  const indexBySlotNameMask = slotsOrder.indexOf(slotLocation);
  if (indexBySlotNameMask >= 0) {
    return indexBySlotNameMask;
  }

  const indexByFullSlotNameMatch = slotsOrder.indexOf(`${slotLocation}-${slotNumber}`);
  if (indexByFullSlotNameMatch >= 0) {
    return indexByFullSlotNameMatch;
  }

  return 999;
};

export const sortBySlotPositionAndNumber = (slotsOrder) => (a, b) => {
  const [aSlotName] = a;
  const [bSlotName] = b;

  const aSlotNameParts = aSlotName.split(Delimiters.DASH);
  const bSlotNameParts = bSlotName.split(Delimiters.DASH);

  const [aSlotLocation, aSlotNumber] = aSlotNameParts.slice(-2);
  const [bSlotLocation, bSlotNumber] = bSlotNameParts.slice(-2);

  const aSlotLocationIndex = getSlotOrderIndex(aSlotLocation, aSlotNumber, slotsOrder);
  const bSlotLocationIndex = getSlotOrderIndex(bSlotLocation, bSlotNumber, slotsOrder);

  return aSlotLocationIndex - bSlotLocationIndex || aSlotNumber - bSlotNumber;
};

import React from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import { BusinessTypes } from '../../constants';

const BusinessSelector = ({ isAdmin, selectedBusiness, onChange }) => {
  const options = Object.values(BusinessTypes).map((business) => {
    if (business.adminRestricted && !isAdmin) {
      return null;
    }
    return (
      <SelectOption
        key={business.name}
        value={business.name}
        label={business.label}
      />
    );
  });
  return (
    <Select
      value={selectedBusiness}
      onChange={onChange}
      placeholder="Select a business"
      width={150}
      size="small"
    >
      {options}
    </Select>
  );
};

BusinessSelector.propTypes = {
  selectedBusiness: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BusinessSelector;

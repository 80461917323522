import { connect } from 'react-redux';

import {
  onBulkImportProcessFile,
  onBulkImportSetValidationMode,
} from '../../actionCreators';

import BulkImporter from './BulkImporter';

export const mapStateToProps = (state) => {
  const {
    BulkImport: {
      isProcessed,
      validationMode,
    },
  } = state;

  return {
    isProcessed,
    validationMode,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onProcessFile(data, fileType) {
      dispatch(onBulkImportProcessFile({ data, fileType }));
    },
    onValidationModeChange(validationMode) {
      dispatch(onBulkImportSetValidationMode(validationMode));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkImporter);

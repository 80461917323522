import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import styles from '../../PackageForm.module.scss';

const SubmitButtons = ({ isButtonDisabled }) => {
  return (
    <div className={styles.buttonContainer}>
      <div className={styles.submitButtonContainer}>
        <div className={classnames(styles.wrapperInner, styles.submitButtonWrapper)}>
          <Button
            submit
            type="primary"
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

SubmitButtons.propTypes = {
  isButtonDisabled: PropTypes.bool,
};

SubmitButtons.defaultProps = {
  isButtonDisabled: false,
};

export default SubmitButtons;

import { connect } from 'react-redux';
import ContentAsins from './ContentAsins';
import { getCurrentPackageActiveTab } from '../../../../../helpers/form';
import {
  getSelectedBusiness,
  isWebsiteOmnichannelViewMode,
} from '../../../../../selectors/sitewide';
import { isUSBusiness } from '../../../../../helpers/common';

export const mapStateToProps = (state) => {
  const isOmnichannelMode = isWebsiteOmnichannelViewMode(state);
  const isUsBusiness = isUSBusiness(getSelectedBusiness(state));

  return {
    currentTab: getCurrentPackageActiveTab(state),
    shouldShowSpanishCopy: !isOmnichannelMode && isUsBusiness,
    shouldShowTranslationStatus: isUsBusiness,
  };
};

export default connect(mapStateToProps)(ContentAsins);

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SingleSelect from '../../../StyledInputs/SingleSelect';

import styles from './UtilityPanel.module.scss';
import Input from '../../../StyledInputs/Input';
import { getDependencyAwareOptions } from '../../../../helpers/getMetadataOptions';

const UtilityPanel = (props) => {
  const {
    selectedFilters,
    filterOptions,
    onFilterChange,
  } = props;

  const commonControlsBlock = [...filterOptions].map(([filterOptionKey, filterOptionValue]) => {
    const className = styles.control;
    if (filterOptionKey === 'pageID') {
      return (
        <div
          className={classnames(styles.control, styles.controlPageID)}
          key={filterOptionKey}
        >
          <Input
            inputValue={selectedFilters ? selectedFilters[filterOptionKey] : ''}
            onInputChange={(val) => { onFilterChange(filterOptionKey, filterOptionValue, val); }}
            placeholder="Page ID"
            size="xlarge"
          />
        </div>
      );
    }

    return (
      <div
        className={className}
        key={filterOptionKey}
      >
        <SingleSelect
          options={getDependencyAwareOptions(filterOptionValue, selectedFilters)}
          label={filterOptionValue.display}
          placeholder={filterOptionValue.display}
          inputValue={selectedFilters ? selectedFilters[filterOptionKey] : ''}
          onInputChange={(val) => { onFilterChange(filterOptionKey, filterOptionValue, val); }}
          size="xlarge"
        />
      </div>
    );
  });

  return (
    <div className={styles.controlsContainer}>
      {commonControlsBlock}
    </div>
  );
};

UtilityPanel.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string).isRequired,
  filterOptions: PropTypes.instanceOf(Map).isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default UtilityPanel;

import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@amzn/meridian/alert';
import InStoreUtilizationForm from './InStoreUtilizationForm';
import InStoreUtilizationCalendar from './InStoreUtilizationCalendar';
import styles from './InStoreUtilization.module.scss';
import { Language } from '../../constants';

const InStoreUtilization = ({ showWarning }) => {
  const getWarning = () => {
    if (!showWarning) {
      return null;
    }

    return (
      <div className={styles.warningMessage}>
        <Alert type="warning">
          {Language.IN_STORE_UTILIZATION_LOADING_WARNING}
        </Alert>
      </div>
    );
  };
  const warningMessage = getWarning();

  return (
    <article className={styles.wrapper}>
      <section className={styles.filtersPanel}>
        <InStoreUtilizationForm />
      </section>
      {warningMessage}
      <section className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          <InStoreUtilizationCalendar />
        </div>
      </section>
    </article>
  );
};

InStoreUtilization.propTypes = {
  showWarning: PropTypes.bool.isRequired,
};

export default InStoreUtilization;

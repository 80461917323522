import { setSlotsOrderInStorage } from './helpers';

export default (dispatch, getState, action, next) => {
  const {
    UtilizationPage: {
      selectedFilters: {
        pageType,
      },
    },
    Sitewide: {
      selectedBusiness,
    },
  } = getState();

  const newSlotsOrder = {
    ...action.data,
    business: selectedBusiness,
    pageType,
  };

  const newAction = {
    ...action,
    data: newSlotsOrder,
  };

  setSlotsOrderInStorage(newSlotsOrder);

  return next(newAction);
};

import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from '../history';

import reducers from '../reducers';
import ExportMiddleware from '../middleware/exportMiddleware';
import KeyboardListenerMiddleware from '../middleware/GridViewKeyboardListener';
import GridViewRequestMiddleware from '../middleware/GridViewRequestMiddleware';
import GridViewFilterMiddleware from '../middleware/GridViewFilterMiddleware';
import restMiddleware from '../middleware/restMiddleware';
import generateId from '../middleware/generate-id';
import appMiddleware from '../middleware/appMiddleware';
import businessSelector from '../middleware/businessSelector';
import yearSelector from '../middleware/startYearsSelector';
import graphqlMiddleware from '../middleware/graphqlMiddleware';
import metadataMiddleware from '../middleware/metadataMiddleware';
import formMiddleware from '../middleware/form';
import notifications from '../middleware/notifications';
import UtilizationPage from '../middleware/UtilizationPage';
import InStoreUtilizationPage from '../middleware/InStoreUtilizationPage';
import userMiddleware from '../middleware/userMiddleware';
import CreateSymphonyCreativeMiddleware from '../middleware/CreateSymphonyCampaignMiddleware';
import dashboard from '../middleware/dashboard';
import MetadataEditorMiddleware from '../middleware/MetadataEditorMiddleware';
import bulkImportMiddleware from '../middleware/bulkImportMiddleware';
import BulletinBoard from '../middleware/BulletinBoard';
import AuditLogMiddleware from '../middleware/auditLogMiddleware';
import CustomViewMiddleware from '../middleware/CustomViewMiddleware';
import ThirdPartyIFrameMiddleware from '../middleware/ThirdPartyIFrameMiddleware';
import CustomerNotifications from '../middleware/CustomerNotifications';
import Comments from '../middleware/Comments';
import timerMiddleware from '../middleware/timerMiddleware';
import PerformanceTimer from '../middleware/performanceTimer';
import MetadataAuditLogMiddleware from '../middleware/metadataAuditLogMiddleware';
import usageTracker from '../middleware/usageTracker';
import flashFill from '../middleware/FlashFill';
import RateCard from '../middleware/RateCard';
import migrateSettingsMiddleware from '../middleware/migrateSettingsMiddleware';

// TODO: Turn these middleware back on once UserSetting is fixed
const middleware = [
  businessSelector,
  yearSelector,
  appMiddleware,
  generateId,
  restMiddleware,
  ExportMiddleware,
  KeyboardListenerMiddleware,
  GridViewRequestMiddleware,
  GridViewFilterMiddleware,
  graphqlMiddleware,
  metadataMiddleware,
  formMiddleware,
  notifications,
  UtilizationPage,
  InStoreUtilizationPage,
  userMiddleware,
  CreateSymphonyCreativeMiddleware,
  dashboard,
  MetadataEditorMiddleware,
  bulkImportMiddleware,
  BulletinBoard,
  AuditLogMiddleware,
  CustomViewMiddleware,
  ThirdPartyIFrameMiddleware,
  CustomerNotifications,
  Comments,
  MetadataAuditLogMiddleware,
  timerMiddleware,
  PerformanceTimer,
  flashFill,
  RateCard,
  migrateSettingsMiddleware,
  routerMiddleware(history),
  usageTracker,
];

if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middleware.push(createLogger());
}

const store = createStore(
  reducers,
  applyMiddleware(...middleware),
);

export default store;

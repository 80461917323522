import {
  ExportOptions,
  ExportOptionsTypes,
  GridViewActions,
} from '../../constants';

export const initialState = {
  exportPage: {
    pageSelection: ExportOptions[ExportOptionsTypes.PAGE_SELECTION][0].value,
    exportType: ExportOptions[ExportOptionsTypes.EXPORT_TYPE][0].value,
    exportFilters: ExportOptions[ExportOptionsTypes.EXPORT_FILTERS][0].value,
  },
};

export default (state = initialState, action) => {
  if (!action || !action.type || !action.payload) {
    return state;
  }
  const { type, payload } = action;
  if (type === GridViewActions.SET_EXPORT_SETTINGS) {
    return {
      ...state,
      exportPage: payload,
    };
  }
  return state;
};

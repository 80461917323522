// TODO: These admins' lists will be replaced with functions (which represent LDAP groups) below
// once we establish LDAP groups and start using them

export const getAppAdminList = () => {
  return [
    'phonkhoi',
    'hanwooll',
    'vkryukov',
    'malpulki',
    'rushisp',
    // Fresh Central Marketing
    'nomilian',
    'alroepke',
    'hellmert',
    'dhvanis',
  ];
}

export const getMetadataAdminList = () => [
  'clrsr',
  'tratrun',
  'tuazonm',
  'landeo',
  'herrpul',
  'mbarg',
  'maxibrow',
  'landeo',
  'petschek',
  'prerao',
  'alisberg',
  'rjshim',
  'seanchok',
  'neismye',
  'mcohena',
  // WFMC metadata admins
  'fuyuzhuo',
  'pmoosrox',
  'colmjn',
  // Traffic admins
  'yusmeera',
  'megyoshi',
  // Fresh DE
  'lotteri',
  ...getAppAdminList(),
];

export const getMelodyTestingAdminList = () => {
  return [
    'rushisp',
    'malpulki',
    'vkryukov',
    'hanwooll',
    'phonkhoi',
    'nomilian',
    'alroepke',
    // Melody team
    'eugenbu',
    'ralumoca',
    'vararucv',
    'epistol',
    'chrisc'
  ];
}

export const getLDAPAdminAccessGroups = () => [];
export const getLDAPMetadataAdminAccessGroups = () => [];
export const getLDAPReadOnlyAccessGroups = () => [];
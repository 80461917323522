import { CampaignColumns, PageTypes, Urls } from './index';

export const UtilizationType = Object.freeze({
  ONLINE: {
    name: 'Online',
    label: 'Online',
  },
  IN_STORE: {
    name: 'In-Store',
    label: 'In-Store',
  },
});
export const UtilizationRouteToType = Object.freeze({
  [Urls.UTILIZATION]: UtilizationType.ONLINE,
  [Urls.IN_STORE_UTILIZATION]: UtilizationType.IN_STORE,
});
export const UtilizationViewMode = Object.freeze({
  TABLE: {
    name: 'table',
    label: 'Table',
  },
  CALENDAR: {
    name: 'calendar',
    label: 'Calendar',
  },
});
export const UtilizationSlotsViewMode = Object.freeze({
  DESKTOP: {
    name: CampaignColumns.DESKTOP_SLOT.name,
    label: `${CampaignColumns.DESKTOP_SLOT.display}s`,
  },
  MOBILE: {
    name: CampaignColumns.MOBILE_SLOT.name,
    label: `${CampaignColumns.MOBILE_SLOT.display}s`,
  },
});
export const UtilizationSlotType = Object.freeze({
  FMC_SLOTS: 'FMC Slots',
});
export const UtilizationOnlineIgnoredPageTypes = [
  PageTypes.IN_STORE,
  PageTypes.ILM,
  PageTypes.SEARCH,
  PageTypes.RECIPES,
  PageTypes.TEST_VALUE,
];
/**
 * This is a list of ALM only pages by pageType
 * (they should not have any FMC slots displayed on them)
 */
export const UtilizationAlmOnlyPages = Object.freeze({
  [PageTypes.CATEGORY]: [
    'Alcohol',
    'Baby Diaper and Wipes',
    'Baby PC',
    'Baby',
    'Baking Mixes',
    'Beauty & Personal Care',
    'Beauty',
    'Beef',
    'Beer',
    'Beverages',
    'Breads & Bakery',
    'Breakfast & Cereal Bars',
    'Breakfast Foods',
    'Butter & Margarine',
    'Candy & Chocolate',
    'Canned & Packaged',
    'Cereal',
    'Cheese',
    'Chicken',
    'Chips',
    'Cleaning',
    'Coffee & Tea',
    'Condiments & Dressings',
    'Cooking & Baking',
    'Cough, Cold, and Flu',
    'Crackers',
    'Dairy & Eggs',
    'Deli & Prepared',
    'Deli Meats & Cheeses',
    'Deodorants and Antiperspirants',
    'Digestion and Nausea',
    'Dishwashing',
    'Eggs',
    'Feminine Care',
    'Frozen Appetizers and Snacks',
    'Frozen Breakfast Foods',
    'Frozen Meals and Entrees',
    'Frozen Vegetables',
    'Frozen',
    'Fruits',
    'Hair Care',
    'Health & Wellness',
    'Herbs and Spices',
    'Home Storage and Organization',
    'Home and Kitchen',
    'Household',
    'Juice',
    'Laundry',
    'Meat & Seafood',
    'Meat Substitutes',
    'Milk & Cream',
    'Non-Dairy Beverages',
    'Office Supplies',
    'Oral Care',
    'Packaged Meals',
    'Packaged Produce',
    'Pain Relief',
    'Pantry',
    'Paper & Plastics',
    'Pasta & Noodles',
    'Personal Care',
    'Pork',
    'Powdered Drink Mixes',
    'Seafood',
    'Skin Care',
    'Snacks',
    'Soup, Stocks, and Broths',
    'Spirts',
    'Sports Nutrition',
    'Toys & Games',
    'Vegetables',
    'Vitamins',
    'Water',
    'Wine',
    'Yogurt',
    'Produce',
  ],
});
/**
 * Custom slots order as per
 * https://www.amazon.com/alm/storefront?almBrandId=QW1hem9uIEZyZXNo page.
 * (you will need Ama bot script to see the actual placements highlighted)
 */
export const UtilizationSlotsOrderByPageType = Object.freeze({
  [PageTypes.CATEGORY]: [
    'top',
    'middleone',
    'middletwo',
    'bottom',
  ],
  [PageTypes.DEALS]: [
    'reserved-2',
    'top',
    'middle',
    'bottom',
    'bottom-acnhor',
  ],
  [PageTypes.STOREFRONT]: [
    'tcg',
    'top',
    'adleftplacement-1',
    'middleone',
    'adleftplacement-2',
    'middletwo',
    'bottom',
  ],
});

export const FixedUtilizationFilters = Object.freeze([
  CampaignColumns.LANGUAGE.name,
  CampaignColumns.CAMPAIGN_TYPE.name,
]);
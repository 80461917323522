import { createSelector } from 'reselect';

export const getCommentsList = (state) => {
  const { Comments: { list } } = state;

  return list && list.length ? list : [];
};

export const getCommentsSelector = createSelector(
  [getCommentsList],
  (commentsList) => {
    return commentsList;
  },
);

import { connect } from 'react-redux';
import EditRateCardPage from './rate-card-edit';
import { getRateCardById } from '../../../helpers/rateCard';

export const mapStateToProps = (state, props) => {
  const {
    RateCard: { cards },
  } = state;
  const { match: { params: { id } } } = props;

  const isValidRateCard = getRateCardById(cards, id);

  return {
    shouldRender: cards.length > 0 && isValidRateCard,
    rateCardId: id,
  };
};

export default connect(mapStateToProps)(EditRateCardPage);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@amzn/meridian//modal';
import Button from '@amzn/meridian/button';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Icon from '@amzn/meridian/icon';
import cogTokens from '@amzn/meridian-tokens/base/icon/print';
import classnames from 'classnames';
import styles from './RateCardReportModal.module.scss';
import { PlacementType } from '../../constants';
import { getPlacementsCount } from '../../helpers/rateCard';

const SUMMARY_BLOCK_HEADER_COLUMN_WIDTH = 250;

const RateCardReportModal = (props) => {
  const {
    isOpen,
    onModalClose,
    onPrint,
    limitedPlacementsList,
    mainPlacementsList,
    totalInvestment,
    vertical,
    dollarsAvailable,
    dollarsUsed,
    isInternalReportType,
    vendorName,
  } = props;

  useEffect(() => {
    return () => {
      onModalClose();
    };
  }, [
    onModalClose,
  ]);

  const createSummaryRow = (name, value) => {
    return (
      <TableRow
        key={name}
        highlightOnHover={false}
      >
        <TableCell
          highlight
          width={SUMMARY_BLOCK_HEADER_COLUMN_WIDTH}
        >
          {name}
        </TableCell>
        <TableCell>
          {value}
        </TableCell>
      </TableRow>
    );
  };

  const getHeader = () => {
    return (
      <div className={styles.headerContainer}>
        <h1 className={styles.title}>Vendor Rate Card (Fresh)</h1>
        <h2 className={styles.vendorName}>{vendorName}</h2>
      </div>
    );
  };

  const getGeneralSummary = () => {
    if (!isInternalReportType) {
      return null;
    }

    return (
      <div className={styles.generalSummary}>
        <div className={styles.generalSummaryContainer}>
          <p>
            <strong>Total investment:</strong>
            {' '}
            {totalInvestment}
          </p>
          <p>
            <strong>Vertical:</strong>
            {' '}
            {vertical}
          </p>
        </div>
        <div className={styles.generalSummaryContainer}>
          <p>
            <strong>UFF:</strong>
            {' '}
            Yes
          </p>
          <p>
            <strong>AFS:</strong>
            {' '}
            Yes
          </p>
        </div>
      </div>
    );
  };

  const getPlacements = (placementsList) => {
    const items = placementsList.map((placement) => {
      const {
        className, placementName, plannedPlacements
      } = placement;
      let count = 0;
      if (plannedPlacements) {
        count = plannedPlacements;
      }

      return (
        <TableRow key={`${placementName}/${plannedPlacements}}`}>
          <TableCell>{className}</TableCell>
          <TableCell>{placementName}</TableCell>
          <TableCell>{count}</TableCell>
        </TableRow>
      );
    });

    return (
      <Table
        className={styles.rateCardReport}
        headerRows={1}
        showStripes
        showDividers
        spacing="small"
      >
        <TableRow>
          <TableCell>Class Name</TableCell>
          <TableCell>Placement Name</TableCell>
          <TableCell>Count</TableCell>
        </TableRow>
        {items}
      </Table>
    );
  };

  const getDollarsUsageSummary = () => {
    if (!isInternalReportType) {
      return null;
    }

    return (
      <div className={styles.dollarsSummaryContainer}>
        <p>
          <strong>Dollars available:</strong>
          {' '}
          {dollarsAvailable}
        </p>
        <p>
          <strong>Dollars used:</strong>
          {' '}
          {dollarsUsed}
        </p>
      </div>
    );
  };

  const getPlacementsSummary = () => {
    const mainPlacementsCount = getPlacementsCount(mainPlacementsList);
    const limitedPlacementsCount = getPlacementsCount(limitedPlacementsList);
    const totalPlacementsCount = mainPlacementsCount + limitedPlacementsCount;

    let internalTotals = null;

    if (isInternalReportType) {
      const allPlacementsTogether = [
        ...mainPlacementsList,
        ...limitedPlacementsList,
      ];
      const onlinePlacementsCount = getPlacementsCount(
        allPlacementsTogether,
        [PlacementType.ONLINE, PlacementType.OMNICHANNEL],
      );
      const inStorePlacementsCount = getPlacementsCount(
        allPlacementsTogether,
        [PlacementType.IN_STORE, PlacementType.OMNICHANNEL],
      );

      const internalTotalsItemsList = [
        {
          name: 'Main Placements',
          count: mainPlacementsCount,
        },
        {
          name: 'Limited placements',
          count: limitedPlacementsCount,
        },
        {
          name: 'Online placements',
          count: onlinePlacementsCount,
        },
        {
          name: 'In-Store placements',
          count: inStorePlacementsCount,
        },
      ];

      internalTotals = internalTotalsItemsList.map(({ name, count }) => {
        return createSummaryRow(name, count);
      });
    }

    return (
      <>
        <h3>Placement summary</h3>
        <Table
          showDividers
          spacing="small"
        >
          {createSummaryRow('Total Placements', totalPlacementsCount)}
          {internalTotals}
        </Table>
      </>
    );
  };

  const getInternalUseWarning = () => {
    if (!isInternalReportType) {
      return null;
    }

    return (
      <div className={styles.warningContainer}>
        <p className={styles.warningMessage}>
          INTERNAL USE ONLY. DO NOT SHARE WITH VENDOR
        </p>
      </div>
    );
  };

  const header = getHeader();
  const generalSummary = getGeneralSummary();
  const limitedPlacements = getPlacements(limitedPlacementsList);
  const mainPlacements = getPlacements(mainPlacementsList);
  const dollarsUsageSummary = getDollarsUsageSummary();
  const placementsSummary = getPlacementsSummary();
  const internalUseWarning = getInternalUseWarning();

  return (
    <Modal
      open={isOpen}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-rate-card-report"
      width={700}
    >
      <Button type="icon" onClick={onPrint}>
        <Icon tokens={cogTokens}>Print</Icon>
      </Button>
      <div className={styles.closeButtonContainer}>
        <Button
          data-testid="btn-rate-card-report-modal-close"
          onClick={onModalClose}
        >
          Close
        </Button>
      </div>

      {header}
      {generalSummary}
      <div className={styles.formGroupWrapper}>
        <div className={classnames(styles.formGroupContent, styles.formGroupContentTable)}>
          <div>
            <h3>Main Placements</h3>
            {mainPlacements}
          </div>
          <div>
            <h3>Limited Placements</h3>
            {limitedPlacements}
          </div>
        </div>
      </div>
      {dollarsUsageSummary}
      <div className={styles.formGroupWrapper}>
        <div className={classnames(styles.formGroupContent, styles.formGroupContentTable)}>
          {placementsSummary}
        </div>
      </div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          {internalUseWarning}
        </div>
      </div>
    </Modal>
  );
};

RateCardReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  limitedPlacementsList: PropTypes.arrayOf(PropTypes.shape({
    placementName: PropTypes.string.isRequired,
  })).isRequired,
  mainPlacementsList: PropTypes.arrayOf(PropTypes.shape({
    placementName: PropTypes.string.isRequired,
  })).isRequired,
  totalInvestment: PropTypes.string.isRequired,
  dollarsAvailable: PropTypes.string.isRequired,
  dollarsUsed: PropTypes.string.isRequired,
  isInternalReportType: PropTypes.bool.isRequired,
  vertical: PropTypes.string,
  vendorName: PropTypes.string,
};

RateCardReportModal.defaultProps = {
  vertical: '',
  vendorName: '',
};

export default RateCardReportModal;

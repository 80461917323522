import React from 'react';
import PropTypes from 'prop-types';
import GridViewHeader from '../GridViewHeader';
import { campaignColumnProps } from '../../proptypes';
import styles from './GridView.module.scss';
import GridViewPackageRow from '../GridViewPackageRow';

// TODO: Since this view has been rebuilt, we will need to update AuditLog view.
// There is a separate SIM to do so.
const GridView = ({ packages, columnOrder, showActionBar, isOmnichannelMode, displayMode }) => {
  const rows = packages.map((currentPackage, index) => {
    return (
      <GridViewPackageRow
        key={currentPackage.id}
        index={index}
        currentPackage={currentPackage}
        columnOrder={columnOrder}
        showActionBar={showActionBar}
        isOmnichannelMode={isOmnichannelMode}
        displayMode={displayMode}
      />
    );
  });

  return (
    <table role="grid" className={styles.gridViewTable}>
      <tbody>
        <GridViewHeader columns={columnOrder} showActionBar={showActionBar} />
        {rows}
      </tbody>
    </table>
  );
};

GridView.propTypes = {
  packages: PropTypes.array,
  columnOrder: campaignColumnProps.isRequired,
  showActionBar: PropTypes.bool,
};

GridView.defaultProps = {
  packages: [],
  showActionBar: true,
};

export default GridView;

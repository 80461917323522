import {
  CampaignColumns,
  Comparators,
  FilterGroupTypes,
  Urls,
} from '../constants';

import { getColumnDisplayNameByName } from './common';
import { createFilter, createGroupFilter, createFilterString } from './filters';

const cleanFilters = (filters) => {
  // These columns require custom handling and are already preset that's why
  // they are removed from filters here.
  const filtersToRemove = [
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
    'username', // It doesn't have a real column name
  ];

  filtersToRemove.forEach((filter) => {
    if (filters[filter]) {
      // eslint-disable-next-line no-param-reassign
      delete filters[filter];
    }
  });

  return filters;
};

const processCustomFilters = (filters, customFilters) => {
  Object
    .keys(customFilters)
    .forEach((filterName) => {
      filters.push(createFilter(
        filterName,
        Comparators.EQUALS,
        customFilters[filterName],
      ));
    });

  return filters;
};

const getFilters = (filters, isOverlapMode = false, usernameFilterField) => {
  const { startDate, endDate, username } = filters;

  let filtersSet = [];
  if (isOverlapMode) {
    filtersSet = [
      createFilter(
        CampaignColumns.START_DATE.name,
        Comparators.LESS_THAN,
        endDate,
      ),
      createFilter(
        CampaignColumns.END_DATE.name,
        Comparators.GREATER_THAN,
        startDate,
      ),
    ];
  } else {
    filtersSet = [
      createGroupFilter(FilterGroupTypes.OR, [
        createFilter(
          CampaignColumns.START_DATE.name,
          Comparators.EQUALS,
          startDate,
        ),
        createFilter(
          CampaignColumns.START_DATE.name,
          Comparators.GREATER_THAN,
          startDate,
        ),
      ]),
      createGroupFilter(FilterGroupTypes.OR, [
        createFilter(
          CampaignColumns.END_DATE.name,
          Comparators.EQUALS,
          endDate,
        ),
        createFilter(
          CampaignColumns.END_DATE.name,
          Comparators.LESS_THAN,
          endDate,
        ),
      ]),
    ];
  }

  if (username) {
    if (usernameFilterField) {
      filtersSet.push(createFilter(
        usernameFilterField,
        Comparators.EQUALS,
        username,
      ));
    } else {
      filtersSet.push(createGroupFilter(FilterGroupTypes.OR, [
        createFilter(
          CampaignColumns.MARKETING_MANAGER.name,
          Comparators.EQUALS,
          username,
        ),
        createFilter(
          CampaignColumns.SCHEDULER.name,
          Comparators.EQUALS,
          username,
        ),
      ]));
    }
  }

  return processCustomFilters(filtersSet, cleanFilters(filters));
};

export const getCustomViewUrl = (options, isOverlapMode = false, usernameFilterField) => {
  const filters = getFilters(options, isOverlapMode, usernameFilterField);

  return `#${Urls.SHOW_ALL_CAMPAIGNS}?${createFilterString({ filters })}`;
};

export const getStatsByGroups = (statsBy, dateRange, customFiltersOptions, isOverlapMode) => {
  const [startDate, endDate] = dateRange;
  return Object
    .keys(statsBy)
    .map((groupName) => {
      const groupValues = statsBy[groupName];
      const newGroupValues = Object
        .keys(groupValues)
        .map((columnValue) => {
          const urlFiltersOptions = {
            startDate,
            endDate,
            [groupName]: columnValue,
            ...customFiltersOptions,
          };
          return {
            columnValue,
            count: groupValues[columnValue],
            url: getCustomViewUrl(urlFiltersOptions, isOverlapMode),
          };
        });

      return {
        groupName: getColumnDisplayNameByName(groupName),
        value: newGroupValues,
      };
    });
};

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../UtilizationSlots/UtilizationSlots.module.scss';
import { slotUtilizationItemProp } from '../../../proptypes';
import UtilizationRow from '../UtilizationRow';

const UtilizationTable = (props) => {
  const {
    tableName,
    daysInDateRange,
    selectedFilters,
    slotType,
    slotUtilization,
    selectedDateRange,
    isAbsStatsValuesOnly,
    isFreshBusiness,
  } = props;

  const getHeaderRows = () => {
    return daysInDateRange.map((date) => {
      return (
        <th className={styles.rowHeader} key={date}>
          <div className={styles.cell}>
            {date}
          </div>
        </th>
      );
    });
  };

  const getRows = () => {
    if (!slotUtilization) {
      return null;
    }
    const [, endDate] = selectedDateRange;

    return Object.entries(slotUtilization).map(([, utilizationData]) => {
      const {
        slotName,
        utilization,
        placement,
        isVisible,
      } = utilizationData;

      if (!isVisible) {
        return null;
      }

      return (
        <UtilizationRow
          key={`${slotName}/${placement}`}
          daysInDateRange={daysInDateRange}
          endDate={endDate}
          slotType={slotType}
          slotName={slotName}
          selectedFilters={selectedFilters}
          utilization={utilization}
          isAbsStatsValuesOnly={isAbsStatsValuesOnly}
          isFreshBusiness={isFreshBusiness}
          placement={placement}
        />
      );
    });
  };

  const headerRows = getHeaderRows();
  const rows = getRows();

  return (
    <table className={styles.table}>
      <thead className={styles.tableHeader}>
        <tr>
          <th className={`${styles.rowHeader} ${styles.columnHeader}`}>
            <div className={styles.cell}>
              {tableName}
            </div>
          </th>
          {headerRows}
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
};

UtilizationTable.propTypes = {
  tableName: PropTypes.string.isRequired,
  daysInDateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedFilters: PropTypes.objectOf(PropTypes.string).isRequired,
  slotType: PropTypes.string.isRequired,
  slotUtilization: PropTypes.arrayOf(slotUtilizationItemProp).isRequired,
  selectedDateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAbsStatsValuesOnly: PropTypes.bool.isRequired,
  isFreshBusiness: PropTypes.bool.isRequired,
};

export default UtilizationTable;

import Awareness from './Awareness';
import Consideration from './Consideration';
import Conversion from './Conversion';

const BonusMainPlacements = [
  ...Awareness,
  ...Consideration,
  ...Conversion,
];

export default BonusMainPlacements;

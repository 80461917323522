export const createFilterString = ({ filters, sorts }) => {
  const filterString = filters ? `filters=${encodeURIComponent(JSON.stringify(filters))}` : 'filters=';
  const sortString = sorts ? `sorts=${encodeURIComponent(JSON.stringify(sorts))}` : 'sorts=';
  return `${filterString}&${sortString}`;
};

export const processFilters = (filterString) => {
  if (filterString) {
    try {
      const filters = JSON.parse(filterString);
      return filters;
    } catch (err) {
      // TODO: (Todd) metrics reporting
      return [];
    }
  }
  return [];
};

export const processSorts = (sortString) => {
  if (sortString) {
    try {
      const sorts = JSON.parse(sortString);
      return sorts;
    } catch (err) {
      // TODO: (Todd) metrics reporting
      return [];
    }
  }
  return [];
};

export const createFilter = (column, comparator, value) => ({
  column,
  comparator,
  value,
});

export const createGroupFilter = (type, rules = []) => ({
  type,
  rules,
});

import { formValueSelector } from 'redux-form';
import { getCampaignFieldsByBusiness } from './form';
import { getString } from './common';

import {
  BusinessTypes,
  RegionTypes,
  LinkTypes,
  ExternalUrls,
  Channel,
  Form,
} from '../constants';

const selector = formValueSelector(Form.NAME);

export const getBusinessRegion = (business) => {
  switch (business) {
    case BusinessTypes.FRESH.name:
    case BusinessTypes.WFM.name:
    case BusinessTypes.WFMC_NA.name:
      return RegionTypes.NA;
    case BusinessTypes.FR_3P.name:
    case BusinessTypes.UK_3P.name:
    case BusinessTypes.FRESH_UK.name:
    case BusinessTypes.IT_3P.name:
    case BusinessTypes.ES_3P.name:
      return RegionTypes.EU;
    case BusinessTypes.FRESH_DE.name:
    case BusinessTypes.DE_3P.name:
      return RegionTypes.DE;
    case BusinessTypes.FRESH_JP.name:
    case BusinessTypes.JP_3P.name:
    case BusinessTypes.FRESH_SG.name:
      return RegionTypes.FE;
    default:
      return RegionTypes.NA;
  }
};

export const getBusinessSpecificBullseyeLink = (region, args) => {
  switch (region) {
    case RegionTypes.NA:
      return `https://bullseye2-na.amazon.com/segment?id=${args.id}`;
    case RegionTypes.EU:
    case RegionTypes.DE:
      return `https://bullseye2-eu.amazon.com/segment?id=${args.id}`;
    case RegionTypes.FE:
      return `https://bullseye2-fe.amazon.com/segment?id=${args.id}`;
    default:
      return '';
  }
};

export const getBusinessSpecificComposerLink = (region, business, args) => {
  switch (region) {
    case RegionTypes.NA:
      return ExternalUrls.COMPOSER;
    case RegionTypes.EU:
      return ExternalUrls.COMPOSER_EU;
    case RegionTypes.DE: {
      const campaignColumnsSet = getCampaignFieldsByBusiness(business);
      const selectedChannel = getString(selector(args.state, campaignColumnsSet.CHANNEL.name));
      if (selectedChannel === Channel.PRIME_NOW) {
        return ExternalUrls.COMPOSER_EU_PRIME_NOW;
      }
      return ExternalUrls.COMPOSER_EU;
    }
    default:
      return ExternalUrls.COMPOSER;
  }
};

export const getBusinessSpecificSymphonyLink = (region) => {
  switch (region) {
    case RegionTypes.NA:
      return ExternalUrls.SYMPHONY;
    case RegionTypes.EU:
    case RegionTypes.DE:
      return ExternalUrls.SYMPHONY_EU;
    case RegionTypes.FE:
      return ExternalUrls.SYMPHONY_FE;
    default:
      return ExternalUrls.SYMPHONY;
  }
};

export const getBusinessSpecificWikiLink = (region, business) => {
  switch (region) {
    case RegionTypes.NA:
      if (business === BusinessTypes.WFM.name) {
        return ExternalUrls.MARKETING_WIKI_WFM;
      }
      return ExternalUrls.MARKETING_WIKI;
    case RegionTypes.DE:
      return ExternalUrls.MARKETING_WIKI_DE;
    case RegionTypes.FE:
      if (business === BusinessTypes.JP_3P.name || business === BusinessTypes.FRESH_JP.name) {
        return ExternalUrls.MARKETING_WIKI_JP;
      }

      return ExternalUrls.MARKETING_WIKI;
    default:
      return ExternalUrls.MARKETING_WIKI;
  }
};

const getBusinessSpecificReportIssueLink = (business) => (
  business === BusinessTypes.FRESH.name
    ? ExternalUrls.REPORT_AN_ISSUE_FRESH
    : ExternalUrls.REPORT_AN_ISSUE
);

const getBusinessSpecificRequestFeatureLink = (business) => (
  business === BusinessTypes.FRESH.name
    ? ExternalUrls.REQUEST_A_FEATURE_FRESH
    : ExternalUrls.REQUEST_A_FEATURE
);
const getBusinessSpecificLink = (linkType, business, args) => {
  const region = getBusinessRegion(business);
  switch (linkType) {
    case LinkTypes.BULLSEYE:
      return getBusinessSpecificBullseyeLink(region, args);
    case LinkTypes.COMPOSER:
      return getBusinessSpecificComposerLink(region, business, args);
    case LinkTypes.SYMPHONY:
      return getBusinessSpecificSymphonyLink(region);
    case LinkTypes.WIKI:
      return getBusinessSpecificWikiLink(region, business);
    case LinkTypes.REPORT_ISSUE:
      return getBusinessSpecificReportIssueLink(business);
    case LinkTypes.REQUEST_FEATURE:
      return getBusinessSpecificRequestFeatureLink(business);
    default:
      return '';
  }
};

export default getBusinessSpecificLink;

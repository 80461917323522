import React from 'react';
import BulkImporter from '../BulkImporter';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const BulkImporterPage = () => {
  return <BulkImporter />;
};

export default WithHistoryTiming(BulkImporterPage, { pageName: PageNames.IMPORTER });

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BulletinBoardColumns } from '../../../../constants';
import styles from '../BulletinBoardForm.module.scss';
import FormField from '../../../PackageForm/FormField';

const Main = ({ business }) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <span className={styles.formGroupTitle}>Announcement manager</span>
            <p className={styles.formGroupDescription}>
              Create and update your announcements using this form.
            </p>
          </div>
        </div>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              Business:
              {' '}
              <strong>{business}</strong>
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...BulletinBoardColumns.MESSAGE} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...BulletinBoardColumns.START_DATE_TIME} />
            </div>
            <div className={styles.formControl}>
              <FormField {...BulletinBoardColumns.END_DATE_TIME} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlCheckbox)}>
              <FormField {...BulletinBoardColumns.IS_STICKY} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.formControlCheckbox)}>
              <FormField {...BulletinBoardColumns.IS_GLOBAL} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Main.propTypes = {
  business: PropTypes.string.isRequired,
};

export default Main;

import { API, graphqlOperation } from 'aws-amplify';
import { MetricLocations, MetricNames, MetricTypes } from '../../constants';
import { updateNotification } from '../../graphql/mutations';
import { submitAppSyncError, submitError, submitStringMetric } from '../../metrics';
import { onNotificationsSetUpdating } from '../../actionCreators';
import { getNotificationsList } from '../../selectors/notificationsSelectors';

export default (store) => () => (action) => {
  const { dispatch, getState } = store;
  const { User: { currentUser: { username } } } = getState();
  const notificationsList = getNotificationsList(getState());
  const { payload: { notifications, valueToMark } } = action;
  const promises = [];

  const metricContext = {
    location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: action.payload,
    action: MetricNames.NOTIFICATIONS_MARK_AS_READ_BATCH,
    username,
  };

  submitStringMetric(MetricNames.NOTIFICATIONS_MARK_AS_READ_BATCH, metricContext);

  const processNotification = (params) => {
    return API
      .graphql(graphqlOperation(updateNotification, params))
      .then(({ data: { updateNotification: response } }) => {
        if (!response) {
          submitError('updateNotification returned an empty result', metricContext);
        }
      })
      .catch((error) => {
        submitAppSyncError(error, metricContext);
      });
  };

  const markNotificationAsRead = (notification) => {
    const notificationToMark = notificationsList.find(item => {
      return item.id === notification.id;
    })
    notificationToMark.isRead = valueToMark;
    const params = {
      input: {
        notification: notificationToMark
      },
    };
    promises.push(processNotification(params));
  };

  dispatch(onNotificationsSetUpdating(true));
  notifications.forEach(markNotificationAsRead);

  Promise.all(promises)
    .then(() => {
      dispatch(onNotificationsSetUpdating(false));
    })
    .catch((error) => {
      submitAppSyncError(error, metricContext);
    });
};

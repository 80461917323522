import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Heading from '@amzn/meridian/heading';
import styles from './CommentsList.module.scss';
import CommentsScreen from './CommentsScreen';
import CommentsPanel from './CommentsPanel';
import { commentProps } from '../../proptypes';

const CommentsList = (props) => {
  const {
    campaignId,
    commentsList,
    onLoadComments,
    campaignName,
  } = props;

  useEffect(() => {
    onLoadComments(campaignId);
  }, [onLoadComments, campaignId]);

  const onRefreshComments = () => onLoadComments(campaignId);
  const campaignTitle = campaignName
    ? (
      <Heading
        className={styles.modalHeaderText}
        level={4}
      >
        {campaignName}
      </Heading>
    )
    : null;

  return (
    <div className={styles.container}>
      {campaignTitle}
      <div className={styles.wrapper}>
        <div className={styles.innerContainer}>
          <CommentsScreen
            commentsList={commentsList}
          />
          <CommentsPanel
            campaignId={campaignId}
            onRefreshComments={onRefreshComments}
          />
        </div>
      </div>
    </div>
  );
};

CommentsList.propTypes = {
  campaignId: PropTypes.string.isRequired,
  onLoadComments: PropTypes.func.isRequired,
  commentsList: PropTypes.arrayOf(PropTypes.shape(commentProps)),
  campaignName: PropTypes.string,
};
CommentsList.defaultProps = {
  commentsList: [],
  campaignName: '',
};

export default CommentsList;

import { CampaignStatuses, PageTypes } from './index';

export const SovWarningRelevantStatuses = [
  CampaignStatuses.HOLD,
  CampaignStatuses.MISSING_INPUTS,
  CampaignStatuses.INPUTS_COMPLETE,
  CampaignStatuses.APPROVED,
];

export const SovWarningRelevantPageTypes = [
  PageTypes.CHECKOUT,
];

export const CORRECT_SOV_FOR_CHECKOUT = 5;
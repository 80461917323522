import _ from 'lodash';
import { connect } from 'react-redux';

import {
  onBulkImportStartImporting,
  onBulkImportPerformImport,
  onBulkImportClearPendingPackages,
} from '../../../actionCreators';
import { isWebsiteOmnichannelViewMode } from '../../../selectors/sitewide';

import BulkImportPreview from './preview';

export const mapStateToProps = (state) => {
  const {
    BulkImport: {
      accepted: { create, update },
      rejected,
      formatIssues,
      mismatchedColumnNames,
      isImporting,
      expectedPackageCount,
      succeededPackageRefMarkers,
      failedPackageRefMarkers,
    },
    Meta: { metadata: { columnOrder } },
  } = state;

  const percentageCompleted = _.round(100 * (succeededPackageRefMarkers.length + failedPackageRefMarkers.length) / expectedPackageCount);
  return {
    create,
    update,
    rejected,
    formatIssues,
    mismatchedColumnNames,
    columnOrder,
    isImporting,
    percentageCompleted,
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onPerformImport() { 
      dispatch(onBulkImportStartImporting());
      dispatch(onBulkImportPerformImport()); 
    },
    onClear() { dispatch(onBulkImportClearPendingPackages()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkImportPreview);

import {
  CampaignColumns,
  CampaignTypes,
  PageTypes,
  WidgetTypes,
} from '../constants';

const OMNICHANNEL_PACKAGES = {
  'Omnichannel Deal Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Basic Deal Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Basic Deal Shoveler',
        [CampaignColumns.PAGETYPE.name]: PageTypes.DEALS,
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Themed Endcap Inclusion',
      }
    ],
  },
  'Omnichannel Event Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Themed Endcap Inclusion',
      },
    ],
  },
  'Omnichannel Event Plus': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Enhanced Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Enhanced Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Themed Endcap Inclusion',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Circular Feature',
      },
    ],
  },
  'Omnichannel Deal Plus': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Enhanced Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Dedicated Event Enhanced Shoveler',
        [CampaignColumns.WIDGET.name]: WidgetTypes.ENHSHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Themed Endcap Inclusion',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Circular Feature',
      },
    ],
  },
  'Premium Prime Day Feature': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Prime Day Hero ASIN Shoveler Inclusion',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Prime Day Hero ASIN Shoveler Inclusion',
        [CampaignColumns.WIDGET.name]: WidgetTypes.SHOV,
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Themed Endcap Inclusion',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: '',
      },
    ],
  },
  'House - Omnichannel': {
    [CampaignTypes.ONLINE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: '',
        [CampaignColumns.LANGUAGE.name]: 'English',
      },
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: '',
        [CampaignColumns.LANGUAGE.name]: 'Spanish',
      },
    ],
    [CampaignTypes.IN_STORE]: [
      {
        [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: '',
      },
    ],
  },
};

export default OMNICHANNEL_PACKAGES;
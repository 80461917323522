/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCampaign = /* GraphQL */ `
  query GetCampaign($business: String!, $startYear: String!, $id: String!) {
    getCampaign(business: $business, startYear: $startYear, id: $id) {
      asins
      brand
      business_startYear
      campaignName
      campaignType
      category
      channel
      copy
      createdAt
      endDateTime
      id
      isLocked
      isDeal
      language
      lockMessage
      lockUser
      marketingManagerNotes
      otherTheme
      packageId
      placementSource
      placementInBundle
      promoId
      promoType
      rateCardId
      refMarker
      scheduler
      schedulingInstructions
      spanishCopy
      startDateTime
      status
      timezoneId
      translationStatus
      updatedAt
      version
      almStores
      browseNodeIds
      cbrNotes
      contraCogs
      composerComponentJson
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
      customSeeMoreUrl
      dedupeRules
      desktopSlot
      encodedKeplerWidgetParam
      functionalQANotes
      heroAsins
      imagePaths
      imageType
      inventory
      inventoryAwareness
      isAnimatedContent
      label
      mobileSlot
      merchant
      needsInventoryAwareness
      pageId
      pageName
      pageTheme
      pageType
      percolateParameters
      placement
      shovelerContent
      slotsOverbookedValidationBypassed
      sov
      sovType
      storefrontReviewNotes
      subHeadline
      symphonyId
      symphonyUrl
      targeting
      targetingExclude
      termsAndConditions
      useNewTemplateDesign
      vssmId
      weblab
      weblabTreatment
      widget
      altText
      bodyCopy
      cbrSim
      country
      creativeStatus
      cta
      customerCtaUrl
      designSim
      desktopImage1x
      desktopImage2x
      device
      frequency
      headline
      hva
      initiative
      mobileImage1x
      mobileImage2x
      seasonalEvent
      tabletImage1x
      tabletImage2x
      targetBusiness
      targetingType
    }
  }
`;
export const getCampaignsByBusinessStartYear = /* GraphQL */ `
  query GetCampaignsByBusinessStartYear(
    $business: String!
    $startYear: String!
    $limit: Int
    $nextToken: String
  ) {
    getCampaignsByBusinessStartYear(
      business: $business
      startYear: $startYear
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        asins
        brand
        business_startYear
        campaignName
        campaignType
        category
        channel
        copy
        createdAt
        endDateTime
        id
        isLocked
        isDeal
        language
        lockMessage
        lockUser
        marketingManagerNotes
        otherTheme
        packageId
        placementSource
        placementInBundle
        promoId
        promoType
        rateCardId
        refMarker
        scheduler
        schedulingInstructions
        spanishCopy
        startDateTime
        status
        timezoneId
        translationStatus
        updatedAt
        version
        almStores
        browseNodeIds
        cbrNotes
        contraCogs
        composerComponentJson
        csCampaignIds {
          creativeId
          desktopPlacementId
          mobilePlacementId
        }
        customSeeMoreUrl
        dedupeRules
        desktopSlot
        encodedKeplerWidgetParam
        functionalQANotes
        heroAsins
        imagePaths
        imageType
        inventory
        inventoryAwareness
        isAnimatedContent
        label
        mobileSlot
        merchant
        needsInventoryAwareness
        pageId
        pageName
        pageTheme
        pageType
        percolateParameters
        placement
        shovelerContent
        slotsOverbookedValidationBypassed
        sov
        sovType
        storefrontReviewNotes
        subHeadline
        symphonyId
        symphonyUrl
        targeting
        targetingExclude
        termsAndConditions
        useNewTemplateDesign
        vssmId
        weblab
        weblabTreatment
        widget
        altText
        bodyCopy
        cbrSim
        country
        creativeStatus
        cta
        customerCtaUrl
        designSim
        desktopImage1x
        desktopImage2x
        device
        frequency
        headline
        hva
        initiative
        mobileImage1x
        mobileImage2x
        seasonalEvent
        tabletImage1x
        tabletImage2x
        targetBusiness
        targetingType
      }
      nextToken
    }
  }
`;
export const getCampaignsByIds = /* GraphQL */ `
  query GetCampaignsByIds(
    $business: String!
    $startYear: String!
    $ids: [String]!
  ) {
    getCampaignsByIds(business: $business, startYear: $startYear, ids: $ids) {
      asins
      brand
      business_startYear
      campaignName
      campaignType
      category
      channel
      copy
      createdAt
      endDateTime
      id
      isLocked
      isDeal
      language
      lockMessage
      lockUser
      marketingManagerNotes
      otherTheme
      packageId
      placementSource
      placementInBundle
      promoId
      promoType
      rateCardId
      refMarker
      scheduler
      schedulingInstructions
      spanishCopy
      startDateTime
      status
      timezoneId
      translationStatus
      updatedAt
      version
      almStores
      browseNodeIds
      cbrNotes
      contraCogs
      composerComponentJson
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
      customSeeMoreUrl
      dedupeRules
      desktopSlot
      encodedKeplerWidgetParam
      functionalQANotes
      heroAsins
      imagePaths
      imageType
      inventory
      inventoryAwareness
      isAnimatedContent
      label
      mobileSlot
      merchant
      needsInventoryAwareness
      pageId
      pageName
      pageTheme
      pageType
      percolateParameters
      placement
      shovelerContent
      slotsOverbookedValidationBypassed
      sov
      sovType
      storefrontReviewNotes
      subHeadline
      symphonyId
      symphonyUrl
      targeting
      targetingExclude
      termsAndConditions
      useNewTemplateDesign
      vssmId
      weblab
      weblabTreatment
      widget
      altText
      bodyCopy
      cbrSim
      country
      creativeStatus
      cta
      customerCtaUrl
      designSim
      desktopImage1x
      desktopImage2x
      device
      frequency
      headline
      hva
      initiative
      mobileImage1x
      mobileImage2x
      seasonalEvent
      tabletImage1x
      tabletImage2x
      targetBusiness
      targetingType
    }
  }
`;
export const getPackage = /* GraphQL */ `
  query GetPackage($business: String!, $startYear: String!, $id: String!) {
    getPackage(business: $business, startYear: $startYear, id: $id) {
      business_startYear
      id
      brand
      category
      endDateTime
      isDeal
      marketingManager
      marketingManagerNotes
      packageName
      placementSource
      program
      rateCardId
      refMarker
      startDateTime
      status
      timezoneId
      vendor
      vertical
      version
      updatedAt
      createdAt
    }
  }
`;
export const getPackagesByBusinessStartYear = /* GraphQL */ `
  query GetPackagesByBusinessStartYear(
    $business: String!
    $startYear: String!
    $limit: Int
    $nextToken: String
  ) {
    getPackagesByBusinessStartYear(
      business: $business
      startYear: $startYear
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        business_startYear
        id
        brand
        category
        endDateTime
        isDeal
        marketingManager
        marketingManagerNotes
        packageName
        placementSource
        program
        rateCardId
        refMarker
        startDateTime
        status
        timezoneId
        vendor
        vertical
        version
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getPackagesByIds = /* GraphQL */ `
  query GetPackagesByIds(
    $business: String!
    $startYear: String!
    $ids: [String]!
  ) {
    getPackagesByIds(business: $business, startYear: $startYear, ids: $ids) {
      business_startYear
      id
      brand
      category
      endDateTime
      isDeal
      marketingManager
      marketingManagerNotes
      packageName
      placementSource
      program
      rateCardId
      refMarker
      startDateTime
      status
      timezoneId
      vendor
      vertical
      version
      updatedAt
      createdAt
    }
  }
`;
export const getMetadata = /* GraphQL */ `
  query GetMetadata($business: String!) {
    getMetadata(business: $business) {
      business
      metadata
      version
    }
  }
`;
export const getRefMarker = /* GraphQL */ `
  query GetRefMarker($input: GetRefMarkerInput!) {
    getRefMarker(input: $input)
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($business: String!, $id: Int!) {
    getAnnouncement(business: $business, id: $id) {
      business
      id
      isSticky
      isGlobalAnnouncement
      message
      startDateTime
      endDateTime
      createdAt
    }
  }
`;
export const queryAnnouncement = /* GraphQL */ `
  query QueryAnnouncement($business: String!, $limit: Int, $nextToken: String) {
    queryAnnouncement(
      business: $business
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        business
        id
        isSticky
        isGlobalAnnouncement
        message
        startDateTime
        endDateTime
        createdAt
      }
      nextToken
    }
  }
`;
export const validateCsCampaignInput = /* GraphQL */ `
  query ValidateCsCampaignInput($input: CreateOrUpdateCSCampaignInput!) {
    validateCSCampaignInput(input: $input) {
      creative {
        id
        displayName
        description
        owner
        businessGroup
        accessControlGroupId
        objective
        intent
        creativeUrl
        errorMessage
      }
      desktopPlacement {
        id
        eligibilityCriteriaRule
        guaranteePercentage
        errorMessage
      }
      mobilePlacement {
        id
        eligibilityCriteriaRule
        guaranteePercentage
        errorMessage
      }
      content {
        id
        component
        resources
        errorMessage
      }
    }
  }
`;
export const getNotificationsForUsername = /* GraphQL */ `
  query GetNotificationsForUsername(
    $toUsername: String!
    $createdAfter: String!
    $business_startYear: String!
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsForUsername(
      toUsername: $toUsername
      createdAfter: $createdAfter
      business_startYear: $business_startYear
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationType
        business_startYear
        toUsername
        fromUsername
        campaignId
        packageId
        name
        column
        oldValue
        newValue
        isRead
        createdAt
      }
      nextToken
    }
  }
`;
export const getCommentsForCampaignId = /* GraphQL */ `
  query GetCommentsForCampaignId(
    $campaignId: String!
    $limit: Int
    $nextToken: String
  ) {
    getCommentsForCampaignId(
      campaignId: $campaignId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        campaignId
        message
        createdBy
        createdAt
      }
      nextToken
    }
  }
`;
export const getUserSession = /* GraphQL */ `
  query GetUserSession($username: String!) {
    getUserSession(username: $username) {
      username
      last_notification_seen_at
    }
  }
`;
export const getUserSetting = /* GraphQL */ `
  query GetUserSetting($username: String!, $dataType: String!) {
    getUserSetting(username: $username, dataType: $dataType) {
      username
      dataType
      settings
    }
  }
`;
export const getRateCardsForBusiness = /* GraphQL */ `
  query GetRateCardsForBusiness(
    $business: String!
    $limit: Int
    $nextToken: String
  ) {
    getRateCardsForBusiness(
      business: $business
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        business
        id
        vendor
        marketingManager
        userInput
        configVersion
        note
        isAssetPublished
        version
      }
      nextToken
    }
  }
`;
export const getAssetsForRateCard = /* GraphQL */ `
  query GetAssetsForRateCard(
    $rateCardId: String!
    $limit: Int
    $nextToken: String
  ) {
    getAssetsForRateCard(
      rateCardId: $rateCardId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        rateCardId
        id
        campaign
        version
      }
      nextToken
    }
  }
`;
export const getAssetAuditLog = /* GraphQL */ `
  query GetAssetAuditLog($assetId: String!, $limit: Int, $nextToken: String) {
    getAssetAuditLog(assetId: $assetId, limit: $limit, nextToken: $nextToken) {
      items {
        asset {
          rateCardId
          id
          campaign
          version
        }
        auditLogMetadata {
          modificationType
          modificationTimestamp
          username
        }
      }
      nextToken
    }
  }
`;
export const getCampaignAuditLogs = /* GraphQL */ `
  query GetCampaignAuditLogs(
    $campaignId: String!
    $limit: Int
    $nextToken: String
  ) {
    getCampaignAuditLogs(
      campaignId: $campaignId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        campaign {
          asins
          brand
          business_startYear
          campaignName
          campaignType
          category
          channel
          copy
          createdAt
          endDateTime
          id
          isLocked
          isDeal
          language
          lockMessage
          lockUser
          marketingManagerNotes
          otherTheme
          packageId
          placementSource
          placementInBundle
          promoId
          promoType
          rateCardId
          refMarker
          scheduler
          schedulingInstructions
          spanishCopy
          startDateTime
          status
          timezoneId
          translationStatus
          updatedAt
          version
          almStores
          browseNodeIds
          cbrNotes
          contraCogs
          composerComponentJson
          csCampaignIds {
            creativeId
            desktopPlacementId
            mobilePlacementId
          }
          customSeeMoreUrl
          dedupeRules
          desktopSlot
          encodedKeplerWidgetParam
          functionalQANotes
          heroAsins
          imagePaths
          imageType
          inventory
          inventoryAwareness
          isAnimatedContent
          label
          mobileSlot
          merchant
          needsInventoryAwareness
          pageId
          pageName
          pageTheme
          pageType
          percolateParameters
          placement
          shovelerContent
          slotsOverbookedValidationBypassed
          sov
          sovType
          storefrontReviewNotes
          subHeadline
          symphonyId
          symphonyUrl
          targeting
          targetingExclude
          termsAndConditions
          useNewTemplateDesign
          vssmId
          weblab
          weblabTreatment
          widget
          altText
          bodyCopy
          cbrSim
          country
          creativeStatus
          cta
          customerCtaUrl
          designSim
          desktopImage1x
          desktopImage2x
          device
          frequency
          headline
          hva
          initiative
          mobileImage1x
          mobileImage2x
          seasonalEvent
          tabletImage1x
          tabletImage2x
          targetBusiness
          targetingType
        }
        auditLogMetadata {
          modificationType
          modificationTimestamp
          username
        }
      }
      nextToken
    }
  }
`;
export const getPackageAuditLogs = /* GraphQL */ `
  query GetPackageAuditLogs(
    $packageId: String!
    $limit: Int
    $nextToken: String
  ) {
    getPackageAuditLogs(
      packageId: $packageId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        package {
          business_startYear
          id
          brand
          category
          endDateTime
          isDeal
          marketingManager
          marketingManagerNotes
          packageName
          placementSource
          program
          rateCardId
          refMarker
          startDateTime
          status
          timezoneId
          vendor
          vertical
          version
          updatedAt
          createdAt
        }
        auditLogMetadata {
          modificationType
          modificationTimestamp
          username
        }
      }
      nextToken
    }
  }
`;
export const getRateCardAuditLog = /* GraphQL */ `
  query GetRateCardAuditLog(
    $rateCardId: String!
    $limit: Int
    $nextToken: String
  ) {
    getRateCardAuditLog(
      rateCardId: $rateCardId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        rateCard {
          business
          id
          vendor
          marketingManager
          userInput
          configVersion
          note
          isAssetPublished
          version
        }
        auditLogMetadata {
          modificationType
          modificationTimestamp
          username
        }
      }
      nextToken
    }
  }
`;

import React from 'react';
import { Field, FieldArray } from 'redux-form';
import classnames from 'classnames';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import Icon from '@amzn/meridian/icon';
import infoTokens from '@amzn/meridian-tokens/base/icon/info';
import Autolinker from 'autolinker';
import styles from '../../RateCardForm.module.scss';
import { RateCardColumns } from '../../../../constants/rateCard';
import RateCardRenderFields from '../../../../helpers/RateCardRenderFields';
import { Language } from '../../../../constants';

const renderContent = (inputProps) => {
  const {
    fields,
    meta: {
      error,
      submitFailed,
    },
    totalMerchantClassSpreadPercentage,
  } = inputProps;

  const rows = fields.map((item, index) => {
    return (
      <TableRow key={`${item}.className`} highlightOnHover>
        <TableCell>
          <Field
            name={`${item}.className`}
            type="text"
            component={RateCardRenderFields.capitalizedTextInputField}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.spread`}
            type="text"
            component={RateCardRenderFields.numberInputField}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.dollars`}
            type="text"
            component={RateCardRenderFields.merchIntentDollarField}
            props={{
              ...inputProps,
              fields,
              index,
            }}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.used`}
            type="text"
            component={RateCardRenderFields.merchIntentDollarField}
            props={{
              ...inputProps,
              fields,
              index,
            }}
          />
        </TableCell>
      </TableRow>
    );
  });

  const tableHeaderRows = (
    <TableRow highlightOnHover>
      <TableCell>Class</TableCell>
      <TableCell>Spread %</TableCell>
      <TableCell>Suggested budget</TableCell>
      <TableCell>Budget used</TableCell>
    </TableRow>
  );

  const summaryRow = (
    <TableRow highlightOnHover>
      <TableCell>Total</TableCell>
      <TableCell columnSpan={3}>
        <span className={classnames({
          [styles.bgColorRed]: totalMerchantClassSpreadPercentage !== 100,
        })}
        >
          {totalMerchantClassSpreadPercentage}
          %
        </span>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {submitFailed && error && <span>{error}</span>}
      <Table headerRows={1} showDividers spacing="small">
        {tableHeaderRows}
        {rows}
        {summaryRow}
      </Table>
    </>
  );
};

const MerchandisingIntent = (originalProps) => {
  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Merchandising Intent</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <FieldArray
              name={RateCardColumns.MERCHANDISING_INTENT.name}
              component={renderContent}
              props={{
                ...originalProps,
              }}
            />
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <div className={classnames([
              styles.formGroupDescription,
              styles.formGroupDescriptionMerchandisingIntent,
            ])}
            >
              <Icon tokens={infoTokens}>Info</Icon>
              <p
                className={styles.merchandisingIntentDescription}
                dangerouslySetInnerHTML={{
                  __html: Autolinker.link(Language.RATE_CARD_MERCHANDISING_INTENT_DESCRIPTION),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchandisingIntent;

import moment from 'moment-timezone/index';
import { createSelector } from 'reselect';
import { joinBusinessStartYear } from '../helpers/common';
import { WebsiteViewMode } from '../constants/sitewide';
import { BusinessTypes, BusinessYear } from '../constants';

export const getStartYears = (state) => {
  return state.Sitewide.startYears;
};

export const getSelectedBusiness = (state) => {
  return state.Sitewide.selectedBusiness;
};

export const getWebsiteViewMode = (state) => {
  return state.Sitewide.viewMode;
};

export const isAppSyncConnectionAlive = (state) => {
  return state.Sitewide.isAppSyncConnectionAlive;
};

export const isReadOnlyMode = (state) => {
  return state.Sitewide.isReadOnlyMode;
};

export const isWebsiteOmnichannelViewMode = createSelector(
  [getWebsiteViewMode],
  (viewMode) => {
    return viewMode === WebsiteViewMode.OMNICHANNEL.name;
  },
);


export const isFreshIntOnsiteTrafficBusiness = createSelector(
  [getSelectedBusiness],
  (business) => {
    return business === BusinessTypes.FRESH_INT_TRAFFIC.name;
  }
)
export const isFreshOnsiteTrafficBusiness = createSelector(
  [getSelectedBusiness],
  (business) => {
    return business === BusinessTypes.FRESH_TRAFFIC.name;
  }
)

export const isBusinessFreshTraffic = createSelector(
  [getSelectedBusiness],
  (business) => {
    return business === BusinessTypes.FRESH_INT_TRAFFIC.name ||
           business === BusinessTypes.FRESH_TRAFFIC.name;
  }
);

export const getBusinessStartYears = createSelector(
  [getSelectedBusiness, getStartYears],
  (business, startYears) => {
    return startYears.map((startYear) => joinBusinessStartYear(business, startYear));
  }
);

export const getBusinessEvergreen = createSelector(
  [getSelectedBusiness],
  (business) => {
    return joinBusinessStartYear(business, BusinessYear.EVERGREEN);
  }
);

export const getCurrentBusinessStartYear = createSelector(
  [getSelectedBusiness],
  (business) => {
    return joinBusinessStartYear(business, moment().year());
  }
)
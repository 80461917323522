import {
  CampaignColumns,
  DedupeTypes,
  PlacementType,
  SovTypes,
} from '../../constants';

const PlacementsConfig = {
  'Prime Day 2023 Hero ASIN Shoveler Inclusion': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day 2023 - Hero deal feature placeholder',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PAGE_NAME.name]: 'Prime Day 2023',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-1',
      'fresh-merchandised-content-2',
      'fresh-merchandised-content-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-1',
      'fresh-merchandised-content-2',
      'fresh-merchandised-content-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Prime Day 2023 Dedicated Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day 2023 - Dedicated Shoveler placeholder',
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Prime Day 2023',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PAGE_NAME.name]: 'Prime Day 2023',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Themed Endcap Inclusion': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: ['E.ENDCAP'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'I.ENDCAP',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'Prime Day 2023 Blended Shoveler Inclusion': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Prime Day 2023 - Blended Shoveler placeholder',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PAGE_NAME.name]: 'Prime Day 2023',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: 'Prime Day 2023',
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Basic Deal Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'COOP 2',
      'COOP 3',
      'COOP 4',
      'COOP 5',
      'COOP 6',
      'COOP 7',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-dealspage-desktop-top-6',
      'alm-dealspage-desktop-top-7',
      'alm-dealspage-desktop-middle-1',
      'alm-dealspage-desktop-middle-2',
      'alm-dealspage-desktop-middle-3',
      'alm-dealspage-desktop-middle-4',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-dealspage-mobile-reserved-2',
      'alm-dealspage-mobile-top-7',
      'alm-dealspage-mobile-middle-1',
      'alm-dealspage-mobile-middle-2',
      'alm-dealspage-mobile-middle-3',
      'alm-dealspage-mobile-middle-4',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Featured Deal Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'COOP 1',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-dealspage-desktop-top-5',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-dealspage-mobile-top-5',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'In-Store Endcap': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: [
      'S.ENDCAP - Alcohol',
      'S.ENDCAP - Baking',
      'S.ENDCAP - Beverages',
      'S.ENDCAP - Breakfast',
      'S.ENDCAP - Commercial Bakery',
      'S.ENDCAP - Cookies Crackers',
      'S.ENDCAP - Dairy',
      'S.ENDCAP - Frozen',
      'S.ENDCAP - Meals/Condiments',
      'S.ENDCAP - Non-Food',
      'S.ENDCAP - Produce',
      'S.ENDCAP - Snacks',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'E.ENDCAP',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'Circular Feature': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: [
      'S.CIRCULAR - BWS',
      'S.CIRCULAR - Bakery',
      'S.CIRCULAR - Beverages',
      'S.CIRCULAR - Dairy',
      'S.CIRCULAR - Deli',
      'S.CIRCULAR - Famous For',
      'S.CIRCULAR - Frozen',
      'S.CIRCULAR - Health Beauty',
      'S.CIRCULAR - Home Kitchen',
      'S.CIRCULAR - Meat Seafood',
      'S.CIRCULAR - Pantry',
      'S.CIRCULAR - Prep. Foods',
      'S.CIRCULAR - Produce',
      'S.CIRCULAR - Snacks',
      'S.CIRCULAR - Special Promo PB Seasonal',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'S.CIRC',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  'Dedicated Event Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Omnichannel Event placeholder - Shoveler feature',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Dedicated Event Enhanced Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Omnichannel Event Plus placeholder - Enhanced Shoveler feature',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-2',
      'fresh-merchandised-content-3',
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-2',
      'fresh-merchandised-content-3',
      'fresh-merchandised-content-4',
      'fresh-merchandised-content-5',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'ENSHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.2,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Fresh Find Hero': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'Fresh Find Hero',
      'Fresh Finds 1',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-top-7',
      'alm-dealspage-desktop-reserved-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-top-7',
      'alm-dealspage-mobile-reserved-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 1,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Under The Shelf Strip': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: ['Under The Shelf Strip - Fresh Find'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'UTSS-FF',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
  },
  'Deal Hero Feature': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Deals',
    [CampaignColumns.PAGE_ID.name]: 'Deals',
    [CampaignColumns.PAGE_NAME.name]: 'Deals',
    [CampaignColumns.PAGE_GROUP.name]: 'Deals',
    [CampaignColumns.PAGE_THEME.name]: 'Deals',
    [CampaignColumns.PLACEMENT.name]: [
      'Hero 1',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-dealpage-desktop-reserved-2',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-dealpage-mobile-reserved-2',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Animated Storefront Hero Tile': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'HERO',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-tcg-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-tcg-special-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.05,
    [CampaignColumns.IS_ANIMATED_CONTENT.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Storefront Hero Tile': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'HERO',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-tcg-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-tcg-special-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.05,
    [CampaignColumns.IS_ANIMATED_CONTENT.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Storefront Shoveler Basic Feature': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler 5',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Storefront Shoveler Feature Plus': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 1',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-storefront-desktop-top-7',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-storefront-mobile-top-7',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Enhanced Storefront Shoveler Feature': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Storefront',
    [CampaignColumns.PAGE_ID.name]: 'FMCDummyValue',
    [CampaignColumns.PAGE_NAME.name]: 'Storefront',
    [CampaignColumns.PAGE_GROUP.name]: 'Storefront',
    [CampaignColumns.PAGE_THEME.name]: 'Storefront',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler 5',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'ENSHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Category Page Hero': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Category',
    [CampaignColumns.PLACEMENT.name]: [
      'Hero',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'alm-category-desktop-top-1',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'alm-category-mobile-top-1',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'HERO',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Category Page Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Category',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 1',
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler Bottom 2',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Enhanced Category Page Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Category',
    [CampaignColumns.PLACEMENT.name]: [
      'Shoveler 1',
      'Shoveler 2',
      'Shoveler 3',
      'Shoveler 4',
      'Shoveler Bottom 2',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'ENSHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  'Checkout Page Shoveler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'Checkout',
    [CampaignColumns.PAGE_NAME.name]: 'Checkout',
    [CampaignColumns.PAGE_GROUP.name]: 'Checkout',
    [CampaignColumns.PLACEMENT.name]: [
      'Checkout 1',
      'Checkout 2',
    ],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    // Autofill
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'center-2',
      'center-3',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'center-2',
      'center-3',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'OTHR',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.SOV.name]: 0.1,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  // "Dedicated Event Page Shovler" and "Dedicated Event Shovler" are the same
  'Dedicated Event Page Shovler': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Omnichannel Event placeholder - Shoveler feature',
    [CampaignColumns.PAGETYPE.name]: 'Event',
    [CampaignColumns.PLACEMENT.name]: ['Shoveler'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.DESKTOP_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.MOBILE_SLOT.name]: [
      'fresh-merchandised-content-6',
      'fresh-merchandised-content-7',
      'fresh-merchandised-content-8',
      'fresh-merchandised-content-9',
      'fresh-merchandised-content-10',
      'fresh-merchandised-content-11',
      'fresh-merchandised-content-12',
      'fresh-merchandised-content-13',
      'fresh-merchandised-content-14',
      'fresh-merchandised-content-15',
      'fresh-merchandised-content-16',
      'fresh-merchandised-content-17',
      'fresh-merchandised-content-18',
      'fresh-merchandised-content-19',
      'fresh-merchandised-content-20',
      'fresh-merchandised-content-21',
      'fresh-merchandised-content-22',
      'fresh-merchandised-content-23',
      'fresh-merchandised-content-24',
      'fresh-merchandised-content-25',
    ],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
    [CampaignColumns.CHANNEL.name]: PlacementType.ONLINE,
  },
  // Is it the same "Premium In-Store Endcap (F3G only)"
  'Premium Endcap': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: '',
    [CampaignColumns.PAGETYPE.name]: 'In-Store',
    [CampaignColumns.PAGE_ID.name]: 'In-Store',
    [CampaignColumns.PAGE_NAME.name]: 'In-Store',
    [CampaignColumns.PAGE_GROUP.name]: 'In-Store',
    [CampaignColumns.PLACEMENT.name]: ['E.ENDCAP'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'P.ENDCAP',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
  // "Specialty Page Feature" and "Dedicated Specialty Page Feature" are the same
  'Dedicated Specialty Page Feature': {
    [CampaignColumns.MARKETING_MANAGER_NOTES.name]: 'Specialty Page Feature placeholder',
    [CampaignColumns.PAGETYPE.name]: 'Other',
    [CampaignColumns.PLACEMENT.name]: ['Other'],
    [CampaignColumns.PLACEMENT_SOURCE.name]: 'Rate Card',
    [CampaignColumns.CHANNEL.name]: PlacementType.IN_STORE,
    [CampaignColumns.DESKTOP_SLOT.name]: [],
    [CampaignColumns.MOBILE_SLOT.name]: [],
    [CampaignColumns.DEDUPE_RULES.name]: DedupeTypes.IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN,
    [CampaignColumns.WIDGET.name]: 'SHOV',
    [CampaignColumns.SOV_TYPE.name]: SovTypes.SOV,
    [CampaignColumns.IS_DEAL.name]: true,
  },
};

const customizedPlacementsConfig = {
  ...PlacementsConfig,
  // TODO: no mapping for now
  'In-Aisle Promotion': {},
  'Storefront Side Tile Hero': PlacementsConfig['Storefront Hero Tile'],
  'Standard Dedicated Endcap': PlacementsConfig['In-Store Endcap'],
};

export default customizedPlacementsConfig;

import { WidgetTypes } from '../../constants';

const COMPOSER = 'composer/';
// eslint-disable-next-line
export const extractImagePaths = (text) => text.match(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&~+#-])/g) || [];
export const getPathStartingFromComposer = (path) => (path ? COMPOSER + path.split(COMPOSER)[1] : '');
export const getEmptyImagePathObject = () => {
  return {
    desktop: '',
    mobile: '',
  };
};
export const convertImagePathsToObject = (images) => {
  const imageObject = getEmptyImagePathObject();
  images.forEach((image) => {
    if (image.includes('desktop')) {
      imageObject.desktop = image;
    } else if (image.includes('mobile')) {
      imageObject.mobile = image;
    }
  });
  if (Object.keys(imageObject).length === 2) {
    return imageObject;
  }
  return getEmptyImagePathObject();
};

export const getImagePathsForCampaign = (campaign, files) => {
  const {
    imagePaths,
    widget,
  } = campaign;
  if (!widget || widget !== WidgetTypes.ENHSHOV) {
    return files && files[0] ? files[0] : imagePaths || '';
  }
  if (files && files.length) {
    return JSON.stringify(convertImagePathsToObject(files));
  }
  if (imagePaths) {
    return JSON.stringify(convertImagePathsToObject(imagePaths));
  }
  return JSON.stringify(getEmptyImagePathObject());
};

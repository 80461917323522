import _ from 'lodash';
import { getTemplateString } from '../../helpers/common';
import { getMissingRequiredFields } from '../../helpers/requiredFields';
import {
  Language,
  CampaignColumns,
  MetricLocations,
  MetricTypes,
  MetricNames,
  Delimiters,
} from '../../constants';
import {
  onOpenToast,
  onEditPackage,
} from '../../actionCreators';
import { submitStringMetric } from '../../metrics';
import { getCampaignColumnNames } from '../../helpers/campaign';
import { getPackage } from '../../selectors/package';
import { getUsername } from '../../selectors/user';

const getErrors = (pkg, columnName, flashFillValue) => {
  const errors = [];

  if (columnName === CampaignColumns.STATUS.name) {
    const missingFields = getMissingRequiredFields(pkg, flashFillValue);
    if (missingFields.length > 0) {
      errors.push(getTemplateString(Language.FLASH_FILL_VALIDATION_ERROR, {
        refMarker: pkg.refMarker,
        missingFields: missingFields.join(Delimiters.COMMA),
      }));
    }
  }

  return errors;
};

const getMetricsContext = (state) => {
  const username = getUsername(state);

  return {
    location: MetricLocations.FLASH_FILL_MIDDLEWARE,
    type: MetricTypes.USAGE,
    data: {},
    action: MetricNames.RUN_FLASH_FILL,
    username,
  };
};

export default (dispatch, getState, action, next) => {
  const state = getState();

  const {
    payload: { id, packageId, cellValue, columnName },
  } = action;

  const currentPackage = getPackage(state, (packageId ? packageId : id));
  if (!currentPackage) {
    return next(action);
  }

  const packageClone = _.cloneDeep(currentPackage);

  submitStringMetric(MetricNames.RUN_FLASH_FILL, getMetricsContext(state));

  if (getCampaignColumnNames().includes(columnName)) {
    const errors = getErrors(packageClone, columnName, cellValue);
    if (errors.length > 0) {
      errors.forEach((errorMessage) => {
        dispatch(onOpenToast(errorMessage));
      });
      return false;
    }
    packageClone.campaigns.forEach((campaign) => {
      campaign[columnName] = cellValue;
    });
    dispatch(onEditPackage(packageClone));
  }
  return next(action);
};
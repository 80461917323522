import React from 'react';
import { useHistory } from 'react-router-dom';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import deleteTokens from '@amzn/meridian-tokens/base/icon/delete';
import dashboardTokens from '@amzn/meridian-tokens/base/icon/dashboard';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import PropTypes from 'prop-types';
import styles from './RateCardAssetsListButtons.module.scss';
import { campaignProps } from '../../proptypes';
import { Urls } from '../../constants';

const RateCardAssetsListButtons = (props) => {
  const {
    asset,
    onDelete,
    isReadOnlyMode,
  } = props;

  const history = useHistory();

  const buttons = [
    {
      name: 'Audit log',
      iconToken: dashboardTokens,
      callback: () => {
        history.push(`${Urls.RATE_CARD_ASSETS}/${asset.id}`);
      },
    },
  ];

  if (!isReadOnlyMode) {
    const editButtons = [
      {
        name: 'Delete',
        iconToken: deleteTokens,
        callback: onDelete,
      },
      {
        name: 'Edit',
        iconToken: editTokens,
        callback: () => history.push(`${Urls.RATE_CARD_ASSETS}/${asset.id}`),
      },
    ];

    editButtons.forEach((editButton) => buttons.push(editButton));
  }

  const buttonsList = buttons.reverse().map(({ name, iconToken, callback }) => {
    return (
      <li
        key={name}
        className={styles.buttonItem}
      >
        <Button
          type="icon"
          onClick={() => callback(asset)}
          size="small"
        >
          <Icon tokens={iconToken}>{name}</Icon>
        </Button>
      </li>
    );
  });

  return (
    <ul className={styles.buttonsList}>
      {buttonsList}
    </ul>
  );
};

RateCardAssetsListButtons.propTypes = {
  onDelete: PropTypes.func.isRequired,
  asset: campaignProps.isRequired,
  isReadOnlyMode: PropTypes.bool.isRequired,
};

export default RateCardAssetsListButtons;

import React from 'react';
import Dashboard from '../Dashboard';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const DashboardPage = () => {
  return <Dashboard />;
};

export default WithHistoryTiming(DashboardPage, { pageName: PageNames.DASHBOARD });

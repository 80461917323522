import {
  RateCardMediaPackage,
  RateCardPlacementPrices,
} from '../../../../../constants/rateCard';

const Conversion = [
  {
    mediaPackage: RateCardMediaPackage.TEN_MILLIONS.name,
    className: 'Conversion',
    type: 'Main',
    placementName: 'Deal Shoveler (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 4,
    numberPlacements: 4,
  },
  {
    mediaPackage: RateCardMediaPackage.FIFTEEN_MILLIONS.name,
    className: 'Conversion',
    type: 'Main',
    placementName: 'Deal Shoveler (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 5,
  },
  {
    mediaPackage: RateCardMediaPackage.TWENTY_MILLIONS.name,
    className: 'Conversion',
    type: 'Main',
    placementName: 'Deal Shoveler (2-weeks)',
    dollars: RateCardPlacementPrices.BONUS_PLACEMENT_COST,
    isUff: 1,
    isAfs: 0,
    vendorMax: 7,
    numberPlacements: 7,
  },
];

export default Conversion;
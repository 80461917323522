import moment from 'moment-timezone';
import {
  GraphQLLabels, Language, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import { onOpenToast } from '../../actionCreators';
import { createAnnouncement } from '../../graphql/mutations';
import { getTranslatedAnnouncement } from '../../helpers/bulletinBoard';

const addRequest = ({
  state, dispatch, action, next,
}) => {
  const {
    User: {
      currentUser: {
        username,
      },
    },
  } = state;

  const announcementToCreate = getTranslatedAnnouncement(action.data, state);
  announcementToCreate.createdAt = moment().utc().format();

  const metricContext = {
    location: MetricLocations.BULLETIN_BOARD_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: announcementToCreate,
    action: MetricNames.ADD_ANNOUNCEMENT,
    username,
  };

  const input = {
    announcement: announcementToCreate,
    username,
  };

  submitStringMetric(MetricNames.ADD_ANNOUNCEMENT, metricContext);
  dispatch(onGraphQLAction({
    graphqlAction: createAnnouncement,
    data: { input },
    label: GraphQLLabels.CREATE_ANNOUNCEMENT,
    onSuccessFunction({ data: { createAnnouncement: newAnnouncement } }) {
      next({
        ...action,
        data: newAnnouncement,
      });

      dispatch(onOpenToast(Language.BULLETIN_BOARD_SUCCESS_ADD_ANNOUNCEMENT));
    },
    onFailureFunction(error) {
      dispatch(onOpenToast(Language.BULLETIN_BOARD_ERROR_ADD_ANNOUNCEMENT));
      submitAppSyncError(error, metricContext);
    },
  }));

  return null;
};

export default addRequest;

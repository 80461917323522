import { RateCardConfigNames } from '../constants/rateCard';
import * as RateCardConfig2022 from '../rateCardConfiguration/rate-card-2022-Aug';
import * as RateCardConfig2023 from '../rateCardConfiguration/rate-card-2023-Aug';
import * as RateCardConfig2024 from '../rateCardConfiguration/rate-card-2024-Aug';

const getConfigs = () => ({
  [RateCardConfigNames.YEAR_2022.name]: RateCardConfig2022,
  [RateCardConfigNames.YEAR_2023.name]: RateCardConfig2023,
  [RateCardConfigNames.YEAR_2024.name]: RateCardConfig2024,
});

export const getLatestConfigName = () => {
  const configs = getConfigs();
  const sortedConfigs = Object.keys(configs).sort();

  return sortedConfigs[sortedConfigs.length - 1];
};

const getLatestConfig = () => {
  return getConfigs()[getLatestConfigName()];
};

const getConfig = (configName) => {
  const config = configName
    ? getConfigs()[configName]
    : getLatestConfig();

  if (!config) {
    throw new Error(`Cannot find RateCard's '${configName}' config!`);
  }

  const {
    BundlesConfig,
    PlacementsConfig,
    MultipliersConfig,
    LimitedPlacements,
    MainPlacements,
    VSSMPlacements,
    BonusMainPlacements,
  } = config;

  return {
    getBundlesConfig: () => BundlesConfig,
    getPlacementsConfig: () => PlacementsConfig,
    getLimitedPlacements: () => LimitedPlacements,
    getMainPlacements: () => MainPlacements,
    getVSSMPlacements: () => VSSMPlacements,
    getBonusMainPlacements: () => BonusMainPlacements,
    getMultipliersConfig: () => MultipliersConfig,
  };
};

export default getConfig;

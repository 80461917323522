import _ from 'lodash';
import { arrayRemoveAll, arrayPush, getFormValues } from 'redux-form';
import formValueSelector from 'redux-form/lib/formValueSelector';
import { CampaignColumns, Form, DEFAULT_TIMEZONE_ID } from '../../constants';
import {
  createInStoreCampaignShell,
  createOnlineCampaignShell,
} from '../../helpers/campaign';
import { getColumnOptions } from '../../selectors/metadata';
import { getPackageMapping } from '../../helpers/packageMapping';
import { getNonEmptyObjKeys } from '../../helpers/common';

const selector = formValueSelector(Form.NAME);

const removeFormCampaignTabs = (dispatch) => {
  dispatch(arrayRemoveAll(Form.NAME, 'campaigns'));
};

const createFormCampaignTab = (dispatch, campaign) => {
  dispatch(arrayPush(
    Form.NAME,
    'campaigns',
    campaign,
  ));
}

const getSlot = (state, slotName, item) => {
  const slots = getColumnOptions(
    state,
    slotName,
    item
  );

  if (slots && slots.length === 1) {
    const [firstSlot] = slots;
    return firstSlot;
  }

  return '';
};

const getDateTimeOverrides = (state) => {
  const pkg = getFormValues(Form.NAME)(state);
  if (_.isEmpty(pkg)) {
    return {};
  }
  const { startDate, startTime, endDate, endTime, timezoneId } = pkg;

  return {
    [CampaignColumns.START_DATE.name]: startDate || '',
    [CampaignColumns.START_TIME.name]: startTime || '',
    [CampaignColumns.END_DATE.name]: endDate || '',
    [CampaignColumns.END_TIME.name]: endTime || '',
    [CampaignColumns.TIMEZONE_ID.name]: timezoneId || DEFAULT_TIMEZONE_ID,
  };
};

const createNewCampaigns = (state, packageName) => {
  const packageMapping = getPackageMapping(packageName);
  if (_.isEmpty(packageMapping)) {
    return [];
  }

  const pkg = getFormValues(Form.NAME)(state);
  const newCampaigns = [];

  const onlineCampaignPresets = packageMapping.getOnlineCampaignPresets();
  if (!_.isEmpty(onlineCampaignPresets)) {
    onlineCampaignPresets.forEach(campaignPreset => {
      const onlineCampaignShell = createOnlineCampaignShell({
        ...campaignPreset,
        ...getDateTimeOverrides(state),
        [CampaignColumns.BUSINESS_START_YEAR.name]: pkg[CampaignColumns.BUSINESS_START_YEAR.name],
      });
      const desktopSlot = getSlot(
        state,
        CampaignColumns.DESKTOP_SLOT.name,
        onlineCampaignShell,
      );
      const mobileSlot = getSlot(
        state,
        CampaignColumns.MOBILE_SLOT.name,
        onlineCampaignShell,
      );

      newCampaigns.push({
        ...onlineCampaignShell,
        [CampaignColumns.DESKTOP_SLOT.name]: desktopSlot,
        [CampaignColumns.MOBILE_SLOT.name]: mobileSlot,
      });
    });
  }

  const inStoreCampaignPresets = packageMapping.getInStoreCampaignPresets();
  if (!_.isEmpty(inStoreCampaignPresets)) {
    inStoreCampaignPresets.forEach(campaignPreset => {
      newCampaigns.push(createInStoreCampaignShell({
        ...campaignPreset,
        [CampaignColumns.BUSINESS_START_YEAR.name]: pkg[CampaignColumns.BUSINESS_START_YEAR.name],
      }));
    });
  }

  return newCampaigns;
};

const getUpdatedExistingCampaigns = (state, packageName) => {
  const existingCampaigns = selector(state, 'campaigns');
  if (_.isEmpty(existingCampaigns) || _.isEmpty(getPackageMapping(packageName))) {
    return [];
  }

  const newCampaigns = createNewCampaigns(state, packageName);

  const copiedExistingCampaignIndexes = {};
  existingCampaigns.forEach((existingCampaign, existingCampaignIndex) => {
    newCampaigns.forEach((newCampaign, newCampaignIndex) => {
      if (
        copiedExistingCampaignIndexes[existingCampaignIndex]
        || newCampaign[CampaignColumns.LANGUAGE.name] !== existingCampaign[CampaignColumns.LANGUAGE.name]
      ) {
        return;
      }
      copiedExistingCampaignIndexes[existingCampaignIndex] = true;

      const existingCampaignFieldsToCopy = _.omit(
        existingCampaign,
        getNonEmptyObjKeys(newCampaign)
      );

      newCampaigns[newCampaignIndex] = {
        ...newCampaign,
        ...existingCampaignFieldsToCopy,
      };
    });
  });

  return newCampaigns;
};

export default (dispatch, getState, action, next) => {
  const state = getState();

  if (selector(state, 'id')) {
    return next(action);
  }
  const { payload: packageName } = action;

  const existingCampaigns = selector(state, 'campaigns');
  if (existingCampaigns && existingCampaigns.length) {
    removeFormCampaignTabs(dispatch);

    getUpdatedExistingCampaigns(state, packageName).forEach(updatedCampaign => {
      createFormCampaignTab(dispatch, updatedCampaign);
    });
  } else {
    createNewCampaigns(state, packageName).forEach(newCampaign => {
      createFormCampaignTab(dispatch, newCampaign);
    });
  }

  return next(action);
}
import { connect } from 'react-redux';
import CheckoutSOVWarning from './CheckoutSOVWarning';
import { getNumber } from '../../../../../../helpers/common';
import {
  CORRECT_SOV_FOR_CHECKOUT,
  SovWarningRelevantPageTypes,
  SovWarningRelevantStatuses,
} from '../../../../../../constants/form';
import { getFormActiveCampaign } from '../../../../../../selectors/form';

export const mapStateToProps = (state) => {
  const {
    status,
    pageType,
    sov
  } = getFormActiveCampaign(state);

  const showWarning = SovWarningRelevantStatuses.includes(status)
                   && SovWarningRelevantPageTypes.includes(pageType)
                   && getNumber(sov) !== CORRECT_SOV_FOR_CHECKOUT;

  return {
    showWarning,
  };
};

export default connect(mapStateToProps)(CheckoutSOVWarning);

import React from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '@amzn/meridian/date-range-picker';
import Button from '@amzn/meridian/button';
import Toggle from '@amzn/meridian/toggle';

import TimeInput from '../../StyledInputs/TimeInput';
import styles from './SearchPanel.module.scss';
import { Language } from '../../../constants';

const SearchPanel = (props) => {
  const {
    dateRange,
    startTime,
    endTime,
    presets,
    onDateRangeSelect,
    onStartTimeSet,
    onEndTimeSet,
    onButtonClick,
    onToggleMode,
    isOverlapMode,
  } = props;

  const modeName = isOverlapMode ? 'Overlap' : 'Contains';
  const modeHint = isOverlapMode
    ? Language.DASHBOARD_STATS_MODE_OVERLAP_DESCRIPTION
    : Language.DASHBOARD_STATS_MODE_CONTAIN_DESCRIPTION;

  const updateStartTime = (value) => onStartTimeSet(value);
  const updateEndTime = (value) => onEndTimeSet(value);

  return (
    <div className={styles.searchPanel}>
      <div className={styles.wrapper}>
        <div className={styles.toggleContainer}>
          <p className={styles.toggleDescription}>Stats mode</p>
          <div className={styles.toggleButtonWrapper}>
            <Toggle
              onChange={onToggleMode}
              checked={isOverlapMode}
            >
              <span className={styles.toggleModeName}>
                {modeName}
              </span>
            </Toggle>
          </div>
          <div className={styles.toggleHint}>
            {modeHint}
          </div>
        </div>
        <div className={styles.searchBar}>
          <div className={styles.dateTimePickerContainer}>
            <div className={styles.datePicker}>
              <DateRangePicker
                value={dateRange}
                onChange={onDateRangeSelect}
                startLabel="Start date"
                endLabel="End date"
                monthsInView={2}
                presets={presets}
              />
            </div>
            <div className={styles.timePicker}>
              <div className={styles.startDate}>
                <TimeInput
                  inputValue={startTime}
                  onInputChange={updateStartTime}
                />
              </div>
              <div className={styles.endDate}>
                <TimeInput
                  inputValue={endTime}
                  onInputChange={updateEndTime}
                />
              </div>
            </div>
          </div>
          <div className={styles.searchButtonContainer}>
            <Button
              onClick={onButtonClick}
              size="large"
            >
              Go
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchPanel.propTypes = {
  onDateRangeSelect: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onStartTimeSet: PropTypes.func.isRequired,
  onEndTimeSet: PropTypes.func.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  presets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  isOverlapMode: PropTypes.bool.isRequired,
  onToggleMode: PropTypes.func.isRequired,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

SearchPanel.defaultProps = {
  startTime: '',
  endTime: '',
};

export default SearchPanel;

import React from 'react';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import PropTypes from 'prop-types';
import { RateCardTabsList } from '../../../constants/rateCard';

const RateCardContainerTabs = ({ currentTab, onSwitchTab }) => {
  return (
    <TabGroup
      value={currentTab}
      onChange={onSwitchTab}
      fill="tabs"
    >
      <Tab value={RateCardTabsList.RATE_CARD.name}>{RateCardTabsList.RATE_CARD.label}</Tab>
      <Tab value={RateCardTabsList.ASSETS.name}>{RateCardTabsList.ASSETS.label}</Tab>
      <Tab value={RateCardTabsList.AUDIT_LOG.name}>{RateCardTabsList.AUDIT_LOG.label}</Tab>
    </TabGroup>
  );
};

RateCardContainerTabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  onSwitchTab: PropTypes.func.isRequired,
};

export default RateCardContainerTabs;

import { connect } from 'react-redux';
import Announcement from './Announcement';
import { getFilteredActiveAnnouncements } from '../../selectors/bulletinBoard';

export const mapStateToProps = (state) => {
  return {
    announcements: getFilteredActiveAnnouncements(state),
  };
};

export default connect(mapStateToProps)(Announcement);

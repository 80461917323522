/* eslint-disable no-param-reassign */
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CampaignColumns, DashboardGroupCriteria } from '../../constants';
import { isGenericFreshBusiness, isWFMBusiness } from '../../helpers/common';

const moment = extendMoment(Moment);

export const getStatsGroups = (business, statsCategory) => {
  if (isGenericFreshBusiness(business)) {
    return {
      [DashboardGroupCriteria.MY_STATS]: [
        CampaignColumns.STATUS.name,
        CampaignColumns.VERTICAL.name,
        CampaignColumns.VENDOR.name,
        CampaignColumns.WIDGET.name,
        CampaignColumns.PAGETYPE.name,
        CampaignColumns.BRAND.name,
      ],
      [DashboardGroupCriteria.TOTAL_STATS]: [
        CampaignColumns.STATUS.name,
        CampaignColumns.VERTICAL.name,
        CampaignColumns.VENDOR.name,
        CampaignColumns.WIDGET.name,
        CampaignColumns.PAGETYPE.name,
        CampaignColumns.BRAND.name,
      ],
    }[statsCategory];
  }

  if (isWFMBusiness(business)) {
    return {
      [DashboardGroupCriteria.MY_STATS]: [
        CampaignColumns.STATUS.name,
        CampaignColumns.CHANNEL.name,
        CampaignColumns.VERTICAL.name,
        CampaignColumns.VENDOR.name,
      ],
      [DashboardGroupCriteria.TOTAL_STATS]: [
        CampaignColumns.STATUS.name,
        CampaignColumns.CHANNEL.name,
        CampaignColumns.VERTICAL.name,
        CampaignColumns.VENDOR.name,
        CampaignColumns.MARKETING_MANAGER.name,
      ],
    }[statsCategory];
  }

  return [];
};

const getColumnValue = (currentPackage, campaign, columnName) => {
  if (campaign && campaign[columnName]) {
    return campaign[columnName];
  }

  if (currentPackage && currentPackage[columnName]) {
    return currentPackage[columnName];
  }

  return '';
};

const processStatsGroup = (dataSet, currentPackage, campaign, business, statsCategory) => {
  dataSet.totalCampaigns++;
  getStatsGroups(business, statsCategory).forEach((columnName) => {
    if (!dataSet.statsBy[columnName]) {
      dataSet.statsBy[columnName] = {};
    }

    const columnValue = getColumnValue(currentPackage, campaign, columnName);
    if (!columnValue) {
      return;
    }
    if (!dataSet.statsBy[columnName][columnValue]) {
      dataSet.statsBy[columnName][columnValue] = 0;
    }

    dataSet.statsBy[columnName][columnValue]++;
  });
};

export const getStatsByDateRange = (
  business,
  packages,
  dateRange,
  isOverlapMode = false,
  username = null,
) => {
  const dataSet = {
    [DashboardGroupCriteria.TOTAL_STATS]: {
      totalCampaigns: 0,
      statsBy: {},
    },
    [DashboardGroupCriteria.MY_STATS]: {
      totalCampaigns: 0,
      totalMarketingManager: 0,
      totalScheduler: 0,
      statsBy: {},
    },
  };

  if (!Array.isArray(dateRange)
    || !dateRange.length
    || !packages
    || !packages.length
  ) {
    return dataSet;
  }

  const [rangeDateStart, rangeDateEnd] = dateRange;

  packages.forEach((currentPackage) => {
    const { campaigns, startDate, endDate, marketingManager } = currentPackage;
    if (
      !campaigns
      || !campaigns.length
      || !startDate
      || !endDate) {
      return;
    }
    const packageStartDate = moment(startDate);
    const packageEndDate = moment(endDate);

    if (packageEndDate.isBefore(packageStartDate, 'days')) {
      return;
    }

    const packageDateRange = moment.range([
      packageStartDate,
      packageEndDate,
    ]);
    const selectedDateRange = moment.range([
      moment(rangeDateStart),
      moment(rangeDateEnd),
    ]);

    if (isOverlapMode) {
      if (!packageDateRange.overlaps(selectedDateRange)) {
        return;
      }
    } else if (!selectedDateRange.contains(packageDateRange, true)) {
      return;
    }

    if (username === marketingManager) {
      dataSet[DashboardGroupCriteria.MY_STATS].totalMarketingManager++;

      processStatsGroup(
        dataSet[DashboardGroupCriteria.MY_STATS],
        currentPackage,
        null,
        business,
        DashboardGroupCriteria.MY_STATS,
      );
    }

    campaigns.forEach((campaign) => {
      const { scheduler } = campaign;

      processStatsGroup(
        dataSet[DashboardGroupCriteria.TOTAL_STATS],
        currentPackage,
        campaign,
        business,
        DashboardGroupCriteria.TOTAL_STATS,
      );
      if (scheduler === username) {
        dataSet[DashboardGroupCriteria.MY_STATS].totalScheduler++;

        processStatsGroup(
          dataSet[DashboardGroupCriteria.MY_STATS],
          currentPackage,
          campaign,
          business,
          DashboardGroupCriteria.MY_STATS,
        );
      }
    });
  });

  return dataSet;
};

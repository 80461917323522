import React from 'react';
import PropTypes from 'prop-types';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import deleteTokens from '@amzn/meridian-tokens/base/icon/close-circle';

import SingleSelect from '../../StyledInputs/SingleSelect';
import Input from '../../StyledInputs/Input';
import DateTime from '../../StyledInputs/DateTime';

import {
  Comparators,
  InputTypes,
} from '../../../constants';

import GridViewFilterGroup from '../GridViewFilterGroup';

import styles from '../GridViewFilterModal/GridViewFilterModal.module.scss';
import sitewideStyles from '../../../styles/sitewide.module.scss';

const GridViewFilterRow = ({
  filter,
  index,
  campaignColumns,
  getAllMetadataOptionsForColumn,
  deleteFilter,
  depth,
  onInputChange,
  updateChildFilters,
  onToggleGroupType,
  deleteGroup,
}) => {
  const columnOptions = Object.keys(campaignColumns).map((key) => {
    return {
      label: campaignColumns[key].display,
      value: campaignColumns[key].name,
    };
  });

  const comparatorOptions = Object.keys(Comparators).map((key) => {
    return Comparators[key];
  });

  const getInputType = () => {
    const columnData = Object.keys(campaignColumns)
      .map((key) => campaignColumns[key])
      .find((column) => column.name === filter.column);
    if (!columnData || !columnData.inputType) {
      return <Input inputValue={filter.value} onInputChange={onInputChange('value')} />;
    }
    if (filter.comparator === Comparators.NOT_EMPTY
      || filter.comparator === Comparators.EMPTY) {
      return null;
    }
    switch (columnData.inputType) {
      case InputTypes.DATE_TIME:
        return (
          <DateTime
            inputValue={filter.value}
            onInputChange={onInputChange('value')}
            autoFocus={false}
          />
        );
      case InputTypes.SINGLE_SELECT: {
        const options = getAllMetadataOptionsForColumn(filter.column);
        return (
          <SingleSelect
            inputValue={filter.value}
            options={options}
            size="small"
            onInputChange={onInputChange('value')}
            allowOverride
          />
        );
      }
      case InputTypes.SINGLE_CHECKBOX: {
        const options = ['True', 'False'];
        return (
          <SingleSelect
            inputValue={filter.value}
            options={options}
            size="small"
            onInputChange={onInputChange('value')}
          />
        );
      }
      // TODO: add additional input types (single select with full metadata options)
      default:
        return <Input inputValue={filter.value} onInputChange={onInputChange('value')} />;
    }
  };

  if (filter.type && filter.rules) {
    return (
      <GridViewFilterGroup
        filters={filter.rules}
        onUpdateFilters={updateChildFilters}
        onDeleteGroup={deleteGroup}
        groupType={filter.type}
        depth={depth}
        onToggleGroupType={onToggleGroupType}
      />
    );
  }
  return (
    <Row height="38px" width="100%" widths="mixed">
      <div className={styles.filterColumn}>
        <SingleSelect
          inputValue={filter.column}
          options={columnOptions}
          size="small"
          onInputChange={onInputChange('column')}
        />
      </div>
      <div className={styles.filterColumn}>
        <SingleSelect
          inputValue={filter.comparator}
          options={comparatorOptions}
          size="small"
          onInputChange={onInputChange('comparator')}
        />
      </div>
      <div className={styles.filterColumn}>
        {getInputType()}
      </div>
      <div>
        <button
          onClick={() => { deleteFilter(index); }}
          className={sitewideStyles.buttonAsLink}
          type="button"
        >
          <Icon tokens={deleteTokens} className={sitewideStyles.svgIcon} />
        </button>
      </div>
    </Row>
  );
};

GridViewFilterRow.propTypes = {
  filter: PropTypes.shape({
    column: PropTypes.string,
    comparator: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    rules: PropTypes.array,
  }).isRequired,
  index: PropTypes.number.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  getAllMetadataOptionsForColumn: PropTypes.func.isRequired,
  campaignColumns: PropTypes.objectOf(PropTypes.shape({
    display: PropTypes.string,
    name: PropTypes.string,
    inputType: PropTypes.string,
  })).isRequired,
  depth: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired,
  updateChildFilters: PropTypes.func.isRequired,
  onToggleGroupType: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
};

export default GridViewFilterRow;

import JsonInputControl from '../Controls/JsonInput/JsonInput';

const JsonInput = (props) => {
  const {
    inputValue,
    placeholder,
    onInputChange,
    onBlur,
  } = props;
  return JsonInputControl({
    input: { value: inputValue },
    onInputChange,
    customOnBlur: onBlur,
    placeholder,
    isGridViewControl: true,
  });
};

export default JsonInput;

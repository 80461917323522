import { connect } from 'react-redux';

import { defaultDispatchToProps, defaultStateToProps } from '../helpers';
import {
  onActiveCellInputSubmit,
  onActiveCellInputUpdate,
} from '../../../actionCreators';
import {
  CampaignColumns,
} from '../../../constants';

import DateTime from '../../StyledInputs/DateTime';

export const mapStateToProps = (state) => {
  const defaultState = defaultStateToProps(state);

  const { GridViewPage: { activeCell: { columnName } } } = state;
  const presets = [];
  if (columnName === CampaignColumns.END_DATE.name) {
    presets.push({ label: 'Forever', value: '2099-01-01' });
  }

  return {
    ...defaultState,
    presets,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
      dispatch(onActiveCellInputSubmit());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateTime);

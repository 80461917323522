import React from 'react';
import PropTypes from 'prop-types';
import { getFiltersUrl } from '../../../helpers/utilization';
import UtilizationCell from '../UtilizationCell/UtilizationCell';

import styles from '../UtilizationSlots/UtilizationSlots.module.scss';
import { slotUtilizationProps } from '../../../proptypes';

const UtilizationRow = (props) => {
  const {
    daysInDateRange,
    endDate,
    slotType,
    slotName,
    selectedFilters,
    utilization,
    isAbsStatsValuesOnly,
    isFreshBusiness,
    placement,
  } = props;

  let index = 0;
  const cellsList = daysInDateRange.map((currentDate) => {
    ++index;

    const nextDate = daysInDateRange[index] ? daysInDateRange[index] : endDate;
    const link = getFiltersUrl(
      slotType,
      slotName,
      currentDate,
      nextDate,
      selectedFilters,
    );

    return (
      <UtilizationCell
        key={`${slotName}/${currentDate}`}
        utilization={utilization}
        isAbsStatsValuesOnly={isAbsStatsValuesOnly}
        isFreshBusiness={isFreshBusiness}
        currentDate={currentDate}
        link={link}
      />
    );
  });

  const placementName = placement
  && placement !== 'default'
    ? `(${placement})`
    : '';

  return (
    <tr key={slotName}>
      <td className={styles.columnHeader}>
        <div className={styles.cell}>
          {slotName}
          {' '}
          {placementName}
        </div>
      </td>
      {cellsList}
    </tr>
  );
};

UtilizationRow.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string).isRequired,
  daysInDateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAbsStatsValuesOnly: PropTypes.bool.isRequired,
  isFreshBusiness: PropTypes.bool.isRequired,
  endDate: PropTypes.string.isRequired,
  slotType: PropTypes.string.isRequired,
  slotName: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  utilization: PropTypes.shape({
    slotUtilizationProps,
  }).isRequired,
};

export default UtilizationRow;

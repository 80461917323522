import Awareness from './Awareness';
import Conversion from './Conversion';
import Consideration from './Consideration';

const LimitedPlacements = [
  ...Awareness,
  ...Conversion,
  ...Consideration,
];

export default LimitedPlacements;

import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch, Redirect, } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import AppLayout from '@amzn/meridian/app-layout';
//import '@amzn/meridian-tokens/base/font/amazon-ember.css'; Confirm if this is necessary by checking in windows machine
import FeatureNotifications from '../FeatureNotification';
import Header from '../Header';
import SideMenu from '../SideMenu';
import MainViewPage from '../routes/campaigns';
import UtilizationPage from '../routes/utilization';
import InStoreUtilizationPage from '../routes/in-store-utilization';
import DashboardPage from '../routes/dashboard';
import MetadataEditor from '../routes/metadata';
import BulkImporter from '../routes/import';
import * as graphQlSubscriptions from '../../graphql/subscriptions';
import history from '../../history';
import { Urls, Colors } from '../../constants';
import ToastManager from '../ToastManager';
import Loader from '../Loader';
import CreateSymphonyCsCampaignModal from '../CreateSymphonyCsCampaignModal';
import AppSyncCheckConnection from '../AppSyncCheckConnection';
import CustomViewModal from '../CustomViewModal';
import CommentsModal from '../CommentsModal';
import CsDiffScreenModal from '../CsDiffScreenModal';
import NewUserExperience from '../NewUserExperience';
import BulletinBoardPage from '../routes/bulletin-board';
import NotificationsPage from '../routes/notifications';
import NewAnnouncementPage from '../routes/new-announcement';
import EditAnnouncementPage from '../routes/edit-announcement';
import MetadataAuditLogPage from '../routes/metadataAuditLog';
import ThirdPartyIFrame from '../ThirdPartyIFrame';
import Redirecter from '../ThirdPartyIFrame/Redirecter';
import RateCardListPage from '../routes/rate-card-list';
import RateCardNewPage from '../routes/rate-card-new';
import RateCardEditPage from '../routes/rate-card-edit';
import RateCardClonePage from '../routes/rate-card-clone';
import RateCardAssetsPage from '../routes/rate-card-assets';
import CampaignAuditLogPage from '../routes/campaign-audit';
import PackageAuditLogPage from '../routes/package-audit';
import '../../metrics/performanceObserver';
var App = function (_a) {
    var onPageInit = _a.onPageInit, onUpdateCampaign = _a.onUpdateCampaign, onUpdateComments = _a.onUpdateComments, onCreateNotification = _a.onCreateNotification, composerEnabled = _a.composerEnabled, onThirdPartyIFrameEvent = _a.onThirdPartyIFrameEvent, onAppSyncSubscriptionConnectionLost = _a.onAppSyncSubscriptionConnectionLost;
    useEffect(function () {
        onPageInit();
    }, [onPageInit]);
    useEffect(function () {
        var subscriptions = [
            API.graphql(graphqlOperation(graphQlSubscriptions.onUpdateCampaign)).subscribe({
                next: onUpdateCampaign,
                error: onAppSyncSubscriptionConnectionLost,
            }),
            API.graphql(graphqlOperation(graphQlSubscriptions.onUpdateComments)).subscribe({
                next: onUpdateComments,
                error: onAppSyncSubscriptionConnectionLost,
            }),
            API.graphql(graphqlOperation(graphQlSubscriptions.onCreateNotification)).subscribe({
                next: onCreateNotification,
                error: onAppSyncSubscriptionConnectionLost,
            }),
        ];
        return function () {
            subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        };
    });
    useEffect(function () {
        window.addEventListener('message', onThirdPartyIFrameEvent, false);
        return function () {
            window.removeEventListener('message', onThirdPartyIFrameEvent);
        };
    });
    return (React.createElement(ConnectedRouter, { history: history },
        React.createElement(AppSyncCheckConnection, null),
        React.createElement(AppLayout, { headerComponent: Header, sidebarComponent: SideMenu, backgroundColor: Colors.ALTERNATE_SECONDARY },
            React.createElement(FeatureNotifications, null),
            React.createElement(Header, null),
            React.createElement(SideMenu, null),
            React.createElement(ToastManager, null),
            React.createElement(Loader, null),
            React.createElement(CreateSymphonyCsCampaignModal, null),
            React.createElement(NewUserExperience, null),
            React.createElement(CustomViewModal, null),
            React.createElement(CommentsModal, null),
            React.createElement(CsDiffScreenModal, null),
            composerEnabled && React.createElement(ThirdPartyIFrame, null),
            React.createElement(Switch, null,
                React.createElement(Redirect, { from: "/", exact: true, to: Urls.SHOW_ALL_CAMPAIGNS }),
                React.createElement(Route, { path: Urls.SHOW_ALL_CAMPAIGNS, component: MainViewPage }),
                React.createElement(Route, { path: Urls.NEW_PACKAGE, component: MainViewPage }),
                React.createElement(Route, { path: Urls.IN_STORE_UTILIZATION, component: InStoreUtilizationPage }),
                React.createElement(Route, { path: Urls.UTILIZATION, component: UtilizationPage }),
                React.createElement(Route, { path: Urls.DASHBOARD, component: DashboardPage }),
                React.createElement(Route, { path: "".concat(Urls.EDIT_PACKAGE, "/:id"), component: MainViewPage }),
                React.createElement(Route, { path: "".concat(Urls.CLONE_PACKAGE, "/:id"), component: MainViewPage }),
                React.createElement(Route, { path: "".concat(Urls.CAMPAIGN_AUDIT_LOG, "/:id"), component: CampaignAuditLogPage }),
                React.createElement(Route, { path: "".concat(Urls.PACKAGE_AUDIT_LOG, "/:id"), component: PackageAuditLogPage }),
                React.createElement(Route, { path: Urls.METADATA_AUDIT_LOG, component: MetadataAuditLogPage }),
                React.createElement(Route, { path: Urls.METADATA_EDITOR, component: MetadataEditor }),
                React.createElement(Route, { path: Urls.IMPORT, component: BulkImporter }),
                React.createElement(Route, { path: Urls.ADD_BOARD_ANNOUNCEMENT, component: NewAnnouncementPage }),
                React.createElement(Route, { path: "".concat(Urls.EDIT_BOARD_ANNOUNCEMENT, "/:id"), component: EditAnnouncementPage }),
                React.createElement(Route, { path: Urls.BULLETIN_BOARD, component: BulletinBoardPage }),
                React.createElement(Route, { path: Urls.NOTIFICATIONS, component: NotificationsPage }),
                React.createElement(Route, { path: Urls.RATE_CARD_LIST, component: RateCardListPage }),
                React.createElement(Route, { path: Urls.RATE_CARD_NEW, component: RateCardNewPage }),
                React.createElement(Route, { path: "".concat(Urls.RATE_CARD_EDIT, "/:id"), component: RateCardEditPage }),
                React.createElement(Route, { path: "".concat(Urls.RATE_CARD_CLONE, "/:id"), component: RateCardClonePage }),
                React.createElement(Route, { path: "".concat(Urls.RATE_CARD_ASSETS, "/:id"), component: RateCardAssetsPage }),
                React.createElement(Route, { path: Urls.ERROR, render: function () { return React.createElement("h2", null, "The requested URL is not available"); } }),
                React.createElement(Route, { path: "".concat(Urls.REDIRECT_LANDING, "/:landingType"), component: Redirecter }),
                React.createElement(Route, { path: Urls.REDIRECT_LANDING, component: Redirecter }),
                React.createElement(Route, { render: function () { return (React.createElement("div", null, "The requested URL is not found")); } })))));
};
export default App;

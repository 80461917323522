import { connect } from 'react-redux';
import RateCardAssetsListButtons from './RateCardAssetsListButtons';
import {
  onRateCardAssetDelete,
} from '../../actionCreators';
import { Language } from '../../constants';

export const mapDispatchToProps = (dispatch) => {
  return {
    onDelete: (asset) => {
      /* eslint-disable no-alert */
      if (window.confirm(Language.RATE_CARD_ASSET_DELETE_CONFIRMATION)) {
        dispatch(onRateCardAssetDelete(asset));
      }
    },
  };
};

export default connect(null, mapDispatchToProps)(RateCardAssetsListButtons);

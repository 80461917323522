import { connect } from 'react-redux';
import {
  onCreateCsCampaignCloseDiffModal,
  onCreateCsCampaignChangeDiffViewMode,
} from '../../actionCreators';
import CsDiffScreenModal from './CsDiffScreenModal';

export const mapStateToProps = (state) => {
  const {
    CreateSymphonyCsCampaign: {
      isDiffModalOpen,
      campaignDiff,
      diffViewMode,
    },
  } = state;

  return {
    isOpen: isDiffModalOpen,
    diffData: campaignDiff,
    diffViewMode,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal() {
      dispatch(onCreateCsCampaignCloseDiffModal());
    },
    onChangeViewMode(viewMode) {
      dispatch(onCreateCsCampaignChangeDiffViewMode(viewMode));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CsDiffScreenModal);

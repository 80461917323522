import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '@amzn/meridian/loader';
import Button from '@amzn/meridian/button';
import classnames from 'classnames';
import Toggle from '@amzn/meridian/toggle';
import Icon from '@amzn/meridian/icon';
import refreshTokens from '@amzn/meridian-tokens/base/icon/refresh';
import Textarea from '@amzn/meridian/textarea';
import styles from '../CommentsList.module.scss';
import { CommentsValidation } from '../../../constants';

const isLengthTooSmall = (text) => text.length < CommentsValidation.MIN_LENGTH;
const isLengthTooBig = (text) => text.length > CommentsValidation.MAX_LENGTH;

const CommentsPanel = (props) => {
  const [commentText, setCommentText] = useState('');
  const [lengthCounter, setLengthCounter] = useState(0);

  const {
    campaignId,
    isPosting,
    isAutoScrollingEnabled,
    onToggleAutoScrolling,
    onRefreshComments,
    onAddComment,
  } = props;

  const postingInProgressBlock = isPosting
    ? (
      <div className={styles.postingLoaderContainer}>
        <Loader
          type="circular"
          size="medium"
        />
        <span className={styles.postingText}>Posting....</span>
      </div>
    )
    : null;

  const controlsBehavior = (
    <div className={classnames([styles.controls, styles.controlsSystem])}>
      <div className={styles.control}>
        <Toggle checked={isAutoScrollingEnabled} onChange={onToggleAutoScrolling}>
          Auto-scrolling
        </Toggle>
      </div>
    </div>
  );

  const getReloadCommentsButton = () => {
    return (
      <Button
        type="link"
        onClick={onRefreshComments}
        disabled={isPosting}
      >
        <Icon tokens={refreshTokens}>
          Refresh comments
        </Icon>
      </Button>
    );
  };

  const getAddButton = () => {
    const postComment = () => {
      onAddComment({
        campaignId,
        message: commentText,
      });
      setCommentText('');
    };

    const isDisabled = isPosting || isLengthTooSmall(commentText) || isLengthTooBig(commentText);

    return (
      <Button
        onClick={postComment}
        disabled={isDisabled}
      >
        Save comment
      </Button>
    );
  };

  const getControlsCommon = () => {
    const reloadCommentsButton = getReloadCommentsButton();
    const addButton = getAddButton();
    const lengthCounterMessage = lengthCounter
      ? (
        <span className={styles.lengthCounterMessage}>
          {lengthCounter}
          /
          {CommentsValidation.MAX_LENGTH}
        </span>
      ) : null;

    return (
      <div className={styles.controls}>
        <div className={classnames([styles.control, styles.controlLengthCounter])}>
          {lengthCounterMessage}
        </div>
        <div className={styles.control}>
          {reloadCommentsButton}
        </div>
        <div className={styles.control}>
          {addButton}
        </div>
      </div>
    );
  };

  const controlsCommon = getControlsCommon();

  const setComment = (text) => {
    setLengthCounter(text.length);
    setCommentText(text);
  };

  return (
    <>
      <div className={styles.commentsWrapper}>
        <Textarea
          value={commentText}
          onChange={setComment}
          placeholder="Write something here..."
        />
      </div>
      <div className={styles.controlsContainer}>
        {controlsBehavior}
        {controlsCommon}
        {postingInProgressBlock}
      </div>
    </>
  );
};

CommentsPanel.propTypes = {
  campaignId: PropTypes.string.isRequired,
  isPosting: PropTypes.bool.isRequired,
  isAutoScrollingEnabled: PropTypes.bool.isRequired,
  onToggleAutoScrolling: PropTypes.func.isRequired,
  onRefreshComments: PropTypes.func.isRequired,
  onAddComment: PropTypes.func.isRequired,
};

export default CommentsPanel;

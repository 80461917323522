import { connect } from 'react-redux';
import UtilityPanel from './UtilityPanel';
import {
  onUtilizationFilterChange,
} from '../../../../actionCreators';
import { getDependentOptionsForUtilization } from '../../../../helpers/getMetadataOptions';

export const mapStateToProps = (state) => {
  const {
    Meta: {
      metadata,
    },
    UtilizationPage: {
      selectedFilters,
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  const filterOptions = getDependentOptionsForUtilization(metadata, selectedBusiness);

  return {
    filterOptions,
    selectedFilters,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onFilterChange(filterOptionKey, filterOptionValue, selectedFilter) {
      dispatch(onUtilizationFilterChange(filterOptionKey, filterOptionValue, selectedFilter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilityPanel);

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Notifications.module.scss';

const FeatureNotification = ({ notifications, onClose }) => {
  let lastVersion = 0;

  const notificationsList = notifications.map((notification) => {
    const {
      announcement, link = '', linkText = '', version, isInternal = false,
    } = notification;

    const hasLink = +(link && link.length > 0);
    const readMoreLink = hasLink
      ? (
        <p className={styles.readMoreLink}>
          <a
            href={link}
            target={isInternal ? '' : '_blank'}
            rel="noopener noreferrer"
          >
            {linkText || 'Read more about this update'}
          </a>
        </p>
      )
      : null;

    if (version > lastVersion) {
      lastVersion = version;
    }

    return (
      <li
        className={styles.notificationContainer}
        key={version}
      >
        <div className={styles.messageContainer}>
          {/* eslint-disable react/no-danger */}
          <p
            className={styles.announcement}
            dangerouslySetInnerHTML={{ __html: announcement }}
          />
        </div>
        {readMoreLink}
      </li>
    );
  });

  return notifications.length > 0
    ? (
      <div className={styles.container}>
        <ul className={styles.notifications}>
          {notificationsList}
        </ul>
        <button
          type="button"
          onClick={onClose(lastVersion)}
          className={styles.closeButton}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
    : null;
};

FeatureNotification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    announcement: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    link: PropTypes.string,
    isInternal: PropTypes.bool,
  })).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeatureNotification;

import formValueSelector from 'redux-form/lib/formValueSelector';
import moment from 'moment-timezone';
import {
  BusinessTypes,
  CampaignColumns,
  ControlsWithHintLinks,
  Delimiters,
  Form,
  FreshDEColumns,
  NeedsInventoryAwarenessFilterFields,
  PageTypes,
  VerticalTypes,
  VSSMExclusiveSlots,
  WFMColumns,
} from '../constants';
import { shouldDisableSovForSovType } from './common';
import { RateCardColumns } from '../constants/rateCard';
import { isWebsiteOmnichannelViewMode } from '../selectors/sitewide';
import { getOmnichannelPackageTab } from '../selectors/packageTabs';
import {
  getFirstOnlineCampaignIndex,
} from './campaign';
import { isPackageColumn } from './package';

export const getCampaignFieldsByBusiness = (business) => {
  switch (business) {
    case BusinessTypes.FRESH.name:
      return CampaignColumns;
    case BusinessTypes.WFM.name:
    case BusinessTypes.WFMC_NA.name:
    case BusinessTypes.NA_3P.name:
      return WFMColumns;
    case BusinessTypes.FRESH_DE.name:
      return FreshDEColumns;
    default:
      return CampaignColumns;
  }
};

export const getRateCardCampaignFieldsByBusiness = () => {
  return RateCardColumns;
};

export const getDependentChildFields = (fieldName, metaFields) => {
  if (getDependentChildFields.cachedFields[fieldName]) {
    return getDependentChildFields.cachedFields[fieldName];
  }

  const fieldsToProcess = [];
  Object.keys(metaFields).forEach((currentFieldName) => {
    const currentField = metaFields[currentFieldName];
    if (
      currentField.dependsOn
      && Array.isArray(currentField.dependsOn)
      && (currentField !== fieldName && currentField.dependsOn.includes(fieldName))
    ) {
      fieldsToProcess.push(currentFieldName);
    }
  });

  getDependentChildFields.cachedFields[fieldName] = fieldsToProcess;

  return fieldsToProcess;
};
getDependentChildFields.cachedFields = {};

export const isSafeToAutoChangeField = (
  fieldName,
  formFieldsMetadata = {},
  initialFieldsValues = {},
) => {
  const fieldMetadata = formFieldsMetadata[fieldName];
  if (!Object.keys(formFieldsMetadata).length || !fieldMetadata) {
    return true;
  }

  const { visited } = fieldMetadata;
  const initialFieldValueExists = initialFieldsValues[fieldName]
    && initialFieldsValues[fieldName] !== '';

  return (!visited && !initialFieldValueExists);
};

export const isSovAutoChangeAllowed = (
  fieldName,
  formFieldsMetadata = {},
  initialFieldsValues = {},
  formValues = {},
) => {
  const fieldsToWatch = [
    CampaignColumns.PLACEMENT.name,
    CampaignColumns.PAGE_GROUP.name,
    CampaignColumns.SOV_TYPE.name,
  ];

  const isSovWatchCriteriaMet = fieldsToWatch.includes(fieldName)
    && !shouldDisableSovForSovType(formValues[CampaignColumns.SOV_TYPE.name]);

  return isSovWatchCriteriaMet && isSafeToAutoChangeField(
    CampaignColumns.SOV.name,
    formFieldsMetadata,
    initialFieldsValues,
  );
};

export const shouldShowHintLink = (fieldName) => ControlsWithHintLinks.includes(fieldName);
export const isValidStartDate = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  if (startDate.isSame(endDate, 'days')) {
    return true;
  }

  return startDate.isBefore(endDate, 'days');
};

export const isNumericInput = (value) => {
  if (value === null || value === undefined || value === '') {
    return false
  }
  return !isNaN(value) && isFinite(value)
}

export const isMissingInventoryAwarenessFields = (missingFields) => {
  return missingFields.find(
    (field) => NeedsInventoryAwarenessFilterFields.includes(field),
  );
};

export const isVSSMExclusiveSlot = (
  vertical, pageType, desktopSlot, mobileSlot,
) => {
  if (pageType === PageTypes.CATEGORY && vertical === VerticalTypes.ALCOHOL) {
    return false;
  }
  return !!VSSMExclusiveSlots.find((value) => value.PAGE_TYPE === pageType
      && (value.DESKTOP_SLOT_NAME === desktopSlot
      || value.MOBILE_SLOT_NAME === mobileSlot));
};

export const getFormFullFieldName = (groupNameParts = [], fieldName) => {
  return [...groupNameParts, fieldName].join(Delimiters.DOT);
}

export const createNestedFormField = (groupName, column) => {
  return {
    ...column,
    name: getFormFullFieldName(groupName, column.name),
  }
};

export const getPackageFormGroupName = (campaignIndex) => {
  return [
    'campaigns',
    campaignIndex,
  ];
}

const createBaseCampaignColumn = (campaignIndex, column) => {
  return createNestedFormField(
    getPackageFormGroupName(campaignIndex),
    column
  );
};

export const createCampaignFieldForIndex = (campaignIndex) => (column) => {
  return createBaseCampaignColumn(campaignIndex, column);
}

export const extractFieldGroupParts = (field) => {
  if (!field || !field.length) {
    return [];
  }

  if (field.includes(Delimiters.DOT)) {
    const fieldParts = field.split(Delimiters.DOT);
    const fieldName = fieldParts.pop();

    return [fieldParts.join(Delimiters.DOT), fieldName];
  }

  return ['', field];
}

export const getCurrentPackageActiveTab = (state) => {
  if (isWebsiteOmnichannelViewMode(state)) {
    return getOmnichannelPackageTab(state);
  }
  const selector = formValueSelector(Form.NAME);
  const campaigns = selector(state, 'campaigns') || [];

  return getFirstOnlineCampaignIndex(campaigns);
};

export const getFieldNameWithFullPath = (fieldName, groupName) => {
  if (!groupName || isPackageColumn(fieldName)) {
    return fieldName;
  }

  return `${groupName}${Delimiters.DOT}${fieldName}`;
};
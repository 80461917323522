import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';

import SingleSelect from '../../StyledInputs/SingleSelect';

import styles from '../MetadataEditor.module.scss';

const QuickCreateModal = ({
  isOpen,
  onModalClose,
  fields,
  onSave,
}) => {
  const [pageType, setPageType] = useState('');
  const [pageName, setPageName] = useState('');
  const [pageID, setPageID] = useState('');
  const [pageGroup, setPageGroup] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const areFieldsMissing = () => (!pageType || !pageName || !pageID || !pageGroup);
  const onPerformSave = () => {
    if (areFieldsMissing()) {
      setErrorMessage('Some fields are missing');
      return;
    }
    onSave({
      pageType,
      pageName,
      pageID,
      pageGroup,
    });
  };

  const onClose = () => {
    onModalClose();
    setPageType('');
    setPageName('');
    setPageID('');
    setPageGroup('');
    setErrorMessage('');
  };

  const onSetPageType = (value) => {
    setPageType(value);
    setPageName('');
    setPageID('');
    setPageGroup('');
    setErrorMessage('');
  };

  const onSetPageName = (value) => {
    setPageName(value);
    setPageID('');
    setPageGroup('');
    setErrorMessage('');
  };

  const onSetPageID = (value) => {
    setPageID(value);
    setErrorMessage('');
  };

  const onSetPageGroup = (value) => {
    setPageGroup(value);
    setErrorMessage('');
  };

  const getPageNameOptions = () => {
    if (!pageType) { return []; }
    if (!fields.pageName.options[pageType]) { return []; }
    return fields.pageName.options[pageType];
  };

  const getPageIDOptions = () => {
    if (!pageName) { return []; }
    if (!fields.pageID.options[pageName]) { return []; }
    return fields.pageID.options[pageName];
  };

  const getPageGroupOptions = () => {
    if (!pageName) { return []; }
    if (!fields.pageGroup.options[pageName]) { return []; }
    return fields.pageGroup.options[pageName];
  };

  if (!fields) { return null; }

  return (
    <Modal open={isOpen}>
      <div className={styles.modalContents}>
        <div>
          <div className={styles.header}>
            Page Type
          </div>
          <div>
            <SingleSelect
              options={fields.pageType.options}
              onInputChange={onSetPageType}
              inputValue={pageType}
              size="small"
              allowOverride
            />
          </div>
        </div>
        <div className={styles.header}>
          <div>
            Page Name
          </div>
          <div>
            <SingleSelect
              options={getPageNameOptions()}
              onInputChange={onSetPageName}
              inputValue={pageName}
              size="small"
              allowOverride
            />
          </div>
        </div>
        <div className={styles.header}>
          <div>
            Page ID
          </div>
          <div>
            <SingleSelect
              options={getPageIDOptions()}
              onInputChange={onSetPageID}
              inputValue={pageID}
              size="small"
              allowOverride
            />
          </div>
        </div>
        <div className={styles.header}>
          <div>
            Page Group
          </div>
          <div>
            <SingleSelect
              options={getPageGroupOptions()}
              onInputChange={onSetPageGroup}
              inputValue={pageGroup}
              size="small"
              allowOverride
            />
          </div>
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.warning}>
          {errorMessage}
        </div>
      </div>
      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button type="secondary" size="small" onClick={onClose}>Cancel</Button>
          <Button
            type="secondary"
            size="small"
            data-testid="saveButton"
            onClick={onPerformSave}
            disabled={areFieldsMissing()}
          >
            Save
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

QuickCreateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    pageName: PropTypes.object,
    pageType: PropTypes.object,
    pageID: PropTypes.object,
    pageGroup: PropTypes.object,
  }),
};

QuickCreateModal.defaultProps = {
  fields: null,
};

export default QuickCreateModal;

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '@amzn/meridian/button';
import Table, { TableRow, TableCell } from '@amzn/meridian/table';
import styles from './BulletinBoardDashboardView.module.scss';
import { Urls, Language, BulletinBoard } from '../../../constants';

const BulletinBoardDashboardView = (props) => {
  const history = useHistory();
  const {
    announcements,
  } = props;

  const showBulletinBoard = () => {
    history.push(`${Urls.BULLETIN_BOARD}`);
  };

  const formattedList = announcements.map((announcement) => {
    const {
      id,
      message,
      startDateTime,
      endDateTime,
      isSticky,
    } = announcement;

    const stickyBadge = isSticky
      ? <span className={styles.badge}>{Language.BULLETIN_BOARD_STICKY_BADGE}</span>
      : null;

    return (
      <TableRow
        key={id}
        highlightOnHover
        highlight={isSticky}
      >
        <TableCell
          alignmentVertical="top"
        >
          <p className={styles.dates}>
            Effective dates:
            {' '}
            {startDateTime}
            {' '}
            -
            {' '}
            {endDateTime}
            {stickyBadge}
          </p>
          {/* eslint-disable react/no-danger */}
          <div
            className={styles.message}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </TableCell>
      </TableRow>
    );
  });

  const showAllButton = (
    <Button size="small" type="link" onClick={showBulletinBoard}>
      Show all
    </Button>
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Table
          spacing="small"
          headerRows={1}
          showStripes={false}
          showDividers
        >
          <TableRow>
            <TableCell
              alignmentVertical="top"
            >
              Last
              {' '}
              {BulletinBoard.LIMIT_MAX_ANNOUNCEMENTS}
              {' '}
              announcements (
              {showAllButton}
              )
            </TableCell>
          </TableRow>
          {formattedList}
        </Table>
      </div>
    </div>
  );
};

BulletinBoardDashboardView.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    message: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
  })).isRequired,
};

export default BulletinBoardDashboardView;

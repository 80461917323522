import { onRateCardUpdate } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const {
    RateCard: { cards },
  } = getState();
  const { payload: { rateCardId } } = action;

  const rateCardToMark = cards.find((card) => card.id === rateCardId);
  if (!rateCardToMark) {
    return next(action);
  }

  dispatch(onRateCardUpdate({
    ...rateCardToMark,
    isAssetPublished: true,
  }));

  return next(action);
};

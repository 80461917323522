export const getError = (error) => {
  return error && error.errors && error.errors.length
    ? error.errors[0].message
    : error;
}

export const parseGraphQLResponse = (response, queryName) => {
  if (!response?.data?.[queryName]) {
    throw new Error(`No response for ${queryName}()`);
  }

  const parsedResponse = response.data[queryName];
  if (parsedResponse.hasOwnProperty('items')) {
    const { items, nextToken = null } = parsedResponse;
    if (!items) {
      throw new Error(`Parsed response from ${queryName}() is empty`);
    }

    return { items, nextToken };
  }

  return parsedResponse;
}
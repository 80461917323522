import React from 'react';
import Utilization from '../Utilization';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const UtilizationPage = () => {
  return <Utilization />;
};

export default WithHistoryTiming(UtilizationPage, { pageName: PageNames.UTILIZATION });

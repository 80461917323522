import React from 'react';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';
import InStoreUtilization from '../InStoreUtilization';

const InStoreUtilizationPage = () => <InStoreUtilization />;

export default WithHistoryTiming(
  InStoreUtilizationPage,
  {
    pageName: PageNames.IN_STORE_UTILIZATION,
  },
);

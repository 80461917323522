import { connect } from 'react-redux';
import Header from './Header';
import {
  Urls, DeployLocations, MetricTypes, MetricLocations, MetricNames,
} from '../../constants';
import { AddIcon } from '../Icons';
import { toggleSideMenu } from '../../actionCreators';
import { getDeployedLocation } from '../../helpers/env';
import { submitStringMetric } from '../../metrics';
import { isBusinessFreshTraffic, isReadOnlyMode } from '../../selectors/sitewide';

export const getLinkList = (isReadOnlyMode, isTrafficMode) => {
  const linkList = [
    {
      name: 'Campaigns',
      route: Urls.SHOW_ALL_CAMPAIGNS,
      shouldShow: true,
    },
    {
      name: 'Utilization',
      route: Urls.UTILIZATION,
      shouldShow: !isTrafficMode,
    },
    {
      name: 'Create',
      route: Urls.NEW_PACKAGE,
      iconComponent: AddIcon,
      shouldShow: !isReadOnlyMode,
    },
  ];

  return linkList.filter(({ shouldShow }) => shouldShow);
};

const getEnvironmentLabel = () => {
  const deployedLocation = getDeployedLocation();

  const allowedLocations = [
    DeployLocations.DEV,
    DeployLocations.BETA,
  ];

  return allowedLocations.includes(deployedLocation) ? deployedLocation : '';
};

const mapStateToProps = ((state) => {
  const { router: { location: { pathname: route } } } = state;

  return {
    route,
    linkList: getLinkList(isReadOnlyMode(state), isBusinessFreshTraffic(state)),
    environmentLabel: getEnvironmentLabel(),
  };
});

const mapDispatchToProps = (dispatch) => {
  return {
    onMenuButtonClick() {
      submitStringMetric(MetricNames.MENU_BUTTON,
        {
          location: MetricLocations.HEADER,
          type: MetricTypes.CLICK,
        });
      dispatch(toggleSideMenu());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import { connect } from 'react-redux';
import Utilization from './Utilization';
import { UtilizationViewMode } from '../../constants/utilization';

export const mapStateToProps = (state) => {
  const {
    UtilizationPage: {
      viewMode,
    },
  } = state;

  return {
    isCalendarViewMode: viewMode === UtilizationViewMode.CALENDAR.name,
  };
};

export default connect(mapStateToProps)(Utilization);

import { connect } from 'react-redux';
import {
  onCreateCsCampaignSetCampaignData,
} from '../../../actionCreators';

import Objective from './Objective';

export const mapStateToProps = (state) => {
  const { CreateSymphonyCsCampaign: { creativeData: { objective = '' }, objectives } } = state;
  return {
    objective,
    objectives,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onChange(objective) {
      dispatch(onCreateCsCampaignSetCampaignData({ creativeData: { objective } }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Objective);

import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';
import {
  getEndDatePickerPresets,
  getStartDatePickerPresets,
  isNotTuesday,
  isNotWednesday,
} from '../../../../../helpers/dateTime';

const Scheduling = ({ campaignColumns, currentTab }) => {
  const campaignField = createCampaignFieldForIndex(currentTab);

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>In-Store Scheduling</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField
                {...campaignField(campaignColumns.START_DATE)}
                presets={getStartDatePickerPresets()}
                isDisabledDate={isNotWednesday}
              />
            </div>
            <div className={styles.formControl}>
              <FormField
                {...campaignField(campaignColumns.END_DATE)}
                presets={getEndDatePickerPresets()}
                isDisabledDate={isNotTuesday}
              />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.START_TIME)} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.END_TIME)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignField(campaignColumns.TIMEZONE_ID)} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl,
              styles.fullSizeFormControl)}>
              <FormField {...campaignField(
                campaignColumns.SCHEDULING_INSTRUCTIONS)} />
            </div>
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
Scheduling.propTypes = formCampaignColumns;

export default Scheduling;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import closeLargeTokens from '@amzn/meridian-tokens/base/icon/close-large';
import styles from './RateCardContainer.module.scss';
import RateCardContainerHeader from './RateCardContainerHeader';
import RateCardAssetsList from '../RateCardAssetsList';
import RateCardForm from '../RateCardForm';
import RateCardReportModal from '../RateCardReportModal';
import history from '../../history';
import {
  Urls,
  Language,
  AuditLogTypes,
} from '../../constants';
import { rateCardProps } from '../../proptypes';
import AuditLogPage from '../AuditLogPage';
import { RateCardTabsList } from '../../constants/rateCard';

const RateCardContainer = (props) => {
  const {
    isNew,
    isClone,
    isLoading,
    loadedRateCard,
    currentTab,
    shouldShowOnCloseConfirmation,
    onLoadAssets,
  } = props;

  useEffect(() => {
    const shouldLoadAssetsList = !isNew && !isClone && (loadedRateCard && loadedRateCard.id);
    if (shouldLoadAssetsList) {
      onLoadAssets(loadedRateCard.id);
    }
  }, [isClone, isNew, loadedRateCard, onLoadAssets]);

  const onModalClose = () => {
    history.push({ pathname: Urls.RATE_CARD_LIST });
  };

  const onCloseButton = () => {
    if (shouldShowOnCloseConfirmation
      && !window.confirm(Language.ON_MODAL_FORM_CLOSE_CONFIRMATION)) {
      return;
    }
    onModalClose();
  };

  const buttonGoBack = () => (
    <span className={styles.closeButton}>
      <Button type="icon" onClick={onCloseButton} backdropColor="white">
        <Icon tokens={closeLargeTokens}>Close</Icon>
      </Button>
    </span>
  );

  if (isLoading) {
    return <div className={styles.overlay} />;
  }

  const getForm = () => {
    return (
      <RateCardForm
        isNew={isNew}
        isClone={isClone}
        loadedRateCard={loadedRateCard}
        onModalClose={onModalClose}
      />
    );
  };

  const getAssets = () => {
    const rateCardId = loadedRateCard && loadedRateCard.id
      ? loadedRateCard.id
      : null;
    return (
      <RateCardAssetsList
        rateCardId={rateCardId}
      />
    );
  };

  const getAuditLog = () => {
    return (
      <div className={styles.auditLogContainer}>
        <AuditLogPage
          campaignId={loadedRateCard.id}
          auditLogType={AuditLogTypes.RATE_CARD}
        />
      </div>
    );
  };

  const getComponentByTab = () => {
    switch (currentTab) {
      case RateCardTabsList.RATE_CARD.name:
        return getForm();
      case RateCardTabsList.ASSETS.name:
        return getAssets();
      case RateCardTabsList.AUDIT_LOG.name:
        return getAuditLog();
      default:
        return getForm();
    }
  };

  const goBackButton = buttonGoBack();
  const component = isNew ? getForm() : getComponentByTab();

  const shouldHideHeader = !isNew && !isClone && !isLoading && !loadedRateCard;

  const header = !shouldHideHeader
    ? (
      <RateCardContainerHeader
        loadedRateCard={loadedRateCard}
      />
    )
    : null;

  return (
    <>
      <div className={styles.overlay} />
      <div className={styles.wrapper}>
        {goBackButton}
        <div className={styles.wrapperInner}>
          {header}
          {component}
          <RateCardReportModal />
        </div>
      </div>
    </>
  );
};

RateCardContainer.propTypes = {
  loadedRateCard: rateCardProps,
  currentTab: PropTypes.string.isRequired,
  isNew: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isClone: PropTypes.bool,
  shouldShowOnCloseConfirmation: PropTypes.bool.isRequired,
  onLoadAssets: PropTypes.func.isRequired,
};

RateCardContainer.defaultProps = {
  isClone: false,
  loadedRateCard: null,
};

export default RateCardContainer;

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@amzn/meridian/tooltip';
import Text from '@amzn/meridian/text';
import Icon from '@amzn/meridian/icon';
import alertKnockoutTokens from '@amzn/meridian-tokens/base/icon/alert-knockout';
import { HashLink as Link } from 'react-router-hash-link';
import styles from '../UtilizationSlots/UtilizationSlots.module.scss';
import siteWideStyle from '../../../styles/sitewide.module.scss';
import {
  BusinessTypes,
  Language,
  UTILIZATION_THRESHOLDS,
  UTILIZATION_THRESHOLDS_ABS,
} from '../../../constants';
import { slotUtilizationProps } from '../../../proptypes';

// TODO: doesn't seem like color legend is used or will be used in the near future.
// check if we can delete isFreshBusiness flag and clean up constants
export const getCellStyle = (utilizationDetails, isFreshBusiness) => {
  const { totalSOV, numberOfCampaigns } = utilizationDetails;

  const business = isFreshBusiness ? BusinessTypes.FRESH.name : BusinessTypes.WFM.name;

  if (!UTILIZATION_THRESHOLDS[business]) {
    return styles.cellContainer;
  }
  if (
    totalSOV >= UTILIZATION_THRESHOLDS[business].OVERUTILIZED
    || numberOfCampaigns >= UTILIZATION_THRESHOLDS_ABS[business].OVERUTILIZED
  ) {
    return styles.redCellContainer;
  }
  if (
    totalSOV >= UTILIZATION_THRESHOLDS[business].WARNING
    || numberOfCampaigns >= UTILIZATION_THRESHOLDS_ABS[business].WARNING
  ) {
    return styles.yellowCellContainer;
  }

  return styles.cellContainer;
};

const getToolTip = (params) => {
  return params.targetedSOV !== 0
    ? (
      <div className={styles.tooltip}>
        <Tooltip
          position="top"
          title={Language.UTILIZATION_SOV_WARNING}
        >
          <Text>
            <Icon tokens={alertKnockoutTokens} />
          </Text>
        </Tooltip>
      </div>
    )
    : null;
};

const getContent = (utilizationDetails, isAbsStatsValuesOnly) => {
  const { totalSOV, numberOfCampaigns } = utilizationDetails;

  return isAbsStatsValuesOnly
    ? numberOfCampaigns
    : `${totalSOV}% (${numberOfCampaigns})`;
};

const UtilizationCell = (props) => {
  const {
    utilization,
    isAbsStatsValuesOnly,
    isFreshBusiness,
    currentDate,
    link,
  } = props;

  const utilizationDetails = utilization[currentDate];

  const content = getContent(utilizationDetails, isAbsStatsValuesOnly);
  const tooltip = getToolTip(utilizationDetails);

  return (
    <td
      key={currentDate}
      className={getCellStyle(utilizationDetails, isFreshBusiness)}
    >
      <div className={styles.cell}>
        <Link
          smooth
          to={link}
          className={siteWideStyle.link}
        >
          {content}
        </Link>
        {tooltip}
      </div>
    </td>
  );
};

UtilizationCell.propTypes = {
  isAbsStatsValuesOnly: PropTypes.bool.isRequired,
  isFreshBusiness: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  currentDate: PropTypes.string.isRequired,
  utilization: PropTypes.shape({
    slotUtilizationProps,
  }).isRequired,
};

export default UtilizationCell;

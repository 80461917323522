import Loader from '@amzn/meridian/loader';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Heading from '@amzn/meridian/heading';
import RadioButton from '@amzn/meridian/radio-button';
import readFile from '../../helpers/readFile';
import BulkImportPreview from './preview';
import styles from './BulkImporter.module.scss';
import { BulkImportText, BulkImportTypes, BulkImportValidationModes } from '../../constants';

const BulkImportPage = (props) => {
  const {
    isProcessed,
    onProcessFile,
    validationMode,
    onValidationModeChange,
    isRateCardCompatibleMode,
  } = props;

  const [isDragOver, setIsDragOver] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const getLoader = () => showLoading
    ? <div className={styles.loader}>
        <Loader size={'large'}/>
      </div>
    : null;

  const handleFile = (file) => {
    setShowLoading(true);
    const processFile = (fileContents) => {
      onProcessFile(fileContents, BulkImportTypes.FILE);
    };

    readFile(file, processFile);
  };

  const handleDrop = (e) => {
    setIsDragOver(false);
    e.preventDefault();
    e.stopPropagation();

    const { dataTransfer: { files } } = e;
    if (files && files.length) {
      handleFile(files[0]);
    }
  };

  const handleDragOver = (e) => {
    // allow files to be dropped on target
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  if (isProcessed) {
    if (showLoading) {
      setShowLoading(false);
    }
    return (
      <BulkImportPreview
        isRateCardCompatibleMode={isRateCardCompatibleMode}
      />
    );
  }
  const classes = classnames({
    [styles.dragArea]: true,
    [styles.dragOver]: isDragOver,
  });

  const onInputFileChange = (evt) => {
    handleFile(evt.target.files[0]);
  };

  const getValidationModeList = () => {
    const optionsList = Object.entries(BulkImportValidationModes).map((mode) => {
      const [name, { display }] = mode;
 
      return (
        <li key={name}>
          <RadioButton
            checked={name === validationMode}
            onChange={onValidationModeChange}
            name="validationModeSwitch"
            value={name}
          >
            {display}
          </RadioButton>
        </li>
      );
    });
 
    return (
      <ul className={styles.validationModesList}>
        {optionsList}
      </ul>
    );
  };

  if (isRateCardCompatibleMode) {
    return null;
  }

  const validationsModeList = getValidationModeList();

  return (
    <div className={styles.dragAreaWrapper}>
      <div
        className={classes}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        data-testid="inner"
      >
        <Heading level={3}>Bulk Upload</Heading>
        <p>
          {BulkImportText.BULK_IMPORT_LANDING_DESCRIPTION}
          <a href="https://w.amazon.com/bin/view/F3_Marketing_Guide/Omnichannel_Marketing_Calendar/BulkActions/"> this Wiki.</a>
        </p>
        <input type="file" onChange={onInputFileChange} />
        <div className={styles.validationControls}>
          <Heading level={4}>{BulkImportText.HEADING}</Heading>
          {validationsModeList}
        </div>
      </div>
      {getLoader()}
    </div>
  );
};

BulkImportPage.propTypes = {
  onProcessFile: PropTypes.func.isRequired,
  onValidationModeChange: PropTypes.func.isRequired,
  validationMode: PropTypes.string.isRequired,
  isProcessed: PropTypes.bool.isRequired,
  isRateCardCompatibleMode: PropTypes.bool,
};

BulkImportPage.defaultProps = {
  isRateCardCompatibleMode: false,
};

export default BulkImportPage;

import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup, { ButtonOption } from '@amzn/meridian/button-group';
import CalendarTable from '../../UtilizationCalendar/CalendarTable';
import { groupedPlacementProps } from '../../../proptypes';
import styles from './UtilizationOnlineCalendar.module.scss';
import { UtilizationSlotsViewMode } from '../../../constants/utilization';

const UtilizationOnlineCalendar = (props) => {
  const {
    slotsViewMode,
    onSwitchSlotsViewMode,
    groupedPlacements,
    showLoader,
    startTime,
    endTime,
  } = props;

  if (showLoader) {
    return <p>Loading...</p>;
  }

  const getSlotsSwitchControls = () => {
    const slotViewOptionList = Object
      .entries(UtilizationSlotsViewMode)
      .map(([, { name, label }]) => (
        <ButtonOption
          key={name}
          value={name}
        >
          {label}
        </ButtonOption>
      ));

    return (
      <ButtonGroup value={slotsViewMode} onChange={onSwitchSlotsViewMode}>
        {slotViewOptionList}
      </ButtonGroup>
    );
  };

  const slotsViewModeSwitchControls = getSlotsSwitchControls();

  return (
    <div>
      <div>
        {slotsViewModeSwitchControls}
      </div>
      <div className={styles.calendarContainer}>
        <CalendarTable
          groupedPlacements={groupedPlacements}
          startTime={startTime}
          endTime={endTime}
        />
      </div>
    </div>
  );
};

UtilizationOnlineCalendar.propTypes = {
  slotsViewMode: PropTypes.string.isRequired,
  onSwitchSlotsViewMode: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  groupedPlacements: groupedPlacementProps,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

UtilizationOnlineCalendar.defaultProps = {
  startTime: '00:00',
  endTime: '00:00',
  groupedPlacements: [],
};

export default UtilizationOnlineCalendar;

import React from 'react';
import PropTypes from 'prop-types';
import Pagination from '@amzn/meridian/pagination';

const Paginator = ({
  paginationPage,
  pageCount,
  onSetPaginationPage,
}) => {
  return (
    <Pagination
      numberOfPages={pageCount}
      onChange={onSetPaginationPage}
      currentPage={paginationPage}
      showSkipArrows={false}
      disabled={false}
    />
  );
};

Paginator.propTypes = {
  paginationPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onSetPaginationPage: PropTypes.func.isRequired,
};

export default Paginator;

import _ from 'lodash';
import { getPaginatedPackages } from 'src/selectors/gridView';
import { getTemplateString } from '../../helpers/common';
import { getMissingRequiredFields } from '../../helpers/requiredFields';
import {
  CampaignColumns,
  Delimiters,
  Language,
  MetricLocations,
  MetricTypes,
  MetricNames,
} from '../../constants';
import {
  onOpenToast,
  onEditPackage,
} from '../../actionCreators';
import { submitStringMetric } from '../../metrics';
import { getPackageColumnNames } from '../../helpers/package';
import { getCampaignColumnNames } from '../../helpers/campaign';
import { getUsername } from '../../selectors/user';

const getErrors = (pkg, columnName, flashFillValue) => {
  const errors = [];

  if (columnName === CampaignColumns.STATUS.name) {
    const missingFields = getMissingRequiredFields(pkg, flashFillValue);
    if (missingFields.length > 0) {
      errors.push(getTemplateString(Language.FLASH_FILL_VALIDATION_ERROR, {
        refMarker: pkg.refMarker,
        missingFields: missingFields.join(Delimiters.COMMA),
      }));
    }
  }

  return errors;
};

const getMetricsContext = (state) => {
  const username = getUsername(state);

  return {
    location: MetricLocations.FLASH_FILL_MIDDLEWARE,
    type: MetricTypes.USAGE,
    data: {},
    action: MetricNames.RUN_FLASH_FILL,
    username,
  };
};

export default (dispatch, getState, action, next) => {
  const state = getState();

  const {
    payload: { id, packageId, cellValue, columnName },
  } = action;

  const packages = getPaginatedPackages(state);
  let canFlashFill = false;

  submitStringMetric(MetricNames.RUN_FLASH_FILL, getMetricsContext(state));
  const packageColumns = getPackageColumnNames();
  const campaignColumns = getCampaignColumnNames();
 
  packages.forEach((pkg) => {
    const currentPackage = _.cloneDeep(pkg);
    
    const errors = getErrors(currentPackage, columnName, cellValue);
    if (errors.length > 0) {
      errors.forEach((errorMessage) => {
        dispatch(onOpenToast(errorMessage));
      });
      return false;
    }
    if (packageId && currentPackage.id === packageId || !packageId && currentPackage.id === id) {
      canFlashFill = true;
    }
    if (!canFlashFill) {
      return false;
    }
    if (packageColumns.includes(columnName) && campaignColumns.includes(columnName) && currentPackage.campaigns) {
      currentPackage.campaigns.forEach((campaign) => {
        campaign[columnName] = cellValue;
      });
      currentPackage[columnName] = cellValue;
    } else if (packageColumns.includes(columnName)) {
      currentPackage[columnName] = cellValue;
    } else if (campaignColumns.includes(columnName)) {
      currentPackage.campaigns.forEach((campaign) => {
        campaign[columnName] = cellValue;
      });
    }

    dispatch(onEditPackage(currentPackage));
  });

  return next(action);
};

import { connect } from 'react-redux';

import GridViewFilterControl from './component';

export const mapStateToProps = (state) => {
  const { filters, sorts } = state.GridViewPage;

  return { filters, sorts };
};

export default connect(mapStateToProps)(GridViewFilterControl);

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@amzn/meridian/tooltip';
import { HashLink as Link } from 'react-router-hash-link';
import styles from './PackageHeader.module.scss';
import NonOmnichannelPackageTabs from '../NonOmnichannelPackageTabs';
import OmnichannelPackageTabs from '../OmnichannelPackageTabs';
import { Language, Urls } from '../../../constants';
import { campaignProps } from '../../../proptypes';

const PackageHeader = (props) => {
  const {
    isLoading,
    isNew,
    loadedCampaign,
    safeDateFromNow,
    isAssetEditMode,
    isOmnichannelMode,
  } = props;

  if (isLoading) {
    return <p>Loading header...</p>;
  }

  const getCampaignLastChangedBlock = () => {
    if (isLoading || !loadedCampaign) {
      return null;
    }
    const { id, updatedAt } = loadedCampaign;
    const formattedDateTime = safeDateFromNow(updatedAt);

    return (
      <Tooltip
        position="top"
        title={Language.CAMPAIGN_FORM_CLICK_TO_AUDIT_LINK}
      >
        <Link
          smooth
          to={`${Urls.CAMPAIGN_AUDIT_LOG}/${id}`}
          className={styles.lastUpdatedLink}
        >
          Last updated:
          {' '}
          {formattedDateTime}
        </Link>
      </Tooltip>
    );
  };

  const getCampaignTitleBlock = () => {
    if (isLoading) {
      return null;
    }
    if (!loadedCampaign) {
      return (
        <h2 className={styles.headerTitle}>New campaign</h2>
      );
    }

    const { campaignName } = loadedCampaign;

    return (
      <h2 className={styles.headerTitle}>{campaignName}</h2>
    );
  };

  const getNonOmnichannelTabsBlock = () => {
    if (!isNew) {
      return null;
    }

    return (
      <div className={styles.tabsContainer}>
        <NonOmnichannelPackageTabs
          isAssetEditMode={isAssetEditMode}
        />
      </div>
    );
  };

  const getNonomnichannelExperience = () => {
    const tabsBlock = getNonOmnichannelTabsBlock();
    const campaignTitleBlock = getCampaignTitleBlock();
    const lastChangesBlock = getCampaignLastChangedBlock();

    return (
      <>
        {tabsBlock}
        <div className={styles.container}>
          {campaignTitleBlock}
          {lastChangesBlock}
        </div>
      </>
    );
  };
  const getOmnichannelExperience = () => {
    return (
      <div className={styles.tabsContainer}>
        <OmnichannelPackageTabs
          isAssetEditMode={isAssetEditMode}
          isNew={isNew}
        />
      </div>
    );
  };

  const getContent = () => {
    return isOmnichannelMode
      ? getOmnichannelExperience()
      : getNonomnichannelExperience();
  };

  return getContent();
};

PackageHeader.propTypes = {
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isAssetEditMode: PropTypes.bool.isRequired,
  safeDateFromNow: PropTypes.func.isRequired,
  loadedCampaign: campaignProps,
};

PackageHeader.defaultProps = {
  loadedCampaign: null,
  isNew: false,
};

export default PackageHeader;

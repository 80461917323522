import { connect } from 'react-redux';
import RateCardConfigSelector from './RateCardConfigSelector';
import { onRateCardSetConfigName } from '../../actionCreators';

export const mapStateToProps = (({ RateCard: { configName } }) => ({ configName }));
export const mapDispatchToProps = (dispatch) => ({
  onChange(configName) {
    dispatch(onRateCardSetConfigName(configName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RateCardConfigSelector);

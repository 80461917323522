/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import Button from '@amzn/meridian/button';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from "@amzn/meridian/tooltip"
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import UpdateIcon from '@material-ui/icons/Update';
import WidgetsIcon from '@material-ui/icons/Widgets';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChatIcon from '@material-ui/icons/ChatBubbleOutline';
import GradientIcon from '@material-ui/icons/Gradient';
import { EditIcon, CloneIcon, DeleteIcon } from '../Icons';
import { submitStringMetric } from '../../metrics';
import {
  MetricTypes,
  MetricLocations,
  MetricNames,
  Language,
  Urls,
} from '../../constants';
import { openCampaignModal } from '../../helpers/campaignModal';

import styles from '../GridViewCell/GridViewCell.module.scss';
import { isOnlineCampaign } from '../../helpers/campaign';

const GridViewActionCell = ({
  item,
  isPackage,
  isActionBarExpanded,
  isOmnichannelMode,
  isFreshTrafficBusiness,
  isReadOnlyMode,
  isMelodyEnabled,
  notificationsEnabled,
  contentSymphonyIntegrationEnabled,
  onDelete,
  onOpenComposer,
  onOpenMelody,
  onOpenComments,
  onOpenJSONBourne,
  onOpenContentSymphony,
}) => {
  const { id, packageId } = item;
  const history = useHistory();
  const classes = classNames({
    [styles.cell]: true,
    [styles.inactive]: true,
    [styles.left]: true,
  });

  const editPackage = () => {
    submitStringMetric(MetricNames.EDIT_PACKAGE,
      {
        location: MetricLocations.ACTION_CELL,
        type: MetricTypes.CLICK,
      });

    openCampaignModal(history, `${Urls.EDIT_PACKAGE}/${id}`);
  };

  const clonePackage = () => {
    submitStringMetric(MetricNames.CLONE_PACKAGE,
      {
        location: MetricLocations.ACTION_CELL,
        type: MetricTypes.CLICK,
      });
    openCampaignModal(history, `${Urls.CLONE_PACKAGE}/${id}`);
  };

  const deleteItem = () => {
    submitStringMetric(MetricNames.DELETE_PACKAGE,
      {
        location: MetricLocations.ACTION_CELL,
        type: MetricTypes.CLICK,
      });
    /* eslint-disable-next-line no-alert */
    if (window.confirm('Are you sure you want to delete this? This action cannot be undone.')) {
      onDelete();
    }
  };

  const campaignHistory = () => {
    submitStringMetric(MetricNames.CAMPAIGN_AUDIT_LOG,
      {
        location: MetricLocations.ACTION_CELL,
        type: MetricTypes.CLICK,
      });
    if (!isOmnichannelMode) {
      if (item.hasOwnProperty('campaigns')) {
        const firstCampaignId = item.campaigns[0].id;
        history.push(`/campaign/audit/${firstCampaignId}`);
      } else {
        history.push(`/campaign/audit/${id}`);
      }
    } else if (isPackage) {
      history.push(`/package/audit/${id}`);
    } else {
      history.push(`/campaign/audit/${id}`);
    }
  }

  const getButton = (buttonConfig) => {
    const {
      icon: Icon,
      onClick,
      hintName,
      position = 'right',
      testId,
      iconCustomStyle,
      disabled = false,
    } = buttonConfig;
    const iconBaseStyle = disabled ? { color: 'grey' } : { color: 'black' };

    const iconStyle = {
      ...iconBaseStyle,
      ...iconCustomStyle,
    };

    return (
      <Tooltip
        key={testId}
        position={position}
        title={hintName}
      >
        <Button
          size="small"
          type="link"
          onClick={onClick}
          data-testid={testId}
          disabled={disabled}
          className={styles.actionButton}
        >
          <Icon style={iconStyle}/>
        </Button>
      </Tooltip>
    );
  };

  const getButtonsList = () => {
    const buttonList = [];

    const modifyButtons = [
      {
        icon: EditIcon,
        onClick: editPackage,
        testId: 'edit',
        hintName: Language.ACTION_BUTTON_EDIT,
        shouldShow: !isReadOnlyMode,
      },
      {
        icon: CloneIcon,
        onClick: clonePackage,
        testId: 'clone',
        hintName: Language.ACTION_BUTTON_CLONE,
        shouldShow: !isReadOnlyMode,
      },
      {
        icon: DeleteIcon,
        onClick: deleteItem,
        testId: 'delete',
        hintName: Language.ACTION_BUTTON_DELETE,
        shouldShow: !isReadOnlyMode,
      },
    ];

    const integrationButtons = [
      {
        icon: ImageIcon,
        onClick: onOpenComposer,
        testId: 'composer',
        hintName: Language.ACTION_BUTTON_COMPOSER,
        disabled: isOmnichannelMode && !isOnlineCampaign(item),
        shouldShow: !isReadOnlyMode,
      },
      {
        icon: WidgetsIcon,
        onClick: onOpenJSONBourne,
        testId: 'JSONBourne',
        hintName: Language.ACTION_BUTTON_JSON_BOURNE,
        disabled: isOmnichannelMode && !isOnlineCampaign(item),
        shouldShow: !isReadOnlyMode,
      },
      {
        icon: GradientIcon,
        onClick: onOpenMelody,
        testId: 'melody',
        hintName: Language.ACTION_BUTTON_MELODY,
        disabled: isOmnichannelMode && !isOnlineCampaign(item),
        shouldShow: !isReadOnlyMode && isMelodyEnabled,
      },
    ]

    if (isOmnichannelMode) {
      if (isPackage) {
        buttonList.push(...modifyButtons);
      } else {
        buttonList.push(...integrationButtons);
        if (contentSymphonyIntegrationEnabled) {
          buttonList.push({
            icon: CloudUploadIcon,
            onClick: onOpenContentSymphony,
            testId: 'ContentSymphony',
            hintName: Language.ACTION_BUTTON_CONTENT_SYMPHONY,
            disabled: !isOnlineCampaign(item),
            shouldShow: !isReadOnlyMode,
          });
        }
      }
    } else {
      buttonList.push(...modifyButtons);

      if (!isFreshTrafficBusiness) {
        buttonList.push(...integrationButtons);
      }
      if (contentSymphonyIntegrationEnabled) {
        buttonList.push({
          icon: CloudUploadIcon,
          onClick: onOpenContentSymphony,
          testId: 'ContentSymphony',
          hintName: Language.ACTION_BUTTON_CONTENT_SYMPHONY,
          shouldShow: !isReadOnlyMode,
        });
      }
    }
    buttonList.push({
      icon: UpdateIcon,
      onClick: campaignHistory,
      testId: 'history',
      hintName: Language.ACTION_BUTTON_AUDIT_LOG,
      shouldShow: !isReadOnlyMode,
    });
    if (notificationsEnabled) {
      buttonList.push({
        icon: ChatIcon,
        onClick: onOpenComments,
        testId: 'comments',
        hintName: Language.ACTION_BUTTON_COMMENTS,
        disabled: isPackage,
        shouldShow: !isReadOnlyMode,
      });
    }

    return buttonList.filter(({ shouldShow }) => shouldShow)
      .map((buttonConfig) => getButton(buttonConfig));
  };

  return isActionBarExpanded ? (
    <td>
      <div className={classes} style={{ padding: '0 8px' }}>
        {getButtonsList()}
      </div>
    </td>
  ) : (
    <td/>
  );
};

GridViewActionCell.propTypes = {
  onDelete: PropTypes.func.isRequired,
  isActionBarExpanded: PropTypes.bool.isRequired,
  composerEnabled: PropTypes.bool.isRequired,
  notificationsEnabled: PropTypes.bool.isRequired,
  contentSymphonyIntegrationEnabled: PropTypes.bool.isRequired,
  onOpenComposer: PropTypes.func.isRequired,
  onOpenComments: PropTypes.func.isRequired,
};

export default GridViewActionCell;

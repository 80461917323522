import { connect } from 'react-redux';
import UtilizationSlots from './UtilizationSlots';
import { getCustomOrderedSlots } from '../../../selectors/utilizationDataSelectors';

export const mapStateToProps = (state) => {
  const {
    Sitewide: {
      showLoader,
    },
  } = state;

  const { desktopSlot, mobileSlot } = getCustomOrderedSlots(state);

  return {
    slotUtilization: {
      desktopSlotUtilization: desktopSlot,
      mobileSlotUtilization: mobileSlot,
    },
    showLoader,
  };
};

export default connect(mapStateToProps)(UtilizationSlots);

import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@amzn/meridian/heading';
import DashboardCard from './DashboardCard';
import SearchPanel from './SearchPanel';
import AllStats from './AllStats';
import MyStats from './MyStats';
import BulletinBoardDashboardView from '../BulletinBoard/BulletinBoardDashboardView';
import Utilization from './Utilization';
import styles from './Dashboard.module.scss';

const Dashboard = ({ isStatsLoading, isBulletinBoardLoading }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.pageName}>
          <Heading level={1}>Dashboard</Heading>
        </div>
        <div className={styles.searchContainer}>
          <SearchPanel />
        </div>
        <div className={styles.contentContainer}>
          <DashboardCard
            heading="My stats"
            isLoading={isStatsLoading}
          >
            <MyStats />
          </DashboardCard>
          <DashboardCard
            heading="All stats"
            isLoading={isStatsLoading}
          >
            <AllStats />
          </DashboardCard>
          <DashboardCard
            heading="Bulletin board"
            isLoading={isBulletinBoardLoading}
          >
            <BulletinBoardDashboardView />
          </DashboardCard>
          <DashboardCard
            heading="Utilization"
            isLoading={isStatsLoading}
          >
            <Utilization />
          </DashboardCard>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  isStatsLoading: PropTypes.bool.isRequired,
  isBulletinBoardLoading: PropTypes.bool.isRequired,
};

export default Dashboard;

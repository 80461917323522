import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';

import { styledInputProps } from '../../proptypes';

const SingleSelect = ({
  inputValue,
  onInputChange,
  options,
  onBlur,
  placeholder,
  size,
  label,
  allowOverride,
}) => {
  const [searchTerm, updateSearchTerm] = useState('');
  const searchRegExp = useMemo(
    () => new RegExp(_.escapeRegExp(searchTerm), 'i'),
    [searchTerm],
  );

  const trimOnChange = (value) => onInputChange(value.trim());

  const hasLabelAndValue = (option) => {
    return option && typeof option.value !== 'undefined' && option.label;
  };

  const selections = options.filter((option) => {
    if (typeof option === 'boolean') { return true; }
    if (hasLabelAndValue(option)) {
      return !searchTerm || searchRegExp.test(option.label);
    }
    return option.indexOf(searchTerm) !== -1 || searchRegExp.test(option);
  }).map((option) => {
    if (hasLabelAndValue(option)) {
      return (<SelectOption value={option.value} label={option.label} key={option.value} />);
    }
    return (<SelectOption value={option} label={option} key={option} />);
  });

  if (allowOverride) {
    const foundSearchTerm = options.find((option) => option === searchTerm);
    const foundInputValue = options.find((option) => option === inputValue);
    const hasSearchTerm = !_.isEmpty(searchTerm);
    const hasInputValue = !_.isEmpty(inputValue);
    // When searching for a value, if it doesn't exist in the list, add the current search
    // to the list for selecting
    if (hasSearchTerm && !foundSearchTerm) {
      selections.push(<SelectOption value={searchTerm} label={searchTerm} key={searchTerm} />);
    }
    // When not searching (option has been selected), if the selected value is not in the list,
    // add it to the list so that it will show up properly
    if (!hasSearchTerm && hasInputValue && !foundInputValue) {
      selections.push(<SelectOption value={inputValue} label={inputValue} key={inputValue} />);
    }
  }

  selections.push(<SelectOption value="" label="No Entry" key="No Entry" />);

  const emptyResults = (
    <Column alignment="center stretch" spacing="small" spacingInset="small">
      <Text alignment="center">No results</Text>
    </Column>
  );

  return (
    <Select
      onBlur={onBlur}
      value={inputValue}
      onChange={trimOnChange}
      onSearch={updateSearchTerm}
      searchQuery={searchTerm}
      placeholder={placeholder}
      size={size}
      label={label}
      width="100%"
      autoFocus
    >
      {selections}
      {!selections.length && emptyResults}
    </Select>
  );
};

SingleSelect.propTypes = {
  ...styledInputProps,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ])),
  allowOverride: PropTypes.bool,
};

SingleSelect.defaultProps = {
  allowOverride: false,
  options: [],
};

export default SingleSelect;

import _ from 'lodash';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {
  CampaignColumns,
  CampaignStatuses,
  Form,
  PlacementType,
  PlacementSource,
  DEFAULT_TIMEZONE_ID,
} from '../../constants';
import {
  onRateCardAssetAdd,
} from '../../actionCreators';
import { joinBusinessStartYear } from '../../helpers/common';
import { getStartYearFromRateCardConfigName } from '../../helpers/rateCard';
import { formatCampaignForMutationInput } from '../../helpers/translateCampaigns';
import { RateCardColumns } from '../../constants/rateCard';
import {
  getGeneratedCampaignName,
} from '../../helpers/campaignName';
import getConfig from '../../helpers/getRateCardConfig';
import {
  getDependencyChainForField,
  getMetadataFieldOptions,
} from '../../helpers/getMetadataOptions';
import {
  createPackageShell,
  getPackageColumnNames,
} from '../../helpers/package';
import {
  createInStoreCampaignShell,
  createOnlineCampaignShell,
  getCampaignColumnNames,
  isOnlineCampaign,
} from '../../helpers/campaign';

import {
  translateAssetFromLocalToAppsync
} from '../../helpers/translateRateCardAssets';
import { getSelectedBusiness } from '../../selectors/sitewide';

const selector = formValueSelector(Form.RATE_CARD_NAME);

const getPlacementFromMetadata = (placementNameFromConfig, campaign, columnName, metadata) => {
  if (!placementNameFromConfig) {
    return '';
  }

  const optionList = getMetadataFieldOptions(campaign, columnName, metadata);
  if (!optionList || !optionList.length) {
    return '';
  }

  return optionList.find((option) => option.includes(placementNameFromConfig)) || '';
};

const getSlotListFromMetadata = (campaign, columnName, metadata) => {
  const optionList = getMetadataFieldOptions(campaign, columnName, metadata);
  if (optionList && optionList.length) {
    return optionList;
  }

  const dependencyChain = getDependencyChainForField(
    columnName,
    metadata.fields[columnName],
    metadata,
  );

  const { options } = dependencyChain.get(columnName);

  const { pageType, placement } = campaign;
  if (!pageType || !placement) {
    return [];
  }

  if (!options[pageType]) {
    return [];
  }

  const foundPlacementName = Object.keys(options[pageType])
    .find((placementName) => placementName.includes(placement));

  return options[pageType][foundPlacementName] ? options[pageType][foundPlacementName] : [];
};

const getRateCardAssetShells = (bundleContainer, metadata, basePresets) => {
  const { placementType, bundleList } = bundleContainer;
  const config = getConfig();
  const bundlesConfig = config.getBundlesConfig();
  const placementsConfig = config.getPlacementsConfig();
  const rateCardAssetList = [];

  bundleList.forEach((bundle) => {
    const { plannedPlacements, placementName: bundleName } = bundle;

    // How many times we should create these placements
    if (!plannedPlacements) {
      return;
    }

    const bundleConfig = bundlesConfig[bundleName];
    if (!bundleConfig) {
      console.log(`No bundleConfig config for '${bundleName}'`);
      return;
    }

    const { placements } = bundleConfig;
    if (!placements) {
      console.log(`No placements for '${bundleName}'`);
      return;
    }

    if (!placementsConfig) {
      console.log(`No placementsConfig config for '${bundleName}'`);
      return;
    }

    /**
     * For placement: From the config, we get random placement. We then check the metadata for
     * this placement and see what it is named there. We pick it up.
     *
     * For slots: We get a list of slots from the metadata based on the placement.
     * From the list, we select a random slot.
     */
    for (let i = 0; i < plannedPlacements; i++) {
      const packageShell = createPackageShell(_.pick({
        ...basePresets,
        [CampaignColumns.PACKAGE_NAME.name]: bundleName
      }, getPackageColumnNames()));
      packageShell.campaigns = [];

      placements.forEach((placementConfigName) => {
        const campaignPlacementData = placementsConfig[placementConfigName];
        const { timezoneId } = campaignPlacementData;

        const assetBasicData = {
          ...basePresets,
          [CampaignColumns.LANGUAGE.name]: 'English',
          [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
          [CampaignColumns.PLACEMENT_IN_BUNDLE.name]: placementConfigName,
          [CampaignColumns.TIMEZONE_ID.name]: timezoneId || DEFAULT_TIMEZONE_ID,
        }

        const baseAssetShell = {
          ...assetBasicData,
          ...campaignPlacementData,
        };

        const randomPlacementFromConfig = _.sample(campaignPlacementData.placement);
        const presetPlacement = getPlacementFromMetadata(
          randomPlacementFromConfig,
          baseAssetShell,
          CampaignColumns.PLACEMENT.name,
          metadata,
        );

        const assetShellWithPlacement = {
          ...baseAssetShell,
          [CampaignColumns.PLACEMENT.name]: presetPlacement,
        };

        const presetDesktopSlot = _.sample(getSlotListFromMetadata(
          assetShellWithPlacement,
          CampaignColumns.DESKTOP_SLOT.name,
          metadata,
        )) || '';

        const presetMobileSlot = _.sample(getSlotListFromMetadata(
          assetShellWithPlacement,
          CampaignColumns.MOBILE_SLOT.name,
          metadata,
        )) || '';

        const rateCardAsset = {
          ...baseAssetShell,
          ...({ [CampaignColumns.PLACEMENT.name]: presetPlacement }),
          ...({ [CampaignColumns.DESKTOP_SLOT.name]: presetDesktopSlot }),
          ...({ [CampaignColumns.MOBILE_SLOT.name]: presetMobileSlot }),
        };

        const campaignColumns = _.pick(rateCardAsset, getCampaignColumnNames());
        const campaignShell = rateCardAsset.channel === PlacementType.IN_STORE
          ? createInStoreCampaignShell(campaignColumns)
          : createOnlineCampaignShell(campaignColumns);

        const campaignName = getGeneratedCampaignName(campaignShell, packageShell);
        if (campaignName) {
          campaignShell.campaignName = campaignName;
        }
        campaignShell.id = 'rateCardAssetTempId';
        campaignShell.packageId = 'rateCardAssetPackageTempId';

        if (packageShell.campaigns && Array.isArray(packageShell.campaigns)) {
          packageShell.campaigns.push(campaignShell);

          if (isOnlineCampaign(campaignShell)) {
            packageShell.campaigns.push({
              ...campaignShell,
              [CampaignColumns.LANGUAGE.name]: 'Spanish',
            });
          }
        }
      });

      rateCardAssetList.push(packageShell);
    }
  });

  return rateCardAssetList;
};

const getTranslatedAsset = (asset) => {
  const translatedAsset = formatCampaignForMutationInput(
    translateAssetFromLocalToAppsync(asset)
  );
  delete translatedAsset.composerComponentContent;
  delete translatedAsset.composerComponentJson;
  delete translatedAsset.composerComponentName;

  return translatedAsset;
};

export default (dispatch, getState, action, next) => {
  const state = getState();
  const {
    User: { currentUser: { username } },
    Meta: { metadata },
    RateCard: { configName }
  } = state;
  const {
    payload: {
      rateCardId,
    },
  } = action;

  const limitedPlacements = selector(state, RateCardColumns.LIMITED_PLACEMENTS.name) || [];
  const mainPlacements = selector(state, RateCardColumns.MAIN_PLACEMENTS.name) || [];
  const vendor = selector(state, RateCardColumns.VENDOR.name);
  const vertical = selector(state, RateCardColumns.VERTICAL.name);

  const bundleContainers = [
    {
      placementType: RateCardColumns.LIMITED_PLACEMENTS.name,
      bundleList: limitedPlacements,
    },
    {
      placementType: RateCardColumns.MAIN_PLACEMENTS.name,
      bundleList: mainPlacements,
    },
  ];

  bundleContainers.forEach((bundleContainer) => {
    const rateCardAssetBasePreset = {
      [CampaignColumns.BUSINESS_START_YEAR.name]: joinBusinessStartYear(getSelectedBusiness(state), getStartYearFromRateCardConfigName(configName)),
      [CampaignColumns.STATUS.name]: CampaignStatuses.MISSING_INPUTS,
      [CampaignColumns.VENDOR.name]: vendor,
      [CampaignColumns.VERTICAL.name]: vertical,
      [CampaignColumns.MARKETING_MANAGER.name]: username,
      [CampaignColumns.SCHEDULER.name]: username,
      [CampaignColumns.RATE_CARD_ID.name]: rateCardId,
      [CampaignColumns.PLACEMENT_SOURCE.name]: PlacementSource.RATE_CARD,
    };

    const rateCardAssetShells = getRateCardAssetShells(
      bundleContainer,
      metadata,
      rateCardAssetBasePreset,
    );

    rateCardAssetShells.forEach((rateCardAssetShell) => {
      const translatedAsset = getTranslatedAsset(rateCardAssetShell);

      dispatch(onRateCardAssetAdd({
        rateCardId,
        campaign: translatedAsset,
      }));
    });
  });

  return next(action);
};

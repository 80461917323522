import React from 'react';
import Alert from '@amzn/meridian/alert';
import { Language } from '../../constants';

const RateCardFinalizedMessage = () => (
  <Alert type="success">
    {Language.RATE_CARD_FINALIZED_MESSAGE}
  </Alert>
);

export default RateCardFinalizedMessage;

import _ from 'lodash';
import selectCell, { getActiveCellValue } from './selectCell';
import updateCellValue from './updateCellValue';
import addErrorToCampaign from './addErrorToCampaign';
import {
  setFilters,
  setSorts,
  addFilter,
  addSort,
  removeFilter,
  removeSort,
  setActiveFilterView,
  saveFilterView,
  deleteFilterView,
} from './filterViews';
import {
  GridViewActions,
  CampaignActions,
  LoadingStatusActions,
  PackageActions,
  ADD_ERROR_TO_CAMPAIGN,
  SET_PACKAGES,
  SET_CAMPAIGNS,
  SET_PACKAGES_WITH_CAMPAIGNS,
} from '../../constants';
import { getSortedCampaigns } from '../../helpers/campaign';

export const initialState = {
  packages: {},
  raw: {
    campaigns: {},
    campaignLoadingStatus: {},
    packages: {},
    packageLoadingStatus: {},
  },
  areRowsExpanded: true,
  expandedRowOverrides: {},
  filters: [],
  sorts: [],
  filterViews: [],
  activeFilterView: null,
  paginationPage: 0,
  paginationItemsPerPage: 30,
  filterViewBeingEdited: null,
  filterViewModalOpen: false,
  exportModalOpen: false,
  activeCell: null,
  activeCellValue: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GridViewActions.SELECT_CELL: {
      return selectCell(state, action);
    }
    case LoadingStatusActions.SET_CAMPAIGNS_LOADING_STATUS: {
      const { payload: { businessStartYear, isLoading } } = action;

      return {
        ...state,
        raw: {
          ...state.raw,
          campaignLoadingStatus: {
            ...state.raw.campaignLoadingStatus,
            [businessStartYear]: isLoading
          }
        }
      };
    }

    case LoadingStatusActions.SET_PACKAGES_LOADING_STATUS: {
      const { payload: { businessStartYear, isLoading } } = action;

      return {
        ...state,
        raw: {
          ...state.raw,
          packageLoadingStatus: {
            ...state.raw.packageLoadingStatus,
            [businessStartYear]: isLoading
          }
        }
      };
    }

    case SET_CAMPAIGNS: {
      const { payload: { businessStartYear, campaigns } } = action;

      const newCampaigns = state.raw.campaigns[businessStartYear] ?
        {...state.raw.campaigns[businessStartYear]} :
        {};
      for (const newCampaign of campaigns) {
        newCampaigns[newCampaign.id] = newCampaign;
      }

      return {
        ...state,
        raw: {
          ...state.raw,
          campaigns: {
            ...state.raw.campaigns,
            [businessStartYear]: newCampaigns,
          },
        },
      };
    }

    case SET_PACKAGES: {
      const { payload: { businessStartYear, packages } } = action;
      const newPackages = state.raw.packages[businessStartYear] ?
        {...state.raw.packages[businessStartYear]} :
        {};
      for (const newPackage of packages) {
        newPackages[newPackage.id] = newPackage;
      }

      return {
        ...state,
        raw: {
          ...state.raw,
          packages: {
            ...state.raw.packages,
            [businessStartYear]: newPackages,
          },
        },
      };
    }

    case SET_PACKAGES_WITH_CAMPAIGNS: {
      const { payload: { businessStartYear, combinedPackagesForBusinessStartYear } } = action;

      return {
        ...state,
        packages: {
          ...state.packages,
          [businessStartYear]: combinedPackagesForBusinessStartYear,
        },
      };
    }
    case CampaignActions.ADD_CAMPAIGN: {
      return state;
    }
    case CampaignActions.DELETE_CAMPAIGN: {
      const { payload: { campaign } } = action;

      if (!campaign) {
        return state;
      }
      const { business_startYear, id, packageId } = campaign;

      const packages = state.packages[business_startYear];
      if (!packages) {
        return state;
      }
      const existingPackage = packages[packageId];
      if (!existingPackage || !existingPackage.campaigns) {
        return state;
      }
      const newPackage = {
        ...existingPackage,
        campaigns: existingPackage.campaigns.filter((childCampaign) => childCampaign.id !== id)
      };

      return {
        ...state,
        packages: {
          ...state.packages,
          [business_startYear]: {
            ...packages,
            [packageId]: newPackage,
          },
        },
      };
    }
    case GridViewActions.ACTIVE_CELL_INPUT_UPDATE: {
      const { payload: { value } } = action;

      return { ...state, activeCellValue: value };
    }
    case GridViewActions.ACTIVE_CELL_INPUT_SUBMIT: {
      const {
        activeCellValue,
        activeCell: {
          item,
          columnName,
        },
      } = state;

      return updateCellValue(
        state,
        item,
        columnName,
        activeCellValue,
      );
    }
    case GridViewActions.UPDATE_CAMPAIGN_COLUMN: {
      const {
        payload: {
          item,
          columnName,
          newValue
        }
      } = action;

      return updateCellValue(
        state,
        item,
        columnName,
        newValue,
      );
    }
    case GridViewActions.RESET_ACTIVE_CELL: {
      const { activeCell } = state;
      if (!activeCell) {
        return state;
      }
      const { item, columnName } = activeCell;

      return {
        ...state,
        activeCellValue: getActiveCellValue(item, columnName)
      };
    }
    case GridViewActions.SET_FILTERS: {
      return setFilters(state, action.data);
    }
    case GridViewActions.SET_SORTS: {
      return setSorts(state, action.data);
    }
    case GridViewActions.ADD_FILTER: {
      return addFilter(state, action.data);
    }
    case GridViewActions.ADD_SORT: {
      return addSort(state, action.data);
    }
    case GridViewActions.REMOVE_FILTER: {
      return removeFilter(state, action.data);
    }
    case GridViewActions.REMOVE_SORT: {
      return removeSort(state, action.data);
    }
    case GridViewActions.SET_FILTER_VIEWS: {
      return {
        ...state,
        filterViews: action.data,
      };
    }
    case GridViewActions.SET_ACTIVE_FILTER_VIEW: {
      return setActiveFilterView(state, action.data);
    }
    case GridViewActions.OPEN_FILTER_VIEW_MODAL: {
      return {
        ...state,
        filterViewModalOpen: true,
        filterViewBeingEdited: action.data,
      };
    }
    case GridViewActions.CLOSE_FILTER_VIEW_MODAL: {
      return {
        ...state,
        filterViewModalOpen: false,
        filterViewBeingEdited: null,
      };
    }
    case GridViewActions.SAVE_FILTER_VIEW: {
      return saveFilterView(state, action.data);
    }
    case GridViewActions.DELETE_FILTER_VIEW: {
      return deleteFilterView(state, action.data);
    }
    case GridViewActions.SET_PAGE: {
      return {
        ...state,
        paginationPage: action.data,
      };
    }
    case GridViewActions.CREATED_PACKAGE_RECEIVED: {
      const { payload: { createdPackage } } = action;

      if (!createdPackage) {
        return state;
      }

      const { business_startYear, id } = createdPackage;

      return {
        ...state,
        packages: {
          ...state.packages,
          [business_startYear]: {
            ...state.packages[business_startYear],
            [id]: createdPackage,
          },
        },
      };
    }
    case PackageActions.DELETE_PACKAGE: {
      const { payload: { currentPackage } } = action;

      if (!currentPackage) {
        return state;
      }
      const { business_startYear, id } = currentPackage;

      const packages = state.packages[business_startYear];
      if (!packages) {
        return state;
      }
      const newPackages = _.cloneDeep(packages);
      delete newPackages[id];

      return {
        ...state,
        packages: {
          ...state.packages,
          [business_startYear]: newPackages,
        },
      };
    }
    case PackageActions.EDIT_PACKAGE:
    case GridViewActions.UPDATED_PACKAGE_RECEIVED: {
      const { payload: { updatedPackage } } = action;

      if (!updatedPackage) {
        return state;
      }
      const { business_startYear, id } = updatedPackage;

      const packages = state.packages[business_startYear];
      if (!packages || !packages[id]) {
        return state;
      }
      updatedPackage.campaigns = getSortedCampaigns(packages[id].campaigns);

      return {
        ...state,
        packages: {
          ...state.packages,
          [business_startYear]: {
            ...state.packages[business_startYear],
            [id]: updatedPackage,
          },
        },
      };
    }
    case GridViewActions.CREATED_CAMPAIGN_RECEIVED: {
      const { payload: { campaign: createdCampaign } } = action;

      if (!createdCampaign) {
        return state;
      }
      const { business_startYear, id, packageId } = createdCampaign;

      const packages = state.packages[business_startYear];
      if (!packages) {
        return state;
      }

      const existingPackage = packages[packageId];
      if (!existingPackage) {
        return state;
      }
      const newPackage = {
        ...existingPackage,
        campaigns: getSortedCampaigns([...(existingPackage.campaigns || []), createdCampaign]),
      };

      return {
        ...state,
        packages: {
          ...state.packages,
          [business_startYear]: {
            ...state.packages[business_startYear],
            [packageId]: newPackage
          },
        },
      };
    }
    case CampaignActions.EDIT_CAMPAIGN:
    case GridViewActions.UPDATED_CAMPAIGN_RECEIVED: {
      const { payload: { campaign: updatedCampaign } } = action;
      if (!updatedCampaign) {
        return state;
      }

      const { business_startYear, id, packageId } = updatedCampaign;
      const packages = state.packages[business_startYear];
      if (!packages) {
        return state;
      }
      const existingPackage = packages[packageId];
      const newPackage = {
        ...existingPackage,
        campaigns: getSortedCampaigns(
          existingPackage.campaigns.map(campaign => campaign.id === id ? updatedCampaign : campaign)
        ),
      };

      return {
        ...state,
        packages: {
          ...state.packages,
          [business_startYear]: {
            ...state.packages[business_startYear],
            [packageId]: newPackage
          },
        },
      };
    }
    case GridViewActions.OPEN_EXPORT_MODAL: {
      return {
        ...state,
        exportModalOpen: true,
      };
    }
    case GridViewActions.CLOSE_EXPORT_MODAL: {
      return {
        ...state,
        exportModalOpen: false,
      };
    }
    case ADD_ERROR_TO_CAMPAIGN: {
      return addErrorToCampaign(state, action.data);
    }
    case GridViewActions.TOGGLE_ROWS_EXPANDED: {
      return {
        ...state,
        areRowsExpanded: !state.areRowsExpanded,
        expandedRowOverrides: {},
      };
    }
    case GridViewActions.SET_EXPANDED_ROWS_OVERRIDE: {
      const { payload: { rowId, isOpen } } = action;

      return {
        ...state,
        expandedRowOverrides: {
          ...state.expandedRowOverrides,
          [rowId]: isOpen,
        },
      };
    }
    default: {
      return state;
    }
  }
};

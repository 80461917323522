import React from 'react';

import styles from '../../GridViewCell/GridViewCell.module.scss';
import { styledInputProps } from '../../../proptypes';

const Input = ({
  onSubmit,
  inputValue,
  onInputChange,
  onBlur,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <input
        value={inputValue || ''}
        onChange={onInputChange}
        className={styles.activeInput}
        ref={(input) => { return setTimeout(() => { return input && input.focus(); }, 0); }}
        onBlur={onBlur}
      />
    </form>
  );
};

Input.propTypes = styledInputProps;

export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '@amzn/meridian/toggle';
import styles from './WebsiteViewSwitcher.module.scss';
import { WebsiteViewMode } from '../../constants/sitewide';

const WebsiteViewSwitcher = (props) => {
  const { viewMode, isAdmin, onViewModeSwitch } = props;

  if (!isAdmin) {
    return null;
  }

  const isOmnichannelViewMode = (
    viewMode === WebsiteViewMode.OMNICHANNEL.name
  );

  const onChange = () => {
    onViewModeSwitch((
      isOmnichannelViewMode
        ? WebsiteViewMode.NON_OMNICHANNEL.name
        : WebsiteViewMode.OMNICHANNEL.name
    ));
  };

  const viewModeDescriptionText = isOmnichannelViewMode
    ? WebsiteViewMode.OMNICHANNEL.label
    : WebsiteViewMode.NON_OMNICHANNEL.label;

  return (
    <div className={styles.container}>
      <Toggle
        checked={isOmnichannelViewMode}
        onChange={onChange}
      />
      <span className={styles.descriptionText}>
        {viewModeDescriptionText}
      </span>
    </div>
  );
};

WebsiteViewSwitcher.propTypes = {
  viewMode: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onViewModeSwitch: PropTypes.func.isRequired,
};

export default WebsiteViewSwitcher;

import { connect } from 'react-redux';

import {
  onNotificationsOpenWidget,
} from '../../actionCreators';
import NotificationBell from './NotificationBell';
import { getNotificationsUnreadCount } from '../../selectors/notificationsSelectors';

export const mapStateToProps = (state) => {
  return {
    notificationsCount: getNotificationsUnreadCount(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onOpenNotificationsWidget: () => {
      dispatch(onNotificationsOpenWidget());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);

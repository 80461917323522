import {
  Validation,
  Language,
  CampaignColumns,
  CARET,
  WidgetTypes,
  ShovelerTypes,
  ASIN_DELIMETER,
  PageTypes,
  CampaignStatuses,
  LanguageTypes,
  PromoTypes,
  SovLimits,
} from '../constants';
import {
  getItemBusiness,
  getTemplateString,
  isEmptyValue,
  isFreshBusiness,
  isGenericFreshBusiness,
} from './common';
import {
  getItemRequiredFields,
} from './requiredFields';
import { isNumericInput, isValidStartDate } from './form';
import { isOmnichannelPackageName } from './package';
import { isNotWednesday, unpadTimeWithSeconds } from './dateTime';
import { DaysOfWeek, TimeValues } from '../constants/dateTime';

/**
 * Form validators.
 */

export const isEmpty = (value) => !(value || typeof value === 'number');
export const tooLong = (value) => value && value.length > Validation.MAX_LENGTH;

export const validatePackage = (pkg, errors = []) => {
  if (isEmptyValue(pkg[CampaignColumns.BUSINESS_START_YEAR.name])) {
    errors[CampaignColumns.BUSINESS_START_YEAR.name] = Language.VALIDATION_REQUIRED;

    return errors;
  }

  getItemRequiredFields(pkg).forEach((fieldName) => {
    if (isEmptyValue(pkg[fieldName])) {
      errors[fieldName] = Language.VALIDATION_REQUIRED;
    }
  });

  const packageName = pkg[CampaignColumns.PACKAGE_NAME.name]
  const startDate = pkg[CampaignColumns.START_DATE.name]
  const endDate = pkg[CampaignColumns.END_DATE.name]
  const startTime = unpadTimeWithSeconds(pkg[CampaignColumns.START_TIME.name]);
  const endTime = unpadTimeWithSeconds(pkg[CampaignColumns.END_TIME.name]);
  const business = getItemBusiness(pkg);


  if (isOmnichannelPackageName(packageName) && isFreshBusiness(business)) {
    const omniPackageNameError = getTemplateString(Language.INVALID_DAY_OF_WEEK, {
      campaignType: 'Omnichannel Deal Campaigns', 
      startDateTime: `${TimeValues.MIDNIGHT.label} ${DaysOfWeek.WEDNESDAY}`,
      endDateTime: `${TimeValues.MIDNIGHT.label} ${DaysOfWeek.WEDNESDAY}`
    });

    if (isNotWednesday(startDate)) {
      errors[CampaignColumns.START_DATE.name] = omniPackageNameError
    }
    if (startTime !== TimeValues.MIDNIGHT.value) {
      errors[CampaignColumns.START_TIME.name] = omniPackageNameError
    }
    if (isNotWednesday(endDate)) {
      errors[CampaignColumns.END_DATE.name] = omniPackageNameError
    }
    if (endTime !== TimeValues.MIDNIGHT.value) {
      errors[CampaignColumns.END_TIME.name] = omniPackageNameError
    }
  }

  if (!isEmptyValue(pkg[CampaignColumns.START_DATE.name])
    && !isValidStartDate(
      pkg[CampaignColumns.START_DATE.name],
      pkg[CampaignColumns.END_DATE.name],
    ) && isFreshBusiness(business)) {
    errors[CampaignColumns.START_DATE.name] = Language.VALIDATION_START_DATE_ERROR;
  }

  return errors;
};

const validateCampaign = (campaign, packageErrors, errors = {}) => {
  getItemRequiredFields(campaign).forEach(requiredField => {
    if (campaign.hasOwnProperty(requiredField)
      && isEmptyValue(campaign[requiredField])) {
      errors[requiredField] = Language.VALIDATION_REQUIRED;
    }
    if (requiredField === CampaignColumns.SOV.name
      && campaign.hasOwnProperty(requiredField)
      && !isNumericInput(campaign[requiredField])) {
        errors[requiredField] = Language.VALIDATION_REQUIRED;
      }
  });

  const { inventoryAwareness, pageType, pageTheme, sov, widget } = campaign;

  const language = campaign[CampaignColumns.LANGUAGE.name];
  const status = campaign[CampaignColumns.STATUS.name];
  if (status === CampaignStatuses.INPUTS_COMPLETE && language === LanguageTypes.SPANISH) {
    delete errors[CampaignColumns.COPY.name];
  }

  const startDate = campaign[CampaignColumns.START_DATE.name]
  const endDate = campaign[CampaignColumns.END_DATE.name]
  const startTime = unpadTimeWithSeconds(campaign[CampaignColumns.START_TIME.name]);
  const endTime = unpadTimeWithSeconds(campaign[CampaignColumns.END_TIME.name]);
  const isDeal = campaign[CampaignColumns.IS_DEAL.name];
  const promoType = campaign[CampaignColumns.PROMO_TYPE.name];
  const business = getItemBusiness(campaign);

  if (isFreshBusiness(business)) {
    if (pageType === PageTypes.EVENT) {
      const eventPageError = getTemplateString(Language.INVALID_DAY_OF_WEEK, {
        campaignType: 'Event Campaign',
        startDateTime: TimeValues.MIDNIGHT.label,
        endDateTime: TimeValues.MIDNIGHT.label
      });

      if (startTime !== TimeValues.MIDNIGHT.value) {
        packageErrors[CampaignColumns.START_TIME.name] = eventPageError;
        errors[CampaignColumns.START_TIME.name] = eventPageError;
      }
      if (endTime !== TimeValues.MIDNIGHT.value) {
        packageErrors[CampaignColumns.END_TIME.name] = eventPageError;
        errors[CampaignColumns.END_TIME.name] = eventPageError;
      }
    }

    else if (pageType !== PageTypes.DEALS && promoType !== PromoTypes.VPC &&
      isDeal) {
      const nonDealsPageError = getTemplateString(Language.INVALID_DAY_OF_WEEK,
        {
          campaignType: 'Deals Campaign not on Deals Page',
          startDateTime: `${TimeValues.DEFAULT.label} ${DaysOfWeek.WEDNESDAY}`,
          endDateTime: `${TimeValues.MIDNIGHT.label} ${DaysOfWeek.WEDNESDAY}`
        });

      if (isNotWednesday(startDate)) {
        packageErrors[CampaignColumns.START_DATE.name] = nonDealsPageError
        errors[CampaignColumns.START_DATE.name] = nonDealsPageError
      }
      if (startTime !== TimeValues.DEFAULT.value) {
        packageErrors[CampaignColumns.START_TIME.name] = nonDealsPageError
        errors[CampaignColumns.START_TIME.name] = nonDealsPageError
      }
      if (isNotWednesday(endDate)) {
        packageErrors[CampaignColumns.END_DATE.name] = nonDealsPageError
        errors[CampaignColumns.END_DATE.name] = nonDealsPageError
      }
      if (endTime !== TimeValues.MIDNIGHT.value) {
        packageErrors[CampaignColumns.END_TIME.name] = nonDealsPageError
        errors[CampaignColumns.END_TIME.name] = nonDealsPageError
      }
    }

 
    if (isDeal && isEmptyValue(campaign[CampaignColumns.PROMO_ID.name])) {
      errors[CampaignColumns.PROMO_ID.name] = Language.VALIDATION_REQUIRED;
    }

    const asinsLength = campaign[CampaignColumns.ASINS.name] ? campaign[CampaignColumns.ASINS.name].split(ASIN_DELIMETER).length : 0;
    if ([WidgetTypes.SHOV, WidgetTypes.ENHSHOV].includes(widget)
      && asinsLength < Validation.MIN_LENGTH
      && campaign[CampaignColumns.SHOVELER_CONTENT.name] !== ShovelerTypes.BROWSE_NODE) {
      errors[CampaignColumns.ASINS.name] = Language.VALIDATION_ASIN_COUNT;
    }

  const isEventPageWithWidgets = (pageType === PageTypes.EVENT
    && [WidgetTypes.ENHSHOV, WidgetTypes.HERO, WidgetTypes.STRIPE].includes(widget));
  const isStorefrontPageWithWidgets = (pageType === PageTypes.STOREFRONT
    && [WidgetTypes.ENHSHOV, WidgetTypes.HERO, WidgetTypes.HALF].includes(widget));

    if ((isEventPageWithWidgets || isStorefrontPageWithWidgets) && isEmptyValue(inventoryAwareness)) {
      errors[CampaignColumns.INVENTORY_AWARENESS.name] = Language.VALIDATION_REQUIRED;
    }
  }

  if (!isEmptyValue(campaign[CampaignColumns.START_DATE.name])
    && !isValidStartDate(
      campaign[CampaignColumns.START_DATE.name],
      campaign[CampaignColumns.END_DATE.name],
    )) {
    errors[CampaignColumns.START_DATE.name] = Language.VALIDATION_START_DATE_ERROR;
  }

  if (!isEmptyValue(campaign[CampaignColumns.ASINS.name])
    && !isEmptyValue(campaign[CampaignColumns.BROWSE_NODE_IDS.name])) {
    errors[CampaignColumns.ASINS.name] = Language.VALIDATION_ASINS_NODE_IDS_BOTH_SET;
    errors[CampaignColumns.BROWSE_NODE_IDS.name] = Language.VALIDATION_ASINS_NODE_IDS_BOTH_SET;
  }

  if (!isEmptyValue(sov)
    && sov > Validation.MAX_SOV_PERCENT) {
    errors[CampaignColumns.SOV.name] = Language.VALIDATION_MAX_SOV_PERCENT;
  }

  if (!isEmptyValue(pageTheme)
    && !isEmptyValue(campaign[CampaignColumns.OTHER_THEME.name])) {
    errors[CampaignColumns.PAGE_THEME.name] = Language.VALIDATION_THEME_BOTH_SET;
    errors[CampaignColumns.OTHER_THEME.name] = Language.VALIDATION_THEME_BOTH_SET;
  }

  return errors;
};

const validateCampaigns = (campaigns, errors) => {
  if (isEmptyValue(campaigns)) {
    return errors;
  }

  campaigns.forEach((campaign, index) => {
    const campaignErrors = [];
    if (isEmptyValue(campaignErrors[index])) {
      campaignErrors[index] = {};
    }

    validateCampaign(campaign, errors, campaignErrors[index]);

    if (!isEmptyValue(campaignErrors[index])) {
      if (!errors.campaigns) {
        errors.campaigns = [];
      }
      errors.campaigns[index] = campaignErrors[index];
    }
  });

  return errors;
};

export const validate = (pkg) => {
  const errors = {};

  validatePackage(pkg, errors);
  if (!isEmptyValue(pkg.campaigns)) {
    validateCampaigns(pkg.campaigns, errors);
  }

  return errors;
};

export const warn = (values) => {
  const warnings = {};
  if (values.brand && values.brand.length < Validation.MIN_LENGTH) {
    warnings.brand = Language.VALIDATION_MIN_LENGTH;
  }
  if (isGenericFreshBusiness(values.business) && values.copy && values.copy.indexOf(CARET) !== -1) {
    warnings.copy = Language.CARET_PRESENT;
  }

  if (
    isGenericFreshBusiness(values.business)
    && values.copy
    && values.copy.length > Validation.COPY_MAX_LENGTH
  ) {
    warnings.copy = Language.VALIDATION_MAX_LENGTH;
  }

  if (
    isGenericFreshBusiness(values.business)
    && values.subHeadline
    && values.subHeadline.length > Validation.SUB_HEADLINE_MAX_LENGTH
  ) {
    warnings.subHeadline = Language.VALIDATION_MAX_LENGTH;
  }

  if (values.scheduler && values.scheduler.length < Validation.MIN_LENGTH) {
    warnings.scheduler = Language.VALIDATION_MIN_LENGTH;
  }

  if (values.vertical && values.vertical.length < Validation.MIN_LENGTH) {
    warnings.vertical = Language.VALIDATION_MIN_LENGTH;
  }

  if (values.vendor && values.vendor.length < Validation.MIN_LENGTH) {
    warnings.vendor = Language.VALIDATION_MIN_LENGTH;
  }

  return warnings;
};

export const getUtilizationFieldsListToWatch = () => {
  return [
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.PLACEMENT.name,
    CampaignColumns.DESKTOP_SLOT.name,
    CampaignColumns.MOBILE_SLOT.name,
  ];
};

export const isValidSlot = (slotUtilizationData) => {
  if (!slotUtilizationData) {
    return true;
  }

  return slotUtilizationData.totalSOV <= SovLimits.SOV_FULL;
};

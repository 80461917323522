import { connect } from 'react-redux';
import { getPaginatedPackages } from 'src/selectors/gridView';
import {
  onKeyboardPressAction,
  onSetFilters,
  onSetSorts,
} from '../../actionCreators';
import { processFilters, processSorts } from '../../helpers/filters';
import GridViewPage from './GridViewPage';
import {
  isReadOnlyMode,
  isWebsiteOmnichannelViewMode,
} from '../../selectors/sitewide';
import { arePackagesLoaded } from '../../selectors/package';
import { areCampaignsLoaded } from '../../selectors/campaign';
import { getFilteredGlobalActiveAnnouncements } from '../../selectors/bulletinBoard';

export const mapDispatchToProps = (dispatch) => {
  return {
    onKeyboardPress(evt) {
      dispatch(onKeyboardPressAction(evt));
    },
    onLocationChange(search) {
      const { filters, sorts } = search;
      dispatch(onSetFilters(processFilters(decodeURIComponent(filters || ''))));
      dispatch(onSetSorts(processSorts(decodeURIComponent(sorts || ''))));
    },
  };
};

export const mapStateToProps = (state) => {
  const {
    GridViewPage: { filterViewModalOpen },
    Meta: { metadata: { columnOrder } },
    CustomView: { customColumnOrder, useCustomColumnOrder },
    BulletinBoard: { announcements },
    router: { location },
  } = state;

  const activeAnnouncements = getFilteredGlobalActiveAnnouncements(state);

  const columns = customColumnOrder && useCustomColumnOrder ? customColumnOrder.filter((column) => {
    return column.isVisible;
  }) : columnOrder;

  return {
    location,
    filterViewModalOpen,
    packages: getPaginatedPackages(state),
    columnOrder: columns,
    hasActiveAnnouncements: activeAnnouncements && activeAnnouncements.length > 0,
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    isAppLoading: !arePackagesLoaded(state) || !areCampaignsLoaded(state),
    isReadOnlyMode: isReadOnlyMode(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GridViewPage);

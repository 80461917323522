import { connect } from 'react-redux';
import Dashboard from './Dashboard';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: {
      campaignsLoading,
    },
    BulletinBoard: {
      isLoading,
    },
  } = state;

  return {
    isStatsLoading: campaignsLoading,
    isBulletinBoardLoading: isLoading,
  };
};

export default connect(mapStateToProps)(Dashboard);

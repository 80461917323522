export const DigitalCircularPdfUploaderStatuses = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
});
export const MediaServiceActions = Object.freeze({
  UPLOAD_FILES: 'MediaServiceActions/UPLOAD_FILES',
  SET_FILE: 'MediaServiceActions/SET_FILE',
  SET_FILES_COUNT_TOTAL: 'MediaServiceActions/SET_FILES_COUNT_TOTAL',
  UPDATE_PROCESSED_FILES_COUNT: 'MediaServiceActions/UPDATE_PROCESSED_FILES_COUNT',
  RESET: 'MediaServiceActions/RESET',
});

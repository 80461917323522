/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      notificationType
      business_startYear
      toUsername
      fromUsername
      campaignId
      packageId
      name
      column
      oldValue
      newValue
      isRead
      createdAt
    }
  }
`;
export const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign {
    onUpdateCampaign {
      asins
      brand
      business_startYear
      campaignName
      campaignType
      category
      channel
      copy
      createdAt
      endDateTime
      id
      isLocked
      isDeal
      language
      lockMessage
      lockUser
      marketingManagerNotes
      otherTheme
      packageId
      placementSource
      placementInBundle
      promoId
      promoType
      rateCardId
      refMarker
      scheduler
      schedulingInstructions
      spanishCopy
      startDateTime
      status
      timezoneId
      translationStatus
      updatedAt
      version
      almStores
      browseNodeIds
      cbrNotes
      contraCogs
      composerComponentJson
      csCampaignIds {
        creativeId
        desktopPlacementId
        mobilePlacementId
      }
      customSeeMoreUrl
      dedupeRules
      desktopSlot
      encodedKeplerWidgetParam
      functionalQANotes
      heroAsins
      imagePaths
      imageType
      inventory
      inventoryAwareness
      isAnimatedContent
      label
      mobileSlot
      merchant
      needsInventoryAwareness
      pageId
      pageName
      pageTheme
      pageType
      percolateParameters
      placement
      shovelerContent
      slotsOverbookedValidationBypassed
      sov
      sovType
      storefrontReviewNotes
      subHeadline
      symphonyId
      symphonyUrl
      targeting
      targetingExclude
      termsAndConditions
      useNewTemplateDesign
      vssmId
      weblab
      weblabTreatment
      widget
      altText
      bodyCopy
      cbrSim
      country
      creativeStatus
      cta
      customerCtaUrl
      designSim
      desktopImage1x
      desktopImage2x
      device
      frequency
      headline
      hva
      initiative
      mobileImage1x
      mobileImage2x
      seasonalEvent
      tabletImage1x
      tabletImage2x
      targetBusiness
      targetingType
    }
  }
`;
export const onUpdatePackage = /* GraphQL */ `
  subscription OnUpdatePackage {
    onUpdatePackage {
      business_startYear
      id
      brand
      category
      endDateTime
      isDeal
      marketingManager
      marketingManagerNotes
      packageName
      placementSource
      program
      rateCardId
      refMarker
      startDateTime
      status
      timezoneId
      vendor
      vertical
      version
      updatedAt
      createdAt
    }
  }
`;
export const onUpdateMetadata = /* GraphQL */ `
  subscription OnUpdateMetadata {
    onUpdateMetadata {
      business
      metadata
      version
    }
  }
`;
export const onUpdateComments = /* GraphQL */ `
  subscription OnUpdateComments {
    onUpdateComments {
      campaignId
      message
      createdBy
      createdAt
    }
  }
`;

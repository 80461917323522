import { InStoreUtilizationActions } from '../constants/inStoreUtilization';

export const onInStoreUtilizationSetFilterOptions = (filterOptions) => ({
  type: InStoreUtilizationActions.SET_FILTER_OPTIONS,
  payload: {
    filterOptions,
  },
});
export const onInStoreUtilizationSelectDateRange = (selectedDateRange) => ({
  type: InStoreUtilizationActions.SELECT_DATE_RANGE,
  payload: {
    selectedDateRange,
  },
});
export const onInStoreUtilizationStartTimeSet = (startTime) => ({
  type: InStoreUtilizationActions.SELECT_START_TIME,
  payload: {
    startTime,
  },
});
export const onInStoreUtilizationEndTimeSet = (endTime) => ({
  type: InStoreUtilizationActions.SELECT_END_TIME,
  payload: {
    endTime,
  },
});
export const onInStoreUtilizationSelectTimezone = (selectedTimezone) => ({
  type: InStoreUtilizationActions.SELECT_TIMEZONE,
  payload: {
    selectedTimezone,
  },
});
export const onInStoreUtilizationSetFilter = (filterGroupName, optionName, isChecked) => ({
  type: InStoreUtilizationActions.SET_FILTER,
  payload: {
    filterGroupName,
    optionName,
    isChecked,
  },
});
export const onInStoreUtilizationSetFilters = (filterGroupName, selectedFilters) => ({
  type: InStoreUtilizationActions.SET_FILTERS,
  payload: {
    filterGroupName,
    selectedFilters,
  },
});
export const onInStoreUtilizationLoadPresets = () => ({
  type: InStoreUtilizationActions.LOAD_PRESETS,
});

export const onInStoreUtilizationCalculateResults = () => ({
  type: InStoreUtilizationActions.CALCULATE_RESULTS,
});

export const onInStoreUtilizationSetResults = (utilization) => ({
  type: InStoreUtilizationActions.SET_RESULTS,
  payload: {
    utilization,
  },
});
export const onInStoreUtilizationSelectTimeFrame = (timeFrame) => ({
  type: InStoreUtilizationActions.SELECT_TIME_FRAME,
  payload: {
    timeFrame,
  },
});

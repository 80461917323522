import { API, graphqlOperation } from 'aws-amplify';
import { getDefaultInitialLoadStartDateTime } from '../../helpers/customerNotifications';
import {
  MetricLocations, MetricNames, MetricTypes, NotificationsConfig,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import {
  getCurrentBusinessStartYear,
} from '../../selectors/sitewide';
import { getNotificationsForUsername } from '../../graphql/queries';
import {
  onNotificationsLoad,
  onNotificationsSet,
  onNotificationsSetLoaded,
  onNotificationsSetLoading,
  onNotificationsSort,
  onNotificationsUpdateLastReceivedTime,
} from '../../actionCreators';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const state = getState();
  const getNotifications = (token, startDateTime) => {
    const {
      User: { currentUser: { username } },
      Notifications: { areNotificationsLoaded },
    } = state;

    const business_startYear = getCurrentBusinessStartYear(state);

    const params = {
      limit: NotificationsConfig.NOTIFICATIONS_LIMIT,
      toUsername: username,
      createdAfter: startDateTime,
      business_startYear,
      nextToken: token,
    };

    const metricContext = {
      location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: params,
      action: MetricNames.LOAD_NOTIFICATIONS,
      username,
    };

    submitStringMetric(MetricNames.LOAD_NOTIFICATIONS, metricContext);

    return API.graphql(graphqlOperation(getNotificationsForUsername, params))
      .then((result) => {
        const { items: notifications, nextToken } = result.data.getNotificationsForUsername;

        // Prevent notifications' re-set and re-sort if there is nothing new
        if (areNotificationsLoaded && (!notifications || !notifications.length)) {
          return;
        }
        dispatch(onNotificationsSet({notifications}));
        if (nextToken) {
          dispatch(onNotificationsLoad({ nextToken, startDateTime }));
        } else {
          dispatch(onNotificationsSort());
          dispatch(onNotificationsUpdateLastReceivedTime());
          dispatch(onNotificationsSetLoading(false));
          dispatch(onNotificationsSetLoaded());
        }
      }).catch((error) => {
        submitAppSyncError(error, metricContext);
      });
  };

  const {
    nextToken,
    startDateTime,
  } = action.payload;

  const selectedStartDateTime = startDateTime || getDefaultInitialLoadStartDateTime();

  getNotifications(nextToken, selectedStartDateTime);

  return next(action);
};

import { API, graphqlOperation } from 'aws-amplify';

import {
  GRAPHQL_ACTION,
  MetricLocations,
  MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';

export default ({ dispatch, getState }) => (next) => (action) => {
  if (action.type === GRAPHQL_ACTION) {
    const { User: { currentUser } } = getState();
    const username = currentUser ? currentUser.username : null;
    const {
      payload: {
        graphqlAction,
        data,
        onSuccessAction,
        onFailureAction,
        onSuccessFunction,
        onFailureFunction,
        label,
      },
    } = action;

    if (!graphqlAction) { return next(action); }

    const metricContext = {
      location: MetricLocations.GRAPHQL_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data,
      action: label,
      username,
    };
    submitStringMetric(label, metricContext);
    return API.graphql(graphqlOperation(graphqlAction, data))
      .then((result) => {
        dispatch(onSuccessAction(result));
        return onSuccessFunction(result);
      })
      .catch((error) => {
        submitAppSyncError(error, metricContext);
        dispatch(onFailureAction(error));
        return onFailureFunction(error);
      });
  }
  return next(action);
};

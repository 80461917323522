import { CampaignColumns } from '../constants';

import { createRequiredFields } from '../helpers/createRequiredFields';

const PACKAGE_FIELDS = {
  BASE: [
    [CampaignColumns.STATUS.name],
    [CampaignColumns.VERTICAL.name],
    [CampaignColumns.START_DATE.name],
    [CampaignColumns.END_DATE.name],
  ],
  STATUS_APPROVED: [],
};

const CAMPAIGN_FIELDS = {
  BASE: [
    [CampaignColumns.STATUS.name],
    [CampaignColumns.PLACEMENT.name],
    [CampaignColumns.START_DATE.name],
    [CampaignColumns.END_DATE.name],
    [CampaignColumns.PAGETYPE.name],
  ],
  STATUS_APPROVED: [
    [CampaignColumns.PAGE_NAME.name],
    [CampaignColumns.PAGE_ID.name],
    [CampaignColumns.DESKTOP_SLOT.name],
    [CampaignColumns.MOBILE_SLOT.name],
    [CampaignColumns.CAMPAIGN_NAME.name],
    [CampaignColumns.COPY.name],
  ],
};

const DEFAULT_REQUIRED_FIELDS = createRequiredFields(
  PACKAGE_FIELDS,
  CAMPAIGN_FIELDS
);

export default DEFAULT_REQUIRED_FIELDS;
import React from 'react';
import PropTypes from 'prop-types';
import styles from './RateCardContainerHeader.module.scss';
import Tabs from '../RateCardContainerTabs';
import { rateCardProps } from '../../../proptypes';

const RateCardContainerHeader = (props) => {
  const {
    showTabs,
    loadedRateCard,
    isLoading,
  } = props;

  if (isLoading) {
    return null;
  }

  const getTitleBlock = () => {
    if (!loadedRateCard) {
      return (
        <h2 className={styles.headerTitle}>New Rate Card</h2>
      );
    }

    return null;
  };

  const getTabsBlock = () => {
    return showTabs
      ? (
        <div className={styles.tabsContainer}>
          <Tabs />
        </div>
      )
      : null;
  };

  const tabsBlock = getTabsBlock();
  const titleBlock = getTitleBlock();

  return (
    <>
      {tabsBlock}
      <div className={styles.container}>
        {titleBlock}
      </div>
    </>
  );
};

RateCardContainerHeader.propTypes = {
  showTabs: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadedRateCard: rateCardProps,
};

RateCardContainerHeader.defaultProps = {
  loadedRateCard: null,
};

export default RateCardContainerHeader;

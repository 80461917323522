import { connect } from 'react-redux';
import InStoreUtilization from './InStoreUtilization';
import { arePackagesLoaded } from '../../selectors/package';
import { areCampaignsLoaded } from '../../selectors/campaign';

const mapStateToProps = (state) => {
  return {
    showWarning: !arePackagesLoaded(state) || !areCampaignsLoaded(state),
  };
};

export default connect(mapStateToProps)(InStoreUtilization);

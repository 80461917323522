import { connect } from 'react-redux';

import createColumn from '../../helpers/createColumn';
import { onAuditLogSetItem } from '../../actionCreators';

import AuditLogPage from './AuditLogPage';
import { AuditLogTypes } from '../../constants';
import { RateCardColumns } from '../../constants/rateCard';
import { isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';

const getItemId = ({ campaignId, packageId, match }) => {
  if (campaignId) {
    return campaignId;
  }
  if (packageId) {
    return packageId;
  }
  if (match && match.params && match.params.id) {
    return match.params.id;
  }

  return null;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    AuditLog: { logs, isLoading },
    Meta: { metadata: { columnOrder } },
  } = state;

  const { id, auditLogType } = ownProps;

  const selectedColumnOrder = auditLogType === AuditLogTypes.RATE_CARD
    ? Object.values(RateCardColumns)
    : columnOrder;

  const usernameColumn = createColumn('username', 'Username', 'UNEDITABLE');
  const modificationTimeColumn = createColumn('modificationTimestamp', 'Modification Time', 'UNEDITABLE');
  const modificationTypeColumn = createColumn('modificationType', 'Type', 'UNEDITABLE');
  const versionColumn = createColumn('version', 'Version', 'UNEDITABLE');

  const auditLogColumnOrder = [
    modificationTimeColumn,
    modificationTypeColumn,
    versionColumn,
    usernameColumn,
    ...selectedColumnOrder,
  ];

  const columnsToCompare = selectedColumnOrder.map((column) => column.name);
  return {
    id: id ?? getItemId(ownProps),
    logs,
    isLoading,
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    columnsToCompare,
    columnOrder: auditLogColumnOrder,
  };
};

export const mapDispatchToProps = (dispatch, { auditLogType }) => {
  return {
    onSelectItem(id) {
      dispatch(onAuditLogSetItem(id, auditLogType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogPage);

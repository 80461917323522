import { createSelector } from 'reselect';
import { NotificationsConfig } from '../constants';

export const getNotificationsList = (state) => {
  return state.Notifications.list;
};

export const getReadList = (state) => {
  return state.Notifications.readList;
};

export const getMarkedList = (state) => {
  return state.Notifications.markedList;
};

export const getPaginationBounds = (state) => {
  const { Notifications: { list, paginationPage, paginationItemsPerPage } } = state;

  const minPage = 0;
  const maxPage = list.length <= paginationItemsPerPage
    ? minPage
    : Math.ceil(list.length / paginationItemsPerPage);

  let currentPage = paginationPage - 1;
  if (currentPage < minPage) {
    currentPage = minPage;
  }
  if (currentPage > maxPage) {
    currentPage = maxPage;
  }

  return [paginationItemsPerPage * currentPage, paginationItemsPerPage * (currentPage + 1)];
};

export const getPaginatedNotifications = createSelector(
  [getNotificationsList, getPaginationBounds],
  (notificationsList, paginationBounds) => {
    return notificationsList.slice(paginationBounds[0], paginationBounds[1]);
  },
);

export const getWidgetNotifications = createSelector(
  [getNotificationsList],
  (notificationsList) => {
    return notificationsList.slice(
      0,
      NotificationsConfig.NOTIFICATIONS_DISPLAY_LIMIT_WIDGET,
    );
  },
);

export const getMarkedNotificationsCount = createSelector(
  [getMarkedList],
  (markedList) => {
    let markedNotificationsCount = 0;

    for (let i = 0, keys = Object.keys(markedList); i < keys.length; i++) {
      const notification = markedList[keys[i]];
      if (notification.isMarked) {
        markedNotificationsCount++;
      }
    }

    return markedNotificationsCount;
  },
);

export const getNotificationsUnreadCount = createSelector(
  [getNotificationsList, getReadList],
  (list, readList) => {
    const count = {};
    list.forEach((item) => {
      if (readList[item.id] !== undefined) {
        if (!readList[item.id]) {
          count[item.id] = true;
        }
        return;
      }
      if (!item.isRead) {
        count[item.id] = true;
      }
    });

    return Object.keys(count).length;
  },
);

import _ from 'lodash';
import {
  NotificationsActions,
  NotificationsConfig,
} from '../../constants';

export const initialState = {
  list: [],
  isLoading: false,
  isWidgetOpen: false,
  areNotificationsLoaded: false,
  newNotificationsCount: 0,
  paginationPage: 0,
  paginationItemsPerPage: NotificationsConfig.PAGINATION_ITEMS_PER_PAGE,
  lastNotificationReceivedTime: null,
  isExperienceEnabled: false,
  isUpdating: false,
  readList: {},
  markedList: {},
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }

  const { type, payload } = action;

  switch (type) {
    case NotificationsActions.SET: {
      const { notifications, shouldOverride } = payload;

      if (!notifications || !notifications.length) {
        return state;
      }

      const mergedList = _.merge(
        _.keyBy(state.list, 'id'),
        _.keyBy(notifications, 'id'),
      );

      return {
        ...state,
        list: shouldOverride
          ? notifications
          : _.values(mergedList),
      };
    }
    case NotificationsActions.SET_LOADING: {
      const { isLoading } = payload;
      return {
        ...state,
        isLoading,
      };
    }
    case NotificationsActions.OPEN_WIDGET: {
      return {
        ...state,
        isWidgetOpen: true,
      };
    }
    case NotificationsActions.CLOSE_WIDGET: {
      return {
        ...state,
        isWidgetOpen: false,
      };
    }
    case NotificationsActions.SET_NEW_NOTIFICATIONS_COUNT: {
      const { count } = payload;
      return {
        ...state,
        newNotificationsCount: count,
      };
    }
    case NotificationsActions.SET_NOTIFICATIONS_LOADED: {
      return {
        ...state,
        areNotificationsLoaded: true,
      };
    }
    case NotificationsActions.SET_PAGE_NUMBER: {
      const { pageNumber } = payload;
      return {
        ...state,
        paginationPage: pageNumber,
      };
    }
    case NotificationsActions.UPDATE_LAST_NOTIFICATION_RECEIVED_TIME: {
      const { lastNotificationReceivedTime } = payload;

      return {
        ...state,
        lastNotificationReceivedTime,
      };
    }
    case NotificationsActions.ENABLE_EXPERIENCE: {
      return {
        ...state,
        isExperienceEnabled: true,
      };
    }
    case NotificationsActions.MARK_AS: {
      const { notifications, valueToMark } = payload;

      const newReadList = { ...state.readList };
      notifications.forEach((notification) => {
        newReadList[notification.id] = valueToMark;
      });

      return {
        ...state,
        readList: newReadList,
      };
    }
    case NotificationsActions.MARK: {
      const { notifications, overrideValue } = payload;

      const newMarkedList = { ...state.markedList };

      notifications.forEach((notification) => {
        const { id } = notification;

        if (!newMarkedList[id]) {
          newMarkedList[id] = notification;
        }
        if (overrideValue !== undefined) {
          newMarkedList[id].isMarked = overrideValue;
        } else {
          newMarkedList[id].isMarked = !newMarkedList[id].isMarked;
        }
      });

      return {
        ...state,
        markedList: newMarkedList,
      };
    }
    case NotificationsActions.RESET_LOADED: {
      return {
        ...state,
        list: [],
      };
    }
    case NotificationsActions.RESET_MARKED: {
      return {
        ...state,
        markedList: [],
      };
    }
    case NotificationsActions.SET_UPDATING: {
      const { isUpdating } = payload;
      return {
        ...state,
        isUpdating,
      };
    }
    default: {
      return state;
    }
  }
};

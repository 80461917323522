import { RateCardMerchTypes } from '../../../../constants/rateCard';

const Conversion = {
  'Prime Day (Event) Landing Page - Exclusive Shoveler': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day Dedicated Shoveler',
    ],
  },
  'Prime Day (Event) Landing Page - Shoveler Inclusion': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day Blended Shoveler Inclusion',
    ],
  },
  'Deal Shoveler (2-weeks)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Basic Deal Shoveler',
    ],
  },
  'Deals Stripe (2-weeks)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deal Hero Feature',
    ],
  },
  'Ambient Endcap - Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Ambient Endcap - Exclusive',
    ],
  },
  'Ambient Endcap - Shared': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Ambient Endcap - Shared',
    ],
  },
  'Chilled Endcap - Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Chilled Endcap - Exclusive',
    ],
  },
  'Chilled Endcap - Shared': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Chilled Endcap - Shared',
    ],
  },
  'Frozen Endcap - Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Frozen Endcap - Exclusive',
    ],
  },
  'Specialty Page Exclusive Shoveler': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Dedicated Specialty Page Feature',
    ],
  },
  '[Basic] F3G Lobby Pallet Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'F3G Lobby Pallet',
      'Deal Shoveler (4-weeks)',
      'Storefront Enhanced Shoveler (4-weeks)',
    ],
  },
  '[Basic] F3G Perimeter Pallet Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'F3G Perimeter Pallet',
      'Deal Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (4-weeks)',
    ],
  },
  '[Basic] NF Perimeter Pallet Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'NF Perimeter Pallet',
      'Deal Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (4-weeks)',
    ],
  },
  'Non Food Perimeter Pallet Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Storefront Shoveler - Standard (4-weeks)',
      'Deal Shoveler (4-weeks)',
      'Non-Food Perimeter Pallet',
    ],
  },
  '[Basic] Endcap Bundle - Ambient Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Ambient Endcap - Exclusive',
      'Deal Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
    ],
  },
  '[Premium] Endcap Bundle - Ambient Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Ambient Endcap - Exclusive',
      'Deal Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
    ],
  },
  '[Basic] Endcap Bundle - Ambient Shared': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Ambient Endcap - Shared',
      'Deal Shoveler (4-weeks)',
    ],
  },
  '[Basic] Endcap Bundle - Chilled Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Chilled Endcap - Exclusive',
      'Deal Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
    ],
  },
  '[Basic] Endcap Bundle - Chilled Shared': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Chilled Endcap - Shared',
      'Deal Shoveler (4-weeks)',
    ],
  },
  '[Basic] Endcap Bundle - Frozen Exclusive': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Frozen Endcap - Exclusive',
      'Deal Shoveler (4-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
    ],
  },
  '[Basic] Endcap Bundle - Frozen Shared': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Frozen Endcap - Shared',
      'Deal Shoveler (4-weeks)',
    ],
  },
  '[Premium] Prime Day Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day (Lead-In) Landing Page - Exclusive Shoveler',
      'Prime Day (Event) Landing Page - Exclusive Shoveler',
      'Storefront Shoveler - Standard (2-weeks)',
      'Shared Endcap',
    ],
  },
  '[Standard] Prime Day Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day (Lead-In) Landing Page - Exclusive Shoveler',
      'Prime Day (Event) Landing Page - Exclusive Shoveler',
    ],
  },
  '[Standard] 2-week Spend & Save Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deals Stripe (2-weeks)',
      'Category Hero (2-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
      'Custom Landing Page (2-weeks)',
    ],
  },
  '[Premium] 2-week Spend & Save Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deals Stripe (2-weeks)',
      'Category Hero (2-weeks)',
      'Category Hero (2-weeks)',
      'Storefront Shoveler - Standard (2-weeks)',
      'Custom Landing Page (2-weeks)',
    ],
  },
  '[Standard] 4-week Spend & Save Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deals Stripe (4-week)',
      'Category Hero (4-weeks)',
      'Storefront Shoveler - Standard (4-weeks)',
      'Custom Landing Page (4-weeks)',
    ],
  },
  '[Premium] 4-week Spend & Save Bundle': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deals Stripe (4-week)',
      'Category Hero (4-weeks)',
      'Category Hero (4-weeks)',
      'Storefront Shoveler - Standard (4-weeks)',
      'Custom Landing Page (4-weeks)',
      'Exclusive Endcap',
    ],
  },
  'Deal Shoveler (1-week)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deal Shoveler (1-week)',
    ],
  },
  'Deal Shoveler (4-weeks)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deal Shoveler (4-weeks)',
    ],
  },
  'Prime Day (Lead-In) Landing Page - Exclusive Shoveler': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day (Lead-In) Landing Page - Exclusive Shoveler',
    ],
  },
  'Prime Day (Lead-In) Landing Page  - Shoveler Inclusion': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day (Lead-In) Landing Page  - Shoveler Inclusion',
    ],
  },
  'Deals Stripe (1-week)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deals Stripe (1-week)',
    ],
  },
  'Deals Stripe (4-week)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deals Stripe (4-week)',
    ],
  },
};

export default Conversion;
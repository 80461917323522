import { getUsername } from '../../selectors/user';
import {
  MetricLocations,
  MetricNames,
  MetricTypes,
  PerformanceTimerNames,
} from '../../constants';
import {
  loadCampaigns,
  setCampaigns,
  assignCampaignsToPackages,
  setCampaignsLoadingFinished,
  onPerformanceTimerStop,
  onDashboardInit,
  onUtilizationShowResults,
  onNotificationsInit,
} from '../../actionCreators';
import { submitAppSyncError, submitStringMetric, submitTimerMetric } from '../../metrics';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import window from '../../helpers/window';
import { appsyncToLocal } from '../../helpers/translateCampaigns';
import { getError, parseGraphQLResponse } from '../../helpers/api';
import { joinBusinessStartYear } from '../../helpers/common';
import { arePackagesLoaded } from '../../selectors/package';
import { areCampaignsLoaded } from '../../selectors/campaign';

export default (dispatch, getState, action, next) => {
  const state = getState();
  const username = getUsername(state);

  const { payload: { startYear, business, nextToken } } = action;
  const businessStartYear = joinBusinessStartYear(business, startYear)

  const params = {
    business,
    startYear,
    nextToken,
  };

  const metricContext = {
    location: MetricLocations.GRID_VIEW_REQUEST_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: params,
    action: MetricNames.LOAD_CAMPAIGNS_BY_BUSINESS,
    username,
  };

  const requestGetCampaigns = (params) => {
    return API.graphql(graphqlOperation(queries.getCampaignsByBusinessStartYear, params));
  }

  const onParseResponse = (response) => {
    const { items, nextToken } = parseGraphQLResponse(response, 'getCampaignsByBusinessStartYear');
    const { Meta: { metadata } } = state;

    const translatedCampaigns = items.map((currentCampaign) => {
      return appsyncToLocal(
        currentCampaign,
        metadata
      )
    });
    return {
      translatedCampaigns,
      nextToken,
    }
  };

  const onUpdateCampaignsInState = (translatedCampaigns, nextToken) => {
    dispatch(setCampaigns(businessStartYear, translatedCampaigns));

    return nextToken;
  };

  const onLoadMoreCampaigns = (nextToken) => {
    if (nextToken) {
      dispatch(loadCampaigns(business, startYear, nextToken));
    } else {
      dispatch(setCampaignsLoadingFinished(businessStartYear));
      dispatch(assignCampaignsToPackages(businessStartYear));
      const latestState = getState();
      if (arePackagesLoaded(latestState) && areCampaignsLoaded(latestState)) {
        dispatch(onPerformanceTimerStop(PerformanceTimerNames.APP_INIT));
        /*
          TODO check if below dispatches needs to be called after all packages/campaigns are loaded or not
          Update the TODO in loadPackages and src/middleware/appMiddleware/index.test.js as well
           - hanwooll
         */
        dispatch(onDashboardInit());
        dispatch(onUtilizationShowResults());
        dispatch(onNotificationsInit());
      }
      submitTimerMetric(MetricNames.LAST_CAMPAIGN_LOADED, Math.floor(window.performance.now()));
    }
  };

  const onError = (error) => {
    submitAppSyncError(error, metricContext);
  };

  submitStringMetric(MetricNames.LOAD_CAMPAIGNS_BY_BUSINESS, metricContext);

  requestGetCampaigns(params)
    .then(onParseResponse)
    .then(({ translatedCampaigns, nextToken }) => {
      return onUpdateCampaignsInState(translatedCampaigns, nextToken);
    })
    .then(onLoadMoreCampaigns)
    .catch(onError);

  return next(action);
};

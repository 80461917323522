import React from 'react';

import styles from '../../GridViewCell/GridViewCell.module.scss';
import { styledInputProps } from '../../../proptypes';

const TextAreaInput = ({
  onSubmit,
  inputValue,
  onInputChange,
  onBlur,
  attributes = {},
}) => {
  const defaultAttributes = {
    cols: 70,
    rows: 20,
  };

  const mergedAttributes = {
    ...defaultAttributes,
    ...attributes,
  };

  return (
    <form onSubmit={onSubmit}>
      <textarea
        value={inputValue || ''}
        onChange={onInputChange}
        className={styles.activeInput}
        ref={(input) => { return setTimeout(() => { return input && input.focus(); }, 0); }}
        onBlur={onBlur}
        {...mergedAttributes}
      />
    </form>
  );
};

TextAreaInput.propTypes = styledInputProps;

export default TextAreaInput;

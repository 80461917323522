import React, { useEffect } from 'react';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Text from '@amzn/meridian/text';
import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import { getUserSetting } from '../../graphql/queries';
import { CloudSettingsDataType, Language } from '../../constants';
import { CONNECTION_CHECK_INTERVAL } from '../../constants/appSync';

const AppSyncCheckConnection = (props) => {
  const { username, onSetAppSyncConnectionStatus, isAppSyncConnectionAlive } = props;

  const onRefreshPage = () => window.location.reload();

  const checkConnection = async () => {
    try {
      await API.graphql(graphqlOperation(getUserSetting, {
        username,
        dataType: CloudSettingsDataType.BUSINESS_SELECTOR_CFG,
      }));

      if (!isAppSyncConnectionAlive) {
        onSetAppSyncConnectionStatus(true);
      }
    } catch (error) {
      if (isAppSyncConnectionAlive) {
        onSetAppSyncConnectionStatus(false, error);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(checkConnection, CONNECTION_CHECK_INTERVAL);

    return () => clearInterval(intervalId);
  }, [isAppSyncConnectionAlive]);

  return (
    <Modal
      title={Language.APP_SYNC_CONNECTION_TIMEOUT_ERROR_TITLE}
      open={!isAppSyncConnectionAlive}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="modal-connection-lost-container"
    >
      <Text id="modal-connection-lost-body">
        {Language.APP_SYNC_CONNECTION_TIMEOUT_ERROR_BODY}
      </Text>
      <ModalFooter>
        <Row alignmentHorizontal="end" widths="fit">
          <Button type="primary" size="small" onClick={onRefreshPage}>
            {Language.APP_SYNC_CONNECTION_TIMEOUT_ERROR_REFRESH_BUTTON}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default AppSyncCheckConnection;
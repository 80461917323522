import { connect } from 'react-redux';
import _ from 'lodash';
import RateCardContainer from './RateCardContainer';
import { Form } from '../../constants';
import { getRateCardById } from '../../helpers/rateCard';
import {
  onRateCardAssetsLoadList,
  onRateCardResetAssets,
} from '../../actionCreators';

const hasFormContentChanged = (state) => {
  if (!state.form || !state.form[Form.RATE_CARD_NAME]) {
    return false;
  }

  const {
    form: {
      [Form.RATE_CARD_NAME]: {
        initial = {},
        values = {},
      },
    },
  } = state;

  const changes = _.differenceWith(_.toPairs(values), _.toPairs(initial), _.isEqual);

  return !!changes.length;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    id,
  } = ownProps;

  const {
    RateCard: {
      cards,
      currentTab,
    },
  } = state;

  const rateCard = id ? getRateCardById(cards, id) : null;

  const shouldShowOnCloseConfirmation = hasFormContentChanged(state);

  return {
    currentTab,
    loadedRateCard: rateCard,
    isLoading: false,
    shouldShowOnCloseConfirmation,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onLoadAssets: (rateCardId) => {
      dispatch(onRateCardResetAssets());
      dispatch(onRateCardAssetsLoadList({ rateCardId }, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardContainer);

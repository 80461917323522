import React from 'react';
import Package from '../Package';
import { campaignFormProps } from '../../proptypes';
import WithHistoryTiming from '../WithHistoryTiming';
import { PageNames } from '../../constants';

const EditPackagePage = (props) => {
  const {
    match: { params: { id } },
    location: { search },
  } = props;
  const params = new URLSearchParams(search);
  const campaignId = params.get('campaignId');
  return <Package isNew={false} id={id} campaignId={campaignId} />;
};

EditPackagePage.propTypes = campaignFormProps;

export default WithHistoryTiming(EditPackagePage, { pageName: PageNames.EDIT_PACKAGE });

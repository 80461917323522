import React, { useEffect, useState } from 'react';
import arrayMove from '../helpers/arrayMove';
import { DragAndDropWidget } from '../constants';

import styles from './withDragAndDrop.module.scss';

const withDragAndDrop = (Component) => (props) => {
  const { items } = props;

  const [itemsList, setItemsList] = useState(items);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [droppedItem, setDroppedItem] = useState(null);

  useEffect(() => {
    setItemsList(items);
  }, [items]);

  const moveColumn = (indexFrom, indexTo) => {
    if (indexTo >= itemsList.length || indexTo < 0) { return; }
    const newOrder = arrayMove(itemsList, indexFrom, indexTo);
    setItemsList(newOrder);
  };

  const onDragStart = (evt, index) => {
    evt.dataTransfer.setData(DragAndDropWidget.DATA_TRANSFER_ID, index);
    setDraggedItem(index);
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
  };

  const onDrop = (evt, index) => {
    evt.preventDefault();
    const column = evt.dataTransfer.getData(DragAndDropWidget.DATA_TRANSFER_ID);
    moveColumn(column, index);
    setDroppedItem(index);
    setTimeout(() => { setDroppedItem(null); }, 1000);
  };

  const onDragEnter = (index) => {
    setHoveredItem(index);
  };

  const onDragEnd = () => {
    setHoveredItem(null);
    setDraggedItem(null);
  };

  return (
    <Component
      {...props}
      itemsList={itemsList}
      setItemsList={setItemsList}
      hoveredItem={hoveredItem}
      draggedItem={draggedItem}
      droppedItem={droppedItem}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragEnd={onDragEnd}
      dragStyles={styles}
    />
  );
};

export default withDragAndDrop;

import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import onGraphQLAction from '../../actionCreators/graphqlAction';
import { createUserSession } from '../../graphql/mutations';
import { submitAppSyncError, submitError } from '../../metrics';

export default (dispatch, getState, action, next) => {
  const { User: { currentUser: { username } } } = getState();

  const metricContext = {
    location: MetricLocations.NOTIFICATIONS_MIDDLEWARE,
    type: MetricTypes.APPSYNC,
    data: {
      username,
    },
    action: MetricNames.CREATE_NOTIFICATIONS_LAST_SEEN_TIME,
    username,
  };

  dispatch(onGraphQLAction({
    graphqlAction: createUserSession,
    data: {
      input: {
        username,
      },
    },
    label: GraphQLLabels.CREATE_USER_SESSION,
    onSuccessFunction({ data: { createUserSession: userSessionData } }) {
      if (!userSessionData) {
        submitError('createUserSession returned an empty result', metricContext);
      }
    },
    onFailureFunction(error) {
      submitAppSyncError(error, metricContext);
    },
  }));

  return next(action);
};

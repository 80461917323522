import { API, graphqlOperation } from 'aws-amplify';
import {
  MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { submitAppSyncError, submitStringMetric } from '../../metrics';
import { getAssetsForRateCard } from '../../graphql/queries';
import {
  onRateCardAssetsLoadList,
  onRateCardResetAssets,
  onRateCardSetAssetsList,
  onRateCardSetAssetsListLoaded,
} from '../../actionCreators';

import {
  translateAssetFromAppsyncToLocal
} from '../../helpers/translateRateCardAssets';

export default (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const {
    payload: {
      nextToken,
      rateCardId,
      forceReload,
    },
  } = action;

  if (forceReload) {
    dispatch(onRateCardResetAssets());
  }

  /* eslint-disable no-shadow */
  const loadAssets = (rateCardId, token) => {
    const {
      User: { currentUser: { username } },
      Meta: { metadata },
    } = getState();

    const params = {
      nextToken: token,
      rateCardId,
    };

    const metricContext = {
      location: MetricLocations.RATE_CARD_ASSETS_LIST_MIDDLEWARE,
      type: MetricTypes.APPSYNC,
      data: params,
      action: MetricNames.LOAD_RATE_CARDS_ASSETS,
      username,
    };

    submitStringMetric(MetricNames.LOAD_RATE_CARDS_ASSETS, metricContext);

    return API.graphql(graphqlOperation(getAssetsForRateCard, params))
      .then(({ data }) => {
        if (!data.getAssetsForRateCard) {
          throw new Error('Response doesn\'t contain \'getAssetsForRateCard\' param!');
        }
        /* eslint-disable no-shadow */
        const { getAssetsForRateCard: { items, nextToken } } = data;

        const translatedAssets = items.map((asset) => {
          return {
            ...asset,
            campaign: translateAssetFromAppsyncToLocal(JSON.parse(asset.campaign), metadata),
          };
        });

        dispatch(onRateCardSetAssetsList(translatedAssets));
        if (nextToken) {
          dispatch(onRateCardAssetsLoadList({ rateCardId, nextToken }));
        } else {
          dispatch(onRateCardSetAssetsListLoaded());
        }
      }).catch((error) => {
        submitAppSyncError(error, metricContext);
      });
  };

  loadAssets(rateCardId, nextToken);

  return next(action);
};

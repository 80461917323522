import { CampaignColumns } from '../constants';

const getColumnDisplayByColumnName = (columnName) => {
  let name = null;
  Object.keys(CampaignColumns).forEach((key) => {
    if (CampaignColumns[key].name === columnName) {
      name = CampaignColumns[key].display;
    }
  });
  return name;
};

export default getColumnDisplayByColumnName;

import { connect } from 'react-redux';

import {
  onCloseExportModal,
  onExportCurrentView,
  onUploadExportSettings,
} from '../../actionCreators';
import { isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';

import ExportModal from './ExportModal';

export const mapStateToProps = (state) => {
  const {
    GridViewPage: { exportModalOpen },
    UserSettings: {
      exportPage: { pageSelection, exportType, exportFilters },
    },
  } = state;
  return {
    isOpen: exportModalOpen,
    isOmnichannelMode: isWebsiteOmnichannelViewMode(state),
    pageSelection,
    exportType,
    exportFilters,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onExport: ({
      pageSelection,
      exportType,
      exportFilters,
    }) => { dispatch(onExportCurrentView({ pageSelection, exportType, exportFilters })); },
    onCloseModal: () => { dispatch(onCloseExportModal()); },
    onSelect: ({
      pageSelection,
      exportType,
      exportFilters,
    }) => { dispatch(onUploadExportSettings({ pageSelection, exportType, exportFilters })); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);

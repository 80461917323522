// TODO: move it to a more centralized location
import _ from 'lodash';
import { connect } from 'react-redux';
import { blur, formValueSelector, getFormValues } from 'redux-form';
import {
  CampaignColumns,
  CampaignStatuses,
  CampaignTypes,
  Form,
  InputTypes,
  PlacementSource,
  VendorTypes,
} from '../../../constants';
import FormField from './FormField';
import {
  checkboxControl,
  datePickerControl,
  inputControl,
  numberControl,
  textAreaControl,
  timeControl,
  timePickerControl,
} from '../../../helpers/formRenders';
import SelectControl from '../Controls/Select';
import MultiSelectControl from '../Controls/MultiSelect';
import JsonInputControl from '../../Controls/JsonInput/JsonInput';
import {
  getString,
  getTranslatedOptionsList,
  isFinalizedAdLeftCampaign,
  isFreshBusiness,
  isWFMBusiness,
  shouldDisableSovForSovType,
  trimAndLowerCase,
} from '../../../helpers/common';
import {
  createCampaignFieldForIndex,
  extractFieldGroupParts,
  getCampaignFieldsByBusiness,
  getCurrentPackageActiveTab,
  getFieldNameWithFullPath,
} from '../../../helpers/form';
import { isExclusiveInStorePackage, isPlacementSourceVssm } from '../../../helpers/package';
import { getItemRequiredFields } from '../../../helpers/requiredFields';
import { getOmnichannelPackageTab } from '../../../selectors/packageTabs';
import { filterInStoreStatusOptions } from '../../../helpers/filterInStoreStatuses';
import { isInStoreCampaign } from '../../../helpers/campaign';
import { getActiveCampaign } from '../index';
import createColumn from '../../../helpers/createColumn';
import { isReadOnlyMode } from '../../../selectors/sitewide';

export const getComponentByType = (type) => {
  switch (type) {
    case InputTypes.INPUT:
      return inputControl;
    case InputTypes.SINGLE_SELECT:
      return SelectControl;
    case InputTypes.MULTI_SELECT:
      return MultiSelectControl;
    case InputTypes.SINGLE_CHECKBOX:
      return checkboxControl;
    case InputTypes.TEXT_AREA_INPUT:
      return textAreaControl;
    case InputTypes.JSON_INPUT:
      return JsonInputControl;
    case InputTypes.DATE_TIME:
      return datePickerControl;
    case InputTypes.TIME_INPUT:
      return timePickerControl;
    case InputTypes.NUMBER_INPUT:
      return numberControl;
    default:
      return inputControl;
  }
};

const hasOptionsList = (type) => {
  return [
    InputTypes.SINGLE_SELECT,
    InputTypes.MULTI_SELECT,
  ].includes(type);
};

const selector = formValueSelector(Form.NAME);
const formValues = getFormValues(Form.NAME);

const getParentFieldValue = (state, dependencyField, groupName) => {
  const parentFieldValue = selector(
    state,
    getFieldNameWithFullPath(dependencyField, groupName)
  );

  if (!parentFieldValue) {
    const campaignField = createCampaignFieldForIndex(getCurrentPackageActiveTab(state));

    return selector(
      state,
      campaignField(createColumn(dependencyField, '', '')).name,
    )
  }

  return parentFieldValue;
};

const getOptions = (state, options, dependsOn = [], groupName, index = 0) => {
  if (!options) {
    return [];
  }
  if (index >= dependsOn.length) {
    return options;
  }

  const parentFieldValue = getParentFieldValue(state, dependsOn[index], groupName);
  if (!parentFieldValue) {
    return [];
  }
  if (options[parentFieldValue]) {
    return getOptions(
      state,
      options[parentFieldValue],
      dependsOn,
      groupName,
      index + 1
    );
  }
  return [];
};

const getOptionList = (state, fields, fieldName, groupName) => {
  if (!fields || !fields[fieldName]) {
    return [];
  }
  const { options, dependsOn } = fields[fieldName];

  return getOptions(state, options, dependsOn, groupName);
};

export const mapStateToProps = (state, ownProps) => {
  const {
    Meta: {
      metadata: {
        fields,
      },
    },
    Sitewide: {
      selectedBusiness,
    },
  } = state;

  const {
    name,
    display,
    inputType,
    allowOverride,
  } = ownProps;

  let { isDisabled = false } = ownProps;
  const [groupName, fieldName] = extractFieldGroupParts(name);

  const campaignColumnsSet = getCampaignFieldsByBusiness(selectedBusiness);
  const currentSelectedBusiness = getString(selector(state, campaignColumnsSet.BUSINESS.name));
  const currentCampaign = getActiveCampaign(
    selector(state, 'campaigns'),
    getOmnichannelPackageTab(state)
  );
  let options = [];
  if (hasOptionsList(inputType)) {
    options = getOptionList(state, fields, fieldName, groupName);

    // allow custom user input
    if (allowOverride) {
      const fieldValue = getString(selector(state, fieldName));
      if (fieldValue && !options.includes(fieldValue)) {
        options.push(fieldValue);
      }
    }

    if (!options.length) {
      isDisabled = true;
    }
  }

  if (campaignColumnsSet.TARGETING.name === fieldName
    || campaignColumnsSet.TARGETING_EXCLUDE.name === fieldName) {
    const desktopSlot = getString(selector(state, campaignColumnsSet.DESKTOP_SLOT.name));
    isDisabled = isFinalizedAdLeftCampaign(desktopSlot);
  }
  if (campaignColumnsSet.STATUS.name === fieldName
    && (currentCampaign && isInStoreCampaign(currentCampaign))
    || isExclusiveInStorePackage(selector(state, campaignColumnsSet.PACKAGE_NAME.name))) {
    options = filterInStoreStatusOptions(options)
  }

  if (campaignColumnsSet.PLACEMENT.name === fieldName
    && selector(state, campaignColumnsSet.PLACEMENT_SOURCE.name) !== PlacementSource.VSSM
  ) {
    options = options.filter((option) => {
      return !(trimAndLowerCase(option).includes(trimAndLowerCase(PlacementSource.VSSM)));
    });
  }

  if (campaignColumnsSet.PACKAGE_NAME.name === fieldName) {
    const isVssm = isPlacementSourceVssm(
      selector(state, campaignColumnsSet.PLACEMENT_SOURCE.name)
    );

    const optionIncludesVssm = (option) => trimAndLowerCase(option).includes(trimAndLowerCase(PlacementSource.VSSM));

    if (isVssm) {
      options = options.filter(optionIncludesVssm);
    } else {
      const filteredOptions = options.filter((option) => !optionIncludesVssm(option)).sort();
      const [houseOptions, allOtherOptions] = _.partition(filteredOptions, (option) => {
        return option.includes(VendorTypes.HOUSE);
      });

      options = [
        ...houseOptions,
        ...allOtherOptions,
      ];
    }
  }

  const isWFM = isWFMBusiness(currentSelectedBusiness);
  const isFresh = isFreshBusiness(currentSelectedBusiness);

  if (isFresh || isWFM) {
    const sovTypeValue = getString(selector(state, campaignColumnsSet.SOV_TYPE.name));

    if (fieldName === campaignColumnsSet.SOV.name && shouldDisableSovForSovType(sovTypeValue)) {
      isDisabled = true;
    }
  }

  if (isReadOnlyMode(state)) {
    isDisabled = true;
  }

  return {
    ...ownProps,
    // we don't use labels, just pure placeholders regarding to UI/UX
    placeholder: display,
    component: getComponentByType(inputType),
    isRequired: getItemRequiredFields(formValues(state)).includes(fieldName),
    allowOverride,
    options: options && options.length > 0
      ? getTranslatedOptionsList(options)
      : [],
    isDisabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    // For some reason native redux-form onBlur() action removes changes made by onChange()
    // when using together with Meridian's autocomplete functionality.
    // This blur() action creator is supposed to be equal to the original onBlur()
    // and doing exactly the same thing but it turns out that it works well and the
    // bug doesn't happen.
    customOnBlur(fieldName, value) {
      dispatch(blur(Form.NAME, fieldName, value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormField);

/* eslint-disable no-param-reassign */
import {
  Validation,
  Language,
} from '../constants';
import { getSpreadLimits } from './rateCard';
import { RateCardColumns } from '../constants/rateCard';

/**
 * Form validators.
 */

export const isEmpty = (value) => !(value || typeof value === 'number');
export const tooLong = (value) => value && value.length > Validation.MAX_LENGTH;

const validateBasicRequiredFields = (values, errors) => {
  const requiredFields = [
    RateCardColumns.VENDOR.name,
    RateCardColumns.MERCHANT_INVESTMENT.name,
    RateCardColumns.VERTICAL.name,
    RateCardColumns.CONFIGURATION_VERSION.name,
    RateCardColumns.MARKETING_MANAGER.name,
  ];

  requiredFields.forEach((requiredField) => {
    if (isEmpty(values[requiredField])) {
      errors[requiredField] = Language.VALIDATION_REQUIRED;
    }
  });

  return errors;
};

const hasExceededSpreadLimit = ({ className, spread }) => {
  const limits = getSpreadLimits({ className });
  if (!limits) {
    return false;
  }
  const parsedSpread = parseInt(spread, 10);

  return (parsedSpread < limits.min || parsedSpread > limits.max);
};

const hasExceededVendorMaxLimit = (placementsCount, vendorMax) => {
  return parseInt(placementsCount, 10) > parseInt(vendorMax, 10);
};

const validateMerchIntent = (values, errors) => {
  const columnName = RateCardColumns.MERCHANDISING_INTENT.name;
  if (!values[columnName]) {
    return errors;
  }

  const merchIntentErrors = [];
  let totalSpread = 0;

  values[columnName].forEach((item, merchIndex) => {
    const itemErrors = {};
    if (!item || !item.spread) {
      itemErrors.spread = Language.VALIDATION_REQUIRED;
    } else {
      totalSpread += parseInt(item.spread, 10);
      if (hasExceededSpreadLimit(item)) {
        const limits = getSpreadLimits(item);
        itemErrors.spread = `Allowed values: ${limits.min}-${limits.max}`;
      }

      if (Object.keys(itemErrors).length) {
        merchIntentErrors[merchIndex] = itemErrors;
      }
    }
  });

  const errorsList = totalSpread > 100 || totalSpread < 100
    ? Array(values[columnName].length).fill({ spread: 'Total spread should be 100%' })
    : merchIntentErrors;

  if (errorsList.length) {
    errors[columnName] = errorsList;
  }

  return errors;
};

const validatePlacements = (placements, errors) => {
  if (!placements || !placements.length) {
    return errors;
  }

  placements.forEach((placement, index) => {
    const placementErrors = {};
    // Check the input contains only numbers (0-9)
    if (!new RegExp(/^-?\d*\.?\d*$/, 'gi').test(placement.plannedPlacements)) {
      placementErrors.plannedPlacements = `Only numbers are allowed!`;
    }
    if (hasExceededVendorMaxLimit(placement.plannedPlacements, placement.vendorMax)
      || placement.plannedPlacements < 0) {
      placementErrors.plannedPlacements = `Allowed values: 0-${placement.vendorMax}`;
    }

    if (Object.keys(placementErrors).length) {
      if (!errors[index]) {
        errors[index] = {};
      }
      errors[index] = placementErrors;
    }
  });

  return errors;
};

export const validate = (values) => {
  const errors = {};

  validateBasicRequiredFields(values, errors);
  validateMerchIntent(values, errors);
  [
    RateCardColumns.LIMITED_PLACEMENTS.name,
    RateCardColumns.MAIN_PLACEMENTS.name
  ].forEach(placementType => {
    errors[placementType] = [];

    validatePlacements(
      values[placementType],
      errors[placementType]
    );
  });

  return errors;
};

export const warn = () => {
  const warnings = {};

  return warnings;
};

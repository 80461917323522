import React from 'react';
import classnames from 'classnames';
import styles from '../../../PackageForm.module.scss';
import FormField from '../../../FormField';
import { formCampaignColumns } from '../../../../../proptypes';
import { createCampaignFieldForIndex } from '../../../../../helpers/form';

const ContentAsins = (props) => {
  const {
    campaignColumns,
    currentTab,
    shouldShowSpanishCopy,
    shouldShowTranslationStatus,
  } = props;
  const campaignField = createCampaignFieldForIndex(currentTab);

  const getSpanishCopy = () => {
    if (!shouldShowSpanishCopy) {
      return null;
    }

    return (
      <div className={styles.formGroupSection}>
        <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
          <FormField {...campaignField(campaignColumns.SPANISH_COPY)} />
        </div>
      </div>
    );
  };

  const getTranslationStatus = () => {
    if (!shouldShowTranslationStatus) {
      return null;
    }

    return (
      <div className={styles.formControl}>
        <FormField {...campaignField(campaignColumns.TRANSLATION_STATUS)} />
      </div>
    );
  };

  const getTrafficContent = () => {
    const spanishCopy = getSpanishCopy();
    const translationStatus = getTranslationStatus();
  
    return (
      <div className={styles.formGroupWrapper}>
      <div className={styles.formGroupContent}>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.ALT_TEXT)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.BODY_COPY)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.CREATIVE_STATUS)} />
          </div>
        </div>
        {spanishCopy}
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl)}>
            <FormField {...campaignField(campaignColumns.SUB_HEADLINE)} />
          </div>
          <div className={classnames(styles.formControl)}>
            <FormField {...campaignField(campaignColumns.HEADLINE)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          {translationStatus}
          <div className={classnames(styles.formControl, styles)}>
            <FormField {...campaignField(campaignColumns.CTA)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.CUSTOMER_CTA_URL)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.ASINS)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.DESKTOP_IMAGE_1x)} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.DESKTOP_IMAGE_2x)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.MOBILE_IMAGE_1x)} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.MOBILE_IMAGE_2x)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.TABLET_IMAGE_1x)} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignField(campaignColumns.TABLET_IMAGE_2x)} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
            <FormField {...campaignField(campaignColumns.NEED_INVENTORY_AWARENESS)} />
          </div>
        </div>
      </div>
      <div className={styles.formGroupLabel}>
        <div className={styles.formGroupLabelWrapper}>
          <p className={styles.formGroupDescription}>
            &nbsp;
          </p>
        </div>
      </div>
    </div>
    )
  }
  const trafficContent = getTrafficContent();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Online Content & ASINs</div>
        {trafficContent}
    </div> 
  );
};


export default ContentAsins;

import { CampaignColumns } from '../constants';
import { createRequiredFields } from '../helpers/createRequiredFields';

const PACKAGE_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
  ],
  STATUS_APPROVED: [
    CampaignColumns.VERTICAL.name,
    CampaignColumns.VENDOR.name,
  ],
  INPUTS_COMPLETE_BASE: [
    CampaignColumns.MARKETING_MANAGER.name,
  ],
  INPUTS_COMPLETE_ADDITIONAL: [],
  INPUTS_COMPLETE_IN_STORE: [
    CampaignColumns.MARKETING_MANAGER.name,
    CampaignColumns.VERTICAL.name,
    CampaignColumns.VENDOR.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.START_TIME.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.END_TIME.name,
  ],
  APPROVED_IN_STORE: [
    CampaignColumns.MARKETING_MANAGER.name,
    CampaignColumns.VERTICAL.name,
    CampaignColumns.VENDOR.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.START_TIME.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.END_TIME.name,
  ],
};

const CAMPAIGN_FIELDS = {
  BASE: [
    CampaignColumns.STATUS.name,
    CampaignColumns.PLACEMENT.name,
    CampaignColumns.START_DATE.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.PAGETYPE.name,
  ],
  STATUS_APPROVED: [
    CampaignColumns.PAGE_NAME.name,
    CampaignColumns.PAGE_ID.name,
    CampaignColumns.DESKTOP_SLOT.name,
    CampaignColumns.MOBILE_SLOT.name,
    CampaignColumns.CAMPAIGN_NAME.name,
    CampaignColumns.COPY.name,
    CampaignColumns.WIDGET.name,
  ],
  INPUTS_COMPLETE_BASE: [
    CampaignColumns.CAMPAIGN_NAME.name,
    CampaignColumns.PAGE_ID.name,
    CampaignColumns.DESKTOP_SLOT.name,
    CampaignColumns.MOBILE_SLOT.name,
    CampaignColumns.COPY.name,
    CampaignColumns.DEDUPE_RULES.name,
    CampaignColumns.SOV_TYPE.name,
    CampaignColumns.SOV.name,
  ],
  INPUTS_COMPLETE_ADDITIONAL: [
    CampaignColumns.HERO_ASINS.name,
    CampaignColumns.INVENTORY_AWARENESS.name,
  ],
  INPUTS_COMPLETE_IN_STORE: [
    CampaignColumns.START_DATE.name,
    CampaignColumns.START_TIME.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.END_TIME.name,
    CampaignColumns.IS_DEAL.name,
    CampaignColumns.COPY.name,
    CampaignColumns.ASINS.name,
    CampaignColumns.PROMO_TYPE.name,
  ],
  APPROVED_IN_STORE: [
    CampaignColumns.START_DATE.name,
    CampaignColumns.START_TIME.name,
    CampaignColumns.END_DATE.name,
    CampaignColumns.END_TIME.name,
    CampaignColumns.IS_DEAL.name,
    CampaignColumns.COPY.name,
    CampaignColumns.ASINS.name,
    CampaignColumns.PROMO_TYPE.name,
  ],
};

const FRESH_REQUIRED_FIELDS = createRequiredFields(
  PACKAGE_FIELDS,
  CAMPAIGN_FIELDS,
);

export default FRESH_REQUIRED_FIELDS;
import { connect } from 'react-redux';
import RateCardList from './RateCardsListButtons';
import {
  onRateCardDelete,
  onRateCardSetTab,
} from '../../actionCreators';
import { Language } from '../../constants';
import { RateCardTabsList } from '../../constants/rateCard';
import { hasPublishedAssets, isCurrentConfigTheLatest } from '../../selectors/rateCardSelectors';
import { getPublishedCampaignListUrl } from '../../helpers/rateCard';

export const mapStateToProps = (state, { rateCard: { id } }) => ({
  showCloneButton: isCurrentConfigTheLatest(state),
  publishedCampaignListUrl: getPublishedCampaignListUrl(id),
  hasPublishedAssets: hasPublishedAssets(state, id),
});

export const mapDispatchToProps = (dispatch) => {
  return {
    onDelete: (rateCard) => {
      /* eslint-disable no-alert */
      if (window.confirm(Language.RATE_CARD_DELETE_CONFIRMATION)) {
        dispatch(onRateCardDelete(rateCard));
      }
    },
    onAuditLogOpen() {
      dispatch(onRateCardSetTab({ tabName: RateCardTabsList.AUDIT_LOG.name }));
    },
    onEditRateCard() {
      dispatch(onRateCardSetTab({ tabName: RateCardTabsList.RATE_CARD.name }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardList);

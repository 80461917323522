import React from 'react';
import Input from '@amzn/meridian/input';
import PropTypes from 'prop-types';
import styles from './RateCardSearch.module.scss';

const RateCardSearch = (props) => {
  const {
    searchQuery,
    onChange,
  } = props;

  return (
    <div className={styles.searchBoxContainer}>
      <Input
        id="search-input"
        value={searchQuery}
        onChange={onChange}
        type="text"
        placeholder="search...."
        size="small"
      />
    </div>
  );
};

RateCardSearch.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RateCardSearch;

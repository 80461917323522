import { Hosts } from '../constants';

const oauth = {
  domain: 'f3-marketing-calendar-cdk-prod.auth.us-west-2.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `https://${Hosts.PROD_READABLE}`,
  redirectSignOut: `https://${Hosts.PROD_READABLE}`,
  responseType: 'code',
};

const config = {
  Auth: {
    identityPoolId: 'us-west-2:3a99be4e-8cba-4145-bf21-31f331b9e060',
    region: 'us-west-2',
    userPoolId: 'us-west-2_UF88KVySP',
    userPoolWebClientId: '316hgdq6ij2ntbbourvn874kck',
    oauth,
  },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://uthq7br64fhznoth4wfbwtcbne.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
};

export default config;

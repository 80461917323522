import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import OmnichannelPackageTabs from './OmnichannelPackageTabs';
import {
  onPackageFormSetTab,
  onFormAddTab,
  onFormRemoveTab,
} from '../../../actionCreators';
import {
  CampaignTypes,
  Form,
  PACKAGE_TAB_INDEX,
  PackageTabTypes,
} from '../../../constants';
import {
  getOmnichannelPackageTab,
} from '../../../selectors/packageTabs';
import { areCampaignsLoaded } from '../../../selectors/campaign';

const selector = formValueSelector(Form.NAME);

const getTabNameForCampaign = ({ campaignType, language }) => {
  return campaignType === CampaignTypes.ONLINE
    ? `${CampaignTypes.ONLINE} ${language ? language : ''}`
    : CampaignTypes.IN_STORE;
};

const getTabList = (campaigns) => {
  const packageTab = {
    tabIndex: PACKAGE_TAB_INDEX,
    display: 'Basic Info',
  };

  if (!campaigns || !campaigns.length) {
    return [packageTab];
  }

  const tabList = [];
  campaigns.forEach((campaign, tabIndex) => {
    tabList.push({
      tabIndex,
      display: getTabNameForCampaign(campaign),
    });
  });
  tabList.unshift(packageTab);

  return tabList;
};

export const mapStateToProps = (state) => {
  const campaigns = selector(state, 'campaigns') || [];

  return {
    tabs: getTabList(campaigns),
    currentTab: getOmnichannelPackageTab(state),
    isLoadingCampaigns: !areCampaignsLoaded(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSwitchTab(tabName) {
      dispatch(onPackageFormSetTab(tabName, PackageTabTypes.OMNICHANNEL));
    },
    onAddTab(campaignType) {
      dispatch(onFormAddTab(campaignType));
    },
    onRemoveTab(tabIndex) {
      if (window.confirm('Are you sure you want to delete this campaign? (Changes aren\'t saved until you "Save" this form)')) {
        dispatch(onFormRemoveTab(tabIndex));
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OmnichannelPackageTabs);

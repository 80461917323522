import { connect } from 'react-redux';
import RateCardList from './RateCardList';
import {
  onRateCardLoadList,
  onRateCardUpdate,
} from '../../actionCreators';
import {
  getSearchFilteredCardList,
  isCurrentConfigTheLatest,
  shouldShowPagination,
} from '../../selectors/rateCardSelectors';

export const mapStateToProps = (state) => {
  return {
    cards: getSearchFilteredCardList(state),
    showPagination: shouldShowPagination(state),
    showNewRateCardButton: isCurrentConfigTheLatest(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: () => {
      dispatch(onRateCardLoadList());
    },
    onUpdate: (rateCard) => {
      dispatch(onRateCardUpdate(rateCard));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardList);

import createColumn from '../helpers/createColumn';
import { createBBColumn } from '../helpers/bulletinBoard';

export const PROGRAM_NAME = 'F3MarketingCalendar';

export const NOOP = 'NOOP';

export const ASSIGN_CAMPAIGNS_TO_PACKAGES = 'ASSIGN_CAMPAIGNS_TO_PACKAGES';
export const LOAD_CAMPAIGNS = 'LOAD_CAMPAIGNS';
export const LOAD_PACKAGES = 'LOAD_PACKAGES';
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS';
export const SET_PACKAGES = 'SET_PACKAGES';
export const SET_PACKAGES_WITH_CAMPAIGNS = 'SET_PACKAGES_WITH_CAMPAIGNS';

export const LOAD_CAMPAIGN = 'LOAD_CAMPAIGN';
export const KEYPRESS = 'KEYPRESS';
export const ON_RECEIVED_LAST_CAMPAIGN_IDS = 'ON_RECEIVED_LAST_CAMPAIGN_IDS';
export const ON_MIGRATE_LOCAL_TO_CLOUD = 'ON_MIGRATE_LOCAL_TO_CLOUD';
export const ON_METADATA_RECEIVED = 'ON_METADATA_RECEIVED';
export const ON_UPDATE_METADATA = 'ON_UPDATE_METADATA';
export const ON_ADD_METADATA_ENTRY = 'ON_METADATA_ENTRY';
export const ON_REMOVE_METADATA_ENTRY = 'ON_REMOVE_METADATA_ENTRY';
export const ON_CLEAR_METADATA = 'ON_CLEAR_METADATA';
export const REST_API = 'REST_API';
export const PAGE_INIT = 'PAGE_INIT';
export const GRAPHQL_ACTION = 'GRAPHQL_ACTION';
export const SELECT_BUSINESS = 'SELECT_BUSINESS';
export const SELECT_YEARS = 'SELECT_YEARS';
export const ON_BUSINESS_RECEIVED = 'ON_BUSINESS_RECEIVED';
export const ON_YEARS_RECEIVED = 'ON_YEARS_RECEIVED';
export const FILTER_VIEW_LOCAL_STORAGE_KEY = 'filters';
export const OPEN_TOAST = 'OPEN_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const ADD_ERROR_TO_CAMPAIGN = 'ADD_ERROR_TO_CAMPAIGN';
export const UPLOAD_LOCAL_METADATA = 'UPLOAD_LOCAL_METADATA';
export const SHOW_LOADING_SPINNER = 'SHOW_LOADING_SPINNER';
export const HIDE_LOADING_SPINNER = 'HIDE_LOADING_SPINNER';
export const SET_NEW_USER_EXPERIENCE_SHOWN = 'SET_NEW_USER_EXPERIENCE_SHOWN';
export const FEATURE_NOTIFICATION_STORAGE_KEY = 'mc-app-version';
// It's -1 to make sure the campaign tabs can start from 0
export const PACKAGE_TAB_INDEX = -1;

export const PackageActions = Object.freeze({
  ADD_PACKAGE: 'PackageActions/ADD_PACKAGE',
  EDIT_PACKAGE: 'PackageActions/EDIT_PACKAGE',
  DELETE_PACKAGE: 'PackageActions/DELETE_PACKAGE',
});
export const CampaignActions = Object.freeze({
  ADD_CAMPAIGN: 'CampaignActions/ADD_CAMPAIGN',
  EDIT_CAMPAIGN: 'CampaignActions/EDIT_CAMPAIGN',
  DELETE_CAMPAIGN: 'CampaignActions/DELETE_CAMPAIGN',
});
export const LoadingStatusActions = Object.freeze({
  SET_PACKAGES_LOADING_STATUS: 'SET_PACKAGES_LOADING_STATUS',
  SET_CAMPAIGNS_LOADING_STATUS: 'SET_CAMPAIGNS_LOADING_STATUS',
});
export const Form = Object.freeze({
  NAME: 'PackageForm',
  BULLETIN_BOARD_NAME: 'BulletinBoardForm',
  RATE_CARD_NAME: 'RateCardForm',
});
export const nonOmnichannelPackageTabList = Object.freeze({
  FORM: { name: 'form', display: 'Form' },
  COMMENTS: { name: 'comments', display: 'Comments' },
});
export const GridViewDisplayModes = Object.freeze({
  EDIT_MODE: 'EDIT_MODE',
  PREVIEW_MODE: 'PREVIEW_MODE',
});
export const GridViewActions = Object.freeze({
  ACTIVE_CELL_INPUT_SUBMIT: 'ACTIVE_CELL_INPUT_SUBMIT',
  ACTIVE_CELL_INPUT_UPDATE: 'ACTIVE_CELL_INPUT_UPDATE',
  RESET_ACTIVE_CELL: 'RESET_ACTIVE_CELL',
  SELECT_CELL: 'SELECT_CELL',
  UPDATE_ACTIVE_CELL: 'UPDATE_ACTIVE_CELL',
  MOVE_ACTIVE_CELL: 'MOVE_ACTIVE_CELL',
  SET_FILTERS: 'SET_FILTERS',
  ADD_FILTER: 'ADD_FILTER',
  REMOVE_FILTER: 'REMOVE_FILTER',
  SET_SORTS: 'SET_SORTS',
  ADD_SORT: 'ADD_SORT',
  REMOVE_SORT: 'REMOVE_SORT',
  SET_FILTER_VIEWS: 'SET_FILTER_VIEWS',
  SET_ACTIVE_FILTER_VIEW: 'SET_ACTIVE_FILTER_VIEW',
  OPEN_FILTER_VIEW_MODAL: 'OPEN_FILTER_VIEW_MODAL',
  CLOSE_FILTER_VIEW_MODAL: 'CLOSE_FILTER_VIEW_MODAL',
  SAVE_FILTER_VIEW: 'SAVE_FILTER_VIEW',
  DELETE_FILTER_VIEW: 'DELETE_FILTER_VIEW',
  LOAD_FILTER_VIEWS_FROM_STORAGE: 'LOAD_FILTER_VIEWS_FROM_STORAGE',
  SET_PAGE: 'SET_PAGE',
  TOGGLE_ACTION_BAR: 'TOGGLE_ACTION_BAR',
  CREATED_PACKAGE_RECEIVED: 'CREATED_PACKAGE_RECEIVED',
  UPDATED_PACKAGE_RECEIVED: 'UPDATED_PACKAGE_RECEIVED',
  UPDATED_CAMPAIGN_RECEIVED: 'UPDATED_CAMPAIGN_RECEIVED',
  CREATED_CAMPAIGN_RECEIVED: 'CREATED_CAMPAIGN_RECEIVED',
  SEND_CAMPAIGN_EDIT: 'SEND_CAMPAIGN_EDIT',
  SEND_PACKAGE_EDIT: 'SEND_PACKAGE_EDIT',
  EXPORT_CURRENT_VIEW: 'EXPORT_CURRENT_VIEW',
  OPEN_EXPORT_MODAL: 'OPEN_EXPORT_MODAL',
  CLOSE_EXPORT_MODAL: 'CLOSE_EXPORT_MODAL',
  GET_EXPORT_SETTINGS: 'GET_EXPORT_SETTINGS',
  SET_EXPORT_SETTINGS: 'SET_EXPORT_SETTINGS',
  UPLOAD_EXPORT_SETTINGS: 'UPLOAD_EXPORT_SETTINGS',
  OPEN_REF_MARKER_MODAL: 'OPEN_REF_MARKER_MODAL',
  CLOSE_REF_MARKER_MODAL: 'CLOSE_REF_MARKER_MODAL',
  UDPATE_REF_MARKER: 'UDPATE_REF_MARKER',
  SET_REF_MARKER_PROCESSING: 'SET_REF_MARKER_PROCESSING',
  UPDATE_REF_MARKER: 'UPDATE_REF_MARKER',
  SET_REF_MARKER_ERROR: 'SET_REF_MARKER_ERROR',
  UPDATE_CAMPAIGN_COLUMN: 'UPDATE_CAMPAIGN_COLUMN',
  PARSE_CAMPAIGN_ASINS: 'PARSE_CAMPAIGN_ASINS',
  SET_FORCE_REF_MARKER: 'SET_FORCE_REF_MARKER',
  UPDATE_CAMPAIGNS_SOV: 'UPDATE_CAMPAIGNS_SOV',
  TOGGLE_ROWS_EXPANDED: 'TOGGLE_ROWS_EXPANDED',
  SET_EXPANDED_ROWS_OVERRIDE: 'SET_EXPANDED_ROWS_OVERRIDE',
});

export const SubscriptionActions = Object.freeze({
  UPDATED_CAMPAIGN_RECEIVED: 'Subscription/UPDATED_CAMPAIGN_RECEIVED',
  UPDATED_COMMENTS_RECIEVED: 'Subscription/UPDATED_COMMENTS_RECIEVED',
})
export const ThirdPartyIFrameActions = Object.freeze({
  SET_DESTINATION: 'ThirdPartyIFrame/SET_DESTINATION',
  ENABLE_EXPERIENCE: 'ThirdPartyIFrame/ENABLE_EXPERIENCE',
  CLOSE: 'ThirdPartyIFrame/CLOSE',
  OPEN_COMPOSER: 'ThirdPartyIFrame/OPEN_COMPOSER',
  OPEN_MELODY: 'ThirdPartyIFrame/OPEN_MELODY',
  SET_ID: 'ThirdPartyIFrame/SET_CAMPAIGN_ID',
  COMPOSER_EVENT: 'ThirdPartyIFrameEvent',
  OPEN_JSON_BOURNE: 'ThirdPartyIFrame/OPEN_JSON_BOURNE',
  JSON_BOURNE_EVENT: 'ThirdPartyIFrame/JSON_BOURNE_EVENT',
});
export const RateCardActions = Object.freeze({
  LOAD_LIST: 'RateCardActions/LOAD_LIST',
  SET_LIST: 'RateCardActions/SET_LIST',
  SET_LIST_LOADED: 'RateCardActions/SET_LIST_LOADED',
  SET_PAGE_NUMBER: 'RateCardActions/SET_PAGE_NUMBER',
  SET_PAGE_NUMBER_ASSETS: 'RateCardActions/SET_PAGE_NUMBER_ASSETS',
  RATE_CARD_CREATE: 'RateCardActions/RATE_CARD_CREATE',
  RATE_CARD_ADD: 'RateCardActions/RATE_CARD_ADD',
  RATE_CARD_ADD_RECEIVED: 'RateCardActions/RATE_CARD_ADD_RECEIVED',
  RATE_CARD_DELETE: 'RateCardActions/RATE_CARD_DELETE',
  RATE_CARD_CLONE: 'RateCardActions/RATE_CARD_CLONE',
  RATE_CARD_UPDATE: 'RateCardActions/RATE_CARD_UPDATE',
  RATE_CARD_UPDATED_RECEIVED: 'RateCardActions/RATE_CARD_UPDATED_RECEIVED',
  RATE_CARD_CREATE_ASSETS: 'RateCardActions/RATE_CARD_CREATE_ASSETS',
  RATE_CARD_RESET_ASSETS: 'RateCardActions/RATE_CARD_RESET_ASSETS',
  RATE_CARD_ASSET_LOAD_LIST: 'RateCardActions/RATE_CARD_ASSET_LOAD_LIST',
  RATE_CARD_ASSET_SET_LIST: 'RateCardActions/RATE_CARD_ASSET_SET_LIST',
  RATE_CARD_ASSET_SET_LIST_LOADED: 'RateCardActions/RATE_CARD_ASSET_SET_LIST_LOADED',
  RATE_CARD_ASSET_ADD: 'RateCardActions/RATE_CARD_ASSET_ADD',
  RATE_CARD_ASSET_ADD_RECEIVED: 'RateCardActions/RATE_CARD_ASSET_ADD_RECEIVED',
  RATE_CARD_ASSET_UPDATE: 'RateCardActions/RATE_CARD_ASSET_UPDATE',
  RATE_CARD_ASSET_UPDATE_RECEIVED: 'RateCardActions/RATE_CARD_ASSET_UPDATE_RECEIVED',
  RATE_CARD_ASSET_DELETE: 'RateCardActions/RATE_CARD_ASSET_DELETE',
  RATE_CARD_ASSET_EXPORT: 'RateCardActions/RATE_CARD_ASSET_EXPORT',
  RATE_CARD_ASSETS_DELETE_BATCH: 'RateCardActions/RATE_CARD_ASSETS_DELETE_BATCH',
  RATE_CARD_ASSETS_PUBLISH: 'RateCardActions/RATE_CARD_ASSETS_PUBLISH',
  RATE_CARD_MARK_AS_PUBLISHED: 'RateCardActions/RATE_CARD_MARK_AS_PUBLISHED',
  RATE_CARD_REFRESH_PLACEMENTS: 'RateCardActions/RATE_CARD_REFRESH_PLACEMENTS',
  SET_TAB: 'RateCardActions/SET_TAB',
  RESET_TAB: 'RateCardActions/RESET_TAB',
  OPEN_REPORT_MODAL: 'RateCardActions/OPEN_REPORT_MODAL',
  CLOSE_REPORT_MODAL: 'RateCardActions/CLOSE_REPORT_MODAL',
  SET_SEARCH_QUERY: 'RateCardActions/SET_SEARCH_QUERY',
  SET_CONFIG_NAME: 'RateCardActions/SET_CONFIG_NAME',
});
export const MessageEventTypes = Object.freeze({
  COMPOSER: 'Composer',
  JSON_BOURNE: 'JSON_BOURNE',
});
export const ComposerUrls = Object.freeze({
  PROD: 'https://composer.corp.amazon.com/',
  DEV: 'https://composer-gamma.corp.amazon.com/',
});
export const MelodyUrls = Object.freeze({
  BETA: 'https://beta.console.harmony.a2z.com/melody/marketingCalendarRedirect',
  PROD: 'https://console.harmony.a2z.com/melody/marketingCalendarRedirect',
});
export const JSONBourneUrls = Object.freeze({
  PROD: 'https://console.harmony.a2z.com/izar/',
  DEV: 'https://gamma.console.harmony.a2z.com/izar/',
});
export const LandingTypes = Object.freeze({
  COMPOSER: 'composer',
  JSON_BOURNE: 'jsonbourne',
});

export const CloudSettingsDataType = Object.freeze({
  FILTERS_AND_SORTS: 'filtersAndSorts',
  CUSTOM_VIEW_COLUMNS: 'customViewColumns',
  CUSTOM_VIEW_ENABLED: 'customViewUse',
  UTILIZATION_PAGE_CFG: 'utilizationPageCfg',
  EXPORT_VIEW_CFG: 'exportViewCfg',
  LAST_USED_FILTER_SORT: 'lastUsedFilterSort',
  LAST_USED_CAMPAIGN_IDS: 'lastUsedCampaignIds',
  BUSINESS_SELECTOR_CFG: 'businessSelectorCfg',
  YEAR_SELECTOR_CFG: 'yearSelectorCfg',
});
export const SET_USER = 'SET_USER';
export const SET_USER_UNABLE_TO_SIGN_IN = 'SET_USER_UNABLE_TO_SIGN_IN';
export const SET_USER_TIMEZONE = 'SET_USER_TIMEZONE';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const MovementDirections = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
});
export const InputTypes = Object.freeze({
  UNEDITABLE: 'UNEDITABLE',
  INPUT: 'INPUT',
  TEXT_AREA_INPUT: 'TEXT_AREA_INPUT',
  SINGLE_SELECT: 'SINGLE_SELECT',
  SINGLE_CHECKBOX: 'SINGLE_CHECKBOX',
  MULTI_SELECT: 'MULTI_SELECT',
  JSON_INPUT: 'JSON_INPUT',
  DATE_TIME: 'DATE_TIME',
  TIME_INPUT: 'TIME_INPUT',
  NUMBER_INPUT: 'NUMBER_INPUT',
});
export const defaultSortColumns = Object.freeze({
  CREATED_AT: 'createdAt',
});

const TrafficFieldsColumns = Object.freeze({
  ALT_TEXT: createColumn('altText', 'Alt Text', InputTypes.INPUT),
  BODY_COPY: createColumn('bodyCopy', 'Body Copy', InputTypes.INPUT),
  TARGET_BUSINESS: createColumn('targetBusiness', 'Target Business', InputTypes.SINGLE_SELECT),
  INITIATIVE: createColumn('initiative', 'Initiative', InputTypes.SINGLE_SELECT),
  SEASONAL_EVENT: createColumn('seasonalEvent', 'Seasonal Event', InputTypes.SINGLE_SELECT),
  TARGETING_TYPE: createColumn('targetingType', 'Targeting Type', InputTypes.SINGLE_SELECT),
  HVA: createColumn('hva', 'HVA', InputTypes.SINGLE_SELECT),
  HEADLINE: createColumn('headline', 'Headline', InputTypes.INPUT),
  DEVICE_TYPE: createColumn('device', 'Device Type', InputTypes.SINGLE_SELECT),
  FREQUENCY: createColumn('frequency', 'Frequency', InputTypes.SINGLE_SELECT),
  CREATIVE_STATUS: createColumn('creativeStatus', 'Creative Status', InputTypes.SINGLE_SELECT),
  CBR_SIM: createColumn('cbrSim', 'CBR SIM', InputTypes.SINGLE_SELECT),
  COUNTRY: createColumn('country', 'Country', InputTypes.SINGLE_SELECT),
  CTA: createColumn('cta', 'CTA', InputTypes.INPUT),
  CUSTOMER_CTA_URL: createColumn('customerCtaUrl', 'Customer CTA URL', InputTypes.INPUT),
  DESKTOP_IMAGE_1x: createColumn('desktopImage1x', 'Desktop Image 1x', InputTypes.INPUT),
  DESKTOP_IMAGE_2x: createColumn('desktopImage2x', 'Desktop Image 2x', InputTypes.INPUT),
  MOBILE_IMAGE_1x: createColumn('mobileImage1x', 'Mobile Image 1x', InputTypes.INPUT),
  MOBILE_IMAGE_2x: createColumn('mobileImage2x', 'Mobile Image 2x', InputTypes.INPUT),
  TABLET_IMAGE_1x: createColumn('tabletImage1x', 'Tablet Image 1x', InputTypes.INPUT),
  TABLET_IMAGE_2x: createColumn('tabletImage2x', 'Tablet Image 2x', InputTypes.INPUT),
});

export const CampaignColumns = Object.freeze({
  ALM_STORES: createColumn('almStores', 'ALM Stores', InputTypes.MULTI_SELECT),
  BUSINESS_START_YEAR: createColumn('business_startYear', 'Business Start Year', InputTypes.UNEDITABLE),
  BRAND: createColumn('brand', 'Brand', InputTypes.SINGLE_SELECT),
  CATEGORY: createColumn('category', 'Category', InputTypes.MULTI_SELECT),
  STATUS: createColumn('status', 'Status', InputTypes.SINGLE_SELECT),
  MARKETING_MANAGER: createColumn('marketingManager', 'Marketing Manager', InputTypes.INPUT),
  MARKETING_MANAGER_NOTES: createColumn('marketingManagerNotes', 'Marketing Manager Notes', InputTypes.TEXT_AREA_INPUT),
  PACKAGE_NAME: createColumn('packageName', 'Package Name', InputTypes.SINGLE_SELECT),
  PROGRAM: createColumn('program', 'Program', InputTypes.SINGLE_SELECT),
  PLACEMENT_SOURCE: createColumn('placementSource', 'Placement Source', InputTypes.SINGLE_SELECT),
  RATE_CARD_ID: createColumn('rateCardId', 'Rate Card ID', InputTypes.UNEDITABLE),
  REF_MARKER: createColumn('refMarker', 'Ref Marker', InputTypes.INPUT),
  VENDOR: createColumn('vendor', 'Vendor', InputTypes.SINGLE_SELECT),
  VERTICAL: createColumn('vertical', 'Vertical', InputTypes.SINGLE_SELECT),
  ASINS: createColumn('asins', 'Asins', InputTypes.INPUT),
  CAMPAIGN_NAME: createColumn('campaignName', 'Campaign Name', InputTypes.INPUT),
  CAMPAIGN_TYPE: createColumn('campaignType', 'Campaign Type', InputTypes.UNEDITABLE),
  COPY: createColumn('copy', 'Copy', InputTypes.INPUT),
  CHANNEL: createColumn('channel', 'Channel', InputTypes.SINGLE_SELECT),
  IS_LOCKED: createColumn('isLocked', 'Is Locked', InputTypes.SINGLE_CHECKBOX),
  LANGUAGE: createColumn('language', 'Language', InputTypes.INPUT),
  LOCK_MESSAGE: createColumn('lockMessage', 'Lock Message', InputTypes.INPUT),
  LOCK_USER: createColumn('lockUser', 'Lock User', InputTypes.INPUT),
  OTHER_THEME: createColumn('otherTheme', 'Other Theme', InputTypes.INPUT),
  PACKAGE_ID: createColumn('packageId', 'Package Id', InputTypes.INPUT),
  PROMO_ID: createColumn('promoId', 'Promo Id', InputTypes.INPUT),
  PROMO_TYPE: createColumn('promoType', 'Promo Type', InputTypes.SINGLE_SELECT),
  SCHEDULER: createColumn('scheduler', 'Scheduler', InputTypes.INPUT),
  BROWSE_NODE_IDS: createColumn('browseNodeIds', 'Browse Node ID', InputTypes.INPUT),
  COMPOSER_COMPONENT_JSON: createColumn('composerComponentJson', 'Component JSON', InputTypes.TEXT_AREA_INPUT),
  CS_CAMPAIGN_IDS: createColumn('csCampaignIds', 'CS Campaign Ids', InputTypes.INPUT),
  CUSTOM_SEE_MORE_URL: createColumn('customSeeMoreUrl', 'Custom See More URL', InputTypes.INPUT),
  DEDUPE_RULES: createColumn('dedupeRules', 'Dedupe rules', InputTypes.SINGLE_SELECT),
  DESKTOP_SLOT: createColumn('desktopSlot', 'Desktop Slot', InputTypes.SINGLE_SELECT),
  ENCODED_KEPLER_WIDGET_PARAM: createColumn('encodedKeplerWidgetParam', 'Encoded Kepler Params', InputTypes.INPUT),
  HERO_ASINS: createColumn('heroAsins', 'Hero ASINs', InputTypes.INPUT),
  IMAGE_PATHS: createColumn('imagePaths', 'Image Path', InputTypes.JSON_INPUT),
  IMAGE_TYPE: createColumn('imageType', 'Image Type', InputTypes.INPUT),
  INVENTORY: createColumn('inventory', 'Inventory', InputTypes.INPUT),
  INVENTORY_AWARENESS: createColumn('inventoryAwareness', 'Inventory Awareness', InputTypes.INPUT),
  IS_ANIMATED_CONTENT: createColumn('isAnimatedContent', 'Is Animated Content?', InputTypes.SINGLE_CHECKBOX),
  IS_DEAL: createColumn('isDeal', 'Is Deal?', InputTypes.SINGLE_CHECKBOX),
  LABEL: createColumn('label', 'Label', InputTypes.INPUT),
  MOBILE_SLOT: createColumn('mobileSlot', 'Mobile Slot', InputTypes.SINGLE_SELECT),
  NEED_INVENTORY_AWARENESS: createColumn('needsInventoryAwareness', 'Needs Inventory Awareness', InputTypes.SINGLE_CHECKBOX),
  PAGE_ID: createColumn('pageId', 'Page ID', InputTypes.SINGLE_SELECT),
  PAGE_NAME: createColumn('pageName', 'Page Name', InputTypes.SINGLE_SELECT),
  PAGE_THEME: createColumn('pageTheme', 'Page Theme', InputTypes.SINGLE_SELECT),
  PAGETYPE: createColumn('pageType', 'Page Type', InputTypes.SINGLE_SELECT),
  PERCOLATE_PARAMETERS: createColumn('percolateParameters', 'Percolate SP', InputTypes.SINGLE_SELECT),
  PLACEMENT: createColumn('placement', 'Placement', InputTypes.SINGLE_SELECT),
  PLACEMENT_IN_BUNDLE: createColumn('placementInBundle', 'Placement in Bundle', InputTypes.SINGLE_SELECT),
  SCHEDULING_INSTRUCTIONS: createColumn('schedulingInstructions', 'Scheduling Instructions', InputTypes.INPUT),
  SHOVELER_CONTENT: createColumn('shovelerContent', 'Shoveler Content', InputTypes.SINGLE_SELECT),
  SLOTS_OVERBOOKED_VALIDATION_BYPASSED: createColumn('slotsOverbookedValidationBypassed', 'Slots Overbooked Validation Bypassed', InputTypes.UNEDITABLE),
  SOV: createColumn('sov', 'SOV', InputTypes.NUMBER_INPUT),
  SOV_TYPE: createColumn('sovType', 'SOV Type', InputTypes.SINGLE_SELECT),
  SUB_HEADLINE: createColumn('subHeadline', 'Sub-headline', InputTypes.INPUT),
  SYMPHONY_ID: createColumn('symphonyId', 'Symphony ID', InputTypes.INPUT),
  SYMPHONY_URL: createColumn('symphonyUrl', 'Symphony URL', InputTypes.INPUT),
  SPANISH_COPY: createColumn('spanishCopy', 'Spanish Copy', InputTypes.INPUT),
  TARGETING: createColumn('targeting', 'Targeting (Bullseye ID)', InputTypes.INPUT),
  TARGETING_EXCLUDE: createColumn('targetingExclude', 'Targeting to exclude (Bullseye ID)', InputTypes.INPUT),
  TERMS_AND_CONDITIONS: createColumn('termsAndConditions', 'T&C', InputTypes.TEXT_AREA_INPUT),
  TRANSLATION_STATUS: createColumn('translationStatus', 'Translation Status', InputTypes.SINGLE_SELECT),
  TIMEZONE_ID: createColumn('timezoneId', 'Timezone ID', InputTypes.SINGLE_SELECT),
  USE_NEW_TEMPLATE: createColumn('useNewTemplateDesign', 'Use "New" template', InputTypes.SINGLE_CHECKBOX),
  VSSM_ID: createColumn('vssmId', 'VSSM ID', InputTypes.INPUT),
  WEBLAB: createColumn('weblab', 'Weblab', InputTypes.INPUT),
  WEBLAB_TREATMENT: createColumn('weblabTreatment', 'Weblab Treatment', InputTypes.SINGLE_SELECT),
  WIDGET: createColumn('widget', 'Widget', InputTypes.SINGLE_SELECT),

  START_DATE: createColumn('startDate', 'Start Date', InputTypes.DATE_TIME),
  START_TIME: createColumn('startTime', 'Start Time', InputTypes.TIME_INPUT),
  END_DATE: createColumn('endDate', 'End Date', InputTypes.DATE_TIME),
  END_TIME: createColumn('endTime', 'End Time', InputTypes.TIME_INPUT),
  BUSINESS: createColumn('business', 'Business', InputTypes.SINGLE_SELECT),

  TRAFFIC_FIELDS: createColumn('trafficFields', 'Traffic fields', InputTypes.UNEDITABLE),
  ...TrafficFieldsColumns,

  // only used for bulk import processing
  IS_PACKAGE: createColumn('isPackage', 'isPackage', InputTypes.INPUT),
  ID: createColumn('id', 'Id', InputTypes.INPUT),


  // TODO: Confirm that we don't need the columns below and we can get rid of them.
  // Will also need to refactor/remove dependent code before we can remove these columns.

  // TODO: @deprecated
  MERCHANT: createColumn('merchant', 'Merchant', InputTypes.SINGLE_SELECT),
  PAGE_GROUP: createColumn('pageGroup', 'Page Group', InputTypes.SINGLE_SELECT),
  RSP: createColumn('rsp', 'RSP', InputTypes.INPUT),
  COMPOSER_COMPONENT_NAME: createColumn('composerComponentName', 'Composer Component Name', InputTypes.INPUT),
  COMPOSER_COMPONENT_CONTENT: createColumn('composerComponentContent', 'Composer Component Content', InputTypes.INPUT),
  DESIGN_SIM: createColumn('designSim', 'Design SIM', InputTypes.INPUT),
  CBR_COMMENTS: createColumn('cbrNotes', 'CBR Comments', InputTypes.TEXT_AREA_INPUT),
  STOREFRONT_REVIEW_NOTES: createColumn('storefrontReviewNotes', 'Storefront Review Notes', InputTypes.TEXT_AREA_INPUT),
  FUNCTIONAL_QA_NOTES: createColumn('functionalQANotes', 'Functional QA Notes', InputTypes.TEXT_AREA_INPUT),
  CONTRA_COGS: createColumn('contraCogs', 'Contra COGS ID', InputTypes.SINGLE_SELECT),
  IS_THEME_PART: createColumn('isPartOfTheme', 'Is part of theme? ', InputTypes.SINGLE_CHECKBOX),
  HAS_TRAFFIC_DRIVERS: createColumn('hasTrafficDrivers', 'Has Traffic Drivers?', InputTypes.SINGLE_CHECKBOX),
});

export const WFMColumns = Object.freeze({
  ...CampaignColumns,
  PAGE_ID: createColumn(
    CampaignColumns.PAGE_ID.name,
    CampaignColumns.PAGE_ID.display,
    InputTypes.INPUT,
  ),
});
export const FreshDEColumns = Object.freeze({
  ...CampaignColumns,
  SCHEDULER: createColumn(
    CampaignColumns.SCHEDULER.name,
    CampaignColumns.SCHEDULER.display,
    InputTypes.SINGLE_SELECT,
  ),
});
export const BulletinBoardColumns = Object.freeze({
  ID: createBBColumn('id', 'ID', InputTypes.INPUT),
  BUSINESS: createBBColumn('business', 'Business', InputTypes.SINGLE_SELECT),
  IS_STICKY: createBBColumn('isSticky', 'Is Sticky?', InputTypes.SINGLE_CHECKBOX),
  IS_GLOBAL: createBBColumn('isGlobalAnnouncement', 'Is Global?', InputTypes.SINGLE_CHECKBOX),
  MESSAGE: createBBColumn('message', 'Message', InputTypes.TEXT_AREA_INPUT),
  START_DATE_TIME: createBBColumn('startDateTime', 'Start Date', InputTypes.DATE_TIME),
  END_DATE_TIME: createBBColumn('endDateTime', 'End Date', InputTypes.DATE_TIME),
  CREATED_AT: createBBColumn('createdAt', 'Created At', InputTypes.DATE_TIME),
});
export const KeyboardKeys = Object.freeze({
  ESCAPE: 'Escape',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  TAB: 'Tab',
});
export const Colors = Object.freeze({
  ALTERNATE_SECONDARY: 'alternateSecondary',
  LIGHT_GREY: 'lightgrey',
});
export const Urls = Object.freeze({
  ERROR: '/error',
  EDIT_PACKAGE: '/package/edit',
  CLONE_PACKAGE: '/package/clone',
  NEW_PACKAGE: '/package/new',
  SHOW_ALL_CAMPAIGNS: '/campaigns',
  UTILIZATION: '/utilization',
  IN_STORE_UTILIZATION: '/utilization/in-store',
  DASHBOARD: '/dashboard',
  METADATA_EDITOR: '/metadata',
  IMPORT: '/import',
  BULLETIN_BOARD: '/board',
  EDIT_BOARD_ANNOUNCEMENT: '/board/edit',
  ADD_BOARD_ANNOUNCEMENT: '/board/add',
  BULK_IMPORT: '/import',
  CAMPAIGN_AUDIT_LOG: '/campaign/audit',
  PACKAGE_AUDIT_LOG: '/package/audit',
  REDIRECT_LANDING: '/redirectLanding',
  NOTIFICATIONS: '/notifications',
  METADATA_AUDIT_LOG: '/metadata/audit',
  RATE_CARD_LIST: '/rate-card-list',
  RATE_CARD_NEW: '/rate-card/new',
  RATE_CARD_EDIT: '/rate-card/edit',
  RATE_CARD_CLONE: '/rate-card/clone',
  RATE_CARD_ASSETS: '/rate-card/assets',
});
export const UrlSearchQuery = Object.freeze({
  CAMPAIGN_ID: '?campaignId'
})
export const ExternalUrls = Object.freeze({
  ASIN_PARSER: 'https://beta.console.harmony.a2z.com/f3-marketing-tools-grease-monkey/asinParser/index.html',
  BROWSER_EXTENSIONS: 'https://w.amazon.com/bin/view/F3_Marketing_Guide/Greasemonkey',
  JSON_TOOL: 'https://console.harmony.a2z.com/izar/',
  COMPOSER: 'https://composer.corp.amazon.com/f3?t=freshMultiTile',
  COMPOSER_EU: 'https://composer.corp.amazon.com/f3?t=FreshEURoto',
  COMPOSER_EU_PRIME_NOW: 'https://composer.corp.amazon.com/f3?t=Tala',
  SYMPHONY: 'https://console.harmony.a2z.com/content-symphony/NA/',
  SYMPHONY_EU: 'https://console.harmony.a2z.com/content-symphony/EU/',
  SYMPHONY_FE: 'https://console.harmony.a2z.com/content-symphony/FE/',
  MARKETING_WIKI: 'https://w.amazon.com/bin/view/F3_Marketing_Guide/',
  MARKETING_WIKI_WFM: 'https://w.amazon.com/bin/view/F3/F3_Retail_Support_SJO/WFM-Team/',
  MARKETING_WIKI_DE: 'https://w.amazon.com/bin/view/F3/Marketing/DE',
  MARKETING_WIKI_JP: 'https://w.amazon.com/bin/view/JP_F3/Marketing_and_Product/Merchandising/MarketingCalendar/',
  REPORT_AN_ISSUE_FRESH: 'https://sim.amazon.com/issues/create?template=992c6021-a5f5-44a0-816e-7a96323629c6',
  REPORT_AN_ISSUE: 'https://t.corp.amazon.com/create/templates/5a86fe2a-d0f4-4c55-a9da-0523cdb9d863',
  REQUEST_A_FEATURE_FRESH: 'https://sim.amazon.com/issues/create?template=a47149f8-0b23-4667-afbc-0657cb79490b',
  REQUEST_A_FEATURE: 'https://sim.amazon.com/issues/create?template=137c1e64-80f0-4f2c-95e1-fbe80e53e3ba',
  BADGE_PHOTO: 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=',
  GET_ACCESS_LINK: 'https://w.amazon.com/bin/view/WWGS/Marketing/Marketing_Tools/Projects/Marketing_Calendar#HAccesstotheMarketingCalendar',
});
export const Validation = Object.freeze({
  MIN_LENGTH: 3,
  MAX_LENGTH: 15,
  MAX_SOV_PERCENT: 100,
  COPY_MAX_LENGTH: 40,
  SUB_HEADLINE_MAX_LENGTH: 35,
});
// TODO: rename to Messages
export const Language = Object.freeze({
  APP_SYNC_CONNECTION_TIMEOUT_ERROR_TITLE: 'Warning! Connection timed out!',
  APP_SYNC_CONNECTION_TIMEOUT_ERROR_BODY: `The connection to the server has been timed out. All changes made after this point will not be saved. If you want to reconnect to the server, you have to refresh the page.`,
  APP_SYNC_CONNECTION_TIMEOUT_ERROR_REFRESH_BUTTON: 'Refresh',
  VALIDATION_REQUIRED: 'Required',
  VALIDATION_MIN_LENGTH: 'The value isn\'t long enough',
  VALIDATION_MAX_LENGTH: 'The value is too long',
  VALIDATION_ASINS_NODE_IDS_BOTH_SET: 'Landing ASINs and Browse Node IDs cannot both be set',
  VALIDATION_THEME_BOTH_SET: `'${CampaignColumns.PAGE_THEME.display}' and '${CampaignColumns.OTHER_THEME.display}' cannot be selected at the same time!`,
  VALIDATION_MAX_SOV_PERCENT: `Max % cannot exceed ${Validation.MAX_SOV_PERCENT}%`,
  VALIDATION_START_DATE_ERROR: 'The start date must be before the end date!',
  VALIDATION_ASIN_COUNT: `A minimum of ${Validation.MIN_LENGTH} ASINs are required.`,
  REF_MARKER_UPDATE_SUCCESS: 'RefMarker has been successfully updated!',
  REF_MARKER_UPDATE_FAIL_USED_MARKER: 'RefID already in use, please select a new refID, or press update again to override',
  REF_MARKER_UPDATE_FAIL_VERSION_MISMATCH: 'Campaign is out of sync, please refresh page',
  PACKAGE_CREATE_SUCCESS: 'New package has been successfully created!',
  PACKAGE_ADD_ERROR: 'Something went wrong.',
  CAMPAIGN_ADD_SUCCESS: 'New campaign has been successfully created!',
  CAMPAIGN_ADD_ERROR: 'Something went wrong.',
  CLICK_HERE_TO_ACCESS_LINK: 'Open link in a new tab',
  CREATIVE_CREATION_SUCCESS: 'Successfully created creative in Symphony. See updated Symphony link below.',
  CREATIVE_UPDATE_SUCCESS: 'Successfully updated creative in Symphony. See updated Symphony link below.',
  CREATIVE_CREATION_ERROR: 'Error creating new creative in Symphony',
  BULLETIN_BOARD_ADD_NEW_ANNOUNCEMENT_BUTTON: 'Add new announcement',
  BULLETIN_BOARD_DELETE_CONFIRM: 'Are you sure you want to delete this announcement? This action cannot be undone.',
  BULLETIN_BOARD_ANNOUNCEMENT_DELETED: 'Announcement has been deleted!',
  BULLETIN_BOARD_ERROR_DELETE_ANNOUNCEMENT: 'Error while deleting announcement!',
  BULLETIN_BOARD_ERROR_LOAD_ANNOUNCEMENT: 'Requested announcement is not found!',
  BULLETIN_BOARD_ERROR_ADD_ANNOUNCEMENT: 'Error while adding new announcement!',
  BULLETIN_BOARD_SUCCESS_ADD_ANNOUNCEMENT: 'New announcement has been successfully created!',
  BULLETIN_BOARD_ERROR_EDIT_ANNOUNCEMENT: 'Error while editing the announcement!',
  BULLETIN_BOARD_SUCCESS_EDIT_ANNOUNCEMENT: 'The announcement has been successfully edited!',
  BULLETIN_BOARD_STICKY_BADGE: 'Sticky',
  CONNECTION_CLOSED: 'Connection to database has been closed, possibly due to inactivity. Your app will not update when other users make changes. Please refresh the page at your earliest convenience.',
  DASHBOARD_STATS_MODE_CONTAIN_DESCRIPTION: 'Show only the campaigns with start and end dates in the selected date range',
  DASHBOARD_STATS_MODE_OVERLAP_DESCRIPTION: 'Show ALL of the campaigns that fall in the selected date range',
  CANNOT_AUTHENTICATE: 'Cannot authenticate user. This may be because the server is not responding or you do not have permissions.',
  AUTHENTICATE_ACCESS_REQUIRED: 'For access to the Marketing Calendar, please follow the instructions ',
  AUTHENTICATE_TRY_AGAIN: 'Try logging in again',
  INVALID_DATE: 'Invalid date',
  INVALID_DATE_SUPPLIED: 'Invalid date or time supplied',
  START_DATE_INCONSISTENT: 'Start date is not within selected years',
  END_DATE_INCONSISTENT: 'End date is not within selected years',
  INVALID_TIME_FORMAT: 'Start or End Time is invalid, values should be in the format XX:30 or XX:00',
  INVALID_DAY_OF_WEEK: 'Please check your flight dates and times to ensure they match the correct format for #{campaignType}, which is #{startDateTime} start - #{endDateTime} end',
  EDIT_VISIBLE_COLUMNS: 'Edit visible columns',
  CAMPAIGN_FORM_CLICK_TO_AUDIT_LINK: 'Click here to see the latest changes have been done to this campaign',
  CARET_PRESENT: 'Caret symbol present in copy. Please do not add caret symbol in the future',
  ACTION_BUTTON_EDIT: 'Edit',
  ACTION_BUTTON_CLONE: 'Clone',
  ACTION_BUTTON_DELETE: 'Delete',
  ACTION_BUTTON_AUDIT_LOG: 'Audit log',
  ACTION_BUTTON_SHOW_PUBLISHED_ASSETS: 'Show Published Assets (Campaigns)',
  ACTION_BUTTON_COMPOSER: 'Composer',
  ACTION_BUTTON_MELODY: 'Melody',
  ACTION_BUTTON_JSON_BOURNE: 'JSONBourne',
  ACTION_BUTTON_CONTENT_SYMPHONY: 'Content Symphony',
  ACTION_BUTTON_COMMENTS: 'Comments',
  FILTER_CONTROL_BUTTON: 'Filtering and custom views',
  NOTIFICATIONS_BELL_BUTTON: 'Notifications',
  MALFORMED_BULK_IMPORT_ROW: 'This row has malformed data that likely needs correction outside the Marketing Calendar, please open a ticket to address this issue.',
  AUDIT_LOG_ERROR_FETCHING: 'Cannot fetch Audit log history for the given entity',
  METADATA_AUDIT_LOGS_ERROR_PARSING: 'Error fetching or processing MetadataAuditLogs',
  UTILIZATION_SOV_WARNING: 'This slot has campaigns which contain \'Targeted SOV\' (real SOV % might be different from what you see in this stats)!',
  REF_MARKER_UPDATE_CONFIRM: 'It appears this reftag has been assigned before. Using the same reftag on multiple campaigns can lead to reporting issues. Are you sure you want to save this reftag?',
  UTILIZATION_SLOTS_SETTINGS_BUTTON_TOOLTIP: 'You can use this settings tab to change slots\' order, as well as make them visible or disappear',
  UTILIZATION_SLOTS_SETTINGS_BUTTON_LABEL: 'Settings',
  UTILIZATION_SHOW_RESULTS: 'Show results',
  CREATE_SYMPHONY_CAMPAIGN_CANNOT_CREATE_TOOLTIP: 'Content Symphony Campaign cannot be created if there is already a Symphony URL present',
  CREATE_SYMPHONY_CAMPAIGN_MODAL_DIFF_TITLE: 'Version Diff',
  CREATE_UPDATE_SYMPHONY_CAMPAIGN_LOADER_TEXT: 'Processing Campaign in Symphony...',
  CREATE_SYMPHONY_CAMPAIGN_MODAL_CLOSE_BUTTON: 'Close',
  CREATE_SYMPHONY_CAMPAIGN_MODAL_VIEW_MODE_ALL: 'All rows',
  CREATE_SYMPHONY_CAMPAIGN_MODAL_VIEW_MODE_ONLY_CHANGED: 'Only changed rows',
  CREATE_SYMPHONY_CAMPAIGN_MODAL_VIEW_MODE_TOOLTIP: 'You can change the diff view mode here. You can choose to show all rows and highlight all changed lines or to display and highlight only changed rows.',
  SLOT_OVERBOOKED_CHECK_BYPASS_CONFIRMATION_MESSAGE: 'It appears that some of the slots you have selected are overbooked. Do you still want to create the campaign?',
  ACCESS_CONTROL_TITLE: 'Permissions error',
  ACCESS_CONTROL_MESSAGE: 'Access to this page has been limited.',
  FLASH_FILL_CONTEXT_PAGE_MENU_ITEM: 'Flash fill below cells',
  FLASH_FILL_CONTEXT_PACKAGE_MENU_ITEM: 'Flash fill package cells', 
  FLASH_FILL_PAGE_CONFIRMATION_MSG: 'Are you sure you want to flash fill all cells below with the same value?',
  FLASH_FILL_PACKAGE_CONFIRMATION_MSG: 'Are you sure you want to flash fill all cells in this package with the same value?',
  FLASH_FILL_VALIDATION_ERROR: 'Unable to set campaign \'#{refMarker}\' status. The following fields are missing: #{missingFields}',
  ON_MODAL_FORM_CLOSE_CONFIRMATION: 'Are you sure you want to close the form? All of your unsaved changes will be lost.',
  INCORRECT_BUSINESS_NAME: 'Business doesn\'t exist. If you are using the wrong name, please change it.',
  RATE_CARD_DELETE_CONFIRMATION: 'Are you sure you want to delete this Rate Card? This action cannot be undone.',
  RATE_CARD_CARD_UPDATED_MESSAGE: 'The Rate Card has been successfully updated!',
  RATE_CARD_ASSET_UPDATED_MESSAGE: 'The Rate Card\'s asset has been successfully updated!',
  RATE_CARD_ASSET_DELETE_CONFIRMATION: 'Are you sure you want to delete this Asset? This action cannot be undone.',
  RATE_CARD_ASSETS_COLLECTION_PUBLISH: 'Important reminder! After your rate card and corresponding placements are published, the simplified export/import function will no longer be available. For bulk edits after publishing, the standard export/import function will have to be used.',
  RATE_CARD_AVAILABLE_MONEY_LIMIT_EXCEEDED: 'You\'ve exceeded your available budget!',
  RATE_CARD_ASSETS_CHANGES_APPLIED: 'Your changes have been successfully applied!',
  RATE_CARD_IMPORT_WARNING: 'Warning! Unfortunately, none of your assets have passed validation! Your file needs to be re-uploaded after you correct all validation errors. Please click "Cancel" and correct all validation errors.',
  RATE_CARD_CONFIG_SELECTOR_MESSAGE: 'Use the dropdown below to change current Rate Card\'s configuration version',
  RATE_CARD_CONFIG_SELECTOR_NAME: 'Year',
  RATE_CARD_REFRESH_PLACEMENTS_MESSAGE: 'The data in this block will become available once the "General information" is filled in and you click "Refresh placements" button',
  RATE_CARD_FINALIZED_MESSAGE: 'All assets have been published and the Rate Card has been finalized. For a list of published campaigns, please use the "Assets Actions" menu. ',
  RATE_CARD_CONFIRM_DELETE_ALL_ASSETS: 'Are you sure you want to delete ALL assets? This cannot be undone.',
  RATE_CARD_CANNOT_PUBLISH_ASSETS: 'Because these assets were created based on an old configuration file, they cannot be published. Use this year\'s configuration to create a new Rate Card.',
  // Using multi-line string literal for CONTENT_APPROVAL_PLACEHOLDER displays
  // undesired indentation to the customer. There isn't a good way to format the
  // code and get the string displayed as desired, so leaving it like this. (lesser-evil option)
  CONTENT_APPROVAL_PLACEHOLDER: `Copy your list of Content Symphony IDs. Each on a new line. 
  
  Example: 
  aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee
  aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeef 
  
  Click submit to approve contents for given IDs`,
  MEDIA_SERVICE_TITLE: 'Digital Circular Pdf Uploader',
  MEDIA_SERVICE_START_OVER_MESSAGE: 'Start Over',
  MEDIA_SERVICE_UPLOADED_FILES_TITLE: 'Uploaded files:',
  MEDIA_SERVICE_HELP_MESSAGE: 'Refer to the <a target="_blank" href="https://w-main.corp.amazon.com/bin/view/F3/R2T2/Marketing_Tools/Projects/Marketing_Calendar/Features/CircularPdfUploader/">wiki</a> for more details about this tool.',
  MEDIA_SERVICE_FAILED_TO_UPLOAD_FILE: 'Failed to upload \'#{fileName}\'!;',
  MEDIA_SERVICE_UPLOADING_FILES_MESSAGE: 'Uploading files (#{filesCountProcessed} of #{filesCountTotal})...',
  MEDIA_SERVICE_NO_FILES_TO_UPLOAD: 'Error! No files selected!',
  NEEDS_INVENTORY_AWARENESS_WARNING: '[Warning] Campaign can\'t be saved as ASINs are required for inventory awareness',
  VSSM_EXCLUSIVE_SLOTS_WARNING: 'Warning! You are selecting a slot that is reserved for VSSM. Please select a different slot.',
  CHECKOUT_SOV_WARNING: 'Please adjust your campaign SOV to 5%, which is required for Checkout campaigns.',
  IN_STORE_UTILIZATION_LOADING_WARNING: 'In order to obtain the most accurate In-store utilization results, all campaigns must be fully loaded. This warning will disappear once the loading is complete. You may want to click the "Show results" button again to see a more accurate result after that.',
  GENERIC_LOADING: 'Loading...',
  GENERIC_NOT_FOUND: 'Not found',
  GENERIC_ERROR: 'Something went wrong.',
  UTILIZATION_WEEK_TITLE: '#{sov}% #{campaignName} [#{status}]',
  UTILIZATION_WEEK_CONTENT: '#{startDate} - #{endDate} (Vendor: #{vendor} | Vertical: #{vertical} | Placement: #{placement})',
  UTILIZATION_WEEK_TITLE_TOOLTIP: 'Click here to edit campaign',
  UTILIZATION_IN_STORE_HEADER: '<span>#{campaignCount} campaigns</span> are running between <span>#{dateTimeStart} - #{dateTimeEnd}</span>',
  SHOW_FILTERS_BUTTON: 'Show/hide filters',
  UTILIZATION_TOGGLE_FMC_SLOTS: 'Show FMC Slots',
  RATE_CARD_MERCHANDISING_INTENT_DESCRIPTION: 'This section will automatically select suggested Main type placements given your input, and ensure your allocation by class utilizes the full budget available. <br /><br /><strong>Please note:</strong> automated placement suggestions are available for "Main Placements" only (learn more <a target="_blank" href="https://w.amazon.com/bin/view/F3_Marketing_Guide/AVNRateCardsintheMC/#H">here</a>)',
  STATUS_CHANGE_FAILED: 'Unable to set campaign status.',
  STATUS_CHANGE_FAILED_MISSING_FIELDS: 'Unable to set campaign status. The following fields are missing: #{missingFields}',
  CAMPAIGN_SYNC_UP_LABEL: 'Sync this online campaign with the main one. Once you click it, everything from the primary Online campaign will be copied over except for these columns: #{uniqueColumns}',
  LOADING_CAMPAIGNS: 'Loading campaigns...',
});
export const Comparators = Object.freeze({
  EQUALS: 'Equals',
  DOES_NOT_EQUAL: 'Does not equal',
  GREATER_THAN: 'Greater Than',
  LESS_THAN: 'Less Than',
  CONTAINS: 'Contains',
  DOES_NOT_CONTAIN: 'Does not contain',
  EMPTY: 'Is blank',
  NOT_EMPTY: 'Is not blank',
});
export const DeployLocations = Object.freeze({
  DEV: 'Dev',
  BETA: 'Beta',
  PROD: 'Prod',
});
export const Hosts = Object.freeze({
  DEV: 'localhost:3000',
  BETA: 'd1rzg3npoinj8f.cloudfront.net',
  PROD: 'd3ehk8uukjup4u.cloudfront.net',
  PROD_READABLE: 'marketing-calendar.f3.amazon.dev',
});
export const UtilizationActions = Object.freeze({
  BUSINESS_CHANGED: 'BUSINESS_CHANGED',
  FILTER_OPTIONS_CREATED: 'FILTER_OPTIONS_CREATED',
  FILTER_CHANGE: 'FILTER_CHANGE',
  SELECT_DATE_RANGE: 'SELECT_DATE_RANGE',
  SELECT_START_TIME: 'UtilizationActions/SELECT_START_TIME',
  SELECT_END_TIME: 'UtilizationActions/SELECT_END_TIME',
  SELECT_TIMEZONE: 'SELECT_TIMEZONE',
  SHOW_RESULTS: 'SHOW_RESULTS',
  SET_RESULTS: 'SET_RESULTS',
  OPEN_FILTERS_MODAL: 'UtilizationActions/OPEN_FILTERS_MODAL',
  CLOSE_FILTERS_MODAL: 'UtilizationActions/CLOSE_FILTERS_MODAL',
  TOGGLE_STATS_MODE: 'UtilizationActions/TOGGLE_STATS_MODE',
  TOGGLE_ABS_SOV_MODE: 'UtilizationActions/TOGGLE_ABS_SOV_MODE',
  SET_SLOTS_ORDER: 'UtilizationActions/SET_SLOTS_ORDER',
  TOGGLE_SLOTS_VISIBILITY: 'UtilizationActions/TOGGLE_SLOTS_VISIBILITY',
  RESET_SLOTS_ORDER: 'UtilizationActions/RESET_SLOTS_ORDER',
  TOGGLE_SLOTS_ORDER_SETTINGS: 'UtilizationActions/TOGGLE_SLOTS_ORDER_SETTINGS',
  SWITCH_VIEW_MODE: 'UtilizationActions/SWITCH_VIEW_MODE',
  SHOW_FMC_SLOTS: 'UtilizationActions/SHOW_FMC_SLOTS',
  SET_SLOTS_VIEW_MODE: 'UtilizationActions/SET_SLOTS_VIEW_MODE',
});
export const DashboardActions = Object.freeze({
  INIT: 'DASHBOARD/INIT',
  SET_DATE_RANGE: 'DASHBOARD/SET_DATE_RANGE',
  RUN_CALC: 'DASHBOARD/RUN_CALC',
  SET_DATA: 'DASHBOARD/SET_DATA',
  SET_START_TIME: 'DASHBOARD/SET_START_TIME',
  SET_END_TIME: 'DASHBOARD/SET_END_TIME',
  TOGGLE_MODE: 'DASHBOARD/TOGGLE_MODE',
});
export const BusinessTypes = Object.freeze({
  FRESH: {
    name: 'Fresh',
    label: 'Fresh US',
  },
  WFM: {
    name: 'WFM',
    label: 'WFM US',
  },
  WFMC_NA: {
    name: 'WFMC NA',
    label: 'WFMC NA',
  },
  FRESH_SG: {
    name: 'Fresh SG',
    label: 'Fresh SG',
  },
  FRESH_UK: {
    name: 'Fresh UK',
    label: 'Fresh UK',
  },
  FRESH_DE: {
    name: 'Fresh DE',
    label: 'Fresh DE',
  },
  NA_3P: {
    name: '3P NA',
    label: '3P NA',
  },
  FRESH_ES: {
    name: 'Fresh ES',
    label: 'Fresh ES',
  },
  FRESH_IT: {
    name: 'Fresh IT',
    label: 'Fresh IT',
  },
  FR_3P: {
    name: '3P FR',
    label: '3P FR',
  },
  UK_3P: {
    name: '3P UK',
    label: '3P UK',
  },
  IT_3P: {
    name: '3P IT',
    label: '3P IT',
  },
  ES_3P: {
    name: '3P ES',
    label: '3P ES',
  },
  DE_3P: {
    name: '3P DE',
    label: '3P DE',
  },
  FRESH_JP: {
    name: 'Fresh JP',
    label: 'Fresh JP',
  },
  JP_3P: {
    name: '3P JP',
    label: '3P JP',
  },
  FRESH_TRAFFIC: {
    name: 'Fresh US Onsite Traffic',
    label: 'Fresh US Onsite Traffic',
  },
  FRESH_INT_TRAFFIC: {
    name: 'EU5 Onsite Traffic',
    label: 'EU5 Onsite Traffic',
  }
});
export const BusinessYear = Object.freeze({
  YEAR_2020: '2020',
  YEAR_2021: '2021',
  YEAR_2022: '2022',
  YEAR_2023: '2023',
  YEAR_2024: '2024',
  YEAR_2025: '2025',
  YEAR_2026: '2026',
  EVERGREEN: 'EVERGREEN',
})
export const VendorTypes = Object.freeze({
  HOUSE: 'House',
});
export const SeeMoreTexts = Object.freeze({
  [BusinessTypes.FRESH_UK.name]: 'Browse all',
  [BusinessTypes.FRESH_DE.name]: 'Mehr entdecken',
  [BusinessTypes.FRESH_ES.name]: 'Ver más',
  [BusinessTypes.FRESH_IT.name]: 'Scopri di più',
  [BusinessTypes.FRESH_JP.name]: 'もっと見る',
  [BusinessTypes.UK_3P.name]: 'Browse all',
  [BusinessTypes.DE_3P.name]: 'Mehr entdecken',
  [BusinessTypes.ES_3P.name]: 'Ver más',
  [BusinessTypes.IT_3P.name]: 'Scopri di più',
  [BusinessTypes.FR_3P.name]: 'Voir plus',
  [BusinessTypes.JP_3P.name]: 'もっと見る',
});
export const WeblabTreatmentTypes = Object.freeze({
  C: 'C',
  T1: 'T1',
  T2: 'T2',
});
export const RegionTypes = Object.freeze({
  NA: 'Region/NA',
  DE: 'Region/DE',
  EU: 'Region/EU',
  FE: 'Region/FE',
});
export const LinkTypes = Object.freeze({
  BULLSEYE: 'BULLSEYE',
  SYMPHONY: 'SYMPHONY',
  WEBLAB: 'WEBLAB',
  WIKI: 'WIKI',
  REPORT_ISSUE: 'REPORT_ISSUE',
  REQUEST_FEATURE: 'REQUEST_FEATURE',
});
export const Channel = Object.freeze({
  PRIME_NOW: 'Prime Now',
});
export const CampaignNamePrefixes = Object.freeze({
  [BusinessTypes.WFM.name]: 'WFM',
  [BusinessTypes.WFMC_NA.name]: 'WFMC',
  [BusinessTypes.FRESH_UK.name]: 'afuk',
  [BusinessTypes.FRESH_DE.name]: 'FrDE',
  [BusinessTypes.FRESH.name]: 'Fr',
  [BusinessTypes.NA_3P.name]: '3PNA',
  [BusinessTypes.FRESH_ES.name]: 'afes',
  [BusinessTypes.FRESH_IT.name]: 'afit',
  [BusinessTypes.FR_3P.name]: '3pfr',
  [BusinessTypes.UK_3P.name]: '3puk',
  [BusinessTypes.IT_3P.name]: '3pit',
  [BusinessTypes.ES_3P.name]: 'ES_3P_',
  [BusinessTypes.DE_3P.name]: '3pde',
  [BusinessTypes.FRESH_JP.name]: 'JPFr',
  [BusinessTypes.JP_3P.name]: 'JP3P',
  [BusinessTypes.FRESH_TRAFFIC.name]: 'AFO',
  [BusinessTypes.FRESH_INT_TRAFFIC.name]: 'AFO',
  DEFAULT: 'FR',
});
export const BusinessSelector = Object.freeze({
  BUSINESS_TYPE: 'BUSINESS_TYPE',
});
// These keys are used as display names, so do not follow CAPS_CASE
export const SortOrder = Object.freeze({
  ASCENDING: {
    label: 'Ascending',
    value: 'a',
  },
  DESCENDING: {
    label: 'Descending',
    value: 'd',
  },
});
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const ALL_PAGES = 'ALL_PAGES';
export const ExportFilters = Object.freeze({
  ALL_ROWS: 'ALL_ROWS',
  ONLINE_ENGLISH_CAMPAIGN_ROWS: 'ONLINE_ENGLISH_CAMPAIGN_ROWS',
});
export const COPY = 'COPY';
export const CSV = 'CSV';
export const ExportOptionsTypes = Object.freeze({
  PAGE_SELECTION: 'pageSelection',
  EXPORT_TYPE: 'exportType',
  EXPORT_FILTERS: 'exportFilters',
});
export const ExportOptions = Object.freeze({
  [ExportOptionsTypes.PAGE_SELECTION]: Object.freeze([
    {
      display: 'Current page',
      value: CURRENT_PAGE,
    },
    {
      display: 'All pages',
      value: ALL_PAGES,
    },
  ]),
  [ExportOptionsTypes.EXPORT_TYPE]: Object.freeze([
    {
      display: 'Download CSV file',
      value: CSV,
    },
    {
      display: 'Copy to clipboard',
      value: COPY,
    },
  ]),
  [ExportOptionsTypes.EXPORT_FILTERS]: Object.freeze([
    {
      display: 'All packages (all rows)',
      value: ExportFilters.ALL_ROWS,
    },
    {
      display: 'Online campaigns (English only; not bulk importable)',
      value: ExportFilters.ONLINE_ENGLISH_CAMPAIGN_ROWS,
    },
  ]),
});
export const Delimiters = Object.freeze({
  COMMA: ',',
  DASH: '-',
  DOT: '.',
  NEWLINE: '\n',
  TAB: '\t',
  UNDERSCORE: '_',
})
export const NON_BREAKING_SPACE = '\u00a0';
export const CARET = '›';
// TODO: make this a setting parameter
// TODO: retranslate all campaigns when delimter changes
export const ASIN_DELIMETER = '|';
export const UTC_TIMEZONE_ID = 'UTC';
export const DEFAULT_TIMEZONE_ID = 'America/Los_Angeles';
export const ColumnStatus = Object.freeze({
  UPDATED: 'updated',
});
export const CampaignStatuses = Object.freeze({
  APPROVED: 'Approved',
  CANCELED: 'Canceled',
  COPY_REVISION_NEEDED: 'Copy Revision Needed', 
  HOLD: 'Hold',
  ILM_APPROVED: 'ILM Approved',
  ILM_DRAFT: 'ILM Draft',
  INPUTS_COMPLETE: 'Inputs Complete',
  MISSING_INPUTS: 'Missing Inputs',
  STOREFRONT_READY: 'Storefront Ready',
  TRANSFERRED_TO_CPP: 'Transferred to CPP',
  NEW_EDITS: 'New Edits',
});
export const OnlineCampaignStatuses = [
  CampaignStatuses.ILM_APPROVED,
  CampaignStatuses.ILM_DRAFT,
  CampaignStatuses.STOREFRONT_READY,
  CampaignStatuses.COPY_REVISION_NEEDED,
  CampaignStatuses.TRANSFERRED_TO_CPP,
]
export const CampaignEventType = Object.freeze({
  LAUNCH: 'LAUNCH',
  END: 'END',
  CANCELLATION: 'CANCELLATION',
  PENDING: 'PENDING',
});
export const DashboardGroupCriteria = Object.freeze({
  TOTAL_STATS: 'totalStats',
  MY_STATS: 'myStats',
});

export const CAMPAIGN_FORM_DATE_FORMAT = 'YYYY-MM-DD';
export const UTILIZATION_DATE_FORMAT = 'YYYY-MM-DD';
export const UTILIZATION_THRESHOLDS = Object.freeze({
  [BusinessTypes.FRESH.name]: {
    WARNING: 80,
    OVERUTILIZED: 100,
  },
  [BusinessTypes.WFM.name]: {
    WARNING: 80,
    OVERUTILIZED: 100,
  },
});
export const UTILIZATION_THRESHOLDS_ABS = Object.freeze({
  [BusinessTypes.FRESH.name]: {
    WARNING: 19,
    OVERUTILIZED: 20,
  },
  [BusinessTypes.WFM.name]: {
    WARNING: 19,
    OVERUTILIZED: 20,
  },
});
export const ASINColumns = [
  CampaignColumns.HERO_ASINS.name,
  CampaignColumns.ASINS.name,
];
export const DateTimeColumns = [
  CampaignColumns.START_DATE.name,
  CampaignColumns.START_TIME.name,
  CampaignColumns.END_DATE.name,
  CampaignColumns.END_TIME.name,
];
export const FormActions = Object.freeze({
  CHANGE: '@@redux-form/CHANGE',
  BLUR: '@@redux-form/BLUR',
  UPDATE_SYNC_ERRORS: '@@redux-form/UPDATE_SYNC_ERRORS',
  REGISTER_FIELD: '@@redux-form/REGISTER_FIELD',
  UNREGISTER_FIELD: '@@redux-form/UNREGISTER_FIELD',
  ARRAY_PUSH: '@@redux-form/ARRAY_PUSH',
  ARRAY_REMOVE_ALL: '@@redux-form/ARRAY_REMOVE_ALL',
  SET_SUBMIT_SUCCEEDED: '@@redux-form/SET_SUBMIT_SUCCEEDED',
  SET_SUBMIT_FAILED: '@@redux-form/SET_SUBMIT_FAILED',
  SET_UTILIZATION: 'FormActions/SET_UTILIZATION',
  SET_VALIDATION: 'FormActions/SET_VALIDATION',
  ADD_TAB: 'FormActions/ADD_TAB',
  REMOVE_TAB: 'FormActions/REMOVE_TAB',
  SYNC_TABS: 'FormActions/SYNC_TABS',
  SHOW_WARNING: 'FormActions/SHOW_WARNING',
  CLEAR_WARNING: 'FormActions/CLEAR_WARNING',
});
export const FormValidationTypes = Object.freeze({
  UTILIZATION: 'utilization',
});
export const SovTypes = Object.freeze({
  SOV: 'SOV',
  TARGETED_SOV: 'Targeted SOV',
  RANDOM: 'Random',
  DEFAULT: 'Default',
});
export const SovLimits = Object.freeze({
  SOV_FULL: 100,
});
export const LanguageTypes = Object.freeze({
  ENGLISH: 'English',
  SPANISH: 'Spanish'
});
export const MetricTypes = Object.freeze({
  APPSYNC: 'appsync',
  LOCAL_STORAGE: 'localStorage',
  CLICK: 'click',
  VIEW: 'view',
  LOG: 'log',
  USAGE: 'usage',
  THIRD_PARTY: 'third party integration',
});
export const MetricActions = Object.freeze({
  APP_SYNC_CONNECTION_LOST_ERROR_REPORTER: 'appSyncConnectionLostErrorReporter',
  APP_SYNC_CONNECTION_SUBSCRIPTION_LOST_ERROR_REPORTER: 'appSyncConnectionSubscriptionLostErrorReporter',
});
export const MetricErrorMessage = Object.freeze({
  METADATA_PARSING_ERROR: 'Error parsing metadata',
});
export const MetricNames = Object.freeze({
  ADD_CAMPAIGN: 'add campaign',
  APP_SYNC_CONNECTION_LOST: 'appsync connection lost',
  APP_SYNC_SUBSCRIPTION_CONNECTION_LOST: 'appsync subscription connection lost',
  CLONE_CAMPAIGN: 'clone campaign',
  DELETE_CAMPAIGN: 'delete campaign',
  EDIT_CAMPAIGN: 'edit campaign',
  LIST_CAMPAIGN: 'list campaign',
  ADD_PACKAGE: 'add package',
  EDIT_PACKAGE: 'edit package',
  CLONE_PACKAGE: 'clone package',
  DELETE_PACKAGE: 'delete package',
  LOAD_PACKAGES_BY_BUSINESS: 'load packages by business',
  LOAD_CAMPAIGNS_BY_BUSINESS: 'load campaigns by business',
  ASSIGN_CAMPAIGNS_TO_PACKAGES: 'assignCampaignsToPackages',
  MENU_BUTTON: 'menu button',
  OPEN_EXPORT_MODAL: 'open export modal',
  OPEN_JSONBOURNE_IFRAME: 'open jsonBourne iframe',
  TOGGLE_ACTION_BAR: 'toggle action bar',
  UPDATE_SOV_FLAGS: 'update SOV flags',
  CREATE_SYMPHONY_CAMPAIGN_VALIDATE: 'create symphony campaign validation',
  CREATE_SYMPHONY_CAMPAIGN: 'create symphony campaign',
  LOAD_CAMPAIGN: 'load campaign',
  LIST_ANNOUNCEMENTS: 'list announcements',
  DELETE_ANNOUNCEMENT: 'delete announcement',
  ADD_ANNOUNCEMENT: 'add announcement',
  EDIT_ANNOUNCEMENT: 'edit announcement',
  CAMPAIGN_AUDIT_LOG: 'open audit log',
  PAGELOAD: 'pageLoad',
  CLICK_TO_CONTENT_INTERACTIVE: 'clickToContentInteractive',
  INITIAL_METADATA: 'initialMetadataLoaded',
  LAST_PACKAGE_LOADED: 'lastPackageLoaded',
  LAST_CAMPAIGN_LOADED: 'lastCampaignLoaded',
  LAST_USED_CAMPAIGNS_LOADED: 'lastUsedCampaignsLoaded',
  NOTIFICATIONS_STOP_POLLING: 'stop notifications polling',
  CHECK_NOTIFICATIONS_LAST_SEEN_TIME: 'check notifications last seen time',
  CREATE_NOTIFICATIONS_LAST_SEEN_TIME: 'create notifications last seen time',
  UPDATE_NOTIFICATIONS_LAST_SEEN_TIME: 'update notifications last seen time',
  NOTIFICATIONS_MARK_AS_READ_BATCH: 'batch mark notifications as read',
  NOTIFICATIONS_ON_CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED: 'on create notification subscription received',
  NOTIFICATION_OPEN: 'open notification',
  UPDATE_NEW_NOTIFICATIONS_COUNT: 'update new notifications count',
  MARK_AS_READ: 'mark notification as read',
  CREATE_COMMENT: 'create a new comment',
  DELETE_COMMENT: 'delete a comment',
  LOAD_NOTIFICATIONS: 'load notifications',
  LOAD_COMMENTS: 'load comments',
  COMMENTS_STOP_POLLING: 'stop polling comments',
  INTERNAL_PAGE_CHANGE: 'INTERNAL_PAGE_CHANGE',
  NO_PAGE_TRANSITION_RECORDED: 'NO_PAGE_TRANSITION_RECORDED',
  PAGE_TRANSITION_RECORDED_WITHOUT_START: 'PAGE_TRANSITION_RECORDED_WITHOUT_START',
  PERFORMANCE_TIME_RECORDER: 'PERFORMANCE_TIME_RECORDER',
  NO_PERFORMANCE_TIME_RECORDED: 'NO_PERFORMANCE_TIME_RECORDED',
  MULTIPLE_PERFORMANCE_TIMERS_RECORDED: 'MULTIPLE_PERFORMANCE_TIMERS_RECORDED',
  PERFORMANCE_TIME_RECORDED_WITHOUT_START: 'PERFORMANCE_TIME_RECORDED_WITHOUT_START',
  API_GRAPH_QL_MIDDLEWARE: 'GraphQL middleware (requests processor)',
  UTILIZATION_MIDDLEWARE_STORAGE_LOAD: 'Utilization middleware. load from storage.',
  RUN_FLASH_FILL: 'run flash fill',
  LOAD_RATE_CARDS: 'load ratecards',
  ADD_RATE_CARD: 'add rate card',
  DELETE_RATE_CARD: 'delete rate card',
  MARK_AS_PUBLISHED_RATE_CARD: 'mark as published rate card',
  UPDATE_RATE_CARD: 'update rate card',
  LOAD_RATE_CARDS_ASSETS: 'load rate cards assets',
  ADD_RATE_CARD_ASSET: 'add rate card asset',
  DELETE_RATE_CARD_ASSET: 'delete rate card asset',
  DELETE_RATE_CARD_ASSET_BATCH: 'delete rate card asset batch',
  UPDATE_RATE_CARD_ASSET: 'update rate card asset',
  CREATE_USER_SETTING: 'create user setting',
  DELETE_USER_SETTING: 'delete user setting',
  GET_USER_SETTING: 'get user setting',
  UPDATE_USER_SETTING: 'update user setting',
  APPROVE_CONTENTS: 'approve contents',
  MEDIA_SERVICE_MEDIA_CENTRAL: 'request media central',
  MEDIA_SERVICE_GET_UPLOAD_URL: 'request uploado url for media central',
});
export const MetricLocations = Object.freeze({
  ACTION_CELL: 'action cell',
  BUSINESS_SELECTOR: 'business selector',
  YEAR_SELECTOR: 'year selector',
  GRID_VIEW_REQUEST_MIDDLEWARE: 'grid view request middleware',
  GRAPHQL_MIDDLEWARE: 'graphqlMiddleware',
  BULLETIN_BOARD_REQUEST_MIDDLEWARE: 'bulletin board request middleware',
  JSON_BOURNE_MIDDLEWARE: 'jsonBourneMiddleware',
  HEADER: 'header',
  METADATA: 'metadata',
  SIDE_MENU: 'side menu',
  CREATE_SYMPHONY_CAMPAIGN: 'create symphony campaign modal',
  NOTIFICATIONS_MIDDLEWARE: 'notifications middleware',
  NOTIFICATIONS_LIST_VIEW: 'notifications list view',
  COMMENTS_MIDDLEWARE: 'comments middleware',
  TIMER_MIDDLEWARE: 'timer middleware',
  PERFORMANCE_TIMER_MIDDLEWARE: 'performance timer middleware',
  UTILIZATION_PAGE_MIDDLEWARE: 'utilization page middleware',
  USAGE_TRACKING_MIDDLEWARE: 'usage tracking middleware',
  FLASH_FILL_MIDDLEWARE: 'flash fill middleware',
  RATE_CARD_LIST_MIDDLEWARE: 'rate card list middleware',
  RATE_CARD_MIDDLEWARE: 'rate card middleware',
  RATE_CARD_DELETE_MIDDLEWARE: 'rate card delete middleware',
  RATE_CARD_UPDATE_MIDDLEWARE: 'rate card update middleware',
  RATE_CARD_ASSETS_LIST_MIDDLEWARE: 'rate card assets list middleware',
  RATE_CARD_MARK_AS_PUBLISHED_MIDDLEWARE: 'rate card mark as published middleware',
  GRID_VIEW_FILTER_MIDDLEWARE: 'grid view filter middleware',
  CUSTOM_VIEW_COLUMNS_MIDDLEWARE: 'custom view columns middleware',
  APPROVE_CONTENTS_MIDDLEWARE: 'approve contents middleware',
  MEDIA_SERVICE_MIDDLEWARE_MEDIA_CENTRAL: 'media service media central middleware',
  MEDIA_SERVICE_MIDDLEWARE_GET_UPLOAD_URL: 'media service grt upload url middleware',
  SITEWIDE_MIDDLEWARE: 'sitewide middleware',
});
export const FeatureNotificationActions = Object.freeze({
  LOAD: 'LOAD',
  ADD_BULK: 'ADD_BULK',
  CLOSE: 'CLOSE',
  CLOSE_BULK: 'CLOSE_BULK',
});
export const ControlsWithHintLinks = [
  CampaignColumns.TARGETING.name,
  CampaignColumns.SYMPHONY_URL.name,
  CampaignColumns.DESIGN_SIM.name,
  CampaignColumns.WEBLAB.name,
];
export const StatusesToExcludeFromUtilization = [CampaignStatuses.CANCELED, CampaignStatuses.HOLD];
export const FilterGroupTypes = Object.freeze({
  AND: 'AND',
  OR: 'OR',
});
export const CreateCsCampaignActions = Object.freeze({
  OPEN_MODAL: 'CREATE_CS_CAMPAIGN_OPEN_MODAL',
  CLOSE_MODAL: 'CREATE_CS_CAMPAIGN_CLOSE_MODAL',
  OPEN_DIFF_MODAL: 'OPEN_DIFF_MODAL',
  CLOSE_DIFF_MODAL: 'CLOSE_DIFF_MODAL',
  CLEAR_DATA: 'CREATE_CS_CAMPAIGN_CLEAR_DATA',
  SET_CAMPAIGN: 'CREATE_CS_CAMPAIGN_SET_CAMPAIGN',
  SET_CAMPAIGN_DATA: 'CREATE_CS_CAMPAIGN_SET_CAMPAIGN_DATA',
  SET_METADATA: 'CREATE_CS_CAMPAIGN_SET_METADATA',
  SEND_CAMPAIGN: 'CREATE_CS_CAMPAIGN_SEND_CAMPAIGN',
  SET_RUNNING_STATE: 'CREATE_CS_CAMPAIGN_SET_RUNNING_STATE',
  SET_SHOULD_VALIDATE: 'CREATE_CS_CAMPAIGN_SET_SHOULD_VALIDATE',
  SET_VALIDATION_ERRORS: 'CREATE_CS_CAMPAIGN_SET_VALIDATION_ERRORS',
  CLEAR_VALIDATION_ERRORS: 'CREATE_CS_CAMPAIGN_CLEAR_VALIDATION_ERRORS',
  CALC_CAMPAIGN_DIFF: 'CALC_CAMPAIGN_DIFF',
  SET_CAMPAIGN_DIFF: 'SET_CAMPAIGN_DIFF',
  ON_CHANGE_DIFF_VIEW_MODE: 'ON_CHANGE_DIFF_VIEW_MODE',
});
export const DateIntervals = Object.freeze({
  ONE_DAY: 1,
  ONE_WEEK: 7,
});
export const MetadataEditorActions = Object.freeze({
  SELECT_FIELD: 'METADATA_EDITOR_SELECT_FIELD',
  SET_DEPENDENCIES: 'METADATA_EDITOR_SET_DEPENDENCIES',
  SET_DEPENDENT_OPTION: 'METADATA_EDITOR_SET_DEPENDENT_OPTION',
  ADD_OPTION: 'METADATA_EDITOR_ADD_OPTION',
  ADD_OPTION_FOR_FIELD: 'METADATA_EDITOR_ADD_OPTION_FOR_FIELD',
  DELETE_OPTION: 'METADATA_EDITOR_DELETE_OPTION',
  DELETE_OPTION_FOR_FIELD: 'METADATA_EDITOR_DELETE_OPTION_FOR_FIELD',
  SET_QUICK_CREATE_MODAL_VISIBILITY: 'METADATA_EDITOR_SET_QUICK_CREATE_MODAL_VISIBILITY',
  QUICK_CREATE_MODAL_SAVE: 'METADATA_EDITOR_QUICK_CREATE_MODAL_SAVE',
});
export const BulkImportActions = Object.freeze({
  PROCESS_FILE: 'BULK_IMPORT_PROCESS_FILE',
  SET_PENDING_PACKAGES: 'BULK_IMPORT_SET_PENDING_PACKAGES',
  CLEAR_PACKAGES: 'BULK_IMPORT_CLEAR_PACKAGES',
  PERFORM_IMPORT: 'BULK_IMPORT_PERFORM_IMPORT',
  ACTION_COMPLETED: 'BULK_IMPORT_ACTION_COMPLETED',
  SET_FOR_IMPORT: 'BULK_IMPORT_SET_FOR_IMPORT',
  PACKAGE_SUCCEEDED: 'BULK_IMPORT_PACKAGE_SUCCEEDED',
  PACKAGE_FAILED: 'BULK_IMPORT_PACKAGE_FAILED',
  SUCCESS: 'BULK_IMPORT_SUCCESS',
  ERROR: 'BULK_IMPORT_ERROR',
  START_IMPORTING: 'BULK_IMPORT_START_IMPORTING',
  COMPLETE_IMPORTING: 'BULK_IMPORT_COMPLETE_IMPORTING',
  SET_VALIDATION_MODE: 'BulkImportActions/SET_VALIDATION_MODE',
  PERFORM_ASSETS_IMPORT: 'BulkImportActions/PERFORM_ASSETS_IMPORT',
  START_FILE_PARSING: 'BulkImportActions/START_FILE_PARSING',
  COMPLETE_FILE_PARSING: 'BulkImportActions/COMPLETE_FILE_PARSING',
});
export const BulkImportTypes = Object.freeze({
  TEXT: 'TEXT',
  FILE: 'FILE',
});
export const BulkImportValidationModes = Object.freeze({
  ALL: {
    name: 'ALL',
    display: 'All',
  },
  PARTIAL: {
    name: 'PARTIAL',
    display: 'Partial',
  },
});
export const BulkImportViewMode = Object.freeze({
  BULK_IMPORT: 'BULK_IMPORT',
  RATE_CARD: 'RATE_CARD',
});
export const BulkImportText = Object.freeze({
  HEADING: 'Validation mode',
  PREVIEW_NOTE: "Please review the campaigns below and make any necessary adjustments prior to publishing new campaigns and/or updates to the Marketing Calendar.",
  PREVIEW_CLICK_IMPORT: "Click on the Import button to finalize your upload and publish it into the Marketing Calendar.",
  PREVIEW_CHECK_FORMAT: "Please check your file contains all necessary columns, is saved in the right format (.CSV), and contains inputs that match the Marketing Calendar’s metadata options exactly (check spelling and capitalization). after #{formatIssues} rows were rejected due to formatting issues. This may be because the file has the wrong number of columns or the file is in the wrong format",
  BULK_IMPORT_LANDING_DESCRIPTION: "With Bulk Upload, you can create or update campaigns in bulk via Excel template upload. Please make sure your template file is saved in the correct format before uploading (.CSV); for more information and step-by-step instructions on Bulk Upload, please view",
  BULK_IMPORT_COLUMN_MISMATCH: "Please review your column headers for incorrect column names. The following columns are not part of the metadata: [#{wrongColumnNames}], and should be corrected to: [#{missingColumnNames}]"
})
export const BulletinBoardActions = Object.freeze({
  LOAD: 'BulletinBoardActions/LOAD',
  SET: 'BulletinBoardActions/SET',
  DELETE: 'BulletinBoardActions/DELETE',
  ADD: 'BulletinBoardActions/ADD',
  EDIT: 'BulletinBoardActions/EDIT',
});
export const BulletinBoard = Object.freeze({
  LIMIT_MAX_ANNOUNCEMENTS: 10,
});
export const SlotsUtilizationGroups = Object.freeze({
  UNDERBOOKED: 'UNDERBOOKED',
  EXCELLENT: 'EXCELLENT',
  OVERBOOKED: 'OVERBOOKED',
});
export const SlotsUtilizationChart = Object.freeze({
  [SlotsUtilizationGroups.UNDERBOOKED]: {
    start: 0,
    end: 99,
  },
  [SlotsUtilizationGroups.EXCELLENT]: {
    start: 100,
    end: 100,
  },
  [SlotsUtilizationGroups.OVERBOOKED]: {
    start: 101,
    end: null,
  },
});
export const ColorCodes = Object.freeze({
  RED: 'red',
  GREEN: 'green',
  ORANGE: 'orange',
  BLUE: 'blue',
});
export const UtilizationFiltersNames = Object.freeze({
  SELECTED_CHANNEL: 'Selected Channel',
  SELECTED_PAGE_TYPE: 'Selected Page Type',
  SELECTED_PAGE_NAME: 'Selected Page Name',
  SELECTED_PAGE_ID: 'Selected Page ID',
});
export const AuditLogActions = Object.freeze({
  FETCH: 'AuditLogActions/FETCH',
  CLEAR: 'AuditLogActions/CLEAR',
  SET_LOADING: 'AuditLogActions/SET_LOADING',
  SET_LOADED: 'AuditLogActions/SET_LOADED',
  SET_ITEM: 'AuditLogActions/SET_ITEM',
  SET_LOGS: 'AuditLogActions/SET_LOGS',
  SET_UNTRANSLATED_LOGS: 'AuditLogActions/SET_UNTRANSLATED_LOGS',
  CLEAR_UNTRANSLATED_LOGS: 'AuditLogActions/CLEAR_UNTRANSLATED_LOGS',
});
export const CustomViewActions = Object.freeze({
  SET_USE_VIEW: 'CustomViewActions/SET_USE_VIEW',
  SET_COLUMN_ORDER: 'CustomViewActions/SET_COLUMN_ORDER',
  SET_MODAL_VISIBLE: 'CustomViewActions/SET_MODAL_VISIBLE',
  LOCAL_STORAGE_COLUMNS: 'CustomViewActions/CUSTOM_VIEW_COLUMNS',
  LOCAL_STORAGE_SETTINGS: 'CustomViewActions/CUSTOM_VIEW_SETTINGS',
});
export const AuditLogTypes = Object.freeze({
  CAMPAIGN: 'CAMPAIGN',
  PACKAGE: 'PACKAGE',
  RATE_CARD: 'RATE_CARD',
  RATE_CARD_ASSETS: 'RATE_CARD_ASSETS',
});
export const UtilizationModeNames = Object.freeze({
  AGGREGATE_BY_WEEKS: 'Aggregate by weeks',
  AGGREGATE_BY_DAYS: 'Aggregate by days',
});
export const UtilizationSovDisplayNames = Object.freeze({
  ABS: 'Show only campaigns count',
  ABS_SOV: 'Show SOV % + campaigns count',
});
export const UtilizationStorage = Object.freeze({
  SLOTS_ORDER: `${PROGRAM_NAME}_UTILIZATION_SLOTS_ORDER`,
});
export const NotificationsActions = Object.freeze({
  INIT: 'NotificationsActions/INIT',
  LOAD: 'NotificationsActions/LOAD',
  SET: 'NotificationsActions/SET',
  SORT: 'NotificationsActions/SORT',
  SET_LOADING: 'NotificationsActions/SET_LOADING',
  SET_PAGE_NUMBER: 'NotificationsActions/SET_PAGE_NUMBER',
  SET_NOTIFICATIONS_LOADED: 'NotificationsActions/SET_NOTIFICATIONS_LOADED',
  SET_NEW_NOTIFICATIONS_COUNT: 'NotificationsActions/SET_NEW_NOTIFICATIONS_COUNT',
  UPDATE_NEW_NOTIFICATIONS_COUNT: 'NotificationsActions/UPDATE_NEW_NOTIFICATIONS_COUNT',
  OPEN_WIDGET: 'NotificationsActions/OPEN_WIDGET',
  CLOSE_WIDGET: 'NotificationsActions/CLOSE_WIDGET',
  INIT_LAST_SEEN: 'NotificationsActions/INIT_LAST_SEEN',
  CREATE_LAST_SEEN_AT: 'NotificationsActions/CREATE_LAST_SEEN_AT',
  UPDATE_LAST_NOTIFICATION_RECEIVED_TIME: 'NotificationsActions/UPDATE_LAST_NOTIFICATION_RECEIVED_TIME',
  ENABLE_EXPERIENCE: 'NotificationsActions/ENABLE_EXPERIENCE',
  MARK_AS: 'NotificationsActions/MARK_AS',
  OPEN: 'NotificationsActions/OPEN',
  MARK: 'NotificationsActions/MARK',
  COMMIT_MARKED: 'NotificationsActions/COMMIT_MARKED',
  RESET_MARKED: 'NotificationsActions/RESET_MARKED',
  RESET_LOADED: 'NotificationsActions/RESET_LOADED',
  SET_UPDATING: 'NotificationsActions/SET_UPDATING',
  CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED: 'NotificationActions/CREATE_NOTIFICATION_SUBSCRIPTION_RECEIVED',
});
export const NotificationsTypes = Object.freeze({
  CREATE_CAMPAIGN: 'createCampaign',
  CREATE_PACKAGE: 'createPackage',
  UPDATE_CAMPAIGN: 'updateCampaign',
  UPDATE_PACKAGE: 'updatePackage',
  DELETE_CAMPAIGN: 'deleteCampaign',
  DELETE_PACKAGE: 'deletePackage',
  MENTION: 'mention',
});
export const NotificationsConfig = Object.freeze({
  NOTIFICATIONS_LIMIT: 5000,
  DEFAULT_DAYS_LIMIT: 14,
  DEFAULT_MINUTES_LIMIT: 1,
  NOTIFICATIONS_DISPLAY_LIMIT_WIDGET: 15,
  POLLING_INTERVAL: 30000,
  CHECK_POLLING_INTERVAL: 5000,
  // TODO: change it back
  PAGINATION_ITEMS_PER_PAGE: 50,
});
export const NotificationsPanelList = Object.freeze({
  MARK_AS_READ: { name: 'Read', label: 'MARK_AS_READ' },
  MARK_AS_UNREAD: { name: 'Unread', label: 'MARK_AS_UNREAD' },
});
export const RouterActions = Object.freeze({
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
});
export const CommentActions = Object.freeze({
  LOAD: 'CommentActions/LOAD',
  SET: 'CommentActions/SET',
  SORT: 'CommentActions/SORT',
  ADD: 'CommentActions/ADD',
  DELETE: 'CommentActions/DELETE',
  SET_POSTING: 'CommentActions/SET_POSTING',
  TOGGLE_AUTO_SCROLLING: 'CommentActions/SET_AUTO_SCROLLING',
  RESET: 'CommentActions/RESET',
  START_POLLING: 'CommentActions/START_POLLING',
  STOP_POLLING: 'CommentActions/STOP_POLLING',
  SET_ACTIVE_CAMPAIGN: 'CommentActions/SET_ACTIVE_CAMPAIGN',
  OPEN_MODAL: 'CommentActions/OPEN_MODAL',
  CLOSE_MODAL: 'CommentActions/CLOSE_MODAL',
});
export const CommentsConfig = Object.freeze({
  LOAD_LIMIT: 1000,
  POLLING_INTERVAL: 30000,
});
export const CommentsValidation = Object.freeze({
  MIN_LENGTH: 2,
  MAX_LENGTH: 3000,
});
export const PackageTabActions = Object.freeze({
  SET_TAB: 'PackageTabActions/SET_TAB',
  RESET_TAB: 'PackageTabActions/RESET_TAB',
});
export const DateFormats = Object.freeze({
  MM_DD_YY_hh_mma: 'MM/DD/YY hh:mma',
  MMDD: 'MMDD',
  MM_DD_YYYY: 'MM/DD/YYYY',
});
export const TimerActions = Object.freeze({
  START_TIMER: 'START_TIMER',
  COMPLETE_TIMER: 'COMPLETE_TIMER',
  CANCEL_TIMER: 'CANCEL_TIMER',
});
export const PerformanceTimerActions = Object.freeze({
  TIMER_START: 'PerformanceTimerActions/TIMER_START',
  TIMER_STOP: 'PerformanceTimerActions/TIMER_STOP',
});
export const Placements = Object.freeze({
  HERO: 'HERO',
  HERO_SIDE_TILE: 'Hero Side Tile',
  COOP_1: 'COOP 1',
  COOP_1_4: 'COOP 1 -4',
  COOP_2_4: 'COOP 2 -4',
});
export const WidgetTypes = Object.freeze({
  BLND_SHOV: 'BLND SHOV',
  ENHSHOV: 'ENHSHOV',
  SHOV: 'SHOV',
  HALF: 'HALF',
  HERO: 'HERO',
  HERO_MAIN: 'HERO Main',
  H_V_ENDCAP: 'H.V.ENDCAP',
  LTD_ENDCAP: 'LTD.ENDCAP',
  P_ENDCAP: 'P.ENDCAP',
  STRIPE: 'STRIPE',
});
export const MetadataAuditLogActions = Object.freeze({
  METADATA_AUDIT_LOGS_RECEIVED: 'MetadataAuditLogActions/METADATA_AUDIT_LOGS_RECEIVED',
  ADD_LOG: 'MetadataAuditLogActions/ADD_LOG',
  CLEAR_DATA: 'MetadataAuditLogActions/CLEAR_DATA',
  SET_FILTER_STRING: 'MetadataAuditLogActions/SET_FILTER_STRING',
});
export const FlashFillActions = Object.freeze({
  FILL_PAGE: 'FlashFillActions/FILL_PAGE',
  FILL_PACKAGE: 'FlashFillActions/FILL_PACKAGE',
});
export const PageNames = Object.freeze({
  BULLETIN_BOARD: 'BULLETIN_BOARD',
  CAMPAIGN_AUDIT: 'CAMPAIGN_AUDIT',
  PACKAGE_AUDIT: 'PACKAGE_AUDIT',
  GRID_VIEW: 'GRID_VIEW',
  CLONE_PACKAGE: 'CLONE_CAMPAIGN',
  EDIT_PACKAGE: 'EDIT_CAMPAIGN',
  NEW_PACKAGE: 'NEW_CAMPAIGN',
  DASHBOARD: 'DASHBOARD',
  EDIT_ANNOUNCEMENT: 'EDIT_ANNOUNCEMENT',
  NEW_ANNOUNCEMENT: 'NEW_ANNOUNCEMENT',
  IMPORTER: 'IMPORTER',
  METADATA: 'METADATA',
  METADATA_AUDIT: 'METADATA_AUDIT',
  NOTIFICATIONS: 'NOTIFICATIONS',
  UTILIZATION: 'UTILIZATION',
  IN_STORE_UTILIZATION: 'IN_STORE_UTILIZATION',
  UNKNOWN_PAGE: 'UNKNOWN_PAGE',
  RATE_CARD_NEW: 'NEW_RATE_CARD',
  RATE_CARD_EDIT: 'EDIT_RATE_CARD',
  CLONE_RATE_CARD: 'CLONE_RATE_CARD',
  RATE_CARD_ASSETS: 'RATE_CARD_ASSETS',
  RATE_CARD_LIST: 'RATE_CARDS_LIST',
  MEDIA_SERVICE_UPLOAD: 'MEDIA_SERVICE_UPLOAD',
});
export const ShovelerTypes = Object.freeze({
  BROWSE_NODE: 'Browse Node',
  FIXED_ASIN_LIST: 'Fixed ASIN List',
  SEARCH_QUERY: 'Search Query',
  PERSONALIZATION: 'Personalization',
});
export const DedupeTypes = Object.freeze({
  IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN: 'IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN',
  IGNORE_PREVIOUS_RESERVATIONS: 'IGNORE_PREVIOUS_RESERVATIONS',
  NONE: 'NONE',
});
export const GraphQLLabels = Object.freeze({
  GET_AUDIT_LOGS: 'getAuditLogs',
  CREATE_ANNOUNCEMENT: 'createAnnouncement',
  DELETE_ANNOUNCEMENT: 'deleteAnnouncement',
  EDIT_ANNOUNCEMENT: 'editAnnouncement',
  GET_ANNOUNCEMENT: 'getAnnouncement',
  CREATE_COMMENT: 'createComment',
  DELETE_COMMENT: 'deleteComment',
  CREATE_CS_CAMPAIGN: 'createCsCampaign',
  CREATE_OR_UPDATE_CS_CAMPAIGN: 'createOrUpdateCsCampaign',
  VALIDATE_CS_CAMPAIGN_INPUT: 'validateCsCampaignInput',
  GET_USER_SESSION: 'getUserSession',
  CREATE_USER_SESSION: 'createUserSession',
  RENEW_USER_SESSION: 'renewUserSession',
  CREATE_PACKAGE: 'createPackage',
  UPDATE_PACKAGE: 'updatePackage',
  CREATE_CAMPAIGN: 'createCampaign',
  GET_CAMPAIGN: 'getCampaign',
  UPDATE_CAMPAIGN: 'updateCampaign',
  DELETE_CAMPAIGN: 'deleteCampaign',
  CREATE_RATE_CARD: 'createRateCard',
  DELETE_RATE_CARD: 'deleteRateCard',
  UPDATE_RATE_CARD: 'updateRateCard',
  CREATE_RATE_CARD_ASSET: 'createRateCardAsset',
  DELETE_RATE_CARD_ASSET: 'deleteRateCardAsset',
  UPDATE_RATE_CARD_ASSET: 'updateRateCardAsset',
  CREATE_USER_SETTING: 'createUserSetting',
  GET_USER_SETTING: 'getUserSetting',
  UPDATE_USER_SETTING: 'updateUserSetting',
  DELETE_USER_SETTING: 'deleteUserSetting',
  APPROVE_CONTENTS: 'approveContents',
});
export const CachedCampaignsConfig = Object.freeze({
  COUNT: 499,
  CHUNK_SIZE: 100,
});
export const DragAndDropWidget = Object.freeze({
  DATA_TRANSFER_ID: 'item',
});
export const UsageMetrics = Object.freeze({
  NAME_PREFIX: 'USAGE_METRIC',
});
export const CsDiffTypes = Object.freeze({
  NO_CHANGE: 'NO_CHANGE',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  CHANGE: 'CHANGE',
});
export const CsDiffScreenTabs = Object.freeze({
  ALL: 'ALL',
  CHANGES_ONLY: 'CHANGES_ONLY',
});
export const CsCategoryNames = Object.freeze({
  DESKTOP_PLACEMENT: 'desktopPlacement',
  MOBILE_PLACEMENT: 'mobilePlacement',
  CREATIVE: 'creative',
  CONTENT: 'content',
});
export const CsCategoryNamesToReadableNames = Object.freeze({
  [CsCategoryNames.DESKTOP_PLACEMENT]: 'Desktop Placement',
  [CsCategoryNames.MOBILE_PLACEMENT]: 'Mobile Placement',
  [CsCategoryNames.CREATIVE]: 'Creative',
  [CsCategoryNames.CONTENT]: 'Content',
});
export const PerformanceTimerNames = Object.freeze({
  APP_INIT: 'APP_INIT',
  UTILIZATION_PAGE: 'UTILIZATION_PAGE',
  BULK_IMPORT: 'BULK_IMPORT',
});
export const QueryParseTypes = Object.freeze({
  STRING: 'STRING',
  ARRAY: 'ARRAY',
});
export const SHOP_ALL = 'Shop All';
export const US = 'US';
export const AlmBrandIds = {
  [BusinessTypes.FRESH.name]: 'QW1hem9uIEZyZXNo',
  [BusinessTypes.NA_3P.name]: {
    'All The Best Pets': 'QWxsIFRoZSBCZXN0IFBldCBDYXJl',
    'Bartell Drugs': 'QmFydGVsbCBEcnVncw',
    'Bristol Farms': 'YnJpc3RvbCBmYXJtcw',
    'Cardenas': 'RDMXfFItJA',
    'FoodMaxx': 'pNHniBOEyG',
    'Keg N Bottle': 'S2VnIE4gQm90dGxl',
    'Lucky Supermarkets': 'WQppp1xWMK',
    'M&M': 'hJDG4SCSoK',
    'Mission Wine': 'TWlzc2lvbiBMaXF1b3I',
    'New Seasons': 'hY0j6LN5aS',
    'Pet Food Express': 'UGV0IEZvb2QgRXhwcmVzcw',
    'Rite Aid': 'WPcGbYPLzk',
    'Save Mart': 'U2F2ZSBNYXJ0',
    'Weis': 'V2VpcyBNYXJrZXRz',
    'Sousa\'s': 'U291c2EncyBXaW5lcyAmIExpcXVvcnM',
    'Surdyk\'s': 'U3VyZHlrJ3MgTGlxdW9yICYgQ2hlZXNlIFNob3A',
  },
  [BusinessTypes.WFM.name]: 'VUZHIFdob2xlIEZvb2Rz',
  [BusinessTypes.WFMC_NA.name]: 'aNHVc2Akvg',
};
export const SearchAliases = {
  [BusinessTypes.FRESH.name]: 'amazonfresh',
  // I can probably just write a function to lowercase everything and remove the spaces, but
  // I don't want to do that just in case MMs add any irregularities - @hanwooll
  [BusinessTypes.NA_3P.name]: {
    'All The Best Pets': 'allthebestpets',
    'Bartell Drugs': 'bartelldrugs',
    'Bristol Farms': 'bristolfarms',
    'Cardenas': 'cardenas',
    'FoodMaxx': 'foodmaxx',
    'Keg N Bottle': 'kegnbottle',
    'Lucky Supermarkets': 'lucky',
    'M&M': 'metropolitanmarket',
    'Mission Wine': 'missionwine',
    'New Seasons': 'newseasonsmarket',
    'Pet Food Express': 'petfoodexpress',
    'Rite Aid': 'riteaid',
    'Save Mart': 'savemart',
    'Weis': 'weis',
    'Sousa\'s': 'sousa\'s',
    'Surdyk\'s': 'surdky\'s',
  },
  [BusinessTypes.WFM.name]: 'wholefoods',
};
export const ContentSymphonyPageIDs = Object.freeze({
  ALM_BYG: 'alm-byg',
});
export const MomentJsDayNames = Object.freeze({
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
});
export const QueryParams = Object.freeze({
  FILTERS: 'filters',
  SORTS: 'sorts',
});
export const RouterHistoryTypes = Object.freeze({
  CAMPAIGN_MODAL_OPEN: 'CAMPAIGN_MODAL_OPEN',
  CAMPAIGN_MODAL_CLOSE: 'CAMPAIGN_MODAL_CLOSE',
});
export const BulkImportWorkMode = Object.freeze({
  STANDARD: 'STANDARD',
  RATE_CARD: 'RATE_CARD',
});
export const PlacementSource = Object.freeze({
  HOUSE: 'House',
  VSSM: 'VSSM',
  RATE_CARD: 'Rate Card',
});
export const PlacementType = Object.freeze({
  IN_STORE: 'In-Store',
  ONLINE: 'Online',
  OMNICHANNEL: 'Omnichannel',
  STRIPE: 'Stripe',
  ILM: 'ILM',
});
export const PageIds = Object.freeze({
  BEFORE_YOU_GO_PAGE_ID_FOR_FRESH_NA: "before-you-go-amabot-desktop|mobile",
  FMC_DUMMY_VALUE: 'FMCDummyValue',
  DEALS: 'Deals',
  SNAP_EBT: '20190785',
});
export const PageTypes = Object.freeze({
  BYG: 'byg',
  CATEGORY: 'Category',
  CHECKOUT: 'Checkout',
  DEALS: 'Deals',
  EVENT: 'Event',
  ILM: 'ILM',
  IN_STORE: 'In-store',
  OTHER: 'Other',
  RECIPES: 'Recipes',
  SEARCH: 'Search',
  SNAP_EBT: 'SNAP EBT',
  STOREFRONT: 'Storefront',
  TEST_VALUE: 'Test Value',
});
export const PromoTypes = Object.freeze({
  VPC: 'VPC',
})
export const CampaignTypes = Object.freeze({
  IN_STORE: 'In-Store',
  ONLINE: 'Online',
});
export const DeviceTypes = Object.freeze({
  ALL: 'All',
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
});
export const VerticalTypes = Object.freeze({
  ALCOHOL: 'Alcohol',
});
export const VSSMExclusiveSlots = Object.freeze([
  {
    PAGE_TYPE: PageTypes.CATEGORY,
    DESKTOP_SLOT_NAME: 'alm-category-desktop-middletwo-2',
    MOBILE_SLOT_NAME: 'alm-category-mobile-middletwo-2',
  },
  {
    PAGE_TYPE: PageTypes.STOREFRONT,
    DESKTOP_SLOT_NAME: 'alm-storefront-desktop-bottom-4',
    MOBILE_SLOT_NAME: 'alm-storefront-mobile-bottom-4',
  },
]);
export const NeedsInventoryAwarenessFilterFields = Object.freeze([
  CampaignColumns.INVENTORY_AWARENESS.name,
  CampaignColumns.HERO_ASINS.name,
  CampaignColumns.ASINS.name,
  CampaignColumns.BROWSE_NODE_IDS.name,
]);
export const NeedsInventoryAwarenessHeroFields = Object.freeze([
  CampaignColumns.INVENTORY_AWARENESS.name,
  CampaignColumns.HERO_ASINS.name,
]);

export const AdLeftDesktopSlots = Object.freeze([
  'alm-storefront-desktop-adleftplacement-1',
  'alm-storefront-desktop-adleftplacement-2',
]);

export const ActionButtonNames = Object.freeze({
  EDIT: 'Edit',
  CLONE: 'Clone',
  DELETE: 'Delete',
  AUDIT_LOG: 'Audit log',
  SHOW_PUBLISHED_ASSETS: 'Show published Assets',
});

export const PackageTabTypes = Object.freeze({
  OMNICHANNEL: 'OMNICHANNEL',
  NON_OMNICHANNEL: 'NON_OMNICHANNEL',
});

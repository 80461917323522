import moment from 'moment';
import { getYearStartDate } from '../../helpers/dateTime';
import { getStatsByDateRange } from './helpers';
import { createDateRange } from '../../helpers/utilization';
import {
  isFreshBusiness,
  isFreshDEBusiness,
  isGeneric3PBusiness,
  isGenericFreshBusiness,
  isUSBusiness,
} from '../../helpers/common';
import {
  CampaignColumns,
  CampaignTypes,
  DashboardActions,
} from '../../constants';
import {
  onUtilizationFilterChange,
  onDashboardDateRangeSet,
  onDashboardStatsSet,
  onDashboardRunCalc,
  onUtilizationSelectDateRange,
  onUtilizationStartTimeSet,
  onUtilizationStopTimeSet,
} from '../../actionCreators';
import {
  getPackagesForCurrentBusinessStartYears
} from '../../selectors/package';

export default ({ dispatch, getState }) => (next) => (action) => {
  const { type } = action;

  if (type === DashboardActions.INIT) {
    const { Sitewide: { selectedBusiness, startYear } } = getState();

    // TODO: Load from local storage
    const dateRange = createDateRange();

    dispatch(onDashboardDateRangeSet(dateRange));
    dispatch(onDashboardRunCalc());

    const channel = CampaignColumns.CHANNEL.name;
    const pageName = CampaignColumns.PAGE_NAME.name;
    const pageType = CampaignColumns.PAGETYPE.name;
    const pageID = CampaignColumns.PAGE_ID.name;
    const language = CampaignColumns.LANGUAGE.name;
    const campaignType = CampaignColumns.CAMPAIGN_TYPE.name;
    // Load Utilization section of the Dashboard with default presets
    if (isFreshDEBusiness(selectedBusiness)) {
      dispatch(onUtilizationFilterChange(channel, {}, 'Fresh'));
      dispatch(onUtilizationFilterChange(pageType, {}, 'Storefront'));
      dispatch(onUtilizationFilterChange(pageName, {}, 'Storefront'));
    } else if (isGenericFreshBusiness(selectedBusiness)) {
      dispatch(onUtilizationFilterChange(pageType, {}, 'Storefront'));
      dispatch(onUtilizationFilterChange(pageName, {}, 'Storefront'));
    } else if (isGeneric3PBusiness(selectedBusiness)) {
      dispatch(onUtilizationFilterChange(pageType, {}, 'Storefront'));
      dispatch(onUtilizationFilterChange(pageID, {}, 'FMCDummyValue'));
    } else {
      dispatch(onUtilizationFilterChange(channel, {}, 'WFM'));
      dispatch(onUtilizationFilterChange(pageType, {}, 'Storefront'));
      dispatch(onUtilizationFilterChange(pageID, {}, 'FMCDummyValue'));
    }

    if (isFreshBusiness(selectedBusiness)) {
      dispatch(onUtilizationFilterChange(language, {}, 'English'));
      dispatch(onUtilizationFilterChange(campaignType, {}, CampaignTypes.ONLINE));
    }

    dispatch(onUtilizationSelectDateRange(
      createDateRange({
        isNA: isUSBusiness(selectedBusiness),
        startFromDate: startYear && startYear !== moment().year().toString() ? getYearStartDate(startYear) : moment(),
      }),
    ));

    // Set default time
    if (isGenericFreshBusiness(selectedBusiness)) {
      dispatch(onUtilizationStartTimeSet({ startTime: '09:00' }));
      dispatch(onUtilizationStopTimeSet({ endTime: '08:59' }));
    } else {
      dispatch(onUtilizationStartTimeSet({ startTime: '00:00' }));
      dispatch(onUtilizationStopTimeSet({ endTime: '23:59' }));
    }
  }

  if (type === DashboardActions.RUN_CALC) {
    const state = getState();
    const {
      Dashboard: {
        isOverlapMode,
        dateRange,
        startTime,
        endTime,
      },
      User: {
        currentUser: {
          username,
        },
      },
      Sitewide: {
        selectedBusiness,
      },
    } = state

    const [rangeDateStart, rangeDateEnd] = dateRange;
    const newDateTimeRange = [
      `${rangeDateStart} ${startTime}`,
      `${rangeDateEnd} ${endTime}`,
    ];

    const stats = getStatsByDateRange(
      selectedBusiness,
      getPackagesForCurrentBusinessStartYears(state),
      newDateTimeRange,
      isOverlapMode,
      username,
    );

    dispatch(onDashboardStatsSet(stats));
  }

  return next(action);
};

import { connect } from 'react-redux';

import { getCampaignFieldsByBusiness } from '../../../helpers/form';
import getAllMetadataOptionsForColumn from '../../../helpers/getAllMetadataOptionsForColumn';
import { FilterGroupTypes } from '../../../constants';

import GridViewFilterRow from './GridViewFilterRow';

const mapStateToProps = (state, ownProps) => {
  const {
    selectedBusiness,
  } = state.Sitewide;

  const {
    filter,
    updateFilter,
    index,
    deleteFilter,
  } = ownProps;

  const onInputChange = (column) => {
    return (value) => {
      const updatedFilter = {
        ...filter,
        [column]: (Number.isNaN(Number(value)) || value === '') ? value : Number(value),
      };
      // When changing column, we need to also strip the value or we could end up with strange
      // filter states such as (vendor === 'old brand name') causing no results to show
      if (column === 'column') {
        updatedFilter.value = '';
      }
      updateFilter(index, updatedFilter);
    };
  };

  const updateChildFilters = (updatedFilters) => {
    if (updatedFilters.length === 0) {
      return deleteFilter(index);
    }
    const newFilters = {
      ...filter,
      rules: updatedFilters,
    };
    return updateFilter(index, newFilters);
  };

  const onToggleGroupType = () => {
    const updatedFilter = {
      ...filter,
      type: filter.type === FilterGroupTypes.OR ? FilterGroupTypes.AND : FilterGroupTypes.OR,
    };
    return updateFilter(index, updatedFilter);
  };

  const deleteGroup = () => {
    return deleteFilter(index);
  };

  return {
    campaignColumns: getCampaignFieldsByBusiness(selectedBusiness),
    getAllMetadataOptionsForColumn: (column) => getAllMetadataOptionsForColumn(state, column),
    onInputChange,
    updateChildFilters,
    onToggleGroupType,
    deleteGroup,
  };
};

export default connect(mapStateToProps)(GridViewFilterRow);

import React from 'react';
import PropTypes from 'prop-types';
import Row from '@amzn/meridian/row';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import UtilityPanel from '../UtilityPanel';

const UpdateUtilityFiltersModal = (props) => {
  const { isOpen, onShowResults, onCloseFilters } = props;

  return (
    <Modal open={isOpen}>
      <UtilityPanel />
      <ModalFooter>
        <Row alignment="center right" widths="fit">
          <Button type="secondary" size="small" onClick={onCloseFilters}>Cancel</Button>
          <Button
            type="secondary"
            size="small"
            onClick={onShowResults}
          >
            Show
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

UpdateUtilityFiltersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onShowResults: PropTypes.func.isRequired,
  onCloseFilters: PropTypes.func.isRequired,
};

export default UpdateUtilityFiltersModal;

import { connect } from 'react-redux';

import {
  onMetadataEditorSelectField,
  onMetadataEditorAddOption,
  onMetadataEditorDeleteOption,
  onMetadataEditorSetQuickCreateModalVisibility,
} from '../../../actionCreators';

import MetadataEditor from './editor';

export const mapStateToProps = (state) => {
  const {
    Meta: { metadata: { fields } },
    MetadataEditor: { selectedField, dependencies },
  } = state;

  return {
    fields,
    selectedField,
    dependencies,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSelectField(fieldName) { dispatch(onMetadataEditorSelectField(fieldName)); },
    onAddOption(option) { dispatch(onMetadataEditorAddOption(option)); },
    onDeleteOption(option) { dispatch(onMetadataEditorDeleteOption(option)); },
    onOpenQuickCreateModal() { dispatch(onMetadataEditorSetQuickCreateModalVisibility(true)); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MetadataEditor);

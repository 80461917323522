import { connect } from 'react-redux';
import UtilizationSlotsPanel from './UtilizationSlotsPanel';
import {
  onUtilizationSetSlotsOrder,
} from '../../../actionCreators';

export const mapStateToProps = (state, { slots }) => {
  return {
    items: slots,
  };
};

export const mapDispatchToProps = (dispatch, { slotType }) => {
  return {
    onSave(slots) {
      dispatch(onUtilizationSetSlotsOrder({
        slots,
        slotType,
      }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtilizationSlotsPanel);

import React from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import { RateCardConfigNames } from '../../constants/rateCard';

const RateCardConfigSelector = ({ configName, onChange }) => {
  const options = Object.values(RateCardConfigNames).map(({ name, label }) => (
    <SelectOption
      key={name}
      value={name}
      label={label}
    />
  ));

  return (
    <Select
      value={configName}
      onChange={onChange}
      placeholder="Select a Config"
      size="small"
    >
      {options}
    </Select>
  );
};

RateCardConfigSelector.propTypes = {
  configName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RateCardConfigSelector;

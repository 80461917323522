import { BulkImportTypes } from '../../../constants';
import CSVToArray from '../../../helpers/csvToArray';

const getNormalizedFileData = (data, fileType) => {
  if (fileType === BulkImportTypes.FILE) {
    return CSVToArray(data);
  }
  return [];
};

export default getNormalizedFileData;

import React from 'react';
import { useHistory } from 'react-router-dom';
import Tooltip from '@amzn/meridian/tooltip';
import editTokens from '@amzn/meridian-tokens/base/icon/edit';
import copyToken from '@amzn/meridian-tokens/base/icon/copy';
import deleteTokens from '@amzn/meridian-tokens/base/icon/delete';
import dashboardTokens from '@amzn/meridian-tokens/base/icon/dashboard';
import exportLargeTokens from '@amzn/meridian-tokens/base/icon/export-large';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import PropTypes from 'prop-types';
import styles from './RateCardListButtons.module.scss';
import { rateCardProps } from '../../proptypes';
import { ActionButtonNames, Language, Urls } from '../../constants';

const RateCardListButtons = (props) => {
  const {
    rateCard,
    onDelete,
    onAuditLogOpen,
    onEditRateCard,
    showCloneButton,
    hasPublishedAssets,
    publishedCampaignListUrl,
  } = props;

  const history = useHistory();

  const buttons = [
    {
      name: ActionButtonNames.EDIT,
      hintName: Language.ACTION_BUTTON_EDIT,
      iconToken: editTokens,
      callback: () => {
        onEditRateCard();
        history.push(`${Urls.RATE_CARD_EDIT}/${rateCard.id}`);
      },
    },
    {
      name: ActionButtonNames.CLONE,
      hintName: Language.ACTION_BUTTON_CLONE,
      iconToken: copyToken,
      callback: () => {
        history.push(`${Urls.RATE_CARD_CLONE}/${rateCard.id}`);
      },
    },
    {
      name: ActionButtonNames.DELETE,
      hintName: Language.ACTION_BUTTON_DELETE,
      iconToken: deleteTokens,
      callback: onDelete,
    },
    {
      name: ActionButtonNames.AUDIT_LOG,
      hintName: Language.ACTION_BUTTON_AUDIT_LOG,
      iconToken: dashboardTokens,
      callback: () => {
        onAuditLogOpen();
        history.push(`${Urls.RATE_CARD_EDIT}/${rateCard.id}`);
      },
    },
    {
      name: ActionButtonNames.SHOW_PUBLISHED_ASSETS,
      hintName: Language.ACTION_BUTTON_SHOW_PUBLISHED_ASSETS,
      iconToken: exportLargeTokens,
      callback: () => {
        history.push(publishedCampaignListUrl);
      },
    },
  ];

  const filteredButtons = buttons.filter(({ name }) => {
    if (name === ActionButtonNames.CLONE && !showCloneButton) {
      return false;
    }
    if (name === ActionButtonNames.SHOW_PUBLISHED_ASSETS && !hasPublishedAssets) {
      return false;
    }

    return true;
  });

  const buttonsList = filteredButtons.map(({
    name, iconToken, hintName, callback,
  }) => {
    return (
      <li
        key={name}
        className={styles.buttonItem}
      >
        <Tooltip
          key={name}
          position="right"
          title={hintName}
        >
          <Button
            type="icon"
            onClick={() => callback(rateCard)}
            size="small"
          >
            <Icon tokens={iconToken}>{name}</Icon>
          </Button>
        </Tooltip>
      </li>
    );
  });

  return (
    <ul className={styles.buttonsList}>
      {buttonsList}
    </ul>
  );
};

RateCardListButtons.propTypes = {
  rateCard: rateCardProps.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAuditLogOpen: PropTypes.func.isRequired,
  onEditRateCard: PropTypes.func.isRequired,
  showCloneButton: PropTypes.bool.isRequired,
  hasPublishedAssets: PropTypes.bool.isRequired,
  publishedCampaignListUrl: PropTypes.string,
};

RateCardListButtons.defaultProps = {
  publishedCampaignListUrl: '',
};

export default RateCardListButtons;

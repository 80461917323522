export default (getState, { pageSelection, exportType, exportFilters }) => {
  const { UserSettings: { exportPage } } = getState();
  const newPageSelection = pageSelection
    || exportPage.pageSelection;
  const newExportType = exportType
    || exportPage.exportType;
  const newExportFilters = exportFilters 
    || exportPage.exportFilters;
  return {
    pageSelection: newPageSelection,
    exportType: newExportType,
    exportFilters: newExportFilters,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import UtilizationForm from './UtilizationForm';
import UtilizationSlots from './UtilizationSlots';
import UtilizationOnlineCalendar from './UtilizationOnlineCalendar';
import styles from './Utilization.module.scss';

const Utilization = ({ isCalendarViewMode }) => {
  const content = isCalendarViewMode
    ? <UtilizationOnlineCalendar />
    : <UtilizationSlots />;

  return (
    <article className={styles.wrapper}>
      <section className={styles.filtersContainer}>
        <UtilizationForm />
      </section>
      <section className={styles.contentContainer}>
        <div className={styles.contentWrapper}>
          {content}
        </div>
      </section>
    </article>
  );
};

Utilization.propTypes = {
  isCalendarViewMode: PropTypes.bool,
};

Utilization.defaultProps = {
  isCalendarViewMode: false,
};

export default Utilization;

import { isPackage } from '../../helpers/package';

const getUpdatedPackage = (currentPackage, columnName, newValue) => {
  return {
    ...currentPackage,
    [columnName]: newValue,
  }
};

const getUpdatedCampaigns = (currentPackage, id, columnName, newValue) => {
  if (!currentPackage || !currentPackage.campaigns) {
    return [];
  }

  return currentPackage.campaigns.map(campaign => {
    if (campaign.id === id && campaign.hasOwnProperty(columnName)) {
      return {
        ...campaign,
        [columnName]: newValue,
      }
    }
    return campaign;
  });
};

const updateCellValue = (
  state,
  item,
  columnName,
  newValue,
) => {
  const { business_startYear, id, packageId } = item;
  if (!business_startYear || !id || !columnName) {
    return state;
  }

  const isPackageUpdate = isPackage(item);
  const existingPackage = isPackageUpdate ? state.packages[business_startYear][id] : state.packages[business_startYear][packageId];
  if (!existingPackage) {
    return state;
  }

  const newPackage = isPackageUpdate ? getUpdatedPackage(existingPackage, columnName, newValue) : {...existingPackage, campaigns: getUpdatedCampaigns(existingPackage, id, columnName, newValue)};

  return {
    ...state,
    packages: {
      ...state.packages,
      [business_startYear]: {
        ...state.packages[business_startYear],
        [newPackage.id]: newPackage
      }
    }
  };
};

export default updateCellValue;

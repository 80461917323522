import React from 'react';
import Tab, { TabGroup } from '@amzn/meridian/tab';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Button from '@amzn/meridian/button';
import {
  CsDiffTypes,
  Language,
  CsCategoryNamesToReadableNames,
  CsDiffScreenTabs,
} from '../../constants';

import styles from './CsDiffScreenModal.module.scss';
import { CsDiffProps } from '../../proptypes';

const CsDiffScreenModal = (props) => {
  const {
    isOpen,
    diffData,
    onCloseModal,
    onChangeViewMode,
    diffViewMode,
  } = props;

  const getItemsList = () => Object.keys(diffData).map((diffKeyName) => {
    const diffContainer = diffData[diffKeyName];
    if (!diffContainer) {
      return null;
    }

    const getItem = (
      keyName,
      diffType = CsDiffTypes.NO_CHANGE,
      oldValue = '',
      newValue = '',
      isHeader = false,
    ) => {
      const containerClasses = classnames({
        [styles.line]: true,
        [styles.lineHeader]: isHeader,
        [styles.lineAdd]: diffType === CsDiffTypes.ADD,
        [styles.lineDelete]: diffType === CsDiffTypes.REMOVE,
        [styles.lineChange]: diffType === CsDiffTypes.CHANGE,
      });

      return (
        <li
          key={keyName}
          className={containerClasses}
        >
          <span className={classnames([styles.diff, styles.diffRowTitle])}>{keyName}</span>
          <span className={classnames([styles.diff, styles.diffOld])}>{oldValue}</span>
          <span className={classnames([styles.diff, styles.diffNew])}>{newValue}</span>
        </li>
      );
    };

    const itemsList = Object.keys(diffContainer).map((diffItemKeyName) => {
      // Skip this key as we have errors reporting in the section below the main one
      if (diffItemKeyName === 'errorMessage') {
        return null;
      }
      const diffItemContainer = diffContainer[diffItemKeyName];
      if (!diffItemContainer) {
        return null;
      }
      const { oldValue, newValue, diffType } = diffItemContainer;

      if (diffViewMode === CsDiffScreenTabs.CHANGES_ONLY
        && diffType === CsDiffTypes.NO_CHANGE) {
        return null;
      }

      return getItem(
        diffItemKeyName,
        diffType,
        oldValue,
        newValue,
      );
    });

    const itemsCategoryHeader = getItem(
      'Field name',
      CsDiffTypes.NO_CHANGE,
      'Old value',
      'New value',
      true,
    );

    const getErrors = () => {
      const { errorMessage } = diffContainer;
      if (!errorMessage || !errorMessage.newValue) {
        return null;
      }

      return (
        <div className={styles.errors}>
          <p className={styles.errorsTitle}>The following error has occurred:</p>
          <div className={styles.errorsMessage}>
            {errorMessage.newValue}
          </div>
        </div>
      );
    };

    const title = CsCategoryNamesToReadableNames[diffKeyName]
      ? CsCategoryNamesToReadableNames[diffKeyName]
      : diffKeyName;
    const errorsBlock = getErrors();

    return (
      <div
        key={diffKeyName}
        className={styles.listContainer}
      >
        <ul
          className={styles.list}
        >
          <h3 className={styles.title}>{title}</h3>
          {itemsCategoryHeader}
          {itemsList}
        </ul>
        {errorsBlock}
      </div>
    );
  });

  const onTabChange = (tabName) => onChangeViewMode(tabName);

  const getCloseButton = () => {
    return (
      <>
        <Button
          type="primary"
          size="small"
          onClick={onCloseModal}
          data-testid="close-cs-diff-modal"
        >
          {Language.CREATE_SYMPHONY_CAMPAIGN_MODAL_CLOSE_BUTTON}
        </Button>
      </>
    );
  };

  const getTabs = () => {
    return (
      <TabGroup value={diffViewMode} onChange={onTabChange}>
        <Tab value={CsDiffScreenTabs.ALL}>
          {Language.CREATE_SYMPHONY_CAMPAIGN_MODAL_VIEW_MODE_ALL}
        </Tab>
        <Tab value={CsDiffScreenTabs.CHANGES_ONLY}>
          {Language.CREATE_SYMPHONY_CAMPAIGN_MODAL_VIEW_MODE_ONLY_CHANGED}
        </Tab>
      </TabGroup>
    );
  };

  const getContent = () => {
    if (!diffData) {
      return 'No data to display';
    }

    const tabs = getTabs();
    const itemsList = getItemsList();

    return (
      <>
        <div className={styles.tabs}>
          {tabs}
        </div>
        <div className={styles.changes}>
          {itemsList}
        </div>
      </>
    );
  };

  const content = getContent();
  const closeButton = getCloseButton();

  return (
    <Modal
      open={isOpen}
      title={Language.CREATE_SYMPHONY_CAMPAIGN_MODAL_DIFF_TITLE}
    >
      {content}
      <ModalFooter>
        <div className={styles.controlsContainer}>
          <div className={styles.buttonsContainer}>
            {closeButton}
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

CsDiffScreenModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  diffViewMode: PropTypes.string.isRequired,
  diffData: PropTypes.shape({
    content: PropTypes.shape({
      id: CsDiffProps,
    }),
    creative: PropTypes.shape({
      id: CsDiffProps,
    }),
  }),
  onCloseModal: PropTypes.func.isRequired,
  onChangeViewMode: PropTypes.func.isRequired,
};

CsDiffScreenModal.defaultProps = {
  diffData: {},
};

export default CsDiffScreenModal;

import { connect } from 'react-redux';
import RateCardContainerHeader from './RateCardContainerHeader';

export const mapStateToProps = (state, ownProps) => {
  const { loadedRateCard } = ownProps;

  const {
    RateCard: {
      cards,
    },
    Meta: {
      initialMetadataLoaded,
    },
  } = state;

  return {
    showTabs: !!loadedRateCard,
    isLoading: !cards.length && !initialMetadataLoaded,
  };
};

export default connect(mapStateToProps)(RateCardContainerHeader);

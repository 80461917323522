import {
  RateCardMerchTypes,
  RateCardPlacementTypes,
} from '../../../../constants/rateCard';

const Consideration = [
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Enhanced Shoveler (4-weeks)',
    dollars: 19000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Shoveler (4-weeks)',
    dollars: 9000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Enhanced Shoveler (2-weeks)',
    dollars: 8000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Shoveler (2-weeks)',
    dollars: 8000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Fresh Event Inclusion',
    dollars: 30000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Specialty Page Inclusion',
    dollars: 10000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Enhanced Shoveler (1-quarter)',
    dollars: 55000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Category Shoveler (1-quarter)',
    dollars: 25000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Specialty Page Exclusive Shoveler',
    dollars: 25000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Fresh Event Shoveler',
    dollars: 75000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'SNAP EBT Page Shoveler (1-quarter)',
    dollars: 25000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 999,
    numberPlacements: 2,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: '[Standard] Seasonal Event Bundle',
    dollars: 115000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 2,
    numberPlacements: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: '[Premium] Seasonal Event Bundle',
    dollars: 200000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 2,
    numberPlacements: 1,
    description: 'Available to Gold+ LoS only',
  },
];

export default Consideration;
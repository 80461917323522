import React, { useState, useMemo } from 'react';
import escapeRegExp from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@amzn/meridian/button';
import Select, { SelectOption } from '@amzn/meridian/select';
import Text from '@amzn/meridian/text';
import Column from '@amzn/meridian/column';
import { formControlInputProps } from '../../../proptypes';
import styles from '../PackageForm.module.scss';
import selectStyles from './Select.module.scss';
import { getMessages } from './helpers';
import { Delimiters } from '../../../constants';

const getInputValue = (value) => {
  if (!value || !value.length) {
    return [];
  }

  return value;
};

const getOutputValue = (values, options) => {
  if (!values || !values.length) {
    return [];
  }

  // We need to re-order elements the way they are ordered in the metadata not the way they've been
  // selected by customers
  const selectedOptionsValues = {};
  values.forEach((option) => { selectedOptionsValues[option] = true; });

  const reorderedValues = options
    .filter((option) => selectedOptionsValues[option.value])
    .map((option) => option.value);

  return reorderedValues;
};

const getLabel = (selectedOptions) => {
  return selectedOptions.map((option) => option.label).join(`${Delimiters.COMMA} `);
};

const MultiSelectControl = (props) => {
  const {
    options,
    label,
    input,
    placeholder,
    meta: {
      touched,
      error,
      warning,
    },
    isRequired,
    allowOverride,
    showAutoSelect,
    customOnBlur,
    isDisabled,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const searchRegExp = useMemo(
    () => new RegExp(escapeRegExp(searchQuery), 'i'),
    [searchQuery],
  );
  const matchedOptions = options.filter(
    (option) => !searchQuery || searchRegExp.test(option.label),
  );
  const { name, value } = input;

  const optionsList = matchedOptions.map(({ label: optionLabel, value: optionValue }) => {
    if (optionLabel && optionValue) {
      return (
        <SelectOption
          label={optionLabel}
          value={optionValue}
          key={`${name}/${optionValue}`}
        />
      );
    }
    return null;
  });

  const emptyResults = allowOverride && searchQuery !== '' ? (
    <SelectOption value={searchQuery} label={searchQuery} key={searchQuery} />
  ) : (
    <Column alignment="center stretch" spacing="small" spacingInset="small">
      <Text alignment="center">No results</Text>
    </Column>
  );

  const messages = getMessages(touched, error, warning);
  const inputValue = getInputValue(value);

  const onChange = (newValue) => customOnBlur(name, getOutputValue(newValue, options));
  const onSelectAll = (markAllAsSelected = true) => () => {
    const values = markAllAsSelected
      ? options.map((option) => option.value)
      : [];
    customOnBlur(name, getOutputValue(values, options));
  };
  const onUnSelectAll = () => onSelectAll(false);

  const getSelectControls = () => {
    if (!showAutoSelect) {
      return null;
    }

    const autoSelectOptionsList = [
      {
        optionName: 'Select All',
        handler: onSelectAll,
      },
      {
        optionName: 'Unselect All',
        handler: onUnSelectAll,
      },
    ];

    const autoSelectOptions = autoSelectOptionsList.map(({ optionName, handler }) => {
      return (
        <li
          key={optionName}
          className={selectStyles.selectControlsItem}
        >
          <Button
            onClick={handler()}
            type="tertiary"
            size="small"
          >
            {optionName}
          </Button>
        </li>
      );
    });

    return (
      <ul className={selectStyles.selectControlsContainer}>
        {autoSelectOptions}
      </ul>
    );
  };

  const selectControls = getSelectControls();

  return (
    <div className={classnames(styles.controlBox, styles.controlBoxHorizontal)}>
      {isRequired ? <span className={styles.controlRequiredMark}>*</span> : null}
      <Select
        {...input}
        onBlur={() => {}}
        onChange={onChange}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        value={inputValue}
        label={label}
        valueLabel={getLabel}
        error={touched && !!error}
        size="small"
        placeholder={placeholder}
        disabled={isDisabled}
      >
        {optionsList}
        {!optionsList.length && emptyResults}
      </Select>
      {messages}
      {selectControls}
    </div>
  );
};

MultiSelectControl.propTypes = {
  isDisabled: PropTypes.bool,
  allowOverride: PropTypes.bool,
  showAutoSelect: PropTypes.bool,
  customOnBlur: PropTypes.func.isRequired,
  isRequired: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: formControlInputProps.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }).isRequired,
};

MultiSelectControl.defaultProps = {
  isDisabled: false,
  allowOverride: false,
  showAutoSelect: false,
  label: '',
  placeholder: '',
};

export default MultiSelectControl;

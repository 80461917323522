import React from 'react';
import classnames from 'classnames';
import styles from '../../PackageForm.module.scss';
import FormField from '../../FormField';
import {
  getEndDatePickerPresets,
  getStartDatePickerPresets,
  isNotTuesday,
  isNotWednesday,
} from '../../../../helpers/dateTime';

const BasicInfo = ({
  campaignColumns,
  shouldShowDateTimePicker,
  shouldShowStatus,
  isExclusiveInStorePackage,
}) => {
  const getPackageName = () => {
    return (
      <div className={styles.formControl}>
        <FormField {...campaignColumns.PACKAGE_NAME} />
      </div>
    );
  };

  const getDateTimePicker = () => {
    if (!shouldShowDateTimePicker) {
      return null;
    }

    return (
      <>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField
              {...campaignColumns.START_DATE}
              presets={getStartDatePickerPresets()}
              isDisabledDate={isExclusiveInStorePackage ? isNotWednesday : () => false}
            />
          </div>
          <div className={styles.formControl}>
            <FormField
              {...campaignColumns.END_DATE}
              presets={getEndDatePickerPresets()}
              isDisabledDate={isExclusiveInStorePackage ? isNotTuesday : () => false}
            />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.START_TIME} />
          </div>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.END_TIME} />
          </div>
        </div>
        <div className={styles.formGroupSection}>
          <div className={styles.formControl}>
            <FormField {...campaignColumns.TIMEZONE_ID} />
          </div>
        </div>
      </>
    );
  };

  const getStatus = () => {
    if (!shouldShowStatus) {
      return null;
    }

    return (
      <div className={styles.formControl}>
        <FormField {...campaignColumns.STATUS} />
      </div>
    );
  };

  const packageName = getPackageName();
  const dateTimePicker = getDateTimePicker();
  const status = getStatus();

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupTitle}>Basic info</div>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.PLACEMENT_SOURCE} />
            </div>
            {packageName}
          </div>
          <div className={styles.formGroupSection}>
            {status}
            <div className={styles.formControl}>
              <FormField {...campaignColumns.MARKETING_MANAGER} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.VERTICAL} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.VENDOR} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.BRAND} />
            </div>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.PROGRAM} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...campaignColumns.CATEGORY} />
            </div>
            <div className={classnames(styles.formControl, styles.formControlCheckbox)}>
              <FormField {...campaignColumns.IS_DEAL} />
            </div>
          </div>
          {dateTimePicker}
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...campaignColumns.MARKETING_MANAGER_NOTES} />
            </div>
          </div>
        </div>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <p className={styles.formGroupDescription}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;

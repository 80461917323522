import { onNotificationsSet } from '../../actionCreators';

export default (dispatch, getState, action, next) => {
  const { Notifications: { list } } = getState();

  const sortedNotifications = [...list].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });

  dispatch(onNotificationsSet({
    notifications: sortedNotifications,
    shouldOverride: true,
  }));

  return next(action);
};

import { RateCardMerchTypes } from '../../constants/rateCard';

const BundlesConfig = {
  '2023 Premium Prime Day Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day 2023 Hero ASIN Shoveler Inclusion',
      'Prime Day 2023 Dedicated Shoveler',
      'Themed Endcap Inclusion',
    ],
  },
  '2023 Prime Day Exclusive Shoveler': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day 2023 Dedicated Shoveler',
    ],
  },
  '2023 Prime Day Inclusion': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Prime Day 2023 Blended Shoveler Inclusion',
    ],
  },
  'Omnichannel Deal Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Basic Deal Shoveler',
      'Themed Endcap Inclusion',
    ],
  },
  'Omnichannel Event Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Themed Endcap Inclusion',
    ],
  },
  'Omnichannel Deal Spotlight': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Featured Deal Shoveler',
      'In-Store Endcap',
      'Circular Feature',
    ],
  },
  'Omnichannel Event': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Themed Endcap Inclusion',
      'Dedicated Event Shoveler',
    ],
  },
  'Omnichannel Event Plus': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Themed Endcap Inclusion',
      'Dedicated Event Enhanced Shoveler',
      'Circular Feature',
    ],
  },
  'Omnichannel Fresh Find': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Fresh Find Hero',
      'Under The Shelf Strip',
    ],
  },
  'Omnichannel Launch Package': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Storefront Side Tile Hero',
      'Circular Feature',
    ],
  },
  'Online Deal Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Basic Deal Shoveler',
    ],
  },
  'Online Deal Page Hero Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Deal Hero Feature',
    ],
  },
  'Online Fresh Find': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Fresh Find Hero',
    ],
  },
  'Online Animated Storefront Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Animated Storefront Hero Tile',
    ],
  },
  'Online Storefront Tile Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Hero Tile',
    ],
  },
  'Online Storefront Shoveler Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler Basic Feature',
    ],
  },
  'Online Storefront Shoveler Feature Plus': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Storefront Shoveler Feature Plus',
    ],
  },
  'Online Enhanced Storefront Shoveler Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Enhanced Storefront Shoveler Feature',
    ],
  },
  'Online Category Feature': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Category Page Hero',
    ],
  },
  'Online Category Shoveler Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Category Page Shoveler',
    ],
  },
  'Online Enhanced Category Shoveler Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Enhanced Category Page Shoveler',
    ],
  },
  'Online Checkout Feature': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Checkout Page Shoveler',
    ],
  },
  'Online Tier A Event Dedicated Inclusion': {
    className: RateCardMerchTypes.CONSIDERATION.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'Online Tier B Event Dedicated Inclusion': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'Online Tier C Event Dedicated Inclusion': {
    className: RateCardMerchTypes.AWARENESS.label,
    placements: [
      'Dedicated Event Page Shovler',
    ],
  },
  'In-Store Endcap': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Standard Dedicated Endcap',
    ],
  },
  'Premium Endcap (F3G only)': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Premium Endcap',
    ],
  },
  'Specialty Page Feature': {
    className: RateCardMerchTypes.CONVERSION.label,
    placements: [
      'Dedicated Specialty Page Feature',
    ],
  },
};

export default BundlesConfig;

export const TimeValues = Object.freeze({
  MIDNIGHT: { value: '00:00', label: '12:00 AM' },
  MINUTE_BEFORE_MIDNIGHT: { value: '23:59', label: '11:59 PM' },
  DEFAULT: { value: '09:00', label: '9:00 AM' },
  TRAFFIC_START: { value: '10:00', label: '10:00 AM' },
})

export const DaysOfWeek = Object.freeze({
  WEDNESDAY: 'Wednesday',
  TUESDAY: 'Tuesday',
})

export const EVERGREEN_YEAR = 2099;
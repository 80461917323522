import { connect } from 'react-redux';
import { getCurrentPackageActiveTab } from '../../../../../helpers/form';
import { CampaignColumns, Form, } from 'src/constants';
import BasicInfo from './BasicInfo';
import formValueSelector from 'redux-form/lib/formValueSelector';
import { isFreshIntOnsiteTrafficBusiness } from 'src/selectors/sitewide';

const selector = formValueSelector(Form.NAME);

export const mapStateToProps = (state) => {
  const startDate = selector(state, CampaignColumns.START_DATE.name);

  return {
    currentTab: getCurrentPackageActiveTab(state),
    startDate,
    isFreshIntTraffic: isFreshIntOnsiteTrafficBusiness(state)
  };
};

export default connect(mapStateToProps)(BasicInfo);

import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import {
  onRateCardCloseReportModal,
} from '../../actionCreators';
import RateCardReportModal from './RateCardReportModal';
import { RateCardColumns, RateCardReportType } from '../../constants/rateCard';
import {
  getTotalInvestment,
  getTotalMoneyUsed,
  getTotalMoneyAvailable,
} from '../../selectors/rateCardSelectors';
import { Form } from '../../constants';
import { getMoneyFormatter } from '../../helpers/money';

const selector = formValueSelector(Form.RATE_CARD_NAME);
const moneyFormatter = getMoneyFormatter();

export const mapStateToProps = (state) => {
  const {
    RateCard: {
      isReportModalOpen,
      reportType,
    },
  } = state;

  const vendorName = selector(state, RateCardColumns.VENDOR.name);
  const vertical = selector(state, RateCardColumns.VERTICAL.name);
  const limitedPlacementsList = selector(state, RateCardColumns.LIMITED_PLACEMENTS.name);
  const mainPlacementsList = selector(state, RateCardColumns.MAIN_PLACEMENTS.name);

  return {
    vendorName,
    vertical,
    totalInvestment: moneyFormatter.format(getTotalInvestment(state)),
    dollarsAvailable: moneyFormatter.format(getTotalMoneyAvailable(state)),
    dollarsUsed: moneyFormatter.format(getTotalMoneyUsed(state)),
    limitedPlacementsList: limitedPlacementsList || [],
    mainPlacementsList: mainPlacementsList || [],
    isInternalReportType: reportType === RateCardReportType.INTERNAL.name,
    isOpen: isReportModalOpen,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onModalClose() {
      dispatch(onRateCardCloseReportModal());
    },
    onPrint() {
      window.print();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardReportModal);

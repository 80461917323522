import { RouterHistoryTypes, Urls } from '../constants';

export const getDefaultCampaignModalBackUrl = (history) => {
  if (!history.location || !history.location.search) {
    return '';
  }

  return `${Urls.SHOW_ALL_CAMPAIGNS}/${history.location.search}`;
};

export const openCampaignModal = (history, pathname, returnUrl, search = null) => {
  const backUrl = returnUrl || getDefaultCampaignModalBackUrl(history);
  history.push({
    pathname,
    search,
    state: {
      type: RouterHistoryTypes.CAMPAIGN_MODAL_OPEN,
      backUrl,
    },
  });
};

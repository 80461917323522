import { CustomViewActions } from '../../constants';

export const initialState = {
  showModal: false,
  customColumnOrder: null,
  useCustomColumnOrder: false,
};

export default (state = initialState, action) => {
  if (!action || !action.type) {
    return state;
  }
  switch (action.type) {
    case CustomViewActions.SET_USE_VIEW: {
      return {
        ...state,
        useCustomColumnOrder: action.payload,
      };
    }
    case CustomViewActions.SET_COLUMN_ORDER: {
      return {
        ...state,
        customColumnOrder: action.payload,
      };
    }
    case CustomViewActions.SET_MODAL_VISIBLE: {
      return {
        ...state,
        showModal: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

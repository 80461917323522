import { RateCardMerchTypes, RateCardPlacementTypes } from '../../constants/rateCard';

const VSSMPlacements = [
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN_VSSM,
    placementName: 'VSSM Storefront Shoveler',
    dollars: 100000,
    isUff: 0,
    isAfs: 1,
    vendorMax: 999,
    numberPlacements: 1,
    uffPlacement: 0,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN_VSSM,
    placementName: 'VSSM Category Shoveler',
    dollars: 25000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 999,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
];

export default VSSMPlacements;

import {
  onCreateCsCampaignSetDiff,
} from '../../actionCreators';
import {
  CsDiffTypes,
} from '../../constants';

const getDiffChangeType = (oldValue, newValue) => {
  if (!oldValue && newValue) {
    return CsDiffTypes.ADD;
  }
  if (oldValue && !newValue) {
    return CsDiffTypes.REMOVE;
  }
  if (oldValue !== newValue) {
    return CsDiffTypes.CHANGE;
  }

  return CsDiffTypes.NO_CHANGE;
};

const getPropertiesExceptionsList = () => {
  return {};
};

const getDiff = (existing, updated) => {
  const existingKeys = existing && typeof existing === 'object'
    ? Object.keys(existing)
    : [];
  const itemsKeys = existingKeys.length
    ? Object.keys(existing)
    : Object.keys(updated);
  const propertiesExceptionsList = getPropertiesExceptionsList();

  const itemsDiff = {};

  if (!itemsKeys.length) {
    return {};
  }

  itemsKeys.forEach((itemName) => {
    const itemContainer = existingKeys.length && existing[itemName]
      ? existing[itemName]
      : updated[itemName];

    if (!itemContainer) {
      return;
    }

    if (!itemsDiff[itemName]) {
      itemsDiff[itemName] = {};
    }

    const propertiesKeys = Object.keys(itemContainer);
    if (!propertiesKeys.length) {
      return;
    }

    propertiesKeys.forEach((propertyName) => {
      if (propertiesExceptionsList[propertyName]) {
        return;
      }

      let oldValue = '';
      let newValue = '';
      let diffType;

      if (existingKeys.length) {
        oldValue = existing[itemName] && existing[itemName][propertyName] !== undefined
          ? existing[itemName][propertyName]
          : '';
        newValue = updated[itemName] && updated[itemName][propertyName] !== undefined
          ? updated[itemName][propertyName]
          : '';
        diffType = getDiffChangeType(oldValue, newValue);
      } else {
        newValue = updated[itemName][propertyName];
        diffType = CsDiffTypes.ADD;
      }

      itemsDiff[itemName][propertyName] = {
        oldValue,
        newValue,
        diffType,
      };
    });
  });

  return itemsDiff;
};

export default (store, next, action) => {
  const { dispatch } = store;
  const {
    existingCampaign,
    updatedCampaign,
  } = action.data;

  dispatch(onCreateCsCampaignSetDiff(getDiff(existingCampaign, updatedCampaign)));
};

import { RateCardMerchTypes, RateCardPlacementTypes } from '../../constants/rateCard';

const MainPlacements = [
  // Awareness
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Enhanced Storefront Shoveler Feature',
    dollars: 115000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Storefront Shoveler Feature',
    dollars: 100000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 26,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Category Feature',
    dollars: 30000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.AWARENESS.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Targeted Storefront Feature',
    dollars: 100000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 999,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  // Consideration
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Enhanced Category Shoveler Feature',
    dollars: 55000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Category Shoveler Feature',
    dollars: 25000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 25,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Specialty Page Feature',
    dollars: 25000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 10,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Tier A Event Dedicated Inclusion',
    dollars: 75000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Tier B Event Dedicated Inclusion',
    dollars: 50000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 5,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Tier C Event Dedicated Inclusion',
    dollars: 30000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 5,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  {
    className: RateCardMerchTypes.CONSIDERATION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'SNAP Page Feature',
    dollars: 25000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 999,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
  // Conversion
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Checkout Feature',
    dollars: 85000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 26,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Online Deal Feature',
    dollars: 50000,
    isUff: 1,
    isAfs: 0,
    vendorMax: 78,
    numberPlacements: 1,
    uffPlacement: 1,
    afsPlacement: 0,
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Prime Day Inclusion',
    dollars: 25000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 5,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
    description: 'Please note that this placement will not be available for incremental pitching',
  },
  {
    className: RateCardMerchTypes.CONVERSION.label,
    type: RateCardPlacementTypes.MAIN,
    placementName: 'Targeted Deals Feature',
    dollars: 70000,
    isUff: 1,
    isAfs: 1,
    vendorMax: 999,
    numberPlacements: 2,
    uffPlacement: 1,
    afsPlacement: 1,
  },
];

export default MainPlacements;

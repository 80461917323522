import { BulletinBoardActions } from '../../constants';
import deleteRequest from './delete';
import loadRequest from './load';
import addRequest from './add';
import editRequest from './edit';
export default (function (_a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return function (next) { return function (action) {
        var type = action.type;
        var payload = {
            state: getState(),
            dispatch: dispatch,
            action: action,
            next: next,
        };
        if (type === BulletinBoardActions.LOAD) {
            return loadRequest(payload);
        }
        if (type === BulletinBoardActions.DELETE) {
            return deleteRequest(payload);
        }
        if (type === BulletinBoardActions.ADD) {
            return addRequest(payload);
        }
        if (type === BulletinBoardActions.EDIT) {
            return editRequest(payload);
        }
        return next(action);
    }; };
});

import React from 'react';
import Heading from '@amzn/meridian/heading';
import PropTypes from 'prop-types';
import { slotUtilizationItemProp } from '../../../proptypes';
import {
  CampaignColumns,
} from '../../../constants';
import styles from './UtilizationSlots.module.scss';
import UtilizationTable from '../UtilizationTable';

const UtilizationSlots = (props) => {
  const {
    slotUtilization,
    showLoader,
  } = props;

  const getSlotsBlock = () => {
    const { desktopSlotUtilization, mobileSlotUtilization } = slotUtilization;

    const tables = [
      {
        tableName: CampaignColumns.DESKTOP_SLOT.display,
        slotType: CampaignColumns.DESKTOP_SLOT.name,
        utilization: desktopSlotUtilization,
      },
      {
        tableName: CampaignColumns.MOBILE_SLOT.display,
        slotType: CampaignColumns.MOBILE_SLOT.name,
        utilization: mobileSlotUtilization,
      },
    ];

    const tablesList = tables.map(({ tableName, slotType, utilization }) => {
      return (
        <div
          key={`${tableName}/${slotType}`}
          className={styles.slots}
        >
          <UtilizationTable
            tableName={tableName}
            slotType={slotType}
            slotUtilization={utilization}
          />
        </div>
      );
    });

    return (
      <div className={styles.utilizationContainer}>
        {tablesList}
      </div>
    );
  };

  const getContent = () => {
    if (showLoader) {
      return (
        <div className={styles.loadingContainer}>
          <Heading level={4}>Please wait, loading...</Heading>
        </div>
      );
    }

    if (!slotUtilization || !Object.keys(slotUtilization).length) {
      return null;
    }

    return getSlotsBlock();
  };

  return getContent();
};

UtilizationSlots.propTypes = {
  showLoader: PropTypes.bool.isRequired,
  slotUtilization: PropTypes.shape({
    desktopSlotUtilization: PropTypes.arrayOf(slotUtilizationItemProp),
    mobileSlotUtilization: PropTypes.arrayOf(slotUtilizationItemProp),
  }).isRequired,
};

export default UtilizationSlots;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { campaignInputProps } from '../../proptypes';

import styles from '../GridViewCell/GridViewCell.module.scss';

const GridViewCellHeader = ({ children, isSticky }) => {
  const classes = classNames({
    [styles.tableHeader]: true,
    [styles.sticky]: isSticky,
  });

  return (
    <th className={classes}>
      <div className={styles.cell}>
        {children}
      </div>
    </th>
  );
};

GridViewCellHeader.propTypes = {
  children: campaignInputProps.isRequired,
  isSticky: PropTypes.bool,
};

GridViewCellHeader.defaultProps = {
  isSticky: false,
};

export default GridViewCellHeader;

import { connect } from 'react-redux';

import { onRateCardSetAssetsPagination } from '../../actionCreators';
import { getRateCardAssetsList } from '../../selectors/rateCardSelectors';

import RateCardListPaginator from '../Paginator/Paginator';

export const mapStateToProps = (state) => {
  const { RateCard: { pageNumberAssets, assetsPerPage } } = state;
  return {
    paginationPage: pageNumberAssets,
    pages: Math.ceil(getRateCardAssetsList(state).length / assetsPerPage),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetPaginationPage(pageNumber) {
      dispatch(onRateCardSetAssetsPagination(pageNumber));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardListPaginator);

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { HashLink as Link } from 'react-router-hash-link';
import Checkbox from '@amzn/meridian/checkbox';
import { safeDateFromNow } from '../../helpers/dateTime';
import { getColumnDisplayNameByName } from '../../helpers/common';
import { notificationProps } from '../../proptypes';
import {
  MetricLocations,
  MetricTypes,
  NotificationsTypes,
  CampaignColumns,
} from '../../constants';
import siteWideStyle from '../../styles/sitewide.module.scss';
import styles from './Notification.module.scss';
import { submitError } from '../../metrics';

export const getNotificationString = (notification) => {
  const {
    notificationType,
    createdAt,
    name,
    fromUsername,
    column,
    oldValue,
    newValue,
  } = notification;

  const sanitizedItemName = name ? sanitizeHtml(name) : '[not available]';
  const sanitizedFromUsername = sanitizeHtml(fromUsername);
  const sanitizedColumn = sanitizeHtml(getColumnDisplayNameByName(column));
  const sanitizedOldValue = oldValue ? sanitizeHtml(oldValue) : '[empty]';
  const sanitizedNewValue = newValue ? sanitizeHtml(newValue) : '[empty]';
  const dateTime = safeDateFromNow(createdAt);

  let message = '';
  switch (notificationType) {
    case NotificationsTypes.CREATE_CAMPAIGN :
    case NotificationsTypes.CREATE_PACKAGE:
      message = (
        <>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, max-len */}
          <strong>{sanitizedItemName}</strong> was created by <strong>{sanitizedFromUsername}</strong> <span className={styles.dateTime}>{dateTime}</span>
        </>
      );
      break;
    case NotificationsTypes.UPDATE_CAMPAIGN:
    case NotificationsTypes.UPDATE_PACKAGE:
      message = (
        <>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, max-len */}
          <strong>{sanitizedItemName}</strong>&apos;s <strong>{sanitizedColumn}</strong> was changed from <i>{sanitizedOldValue}</i> to <i>{sanitizedNewValue}</i> by <strong>{sanitizedFromUsername}</strong> <span className={styles.dateTime}>{dateTime}</span>
        </>
      );
      break;
    case NotificationsTypes.DELETE_CAMPAIGN:
    case NotificationsTypes.DELETE_PACKAGE:
      message = (
        <>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, max-len */}
          <strong>{sanitizedItemName}</strong> was deleted by <strong>{sanitizedFromUsername}</strong> <span className={styles.dateTime}>{dateTime}</span>
        </>
      );
      break;
    case NotificationsTypes.MENTION:
      message = (
        <>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line, max-len */}
          <strong>{sanitizedFromUsername}</strong> mentioned you in <strong>{sanitizedItemName}</strong>&apos;s comments <span className={styles.dateTime}>{dateTime}</span>
        </>
      );
      break;
    default:
      return '';
  }

  return message;
};

export const getNotificationStyleByColumnName = (columnName) => {
  switch (columnName) {
    case CampaignColumns.STATUS.name:
      return styles.notificationStatusChange;
    case CampaignColumns.SYMPHONY_URL.name:
      return styles.notificationSymphonyUrlChange;
    case CampaignColumns.CBR_COMMENTS.name:
    case CampaignColumns.FUNCTIONAL_QA_NOTES.name:
    case CampaignColumns.STOREFRONT_REVIEW_NOTES.name:
      return styles.notificationReviewComments;
    default:
      return null;
  }
};

const Notification = (props) => {
  const history = useHistory();

  const {
    id,
    notificationType,
    isRead,
    isMarked,
    onOpen,
    onMark,
    column,
    createdAt,
    toUsername,
    packageLink,
    isDisabledCheckbox,
  } = props;

  const isMention = notificationType === NotificationsTypes.MENTION;

  const message = getNotificationString(props);

  if (!message) {
    const metricContext = {
      location: MetricLocations.NOTIFICATIONS_LIST_VIEW,
      type: MetricTypes.VIEW,
      data: {
        notification: props,
      },
      action: 'loadNotificationMessage',
    };
    submitError(`[notifications] 'notificationType' is not found for id = ${id}`, metricContext);

    return null;
  }

  const onClick = () => {
    onOpen(id);

    history.push();
  };

  const classes = {
    [styles.notification]: true,
    [styles.notificationMention]: isMention,
    [styles.notificationRead]: isRead,
  };

  const customNotificationStyle = getNotificationStyleByColumnName(column);
  if (customNotificationStyle) {
    classes[customNotificationStyle] = true;
  }

  const onMarkNotification = () => {
    onMark({
      id,
      toUsername,
      createdAt,
    });
  };

  const checkBoxControl = (
    <div className={styles.checkboxContainer}>
      <Checkbox
        checked={isMarked}
        onChange={onMarkNotification}
        disabled={isDisabledCheckbox}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      {checkBoxControl}
      <div className={classnames(classes)}>
        <Link
          smooth
          to={packageLink}
          className={classnames([siteWideStyle.link, styles.link])}
          onClick={onClick}
          data-testid="btn-mark-as-read"
        >
          {message}
        </Link>
      </div>
    </div>
  );
};

const funcProps = {
  onOpen: PropTypes.func.isRequired,
};

Notification.propTypes = {
  ...notificationProps,
  ...funcProps,
};

Notification.defaultProps = {
  column: '',
  oldValue: '',
  newValue: '',
};

export default memo(Notification);

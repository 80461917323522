import { useEffect, useLayoutEffect, useState } from 'react';
import { useThrottle } from 'react-use';
import { useMeasure } from '../../../hooks/utils/useMeasure';
import { getRefElement } from '../../../utils/getRefElement';

export function useColumnResizer(props) {
  const {
    tableWrapperRef,
    slotNameHeaderRef,
  } = props;
  const [slotColumnWidth, setSlotColumnWidth] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);
  const [rowWidth, setRowWidth] = useState(0);
  // Throttle re-rendering the calendar to improve performance
  const throttledRowWidth = useThrottle(rowWidth, 200);
  const tableWrapperBoundRect = useMeasure(tableWrapperRef);

  /**
   * Listen to 'width' of the table wrapper. If the width changes adjust the current width value.
   */
  useEffect(() => {
    if (tableWrapperBoundRect.width === 0) return;

    setTableWidth(tableWrapperBoundRect.width);
  }, [tableWrapperBoundRect.width]);

  useLayoutEffect(() => {
    const tableWrapper = getRefElement(tableWrapperRef);
    const slotNameHeader = getRefElement(slotNameHeaderRef);

    const newTableWidth = tableWrapper?.getBoundingClientRect().width ?? 0;
    const newSlotColumnWidth = slotNameHeader?.getBoundingClientRect().width ?? 0;
    const newRowWidth = newTableWidth - newSlotColumnWidth;

    setTableWidth(newTableWidth);
    setSlotColumnWidth(newSlotColumnWidth);
    setRowWidth(newRowWidth);
  }, [slotNameHeaderRef, tableWrapperRef]);

  return {
    tableWidth,
    slotColumnWidth,
    tableHeight: tableWrapperBoundRect.height,
    rowWidth: throttledRowWidth,
  };
}

import { connect } from 'react-redux';

import UserPanel from './UserPanel';
import { onSignOut } from '../../actionCreators';

export const mapStateToProps = (state) => {
  const {
    User: {
      currentUser: {
        username,
        realName,
      },
    },
    Notifications: {
      isExperienceEnabled,
    },
  } = state;

  return {
    username,
    realName,
    isNotificationsWidgetEnabled: isExperienceEnabled,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSignOut() { dispatch(onSignOut()); },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);

import {
  NOOP,
  LOAD_CAMPAIGNS,
  KEYPRESS,
  SET_CAMPAIGNS,
  ON_METADATA_RECEIVED,
  REST_API,
  PAGE_INIT,
  GRAPHQL_ACTION,
  UPLOAD_LOCAL_METADATA,
  SHOW_LOADING_SPINNER,
  HIDE_LOADING_SPINNER,
  TimerActions,
  NotificationsActions,
  CommentActions,
  DashboardActions,
  LoadingStatusActions,
  FormActions,
  RouterActions,
} from '../../constants';

const getExceptionsList = () => ({
  [NOOP]: true,
  [LOAD_CAMPAIGNS]: true,
  [KEYPRESS]: true,
  [LoadingStatusActions.SET_CAMPAIGNS_LOADING_STATUS]: true,
  [LoadingStatusActions.SET_PACKAGES_LOADING_STATUS]: true,
  [SET_CAMPAIGNS]: true,
  [ON_METADATA_RECEIVED]: true,
  [REST_API]: true,
  [PAGE_INIT]: true,
  [GRAPHQL_ACTION]: true,
  [UPLOAD_LOCAL_METADATA]: true,
  [SHOW_LOADING_SPINNER]: true,
  [HIDE_LOADING_SPINNER]: true,
  [TimerActions.START_TIMER]: true,
  [TimerActions.CANCEL_TIMER]: true,
  [TimerActions.COMPLETE_TIMER]: true,
  [NotificationsActions.INIT]: true,
  [NotificationsActions.LOAD]: true,
  [NotificationsActions.SET]: true,
  [NotificationsActions.SORT]: true,
  [NotificationsActions.SET_LOADING]: true,
  [NotificationsActions.START_POLLING]: true,
  [NotificationsActions.SET_NOTIFICATIONS_LOADED]: true,
  [NotificationsActions.SET_NEW_NOTIFICATIONS_COUNT]: true,
  [NotificationsActions.UPDATE_NEW_NOTIFICATIONS_COUNT]: true,
  [NotificationsActions.INIT_LAST_SEEN]: true,
  [NotificationsActions.CREATE_LAST_SEEN_AT]: true,
  [NotificationsActions.UPDATE_LAST_NOTIFICATION_RECEIVED_TIME]: true,
  [NotificationsActions.ENABLE_EXPERIENCE]: true,
  [NotificationsActions.STOP_POLLING]: true,
  [NotificationsActions.SET_UPDATING]: true,
  [CommentActions.LOAD]: true,
  [CommentActions.SORT]: true,
  [CommentActions.RESET]: true,
  [CommentActions.START_POLLING]: true,
  [CommentActions.STOP_POLLING]: true,
  [DashboardActions.INIT]: true,
  [RouterActions.LOCATION_CHANGE]: true,
  [FormActions.UPDATE_SYNC_ERRORS]: true,
  [FormActions.REGISTER_FIELD]: true,
  [FormActions.UNREGISTER_FIELD]: true,
  [FormActions.BLUR]: true,
  [FormActions.CHANGE]: true,
  [FormActions.ARRAY_PUSH]: true,
  [FormActions.ARRAY_REMOVE_ALL]: true,
});

export default getExceptionsList;

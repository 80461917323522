import { connect } from 'react-redux';
import { onRateCardSetPagination } from '../../actionCreators';
import { getFilteredRateCardList } from '../../selectors/rateCardSelectors';
import RateCardListPaginator from '../Paginator/Paginator';

export const mapStateToProps = (state) => {
  const { RateCard: { pageNumber, cardsPerPage } } = state;

  return {
    paginationPage: pageNumber,
    pageCount: Math.ceil(getFilteredRateCardList(state).length / cardsPerPage),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onSetPaginationPage(pageNumber) {
      dispatch(onRateCardSetPagination(pageNumber));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RateCardListPaginator);

import { hasItemChanged } from '../../helpers/common';
import extractAsins from '../../helpers/extractAsins';
import {
  ASIN_DELIMETER,
  ASINColumns,
  CampaignColumns,
} from '../../constants';
import {
  onActiveCellInputUpdate,
  onSendPackageEdit,
  onSendCampaignEdit,
  onEditCampaign,
  onEditPackage,
} from '../../actionCreators';
import { getPackage } from '../../selectors/package';
import {
  getCombinedStatusForPackage,
  getPackageCampaignSyncExcludedColumnNames,
  isPackage,
} from '../../helpers/package';
import {
  getActiveCell,
  getActiveCellValue,
} from '../../selectors/gridView';
import { isWebsiteOmnichannelViewMode } from '../../selectors/sitewide';

export default (dispatch, getState, action, next) => {
  const state = getState();

  const activeCell = getActiveCell(state);
  if (!activeCell) {
    return next(action);
  }

  const activeCellValue = getActiveCellValue(state);
  const {
    item,
    columnName,
  } = activeCell;

  const parseAsins = () => {
    if (!columnName || !ASINColumns.includes(columnName)) {
      return;
    }

    dispatch(onActiveCellInputUpdate(
      extractAsins(activeCellValue).join(ASIN_DELIMETER)
    ));
  };

  const syncPackageToCampaigns = (packageId) => {
    const currentPackage = getPackage(state, packageId);
    if (!currentPackage || !currentPackage.campaigns) {
      return;
    }

    const syncUpExcludedColumns = getPackageCampaignSyncExcludedColumnNames(isWebsiteOmnichannelViewMode(state));

    currentPackage.campaigns.forEach(campaign => {
      if (!campaign.hasOwnProperty(columnName)
        || syncUpExcludedColumns.includes(columnName)) {
        return;
      }
      dispatch(onEditCampaign({
        ...campaign,
        [columnName]: activeCellValue,
      }));
    });
  }

  if (!hasItemChanged(state)) {
    return next(action);
  }

  const syncCampaignToPackage = (id, packageId) => {
    const currentPackage = getPackage(state, packageId);
    if (!currentPackage || !currentPackage.campaigns) {
      return;
    }
    if (columnName === CampaignColumns.IS_DEAL.name) {
      if (activeCellValue) {
        const otherCampaigns = currentPackage.campaigns.filter(({ id: otherId }) => id !== otherId);
        if (otherCampaigns.every(({ isDeal }) => isDeal) && !currentPackage.isDeal) {
          dispatch(onEditPackage({
            ...currentPackage,
            isDeal: true,
          }, { skipCampaignsUpdate: true }));
        }
      } else {
        dispatch(onEditPackage({
          ...currentPackage,
          isDeal: false,
        }, { skipCampaignsUpdate: true }));
      }
    }
  }

  const updatePackageStatus = (packageId) => {
    if (columnName !== CampaignColumns.STATUS.name) {
      return;
    }
    const currentPackage = getPackage(getState(), packageId);
    if (!currentPackage || !currentPackage.campaigns) {
      return;
    }

    const packageStatus = getCombinedStatusForPackage(currentPackage);
    if (packageStatus && packageStatus !== currentPackage.status) {
      dispatch(onEditPackage({
        ...currentPackage,
        [CampaignColumns.STATUS.name]: packageStatus,
      }, { skipCampaignsUpdate: true }));
    }
  };

  next(action);

  parseAsins();

  const { id, packageId } = item;
  if (isPackage(item)) {
    dispatch(onSendPackageEdit(id));
    syncPackageToCampaigns(id);
  } else {
    dispatch(onSendCampaignEdit(id, packageId));
    syncCampaignToPackage(id, packageId);
    updatePackageStatus(packageId);
  }

  return null;
};

import {
  ON_METADATA_RECEIVED,
  ON_ADD_METADATA_ENTRY,
  ON_REMOVE_METADATA_ENTRY,
  ON_CLEAR_METADATA,
} from '../../constants';

import {
  processMetadata,
  addMetadataEntry,
  removeMetadataEntry,
} from './processMetadata';

export const initialState = {
  metadata: {
    columnOrder: [],
  },
  isMetadataLoaded: false,
  initialMetadataLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ON_METADATA_RECEIVED: {
      return processMetadata(state, action.data);
    }
    case ON_ADD_METADATA_ENTRY: {
      return addMetadataEntry(state, action.data);
    }
    case ON_REMOVE_METADATA_ENTRY: {
      return removeMetadataEntry(state, action.data);
    }
    case ON_CLEAR_METADATA: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import Editor from './editor';
import QuickCreateModal from './QuickCreateModal';

import styles from './MetadataEditor.module.scss';

const MetadataEditor = ({ fields }) => {
  const editor = fields ? (<Editor />) : (<div>Loading</div>);

  return (
    <div className={styles.editorWrapper}>
      <QuickCreateModal />
      {editor}
    </div>
  );
};

MetadataEditor.propTypes = {
  fields: PropTypes.objectOf(PropTypes.object),
};

MetadataEditor.defaultProps = {
  fields: null,
};

export default MetadataEditor;

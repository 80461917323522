import { connect } from 'react-redux';
import CommentsScreen from './CommentsScreen';

export const mapStateToProps = (state) => {
  const {
    Comments: { isAutoScrollingEnabled },
  } = state;

  return {
    isAutoScrollingEnabled,
  };
};

export default connect(mapStateToProps)(CommentsScreen);

import { onRateCardAdd } from '../../actionCreators';

export default (dispatch, getState, action) => {
  const {
    payload: {
      rateCard,
    },
  } = action;

  const newRateCard = {
    ...rateCard,
  };
  newRateCard.isAssetPublished = false;

  delete newRateCard.id;

  dispatch(onRateCardAdd(newRateCard));

  return null;
};

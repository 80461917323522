import React from 'react';
import MetadataEditor from '../../MetadataEditor';
import AccessControl from '../../AccessControl';
import WithHistoryTiming from '../../WithHistoryTiming';
import { PageNames } from '../../../constants';

const MetadataPage = ({ hasAccess }) => {
  return (
    <AccessControl hasAccess={hasAccess}>
      <MetadataEditor />
    </AccessControl>
  );
};

export default WithHistoryTiming(MetadataPage, { pageName: PageNames.METADATA });

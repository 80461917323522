const reader = new FileReader();

export default (file, callback) => {
  if (!file) {
    return;
  }
  const loadend = () => {
    reader.removeEventListener('loadend', loadend);
    if (typeof callback === 'function') {
      callback(reader.result);
    }
  };

  reader.addEventListener('loadend', loadend);
  reader.readAsText(file);
};

import { submitAppSyncError } from '../../metrics';
import * as queries from '../../graphql/queries';
import {
  GraphQLLabels, MetricLocations, MetricNames, MetricTypes,
} from '../../constants';
import { onBulletinBoardSet } from '../../actionCreators';
import { getSortedResults } from '../../helpers/bulletinBoard';
import onGraphQLAction from '../../actionCreators/graphqlAction';

const loadRequest = ({
  state, dispatch, action, next,
}) => {
  const { Sitewide: { selectedBusiness } } = state;

  const params = {
    business: selectedBusiness,
  };

  next(action);

  dispatch(onGraphQLAction({
    graphqlAction: queries.queryAnnouncement,
    data: params,
    label: GraphQLLabels.GET_ANNOUNCEMENT,
    onSuccessFunction({ data: { queryAnnouncement: { items } } }) {
      return dispatch(onBulletinBoardSet({ items: getSortedResults(items) }));
    },
    onFailureFunction(error) {
      const {
        User: {
          currentUser: {
            username,
          },
        },
      } = state;

      const metricContext = {
        location: MetricLocations.BULLETIN_BOARD_REQUEST_MIDDLEWARE,
        type: MetricTypes.APPSYNC,
        data: params,
        action: MetricNames.LIST_ANNOUNCEMENTS,
        username,
      };

      submitAppSyncError(error, metricContext);
    },
  }));
};

export default loadRequest;

import { connect } from 'react-redux';
import { defaultDispatchToProps, defaultStateToProps } from '../helpers';
import { onActiveCellInputUpdate } from '../../../actionCreators';
import JsonInput from '../../StyledInputs/JsonInput';

export const mapStateToProps = (state) => defaultStateToProps(state);

export const mapDispatchToProps = (dispatch) => {
  return {
    ...defaultDispatchToProps(dispatch),
    onInputChange(value) {
      dispatch(onActiveCellInputUpdate(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JsonInput);

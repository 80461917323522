import { connect } from 'react-redux';
import {
  onThirdPartyIFrameOpenComposerForCampaign,
} from '../../../../../actionCreators';
import Scheduling from './Scheduling';
import { getCurrentPackageActiveTab } from '../../../../../helpers/form';

export const mapStateToProps = (state, { assetEditCompatibleMode }) => {
  const {
    ThirdPartyIFrame: { composerEnabled },
  } = state;

  return {
    composerEnabled: composerEnabled && !assetEditCompatibleMode,
    currentTab: getCurrentPackageActiveTab(state),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onOpenComposer(id) {
      dispatch(onThirdPartyIFrameOpenComposerForCampaign(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduling);

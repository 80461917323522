import { connect } from 'react-redux';
import formValueSelector from 'redux-form/lib/formValueSelector';
import AutomatedFieldsSummary from './AutomatedFieldsSummary';
import { shouldShowTrafficDriversBlock } from '../../../../helpers/common';
import { Form } from '../../../../constants';

const selector = formValueSelector(Form.NAME);

export const mapStateToProps = (state) => {
  const {
    Sitewide: { selectedBusiness },
  } = state;

  return {
    showTrafficDrivers: shouldShowTrafficDriversBlock(selectedBusiness),
    campaigns: selector(state, 'campaigns') || [],
  };
};

export default connect(mapStateToProps)(AutomatedFieldsSummary);

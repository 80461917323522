import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@amzn/meridian/popover';
import Toggle from '@amzn/meridian/toggle';
import { UtilizationViewMode } from '../../../constants/utilization';
import {
  CampaignColumns,
  Language,
  UtilizationModeNames,
  UtilizationSovDisplayNames,
} from '../../../constants';
import styles from '../UtilizationForm/UtilizationForm.module.scss';
import Input from '../../StyledInputs/Input';
import SingleSelect from '../../StyledInputs/SingleSelect';
import MultiSelect from '../../StyledInputs/MultiSelect';
import { getDependencyAwareOptions } from '../../../helpers/getMetadataOptions';

const isTextInputControl = (columnName) => [
  CampaignColumns.PAGE_ID.name,
  CampaignColumns.MARKETING_MANAGER.name,
].includes(columnName);

const isMultiSelectControl = (columnName) => [
  CampaignColumns.STATUS.name,
  CampaignColumns.PLACEMENT.name,
].includes(columnName);

const getPlaceholderTextByColumnName = (columnName) => ({
  [CampaignColumns.PAGE_ID.name]: CampaignColumns.PAGE_ID.display,
  [CampaignColumns.MARKETING_MANAGER.name]: CampaignColumns.MARKETING_MANAGER.display,
}[columnName] || '');

const UtilizationFiltersPanel = (props) => {
  const {
    buttonRef,
    selectedFilters,
    filterOptions,
    isOpen,
    isAbsStatsValuesOnly,
    isAggregateByWeeksMode,
    isCalendarViewMode,
    showFmcSlots,
    shouldShowFmcSlotsSwitch,
    shouldShowViewModeSwitch,
    onFilterChange,
    onToggleMode,
    onToggleAbsSovMode,
    onToggleFmcSlots,
    onSwitchViewMode,
    onClose,
  } = props;

  if (!buttonRef) {
    return null;
  }

  const statsModeName = isAggregateByWeeksMode
    ? UtilizationModeNames.AGGREGATE_BY_WEEKS
    : UtilizationModeNames.AGGREGATE_BY_DAYS;

  const statsSovAbsMode = isAbsStatsValuesOnly
    ? UtilizationSovDisplayNames.ABS
    : UtilizationSovDisplayNames.ABS_SOV;

  const onViewModeChange = () => {
    const newViewMode = isCalendarViewMode
      ? UtilizationViewMode.TABLE.name
      : UtilizationViewMode.CALENDAR.name;

    onSwitchViewMode(newViewMode);
  };

  const onToggleFmcSlotsClick = () => onToggleFmcSlots(showFmcSlots);

  const getViewModeSwitchToggleControl = () => {
    if (!shouldShowViewModeSwitch) {
      return null;
    }

    return (
      <Toggle
        onChange={onViewModeChange}
        checked={isCalendarViewMode}
      >
        <span>
          {UtilizationViewMode.CALENDAR.label}
          {' '}
          view mode
        </span>
      </Toggle>
    );
  };

  const getToggleFilterControls = () => {
    const viewModeSwitchToggle = getViewModeSwitchToggleControl();

    const areStandardToggleControlsDisabled = shouldShowViewModeSwitch && isCalendarViewMode;
    const toggleControlList = [
      {
        onChange: onToggleMode,
        checked: isAggregateByWeeksMode,
        disabled: areStandardToggleControlsDisabled,
        name: statsModeName,
      },
      {
        onChange: onToggleAbsSovMode,
        checked: isAbsStatsValuesOnly,
        disabled: areStandardToggleControlsDisabled,
        name: statsSovAbsMode,
      },
    ];

    if (shouldShowFmcSlotsSwitch) {
      toggleControlList.push({
        onChange: onToggleFmcSlotsClick,
        checked: showFmcSlots,
        name: Language.UTILIZATION_TOGGLE_FMC_SLOTS,
      });
    }

    const itemList = toggleControlList.map((toggleControlProps) => {
      const { name } = toggleControlProps;
      return (
        <li
          key={name}
          className={styles.listItem}
        >
          <Toggle {...toggleControlProps}>
            <span>
              {name}
            </span>
          </Toggle>
        </li>
      );
    });

    if (viewModeSwitchToggle) {
      itemList.unshift((
        <li
          key="view-mode-toggle"
          className={styles.listItem}
        >
          {viewModeSwitchToggle}
        </li>
      ));
    }

    return (
      <ul className={styles.list}>
        {itemList}
      </ul>
    );
  };

  const getFilterControls = () => {
    const dynamicFilterList = [...filterOptions].map(([filterOptionKey, filterOptionValue]) => {
      if (isTextInputControl(filterOptionKey)) {
        return (
          <li
            key={filterOptionKey}
            className={styles.listItem}
          >
            <Input
              inputValue={selectedFilters ? selectedFilters[filterOptionKey] : ''}
              onInputChange={(val) => {
                onFilterChange(filterOptionKey, filterOptionValue, val);
              }}
              label={getPlaceholderTextByColumnName(filterOptionKey)}
              placeholder={getPlaceholderTextByColumnName(filterOptionKey)}
              size="xlarge"
            />
          </li>
        );
      }

      if (isMultiSelectControl(filterOptionKey)) {
        return (
          <li
            key={filterOptionKey}
            className={styles.listItem}
          >
            <MultiSelect
              options={getDependencyAwareOptions(filterOptionValue, selectedFilters)}
              label={filterOptionValue.display}
              placeholder={filterOptionValue.display}
              inputValue={selectedFilters ? selectedFilters[filterOptionKey] : ''}
              onInputChange={(val) => {
                onFilterChange(filterOptionKey, filterOptionValue, val);
              }}
              onBlur={() => {}}
              size="xlarge"
            />
          </li>
        );
      }

      return (
        <li
          key={filterOptionKey}
          className={styles.listItem}
        >
          <SingleSelect
            options={getDependencyAwareOptions(filterOptionValue, selectedFilters)}
            label={filterOptionValue.display}
            placeholder={filterOptionValue.display}
            inputValue={selectedFilters ? selectedFilters[filterOptionKey] : ''}
            onInputChange={(val) => {
              onFilterChange(filterOptionKey, filterOptionValue, val);
            }}
            size="xlarge"
          />
        </li>
      );
    });

    return (
      <ul className={styles.list}>
        {dynamicFilterList}
      </ul>
    );
  };

  const filterControls = getFilterControls();
  const toggleFilterControls = getToggleFilterControls();

  return (
    <Popover
      anchorNode={buttonRef.current}
      open={isOpen}
      onClose={onClose}
      position="bottom"
    >
      {filterControls}
      {toggleFilterControls}
    </Popover>
  );
};

UtilizationFiltersPanel.propTypes = {
  selectedFilters: PropTypes.objectOf(PropTypes.string).isRequired,
  filterOptions: PropTypes.instanceOf(Map).isRequired,
  onClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onToggleMode: PropTypes.func.isRequired,
  onToggleAbsSovMode: PropTypes.func.isRequired,
  onToggleFmcSlots: PropTypes.func.isRequired,
  onSwitchViewMode: PropTypes.func.isRequired,
  isAggregateByWeeksMode: PropTypes.bool.isRequired,
  isAbsStatsValuesOnly: PropTypes.bool.isRequired,
  isCalendarViewMode: PropTypes.bool,
  isOpen: PropTypes.bool,
  shouldShowViewModeSwitch: PropTypes.bool,
  showFmcSlots: PropTypes.bool,
  shouldShowFmcSlotsSwitch: PropTypes.bool,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

UtilizationFiltersPanel.defaultProps = {
  isCalendarViewMode: false,
  isOpen: false,
  showFmcSlots: false,
  shouldShowViewModeSwitch: false,
  shouldShowFmcSlotsSwitch: false,
  buttonRef: null,
};

export default UtilizationFiltersPanel;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import KeyboardListener from '../KeyboardListener';
import ExportModal from '../ExportModal';
import GridView from '../GridView';
import OverlayLoader from '../OverlayLoader';
import GridViewFilterControl from '../GridViewFilters/GridViewFilterControl';
import GridViewFilterModal from '../GridViewFilters/GridViewFilterModal';
import GridViewPaginator from '../GridViewPaginator';
import {
  campaignColumnProps,
} from '../../proptypes';
import styles from './GridViewPage.module.scss';
import { GridViewDisplayModes, Language } from '../../constants';

const GridViewPage = ({
  onKeyboardPress,
  onLocationChange,
  location,
  filterViewModalOpen,
  packages,
  columnOrder,
  hasActiveAnnouncements,
  isOmnichannelMode,
  isAppLoading,
  isReadOnlyMode,
}) => {
  useEffect(() => {
    onLocationChange(location.query);
  }, [location.query, onLocationChange]);

  return (
    <div className={classnames({
      [styles.page]: true,
      [styles.withAnnouncements]: hasActiveAnnouncements,
    })}
    >
      <OverlayLoader
        isLoading={isAppLoading}
        text={Language.LOADING_CAMPAIGNS}
        overlayStyleOverrides={{
          top: styles.spaceFromTop,
        }}
      />
      {filterViewModalOpen && <GridViewFilterModal />}
      <ExportModal />
      <KeyboardListener onKeyboardPress={onKeyboardPress}>
        <GridView
          packages={packages}
          columnOrder={columnOrder}
          isOmnichannelMode={isOmnichannelMode}
          displayMode={(
            isReadOnlyMode ? GridViewDisplayModes.PREVIEW_MODE : GridViewDisplayModes.EDIT_MODE
          )}
        />
      </KeyboardListener>
      <div className={styles.bottomActionBar}>
        <GridViewPaginator />
        <GridViewFilterControl />
      </div>
    </div>
  );
};

GridViewPage.propTypes = {
  onKeyboardPress: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  filterViewModalOpen: PropTypes.bool,
  columnOrder: campaignColumnProps.isRequired,
  hasActiveAnnouncements: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      filters: PropTypes.string,
      sorts: PropTypes.string,
    }),
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
  isOmnichannelMode: PropTypes.bool,
};
GridViewPage.defaultTypes = {
  filterViewModalOpen: false,
  isOmnichannelMode: false,
};

export default GridViewPage;

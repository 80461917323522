import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '@amzn/meridian/select';
import Checkbox from '@amzn/meridian/checkbox';
import { NotificationsPanelList } from '../../../constants';
import { notificationProps } from '../../../proptypes';

const NotificationsListPanel = (props) => {
  const {
    paginationPage,
    notificationsList,
    onMarkAll,
    onCommitChanges,
    markedNotificationsCount,
    isUpdating,
  } = props;

  const [checkedState, setCheckedState] = useState({
    [paginationPage]: false,
  });

  const markAllCheckboxes = (overrideValue) => {
    setCheckedState({
      ...checkedState,
      [paginationPage]: !checkedState[paginationPage],
    });
    onMarkAll(notificationsList, overrideValue);
  };

  const onCommitAllChanges = (value) => {
    onCommitChanges(value);
    setCheckedState({});
  };

  const labelName = checkedState[paginationPage] ? 'Uncheck all' : 'Check all';

  const getOptionsList = () => {
    const optionsList = [
      {
        name: NotificationsPanelList.MARK_AS_READ.name,
        label: NotificationsPanelList.MARK_AS_READ.label,
      },
      {
        name: NotificationsPanelList.MARK_AS_UNREAD.name,
        label: NotificationsPanelList.MARK_AS_UNREAD.label,
      },
    ];

    return optionsList.map(({ name, label }) => {
      const labelString = `Mark (${markedNotificationsCount}) notifications as '${name}'`;

      return (
        <SelectOption
          key={`${name}/${label}`}
          value={label}
          label={labelString}
        />
      );
    });
  };

  const options = getOptionsList();

  return (
    <>
      <Checkbox
        checked={checkedState[paginationPage]}
        onChange={markAllCheckboxes}
        type="link"
        disabled={isUpdating}
      >
        {labelName}
      </Checkbox>
      <Select
        value=""
        onChange={onCommitAllChanges}
        placeholder={`Mark (${markedNotificationsCount}) notifications as...`}
        width={300}
        size="small"
        disabled={isUpdating}
      >
        {options}
      </Select>
    </>
  );
};

NotificationsListPanel.propTypes = {
  notificationsList: PropTypes.arrayOf(PropTypes.shape(notificationProps)).isRequired,
  paginationPage: PropTypes.number.isRequired,
  onMarkAll: PropTypes.func.isRequired,
  onCommitChanges: PropTypes.func.isRequired,
  markedNotificationsCount: PropTypes.number.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

export default NotificationsListPanel;

/* eslint-disable */
/* Mock data. It will be removed soon. */
import { CampaignColumns } from '../constants';

export default {
  "fields": {
    "brand": {
      "dependsOn": [
        "vendor"
      ],
      "options": {
        "3M": [
          "Nexcare"
        ],
        "4505 Meats Inc": [
          "4505 Meats"
        ],
        "AB World Foods": [
          "AB World Foods",
          "Blue Dragon",
          "Patak's"
        ],
        "Abbott": [
          "Ensure",
          "Glucerna",
          "Pedialyte",
          "Similac",
          "Zone Perfect"
        ],
        "ACURE": [
          "ACURE"
        ],
        "AFPB": [
          "365 Organic",
          "Amazon Kitchen",
          "Aplenty",
          "Fresh Brand",
          "Happy Belly",
          "Our Brands"
        ],
        "Alcohol": [
          "Alcohol",
          "Cursive Wine",
          "New Years ",
          "Pre-Christmas ",
          "Thanksgiving",
          "Winter Feature"
        ],
        "Aloevine": [
          "Aloevine"
        ],
        "Aloha": [
          "Aloha"
        ],
        "Amazon Meal Kits": [
          "Amazon Meal Kits"
        ],
        "AmazonBasics": [
          "AmazonBasics"
        ],
        "American Pop Corn Company": [
          "JOLLY TIME, Enlightened"
        ],
        "Applegate": [
          "Applegate deli portfolio",
          "Applegate grilling"
        ],
        "Aquastar": [
          "Aquastar"
        ],
        "Arctic Zero": [
          "ARCTIC ZERO"
        ],
        "Aryzta": [
          "La Brea"
        ],
        "Asparagus": [
          "Veggie"
        ],
        "Atkins": [
          "Atkins Ranch"
        ],
        "B&G Foods, Inc.": [
          "Back to Nature",
          "Green Giant",
          "Spice Islands"
        ],
        "Barilla": [
          "Barilla"
        ],
        "Barney": [
          "Barney"
        ],
        "Bayer": [
          "Aleve/Aspirin/Bayer",
          "Alka-Seltzer + ",
          "Alka-Seltzer + / Afrin",
          "Claritin"
        ],
        "Beauty": [
          "full assortment"
        ],
        "Beauty Personal Care": [
          "full assortment"
        ],
        "BeautyHouse": [
          "Beauty - Almay brand",
          "Beauty - Covergirl",
          "Beauty - Eye products",
          "Beauty - Face products",
          "Beauty - Lip products",
          "Beauty - Loreal, Maybelline, Garnier",
          "Beauty - Revlon brand",
          "Beauty - all products"
        ],
        "Bel Brands": [
          "Boursin"
        ],
        "Bellisio Foods": [
          "Boston Market, Michelina's, Authentic Asia, EatingWell, Atkins",
          "EatingWell, Atkins",
          "MSF - Burgers"
        ],
        "Bimbo": [
          "Ballpark",
          "Bimbo",
          "Entenmann's",
          "Little Bites",
          "Packaged Bread",
          "Sara Lee - Artesano",
          "Thomas"
        ],
        "Blue Buffalo": [
          "Blue Buffalo"
        ],
        "Blue Diamond": [
          "Blue Diamond"
        ],
        "Bowery Farming": [
          "Bowery Farming"
        ],
        "Bumble Bee": [
          "Bumble Bee"
        ],
        "Bush Brothers & Co": [
          "Bush's Best"
        ],
        "Butterball": [
          "Butterball"
        ],
        "Califia": [
          "Califia"
        ],
        "Campbell's": [
          "Late July, Kettle, Cape Cod",
          "Pepperidge Farm Cookies, Snyder's of Hanover, Snack Factory",
          "Pepperidge Farm Goldfish, Lance, Snyder's of Hanover"
        ],
        "Cellucor": [
          "Cellucor"
        ],
        "CF Sauer": [
          "Duke's Mayo"
        ],
        "Challenge": [
          "Challenge"
        ],
        "Chloe's Pops": [
          "Chloe's Pops"
        ],
        "Chobani": [
          "Chobani"
        ],
        "Church & Dwight": [
          "Arm & Hammer",
          "Household",
          "Laundry",
          "Vitamins"
        ],
        "Clorox": [
          "Brita",
          "Burt's Bees",
          "Clorox",
          "Fresh Step",
          "Glad",
          "Hidden Valley",
          "Hidden Valley Ranch, Soy Vay",
          "Kingsford",
          "Natural Vitality, Neocell, Rainbow Light"
        ],
        "Coca-Cola": [
          "Aha",
          "Barqs",
          "Coca-Cola",
          "Coffee & Tea",
          "Coke Energy",
          "Coke Zero",
          "Coke de Mexico",
          "Coke with Coffee",
          "Core Power",
          "Dasani",
          "Diet Coke",
          "Dunkin",
          "Fairlife",
          "Fanta",
          "Fresca",
          "Gold Peak",
          "Gold Peak, Honest Tea",
          "Honest Kids",
          "Honest Tea",
          "Minute Maid",
          "Monster",
          "POWERADE",
          "Powerade",
          "Seagrams",
          "Simply  ",
          "Sprite",
          "Suja",
          "Topo Chico",
          "Yup!",
          "fairlife",
          "smartwater",
          "vitaminwater",
          "vitaminwater & smartwater"
        ],
        "Colavita": [
          "Colavita"
        ],
        "Colgate": [
          "Colgate"
        ],
        "Conagra": [
          "Angie's, Orville, ACT II",
          "Armour, Libby's, Rosarita, Ranch Style Beans",
          "Banquet",
          "Banquet, Evol, Gardein, Udi's",
          "Birds Eye & Alexia",
          "Birds Eye - Veggies",
          "Birds Eye, Alexia, Healthy Choice, Banquet",
          "Chef Boyardee & Manwich",
          "Condiments: Hunt's Ketchup, Gulden's, Vlasic, Wishbone",
          "Duke's, Slim Jim, David's, Big's",
          "Duncan Hines, Comstock, Wilderness, PAM",
          "Evol",
          "Frontera, La Choy",
          "Gardein",
          "Gardein & Birds Eye",
          "Gulden's, Vlasic, Wishbone, Frontera",
          "Healthy Choice",
          "Healthy Choice & Alexia",
          "Healthy Choice & Evol",
          "Healthy Choice & Evol & Birds Eye",
          "Healthy Choice - Power Dressing",
          "Hebrew National",
          "Hebrew National, Hunt's, Gulden's, Vlasic, Gardein, PAM, Udi's",
          "Hunt's Tomatoes",
          "Marie Callender's",
          "Orville, Angie's, Slim Jim, Duke's",
          "PAM + Condiments",
          "PAM, Earth Balance, Smart Balance",
          "Reddi Wip, Marie Callender's, Duncan Hines",
          "Slim Jim, Duke's",
          "Udi's, Glutino",
          "Ultimate Burger",
          "Van De Kamp's & Mrs. Paul's"
        ],
        "Continental Mills": [
          "Kretchmer",
          "Krusteaz",
          "Krusteaz, Ghirardelli, Red Lobster",
          "Red Lobster"
        ],
        "Coolhaus": [
          "Coolhaus"
        ],
        "CPB": [
          "CPB",
          "House",
          "NF Private Label"
        ],
        "Crayola": [
          "Crayola"
        ],
        "Crofter's Organic": [
          "Crofter's Organic",
          "Food Broker"
        ],
        "Cursive Wine": [
          "Cursive Wine"
        ],
        "Daily's": [
        ],
        "Danone": [
          "Activia, LNF, Oikos",
          "Dannon",
          "Growing Years, Horizon Organic",
          "Horizon Organic",
          "International Delight",
          "Silk, Good Plants, So Delicious",
          "Silk, SO Delicious",
          "Silk/International",
          "Two Good, Oikos, Acitivia, LNF",
          "Two Good, Oikos, Activia, Light & Fit",
          "Vega"
        ],
        "Darigold": [
          "Darigold"
        ],
        "Dean": [
        ],
        "Del Monte Foods, Inc": [
          "College Inn Broths & Stocks",
          "Contadina Tomatoes",
          "Del Monte Canned Vegetables & Fruits",
          "Del Monte Fruit Cup® Snacks",
          "Del Monte Veggieful Snacks & Meals"
        ],
        "Dietz & Watson": [
          "Dietz & Watson",
          "Hot Dogs - Sausages",
          "Packaged Deli",
          "Sliced Deli + Cheese",
          "Snacks"
        ],
        "dogtoys": [
          "Dog Toys"
        ],
        "Dole": [
          "Dole",
          "Dole Canned Fruit"
        ],
        "Dr. Praeger's ": [
          "Dr. Praeger's ",
          "KashiGo"
        ],
        "Dukes": [
          "Food Broker"
        ],
        "Duracell": [
          "Duracell"
        ],
        "Edgewell": [
          "Edgewell"
        ],
        "Ellenos": [
          "Ellenos"
        ],
        "Energizer": [
          "Energizer"
        ],
        "Enlightened": [
          "Enlightened"
        ],
        "EOS": [
          "EOS"
        ],
        "Event": [
          "Event"
        ],
        "Exercise/fitness": [
          "Exercise/fitness"
        ],
        "Fage": [
          "Fage"
        ],
        "Ferrara": [
          "Black Forest, Sweetarts, Murray's and Mother's",
          "Black Forest, Sweetarts, Murray's, Mother's, Spree & Gobstopper",
          "Keebler, Famous Amos, Mother's and Murray",
          "Keebler, Fruity Snacks, Mother's, Famous Amos, Stretch Island, Black Forest, Trolli, Laffy Taffy, Sweetarts",
          "Keebler, Stretch Island, Mothers and Famous Amos",
          "Keebler, Trolli, Laffy Taffy, Black Forest",
          "Laffy Taffy, Trolli, Black Forest, Sweetarts, Keebler ",
          "Trolli, Black Forest, Keebler, Stretch Island and Famous Amos"
        ],
        "Flowers": [
          "Canyon Bakehouse",
          "DKB",
          "Tastykake"
        ],
        "Franz": [
          "Franz"
        ],
        "Freshpet": [
          "FreshPet"
        ],
        "Frontier Co-op": [
          "Food Broker",
          "Simply Organic"
        ],
        "Frozen": [
          "Frozen"
        ],
        "Fruit": [
          "Fruit"
        ],
        "Fyffes": [
          "Fyffes"
        ],
        "GBD": [
          "Cuisine Solutions ",
          "Kevin's"
        ],
        "General Mills": [
          "Big G, ",
          "Big G, Yoplait, Pillsbury",
          "Nature Valley, Larabar",
          "Old El Paso",
          "Progresso",
          "Progresso, Annies, Betty Crocker",
          "Totinos, Cascadian Farms",
          "Yoplait",
          "Yoplait, Pillsbury"
        ],
        "Gerber": [
          "Gerber"
        ],
        "GFI": [
          "GFI"
        ],
        "Ghirardelli": [
          "Ghirardelli"
        ],
        "Gifting": [
          "Gifting Vday"
        ],
        "Glanbia": [
          "Optimum Nutrition"
        ],
        "GP": [
          "Angel Soft",
          "Aria",
          "Brawny",
          "Brawny / Dixie",
          "Cleaning",
          "Dixie",
          "Dixie Craftimals",
          "Dixie/Vanity Fair/PT",
          "Paper / Tabletop",
          "Paper / Tabletop / TP",
          "Plates, Napkins, PT",
          "QN",
          "Sparkle",
          "TP",
          "Value",
          "Vanity Fair"
        ],
        "GSK": [
          "Digestive Health",
          "Excedrin",
          "Superbowl digestive health ",
          "Theraflu breathe right flonase"
        ],
        "GSKPfizer": [
          "Advil, Excedrin, Voltaren ",
          "Benefiber, Citrucel, Ex-Lax, Gas-X, Nexium, Prep-H, Tums ",
          "Chapstick",
          "Chapstick, Abreva",
          "Emergen-C, Centrum, Caltrate",
          "Flonase",
          "Nicorette",
          "Sensodyne, Biotene, Aquafresh, Parodontax",
          "Theraflu, Robitussin, Advil Resp"
        ],
        "Hallmark": [
          "Hallmark"
        ],
        "Hanover Foods Corporation": [
          "Castleberry",
          "Hanover (HAR0S)",
          "Hanover (HAR0S), Castleberry"
        ],
        "Happy Family": [
          "Happy Baby"
        ],
        "Health-Ade": [
          "Health-Ade"
        ],
        "Heartland FPG": [
          "Splenda"
        ],
        "Henkel": [
          "Dial",
          "Henkel",
          "Henkel Beauty",
          "Henkel LHC"
        ],
        "Hersheys": [
          "Amplify",
          "Hersheys"
        ],
        "HMR": [
          "Amazon Kitchen"
        ],
        "Home": [
          "House"
        ],
        "Honest Company": [
          "All Honest",
          "Honest Baby",
          "Honest Beauty"
        ],
        "Hood": [
          "Almond Breeze",
          "Heluva Good!",
          "Hood Cottage Cheese",
          "Lactaid",
          "Planet Oat"
        ],
        "Hormel": [
          "Applegate",
          "Black Label Bacon",
          "Columbus",
          "Columbus ",
          "Compleats",
          "Dinty Moore",
          "Herdez",
          "Herdez - Wholly",
          "Hormel Chili",
          "Hormel Cuts and Pairings",
          "Hormel Gatherings",
          "Hormel Gatherings - Salsa",
          "Hormel Pepperoni",
          "Mary Kitchen Hash",
          "Natural Choice",
          "Planters",
          "SKIPPY",
          "SPAM",
          "Skippy",
          "Wholly Guacamole",
          "Wholly/Herdez"
        ],
        "House": [
          "365 Everyday",
          "Alcohol",
          "Allergy",
          "Aplenty",
          "Apppetizers for March Madness",
          "Baby Sale",
          "Baby Sale deal messaging)",
          "Baked Goods -- All Brands",
          "Bakery",
          "Bigelow, Tetley, Good Earth",
          "Birds Eye, Cascadian Farm, Pictsweet",
          "Bush's Best, Musco Olives, Red Gold",
          "Cascadian Farm, Wyman's",
          "Chocolate single-serve main",
          "Chocolate snacks",
          "Coffee & Tea",
          "Colavita, Pompeian, Mizkan, Kikkoman",
          "Colavita, Pompeian, Nutiva, Mizkan",
          "Cold and Flu",
          "Continental Mills, Ghiradelli",
          "Coolhaus, Enlightened, Wells Enterprises, Yasso, ",
          "Cursive Wine",
          "Del Monte",
          "Deli Prepared",
          "Digestive Health",
          "Dinner mains from Easter event",
          "Disinfect and Protect",
          "Dole",
          "Duke's, Barney Butter, Crofter's",
          "Earth month storefront hero",
          "Easter",
          "Easter Candy",
          "Easy snacking",
          "Eat In",
          "Eggnog",
          "Eggo, Toaster Strudel, El Monterey",
          "Enlightened, Halo Top, Arctic Zero",
          "Father's Day",
          "Feminine Care",
          "First Aid",
          "Food Broker",
          "Foot Care (Pedicures, etc.)",
          "Ghirardelli, Perfetti, Hi Chew",
          "GoGoSqueez",
          "Grillable meat asins",
          "Grilling Season",
          "Gummy candy",
          "Halloween Candy",
          "Hersheys, Mars Wrigely",
          "Hiking snacks",
          "Holiday Candy",
          "Holiday Confections",
          "House",
          "House - Bakery",
          "House-Taco shoveler",
          "House-Wings ",
          "Household",
          "Ice Breakers, eclipse, Mentos, Orbit, Trident, Extra",
          "Ice breakers, breath savers, 365, tic tac, Altoids, Newman's Own",
          "Idahoan, Southeastern Mills",
          "Illy, Good Earth, Apple & Eve, Dr. Praeger's, Sunshine",
          "Keto-friendly treats",
          "Kikkoman, Southeastern Mills, Simply Organic, Mizkan",
          "La Colombe",
          "Lavazza, Illy",
          "Lavazza, Kicking Horse, Illy, Eight O'Clock Coffee, ",
          "Low ASP",
          "M&M, Dove, Snickers, Twix, 3M, Milky Way, Mars Variety, KIND",
          "March Madness",
          "Meal Ideas",
          "Memorial Day",
          "Mezzetta, Mt. Olive",
          "Mother's Day",
          "Multi-pack",
          "NUTRO Dry Dog Food",
          "National Candy Month",
          "National Wine Day",
          "Nongshim",
          "Nutwell",
          "Oberto, Peeled Snacks, Newman’s Own, Kind",
          "Ocho, Toblerone, Theo, Scharffen Berger, Lindt, Ghiradelli, Alter Eco",
          "PL Launch",
          "Palermo's, Bellisio, Dr. Praeger's, J&J Snack Foods, White Castle, Ajinomoto",
          "Pantry Staples",
          "Picnic snacks",
          "Pictsweet, Hanover, B&G",
          "Pride Week",
          "Produce",
          "Recipes",
          "Riviana",
          "Riviana, Mizkan, Newman's Own, Red Gold",
          "Salty",
          "Save 30% on oils - Circular F3G - House",
          "Save 40% on Frozen Treats - Circular F3G - House",
          "Seasonal Shoveler",
          "Shoveler",
          "Simply Organic, Morton Salt",
          "Snacks",
          "Sour candy",
          "Southeastern Mills",
          "Sports Nutrition",
          "StarKist, Nongshim, Palermo's, White Castle, Dr. Praeger's, Bellissio",
          "Starburst",
          "Strategic Category Initiative",
          "Summer Grilling",
          "Sunscreen",
          "Sweet",
          "Tacos",
          "Targeted campaign - Paper",
          "Toys",
          "VDAY Candy",
          "Vitamins",
          "Wells Enterprises, Chloe's, JonnyPops, J&J Snack Foods",
          "Wells Enterprises, Coollhaus, The Cheesecake Factory",
          "Wells Enterprises, Yasso, Enlightened",
          "Wyman's of Maine",
          "oral care"
        ],
        "Icelandic Provisions": [
        ],
        "Idahoan": [
          "Idaohan"
        ],
        "illy Coffee": [
          "Save 30% on coffee - Circular F3G - ILMU0",
          "illy Coffee"
        ],
        "Infirst": [
          "Mylanta & Mylicon "
        ],
        "J&J Snack Foods": [
          "LUIGI'S Real Italian Ice",
          "SUPERPRETZEL and Auntie Anne's"
        ],
        "Jasper Wyman & Son": [
        ],
        "Jennie O": [
        ],
        "JFC": [
          "JFC"
        ],
        "Johnsonville": [
        ],
        "JonnyPops": [
          "JonnyPops"
        ],
        "Kayem": [
          "Kayem"
        ],
        "KC": [
          "Goodnites",
          "Goodnites, Pull-ups",
          "Huggies",
          "Kleenex",
          "La Vie",
          "Scott, Cottonelle, Kleenex"
        ],
        "KDP": [
          "FORTO"
        ],
        "Kellogg's": [
          "Caddy Packs - Summer Snacking",
          "Cereal Snacking",
          "Cheez-it , Pringles, Townhouse",
          "Cheez-it, Pringles, Townhouse",
          "Coffee-Mate",
          "DiGiorno",
          "Eggo - ",
          "Froot Loops, Frosted Flakes, Apple Jacks, Corn Pops",
          "Frosted Mini Wheats, Frosted Flakes",
          "Kashi",
          "Kashi, Bear Naked",
          "KashiGo",
          "MSF - Burgers",
          "MSF - Incogmeato",
          "MVP Pack",
          "Morningstar Farms",
          "Paw Patrol",
          "Special K",
          "Starbucks Creamer",
          "Toll House RCD"
        ],
        "Kerrygold": [
          "Kerrygold"
        ],
        "Kicking Horse Coffee": [
        ],
        "Kikkoman": [
          "Food Broker",
          "Kikkoman"
        ],
        "Kitchen": [
        ],
        "Koia": [
          "Koia"
        ],
        "Kraft Heinz": [
          "Arizona Iced-Tea",
          "BTS Drinks",
          "Bacon",
          "Boca Grilling",
          "Breakfast",
          "Charcuterie (Cracker Barrel, Bold Butcher, Claussen, Grey poupon)",
          "Cheese & Bacon",
          "Cheese Snacking",
          "Christmas",
          "Christmas brunch",
          "Cinco de Mayo",
          "Cold-cuts",
          "Condiments",
          "Cracker barrel Innovation",
          "Creative Roots",
          "Deli Meats",
          "Desserts of Future",
          "Easter",
          "Easter Brunch",
          "Easter Desserts",
          "Everything but the turkey",
          "Frozen Meals",
          "Frozen Pizzas",
          "Frozen Sides Innovation",
          "Frozen Snacks",
          "Frozen Snacks for tailgating",
          "Gevalia",
          "Heinz Memorial Day",
          "Holiday Apps",
          "Holiday Baking",
          "Holiday Charcuterie",
          "Holiday Meals",
          "Holiday Traffic Driver with Media",
          "Jell-O",
          "Jet Puffed Snacking",
          "Keto-friendly",
          "KoolAid",
          "Kraft Dressing",
          "Kraft Shredded Cheeses",
          "Loaded Baked Potatoes",
          "Lunchables",
          "Mac & Cheese",
          "Mac and Cheese",
          "Meals Scale",
          "Mio",
          "Mother's Day Brunch",
          "Non Core Thanksgiving (Ambient)",
          "Non Core Thanksgiving (Refrigerated)",
          "Ore-ida and Mashups",
          "Oscar Mayer",
          "Pasta Night",
          "Pasta Sauce & Gravys",
          "Patriotic Desserts",
          "Philadelphia",
          "Planter's",
          "Planters",
          "Planters Flip-tops",
          "Protein Snacking",
          "Rice Krispy Cross Merch",
          "Smores cross partnership",
          "Snacking Cheese",
          "Sure Jell"
        ],
        "KraftHeinz/Conagra": [
          "Velveeta/Rotel"
        ],
        "Lactalis": [
          "Lactalis",
          "Siggi's",
          "Stonyfield"
        ],
        "Lamb Weston": [
          "Alexia"
        ],
        "Land O'Lakes": [
          "Land O'Lakes"
        ],
        "Lavazza": [
          "Kicking Horse Coffee",
          "Lavazza"
        ],
        "Litehouse": [
          "Litehouse"
        ],
        "Maple Hill": [
          "Maple Hill"
        ],
        "Mars Pets": [
          "Cesar",
          "Crave Dog",
          "Dentastix",
          "Greenies",
          "Greenies + Temptations",
          "IAMS",
          "IAMS, Cesar, Greenies",
          "Nutro",
          "Sheba",
          "Temptations"
        ],
        "Mars Wrigley": [
          "Dove, M&M, Mars Variety, Skittles, Starburst",
          "Extra, 5, Orbit, Life Savers, Altoids, Eclipse, Doublemint",
          "Extra, 5, Orbit, Life Savers, Altoids, Eclipse, Doublemint, Hubba Bubba, Juicy Fruit",
          "Hero Chocolate + VPC on Combos + KIND",
          "M&M, Dove - SUP and Bars",
          "M&M, Dove, Snickers, Twix, Life Savers",
          "M&M, Snickers, Starburst, Skittles, Life Savers - SUPs",
          "Mars Wrigley"
        ],
        "Materne": [
          "GoGo Squeez"
        ],
        "Mattel": [
          "Fisher Price"
        ],
        "McCormick": [
        ],
        "McKee": [
          "McKee"
        ],
        "Mead Johnson": [
          "Enfa"
        ],
        "Meow Mix": [
          "Meow Mix"
        ],
        "Mezzetta": [
          "Mezzetta"
        ],
        "Mondelez": [
          "\"Thins\" brands",
          "Easter candy (Oreo + SPK/SWF)",
          "Halls",
          "Olympic Brands (Oreo, Ritz, Belvita, Trident, Chips Ahoy, SPK)",
          "Oreo",
          "Ritz"
        ],
        "Morinaga": [
          "Hi Chew"
        ],
        "Morton Salt, Inc.": [
          "Morton "
        ],
        "MOWI": [
          "MOWI"
        ],
        "Mt Olive": [
          "Mt Olive"
        ],
        "Multiple": [
        ],
        "Musco Family Olive Co": [
          "Pearls",
          "Pearls/Early California"
        ],
        "NA": [
        ],
        "Nature's Bakery": [
          "Nature's Bakery"
        ],
        "Nature's Way": [
          "Nature's Way"
        ],
        "NCP": [
          "Blue Bottle",
          "Nescafe Clasico",
          "Starbucks"
        ],
        "Nestle Waters": [
          "Italian Sparkling",
          "NPL",
          "NPL+",
          "Perrier",
          "RSW Sparkling",
          "S.Pellegrino",
          "S.Pellegrino & Acqua Panna"
        ],
        "Newman's Own": [
          "Newman's Own"
        ],
        "NongShim": [
          "Food Broker"
        ],
        "Noosa": [
          "Noosa"
        ],
        "NUSA": [
          "Buitoni",
          "Chameleon",
          "Coffee-Mate",
          "DiGiorno",
          "Drumstick",
          "Drumstick, Skinny Cow, Haagen Dazs",
          "Haagen Dazs ",
          "Haagen Dazs,  Outshine, Dreyers, Skinny Cow, Drumstick",
          "Hot Pockets",
          "Lean Cuisine",
          "Lean Cuisine, Stouffers, Buitoni",
          "Nesquik",
          "Outshine",
          "Starbucks Creamer",
          "Stouffers",
          "Sweet Earth",
          "Toll House ",
          "Toll House RCD",
          "Tombstone &  Jacks",
          "Total Pizza"
        ],
        "Nutiva": [
          "Nutiva"
        ],
        "Nutrabolt": [
          "Cellucor"
        ],
        "Oberto": [
          "Oberto, Cattleman's Cut, Pacific Gold"
        ],
        "OIH": [
          "OIH",
          "UCO1",
          "UFL6",
          "UMA4",
          "UTX7",
          "UWA5"
        ],
        "OIH - GL 540": [
          "JFK2",
          "UCA4",
          "UIN1",
          "UMA4",
          "UOH5"
        ],
        "OIH - GL 541": [
          "JFK2",
          "UCA4",
          "UIN1",
          "UMA4",
          "UOH5"
        ],
        "OpalApple": [
        ],
        "Oppy": [
          "Envy",
          "Ocean Spray",
          "Zespri"
        ],
        "Orchard Valley": [
          "Fisher "
        ],
        "Organic Valley": [
          "Organic Valley"
        ],
        "organicgirl": [
          "organicgirl"
        ],
        "OXO": [
          "OXO + paleo"
        ],
        "Palermo's": [
          "Food Broker",
          "Screamin' Sicilian, Urban Pie"
        ],
        "PartyPlatter": [
        ],
        "Peeled Snacks": [
          "Food Broker",
          "Peeled Snacks"
        ],
        "PepsiCo": [
          "BFY Snacks",
          "BFY Snacks and Drinks",
          "Beverages",
          "Breakfast",
          "CSDs",
          "CSDs & Energy",
          "Chips",
          "Chips & Dips",
          "Cinco de Mayo Selection",
          "Coffee and tea",
          "Coffee, Juice, and more",
          "Crunchy Snacks",
          "Dips",
          "Doritos",
          "Gatorade",
          "Gatorade & Muscle Milk",
          "Gatorade & Propel",
          "Innovation",
          "Juices",
          "Lunchbox Snacks",
          "NYNY",
          "Oatmeal",
          "On the go",
          "Pancakes and cereal",
          "Quaker",
          "Snack cravings",
          "Snacks",
          "Snacks & Beverages",
          "Snacks & Sips",
          "Soda",
          "Stacy's & Sabra",
          "Starbucks",
          "Still and sparkling water",
          "Sugar-free beverages",
          "Sugar-free soft drinks",
          "Summer Snacks & Drinks",
          "Super Bowl",
          "Super Bowl Drinks & Snacks",
          "Super Bowl Innovations",
          "Unsweetened coffee and tea",
          "Zero-calorie performance drinks",
          "bubly"
        ],
        "Perfetti Van Melle": [
          "Airheads",
          "Mentos"
        ],
        "Personal care - house": [
        ],
        "Pets": [
          "Cat litter & training pads",
          "Dog Food - Fresh, Raw, Dehydrated",
          "Dog Treats & Chews",
          "Dog food - senior",
          "NUTRO Dry Dog Food",
          "Wet Cat Food"
        ],
        "Pfizer": [
          "Advil",
          "Centrum"
        ],
        "Philips": [
          "Philips"
        ],
        "Pictsweet Farms": [
          "Food Broker",
          "Pictsweet Farms"
        ],
        "Pilgrims": [
          "JBC",
          "Just BARE"
        ],
        "Pinnacle": [
        ],
        "PL/CL Alcohol": [
          "Cursive Wine",
          "El Terrano",
          "Globerati",
          "King Rabbit",
          "Liminal Space",
          "Summit Wine",
          "Sweet Lake Vodka"
        ],
        "Planters (Hormel)": [
          "Planters"
        ],
        "PlayOffMadness": [
        ],
        "Pompeian": [
          "Food Broker",
          "Pompeian"
        ],
        "Pre": [
          "Pre"
        ],
        "Premier Nutrition": [
          "Premier Protein"
        ],
        "Prestige": [
          "Prestige"
        ],
        "PRO": [
        ],
        "Proctor & Gamble": [
          "Aussie, head & shoulders, Pantene, herbal essences ",
          "Bounty, Charmin, Puffs",
          "Cascade",
          "Crest, Oral B",
          "Dawn",
          "Gain",
          "Gillette",
          "Olay, Old Spice, Secret",
          "Pampers",
          "Swiffer, Mr. Clean, febreze",
          "Tide",
          "Tide, Downy, Gain",
          "Vick's, ZzzQuil"
        ],
        "Produce": [
          "House",
          "Produce"
        ],
        "Rana": [
          "Rana"
        ],
        "Ranir": [
          "Plackers",
          "Rembrandt"
        ],
        "Rao's": [
          "Rao's"
        ],
        "RB Health": [
          "Airborne",
          "Allergy",
          "Baby Event",
          "Baby formula",
          "Clearasil",
          "Cold & flu",
          "Digestive Advantage",
          "Enfamil",
          "Mucinex",
          "Neuriva",
          "Sexual Health",
          "Sexual Wellness",
          "Upper Res",
          "Upper Resp (Full Selection)",
          "Upper Respiratory",
          "Veet Amope"
        ],
        "RB HyHo": [
          "Air Wick",
          "Botanical Gardens",
          "Finish",
          "Lysol",
          "Woolite"
        ],
        "RC Bigleow": [
          "Bigelow"
        ],
        "Ready Pac": [
          "Ready Pac"
        ],
        "Recipe": [
        ],
        "Red Bull": [
          "Red Bull"
        ],
        "Red Gold, Inc.": [
          "Tuttorosso, Red Gold, Sacramento"
        ],
        "Reesers": [
          "Reesers"
        ],
        "Reynolds": [
          "Hefty",
          "Reynolds"
        ],
        "Rich": [
          "40% off apps and sides",
          "All Frozen",
          "Bakery",
          "Bakery ",
          "Bakery & Ice Cream Cakes",
          "Buy 2, G1 Free",
          "Disney items (TBD)",
          "Farm Rich",
          "Farm Rich & SeaPak",
          "SeaPak",
          "Spend $15, Save $5 FR and SP"
        ],
        "Rich's Bakery": [
          "Rich's"
        ],
        "Riviana Foods": [
          "American Beauty, Creamette, Light 'N Fluffy, Mahatma Rice, Minute Rice, No Yolks, RiceSelect, Skinner, Success Rice",
          "American Beauty, Creamette, Light 'N Fluffy, Mahatma Rice, No Yolks, RiceSelect, Skinner, Wacky Mac",
          "American Beauty, Creamette, Light 'N Fluffy, No Yolks, RiceSelect, Mahatma Rice, Minute Rice, Success Rice",
          "Creamette, American Beauty, Light N Fluffy, No Yolks, Wacky Mac, Mahatma Rice, Minute Rice, RiceSelect, Skinner, Success Rice",
          "Creamette, RiceSelect, Ronzoni, No Yolks, ",
          "Food Broker",
          "RiceSelect",
          "RiceSelect, Mahatma Rice, Minute Rice, Success Rice, American Beauty, Creamette, Light 'N Fluffy, Skinner",
          "RiceSelect, Mahatma Rice, Minute Rice, Success Rice, American Beauty, Creamette, Light 'N Fluffy, Skinner, No Yolks",
          "RiceSelect, Mahatma Rice, Success Rice, Minute Rice, American Beauty, Creamette, No Yolks, Skinner",
          "RiceSelect, Mahatma Rice, Success Rice, Minute Rice, American Beauty, Creamette, Skinner, Wacky Mac",
          "RiceSelect, Mahatma Rice, Success Rice, Minute Rice, Light 'N Fluffy, American Beauty, Creamette, Skinner",
          "RiceSelect, Mahatma Rice, Success Rice, Minute Rice, Skinner, Light 'N Fluffy, No Yolks, American Beauty, Creamette"
        ],
        "Ruiz Foods": [
          "El Monterey & Artisan Bistro"
        ],
        "Sabra": [
          "Sabra"
        ],
        "Sanofi": [
          "Act",
          "Allegra and Xyzal",
          "Aspercreme, Icy Hot",
          "Gold Bond",
          "Gold Bond / Selsun Blue",
          "Icy Hot",
          "Kaopectate and Dulcolax",
          "Nasacort",
          "Selsun Blue"
        ],
        "Saputo": [
          "Saputo"
        ],
        "Sargento": [
          "Reserves"
        ],
        "Schwan's": [
          "Red Baron, Pagoda, Freschetta, Edwards"
        ],
        "SCJ": [
          "Caldrea",
          "Glade",
          "KIWI",
          "Raid, OFF!",
          "Scrubbing Bubbles",
          "Windex",
          "Ziploc",
          "pledge",
          "pledge, Scrubbing Bubbles, Drano, fantastik"
        ],
        "SCJLBU": [
          "MMCD",
          "Method"
        ],
        "Screamin' Sicilian & Urban Pie": [
          "Food Broker"
        ],
        "SE Mills": [
          "Better Than Bouillon"
        ],
        "Seventh Generation": [
          "Seventh Generatin"
        ],
        "Siggis": [
          "Crave Dog",
          "Plant based",
          "Siggis"
        ],
        "SkinCareHouse": [
          "Various Skincare"
        ],
        "SlicedMushroom": [
          "Veggie"
        ],
        "Smithfield": [
        ],
        "Smuckers": [
          "Dunkin and Folgers coffee",
          "Treats (MB, Nurish, NR)"
        ],
        "Solen USA": [
          "Biscolata"
        ],
        "Sovo's Brands": [
          "Rao's"
        ],
        "StarKist": [
          "StarKist "
        ],
        "Stonyfield": [
          "Stonyfield"
        ],
        "Strawberry": [
          "Fruit"
        ],
        "Sun Basket": [
          "Sun Basket"
        ],
        "Sunshine": [
          "Sunshine"
        ],
        "SVG": [
          "LHP",
          "SVG Laundry",
          "SVG Laundry Deals",
          "Seventh Generation",
          "Seventh Generation - deals page (last minute VPC)",
          "Seventh Generation, Unilever BPC"
        ],
        "TasteInSeason": [
          "Produce"
        ],
        "Tata Global Beverages": [
          "Eight O'Clock Coffee",
          "Good Earth & Tetley",
          "Tetley"
        ],
        "TBD": [
          "TBD"
        ],
        "Tile": [
          "Tile"
        ],
        "Toms of Maine": [
          "Toms of Maine"
        ],
        "TransOcean": [
          "TransOcean"
        ],
        "Tru Fru": [
          "Tru Fru"
        ],
        "Tucson Tamales": [
          "Tucson Tamales"
        ],
        "Tyson": [
          "Aidells, HF Smoked Sausage",
          "Ballpark, HF Sausage, Aidells",
          "Dog Treats & Chews",
          "Hillshire Farm Lunchmeat, Hillshire Snacking",
          "Hillshire Farm Smoked Sausage",
          "Hillshire Farm, Aidells, RVAP",
          "Hillshire Farm, Ballpark, RVAP, State Fair",
          "Hillshire Farm, Hillshire Snacking, Jimmy Dean",
          "Hillshire Lunch meat",
          "IQF",
          "Jimmy Dean",
          "Jimmy Dean Frozen and refrigerated",
          "Jimmy Dean Refrigerated",
          "Jimmy Dean Refrigerated & Bacon",
          "RVAP, State Fair",
          "RVAP, lunchmeat, snacking, JD frozen ",
          "Tastemakers",
          "Tyson (Tastemakers, frozen meals)",
          "Tyson Beef/Pork (case ready)",
          "Tyson Shortcuts",
          "Wright Brand Bacon, JD Bacon, Tyson Bacon"
        ],
        "Umpqua Foods": [
          "Munk Pack, Umpqua Oats"
        ],
        "Unilever": [
          "BPC",
          "Ben & Jerry's",
          "Breyer's",
          "Breyer's, Ben & Jerry's, Talenti",
          "Dove",
          "F&R",
          "Ice Cream",
          "Knorr's",
          "Lipton soup",
          "Seventh Generation",
          "Sir Kensington's",
          "Talenti",
          "Tea"
        ],
        "Veg": [
          "Veggie"
        ],
        "Vega": [
          "Protein Powders",
          "Protein bars & shakes",
          "Vega Sport line"
        ],
        "Vegan Eats": [
          "Vegan Eats"
        ],
        "Veggie": [
        ],
        "Verde": [
          "Cereal Snacking",
          "Cheez-it , Pringles, Townhouse",
          "Froot Loops, Frosted Flakes, Apple Jacks, Corn Pops",
          "Frosted Mini Wheats, Frosted Flakes",
          "Kashi, Bear Naked",
          "MSF - Incogmeato",
          "MVP Pack",
          "Morningstar Farms",
          "Special K",
          "Verde"
        ],
        "Wells Enterprises": [
          "Blue Bunny",
          "Bomb Pop",
          "Halo Top",
          "Load'd",
          "The Cheesecake Factory"
        ],
        "Wells/Blue Bunny": [
          "Blue Bunny 46 & 48",
          "Blue Bunny Packaged, Novelties, & Load'd",
          "Bomb Pop",
          "Halo Top ",
          "Sorbabes",
          "The Cheesecake Factory"
        ],
        "White Castle": [
          "Food Broker",
          "White"
        ],
        "Wyman's of Maine": [
          "Caddy Packs - Summer Snacking",
          "Wyman's of Maine"
        ],
        "Yakult": [
          "Yakult"
        ],
        "Yasso": [
          "Yasso"
        ],
        "Zespri": [
        ],
        "Zoi": [
          "Zoi"
        ]
      }
    },
    "status": {
      "dependsOn": [
      ],
      "options": [
        "Approved",
        "Approved - In-Store",
        "Canceled",
        "Copy Revision Needed",
        "Hold",
        "ILM Approved",
        "ILM Draft",
        "Inputs Complete",
        "Inputs Complete - In-Store",
        "Missing Inputs",
        "Storefront Ready",
        "Transferred to CPP"
      ]
    },
    "category": {
      "dependsOn": [
      ],
      "options": [
        "Alcohol",
        "Baby",
        "Baby & Kids Food",
        "Beauty & Personal Care",
        "Beef",
        "Beverages",
        "Breads & Bakery",
        "Breakfast Foods",
        "Butter & Margarine",
        "Candy & Chocolate",
        "Canned & Packaged",
        "Cereal",
        "Cheese",
        "Condiments & Dressings",
        "Cooking & Baking",
        "Dairy & Eggs",
        "Deli & Prepared",
        "Deli Meats & Cheeses",
        "Eggs",
        "Frozen",
        "Fruits",
        "General Merch",
        "Gifting",
        "Health & Wellness",
        "Home and Kitchen",
        "Household",
        "Ice Cream",
        "Juice",
        "Meat & Seafood",
        "Milk & Cream",
        "Office Supplies",
        "Packaged Meals",
        "Pantry Staples",
        "Pet Food",
        "Pet Supplies",
        "Produce",
        "Seafood",
        "Snacks",
        "Sports Nutrition",
        "Toys & Games",
        "Vegetables",
        "Vitamins",
        "Yogurt"
      ]
    },
    "channel": {
      "dependsOn": [
      ],
      "options": [
        "In-Store",
        "Online"
      ]
    },
    "contraCogs": {
      "dependsOn": [
        "vendor"
      ],
      "options": {
        "Alcohol": [
          "test",
          "test2"
        ],
        "Amazon Meal Kits": [
          "test",
          "test2"
        ],
        "AmazonBasics": [
          "test",
          "test2"
        ]
      }
    },
    "dedupeRules": {
      "dependsOn": [
      ],
      "options": [
        "IGNORE_PREVIOUS_RESERVATIONS",
        "IGNORE_VARIATIONAL_PARENTAGE_WITHIN_CAMPAIGN"
      ]
    },
    "desktopSlot": {
      "dependsOn": [
        "pageType",
        "placement"
      ],
      "options": {
        "Category": {
          "Coupons Shoveler": [
            "alm-category-desktop-top-3"
          ],
          "FMC Slots": [
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ],
          "Hero": [
            "alm-category-desktop-top-1"
          ],
          "Nav Blocks": [
            "alm-category-desktop-top-2"
          ],
          "Nav blocks 2": [
            "alm-category-desktop-top-4"
          ],
          "Other": [
            "alm-category-desktop-top-3",
            "alm-category-desktop-top-4"
          ],
          "Shoveler 1": [
            "alm-category-desktop-middleone-1"
          ],
          "Shoveler 2": [
            "alm-category-desktop-middleone-2"
          ],
          "Shoveler 3": [
            "alm-category-desktop-middleone-3"
          ],
          "Shoveler 4": [
            "alm-category-desktop-middletwo-1"
          ],
          "Shoveler 5": [
            "alm-category-desktop-middletwo-2"
          ],
          "Shoveler Bottom 1": [
            "alm-category-desktop-bottom-1"
          ],
          "Shoveler Bottom 2": [
            "alm-category-desktop-bottom-2"
          ],
          "Slot 5": [
            "alm-category-desktop-middletwo-2"
          ],
          "Stripe": [
            "alm-category-desktop-middletwo-2"
          ],
          "VSSM Shoveler 5": [
            "alm-category-desktop-middletwo-2"
          ],
          "VSSM Shoveler 5 - RMS CANNOT NOT USE": [
            "alm-category-desktop-middletwo-2"
          ]
        },
        "Checkout": {
          "BYG 1": [
            "byg-1"
          ],
          "BYG 2": [
            "byg-2"
          ],
          "BYG 3": [
            "byg-3"
          ],
          "Checkout 1": [
            "center-2"
          ],
          "Checkout 2": [
            "center-3"
          ],
          "Checkout 3 (TEST)": [
            "center-4"
          ],
          "DO NOT USE - Checkout 3": [
            "byg-4"
          ]
        },
        "Deals": {
          "Ad": [
            "alm-dealspage-desktop-ad-2"
          ],
          "Alcohol 1": [
            "alm-dealspage-desktop-bottom-16"
          ],
          "Alcohol 2": [
            "alm-dealspage-desktop-bottom-33\t"
          ],
          "All Fresh Coupons": [
            "\talm-dealspage-desktop-top-2"
          ],
          "Basic Deal Shoveler": [
            "alm-dealspage-desktop-middle-1",
            "alm-dealspage-desktop-middle-2",
            "alm-dealspage-desktop-middle-3",
            "alm-dealspage-desktop-middle-4",
            "alm-dealspage-desktop-middle-5",
            "alm-dealspage-desktop-top-6",
            "alm-dealspage-desktop-top-7"
          ],
          "COOP 1": [
            "alm-dealspage-desktop-top-5"
          ],
          "COOP 1 (Featured Brand Deal Shoveler)": [
            "alm-dealspage-desktop-top-5"
          ],
          "COOP 1 (Featured COOP Deal Shoveler)": [
            "alm-dealspage-desktop-top-5"
          ],
          "COOP 2": [
            "alm-dealspage-desktop-top-6"
          ],
          "COOP 3": [
            "alm-dealspage-desktop-top-7"
          ],
          "COOP 4": [
            "alm-dealspage-desktop-middle-1"
          ],
          "COOP 5": [
            "alm-dealspage-desktop-middle-2"
          ],
          "COOP 6": [
            "alm-dealspage-desktop-middle-3"
          ],
          "COOP 7": [
            "alm-dealspage-desktop-middle-4"
          ],
          "F3G 1": [
            "alm-dealspage-desktop-bottom-7"
          ],
          "F3G 10": [
            "alm-dealspage-desktop-bottom-26"
          ],
          "F3G 11": [
            "alm-dealspage-desktop-bottom-27"
          ],
          "F3G 12": [
            "alm-dealspage-desktop-bottom-30"
          ],
          "F3G 13": [
            "alm-dealspage-desktop-bottom-31"
          ],
          "F3G 14": [
            "alm-dealspage-desktop-bottom-32"
          ],
          "F3G 2": [
            "alm-dealspage-desktop-bottom-8"
          ],
          "F3G 3": [
            "alm-dealspage-desktop-bottom-9"
          ],
          "F3G 4": [
            "alm-dealspage-desktop-bottom-13"
          ],
          "F3G 5": [
            "alm-dealspage-desktop-bottom-14"
          ],
          "F3G 6": [
            "alm-dealspage-desktop-bottom-15"
          ],
          "F3G 7": [
            "alm-dealspage-desktop-bottom-18"
          ],
          "F3G 8": [
            "alm-dealspage-desktop-bottom-20"
          ],
          "F3G 9": [
            "alm-dealspage-desktop-bottom-25"
          ],
          "Featured Brand Deal Shoveler": [
            "alm-dealspage-desktop-top-5"
          ],
          "Featured Deal Shoveler ": [
            "alm-dealspage-desktop-top-1"
          ],
          "Featured House Deal Shoveler 1": [
            "alm-dealspage-desktop-top-1"
          ],
          "Featured House Deal Shoveler 2": [
            "alm-dealspage-desktop-top-2"
          ],
          "Fresh Find 2 / OIH": [
            "\talm-dealspage-desktop-top-3"
          ],
          "Fresh Find Hero": [
            "alm-dealspage-desktop-top-3"
          ],
          "Fresh Finds 1": [
            "\talm-dealspage-desktop-reserved-3"
          ],
          "Hero 1": [
            "alm-dealspage-desktop-reserved-2"
          ],
          "Hero 2": [
            "alm-dealspage-desktop-top-1",
            "alm-dealspage-desktop-top-3"
          ],
          "High Vis House (Featured house deal shoveler)": [
            "alm-dealspage-desktop-top-1"
          ],
          "Items Under $X": [
            "alm-dealspage-desktop-bottom-1"
          ],
          "Non-Food 1": [
            "alm-dealspage-desktop-bottom-10"
          ],
          "Non-Food 2": [
            "alm-dealspage-desktop-bottom-11"
          ],
          "Non-Food 3": [
            "alm-dealspage-desktop-bottom-17"
          ],
          "Non-Food 4": [
            "alm-dealspage-desktop-bottom-19"
          ],
          "Non-Food 5": [
            "alm-dealspage-desktop-bottom-34\t"
          ],
          "Non-Food 6": [
            "alm-dealspage-desktop-bottom-35"
          ],
          "Non-Food 7": [
            "alm-dealspage-desktop-bottom-36"
          ],
          "Non-Food 8": [
            "alm-dealspage-desktop-bottom-37"
          ],
          "OIH": [
            "alm-dealspage-desktop-top-4"
          ],
          "Page Header (Hero)": [
            "\talm-dealspage-desktop-reserved-2"
          ],
          "Paleo 1": [
            "alm-dealspage-desktop-bottom-4"
          ],
          "Paleo 2": [
            "alm-dealspage-desktop-bottom-23"
          ],
          "PDCOOP1": [
            "alm-dealspage-desktop-top-2"
          ],
          "PDCOOP10": [
            "alm-dealspage-desktop-middle-5"
          ],
          "PDCOOP2": [
            "alm-dealspage-desktop-top-3"
          ],
          "PDCOOP3": [
            "alm-dealspage-desktop-top-4"
          ],
          "PDCOOP4": [
            "alm-dealspage-desktop-top-6"
          ],
          "PDCOOP5": [
            "alm-dealspage-desktop-top-7"
          ],
          "PDCOOP6": [
            "alm-dealspage-desktop-middle-1"
          ],
          "PDCOOP7": [
            "alm-dealspage-desktop-middle-2"
          ],
          "PDCOOP8": [
            "alm-dealspage-desktop-middle-3"
          ],
          "PDCOOP9": [
            "alm-dealspage-desktop-middle-4"
          ],
          "PDHero": [
            "alm-dealspage-desktop-reserved-2"
          ],
          "PDHouse1": [
            "alm-dealspage-desktop-reserved-3"
          ],
          "PDHouse2": [
            "alm-dealspage-desktop-top-1"
          ],
          "PDHouse3": [
            "alm-dealspage-desktop-top-5"
          ],
          "PDHouse4": [
            "alm-dealspage-desktop-middle-6"
          ],
          "PDVPC1": [
            "alm-dealspage-desktop-bottom-1"
          ],
          "PDVPC2": [
            "alm-dealspage-desktop-bottom-2"
          ],
          "PDVPC3": [
            "alm-dealspage-desktop-bottom-3"
          ],
          "PDVPC4": [
            "alm-dealspage-desktop-bottom-4"
          ],
          "PDVPC5": [
            "alm-dealspage-desktop-bottom-5"
          ],
          "PDVPC6": [
            "alm-dealspage-desktop-bottom-6"
          ],
          "Perishables 1": [
            "alm-dealspage-desktop-bottom-2"
          ],
          "Perishables 2": [
            "alm-dealspage-desktop-bottom-3"
          ],
          "Perishables 3": [
            "alm-dealspage-desktop-bottom-5"
          ],
          "Perishables 4": [
            "alm-dealspage-desktop-bottom-21"
          ],
          "Perishables 5": [
            "alm-dealspage-desktop-bottom-22"
          ],
          "Perishables 6": [
            "alm-dealspage-desktop-bottom-24"
          ],
          "Price Perception": [
            "alm-dealspage-desktop-middle-6"
          ],
          "Reserved Top Slot": [
            "\talm-dealspage-desktop-reserved-1"
          ],
          "Seasonal": [
            "alm-dealspage-desktop-top-4"
          ],
          "Seasonal / OTB": [
            "alm-dealspage-desktop-middle-5"
          ]
        },
        "Event": {
          "Hero": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ],
          "Other": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-21",
            "fresh-merchandised-content-22",
            "fresh-merchandised-content-23",
            "fresh-merchandised-content-24",
            "fresh-merchandised-content-25",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ],
          "Shoveler": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-21",
            "fresh-merchandised-content-22",
            "fresh-merchandised-content-23",
            "fresh-merchandised-content-24",
            "fresh-merchandised-content-25",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ]
        },
        "ILM": {
          "ILM": [
            "ILM"
          ]
        },
        "In-Store": {
          "Digital Signage": [
            "n/a"
          ]
        },
        "Other": {
          "Other": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-21",
            "fresh-merchandised-content-22",
            "fresh-merchandised-content-23",
            "fresh-merchandised-content-24",
            "fresh-merchandised-content-25",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ]
        },
        "Recipes": {
          "featured-recipe-1": [
            "featured-recipe-1"
          ],
          "featured-recipe-2": [
            "featured-recipe-2"
          ]
        },
        "Search": {
          "Multi-Link Sparkle": [
            "desktop-auto-sparkle"
          ],
          "Single Link Sparkle": [
            "desktop-auto-sparkle"
          ],
          "Sparkle": [
            "desktop-auto-sparkle"
          ]
        },
        "Storefront": {
          "Category Slot 1": [
            "alm-storefront-desktop-adleftplacement-1"
          ],
          "Category Slot 2": [
            "alm-storefront-desktop-adleftplacement-2"
          ],
          "Category Tile": [
            "alm-storefront-desktop-bottom-6"
          ],
          "Deals 1": [
            "alm-storefront-desktop-middleone-1"
          ],
          "Deals Side Tile": [
            "alm-storefront-desktop-tcg-2"
          ],
          "DO NOT USE - Category Slot": [
            "alm-storefront-desktop-middletwo-3"
          ],
          "DO NOT USE - Discoverability 1": [
            "alm-storefront-desktop-middleone-2"
          ],
          "DO NOT USE - Discoverability 2": [
            "alm-storefront-desktop-middleone-3"
          ],
          "DO NOT USE - P13N Testing": [
            "desktop-grocery-storefront"
          ],
          "Half Tile": [
            "alm-storefront-desktop-middletwo-2"
          ],
          "HERO": [
            "alm-storefront-desktop-tcg-3"
          ],
          "HERO Main": [
            "alm-storefront-desktop-tcg-1"
          ],
          "Nav Blocks": [
            "alm-storefront-desktop-top-1"
          ],
          "Other": [
            "alm-storefront-desktop-reserved-2"
          ],
          "P13N 6 (Approval Needed)": [
            "alm-storefront-desktop-top-7"
          ],
          "Private Label SHV": [
            "alm-storefront-desktop-adleftplacement-2"
          ],
          "Seasonal SHV": [
            "alm-storefront-desktop-adleftplacement-1"
          ],
          "Shoveler 1": [
            "alm-storefront-desktop-top-7"
          ],
          "Shoveler 1 (InvAw)": [
            "alm-storefront-desktop-adleftplacement-2"
          ],
          "Shoveler 2": [
            "alm-storefront-desktop-middletwo-1"
          ],
          "Shoveler 3": [
            "alm-storefront-desktop-bottom-1"
          ],
          "Shoveler 4": [
            "alm-storefront-desktop-bottom-2"
          ],
          "Shoveler 5": [
            "alm-storefront-desktop-bottom-3"
          ],
          "TR - Category Slot 1": [
            "alm-storefront-desktop-middletwo-3"
          ],
          "TR - Deals 1": [
            "alm-storefront-desktop-middleone-1"
          ],
          "TR - Half Tile": [
            "alm-storefront-desktop-middletwo-2"
          ],
          "TR - Private Label SHV": [
            "alm-storefront-desktop-adleftplacement-2"
          ],
          "TR - Seasonal SHV": [
            "alm-storefront-desktop-adleftplacement-1"
          ],
          "TR - Shoveler 1": [
            "alm-storefront-desktop-top-7"
          ],
          "TR - Shoveler 2": [
            "alm-storefront-desktop-middletwo-1"
          ],
          "TR - Shoveler 3": [
            "alm-storefront-desktop-bottom-1"
          ],
          "TR - Shoveler 4": [
            "alm-storefront-desktop-bottom-2"
          ],
          "TR - Shoveler 5": [
            "alm-storefront-desktop-bottom-3"
          ],
          "TR – Discoverability Slot 1": [
            "alm-storefront-desktop-middleone-2"
          ],
          "TR – Discoverability Slot 2": [
            "alm-storefront-desktop-middleone-3"
          ],
          "VSSM Shoveler 6": [
            "alm-storefront-desktop-bottom-4"
          ],
          "VSSM Shoveler 6 - RMS CANNOT NOT USE": [
            "alm-storefront-desktop-bottom-4"
          ],
          "VSSM Shoveler 6 - RMS CANNOT USE": [
            "alm-storefront-desktop-bottom-4 "
          ],
          "VSSM Shoveler 7 - RMS CANNOT USE": [
            "alm-storefront-desktop-bottom-5"
          ]
        }
      }
    },
    "mediaPackage": {
      "dependsOn": [
      ],
      "options": [
        "$10MM",
        "$15MM",
        "$20MM"
      ]
    },
    "mobileSlot": {
      "dependsOn": [
        "pageType",
        "placement"
      ],
      "options": {
        "Category": {
          "Coupons Shoveler": [
            "alm-category-mobile-top-3"
          ],
          "FMC Slots": [
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ],
          "Hero": [
            "alm-category-mobile-top-1"
          ],
          "Nav Blocks": [
            "N/A",
            "mobile-bottom-1"
          ],
          "Nav blocks 2": [
            "alm-category-mobile-top-4"
          ],
          "Other": [
            "alm-category-mobile-top-3",
            "alm-category-mobile-top-4"
          ],
          "Shoveler 1": [
            "alm-category-mobile-middleone-1"
          ],
          "Shoveler 2": [
            "alm-category-mobile-middleone-2"
          ],
          "Shoveler 3": [
            "alm-category-mobile-middleone-3"
          ],
          "Shoveler 4": [
            "alm-category-mobile-middletwo-1"
          ],
          "Shoveler 5": [
            "alm-category-mobile-middletwo-2"
          ],
          "Shoveler Bottom 1": [
            "alm-category-mobile-bottom-1"
          ],
          "Shoveler Bottom 2": [
            "alm-category-mobile-bottom-2"
          ],
          "Slot 5": [
            "alm-category-mobile-middletwo-2"
          ],
          "Stripe": [
            "alm-category-mobile-middletwo-2"
          ],
          "VSSM Shoveler 5": [
            "alm-category-mobile-middletwo-2"
          ],
          "VSSM Shoveler 5 - RMS CANNOT NOT USE": [
            "alm-category-mobile-middletwo-2"
          ]
        },
        "Checkout": {
          "BYG 1": [
            "byg-1"
          ],
          "BYG 2": [
            "byg-2"
          ],
          "BYG 3": [
            "byg-3"
          ],
          "Checkout 1": [
            "center-2"
          ],
          "Checkout 2": [
            "center-3"
          ],
          "Checkout 3 (TEST)": [
            "center-4"
          ],
          "DO NOT USE - Checkout 3": [
            "byg-mobile-4"
          ]
        },
        "Deals": {
          "Ad": [
            "alm-dealspage-mobile-ad-2\t"
          ],
          "Alcohol 1": [
            "alm-dealspage-mobile-bottom-16"
          ],
          "Alcohol 2": [
            "alm-dealspage-mobile-bottom-33"
          ],
          "All Fresh Coupons": [
            "alm-dealspage-mobile-top-2\t"
          ],
          "Basic Deal Shoveler": [
            "alm-dealspage-mobile-middle-1",
            "alm-dealspage-mobile-middle-2",
            "alm-dealspage-mobile-middle-3",
            "alm-dealspage-mobile-middle-4",
            "alm-dealspage-mobile-middle-5",
            "alm-dealspage-mobile-top-6",
            "alm-dealspage-mobile-top-7"
          ],
          "COOP 1": [
            "alm-dealspage-mobile-top-5"
          ],
          "COOP 1 (Featured Brand Deal Shoveler)": [
            "alm-dealspage-mobile-top-5\t"
          ],
          "COOP 1 (Featured COOP Deal Shoveler)": [
            "alm-dealspage-mobile-top-5"
          ],
          "COOP 2": [
            "alm-dealspage-mobile-top-6"
          ],
          "COOP 3": [
            "alm-dealspage-mobile-top-7"
          ],
          "COOP 4": [
            "alm-dealspage-mobile-middle-1"
          ],
          "COOP 5": [
            "alm-dealspage-mobile-middle-2"
          ],
          "COOP 6": [
            "alm-dealspage-mobile-middle-3"
          ],
          "COOP 7": [
            "alm-dealspage-mobile-middle-4"
          ],
          "F3G 1": [
            "alm-dealspage-mobile-bottom-7\t"
          ],
          "F3G 10": [
            "alm-dealspage-mobile-bottom-26"
          ],
          "F3G 11": [
            "alm-dealspage-mobile-bottom-27"
          ],
          "F3G 12": [
            "alm-dealspage-mobile-bottom-30"
          ],
          "F3G 13": [
            "alm-dealspage-mobile-bottom-31\t"
          ],
          "F3G 14": [
            "alm-dealspage-mobile-bottom-32\t"
          ],
          "F3G 2": [
            "alm-dealspage-mobile-bottom-8"
          ],
          "F3G 3": [
            "alm-dealspage-mobile-bottom-9\t"
          ],
          "F3G 4": [
            "alm-dealspage-mobile-bottom-13\t"
          ],
          "F3G 5": [
            "alm-dealspage-mobile-bottom-14"
          ],
          "F3G 6": [
            "alm-dealspage-mobile-bottom-15"
          ],
          "F3G 7": [
            "alm-dealspage-mobile-bottom-18"
          ],
          "F3G 8": [
            "alm-dealspage-mobile-bottom-20"
          ],
          "F3G 9": [
            "alm-dealspage-mobile-bottom-25\t"
          ],
          "Featured Brand Deal Shoveler": [
            "alm-dealspage-mobile-top-5"
          ],
          "Featured Deal Shoveler ": [
            "alm-dealspage-mobile-top-1"
          ],
          "Featured House Deal Shoveler 1": [
            "alm-dealspage-mobile-top-1"
          ],
          "Featured House Deal Shoveler 2": [
            "alm-dealspage-mobile-top-2"
          ],
          "Fresh Find 2 / OIH": [
            "alm-dealspage-mobile-top-3\t"
          ],
          "Fresh Find Hero": [
            "alm-dealspage-mobile-top-3"
          ],
          "Fresh Finds 1": [
            "alm-dealspage-mobile-reserved-3\t"
          ],
          "Hero 1": [
            "alm-dealspage-mobile-reserved-2"
          ],
          "Hero 2": [
            "alm-dealspage-mobile-top-1",
            "alm-dealspage-mobile-top-3"
          ],
          "High Vis House (Featured house deal shoveler)": [
            "alm-dealspage-mobile-top-1\t"
          ],
          "Items Under $X": [
            "alm-dealspage-mobile-bottom-1\t"
          ],
          "Non-Food 1": [
            "alm-dealspage-mobile-bottom-10"
          ],
          "Non-Food 2": [
            "alm-dealspage-mobile-bottom-11"
          ],
          "Non-Food 3": [
            "alm-dealspage-mobile-bottom-17\t"
          ],
          "Non-Food 4": [
            "alm-dealspage-mobile-bottom-19\t"
          ],
          "Non-Food 5": [
            "alm-dealspage-mobile-bottom-34"
          ],
          "Non-Food 6": [
            "alm-dealspage-mobile-bottom-35"
          ],
          "Non-Food 7": [
            "alm-dealspage-mobile-bottom-36"
          ],
          "Non-Food 8": [
            "alm-dealspage-mobile-bottom-37"
          ],
          "OIH": [
            "alm-dealspage-mobile-top-4\t"
          ],
          "Page Header (Hero)": [
            "alm-dealspage-mobile-reserved-2\t"
          ],
          "Paleo 1": [
            "alm-dealspage-mobile-bottom-4\t"
          ],
          "Paleo 2": [
            "alm-dealspage-mobile-bottom-23\t"
          ],
          "PDCOOP1": [
            "alm-dealspage-mobile-top-2"
          ],
          "PDCOOP10": [
            "alm-dealspage-mobile-middle-5"
          ],
          "PDCOOP2": [
            "alm-dealspage-mobile-top-3"
          ],
          "PDCOOP3": [
            "alm-dealspage-mobile-top-4"
          ],
          "PDCOOP4": [
            "alm-dealspage-mobile-top-6"
          ],
          "PDCOOP5": [
            "alm-dealspage-mobile-top-7"
          ],
          "PDCOOP6": [
            "alm-dealspage-mobile-middle-1"
          ],
          "PDCOOP7": [
            "alm-dealspage-mobile-middle-2"
          ],
          "PDCOOP8": [
            "alm-dealspage-mobile-middle-3"
          ],
          "PDCOOP9": [
            "alm-dealspage-mobile-middle-4"
          ],
          "PDHero": [
            "alm-dealspage-mobile-reserved-2"
          ],
          "PDHouse1": [
            "alm-dealspage-mobile-reserved-3"
          ],
          "PDHouse2": [
            "alm-dealspage-mobile-top-1"
          ],
          "PDHouse3": [
            "alm-dealspage-mobile-top-5"
          ],
          "PDHouse4": [
            "alm-dealspage-mobile-middle-6"
          ],
          "PDVPC1": [
            "alm-dealspage-mobile-bottom-1"
          ],
          "PDVPC2": [
            "alm-dealspage-mobile-bottom-2"
          ],
          "PDVPC3": [
            "alm-dealspage-mobile-bottom-3"
          ],
          "PDVPC4": [
            "alm-dealspage-mobile-bottom-4"
          ],
          "PDVPC5": [
            "alm-dealspage-mobile-bottom-5"
          ],
          "PDVPC6": [
            "alm-dealspage-mobile-bottom-6"
          ],
          "Perishables 1": [
            "alm-dealspage-mobile-bottom-2\t"
          ],
          "Perishables 2": [
            "alm-dealspage-mobile-bottom-3\t"
          ],
          "Perishables 3": [
            "alm-dealspage-mobile-bottom-5\t"
          ],
          "Perishables 4": [
            "alm-dealspage-mobile-bottom-21\t"
          ],
          "Perishables 5": [
            "alm-dealspage-mobile-bottom-22\t"
          ],
          "Perishables 6": [
            "alm-dealspage-mobile-bottom-24\t"
          ],
          "Price Perception": [
            "alm-dealspage-mobile-middle-6\t"
          ],
          "Reserved Top Slot": [
            "alm-dealspage-mobile-reserved-1"
          ],
          "Seasonal": [
            "alm-dealspage-mobile-top-4"
          ],
          "Seasonal / OTB": [
            "alm-dealspage-mobile-middle-5\t"
          ]
        },
        "Event": {
          "Hero": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ],
          "Other": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-21",
            "fresh-merchandised-content-22",
            "fresh-merchandised-content-23",
            "fresh-merchandised-content-24",
            "fresh-merchandised-content-25",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ],
          "Shoveler": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-21",
            "fresh-merchandised-content-22",
            "fresh-merchandised-content-23",
            "fresh-merchandised-content-24",
            "fresh-merchandised-content-25",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ]
        },
        "ILM": {
          "ILM": [
            "ILM"
          ]
        },
        "In-Store": {
          "Digital Signage": [
            "n/a"
          ]
        },
        "Other": {
          "Other": [
            "fresh-merchandised-content-1",
            "fresh-merchandised-content-10",
            "fresh-merchandised-content-11",
            "fresh-merchandised-content-12",
            "fresh-merchandised-content-13",
            "fresh-merchandised-content-14",
            "fresh-merchandised-content-15",
            "fresh-merchandised-content-16",
            "fresh-merchandised-content-17",
            "fresh-merchandised-content-18",
            "fresh-merchandised-content-19",
            "fresh-merchandised-content-2",
            "fresh-merchandised-content-20",
            "fresh-merchandised-content-21",
            "fresh-merchandised-content-22",
            "fresh-merchandised-content-23",
            "fresh-merchandised-content-24",
            "fresh-merchandised-content-25",
            "fresh-merchandised-content-3",
            "fresh-merchandised-content-4",
            "fresh-merchandised-content-5",
            "fresh-merchandised-content-6",
            "fresh-merchandised-content-7",
            "fresh-merchandised-content-8",
            "fresh-merchandised-content-9"
          ]
        },
        "Recipes": {
          "featured-recipe-1": [
            "featured-recipe-1"
          ],
          "featured-recipe-2": [
            "featured-recipe-2"
          ]
        },
        "Search": {
          "Multi-Link Sparkle": [
            "mobile-auto-sparkle"
          ],
          "Single Link Sparkle": [
            "mobile-auto-sparkle"
          ],
          "Sparkle": [
            "mobile-auto-sparkle"
          ]
        },
        "Storefront": {
          "Category Slot 1": [
            "alm-storefront-mobile-adleftplacement-1"
          ],
          "Category Slot 2": [
            "alm-storefront-mobile-adleftplacement-2"
          ],
          "Category Tile": [
            "alm-storefront-desktop-mobile-6"
          ],
          "Deals 1": [
            "alm-storefront-mobile-middleone-1"
          ],
          "Deals Side Tile": [
            "alm-storefront-mobile-tcg-2"
          ],
          "DO NOT USE - Category Slot": [
            "alm-storefront-mobile-middletwo-3"
          ],
          "DO NOT USE - Discoverability 1": [
            "alm-storefront-mobile-middleone-2"
          ],
          "DO NOT USE - Discoverability 2": [
            "alm-storefront-mobile-middleone-3"
          ],
          "DO NOT USE - P13N Testing": [
            "mobile-grocery-storefront"
          ],
          "Half Tile": [
            "alm-storefront-mobile-middletwo-2"
          ],
          "HERO": [
            "alm-storefront-mobile-tcg-special-3"
          ],
          "HERO Main": [
            "alm-storefront-mobile-tcg-1"
          ],
          "Nav Blocks": [
            "N/A"
          ],
          "Other": [
            "alm-storefront-mobile-reserved-2"
          ],
          "P13N 6 (Approval Needed)": [
            "alm-storefront-mobile-top-7"
          ],
          "Private Label SHV": [
            "alm-storefront-mobile-adleftplacement-2"
          ],
          "Seasonal SHV": [
            "alm-storefront-mobile-adleftplacement-1"
          ],
          "Shoveler 1": [
            "alm-storefront-mobile-top-7"
          ],
          "Shoveler 1 (InvAw)": [
            "alm-storefront-mobile-adleftplacement-2"
          ],
          "Shoveler 2": [
            "alm-storefront-mobile-middletwo-1"
          ],
          "Shoveler 3": [
            "alm-storefront-mobile-bottom-1"
          ],
          "Shoveler 4": [
            "alm-storefront-mobile-bottom-2"
          ],
          "Shoveler 5": [
            "alm-storefront-mobile-bottom-3"
          ],
          "TR - Category Slot 1": [
            "alm-storefront-mobile-middletwo-3"
          ],
          "TR - Deals 1": [
            "alm-storefront-mobile-middleone-1"
          ],
          "TR - Half Tile": [
            "alm-storefront-mobile-middletwo-2"
          ],
          "TR - Private Label SHV": [
            "alm-storefront-mobile-adleftplacement-2"
          ],
          "TR - Seasonal SHV": [
            "alm-storefront-mobile-adleftplacement-1"
          ],
          "TR - Shoveler 1": [
            "alm-storefront-mobile-top-7"
          ],
          "TR - Shoveler 2": [
            "alm-storefront-mobile-middletwo-1"
          ],
          "TR - Shoveler 3": [
            "alm-storefront-mobile-bottom-1"
          ],
          "TR - Shoveler 4": [
            "alm-storefront-mobile-bottom-2"
          ],
          "TR - Shoveler 5": [
            "alm-storefront-mobile-bottom-3"
          ],
          "TR – Discoverability Slot 1": [
            "alm-storefront-mobile-middleone-2"
          ],
          "TR – Discoverability Slot 2": [
            "alm-storefront-mobile-middleone-3"
          ],
          "VSSM Shoveler 6": [
            "alm-storefront-mobile-bottom-4"
          ],
          "VSSM Shoveler 6 - RMS CANNOT NOT USE": [
            "alm-storefront-mobile-bottom-4"
          ],
          "VSSM Shoveler 6 - RMS CANNOT USE": [
            "alm-storefront-mobile-bottom-4 "
          ],
          "VSSM Shoveler 7 - RMS CANNOT USE": [
            "alm-storefront-mobile-bottom-5"
          ]
        }
      }
    },
    "otherTheme": {
      "dependsOn": [
      ],
      "options": [
        "BFY Snacks",
        "Beverages",
        "Breakfast",
        "Breakfast Beverages",
        "CSDs",
        "CSDs & Energy",
        "Energy",
        "Gen Merch",
        "PL/CL Alcohol",
        "PepsiCo Snackapalooza",
        "PepsiCo Super Bowl",
        "Seasonal",
        "Snacks",
        "Snacks & Beverages",
        "Water"
      ]
    },
    "packageName": {
      "dependsOn": [
      ],
      "options": [
        "2023 Premium Prime Day Feature",
        "2023 Prime Day Exclusive Shoveler",
        "2023 Prime Day Inclusion",
        "In-Aisle Promotion",
        "In-Store Endcap",
        "Omnichannel Deal Feature",
        "Omnichannel Deal Spotlight",
        "Omnichannel Event",
        "Omnichannel Event Plus",
        "Omnichannel Fresh Find",
        "Omnichannel Launch Package",
        "Online Category Feature",
        "Online Category Shoveler Feature",
        "Online Checkout Feature",
        "Online Deal Feature",
        "Online Deal Page Hero Feature",
        "Online Enhanced Category Shoveler Feature",
        "Online Enhanced Storefront Shoveler Feature",
        "Online Fresh Find",
        "Online Storefront Shoveler Feature",
        "Online Storefront Shoveler Feature Plus",
        "Online Storefront Tile Feature",
        "Online Tier A Event Dedicated Inclusion",
        "Online Tier B Event Dedicated Inclusion",
        "Online Tier C Event Dedicated Inclusion",
        "Premium Endcap (F3G only)",
        "Specialty Page Feature",
        "VSSM Category",
        "VSSM Storefront"
      ]
    },
    "pageGroup": {
      "dependsOn": [
        "pageName"
      ],
      "options": {
        " Butter & Margarine": [
          "Standard Category"
        ],
        "2021 BTS/OTC store INPUTS": [
          "F3 Events"
        ],
        "2022 Cinco de Mayo": [
          "F3 Events"
        ],
        "2022 Cinco de Mayo INPUTS": [
          "F3 Events"
        ],
        "2022 Cold and Flu INPUTS": [
          "F3 Events"
        ],
        "2022 Earth Day INPUTS": [
          "F3 Events"
        ],
        "2022 Easter INPUTS": [
          "F3 Events"
        ],
        "2022 Fall Flavors INPUTS": [
          "F3 Events"
        ],
        "2022 Father's Day INPUTS": [
          "F3 Events"
        ],
        "2022 Halloween INPUTS": [
          "F3 Events"
        ],
        "2022 Holiday Shop INPUTS": [
          "F3 Events"
        ],
        "2022 March Baby Sale": [
          "F3 Events"
        ],
        "2022 March Baby Sale INPUTS": [
          "F3 Events"
        ],
        "2022 March Madness": [
          "F3 Events"
        ],
        "2022 March Madness INPUTS": [
          "F3 Events"
        ],
        "2022 Mother's Day INPUTS": [
          "F3 Events"
        ],
        "2022 New Years Eve INPUTS": [
          "F3 Events"
        ],
        "2022 Prime Day INPUTS": [
          "F3 Events"
        ],
        "2022 Ready for School INPUTS": [
          "F3 Events"
        ],
        "2022 September Baby Sale INPUTS": [
          "F3 Events"
        ],
        "2022 Spring Cleaning INPUTS": [
          "F3 Events"
        ],
        "2022 St. Patrick's Day": [
          "F3 Events"
        ],
        "2022 St. Patrick's Day INPUTS": [
          "F3 Events"
        ],
        "2022 Summer Grilling Inputs": [
          "F3 Events"
        ],
        "2022 Super Bowl": [
          "F3 Events"
        ],
        "2022 Super Bowl INPUTS": [
          "F3 Events"
        ],
        "2022 Thanksgiving INPUTS": [
          "F3 Events"
        ],
        "2022 Valentine's day": [
          "F3 Events"
        ],
        "2022 Valentine's Day INPUTS": [
          "F3 Events"
        ],
        "2023 New Year, New You": [
          "F3 Events"
        ],
        "2023 New Year, New You INPUTS": [
          "F3 Events"
        ],
        "Alcohol": [
          "Standard Category"
        ],
        "Alexa Nutrition Discovery": [
          "Other"
        ],
        "Allergy": [
          "F3 Events"
        ],
        "Artisan Cheese": [
          "Standard Category"
        ],
        "Arts and Crafts Supplies": [
          "Standard Category"
        ],
        "At-home beauty": [
          "F3 Events"
        ],
        "Baby": [
          "Standard Category"
        ],
        "Baby Health Care": [
          "Standard Category"
        ],
        "Baby PC": [
          "Baby Personal Care"
        ],
        "Baby Personal Care": [
          "Baby Personal Care"
        ],
        "Baby Sale": [
        ],
        "Baby Skin Care": [
          "Standard Category"
        ],
        "Back to Sports": [
          "F3 Events"
        ],
        "Baked Goods and Sweet Treats": [
          "Standard Category"
        ],
        "Bakery Amazon Brands": [
          "Standard Category"
        ],
        "Bakery Breakfast": [
          "Standard Category"
        ],
        "Bakery Shop by Brand": [
          "Standard Category"
        ],
        "Baking Mixes": [
          "Standard Category"
        ],
        "Beauty & Personal Care": [
          "Premium Category"
        ],
        "Bed & Bath": [
          "Standard Category"
        ],
        "Beef": [
          "Premium Category"
        ],
        "Beef Buying Guide": [
          "Standard Category"
        ],
        "Beer": [
          "Standard Category"
        ],
        "Beverages": [
          "Premium Category"
        ],
        "BHM 2022": [
          "F3 Events"
        ],
        "Black Friday/Cyber Monday": [
          "F3 Events"
        ],
        "Block Cheese": [
          "Standard Category"
        ],
        "Bread": [
          "Standard Category"
        ],
        "Breads & Bakery": [
          "Premium Category"
        ],
        "Breakfast & Cereal Bars": [
          "Standard Category"
        ],
        "Breakfast Foods": [
          "Premium Category"
        ],
        "Candy & Chocolate": [
          "Standard Category"
        ],
        "Canned & Packaged": [
          "Standard Category"
        ],
        "Cat Food": [
          "Standard Category"
        ],
        "Cat Supplies": [
          "Standard Category"
        ],
        "Cereal": [
          "Standard Category"
        ],
        "Checkout": [
          "Checkout"
        ],
        "Cheese": [
          "Standard Category"
        ],
        "Chicken": [
          "Standard Category"
        ],
        "Chips": [
          "Standard Category"
        ],
        "Chobani BXGY": [
          "Standard Category"
        ],
        "Chobani Oat Barista FF": [
          "F3 Events"
        ],
        "Cinco de Mayo": [
          "F3 Events"
        ],
        "Cinco de Mayo 2021": [
          "F3 Events"
        ],
        "Clothing & Accessories": [
          "Standard Category"
        ],
        "Coffee & Tea": [
          "Standard Category"
        ],
        "Cold & Flu": [
          "F3 Events"
        ],
        "Condiments & Dressings": [
          "Standard Category"
        ],
        "Cooking & Baking": [
          "Standard Category"
        ],
        "Cough, Cold, and Flu": [
          "Standard Category"
        ],
        "Crackers": [
          "Standard Category"
        ],
        "Dairy & Eggs": [
          "Premium Category"
        ],
        "Deals": [
          "Deals"
        ],
        "Deli & Prepared": [
          "Premium Category"
        ],
        "Deli & Prepared Amazon Brands": [
          "Standard Category"
        ],
        "Deli Meats & Cheeses": [
          "Standard Category"
        ],
        "Deodorants and Antiperspirants": [
          "Standard Category"
        ],
        "Digestion and Nausea": [
          "Standard Category"
        ],
        "Dips and Spreads": [
          "Standard Category"
        ],
        "Dishwashing": [
          "Standard Category"
        ],
        "Dog Food": [
          "Standard Category"
        ],
        "Dog Supplies": [
          "Standard Category"
        ],
        "Dog Toys": [
          "Standard Category"
        ],
        "Dog Treats": [
          "Standard Category"
        ],
        "Easter": [
          "F3 Events"
        ],
        "Easter 2021": [
          "F3 Events"
        ],
        "Eat In": [
          "F3 Events"
        ],
        "Eat in- Meal ideas": [
          "Other",
          "Standard Category"
        ],
        "Eat in-Main": [
          "Other",
          "Standard Category"
        ],
        "Eat in-Ready to eat/heat": [
          "Other",
          "Standard Category"
        ],
        "Egg Buying Guide": [
          "Egg Buying Guide"
        ],
        "Eggs": [
          "Standard Category"
        ],
        "Electronics": [
          "Standard Category"
        ],
        "Fall Store": [
          "F3 Events"
        ],
        "Father's Day": [
          "F3 Events"
        ],
        "Father's Day 2021 Inputs": [
          "F3 Events"
        ],
        "Feeding and Formula": [
          "Standard Category"
        ],
        "Feminine Care": [
          "Standard Category"
        ],
        "Flatbreads and Tortillas": [
          "Standard Category"
        ],
        "Frozen": [
          "Premium Category"
        ],
        "Frozen Appetizers and Snacks": [
          "Standard Category"
        ],
        "Frozen Breakfast Foods": [
          "Standard Category"
        ],
        "Frozen Fruit": [
          "Standard Category"
        ],
        "Frozen Meals and Entrees": [
          "Standard Category"
        ],
        "Frozen Meats": [
          "Standard Category"
        ],
        "Frozen Seafood": [
          "Standard Category"
        ],
        "Frozen Vegetables": [
          "Standard Category"
        ],
        "Fruit Cups and Applesauce": [
          "Standard Category"
        ],
        "Fruits": [
          "Premium Category"
        ],
        "Gifting": [
          "Standard Category"
        ],
        "Gluten-Free": [
          "Other"
        ],
        "Granola": [
          "Standard Category"
        ],
        "Halloween Store": [
          "F3 Events"
        ],
        "Halloween/Fall": [
          "F3 Events"
        ],
        "Happy School Year": [
          "F3 Events"
        ],
        "Health & Safety": [
          "F3 Events"
        ],
        "Health & Wellness": [
          "Standard Category"
        ],
        "Health Care - PL ": [
          "Standard Category"
        ],
        "Herbs and Spices": [
          "Standard Category"
        ],
        "Holiday Shop": [
          "F3 Events"
        ],
        "Holiday Shop 2021": [
          "F3 Events"
        ],
        "Home and Kitchen": [
          "Standard Category"
        ],
        "Home Storage and Organization": [
          "Standard Category"
        ],
        "Household": [
          "Premium Category"
        ],
        "Ice Cream": [
          "Standard Category"
        ],
        "ILM": [
          "ILM"
        ],
        "International Foods": [
          "Standard Category"
        ],
        "Jerky & Pork Rinds": [
          "Standard Category"
        ],
        "Juice": [
          "Standard Category"
        ],
        "Juicing Buying Guide": [
          "Other"
        ],
        "Keto": [
          "Other"
        ],
        "Kitchen & Dining": [
          "Standard Category"
        ],
        "Laundry": [
          "Standard Category"
        ],
        "Macaroni and Cheese": [
          "Standard Category"
        ],
        "March Madness": [
          "F3 Events"
        ],
        "March Madness 2021": [
          "F3 Events"
        ],
        "Meat & Seafood": [
          "Premium Category"
        ],
        "Meat Subsitutes": [
          "Standard Category"
        ],
        "Milk & Cream": [
          "Premium Category",
          "Standard Category"
        ],
        "Mother's Day": [
          "F3 Events"
        ],
        "Mother's Day 2021": [
          "F3 Events"
        ],
        "Movie Night In": [
          "F3 Events"
        ],
        "National Dairy Month": [
          "F3 Events"
        ],
        "National Wellness Month": [
          "F3 Events"
        ],
        "New Year, New You": [
          "F3 Events"
        ],
        "New Year, New You 2021": [
          "F3 Events"
        ],
        "Non-Dairy": [
          "Standard Category"
        ],
        "Non-Dairy Beverages": [
          "Standard Category"
        ],
        "Nuts and Seeds": [
          "Standard Category"
        ],
        "Nuts and Trail Mix": [
          "Standard Category"
        ],
        "Oatmeal": [
          "Standard Category"
        ],
        "Office Supplies": [
          "Standard Category"
        ],
        "Organic": [
          "Other"
        ],
        "Organic Valley Brand Store": [
          "Other"
        ],
        "OTC Landing": [
          "F3 Events"
        ],
        "Other": [
          "Other"
        ],
        "P&G BXGY": [
          "Standard Category"
        ],
        "Packaged Meals": [
          "Standard Category"
        ],
        "Packaged Produce": [
          "Standard Category"
        ],
        "Pain Relief": [
          "Standard Category"
        ],
        "Paleo": [
          "Other"
        ],
        "Pantry Staples": [
          "Premium Category"
        ],
        "Pasta & Noodles": [
          "Standard Category"
        ],
        "PD2022": [
          "Event"
        ],
        "Pet Supplies": [
          "Standard Category"
        ],
        "Plant-Based": [
          "Other"
        ],
        "Plant-based Lifestyles": [
          "Standard Category"
        ],
        "Plant-Based Yogurt": [
          "Standard Category"
        ],
        "Popcorn and Pretzels": [
          "Standard Category"
        ],
        "Pork": [
          "Standard Category"
        ],
        "Powdered Drink Mixes": [
          "Standard Category"
        ],
        "Prepared foods": [
          "Standard Category"
        ],
        "Prime Day": [
          "F3 Events"
        ],
        "Produce": [
          "Premium Category"
        ],
        "Pudding and Gelatin": [
          "Standard Category"
        ],
        "Recipe": [
          "F3 Events"
        ],
        "Refrigerated Dough": [
          "Standard Category"
        ],
        "RFS Food": [
          "F3 Events"
        ],
        "RFS HPC": [
          "F3 Events"
        ],
        "RFS Landing": [
          "F3 Events"
        ],
        "Rolls and Buns": [
          "Standard Category"
        ],
        "Root Vegetable Buying Guide": [
          "Other"
        ],
        "Sandwich": [
          "F3 Events"
        ],
        "Sauces and Marinades": [
          "Standard Category"
        ],
        "SCJ Summer Store": [
          "F3 Events"
        ],
        "Seafood": [
          "Premium Category"
        ],
        "Search": [
          "Search"
        ],
        "Search Test": [
        ],
        "Seasonal Deals": [
          "F3 Events"
        ],
        "Self-Care Sale": [
          "SCS 2021"
        ],
        "September Baby Sale 2021": [
          "F3 Events"
        ],
        "Shipping Cutoff": [
          "F3 Events"
        ],
        "Shredded Cheese": [
          "Standard Category"
        ],
        "Sleep Aid": [
          "Premium Category"
        ],
        "Sliced Cheese": [
          "Standard Category"
        ],
        "Snack Bars": [
          "Standard Category"
        ],
        "Snack Cakes and Cookies": [
          "Standard Category"
        ],
        "Snacks": [
          "Premium Category"
        ],
        "Snacks and Dried Fruit": [
          "Standard Category"
        ],
        "Soap and sanitizer": [
          "F3 Events",
          "Standard Category"
        ],
        "Soup, Stocks, and Broths": [
          "Standard Category"
        ],
        "Specialty Diets": [
          "Other",
          "Standard Category"
        ],
        "Spirits": [
          "Standard Category"
        ],
        "Sports Nutrition": [
          "Standard Category"
        ],
        "Spring Cleaning": [
          "F3 Events"
        ],
        "Spring Cleaning 2022": [
          "F3 Events"
        ],
        "St. Patrick's Day": [
          "F3 Events"
        ],
        "St. Patrick's Day 2021": [
          "F3 Events"
        ],
        "Storefront": [
          "Storefront"
        ],
        "Summer Grilling": [
          "F3 Events"
        ],
        "Summer Grilling 2021": [
          "F3 Events"
        ],
        "Summer Store 2021 Inputs": [
          "F3 Events"
        ],
        "Super Bowl": [
          "F3 Events"
        ],
        "Super Bowl 2021": [
          "F3 Events"
        ],
        "Super Bowl 2023 Inputs": [
          "30005044"
        ],
        "Tea": [
          "Standard Category"
        ],
        "Thanksgiving": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Apps": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Bev": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Clean-up": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Dessert": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Main": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Sides": [
          "F3 Events"
        ],
        "Thanksgiving 20 - Turkey": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Beverages": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Clean Up": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Desserts": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Main": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Sides": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Snacks and Apps": [
          "F3 Events"
        ],
        "Thanksgiving 21 - Turkey": [
          "F3 Events"
        ],
        "Thanksgiving 22 - Apps": [
          "Events"
        ],
        "Thanksgiving 22 - Bev": [
          "Events"
        ],
        "Thanksgiving 22 - Clean-up": [
          "Events"
        ],
        "Thanksgiving 22 - Desserts": [
          "Events"
        ],
        "Thanksgiving 22 - Sides": [
          "Events"
        ],
        "Thanksgiving 22 - Turkey": [
          "Events"
        ],
        "Toaster Pastries": [
          "Standard Category"
        ],
        "Toys & Games": [
          "Standard Category"
        ],
        "Valentine's Day": [
          "F3 Events"
        ],
        "Valentine's Day 2021": [
          "F3 Events"
        ],
        "Valentine's Day 2022": [
          "F3 Events"
        ],
        "Vegetables": [
          "Premium Category"
        ],
        "Vitamins": [
          "Standard Category"
        ],
        "Water": [
          "Standard Category"
        ],
        "Wellness": [
          "Standard Category"
        ],
        "Whitney/Zak Test Page": [
          "F3 Events"
        ],
        "Wine": [
          "Standard Category"
        ],
        "Yogurt": [
          "Standard Category"
        ],
        "Yogurt Shop By Brand": [
          "Standard Category"
        ]
      }
    },
    "pageID": {
      "dependsOn": [
        "pageName"
      ],
      "options": {
        " Butter & Margarine": [
          "6520421011"
        ],
        " P&G Jan 22 BXGY Omni Scale Event": [
          "30004818"
        ],
        "1% Milk": [
          "30001568"
        ],
        "2% Milk": [
          "30001567"
        ],
        "2021 BTS/OTC store INPUTS": [
          "30001577"
        ],
        "2021 Cold & Flu": [
          "20190832"
        ],
        "2021 Fall Flavors (INPUTS ONLY)": [
          "30001811"
        ],
        "2021 New Year's Eve": [
          "30000494"
        ],
        "2022 Beauty & Personal Care Sale": [
          "30004643"
        ],
        "2022 Cinco de Mayo": [
          "30002419"
        ],
        "2022 Cinco de Mayo INPUTS": [
          "30002419"
        ],
        "2022 Cold & Flu INPUTS": [
          "30004136"
        ],
        "2022 Cold and Flu INPUTS": [
          "30002421"
        ],
        "2022 Earth Day INPUTS": [
          "30002423"
        ],
        "2022 Easter": [
          "30003055"
        ],
        "2022 Easter Candy & Choc": [
          "30003193"
        ],
        "2022 Easter INPUTS": [
          "30002402"
        ],
        "2022 Fall Flavors INPUTS": [
          "30002413"
        ],
        "2022 Father's Day INPUTS": [
          "30002420"
        ],
        "2022 Feb Wellness Sale": [
          "30001761"
        ],
        "2022 Halloween INPUTS": [
          "30002408"
        ],
        "2022 Holiday Gifting": [
          "30004644"
        ],
        "2022 Holiday Shop INPUTS": [
          "30002407"
        ],
        "2022 March Baby Sale": [
          "30002412"
        ],
        "2022 March Baby Sale INPUTS": [
          "30002412"
        ],
        "2022 March Madness": [
          "30002418"
        ],
        "2022 March Madness INPUTS": [
          "30002418"
        ],
        "2022 Mother's Day INPUTS": [
          "30002403"
        ],
        "2022 New Years Eve": [
          "30002422"
        ],
        "2022 New Years Eve INPUTS": [
          "30002422"
        ],
        "2022 NYNY": [
          "30002772"
        ],
        "2022 NYNY INPUTS": [
          "n/a"
        ],
        "2022 Prime Day": [
          "30002404"
        ],
        "2022 Prime Day INPUTS": [
          "30002404"
        ],
        "2022 Ready for School INPUTS": [
          "30002411"
        ],
        "2022 September Baby Sale INPUTS": [
          "30002414"
        ],
        "2022 Spring Cleaning INPUTS": [
          "30002409"
        ],
        "2022 St. Patrick's Day": [
          "30002417"
        ],
        "2022 St. Patrick's Day INPUTS": [
          "30002417"
        ],
        "2022 Summer Grilling": [
          "30002410"
        ],
        "2022 Summer Grilling Inputs": [
          "30002410"
        ],
        "2022 Super Bowl": [
          "30002416"
        ],
        "2022 Super Bowl INPUTS": [
          "30002416"
        ],
        "2022 Thanksgiving INPUTS": [
          "30002406"
        ],
        "2022 Valentine's day": [
          "30002401"
        ],
        "2022 Valentine's Day INPUTS": [
          "30002401"
        ],
        "2022 World Cup": [
          "30004739"
        ],
        "2023 Beauty & Personal Care Sale": [
          "30006055"
        ],
        "2023 Cinco De Mayo": [
          "30005540"
        ],
        "2023 Cold and flu INPUTS": [
          "00000000"
        ],
        "2023 Easter Inputs": [
          "30005261"
        ],
        "2023 Fall Flavors": [
          "30005874"
        ],
        "2023 Fall flavors INPUTS": [
          "30005874"
        ],
        "2023 Father's Day": [
          "30005525"
        ],
        "2023 Father's Day INPUTS": [
          "30005525"
        ],
        "2023 February Wellness Sale": [
          "30005535"
        ],
        "2023 Halloween INPUTS": [
          "30006303"
        ],
        "2023 Hanukkah INPUTS": [
          "00000000"
        ],
        "2023 Hispanic Heritage Month + Día de los Muertos INPUTS": [
          "00000000"
        ],
        "2023 Holiday shop INPUTS": [
          "00000000"
        ],
        "2023 March Baby Sale": [
          "30005583"
        ],
        "2023 March Madness": [
          "30005458"
        ],
        "2023 March Madness INPUTS": [
        ],
        "2023 Memorial Day": [
          "30006146"
        ],
        "2023 Mother's Day INPUTS": [
          "00000000"
        ],
        "2023 New Year, New You": [
          "30002415"
        ],
        "2023 New Year, New You INPUTS": [
          "30002415"
        ],
        "2023 NYE INPUTS": [
          "00000000"
        ],
        "2023 NYNY INPUTS": [
          "00000000"
        ],
        "2023 Panini Shop": [
          "30005599"
        ],
        "2023 Passover Inputs": [
          "30005323"
        ],
        "2023 Pride Month INPUTS": [
          "00000000"
        ],
        "2023 Prime Day": [
          "30005599"
        ],
        "2023 Prime Day INPUTS": [
          "30005600"
        ],
        "2023 ready for school/OTC INPUTS": [
          "00000000"
        ],
        "2023 September baby sale INPUTS": [
          "30006510"
        ],
        "2023 Spring Cleaning": [
          "30005380"
        ],
        "2023 St. Patrick's Day": [
          "30005257"
        ],
        "2023 St. Patrick's Day Inputs": [
          "30005257"
        ],
        "2023 Summer Grilling": [
          "30005524"
        ],
        "2023 Summer Grilling INPUTS": [
          "30005524"
        ],
        "2023 Thanksgiving INPUTS": [
          "00000000"
        ],
        "2023 Valentine's Day INPUTS": [
          "30004801"
        ],
        "ABI": [
          "30005416"
        ],
        "AFPB New & Seasonal": [
          "30006841"
        ],
        "Alcohol": [
          "2983371011"
        ],
        "Alexa Nutrition Discovery": [
          "30000795"
        ],
        "All Alcohol": [
          "2983371011"
        ],
        "Allergy": [
          "20190810"
        ],
        "Amazon Brands (Dairy)": [
          "30001660"
        ],
        "Amazon Brands (Deli & Prepared)": [
          "30001672"
        ],
        "Aplenty": [
          "30001033"
        ],
        "Art supplies": [
          "30003792"
        ],
        "Artisan Cheese": [
          "17701362011"
        ],
        "Artisan Cheese - Cheese Board": [
          "30000187"
        ],
        "Artisan Cheese - Main": [
          "20190886"
        ],
        "Artisan Cheese - Occasion": [
          "20190888"
        ],
        "Artisan Cheese - Pairings": [
          "20190887"
        ],
        "Artisan Cheese Buying Guide": [
          "20190886"
        ],
        "Arts and Crafts Supplies": [
          "166057011"
        ],
        "Asian Flavors": [
          "30001754"
        ],
        "At-home beauty": [
          "30000496"
        ],
        "August Wellness Sale": [
          "30004163"
        ],
        "Baby": [
          "10787321"
        ],
        "Baby and Kids' Food": [
          "30003205"
        ],
        "Baby Diaper and Wipes": [
          "16014841"
        ],
        "Baby Health Care": [
          "16025501",
          "30000408"
        ],
        "Baby PC": [
          "16025511"
        ],
        "Baby Personal Care": [
          "30000409"
        ],
        "Baby Sale": [
          "",
          "20190579"
        ],
        "Baby Skin Care": [
          "30000409"
        ],
        "Back to Sports": [
          "20190781"
        ],
        "Bacon & Sausage": [
          "30005690"
        ],
        "Baked Goods and Sweet Treats": [
          "20190742"
        ],
        "Bakery Amazon Brands": [
          "20190757"
        ],
        "Bakery Breakfast": [
          "20190745"
        ],
        "Bakery Categories": [
          "30001883"
        ],
        "Bakery Shop by Brand": [
          "30001093"
        ],
        "Baking": [
          "30003844"
        ],
        "Baking at Home": [
          "30003357"
        ],
        "Baking Mixes": [
          "11713205011"
        ],
        "Barbeque and pest control": [
          "30003789"
        ],
        "Beauty": [
          "11058281"
        ],
        "Beauty & Personal Care": [
          "3760911"
        ],
        "Bed & Bath": [
          "1057792"
        ],
        "Beef": [
          "371470011"
        ],
        "Beef Buying Guide": [
          "30000369"
        ],
        "Beer": [
          "2983385011"
        ],
        "Berries & Grapes": [
          "30003204"
        ],
        "Beverages": [
          "16310231"
        ],
        "BHM 2022": [
          "30002478"
        ],
        "Black Friday/Cyber Monday": [
          ""
        ],
        "Block Cheese": [
          "30001447"
        ],
        "Blue Moon": [
          "30000451"
        ],
        "BPC Promo": [
          "20190854"
        ],
        "Bread": [
          "20190744"
        ],
        "Breads & Bakery": [
          "16318751"
        ],
        "Breakfast & Cereal Bars": [
          "387559011"
        ],
        "Breakfast Foods": [
          "16310251"
        ],
        "Brew a cup of tea": [
          "30000912"
        ],
        "Buy 2 Chips, Get 1 Dip": [
          "30004690"
        ],
        "BYG": [
          "BYG"
        ],
        "Cage-free (Eggs)": [
          "30001658"
        ],
        "Candy & Chocolate": [
          "16322461"
        ],
        "Canned & Packaged": [
          "6464939011"
        ],
        "Caramel": [
          "30002654"
        ],
        "Carbonated Soft Drinks": [
          "30002007"
        ],
        "Cat Food": [
          "30003784"
        ],
        "Cat Grooming & Toys": [
          "30004112"
        ],
        "Cat Litter & Supplies": [
          "30003787"
        ],
        "Cat Supplies": [
          "2975241011"
        ],
        "Cat Treats": [
          "30003785"
        ],
        "CBI - Beer": [
          "30005832"
        ],
        "CBI - Wine": [
          "30004568"
        ],
        "Cereal": [
          "16318891"
        ],
        "Checkout": [
          "n/a"
        ],
        "Cheese": [
          "6520456011"
        ],
        "Cheese by Occasion": [
          "20190888"
        ],
        "Chicken": [
          "2256156011"
        ],
        "Chips": [
          "19820244011"
        ],
        "Chobani BXGY": [
          "20190803"
        ],
        "Chobani Oat Barista FF": [
          "30000913"
        ],
        "Chocolate": [
          "30002186"
        ],
        "Cinco de Mayo": [
          ""
        ],
        "Cinco de Mayo 2021": [
          "30001056"
        ],
        "Cleaning": [
          "15342821"
        ],
        "Clothing & Accessories": [
          "7141123011"
        ],
        "Cocktail Recipes": [
          "30004320"
        ],
        "Coffee & Tea": [
          "19794294011"
        ],
        "Coffee at Home": [
          "30002004"
        ],
        "Coffee_CAH": [
          "30002005"
        ],
        "Cold & Flu": [
          "",
          "20190832"
        ],
        "Cold & Flu 2021-22 Inputs": [
          "n/a"
        ],
        "Condiments & Dressings": [
          "16320321"
        ],
        "Cooking & Baking": [
          "16310221"
        ],
        "Corona Landing Page": [
          "30001555"
        ],
        "Cough, Cold, and Flu": [
          "3761141"
        ],
        "Crackers": [
          "16322781"
        ],
        "Cream": [
          "30004205"
        ],
        "Cream cheese": [
          "30001669"
        ],
        "Creed 3": [
          "30005592"
        ],
        "Culinary": [
          "30003788"
        ],
        "Cursive Wine": [
          "20190733"
        ],
        "Cursive Wine - NBC": [
          "30000004"
        ],
        "Dairy & Eggs": [
          "371460011"
        ],
        "Dairy categories": [
          "30001880"
        ],
        "Dairy Hosting Page": [
          "30004478 "
        ],
        "Danone - Coffee & Creamer": [
          "30000343"
        ],
        "Danone - Horizon Organic": [
          "30000318"
        ],
        "Danone - Plant-Based": [
          "30000319"
        ],
        "Danone - Yogurt": [
          "30000308"
        ],
        "Deals": [
          "Deals"
        ],
        "Deals - Beverage": [
          "30000918"
        ],
        "Deals - Food": [
          "30000917"
        ],
        "Deals - Household & Personal Care": [
          "30000919"
        ],
        "Deli & Prepared": [
          "18773724011"
        ],
        "Deli & Prepared Amazon Brands": [
          "20190800"
        ],
        "Deli Dips, Salsas & Spreads": [
          "30004164"
        ],
        "Deli Meats": [
          "30003717"
        ],
        "Deli Meats & Cheeses": [
          "18773725011"
        ],
        "Deli/Prep Categories": [
          "30001882"
        ],
        "Deodorants and Antiperspirants": [
          "3778451"
        ],
        "Dia de los Muertos 2021": [
          "30002199"
        ],
        "Diapering and Training": [
        ],
        "Dietz & Watson Brand Store": [
          "30002087"
        ],
        "Digestion and Nausea": [
          "3762051"
        ],
        "Dips and Spreads": [
          "20190640"
        ],
        "Dips, Salsas & Spreads": [
        ],
        "Dishwashing": [
          "15693761"
        ],
        "Dog Food": [
          "30003781"
        ],
        "Dog Grooming & Supplies": [
          "30003786"
        ],
        "Dog Supplies": [
          "2975312011"
        ],
        "Dog Toys": [
          "2975413011"
        ],
        "Dog Treats": [
          "30003783"
        ],
        "Dozen (Eggs)": [
          "30001657"
        ],
        "Drinks for any occasion": [
          "30000198"
        ],
        "Drinks/Kefir Yogurt": [
          "30003521"
        ],
        "Easter": [
          ""
        ],
        "Easter 2021": [
          "30000791"
        ],
        "Easter Brunch": [
          "30003082"
        ],
        "Easy At Home with Andy Baraghani": [
          "30003360"
        ],
        "Easy At Home with Daphne Oz": [
          "30003361"
        ],
        "Eat In": [
          "",
          "20190583"
        ],
        "Eat in- Meal ideas": [
          "30000854"
        ],
        "Eat in-Main": [
          "30000852"
        ],
        "Eat in-Ready to eat/heat": [
          "30000853"
        ],
        "Egg Buying Guide": [
          "30003132"
        ],
        "Egg pack size": [
          "30001657"
        ],
        "Egg Whites/Substitutes": [
          "30004142"
        ],
        "Eggnog & Alcohol": [
          "30000181"
        ],
        "Eggs": [
          "371464011"
        ],
        "Electronics": [
          "172282"
        ],
        "Energy Drinks": [
          "30003862"
        ],
        "Fall Flavors 2021": [
          "30001811"
        ],
        "Fall Store": [
          "20190833"
        ],
        "Fat-free Milk": [
          "30001569"
        ],
        "Father's Day": [
          ""
        ],
        "Father's Day 2021 Inputs": [
          "30001064"
        ],
        "Feeding and Formula": [
          "16323111"
        ],
        "Feminine Care": [
          "3779501"
        ],
        "Flatbreads and Tortillas": [
          "20190749"
        ],
        "Flavored milk": [
          "30001570"
        ],
        "Food for Preschoolers and Big Kids": [
          "30003329"
        ],
        "Food storage": [
          "30003790"
        ],
        "Fragrance-free Baby Products": [
          "30000167"
        ],
        "Fresh Bakery": [
          "30004441"
        ],
        "Fresh brand page": [
          "20190627"
        ],
        "Fridge Night": [
          "30003622"
        ],
        "Frozen": [
          "6459122011"
        ],
        "Frozen Appetizers and Snacks": [
          "6459123011"
        ],
        "Frozen Breakfast Foods": [
          "6459139011"
        ],
        "Frozen Desserts": [
          "30005905"
        ],
        "Frozen Fruit": [
          "6459159011"
        ],
        "Frozen Fruit & Juice": [
          "30003250"
        ],
        "Frozen Ice Cream": [
          "6459171011"
        ],
        "Frozen Meals and Entrees": [
          "6459203011"
        ],
        "Frozen Meats": [
          "6459214011"
        ],
        "Frozen Novelties": [
          "30005904"
        ],
        "Frozen Pizza": [
          "30001696",
          "6459122011"
        ],
        "Frozen Potatoes & Onions": [
          "30003247"
        ],
        "Frozen Seafood": [
          "6459241011"
        ],
        "Frozen Vegetables": [
          "6459248011"
        ],
        "Fruit Cups and Applesauce": [
          "20190649"
        ],
        "Fruits": [
          "16318981"
        ],
        "Gifting": [
          "20190249"
        ],
        "Gluten-Free": [
          "20190192"
        ],
        "GP E-mail Landing Page": [
          "30002731"
        ],
        "Granola": [
          "20190698"
        ],
        "Greek/Icelandic Yogurt": [
          "30003520"
        ],
        "Guilt-free Snacking": [
          "30003780"
        ],
        "Gum & Mints": [
          "30002656"
        ],
        "Gummy, Soft & Chewy": [
          "30002646"
        ],
        "Hair Care": [
          "11057241"
        ],
        "Hallmark Holiday": [
          "20190850"
        ],
        "Halloween Savings": [
          "30004974"
        ],
        "Halloween Store": [
          "20190848"
        ],
        "Halloween Store 2021": [
          "30001822"
        ],
        "Halloween/Fall": [
          ""
        ],
        "Happy School Year": [
          ""
        ],
        "Hard Candy": [
          "30002655"
        ],
        "Hard-boiled": [
          "30003945"
        ],
        "Health & Safety": [
          "30000436"
        ],
        "Health & Wellness": [
          "3760941"
        ],
        "Health and household from our brands": [
          "30005228"
        ],
        "Health Care - PL ": [
          "30001226"
        ],
        "Heavy Lifting Store": [
          "20190873"
        ],
        "Herbs and Spices": [
          "16310281"
        ],
        "Holiday Alcohol": [
          "30000396"
        ],
        "Holiday Candy & Chocolate": [
          "30002677"
        ],
        "Holiday Shop": [
          "",
          "30000001"
        ],
        "Holiday Shop 2021": [
          "30002149"
        ],
        "Holiday Shop 2021 (INPUTS ONLY)": [
          "30002149"
        ],
        "Holiday Shop 2022": [
          "30004259\t"
        ],
        "Home and Kitchen": [
          "1055398"
        ],
        "Home Storage and Organization": [
          "3610841"
        ],
        "Household": [
          "15342811"
        ],
        "Household Coupons": [
          "30000166"
        ],
        "Huggies Super Bowl": [
          "30000869"
        ],
        "Hypoallergenic Baby Products": [
          "30000163"
        ],
        "Ice Cream": [
          "30005903",
          "6459171011"
        ],
        "Ice Cream & Desserts": [
          "30005752"
        ],
        "Ice cream by brand": [
          "30005906"
        ],
        "Ice cream by diet": [
          "30005908"
        ],
        "Ice cream by flavor": [
          "30005907"
        ],
        "Ice Cream by Size": [
          "30005993"
        ],
        "ILM": [
          "ILM"
        ],
        "In-Store": [
          "In-Store"
        ],
        "Indian Flavors": [
          "30001756"
        ],
        "Indulgent Yogurt": [
          "30003526"
        ],
        "Infant Formula": [
          "30003324"
        ],
        "Ingredients for Pizza Night": [
          "30000848"
        ],
        "Instore Exclusives": [
          "30005789"
        ],
        "International Foods": [
          "20190687"
        ],
        "Italian Flavors": [
          "30001757"
        ],
        "Jack Ryan": [
          "30004767"
        ],
        "Jerky & Pork Rinds": [
          "20190638",
          "20190698"
        ],
        "Juice": [
          "16318171"
        ],
        "Juicing": [
          "30001506"
        ],
        "Juicing Buying Guide": [
          "30001506"
        ],
        "Kentucky Derby 2021": [
          "30001274"
        ],
        "Keto": [
          "30000052"
        ],
        "Kids Snacks": [
          "30003779"
        ],
        "Kids/Baby Yogurt": [
          "30003523"
        ],
        "Kitchen & Dining": [
          "284507"
        ],
        "Kosher Groceries": [
          "30003356"
        ],
        "Lactose-free Milk": [
          "30001571"
        ],
        "Latin Flavors": [
          "30001755"
        ],
        "Laundry": [
          "15356111"
        ],
        "Low Sugar & Sugar Free": [
          "30002657"
        ],
        "Low/No Sugar Yogurt": [
          "30003524"
        ],
        "Macaroni and Cheese": [
          "20190561"
        ],
        "Make A Mimosa": [
          "30001005"
        ],
        "Make a Mimosa Traffic": [
          "30001078"
        ],
        "March Madness": [
          ""
        ],
        "March Madness 2021": [
          "30000866"
        ],
        "Margarine": [
          "30003848"
        ],
        "May Pet Sale": [
          "30003206"
        ],
        "Meat & Seafood": [
          "371469011"
        ],
        "Meat Subsitutes": [
          "6518859011"
        ],
        "Memorial Day 2023": [
          "30006146"
        ],
        "Milk & Cream": [
          "6520435011"
        ],
        "Milk&Creamer_CAH": [
          "30002006"
        ],
        "Mix Ins_CAH": [
          "30002008"
        ],
        "Modelo Landing Page": [
          "30001556"
        ],
        "MolsonCoors": [
          "30005393"
        ],
        "More from Fresh": [
          "20190180"
        ],
        "Mother's Day": [
          ""
        ],
        "Mother's Day 2021": [
          "30000864"
        ],
        "Movie Night": [
          "30005788"
        ],
        "Movie Night In": [
          "30000486"
        ],
        "National Dairy Month": [
          ""
        ],
        "National Wellness Month": [
          "20190784"
        ],
        "New Year's Eve 2020": [
          "30000494"
        ],
        "New Year, New You": [
          ""
        ],
        "New Year, New You 2021": [
          "30000128"
        ],
        "Non-Dairy": [
          "20190756"
        ],
        "Non-Dairy (DCE)": [
          "20190756"
        ],
        "Non-Dairy Beverages": [
          "119343011"
        ],
        "Non-dairy butter": [
          "30003849"
        ],
        "Non-Food Holiday Gifting": [
          "30002751"
        ],
        "Nuts and Seeds": [
          "16322881"
        ],
        "Nuts and Trail Mix": [
          "20190637"
        ],
        "Oatmeal": [
          "20190699"
        ],
        "Office supplies": [
          "30003794"
        ],
        "Oral Care": [
          "10079992011"
        ],
        "Organic": [
          "20190336"
        ],
        "Organic Baby Products": [
          "30000164"
        ],
        "Organic Produce": [
          "30003202"
        ],
        "Organic Valley Brand Store": [
          "20190884"
        ],
        "OTC Landing": [
          "20190774"
        ],
        "Other": [
          "n/a"
        ],
        "Our brands eggs": [
          "30003946"
        ],
        "Our Brands page": [
          "20190596"
        ],
        "P&G BXGY": [
          "30003853"
        ],
        "P&G Jan 22 BXGY Omni Scale Event": [
          "30004818"
        ],
        "P&G Jan 23 BXGY Omni Event": [
          "30004818"
        ],
        "Packaged Meals": [
          "6499407011"
        ],
        "Packaged Produce": [
          "6507187011"
        ],
        "Pain Relief": [
          "3763561"
        ],
        "Paleo": [
          "30000051"
        ],
        "Pantry Staples": [
          "18787303011"
        ],
        "Paper & Plastics": [
          "15342841"
        ],
        "Pasta & Noodles": [
          "6502933011"
        ],
        "PD2022": [
          "XYZ"
        ],
        "PepsiCo Super Bowl 2021": [
          "30000673"
        ],
        "Perfect Pairings": [
          "20190887"
        ],
        "Perishables Categories": [
          "30002262"
        ],
        "Personal Care": [
          "3777891"
        ],
        "Personal Care Deals Page": [
          "20190893"
        ],
        "Pest Control and Cleaning Supplies": [
          "30004276"
        ],
        "Pet Food & Supplies": [
          "30003782"
        ],
        "Pet Sale": [
          "30001227"
        ],
        "Pet Supplies": [
          "2619533011"
        ],
        "Plant-Based": [
          "20190337"
        ],
        "Plant-based eggs": [
          "30003948"
        ],
        "Plant-based Lifestyles": [
          "20190337"
        ],
        "Plant-Based Yogurt": [
          "20190811"
        ],
        "Popcorn and Pretzels": [
          "20190641"
        ],
        "Pork": [
          "6519153011"
        ],
        "Powdered Drink Mixes": [
          "15525241011"
        ],
        "Pregnancy for Mom": [
          "30003330"
        ],
        "Premium butter": [
          "30003847"
        ],
        "Prepared Foods": [
          "30000395"
        ],
        "Prepared foods": [
          "30000395"
        ],
        "Prime Day": [
          ""
        ],
        "Probiotic Yogurt": [
          "30003525"
        ],
        "Produce": [
          "6506977011"
        ],
        "Pudding and Gelatin": [
          "20190648"
        ],
        "Recipe": [
          ""
        ],
        "Recipes": [
          "Recipes"
        ],
        "Red Wine": [
          "30004202"
        ],
        "Refrigerated Dough": [
          "20190747"
        ],
        "RFS Food": [
          "20190772"
        ],
        "RFS HPC": [
          "20190773"
        ],
        "RFS Landing": [
          "20190771"
        ],
        "RFS-Food 2021": [
          "30001797"
        ],
        "RFS-HPC 2021": [
          "30001796"
        ],
        "RFS-Main": [
          "30001795"
        ],
        "Rolls and Buns": [
          "20190746"
        ],
        "Root Vegetable Buying Guide": [
          "30001504"
        ],
        "Root Vegetables": [
          "30001504"
        ],
        "S'mores for Summer": [
          "30003733"
        ],
        "Salad store": [
          " 30001271"
        ],
        "Sandwich": [
          "20190764"
        ],
        "Sauces and Marinades": [
          "6502765011"
        ],
        "Save on P&G products": [
          "30004818"
        ],
        "School supplies": [
          "30003793"
        ],
        "SCJ Summer Store": [
          "30001426"
        ],
        "SCJSpringCleaning": [
          "30001155"
        ],
        "Seafood": [
          "371477011"
        ],
        "Search": [
          "Search"
        ],
        "Search Test": [
          "1234567890"
        ],
        "Seasonal Deals": [
          "30005876"
        ],
        "Seasonal Produce": [
          "30003203"
        ],
        "Self-Care Sale": [
          "30002260"
        ],
        "September Baby Sale 2021": [
          "30001783"
        ],
        "Seventh Generation": [
          "30006044"
        ],
        "SG Favorites": [
          "874897451"
        ],
        "Shipping Cutoff": [
          ""
        ],
        "Shop by brand (Yogurt)": [
          "20190822"
        ],
        "Shop by Recipes for Kids": [
          "30003331"
        ],
        "Shredded Cheese": [
          "30001445"
        ],
        "Skin Care": [
          "11060451"
        ],
        "Sleep Aid": [
          "20190812"
        ],
        "Sliced Cheese": [
          "30001444"
        ],
        "Smoothie Store": [
          "30001304"
        ],
        "Snack and Stream": [
          "30004180"
        ],
        "Snack Bars": [
          "20190639"
        ],
        "Snack Cakes and Cookies": [
          "20190643"
        ],
        "Snacking cheese": [
          "30002034"
        ],
        "Snacks": [
          "16322721"
        ],
        "Snacks (Dairy)": [
          "30001656"
        ],
        "Snacks and Dried Fruit": [
          "20190642"
        ],
        "SNAP Baltimore": [
          "30004424"
        ],
        "SNAP EBT": [
          "20190785"
        ],
        "SNAP Washington": [
          "30004423"
        ],
        "Soap and sanitizer": [
          "30000436"
        ],
        "Soup, Stocks, and Broths": [
          "16319621"
        ],
        "Sparkling water": [
          "30003249"
        ],
        "Sparkling Wine": [
          "30004204"
        ],
        "Specialty Diets": [
          "30000008"
        ],
        "Spirits": [
          "2983387011"
        ],
        "Sports drinks": [
          "30003248"
        ],
        "Sports Nutrition": [
          "6973663011"
        ],
        "Spring Cleaning": [
          ""
        ],
        "Spring Cleaning 2021": [
          "30000928"
        ],
        "Spring Cleaning 2022": [
          "30002409"
        ],
        "St. Patrick's Day": [
          ""
        ],
        "St. Patrick's Day 2021": [
          "30000935"
        ],
        "Stage 1 Baby Food": [
          "30003325"
        ],
        "Stage 2 Baby Food": [
          "30003326"
        ],
        "Stage 3 Baby Food": [
          "30003327"
        ],
        "Stage 4 Food for Toddlers": [
          "30003328"
        ],
        "Stick (Butter)": [
          "30001659"
        ],
        "Storefront": [
          "FMCDummyValue"
        ],
        "Sulfate and Paraben Free Baby Products": [
          "30000168"
        ],
        "Summer Alcohol Program": [
          "30001551"
        ],
        "Summer Grilling": [
          ""
        ],
        "Summer Grilling 2021": [
          "30001063"
        ],
        "Summer Store 2021 Inputs": [
          "30001063"
        ],
        "Summer Vacation Store 2021 Inputs": [
          "30001173"
        ],
        "Summer Vacation Store 2022": [
          "30003350"
        ],
        "Sundae Store": [
          "30003841"
        ],
        "Super Bowl": [
          ""
        ],
        "Super Bowl 2021": [
          "30000455"
        ],
        "Super Bowl 2023": [
          "30005044"
        ],
        "Super Bowl 2023 Inputs": [
          "30005044"
        ],
        "Sustainability": [
          "30002146"
        ],
        "Svedka Landing Page": [
          "30001564"
        ],
        "Sweets Store": [
          "30003819"
        ],
        "Tea": [
          "16318401"
        ],
        "Thanksgiving": [
          ""
        ],
        "Thanksgiving 20 - Apps": [
          "20190824"
        ],
        "Thanksgiving 20 - Bev": [
          "20190828"
        ],
        "Thanksgiving 20 - Clean-up": [
          "20190829"
        ],
        "Thanksgiving 20 - Dessert": [
          "20190827"
        ],
        "Thanksgiving 20 - Main": [
          "20190823"
        ],
        "Thanksgiving 20 - Sides": [
          "20190826"
        ],
        "Thanksgiving 20 - Turkey": [
          "20190825"
        ],
        "Thanksgiving 2021 Inputs": [
          "30002150"
        ],
        "Thanksgiving 2022": [
          "30004258"
        ],
        "Thanksgiving 2023 - Beverages": [
          "30006059"
        ],
        "Thanksgiving 2023 - Clean-up": [
          "30006060"
        ],
        "Thanksgiving 2023 - Desserts": [
          "30006058"
        ],
        "Thanksgiving 2023 - Main Course": [
          "30006056"
        ],
        "Thanksgiving 2023 - Sides": [
          "30006057 "
        ],
        "Thanksgiving 2023 - Starters": [
          "30006011"
        ],
        "Thanksgiving 21 - Beverages": [
          "30002256"
        ],
        "Thanksgiving 21 - Clean Up": [
          "30002258"
        ],
        "Thanksgiving 21 - Desserts": [
          "30002257"
        ],
        "Thanksgiving 21 - Main": [
          "30002252"
        ],
        "Thanksgiving 21 - Sides": [
          "30002255"
        ],
        "Thanksgiving 21 - Snacks and Apps": [
          "30002254"
        ],
        "Thanksgiving 21 - Turkey": [
          "30002253"
        ],
        "Thanksgiving 22 - Apps": [
          "30004525"
        ],
        "Thanksgiving 22 - Bev": [
          "30004529"
        ],
        "Thanksgiving 22 - Clean-up": [
          "30004530"
        ],
        "Thanksgiving 22 - Desserts": [
          "30004528",
          "30004529"
        ],
        "Thanksgiving 22 - Sides": [
          "30004527"
        ],
        "Thanksgiving 22 - Turkey": [
          "30004526"
        ],
        "The Peripheral": [
          "30004766"
        ],
        "Toaster Pastries": [
          "20190697"
        ],
        "Toys & Games": [
          "165793011"
        ],
        "Try a new beverage": [
          "30001057"
        ],
        "Tub butter": [
          "30003846"
        ],
        "Valentine's Day": [
          ""
        ],
        "Valentine's Day 2021": [
          "30000255"
        ],
        "Valentine's Day 2022": [
          "30002960"
        ],
        "Valentine's Day Sweets": [
          "30002968"
        ],
        "Vegetables": [
          "16319281",
          "72901703"
        ],
        "Vitamins": [
          "3764441"
        ],
        "Warm Drink Store": [
          "20190858"
        ],
        "Water": [
          "16318651"
        ],
        "Wellness": [
          "20190784"
        ],
        "Wellness Sale - 2021": [
          "30001761"
        ],
        "White Wine": [
          "30004203"
        ],
        "Whitney/Zak Test Page": [
          "999999999999"
        ],
        "Whole Milk": [
          "30001566"
        ],
        "Wine": [
          "2983386011"
        ],
        "Wine Promo": [
          "30006728"
        ],
        "World Flavors": [
          "30001704"
        ],
        "Wraps and bags": [
          "30003791"
        ],
        "Yogurt": [
          "6520506011"
        ],
        "Yogurt by Flavor/Type": [
          "30003518"
        ],
        "Yogurt by Milk Fat Content": [
          "30003527"
        ],
        "Yogurt by Size": [
          "30003519"
        ],
        "Yogurt Shop By Brand": [
          "20190822"
        ]
      }
    },
    "pageName": {
      "dependsOn": [
        "pageType"
      ],
      "options": {
        "Category": [
          " Butter & Margarine",
          "1% Milk",
          "2% Milk",
          "Alcohol",
          "Art supplies",
          "Artisan Cheese",
          "Arts and Crafts Supplies",
          "Baby",
          "Baby Diaper and Wipes",
          "Baby Health Care",
          "Baby PC",
          "Baby Personal Care",
          "Baby Skin Care",
          "Bacon & Sausage",
          "Baked Goods and Sweet Treats",
          "Bakery Amazon Brands",
          "Bakery Breakfast",
          "Bakery Shop by Brand",
          "Baking",
          "Baking Mixes",
          "Barbeque and pest control",
          "Beauty",
          "Beauty & Personal Care",
          "Bed & Bath",
          "Beef",
          "Beef Buying Guide",
          "Beer",
          "Berries & Grapes",
          "Beverages",
          "Block Cheese",
          "Bread",
          "Breads & Bakery",
          "Breakfast & Cereal Bars",
          "Breakfast Foods",
          "Candy & Chocolate",
          "Canned & Packaged",
          "Carbonated Soft Drinks",
          "Cat Food",
          "Cat Grooming & Toys",
          "Cat Litter & Supplies",
          "Cat Supplies",
          "Cat Treats",
          "Cereal",
          "Cheese",
          "Chicken",
          "Chips",
          "Cleaning",
          "Clothing & Accessories",
          "Coffee & Tea",
          "Condiments & Dressings",
          "Cooking & Baking",
          "Cough, Cold, and Flu",
          "Crackers",
          "Cream cheese",
          "Culinary",
          "Dairy & Eggs",
          "Deli & Prepared",
          "Deli & Prepared Amazon Brands",
          "Deli Meats",
          "Deli Meats & Cheeses",
          "Deodorants and Antiperspirants",
          "Digestion and Nausea",
          "Dips and Spreads",
          "Dishwashing",
          "Dog Food",
          "Dog Grooming & Supplies",
          "Dog Supplies",
          "Dog Toys",
          "Dog Treats",
          "Eat in- Meal ideas",
          "Eat in-Main",
          "Eat in-Ready to eat/heat",
          "Eggs",
          "Electronics",
          "Energy Drinks",
          "Fat-free Milk",
          "Feeding and Formula",
          "Feminine Care",
          "Flatbreads and Tortillas",
          "Flavored milk",
          "Food storage",
          "Fragrance-free Baby Products",
          "Frozen",
          "Frozen Appetizers and Snacks",
          "Frozen Breakfast Foods",
          "Frozen Desserts",
          "Frozen Fruit & Juice",
          "Frozen Ice Cream",
          "Frozen Meals and Entrees",
          "Frozen Meats",
          "Frozen Novelties",
          "Frozen Pizza",
          "Frozen Potatoes & Onions",
          "Frozen Seafood",
          "Frozen Vegetables",
          "Fruit Cups and Applesauce",
          "Fruits",
          "Gifting",
          "Granola",
          "Hair Care",
          "Health & Wellness",
          "Health and household from our brands",
          "Herbs and Spices",
          "Home Storage and Organization",
          "Home and Kitchen",
          "Household",
          "Hypoallergenic Baby Products",
          "Ice Cream",
          "Ice Cream & Desserts",
          "Ice Cream by Size",
          "Ice cream by brand",
          "Ice cream by diet",
          "Ice cream by flavor",
          "Infant Formula",
          "International Foods",
          "Jerky & Pork Rinds",
          "Juice",
          "Juicing",
          "Kitchen & Dining",
          "Lactose-free Milk",
          "Laundry",
          "Macaroni and Cheese",
          "Margarine",
          "May Pet Sale",
          "Meat & Seafood",
          "Meat Subsitutes",
          "Milk & Cream",
          "Non-Dairy",
          "Non-Dairy Beverages",
          "Non-Food",
          "Non-dairy butter",
          "Nuts and Seeds",
          "Nuts and Trail Mix",
          "Oatmeal",
          "Office Supplies",
          "Office supplies",
          "Oral Care",
          "Organic Baby Products",
          "Organic Produce",
          "P&G BXGY",
          "Packaged Meals",
          "Packaged Produce",
          "Pain Relief",
          "Pantry Staples",
          "Paper",
          "Paper & Plastics",
          "Pasta & Noodles",
          "Personal Care",
          "Pet Food & Supplies",
          "Pet Supplies",
          "Plant-Based Yogurt",
          "Popcorn and Pretzels",
          "Pork",
          "Powdered Drink Mixes",
          "Premium butter",
          "Prepared foods",
          "Produce",
          "Pudding and Gelatin",
          "Red Wine",
          "Refrigerated Dough",
          "Rolls and Buns",
          "Root Vegetables",
          "Sauces and Marinades",
          "School supplies",
          "Seafood",
          "Seasonal Produce",
          "Shredded Cheese",
          "Skin Care",
          "Sleep Aid",
          "Sliced Cheese",
          "Sliced Cheese",
          "Snack Bars",
          "Snack Cakes and Cookies",
          "Snacks",
          "Snacks and Dried Fruit",
          "Soap and sanitizer",
          "Soup, Stocks, and Broths",
          "Sparkling Wine",
          "Sparkling water",
          "Specialty Diets",
          "Spirits",
          "Spirits",
          "Sports Nutrition",
          "Sports drinks",
          "Stage 1 Baby Food",
          "Stage 2 Baby Food",
          "Stage 3 Baby Food",
          "Stage 4 Food for Toddlers",
          "Sulfate and Paraben Free Baby Products",
          "Tea",
          "Toaster Pastries",
          "Toys & Games",
          "Tub butter",
          "Vegetables",
          "Vitamins",
          "Water",
          "Wellness",
          "White Wine",
          "Whole Milk",
          "Wine",
          "World Flavors",
          "Wraps and bags",
          "Yogurt",
          "Yogurt Shop By Brand",
          "energy drinks"
        ],
        "Checkout": [
          "BYG",
          "Checkout"
        ],
        "Deals": [
          "Deals",
          "Seasonal Deals"
        ],
        "Event": [
          "2021 BTS/OTC store INPUTS",
          "2021 Cold & Flu",
          "2021 Fall Flavors (INPUTS ONLY)",
          "2021 New Year's Eve",
          "2022 Beauty & Personal Care Sale",
          "2022 Cinco de Mayo",
          "2022 Cinco de Mayo INPUTS",
          "2022 Cold and Flu INPUTS",
          "2022 Earth Day INPUTS",
          "2022 Easter",
          "2022 Easter INPUTS",
          "2022 Fall Flavors INPUTS",
          "2022 Father's Day INPUTS",
          "2022 Feb Wellness Sale",
          "2022 Halloween INPUTS",
          "2022 Holiday Gifting",
          "2022 Holiday Shop INPUTS",
          "2022 March Baby Sale",
          "2022 March Baby Sale INPUTS",
          "2022 March Madness INPUTS",
          "2022 Mother's Day INPUTS",
          "2022 NYNY",
          "2022 NYNY INPUTS",
          "2022 New Years Eve",
          "2022 New Years Eve INPUTS",
          "2022 Prime Day",
          "2022 Prime Day INPUTS",
          "2022 Ready for School INPUTS",
          "2022 September Baby Sale INPUTS",
          "2022 Spring Cleaning INPUTS",
          "2022 St. Patrick's Day",
          "2022 St. Patrick's Day INPUTS",
          "2022 Summer Grilling",
          "2022 Summer Grilling Inputs",
          "2022 Super Bowl",
          "2022 Super Bowl INPUTS",
          "2022 Thanksgiving INPUTS",
          "2022 Valentine's Day INPUTS",
          "2023 Cinco De Mayo",
          "2023 Cinco de Mayo INPUTS",
          "2023 Cold and flu INPUTS",
          "2023 Easter Inputs",
          "2023 Fall Flavors",
          "2023 Fall flavors INPUTS",
          "2023 Father's Day",
          "2023 Father's Day INPUTS",
          "2023 February Wellness Sale",
          "2023 Halloween INPUTS",
          "2023 Hanukkah INPUTS",
          "2023 Hispanic Heritage Month + Día de los Muertos INPUTS",
          "2023 Holiday shop INPUTS",
          "2023 March Baby Sale",
          "2023 March Madness",
          "2023 March Madness INPUTS",
          "2023 Mother's Day INPUTS",
          "2023 NYE INPUTS",
          "2023 NYNY INPUTS",
          "2023 New Year, New You INPUTS",
          "2023 Panini Shop",
          "2023 Passover Inputs",
          "2023 Pride Month INPUTS",
          "2023 Prime Day",
          "2023 Prime Day INPUTS",
          "2023 September baby sale INPUTS",
          "2023 Spring Cleaning",
          "2023 Spring Cleaning INPUTS",
          "2023 St. Patrick's Day",
          "2023 St. Patrick's Day Inputs",
          "2023 Summer Grilling",
          "2023 Summer Grilling INPUTS",
          "2023 Thanksgiving INPUTS",
          "2023 Valentine's Day INPUTS",
          "2023 ready for school/OTC INPUTS",
          "Allergy",
          "At-home beauty",
          "August Wellness Sale",
          "BHM 2022",
          "Baby Sale",
          "Back to Sports",
          "Black Friday/Cyber Monday",
          "Chobani BXGY",
          "Chobani Oat Barista FF",
          "Cinco de Mayo 2021",
          "Cold & Flu 2021-22 Inputs",
          "Dia de los Muertos 2021",
          "Easter 2021",
          "Eat In",
          "Eggnog & Alcohol",
          "Fall Store",
          "Father's Day 2021 Inputs",
          "Halloween Store",
          "Halloween Store 2021",
          "Halloween/Fall",
          "Happy School Year",
          "Health & Safety",
          "Heavy Lifting Store",
          "Holiday Shop",
          "Holiday Shop 2021",
          "Holiday Shop 2021 (INPUTS ONLY)",
          "Holiday Shop 2022",
          "Household Coupons",
          "Ingredients for Pizza Night",
          "Kentucky Derby 2021",
          "March Madness 2021",
          "Memorial Day 2023",
          "Mother's Day",
          "Mother's Day 2021",
          "Movie Night In",
          "National Dairy Month",
          "National Wellness Month",
          "New Year's Eve 2020",
          "New Year, New You 2021",
          "Non-Food Holiday Gifting",
          "OTC Landing",
          "PD2022",
          "PepsiCo Super Bowl 2021",
          "Pet Sale",
          "Pet Sale",
          "Prime Day",
          "Prime Day 2023",
          "RFS Food",
          "RFS HPC",
          "RFS Landing",
          "RFS-Food 2021",
          "RFS-HPC 2021",
          "RFS-Main",
          "Recipe",
          "S'mores for Summer",
          "SCJ Summer Store",
          "SCJSpringCleaning",
          "SCS 2021",
          "Sandwich",
          "Save on P&G products",
          "Self-Care Sale",
          "September Baby Sale 2021",
          "Shipping Cutoff",
          "Soap and sanitizer",
          "Spring Cleaning",
          "Spring Cleaning 2021",
          "Spring Cleaning 2022",
          "St. Patrick's Day",
          "St. Patrick's Day 2021",
          "Summer Grilling",
          "Summer Grilling 2021",
          "Summer Store 2021 Inputs",
          "Summer Vacation Store 2021 Inputs",
          "Super Bowl 2021",
          "Super Bowl 2023",
          "Super Bowl 2023 Inputs",
          "Thanksgiving 20 - Apps",
          "Thanksgiving 20 - Bev",
          "Thanksgiving 20 - Clean-up",
          "Thanksgiving 20 - Dessert",
          "Thanksgiving 20 - Main",
          "Thanksgiving 20 - Sides",
          "Thanksgiving 20 - Turkey",
          "Thanksgiving 2021 Inputs",
          "Thanksgiving 2022",
          "Thanksgiving 2023 - Beverages",
          "Thanksgiving 2023 - Clean-up",
          "Thanksgiving 2023 - Desserts",
          "Thanksgiving 2023 - Main Course",
          "Thanksgiving 2023 - Sides",
          "Thanksgiving 2023 - Starters",
          "Thanksgiving 21 - Beverages",
          "Thanksgiving 21 - Clean Up",
          "Thanksgiving 21 - Desserts",
          "Thanksgiving 21 - Main",
          "Thanksgiving 21 - Sides",
          "Thanksgiving 21 - Snacks and Apps",
          "Thanksgiving 21 - Turkey",
          "Thanksgiving 22 - Apps",
          "Thanksgiving 22 - Bev",
          "Thanksgiving 22 - Clean-up",
          "Thanksgiving 22 - Desserts",
          "Thanksgiving 22 - Sides",
          "Thanksgiving 22 - Turkey",
          "Valentine's Day 2021",
          "Valentine's Day 2022",
          "Warm Drink Store",
          "Wellness Sale - 2021"
        ],
        "ILM": [
          "ILM"
        ],
        "In-Store": [
          "In-Store"
        ],
        "Other": [
          "2022 Easter Candy & Choc",
          "2022 World Cup",
          "2023 Beauty & Personal Care Sale",
          "2023 Panini Shop",
          "ABI",
          "AFPB New & Seasonal",
          "Alexa Nutrition Discovery",
          "Amazon Brands (Dairy)",
          "Amazon Brands (Deli & Prepared)",
          "Aplenty",
          "Artisan Cheese - Cheese Board",
          "Artisan Cheese - Main",
          "Artisan Cheese - Occasion",
          "Artisan Cheese - Pairings",
          "Asian Flavors",
          "BPC Promo",
          "Baby and Kids' Food",
          "Bakery Categories",
          "Baking at Home",
          "Blue Moon",
          "Brew a cup of tea",
          "Buy 2 Chips, Get 1 Dip",
          "CBI - Beer",
          "CBI - Wine",
          "Cage-free (Eggs)",
          "Caramel",
          "Chocolate",
          "Cocktail Recipes",
          "Coffee at Home",
          "Coffee_CAH",
          "Corona Landing Page",
          "Cream",
          "Creed 3",
          "Cursive Wine",
          "Cursive Wine - NBC",
          "Dairy Hosting Page",
          "Dairy categories",
          "Danone - Coffee & Creamer",
          "Danone - Horizon Organic",
          "Danone - Plant-Based",
          "Danone - Yogurt",
          "Deals - Beverage",
          "Deals - Food",
          "Deals - Household & Personal Care",
          "Deli Dips, Salsas & Spreads",
          "Deli/Prep Categories",
          "Diapering and Training",
          "Dietz & Watson Brand Store",
          "Dips, Salsas & Spreads",
          "Drinks for any occasion",
          "Drinks/Kefir Yogurt",
          "Easter Brunch",
          "Easy At Home with Andy Baraghani",
          "Easy At Home with Daphne Oz",
          "Eat in- Meal ideas",
          "Eat in-Main",
          "Eat in-Ready to eat/heat",
          "Egg Buying Guide",
          "Egg Whites/Substitutes",
          "Egg pack size",
          "Fall Flavors 2021",
          "Food for Preschoolers and Big Kids",
          "Fresh Bakery",
          "Fresh brand page",
          "Fridge Night",
          "GP E-mail Landing Page",
          "Gluten-Free",
          "Greek/Icelandic Yogurt",
          "Guilt-free Snacking",
          "Gum & Mints",
          "Gummy, Soft & Chewy",
          "Hallmark Holiday",
          "Halloween Savings",
          "Hard Candy",
          "Hard-boiled",
          "Health Care - PL ",
          "Holiday Alcohol",
          "Holiday Candy & Chocolate",
          "Huggies Super Bowl",
          "Indian Flavors",
          "Indulgent Yogurt",
          "Instore Exclusives",
          "Italian Flavors",
          "Jack Ryan",
          "Juicing Buying Guide",
          "Keto",
          "Kids Snacks",
          "Kids/Baby Yogurt",
          "Kosher Groceries",
          "Latin Flavors",
          "Low Sugar & Sugar Free",
          "Low/No Sugar Yogurt",
          "Make A Mimosa",
          "Make a Mimosa Traffic",
          "Milk&Creamer_CAH",
          "Mix Ins_CAH",
          "Modelo Landing Page",
          "MolsonCoors",
          "More from Fresh",
          "Movie Night",
          "Non-Dairy (DCE)",
          "Organic",
          "Organic Valley Brand Store",
          "Other",
          "Our Brands page",
          "Our brands eggs",
          "Paleo",
          "Perishables Categories",
          "Personal Care Deals Page",
          "Pest Control and Cleaning Supplies",
          "Plant Based Yogurt",
          "Plant-Based",
          "Plant-based eggs",
          "Pregnancy for Mom",
          "Probiotic Yogurt",
          "Root Vegetable Buying Guide",
          "SNAP Baltimore",
          "SNAP EBT",
          "SNAP Washington",
          "Salad store",
          "Seventh Generation",
          "Shop by Recipes for Kids",
          "Shop by brand (Yogurt)",
          "Smoothie Store",
          "Snack and Stream",
          "Snacking cheese",
          "Snacks (Dairy)",
          "Specialty Diets",
          "Stick (Butter)",
          "Summer Alcohol Program",
          "Summer Vacation Store 2022",
          "Sundae Store",
          "Sustainability",
          "Svedka Landing Page",
          "Sweets Store",
          "The Peripheral",
          "Try a new beverage",
          "Valentine's Day Sweets",
          "Wine Promo",
          "Yogurt by Flavor/Type",
          "Yogurt by Milk Fat Content",
          "Yogurt by Size"
        ],
        "Recipes": [
          "Recipes"
        ],
        "Search": [
          "Search"
        ],
        "Storefront": [
          "Storefront"
        ]
      }
    },
    "pageTheme": {
      "dependsOn": [
      ],
      "options": [
        " Butter & Margarine",
        "1% Milk",
        "2% Milk",
        "2021 BTS/OTC store INPUTS",
        "2021 Cold & Flu",
        "2021 Fall Flavors (INPUTS ONLY)",
        "2021 New Year's Eve",
        "2022 Beauty & Personal Care Sale",
        "2022 Cinco de Mayo",
        "2022 Cinco de Mayo INPUTS",
        "2022 Cold and Flu INPUTS",
        "2022 Earth Day INPUTS",
        "2022 Easter",
        "2022 Easter Candy & Choc",
        "2022 Easter INPUTS",
        "2022 Fall Flavors INPUTS",
        "2022 Father's Day INPUTS",
        "2022 Feb Wellness Sale",
        "2022 Halloween INPUTS",
        "2022 Holiday Gifting",
        "2022 Holiday Shop INPUTS",
        "2022 March Baby Sale",
        "2022 March Baby Sale INPUTS",
        "2022 March Madness INPUTS",
        "2022 Mother's Day INPUTS",
        "2022 NYNY",
        "2022 NYNY INPUTS",
        "2022 New Years Eve",
        "2022 New Years Eve INPUTS",
        "2022 Prime Day",
        "2022 Prime Day INPUTS",
        "2022 Ready for School INPUTS",
        "2022 September Baby Sale INPUTS",
        "2022 Spring Cleaning INPUTS",
        "2022 St. Patrick's Day",
        "2022 St. Patrick's Day INPUTS",
        "2022 Summer Grilling",
        "2022 Summer Grilling Inputs",
        "2022 Super Bowl",
        "2022 Super Bowl INPUTS",
        "2022 Thanksgiving INPUTS",
        "2022 Valentine's Day INPUTS",
        "2022 World Cup",
        "2023 Beauty & Personal Care Sale",
        "2023 Cinco De Mayo",
        "2023 Cinco de Mayo INPUTS",
        "2023 Cold and flu INPUTS",
        "2023 Easter Inputs",
        "2023 Fall Flavors",
        "2023 Fall flavors INPUTS",
        "2023 Father's Day",
        "2023 Father's Day INPUTS",
        "2023 February Wellness Sale",
        "2023 Halloween INPUTS",
        "2023 Hanukkah INPUTS",
        "2023 Hispanic Heritage Month + Día de los Muertos INPUTS",
        "2023 Holiday shop INPUTS",
        "2023 March Baby Sale",
        "2023 March Madness",
        "2023 March Madness INPUTS",
        "2023 Mother's Day INPUTS",
        "2023 NYE INPUTS",
        "2023 NYNY INPUTS",
        "2023 New Year, New You INPUTS",
        "2023 Panini Shop",
        "2023 Passover Inputs",
        "2023 Pride Month INPUTS",
        "2023 Prime Day",
        "2023 Prime Day INPUTS",
        "2023 September baby sale INPUTS",
        "2023 Spring Cleaning",
        "2023 Spring Cleaning INPUTS",
        "2023 St. Patrick's Day",
        "2023 St. Patrick's Day Inputs",
        "2023 Summer Grilling",
        "2023 Summer Grilling INPUTS",
        "2023 Thanksgiving INPUTS",
        "2023 Valentine's Day INPUTS",
        "2023 ready for school/OTC INPUTS",
        "ABI",
        "AFPB New & Seasonal",
        "Alcohol",
        "Alexa Nutrition Discovery",
        "Allergy",
        "Amazon Brands (Dairy)",
        "Amazon Brands (Deli & Prepared)",
        "Aplenty",
        "Art supplies",
        "Artisan Cheese",
        "Artisan Cheese - Cheese Board",
        "Artisan Cheese - Main",
        "Artisan Cheese - Occasion",
        "Artisan Cheese - Pairings",
        "Arts and Crafts Supplies",
        "Asian Flavors",
        "At-home beauty",
        "August Wellness Sale",
        "BHM 2022",
        "BPC Promo",
        "BYG",
        "Baby",
        "Baby Diaper and Wipes",
        "Baby Health Care",
        "Baby PC",
        "Baby Personal Care",
        "Baby Sale",
        "Baby Skin Care",
        "Baby and Kids' Food",
        "Back to Sports",
        "Bacon & Sausage",
        "Baked Goods and Sweet Treats",
        "Bakery Amazon Brands",
        "Bakery Breakfast",
        "Bakery Categories",
        "Bakery Shop by Brand",
        "Baking",
        "Baking Mixes",
        "Baking at Home",
        "Barbeque and pest control",
        "Beauty",
        "Beauty & Personal Care",
        "Bed & Bath",
        "Beef",
        "Beef Buying Guide",
        "Beer",
        "Berries & Grapes",
        "Beverages",
        "Black Friday/Cyber Monday",
        "Block Cheese",
        "Blue Moon",
        "Bread",
        "Breads & Bakery",
        "Breakfast & Cereal Bars",
        "Breakfast Foods",
        "Brew a cup of tea",
        "Buy 2 Chips, Get 1 Dip",
        "CBI - Beer",
        "CBI - Wine",
        "Cage-free (Eggs)",
        "Candy & Chocolate",
        "Canned & Packaged",
        "Caramel",
        "Carbonated Soft Drinks",
        "Cat Food",
        "Cat Grooming & Toys",
        "Cat Litter & Supplies",
        "Cat Supplies",
        "Cat Treats",
        "Cereal",
        "Checkout",
        "Cheese",
        "Chicken",
        "Chips",
        "Chobani BXGY",
        "Chobani Oat Barista FF",
        "Chocolate",
        "Cinco de Mayo 2021",
        "Cleaning",
        "Clothing & Accessories",
        "Cocktail Recipes",
        "Coffee & Tea",
        "Coffee at Home",
        "Coffee_CAH",
        "Cold & Flu 2021-22 Inputs",
        "Condiments & Dressings",
        "Cooking & Baking",
        "Corona Landing Page",
        "Cough, Cold, and Flu",
        "Crackers",
        "Cream",
        "Cream cheese",
        "Creed 3",
        "Culinary",
        "Cursive Wine",
        "Cursive Wine - NBC",
        "Dairy & Eggs",
        "Dairy Hosting Page",
        "Dairy categories",
        "Danone - Coffee & Creamer",
        "Danone - Horizon Organic",
        "Danone - Plant-Based",
        "Danone - Yogurt",
        "Deals",
        "Deals - Beverage",
        "Deals - Food",
        "Deals - Household & Personal Care",
        "Deli & Prepared",
        "Deli & Prepared Amazon Brands",
        "Deli Dips, Salsas & Spreads",
        "Deli Meats",
        "Deli Meats & Cheeses",
        "Deli/Prep Categories",
        "Deodorants and Antiperspirants",
        "Dia de los Muertos 2021",
        "Diapering and Training",
        "Dietz & Watson Brand Store",
        "Digestion and Nausea",
        "Dips and Spreads",
        "Dips, Salsas & Spreads",
        "Dishwashing",
        "Dog Food",
        "Dog Grooming & Supplies",
        "Dog Supplies",
        "Dog Toys",
        "Dog Treats",
        "Drinks for any occasion",
        "Drinks/Kefir Yogurt",
        "Easter 2021",
        "Easter Brunch",
        "Easy At Home with Andy Baraghani",
        "Easy At Home with Daphne Oz",
        "Eat In",
        "Eat in- Meal ideas",
        "Eat in-Main",
        "Eat in-Ready to eat/heat",
        "Egg Buying Guide",
        "Egg Whites/Substitutes",
        "Egg pack size",
        "Eggnog & Alcohol",
        "Eggs",
        "Electronics",
        "Energy Drinks",
        "Fall Flavors 2021",
        "Fall Store",
        "Fat-free Milk",
        "Father's Day 2021 Inputs",
        "Feeding and Formula",
        "Feminine Care",
        "Flatbreads and Tortillas",
        "Flavored milk",
        "Food for Preschoolers and Big Kids",
        "Food storage",
        "Fragrance-free Baby Products",
        "Fresh Bakery",
        "Fresh brand page",
        "Fridge Night",
        "Frozen",
        "Frozen Appetizers and Snacks",
        "Frozen Breakfast Foods",
        "Frozen Desserts",
        "Frozen Fruit & Juice",
        "Frozen Ice Cream",
        "Frozen Meals and Entrees",
        "Frozen Meats",
        "Frozen Novelties",
        "Frozen Pizza",
        "Frozen Potatoes & Onions",
        "Frozen Seafood",
        "Frozen Vegetables",
        "Fruit Cups and Applesauce",
        "Fruits",
        "GP E-mail Landing Page",
        "Gifting",
        "Gluten-Free",
        "Granola",
        "Greek/Icelandic Yogurt",
        "Guilt-free Snacking",
        "Gum & Mints",
        "Gummy, Soft & Chewy",
        "Hair Care",
        "Hallmark Holiday",
        "Halloween Savings",
        "Halloween Store",
        "Halloween Store 2021",
        "Halloween/Fall",
        "Happy School Year",
        "Hard Candy",
        "Hard-boiled",
        "Health & Safety",
        "Health & Wellness",
        "Health Care - PL ",
        "Health and household from our brands",
        "Heavy Lifting Store",
        "Herbs and Spices",
        "Holiday Alcohol",
        "Holiday Candy & Chocolate",
        "Holiday Shop",
        "Holiday Shop 2021",
        "Holiday Shop 2021 (INPUTS ONLY)",
        "Holiday Shop 2022",
        "Home Storage and Organization",
        "Home and Kitchen",
        "Household",
        "Household Coupons",
        "Huggies Super Bowl",
        "Hypoallergenic Baby Products",
        "ILM",
        "Ice Cream",
        "Ice Cream & Desserts",
        "Ice Cream by Size",
        "Ice cream by brand",
        "Ice cream by diet",
        "Ice cream by flavor",
        "In-Store",
        "Indian Flavors",
        "Indulgent Yogurt",
        "Infant Formula",
        "Ingredients for Pizza Night",
        "Instore Exclusives",
        "International Foods",
        "Italian Flavors",
        "Jack Ryan",
        "Jerky & Pork Rinds",
        "Juice",
        "Juicing",
        "Juicing Buying Guide",
        "Kentucky Derby 2021",
        "Keto",
        "Kids Snacks",
        "Kids/Baby Yogurt",
        "Kitchen & Dining",
        "Kosher Groceries",
        "Lactose-free Milk",
        "Latin Flavors",
        "Laundry",
        "Low Sugar & Sugar Free",
        "Low/No Sugar Yogurt",
        "Macaroni and Cheese",
        "Make A Mimosa",
        "Make a Mimosa Traffic",
        "March Madness 2021",
        "Margarine",
        "May Pet Sale",
        "Meat & Seafood",
        "Meat Subsitutes",
        "Memorial Day 2023",
        "Milk & Cream",
        "Milk&Creamer_CAH",
        "Mix Ins_CAH",
        "Modelo Landing Page",
        "MolsonCoors",
        "More from Fresh",
        "Mother's Day",
        "Mother's Day 2021",
        "Movie Night",
        "Movie Night In",
        "National Dairy Month",
        "National Wellness Month",
        "New Year's Eve 2020",
        "New Year, New You 2021",
        "Non-Dairy",
        "Non-Dairy (DCE)",
        "Non-Dairy Beverages",
        "Non-Food",
        "Non-Food Holiday Gifting",
        "Non-dairy butter",
        "Nuts and Seeds",
        "Nuts and Trail Mix",
        "OTC Landing",
        "Oatmeal",
        "Office Supplies",
        "Office supplies",
        "Oral Care",
        "Organic",
        "Organic Baby Products",
        "Organic Produce",
        "Organic Valley Brand Store",
        "Other",
        "Our Brands page",
        "Our brands eggs",
        "P&G BXGY",
        "PD2022",
        "Packaged Meals",
        "Packaged Produce",
        "Pain Relief",
        "Paleo",
        "Pantry Staples",
        "Paper",
        "Paper & Plastics",
        "Pasta & Noodles",
        "PepsiCo Super Bowl 2021",
        "Perishables Categories",
        "Personal Care",
        "Personal Care Deals Page",
        "Pest Control and Cleaning Supplies",
        "Pet Food & Supplies",
        "Pet Sale",
        "Pet Supplies",
        "Plant Based Yogurt",
        "Plant-Based",
        "Plant-Based Yogurt",
        "Plant-based eggs",
        "Popcorn and Pretzels",
        "Pork",
        "Powdered Drink Mixes",
        "Pregnancy for Mom",
        "Premium butter",
        "Prepared foods",
        "Prime Day",
        "Prime Day 2023",
        "Probiotic Yogurt",
        "Produce",
        "Pudding and Gelatin",
        "RFS Food",
        "RFS HPC",
        "RFS Landing",
        "RFS-Food 2021",
        "RFS-HPC 2021",
        "RFS-Main",
        "Recipe",
        "Recipes",
        "Red Wine",
        "Refrigerated Dough",
        "Rolls and Buns",
        "Root Vegetable Buying Guide",
        "Root Vegetables",
        "S'mores for Summer",
        "SCJ Summer Store",
        "SCJSpringCleaning",
        "SCS 2021",
        "SNAP Baltimore",
        "SNAP EBT",
        "SNAP Washington",
        "Salad store",
        "Sandwich",
        "Sauces and Marinades",
        "Save on P&G products",
        "School supplies",
        "Seafood",
        "Search",
        "Seasonal Deals",
        "Seasonal Produce",
        "Self-Care Sale",
        "September Baby Sale 2021",
        "Seventh Generation",
        "Shipping Cutoff",
        "Shop by Recipes for Kids",
        "Shop by brand (Yogurt)",
        "Shredded Cheese",
        "Skin Care",
        "Sleep Aid",
        "Sliced Cheese",
        "Smoothie Store",
        "Snack Bars",
        "Snack Cakes and Cookies",
        "Snack and Stream",
        "Snacking cheese",
        "Snacks",
        "Snacks (Dairy)",
        "Snacks and Dried Fruit",
        "Soap and sanitizer",
        "Soup, Stocks, and Broths",
        "Sparkling Wine",
        "Sparkling water",
        "Specialty Diets",
        "Spirits",
        "Sports Nutrition",
        "Sports drinks",
        "Spring Cleaning",
        "Spring Cleaning 2021",
        "Spring Cleaning 2022",
        "St. Patrick's Day",
        "St. Patrick's Day 2021",
        "Stage 1 Baby Food",
        "Stage 2 Baby Food",
        "Stage 3 Baby Food",
        "Stage 4 Food for Toddlers",
        "Stick (Butter)",
        "Storefront",
        "Sulfate and Paraben Free Baby Products",
        "Summer Alcohol Program",
        "Summer Grilling",
        "Summer Grilling 2021",
        "Summer Store 2021 Inputs",
        "Summer Vacation Store 2021 Inputs",
        "Summer Vacation Store 2022",
        "Sundae Store",
        "Super Bowl 2021",
        "Super Bowl 2023",
        "Super Bowl 2023 Inputs",
        "Sustainability",
        "Svedka Landing Page",
        "Sweets Store",
        "Tea",
        "Thanksgiving 20 - Apps",
        "Thanksgiving 20 - Bev",
        "Thanksgiving 20 - Clean-up",
        "Thanksgiving 20 - Dessert",
        "Thanksgiving 20 - Main",
        "Thanksgiving 20 - Sides",
        "Thanksgiving 20 - Turkey",
        "Thanksgiving 2021 Inputs",
        "Thanksgiving 2022",
        "Thanksgiving 2023 - Beverages",
        "Thanksgiving 2023 - Clean-up",
        "Thanksgiving 2023 - Desserts",
        "Thanksgiving 2023 - Main Course",
        "Thanksgiving 2023 - Sides",
        "Thanksgiving 2023 - Starters",
        "Thanksgiving 21 - Beverages",
        "Thanksgiving 21 - Clean Up",
        "Thanksgiving 21 - Desserts",
        "Thanksgiving 21 - Main",
        "Thanksgiving 21 - Sides",
        "Thanksgiving 21 - Snacks and Apps",
        "Thanksgiving 21 - Turkey",
        "Thanksgiving 22 - Apps",
        "Thanksgiving 22 - Bev",
        "Thanksgiving 22 - Clean-up",
        "Thanksgiving 22 - Desserts",
        "Thanksgiving 22 - Sides",
        "Thanksgiving 22 - Turkey",
        "The Peripheral",
        "Toaster Pastries",
        "Toys & Games",
        "Try a new beverage",
        "Tub butter",
        "Valentine's Day 2021",
        "Valentine's Day 2022",
        "Valentine's Day Sweets",
        "Vegetables",
        "Vitamins",
        "Warm Drink Store",
        "Water",
        "Wellness",
        "Wellness Sale - 2021",
        "White Wine",
        "Whole Milk",
        "Wine",
        "Wine Promo",
        "World Flavors",
        "Wraps and bags",
        "Yogurt",
        "Yogurt Shop By Brand",
        "Yogurt by Flavor/Type",
        "Yogurt by Milk Fat Content",
        "Yogurt by Size",
        "energy drinks"
      ]
    },
    "pageType": {
      "dependsOn": [
      ],
      "options": [
        "Category",
        "Checkout",
        "Deals",
        "Event",
        "ILM",
        "In-Store",
        "Other",
        "Recipes",
        "Search",
        "Storefront",
        "Test 123",
        "Test 456 ",
        "Test value",
        "Test 789"
      ]
    },
    "placement": {
      "dependsOn": [
        "pageType"
      ],
      "options": {
        "Category": [
          "Coupons Shoveler",
          "FMC Slots",
          "Hero",
          "Nav Blocks",
          "Other",
          "Shoveler 1",
          "Shoveler 2",
          "Shoveler 3",
          "Shoveler 4",
          "Shoveler Bottom 2",
          "VSSM Shoveler 5 - RMS CANNOT NOT USE"
        ],
        "Checkout": [
          "BYG 2",
          "BYG 3",
          "DO NOT USE - Checkout 3"
        ],
        "Deals": [
          "Ad",
          "Alcohol 1",
          "Alcohol 2",
          "All Fresh Coupons",
          "COOP 1 (Featured Brand Deal Shoveler)",
          "COOP 2",
          "COOP 3",
          "COOP 4",
          "COOP 5",
          "COOP 6",
          "COOP 7",
          "F3G 1",
          "F3G 10",
          "F3G 11",
          "F3G 12",
          "F3G 13",
          "F3G 14",
          "F3G 2",
          "F3G 3",
          "F3G 4",
          "F3G 5",
          "F3G 6",
          "F3G 7",
          "F3G 8",
          "F3G 9",
          "Featured House Deal Shoveler 1",
          "Featured House Deal Shoveler 2",
          "Fresh Find 2 / OIH",
          "Fresh Find Hero",
          "Fresh Finds 1",
          "Hero 1",
          "Items Under $X",
          "Non-Food 1",
          "Non-Food 2",
          "Non-Food 3",
          "Non-Food 4",
          "Non-Food 5",
          "Non-Food 6",
          "Non-Food 7",
          "Non-Food 8",
          "OIH",
          "Other",
          "PDCOOP1",
          "PDCOOP10",
          "PDCOOP2",
          "PDCOOP3",
          "PDCOOP4",
          "PDCOOP5",
          "PDCOOP6",
          "PDCOOP7",
          "PDCOOP8",
          "PDCOOP9",
          "PDHero",
          "PDHouse1",
          "PDHouse2",
          "PDHouse3",
          "PDHouse4",
          "PDVPC1",
          "PDVPC2",
          "PDVPC3",
          "PDVPC4",
          "PDVPC5",
          "PDVPC6",
          "Page Header (Hero)",
          "Paleo 1",
          "Paleo 2",
          "Perishables 1",
          "Perishables 2",
          "Perishables 3",
          "Perishables 4",
          "Perishables 5",
          "Perishables 6",
          "Price Perception",
          "Prime Day ASIN Inclusion",
          "Prime Day Hero Deal",
          "Prime Day VPC Shoveler",
          "Reserved Top Slot",
          "Seasonal / OTB"
        ],
        "Event": [
          "Hero",
          "Other",
          "Shoveler"
        ],
        "ILM": [
          "ILM"
        ],
        "In-Store": [
          "Circular",
          "Digital Signage",
          "E.ENDCAP",
          "Endcap Inclusion - Ambient",
          "Endcap Inclusion - Chilled",
          "H.V.ENDCAP",
          "In Aisle Promo",
          "LTD.ENDCAP",
          "P.ENDCAP",
          "Pallet",
          "S.ENDCAP",
          "Under Shelf Stripe"
        ],
        "Other": [
          "Other"
        ],
        "Recipes": [
          "featured-recipe-1",
          "featured-recipe-2"
        ],
        "Search": [
          "Multi-Link Sparkle",
          "Single Link Sparkle"
        ],
        "Storefront": [
          "Category Tile",
          "DO NOT USE - Category Slot",
          "DO NOT USE - Discoverability 1",
          "DO NOT USE - Discoverability 2",
          "DO NOT USE - P13N Testing",
          "Deals 1",
          "Deals Side Tile",
          "HERO",
          "HERO Main",
          "Half Tile",
          "Nav Blocks",
          "Other",
          "Private Label SHV",
          "Seasonal SHV",
          "Shoveler 1",
          "Shoveler 2",
          "Shoveler 3",
          "Shoveler 4",
          "Shoveler 5",
          "VSSM Shoveler 6 - RMS CANNOT USE",
          "VSSM Shoveler 7 - RMS CANNOT USE"
        ]
      }
    },
    "placementInBundle": {
      "dependsOn": [
        "packageName"
      ],
      "options": {
        "2023 Premium Prime Day Feature": [
          "Prime Day 2023 Dedicated Shoveler",
          "Prime Day 2023 Hero ASIN Shoveler Inclusion"
        ],
        "2023 Prime Day Exclusive Shoveler": [
          "Prime Day 2023 Dedicated Shoveler"
        ],
        "2023 Prime Day Inclusion": [
          "Prime Day 2023 ASIN Inclusion Shoveler"
        ],
        "In-Aisle Promotion": [
          "In-Aisle Promotion"
        ],
        "In-Store Endcap": [
          "Standard Dedicated Endcap"
        ],
        "Omnichannel Deal Feature": [
          "Basic Deal Shoveler",
          "Themed Endcap Inclusion"
        ],
        "Omnichannel Deal Spotlight": [
          "Circular Feature",
          "Featured Deal Shoveler",
          "In-Store Endcap"
        ],
        "Omnichannel Event": [
          "Dedicated Event Shoveler",
          "Themed Endcap Inclusion"
        ],
        "Omnichannel Event Plus": [
          "Circular Feature",
          "Dedicated Event Enhanced Shoveler",
          "Themed Endcap Inclusion"
        ],
        "Omnichannel Fresh Find": [
          "Fresh Find Hero",
          "UnderThe Shelf Strip"
        ],
        "Omnichannel Launch Package": [
          "Circular Feature",
          "In-Aisle Promotion",
          "Storefront Side Tile Hero"
        ],
        "Online Animated Storefront Feature": [
          "Animated Storefront Hero Tile"
        ],
        "Online Category Feature": [
          "Category Page Hero"
        ],
        "Online Category Shoveler Feature": [
          "Category Page Shoveler"
        ],
        "Online Checkout Feature": [
          "Checkout Page Shoveler"
        ],
        "Online Deal Feature": [
          "Basic Deal Shoveler"
        ],
        "Online Deal Page Hero Feature": [
          "Deal Hero Feature"
        ],
        "Online Enhanced Category Shoveler Feature": [
          "Enhanced Category Page Shoveler"
        ],
        "Online Enhanced Storefront Shoveler Feature": [
          "Enhanced Storefront Shoveler Feature"
        ],
        "Online Fresh Find": [
          "Fresh Find Hero"
        ],
        "Online Storefront Shoveler Feature": [
          "Storefront Shoveler Basic Feature"
        ],
        "Online Storefront Shoveler Feature Plus": [
          "Storefront Shoveler Feature Plus"
        ],
        "Online Storefront Tile Feature": [
          "Storefront Hero Tile"
        ],
        "Online Tier A Event Dedicated Inclusion": [
          "Dedicated Event Page Shovler"
        ],
        "Online Tier B Event Dedicated Inclusion": [
          "Dedicated Event Page Shovler"
        ],
        "Online Tier C Event Dedicated Inclusion": [
          "Dedicated Event Page Shovler"
        ],
        "Premium Endcap (F3G only)": [
          "Premium Endcap"
        ],
        "Specialty Page Feature": [
          "Dedicated Specialty Page Feature"
        ],
        "VSSM Category": [
          "VSSM Category Shoveler"
        ],
        "VSSM Storefront": [
          "VSSM Storefront Shoveler"
        ]
      }
    },
    "placementSource": {
      "dependsOn": [
      ],
      "options": [
        "House",
        "Incremental",
        "LIP",
        "Rate Card",
        "VSSM"
      ]
    },
    "promoType": {
      "dependsOn": [
      ],
      "options": [
        "% or $ off",
        "BXGY",
        "EDLP",
        "Fixed Price",
        "MKD",
        "POS",
        "VPC"
      ]
    },
    "shovelerContent": {
      "dependsOn": [
      ],
      "options": [
        "Browse Node",
        "Fixed ASIN List",
        "Search Query"
      ]
    },
    "sov": {
      "dependsOn": [
        "pageGroup",
        "placement"
      ],
      "options": {
        "Checkout": {
          "Checkout 1": [
            0.1,
            "10",
            "10"
          ],
          "Checkout 2": [
            0.1,
            "10"
          ],
          "COOP 1": [
          ]
        },
        "Deals": {
          "COOP 1": [
            0.1
          ],
          "COOP 2": [
            0.1,
            "10"
          ],
          "COOP 3": [
            0.1
          ],
          "COOP 4": [
            0.1
          ],
          "COOP 5": [
            0.1
          ],
          "COOP 6": [
            "0.1"
          ],
          "COOP 7": [
            "0.1"
          ],
          "Hero 1": [
            1
          ],
          "Hero 2": [
            1,
            "10"
          ],
          "PDVPC1": [
            "10"
          ],
          "Seasonal": [
            0.1
          ]
        },
        "F3 Events": {
          "Hero": [
            0
          ],
          "HERO": [
            "10",
            "100",
            "5"
          ],
          "Other": [
            0
          ],
          "Shoveler": [
            0,
            "10",
            "100",
            "50"
          ]
        },
        "ILM": {
          "ILM": [
            "100"
          ]
        },
        "Other": {
          "Other": [
            ".1",
            ".2",
            "1",
            "100",
            "20",
            "25"
          ]
        },
        "Premium Category": {
          "Hero": [
            0.1,
            "10",
            "20"
          ],
          "HERO": [
            "0.1"
          ],
          "Shoveler 1": [
            "0.1",
            "10",
            "100"
          ],
          "Shoveler 2": [
            "0.1",
            "10"
          ],
          "Shoveler 3": [
            "0.1",
            "10",
            "80"
          ],
          "Shoveler 4": [
            "0.1",
            "10"
          ],
          "Shoveler 5": [
            "0.1"
          ]
        },
        "Search": {
          "Single Link Sparkle": [
            "100"
          ]
        },
        "Standard Category": {
          "Hero": [
            0.2,
            "20"
          ],
          "Shoveler 1": [
            "0.2",
            "10",
            "100",
            "20"
          ],
          "Shoveler 2": [
            "0.2",
            "10",
            "20"
          ],
          "Shoveler 3": [
            "0.2"
          ],
          "Shoveler 4": [
            "0.2",
            "10",
            "25"
          ],
          "Shoveler 5": [
            "0.2"
          ],
          "Slot 5": [
            "0.2"
          ]
        },
        "Storefront": {
          "Deals 1": [
            0.1
          ],
          "Half Tile": [
            0.2,
            "10",
            "20"
          ],
          "HERO": [
            0.05,
            "10",
            "5"
          ],
          "HERO Main": [
            0.1,
            "10",
            "30",
            "45"
          ],
          "Private Label SHV": [
            0.1
          ],
          "Seasonal SHV": [
            0.1
          ],
          "Shoveler 1 (InvAw)": [
            0.1
          ],
          "Shoveler 2": [
            0.1,
            "10",
            "10"
          ],
          "Shoveler 3": [
            0.1,
            "10"
          ],
          "Shoveler 4": [
            0.1,
            "10"
          ],
          "Shoveler 5": [
            0.1
          ]
        }
      }
    },
    "sovType": {
      "dependsOn": [
      ],
      "options": [
        "Default",
        "Random",
        "SOV",
        "Targeted SOV"
      ]
    },
    "timezone": {
      "dependsOn": [
      ],
      "options": [
        "Pacific"
      ]
    },
    "timezoneID": {
      "dependsOn": [
        "timezone"
      ],
      "options": {
        "Central": [
          "America/Chicago"
        ],
        "Eastern": [
          "America/New_York"
        ],
        "Mountain": [
          "America/Denver"
        ],
        "Pacific": [
          "America/Los_Angeles"
        ],
        "UTC": [
          "UTC"
        ]
      }
    },
    "translationStatus": {
      "dependsOn": [
      ],
      "options": [
        "Campaign Translated",
        "Translation Completed",
        "Translation Pending"
      ]
    },
    "vendor": {
      "dependsOn": [
      ],
      "options": [
        "3M",
        "4505 Meats Inc",
        "AB World Foods",
        "ACURE",
        "AFPB",
        "Abbott",
        "Abe's",
        "Acme",
        "Advanced Beauty Systems",
        "AiVy",
        "Ajinomoto",
        "Alcohol",
        "Aloevine",
        "Aloha",
        "Alpha Foods",
        "Amazon Meal Kits",
        "AmazonBasics",
        "American Greetings",
        "American Pop Corn Company",
        "Amy's",
        "Angelic",
        "Angelic Bakehouse",
        "Anheuser Busch",
        "Apple & Eve",
        "Applegate",
        "Aquastar",
        "Arctic Zero",
        "Aryzta",
        "Asparagus",
        "Atkins",
        "Aunt Millies",
        "B&G Foods, Inc.",
        "Baozza",
        "Barilla",
        "Barney",
        "Bayer",
        "BeautyHouse",
        "Bel Brands",
        "BelGioioso",
        "Bellisio Foods",
        "Bernatellos",
        "Bertolli",
        "Better Foods",
        "Better Planet Brands",
        "Bimbo",
        "BioBag America",
        "Bitchin' Sauce",
        "Blue Buffalo",
        "Blue Diamond",
        "Blue Triton",
        "Bob Evans",
        "Bob's Red Mill",
        "Bona",
        "Botticelli",
        "Bowery Farming",
        "Bowery Farms",
        "Brown and Haley",
        "Bumble Bee",
        "Buns & Rolls",
        "Bush Brothers & Co",
        "Butterball",
        "CBI",
        "CF Sauer",
        "CHOMPS",
        "CJ Foods",
        "CPB",
        "Cabot",
        "Cacique",
        "Califia",
        "California Olive Ranch",
        "Campbell's",
        "Cappellos",
        "Caulipower",
        "Cedar's",
        "Cellucor",
        "Challenge",
        "Chef Bombay",
        "Chicken of the Sea",
        "Chloe's Pops",
        "Chobani",
        "Church & Dwight",
        "Clarion Brands",
        "Clif Bar",
        "Clorox",
        "Clover Sonoma",
        "Coca-Cola",
        "Colavita",
        "Colgate",
        "Conagra",
        "Constellation Brands",
        "Continental Mills",
        "Coolhaus",
        "Crayola",
        "Crofter's Organic",
        "Crofters",
        "Cursive Wine",
        "DGIC",
        "DVA",
        "Daily's",
        "Danone",
        "Darigold",
        "DeOleo",
        "Dean",
        "Del Monte Foods, Inc",
        "Del Real",
        "Dessert Holdings",
        "Dietz & Watson",
        "Dole",
        "Dr. Praeger's ",
        "Dukes",
        "Duracell",
        "EO Product",
        "EOS",
        "Eagle Brands",
        "Edgewell",
        "Ekaterra",
        "Ellenos",
        "Ellis Isle",
        "Energizer",
        "Enlightened",
        "Envy",
        "Equator",
        "FIJI",
        "Fage",
        "Ferrara",
        "Ferrero",
        "Filippo Berio",
        "Flowers",
        "Foodstirs",
        "Forager Project",
        "Franz",
        "Freshpet",
        "Frey Farms",
        "Frontier Co-op",
        "Fyffes",
        "GBD",
        "GFI",
        "GP",
        "GSKPfizer",
        "GT Kombucha",
        "Garden of Life",
        "General Mills",
        "Gerber",
        "Ghirardelli",
        "Glanbia",
        "GoGo Squeez",
        "GoMacro",
        "Godiva",
        "Good Culture",
        "Gordos Dip",
        "Gortons",
        "Gotham Greens",
        "Gourmesso",
        "Goya",
        "Granite Gold",
        "Greenleaf",
        "Hain Celestial",
        "Hallmark",
        "Hanover Foods Corporation",
        "Happi Foodi",
        "Happy Family",
        "Harmless Harvest",
        "Health-Ade",
        "Heartland FPG",
        "Hello Oral Care",
        "Henkel",
        "Hersheys",
        "Hippo Harvest",
        "Honest Company",
        "Hood",
        "Hope foods",
        "Hormel",
        "Hostess",
        "House",
        "IQBAR",
        "Icelandic Glacial",
        "Icelandic Provisions",
        "Idahoan",
        "Impossible",
        "Infirst",
        "Ithica Hummus",
        "Iwon Organic",
        "J&J Snack Foods",
        "J&J Vision",
        "JBS",
        "JBSS",
        "JFC",
        "JUST",
        "Jack Link's",
        "Jasper Wyman & Son",
        "Jennie O",
        "Johnson & Johnson",
        "Johnsonville",
        "Jolly Time",
        "JonnyPops",
        "Juanitas",
        "KC",
        "KDP",
        "KIND",
        "Kayco",
        "Kayem",
        "Kellogg's",
        "Kerrygold",
        "Keto Krisp",
        "Kevin's",
        "Kicking Horse Coffee",
        "Kikkoman",
        "King Arthur",
        "King's Hawaiian",
        "Kitchen",
        "Koia",
        "Kraft Heinz",
        "KraftHeinz/Conagra",
        "La Brea",
        "La Colombe",
        "La Tourangelle",
        "Lactalis",
        "Lactalis_Cheese",
        "Lamb Weston",
        "Land O'Lakes",
        "Lavazza",
        "Lifeway",
        "Limited Release",
        "Lindt",
        "Lipman",
        "Litehouse",
        "Little Leaf Farms",
        "Loacker",
        "MOWI",
        "Machu Picchu",
        "MammaMancini's",
        "Maple Hill",
        "Mariani",
        "Mars Ice Cream",
        "Mars Pets",
        "Mars Wrigley",
        "Materne",
        "Mattel",
        "Maya Kaimal",
        "Mazzetta",
        "McCormick",
        "McKee",
        "Mead Johnson",
        "Melinda's",
        "Meow Mix",
        "Mezzetta",
        "Mission",
        "Miyokos",
        "Mizkan",
        "MochiDoki",
        "Mondelez",
        "Monster Energy",
        "Morinaga",
        "Morton Salt, Inc.",
        "Mt Olive",
        "Mueller Sports Medicine",
        "Munk Pack",
        "Musco Family Olive Co",
        "MyMochi",
        "NCP",
        "NUSA",
        "Nature's Bakery",
        "Nature's Bounty",
        "Nature's Way",
        "NatureSweet",
        "Nestle IPW",
        "Nestle Waters",
        "Nestle Waters Domestic",
        "Newman's Own",
        "Nick's Ice Cream",
        "Niman Ranch",
        "Nissin Foods",
        "Non-Food PL",
        "NongShim",
        "Nonni's",
        "Noosa",
        "Nutiva",
        "Nutrabolt",
        "Nutraceuticals",
        "OIH",
        "OIH - GL 540",
        "OIH - GL 541",
        "OXO",
        "Oberto",
        "Ocean Spray",
        "Olipop",
        "Olly",
        "Once Upon a Farm",
        "One Mighty Mill",
        "OpalApple",
        "Oppy",
        "Orchard Valley",
        "Organic Valley",
        "PL/CL Alcohol",
        "POM Wonderful",
        "PRO",
        "Palermo's",
        "Panos Brands",
        "PartyPlatter",
        "Pat LaFrieda",
        "Peeled Snacks",
        "Peet's",
        "PepsiCo",
        "Perfetti Van Melle",
        "Petit Pot",
        "Pets",
        "Pfizer",
        "Philips",
        "Pictsweet Farms",
        "Pilgrims",
        "Pinnacle",
        "Planters (Hormel)",
        "Pompeian",
        "Poppi",
        "Post Foods",
        "Pre",
        "Premier Nutrition",
        "Prestige",
        "Primal Kitchen",
        "Proctor & Gamble",
        "Produce",
        "Purell",
        "Purell",
        "Q MIXERS",
        "Quest",
        "RB Health",
        "RB HyHo",
        "RC Bigleow",
        "Rana",
        "Ranir",
        "Rao's",
        "Ready Pac",
        "Recipe",
        "Red Bull",
        "Red Gold, Inc.",
        "Reesers",
        "Revol Greens",
        "Reynolds",
        "Rich",
        "Rich's Bakery",
        "Rise Brewing",
        "Riviana Foods",
        "Roar Beverages",
        "Roca",
        "Ruiz Foods",
        "Rummo",
        "Rummo Pasta",
        "SCJ",
        "SCJLBU",
        "SE Mills",
        "SVG",
        "Sabra",
        "Sambazon",
        "Sanofi",
        "Saputo",
        "Sargento",
        "Sartori",
        "Schwan's",
        "Schweid & Sons",
        "Screamin' Sicilian & Urban Pie",
        "Serendipity",
        "Serenity Kids",
        "Seventh Generation",
        "Siggis",
        "SkinCareHouse",
        "SlicedMushroom",
        "Smithfield",
        "Smuckers",
        "Smuckers Pet",
        "Snack n Waffle",
        "Snow Days",
        "Solen USA",
        "Sonoma Creamery",
        "Sovo's Brands",
        "Spice World",
        "Spindrift",
        "Sprout Organics",
        "St. Pierre",
        "StarKist",
        "Stash Tea",
        "Stonyfield",
        "Strawberry",
        "Summit Hill",
        "Summit Wine",
        "Sun Basket",
        "Sun-Maid",
        "Sunshine",
        "Sweet Lake Vodka",
        "Sweet Nothings",
        "Talking Rain",
        "Taste of Italy",
        "TasteInSeason",
        "Tata Global Beverages",
        "Tattooed Chef",
        "Taylor Farms",
        "Tetley",
        "The Better Bakehouse",
        "The Good Bean",
        "The New Primal",
        "Theo Chocolate",
        "This Bar Saves Lives",
        "This Saves Lives",
        "Tile",
        "Tillamook Country Smoker",
        "Toms of Maine",
        "TransOcean",
        "Trident",
        "Tropicana Brand Group (TBG)",
        "Tru Fru",
        "True Brands",
        "Truff",
        "Truwhip",
        "Tucson Tamales",
        "Tyson",
        "Umpqua Foods",
        "Unilever",
        "Upfield",
        "Vahdam Teas",
        "Van Leeuwen",
        "Veg",
        "Vega",
        "Vegan Eats",
        "Veggie",
        "Veggies Made Great",
        "Verde",
        "Vital Farms",
        "Vitals International",
        "Wandering Bear",
        "Waterloo",
        "Wedderspoon",
        "Welch's",
        "Wellements",
        "Wells Enterprises",
        "White Castle",
        "Wholesum",
        "Wicked Foods",
        "Wonderful",
        "Wonderful Halos",
        "Wonderful Pistachios",
        "Wonderful Seedless Lemons",
        "Wyman's of Maine",
        "Yakult",
        "Yasso",
        "YumEarth",
        "Yumble",
        "Zespri",
        "Zoi",
        "dogtoys",
        "iDesign",
        "iHealth",
        "illy Coffee",
        "kimberly clark",
        "organicgirl"
      ]
    },
    "vertical": {
      "dependsOn": [
      ],
      "options": [
        "Alcohol",
        "Best of Amazon",
        "F3G",
        "HPC",
        "Non-Food",
        "Other",
        "Paleo",
        "Perishables"
      ]
    },
    "weblabTreatment": {
      "dependsOn": [
      ],
      "options": [
        "C",
        "T1",
        "T2"
      ]
    },
    "widget": {
      "dependsOn": [
        "placement"
      ],
      "options": {
        "Alcohol 2": [
          "SHOV"
        ],
        "Basic Deal Shoveler": [
          "SHOV"
        ],
        "BYG 1": [
          "OTHR"
        ],
        "BYG 2": [
          "OTHR"
        ],
        "Category Slot 1": [
          "SHOV"
        ],
        "Category Slot 2": [
          "SHOV"
        ],
        "Category Tile": [
          "Category Tile"
        ],
        "Checkout 1": [
          "OTHR",
          "SHOV"
        ],
        "Checkout 2": [
          "OTHR",
          "SHOV"
        ],
        "Circular": [
          "CIRC"
        ],
        "Coffin-Protein": [
          "COFFIN"
        ],
        "COOP 1": [
          "SHOV"
        ],
        "COOP 1 (Featured Brand Deal Shoveler)": [
          "SHOV"
        ],
        "COOP 1 (Featured COOP Deal Shoveler)": [
          "SHOV"
        ],
        "COOP 2": [
          "SHOV"
        ],
        "COOP 3": [
          "SHOV"
        ],
        "COOP 4": [
          "SHOV"
        ],
        "COOP 5": [
          "SHOV"
        ],
        "COOP 6": [
          "SHOV"
        ],
        "COOP 7": [
          "SHOV"
        ],
        "Custom Circular": [
          "C.CIRC-EVT"
        ],
        "Deals 1": [
          "SHOV"
        ],
        "Deals Side Tile": [
          "TCG"
        ],
        "Digital Signage": [
          "D.SIGN"
        ],
        "DO NOT USE - P13N Testing": [
          "SHOV"
        ],
        "E.ENDCAP": [
          "E.Endcap - Ambient",
          "E.Endcap - Chilled",
          "E.Endcap - Frozen"
        ],
        "Endcap Inclusion - Ambient": [
          "E.INCLUSION - Ambient"
        ],
        "Endcap Inclusion - Chilled": [
          "E.INCLUSION - Chilled"
        ],
        "Featured Brand Deal Shoveler": [
          "F.B.DEAL.ENSHOV"
        ],
        "Featured Deal Shoveler ": [
          "F.DEAL.SHOV"
        ],
        "Featured House Deal Shoveler 1": [
          "SHOV"
        ],
        "Featured House Deal Shoveler 2": [
          "SHOV"
        ],
        "featured-recipe-1": [
          "Featured Hero"
        ],
        "featured-recipe-2": [
          "Featured Hero"
        ],
        "FMC Slots": [
          "ENSHOV",
          "SHOV"
        ],
        "Fresh Find Hero": [
          "FF.HERO"
        ],
        "H.V.ENDCAP": [
          "H.V.ENDCAP"
        ],
        "Half Tile": [
          "HALF"
        ],
        "Hero": [
          "HERO",
          "NON CLICKABLE HERO",
          "SHOV"
        ],
        "HERO": [
          "HERO",
          "NON CLICKABLE HERO",
          "SHOV"
        ],
        "Hero 1": [
          "HERO",
          "SHOV"
        ],
        "Hero 2": [
          "HERO",
          "SHOV"
        ],
        "HERO Main": [
          "HERO Main",
          "NON CLICKABLE HERO"
        ],
        "ILM": [
          "ILM"
        ],
        "In Aisle Promo": [
          "AISLE PROMO"
        ],
        "LTD.ENDCAP": [
          "LTD.ENDCAP"
        ],
        "Multi-Link Sparkle": [
          "SPARK"
        ],
        "Nav Blocks": [
          "NB"
        ],
        "Other": [
          "ENHSHOV",
          "HALF",
          "HERO",
          "HERO Main",
          "NB",
          "NON CLICKABLE HERO",
          "OTHR",
          "SHOV",
          "STRIPE"
        ],
        "P.CIRCULAR-Bakery": [
          "P.CIRC"
        ],
        "P.CIRCULAR-Beverages": [
          "P.CIRC"
        ],
        "P.CIRCULAR-Frozen": [
          "P.CIRC"
        ],
        "P.CIRCULAR-Meat Seafood": [
          "P.CIRC"
        ],
        "P.CIRCULAR-Pantry": [
          "P.CIRC"
        ],
        "P.CIRCULAR-Produce": [
          "P.CIRC"
        ],
        "P.CIRCULAR-Snacks": [
          "P.CIRC"
        ],
        "P.Endcap": [
          "P.ENDCAP"
        ],
        "P.ENDCAP": [
          "P.ENDCAP"
        ],
        "P13N 6 (Approval Needed)": [
          "SHOV"
        ],
        "Pallet": [
          "Pallet"
        ],
        "PDCOOP1": [
          "SHOV"
        ],
        "PDCOOP10": [
          "SHOV"
        ],
        "PDCOOP2": [
          "SHOV"
        ],
        "PDCOOP3": [
          "SHOV"
        ],
        "PDCOOP4": [
          "SHOV"
        ],
        "PDCOOP5": [
          "SHOV"
        ],
        "PDCOOP6": [
          "SHOV"
        ],
        "PDCOOP7": [
          "SHOV"
        ],
        "PDCOOP8": [
          "SHOV"
        ],
        "PDCOOP9": [
          "SHOV"
        ],
        "PDHouse1": [
          "SHOV"
        ],
        "PDHouse2": [
          "SHOV"
        ],
        "PDHouse3": [
          "SHOV"
        ],
        "PDHouse4": [
          "SHOV"
        ],
        "PDVPC1": [
          "SHOV"
        ],
        "PDVPC2": [
          "SHOV"
        ],
        "PDVPC3": [
          "SHOV"
        ],
        "PDVPC4": [
          "SHOV"
        ],
        "PDVPC5": [
          "SHOV"
        ],
        "PDVPC6": [
          "SHOV"
        ],
        "Premium Circular": [
          "P CIRC"
        ],
        "Premium Endcap": [
          "P ENDCAP"
        ],
        "Prime Day Hero Deal": [
          "PD.DEAL.HERO"
        ],
        "Prime Day VPC Shoveler": [
          "PD.VPC.SHOV"
        ],
        "Private Label SHV": [
          "ENHSHOV",
          "SHOV"
        ],
        "S.CIRCULAR-Bakery": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Beverages": [
          "S.CIRC"
        ],
        "S.CIRCULAR-BWS": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Dairy": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Deli": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Famous For": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Frozen": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Health Beauty": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Home Kitchen": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Meat Seafood": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Pantry": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Prep. Foods": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Produce": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Snacks": [
          "S.CIRC"
        ],
        "S.CIRCULAR-Special Promo PB Seasonal": [
          "S.CIRC"
        ],
        "S.ENDCAP": [
          "S.Endcap - Ambient",
          "S.Endcap - Chilled",
          "S.Endcap - Frozen"
        ],
        "S.Endcap - Ambient": [
          "S.ENDCAP"
        ],
        "S.Endcap - Chilled": [
          "S.ENDCAP"
        ],
        "S.Endcap - Frozen": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Baking": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Beverages": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Breakfast": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Commercial Bakery": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Cookies Crackers": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Dairy": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Frozen": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Meals/Condiments": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Non-Food": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Produce": [
          "S.ENDCAP"
        ],
        "S.ENDCAP-Snacks": [
          "S.ENDCAP"
        ],
        "Seasonal": [
          "SHOV"
        ],
        "Seasonal SHV": [
          "SHOV"
        ],
        "Shoveler": [
          "SHOV"
        ],
        "Shoveler 1": [
          "ENHSHOV",
          "SHOV"
        ],
        "Shoveler 1 (InvAw)": [
          "SHOV"
        ],
        "Shoveler 2": [
          "ENHSHOV",
          "SHOV"
        ],
        "Shoveler 3": [
          "ENHSHOV",
          "SHOV"
        ],
        "Shoveler 4": [
          "ENHSHOV",
          "SHOV"
        ],
        "Shoveler 5": [
          "ENHSHOV",
          "SHOV"
        ],
        "Shoveler Bottom 2": [
          "SHOV"
        ],
        "Single Link Sparkle": [
          "SPARK"
        ],
        "Slot 5": [
          "ENHSHOV",
          "OTHR",
          "SAW",
          "SHOV",
          "STRIPE"
        ],
        "Sparkle": [
          "SPARK"
        ],
        "Standard Circular": [
          "S CIRC"
        ],
        "Standard Endcap": [
          "S ENDCAP"
        ],
        "Stripe": [
          "STRIPE"
        ],
        "TR - Category Slot 1": [
          "SHOV"
        ],
        "TR - Deals 1": [
          "SHOV"
        ],
        "TR - Half Tile": [
          "HALF"
        ],
        "TR - Private Label SHV": [
          "SHOV"
        ],
        "TR - Seasonal SHV": [
          "SHOV"
        ],
        "TR - Shoveler 1": [
          "SHOV"
        ],
        "TR - Shoveler 2": [
          "SHOV"
        ],
        "TR - Shoveler 3": [
          "SHOV"
        ],
        "TR - Shoveler 4": [
          "SHOV"
        ],
        "TR - Shoveler 5": [
          "SHOV"
        ],
        "Under Shelf Stripe": [
          "U.S.STRIPE"
        ],
        "Under The Shelf Strip - Fresh Find": [
          "UTSS-FF"
        ],
        "Under The Shelf Strip-Dairy": [
          "UTSS-EVT"
        ],
        "Under The Shelf Strip-Deli": [
          "UTSS-EVT"
        ],
        "Under The Shelf Strip-Frozen": [
          "UTSS-EVT"
        ],
        "Under The Shelf Strip-Meat": [
          "UTSS-EVT"
        ],
        "Under The Shelf Strip-Other": [
          "UTSS-EVT"
        ]
      }
    }
  },
  "columnOrder": [
    {
      "name": "refMarker",
      "display": "Ref Marker",
      "inputType": "INPUT"
    },
    {
      "name": "brand",
      "display": "Brand",
      "inputType": "INPUT"
    },
    {
      "name": "category",
      "display": "Category",
      "inputType": "INPUT"
    },
    {
      "name": "status",
      "display": "Status",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "marketingManager",
      "display": "Marketing Manager",
      "inputType": "INPUT"
    },
    {
      "name": "marketingManagerNotes",
      "display": "Marketing Manager Notes",
      "inputType": "TEXT_AREA_INPUT"
    },
    {
      "name": "packageName",
      "display": "Package Name",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "placementSource",
      "display": "Placement Source",
      "inputType": "INPUT"
    },
    {
      "name": "rateCardId",
      "display": "Rate Card ID",
      "inputType": "UNEDITABLE"
    },
    {
      "name": "vendor",
      "display": "Vendor",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "vertical",
      "display": "Vertical",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "asins",
      "display": "Asins",
      "inputType": "INPUT"
    },
    {
      "name": "campaignName",
      "display": "Campaign Name",
      "inputType": "INPUT"
    },
    {
      "name": "campaignType",
      "display": "Campaign Type",
      "inputType": "INPUT"
    },
    {
      "name": "copy",
      "display": "Copy",
      "inputType": "INPUT"
    },
    {
      "name": "language",
      "display": "Language",
      "inputType": "INPUT"
    },
    {
      "name": "otherTheme",
      "display": "Other Theme",
      "inputType": "INPUT"
    },
    {
      "name": "promoId",
      "display": "Promo Id",
      "inputType": "INPUT"
    },
    {
      "name": "promoType",
      "display": "Promo Type",
      "inputType": "INPUT"
    },
    {
      "name": "scheduler",
      "display": "Scheduler",
      "inputType": "INPUT"
    },
    {
      "name": "schedulingInstructions",
      "display": "Scheduling Instructions",
      "inputType": "INPUT"
    },
    {
      "name": "browseNodeIds",
      "display": "Browse Node ID",
      "inputType": "INPUT"
    },
    {
      "name": "customSeeMoreUrl",
      "display": "Custom See More URL",
      "inputType": "INPUT"
    },
    {
      "name": "dedupeRules",
      "display": "Dedupe rules",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "desktopSlot",
      "display": "Desktop Slot",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "encodedKeplerWidgetParam",
      "display": "Encoded Keplar Params",
      "inputType": "INPUT"
    },
    {
      "name": "heroASINs",
      "display": "Hero ASINs",
      "inputType": "INPUT"
    },
    {
      "name": "imagePaths",
      "display": "Image Path",
      "inputType": "JSON_INPUT"
    },
    {
      "name": "imageType",
      "display": "Image Type",
      "inputType": "INPUT"
    },
    {
      "name": "inventory",
      "display": "Inventory",
      "inputType": "INPUT"
    },
    {
      "name": "inventoryAwareness",
      "display": "Inventory Awareness",
      "inputType": "INPUT"
    },
    {
      "name": "isAnimatedContent",
      "display": "Is Animated Content?",
      "inputType": "SINGLE_CHECKBOX"
    },
    {
      "name": "isDeal",
      "display": "Is Deal?",
      "inputType": "SINGLE_CHECKBOX"
    },
    {
      "name": "label",
      "display": "Label",
      "inputType": "INPUT"
    },
    {
      "name": "mobileSlot",
      "display": "Mobile Slot",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "needsInventoryAwareness",
      "display": "Needs Inventory Awareness",
      "inputType": "SINGLE_CHECKBOX"
    },
    {
      "name": "pageID",
      "display": "Page ID",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "pageName",
      "display": "Page Name",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "pageTheme",
      "display": "Page Theme",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "pageType",
      "display": "Page Type",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "percolateStrategyParameters",
      "display": "Percolate SP",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "placement",
      "display": "Placement",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "placementInBundle",
      "display": "Placement in Bundle",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "shovelerContent",
      "display": "Shoveler Content",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "slotsOverbookedValidationBypassed",
      "display": "Slots Overbooked Validation Bypassed",
      "inputType": "UNEDITABLE"
    },
    {
      "name": "sov",
      "display": "SOV",
      "inputType": "NUMBER_INPUT"
    },
    {
      "name": "sovType",
      "display": "SOV Type",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "subHeadline",
      "display": "Sub-headline",
      "inputType": "INPUT"
    },
    {
      "name": "symphonyId",
      "display": "Sub-headline",
      "inputType": "INPUT"
    },
    {
      "name": "symphonyURL",
      "display": "Symphony URL",
      "inputType": "INPUT"
    },
    {
      "name": "targeting",
      "display": "Targeting (Bullseye ID)",
      "inputType": "INPUT"
    },
    {
      "name": "targetingExclude",
      "display": "Targeting to exclude (Bullseye ID)",
      "inputType": "INPUT"
    },
    {
      "name": "termsAndConditions",
      "display": "T&C",
      "inputType": "TEXT_AREA_INPUT"
    },
    {
      "name": "timezoneId",
      "display": "Timezone ID",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "useNewTemplateDesign",
      "display": "Use \"New\" template",
      "inputType": "SINGLE_CHECKBOX"
    },
    {
      "name": "vssmId",
      "display": "VSSM ID",
      "inputType": "INPUT"
    },
    {
      "name": "weblab",
      "display": "Weblab",
      "inputType": "INPUT"
    },
    {
      "name": "weblabTreatment",
      "display": "Weblab Treatment",
      "inputType": "SINGLE_SELECT"
    },
    {
      "name": "widget",
      "display": "Widget",
      "inputType": "SINGLE_SELECT"
    }
  ],
};

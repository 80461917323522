import _ from 'lodash';

export const getActiveCellValue = (item, columnName) => {
  if (_.isEmpty(item)) {
    return null;
  }

  return item[columnName];
};

const selectCell = (state, action) => {
  const { payload } = action;

  if (!payload || !payload.item || !payload.columnName) {
    return {
      ...state,
      activeCell: null,
      activeCellValue: null,
    };
  }

  const { item, columnName } = payload;

  return {
    ...state,
    activeCell: item && columnName
      ? {
        item,
        columnName
      }
      : null,
    activeCellValue: getActiveCellValue(item, columnName),
  };
};

export default selectCell;

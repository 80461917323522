import { Hosts } from '../constants';

const oauth = {
  domain: 'f3-marketing-calendar-cdk-beta.auth.us-west-2.amazoncognito.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `https://${Hosts.BETA}`,
  redirectSignOut: `https://${Hosts.BETA}`,
  responseType: 'code',
};

const config = {
  Auth: {
    identityPoolId: 'us-west-2:c8219de6-6b9a-4542-8a7b-13f7b3e8a59d',
    region: 'us-west-2',
    userPoolId: 'us-west-2_mAm1ut7iA',
    userPoolWebClientId: '4k2g36dvnaqhp89kisvln3irkr',
    oauth,
  },
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint: 'https://vpoyt4brq5fd7hc6xsejhhmrce.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
};

export default config;

import featureNotificationSystem from './updates/featureNotificationSystem';
import openLinksInNewTab from './updates/openLinksInNewTab';
import campaignNameGeneration from './updates/campaignNameGeneration';

/**
 * This file contains new feature announcements (will be displayed as a blue bar on top of the app)
 * You can put a short summary of your feature here with a link to the wiki
 * (when the full description is located).
 *
 * Notifications are shown using stack in one by one order (from oldest to newest).
 * Once the one is closed, the next one will be shown until it runs out of notifications to show.
 */

/**
 * Format:
 {
  link: <string>, (optional parameter)
  announcement: <string>,
 }
 * @returns {*[]}
 */
export default () => {
  return [
    {
      announcement: 'Marketing Calendar released',
    },
    {
      announcement: featureNotificationSystem(),
      link: 'https://sim.amazon.com/issues/P38626814',
      linkText: 'P38626814',
    },
    {
      announcement: openLinksInNewTab(),
      link: 'https://sim.amazon.com/issues/P38174448',
      linkText: 'P38174448',
    },
    {
      announcement: campaignNameGeneration(),
      link: 'https://sim.amazon.com/issues/V236353135',
      linkText: 'V236353135',
    },
    {
      announcement: 'Added a new tool for creating and updating campaigns in bulk',
      link: '#/import',
      linkText: 'Check it out here',
      isInternal: true,
    },
    {
      announcement: 'Launched: Detailed campaign view UI improvements',
      link: '#/new-campaign',
      linkText: 'Check it out here',
      isInternal: true,
    },
    {
      announcement: 'Please Note: We have disabled Arrow-key navigation on the Campaigns page due to conflict with editing fields. Please contact asitta@ for any concerns and feedback.',
    },
    {
      announcement: 'Please Note: Tab navigation is now supported on the Campaigns page. Press tab/shift+tab from a cell to move right/left respectively.',
    },
  ];
};
